import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const KitHeader = () => {
  const { classes } = styles();

  return (
    <Grid container justifyContent='center' alignItems='center' direction='column' className={classes.container}>
      <Grid item>
        <Grid container style={{ maxWidth: 350 }} justifyContent='center'>
          <Typography className={classes.title}>Drop your cards here.</Typography>
          <Typography className={classes.subtitle}>
            Start creating your media kit by dragging and dropping cards into this space.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = makeStyles()(() => ({
  container: {
    backgroundColor: '#F7F6F9',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  title: {
    fontSize: 12,
    fontWeight: 500,
    marginBottom: 12,
    textAlign: 'center'
  },
  subtitle: {
    fontSize: 14,
    fontStyle: 'italic',
    color: '#333333',
    opacity: 0.5,
    textAlign: 'center'
  }
}));

export default KitHeader;
