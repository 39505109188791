import React, { useState, useEffect, useMemo } from 'react';

import { makeStyles } from 'tss-react/mui';
import { Grid, Typography, Button, Box, ButtonBase } from '@mui/material';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { EditBoxProps } from './MediaKitEditBox';
import { isEmpty } from 'lodash';
import AddIcon from 'assets/components/AddIcon';
import { ElementContentType, RichTextContent } from 'types/generated';

const styles = makeStyles()((theme) => ({
  error: { color: 'red' },
  container: { marginTop: 30 },
  textEditor: { padding: 15, minHeight: 300, width: '100%' },
  textPreview: { marginTop: 12, fontStyle: 'italic' },
  previewContainer: { padding: 10, fontSize: 14, marginTop: 10, paddingBottom: 20 },
  placeholderContainer: {
    width: '100%',
    paddingBottom: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 200
  }
}));

function isRichTextContent(content?: ElementContentType | null): content is RichTextContent {
  return content && (content as RichTextContent).rich_text_content !== undefined ? true : false;
}

const RichTextBox = ({ element: { content }, handleUpdate, isEditing, setIsEditing }: EditBoxProps) => {
  const { classes } = styles();
  const [richTextContent, setRichTextContent] = useState(EditorState.createEmpty());

  const storedRichTextContent = useMemo(
    () => (isRichTextContent(content) ? content.rich_text_content : undefined),
    [content]
  );

  useEffect(() => {
    if (!isEditing) {
      if (storedRichTextContent) {
        const contentBlock = htmlToDraft(storedRichTextContent);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          setRichTextContent(EditorState.createWithContent(contentState));
        }
      } else {
        setRichTextContent(EditorState.createEmpty());
      }
    }
  }, [storedRichTextContent, setRichTextContent, isEditing]);

  if (isEditing) {
    return (
      <>
        <Editor
          toolbar={{
            options: [
              'inline',
              'blockType',
              'fontSize',
              'list',
              'link',
              'embedded',
              'emoji',
              'history',
              'textAlign',
              'fontFamily'
            ]
          }}
          placeholder={'Enter your content here...'}
          editorClassName={classes.textEditor}
          editorState={richTextContent}
          onEditorStateChange={(editorState) => {
            setRichTextContent(editorState);
          }}
        />
        <Grid container justifyContent='flex-start'>
          <Button
            variant='outlined'
            color='primary'
            onClick={() => {
              const convertedContent = draftToHtml(convertToRaw(richTextContent.getCurrentContent()));
              handleUpdate({ ...content, rich_text_content: convertedContent });
            }}
          >
            {`Save & Close`}
          </Button>
        </Grid>
      </>
    );
  }

  if (isEmpty(storedRichTextContent)) {
    return (
      <ButtonBase className={classes.placeholderContainer} onClick={() => setIsEditing(true)}>
        <AddIcon size={35} strokeWidth={3} />
      </ButtonBase>
    );
  }

  return (
    <Box className={classes.previewContainer}>
      <Typography
        align='center'
        className={classes.textPreview}
        dangerouslySetInnerHTML={{ __html: storedRichTextContent! }}
      />
    </Box>
  );
};

export default RichTextBox;
