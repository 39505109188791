export const SNACK_BAR_MESSAGES = {
  MEDIA_KIT_CREATED: 'Media Kit Created!',
  MEDIA_KIT_PUBLISHED: 'Media Kit Published!',
  MEDIA_KIT_UPDATED: 'Media Kit Updated!',
  MEDIA_KIT_DELETED: 'Media Kit Deleted!',
  MEDIA_KIT_LINK_COPIED: 'Media Kit Link Copied!',
  REPORT_LINK_COPIED: 'Report Link Copied!',
  INVOICE_LINK_COPIED: 'Invoice Link Copied!',
  BITLY_LINK_COPIED: 'Bitly Link Copied!',
  APAR_LINK_COPIED: 'APAR Link Copied!'
};
