import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { formatNumber, formatPercentage } from 'lib/formatNumber';
import { Profile } from 'types/generated';

interface ComponentProps {
  profile: Profile;
}

interface StatBoxProps {
  stat: string;
  title: string;
  subtitle: string;
}

const styles = makeStyles()((theme) => ({
  statContainer: {
    border: 'solid #6C6C72 0.5px',
    borderRadius: 4,
    marginBottom: 10,
    padding: 0,
    paddingBottom: 15
  },
  statTitle: {
    fontSize: 24,
    textAlign: 'center'
  },
  statSubtitle: {
    fontSize: 10,
    fontWeight: 600,
    textAlign: 'center',
    maxWidth: 160,
    margin: '0 auto'
  },
  statTip: {
    fontSize: 10,
    textAlign: 'center',
    maxWidth: 160,
    margin: '0 auto'
  },
  statCell: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 10px'
  },
  statDivider: {
    borderRight: 'solid #D8D8D8 2px',
    width: 2
  },
  sectionHeader: {
    fontSize: 16,
    fontWeight: 500,
    textAlign: 'center',
    marginBottom: 5,
    marginTop: 5
  }
}));

const SummaryStats = ({ profile }: ComponentProps) => {
  const { classes } = styles();

  const StatBox = ({ stat, title, subtitle }: StatBoxProps) => (
    <>
      <Typography className={classes.statTitle}>{stat}</Typography>
      <Typography className={classes.statSubtitle}>{title}</Typography>
      <Typography className={classes.statTip}>{subtitle}</Typography>
    </>
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={7}>
        <Grid container className={classes.statContainer}>
          <Grid item xs={12} justifyContent='center'>
            <Typography className={classes.sectionHeader}>Reach of media types</Typography>
          </Grid>
          <Grid item xs={3} className={`${classes.statCell} ${classes.statDivider}`}>
            <StatBox
              stat={formatNumber(profile.followers_count)}
              title={'Followers'}
              subtitle={'Total users following this account'}
            />
          </Grid>
          <Grid item xs={3} className={`${classes.statCell} ${classes.statDivider}`}>
            <StatBox
              stat={formatNumber(profile.instagramFeedStats?.reach?.median)}
              title={'Grid Reach'}
              subtitle={'Median unique views on recent grid content'}
            />
          </Grid>
          <Grid item xs={3} className={`${classes.statCell} ${classes.statDivider}`}>
            <StatBox
              stat={formatNumber(profile.instagramReelStats?.reach?.median)}
              title={'Reels Reach'}
              subtitle={'Median unique views on recent reels content'}
            />
          </Grid>
          <Grid item xs={3} className={classes.statCell}>
            <StatBox
              stat={formatNumber(profile.storyStats?.reach?.median)}
              title={'Story Reach'}
              subtitle={'Median unique views on recent story content'}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={5}>
        <Grid container className={classes.statContainer}>
          <Grid item xs={12} justifyContent='center'>
            <Typography className={classes.sectionHeader}>Engagement of media types</Typography>
          </Grid>
          <Grid item xs={6} className={`${classes.statCell} ${classes.statDivider}`}>
            <StatBox
              stat={`${formatPercentage(profile.instagramFeedStats?.engagement_percentageOfReach?.median)}%`}
              title={'Grid Engagement'}
              subtitle={'Median engagement to reach for recent grid content'}
            />
          </Grid>
          <Grid item xs={6} className={`${classes.statCell}`}>
            <StatBox
              stat={`${formatPercentage(profile.instagramReelStats?.total_interactions_percentageOfReach?.median)}%`}
              title={'Reels Engagement'}
              subtitle={'Median engagement to reached for recent reels content'}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SummaryStats;
