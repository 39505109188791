import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTheme } from '@mui/material';

import {
  ButtonBase,
  Avatar,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  Paper,
  Dialog,
  FormControlLabel,
  lighten
} from '@mui/material';

import {
  Profile,
  FacebookPageInfo,
  useGetFacebookPagesQuery,
  useCreateProfileMutation,
  useDeleteProfileMutation,
  useGetProfilesQuery,
  useGetMyUserQuery,
  SocialChannel
} from '../../types/generated';
import api from '../../api/q83API';

import InstagramVerification from './InstagramVerification';
import YoutubeProfile from './YoutubeProfile';
import Troubleshooting from './Troubleshooting';
import Modal from 'components/Modal';
import { IOSSwitch } from 'components/Fields/IOSSwitch';

import { useMobileView } from '../../hooks/useMobileView';
import { useErrorHandlerHook } from 'hooks/useErrorCatchHook';
import useTracking from 'context/useTrackingCtx';
import { compact, filter, find } from 'lodash';
import TiktokProfiles from './TiktokProfileManagement';

const PageManagement = () => {
  const theme = useTheme();
  const { trackEvent } = useTracking();
  const [mobileView] = useMobileView();
  const [removePageDetails, setRemovePageDetails] = useState<
    { removePageId: string; profileType: SocialChannel } | undefined
  >();
  const [isTroubleshootingOpen, setIsTroubleshootingOpen] = useState(false);
  const [loadingPageIds, setLoadingPageIds] = useState<string[]>([]);
  const [loadingInstagramIds, setLoadingInstagramIds] = useState<string[]>([]);

  const [search, setSearchParams] = useSearchParams();

  useEffect(() => {
    const query = new URLSearchParams(search);
    const message = query.get('message');
    if (message) {
      alert(message);
      query.delete('message');
      setSearchParams(query.toString());
    }
  }, [search, setSearchParams]);

  const { data: userData, loading: userLoading, error: userError, refetch: userRefetch } = useGetMyUserQuery();
  const {
    data: profilesData,
    loading: profilesLoading,
    error: profilesError,
    refetch: profilesRefetch
  } = useGetProfilesQuery();
  const {
    data: pagesData,
    loading: pagesLoading,
    error: pagesError,
    refetch: pagesRefetch
  } = useGetFacebookPagesQuery();
  const [createProfile, { loading: createLoading, error: createError }] = useCreateProfileMutation({
    onCompleted: () => {
      alert('Added social account successfully!');
    }
  });

  const [deleteProfile, { loading: deleteLoading, error: deleteError }] = useDeleteProfileMutation({
    onCompleted: () => {
      setRemovePageDetails(undefined);
      alert('Removed social account successfully!');
    }
  });

  const { apolloHandler } = useErrorHandlerHook();

  useEffect(() => {
    if (pagesError) {
      apolloHandler(pagesError, `Unable to fetch Facebook pages`);
    }
  }, [pagesError, apolloHandler]);

  useEffect(() => {
    if (deleteError) {
      apolloHandler(deleteError, `Unable to delete social account`);
    }
  }, [deleteError, apolloHandler]);

  useEffect(() => {
    if (createError) {
      apolloHandler(createError, `Unable to add social account`);
    }
  }, [createError, apolloHandler]);

  useEffect(() => {
    if (profilesError) {
      apolloHandler(profilesError, `Unable to get social profiles`);
    }
  }, [profilesError, apolloHandler]);

  useEffect(() => {
    if (userError) {
      apolloHandler(userError, `Unable to get user info`);
    }
  }, [userError, apolloHandler]);

  const profiles = profilesData?.getProfiles;
  const facebookPages = pagesData?.getFacebookPages;
  const user = userData?.myUser;
  const isLoading = pagesLoading || createLoading || profilesLoading || deleteLoading || userLoading;

  const handleAdd = (page: FacebookPageInfo, profileType: SocialChannel.Facebook | SocialChannel.Instagram) => {
    if (getActiveProfile(profileType, page) && page?.id) {
      setRemovePageDetails({ removePageId: page.id, profileType });
    } else {
      const pageId = page?.id;
      if (profileType === SocialChannel.Instagram) {
        if (!page?.instagram_business_account) {
          alert(`This instagram account is invalid`);
        } else {
          // Allow less than 100 followers for staging / local
          if (
            profileType === SocialChannel.Instagram &&
            process.env.REACT_APP_DEPLOYMENT === 'production' &&
            page?.instagram_business_account?.followers_count &&
            page.instagram_business_account.followers_count < 100
          ) {
            alert(`Only Instagram accounts with more than 100 followers can be added to Kitly`);
          } else {
            setLoadingInstagramIds(compact([...loadingInstagramIds, page.instagram_business_account.id]));
            createProfile({
              variables: {
                info: {
                  profileType,
                  name: page.name,
                  id: page.id,
                  instagram_business_account: {
                    id: page.instagram_business_account.id,
                    name: page.instagram_business_account.name,
                    profile_picture_url: page.instagram_business_account.profile_picture_url,
                    followers_count: page.instagram_business_account.followers_count
                  }
                }
              }
            })
              .then(() => {
                trackEvent('profile', `Added instagram profile`);
                profilesRefetch();
              })
              .catch((ex) => {
                alert(ex.message);
              })
              .finally(() => {
                if (page?.instagram_business_account?.id) {
                  setLoadingInstagramIds(
                    filter(loadingInstagramIds, (id) => id !== page!.instagram_business_account!.id)
                  );
                }
              });
          }
        }
      } else if (page?.id) {
        setLoadingPageIds(compact([...loadingPageIds, pageId]));
        createProfile({
          variables: {
            info: {
              profileType,
              name: page.name,
              id: page.id
            }
          }
        })
          .then(() => {
            trackEvent('profile', `Added facebook profile`);
            profilesRefetch();
          })
          .catch((ex) => {
            alert(ex.message);
          })
          .finally(() => {
            setLoadingPageIds(filter(loadingPageIds, (id) => id !== pageId));
          });
      }
    }
  };

  const handleRemove = (
    pageToRemove: FacebookPageInfo,
    profileId: string,
    profileType: SocialChannel.Instagram | SocialChannel.Facebook
  ) => {
    if (profileType === SocialChannel.Instagram && pageToRemove?.instagram_business_account?.id) {
      setLoadingInstagramIds([...loadingInstagramIds, pageToRemove!.instagram_business_account!.id]);
    } else {
      setLoadingPageIds(compact([...loadingPageIds, pageToRemove.id]));
    }
    deleteProfile({ variables: { profileId: profileId, pageId: removePageDetails?.removePageId } })
      .catch((ex) => alert(ex.message))
      .finally(() => {
        if (profileType === SocialChannel.Instagram && pageToRemove?.instagram_business_account?.id) {
          setLoadingInstagramIds(
            filter(loadingInstagramIds, (id) => id !== pageToRemove!.instagram_business_account!.id)
          );
        } else {
          setLoadingPageIds(filter(loadingPageIds, (id) => id !== pageToRemove.id));
        }
        profilesRefetch();
      });
  };

  const handleToggleTroubleshooting = () => {
    setIsTroubleshootingOpen(!isTroubleshootingOpen);
  };

  const getActiveProfile = (
    profileType: SocialChannel.Instagram | SocialChannel.Facebook,
    page?: FacebookPageInfo | null
  ) => {
    const profile = profileType === SocialChannel.Instagram ? page?.instagram_business_account : page;
    if (profile && profiles) {
      const index = profiles.findIndex((x) => x?.id === profile.id);
      if (index !== -1) {
        return profiles[index];
      }
    }
    return null;
  };

  const renderDisconnectButton = () => {
    const cancelInstagramVerification = () =>
      api
        .get('cancel/instagram')
        .then((d) => {
          alert('Disconnected from Instagram successfully');
          userRefetch();
          pagesRefetch();
        })
        .catch((ex) => {
          alert(ex.message);
        });

    return (
      <Grid item xs={12}>
        <Grid item container style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Grid item container xs={6} alignItems='center'>
            <Grid item>
              <img
                src={'https://media.q-83.com/site/instagram_logo.png'}
                alt='instagram logo'
                style={{ height: theme.spacing(5), width: theme.spacing(5), marginRight: theme.spacing(2) }}
              />
            </Grid>
            <Grid item>
              <Typography variant='body1' style={{ fontWeight: 600 }}>
                Instagram / Facebook
              </Typography>
            </Grid>
          </Grid>
          {isLoading ? (
            <CircularProgress size={15} />
          ) : (
            <Button variant='outlined' onClick={cancelInstagramVerification}>
              <Typography
                component='span'
                variant='body1'
                style={{ cursor: 'pointer', color: theme.palette.primary.main, fontWeight: 600 }}
              >
                Disconnect
              </Typography>
            </Button>
          )}
        </Grid>
        <Divider style={{ margin: `${theme.spacing(3)} 0px`, backgroundColor: '#999999' }} />
      </Grid>
    );
  };

  const renderRemoveAccountModal = () => {
    if (!removePageDetails) return null;

    const pageToRemove = facebookPages ? find(facebookPages, { id: removePageDetails.removePageId }) : null;

    const pageId =
      removePageDetails.profileType === SocialChannel.Instagram && pageToRemove?.instagram_business_account?.id
        ? pageToRemove?.instagram_business_account?.id
        : pageToRemove?.id;

    const correspondingProfile = pageId && profiles ? find(profiles, { id: pageId }) : null;

    if (!pageToRemove || !correspondingProfile || !pageId) return null;

    return (
      <Modal
        isOpen={true}
        onToggle={() => {
          setRemovePageDetails(undefined);
        }}
        onConfirm={() => {
          if (correspondingProfile._id) {
            handleRemove(
              pageToRemove,
              correspondingProfile._id,
              correspondingProfile.profileType === SocialChannel.Instagram
                ? SocialChannel.Instagram
                : SocialChannel.Facebook
            );
            setRemovePageDetails(undefined);
          }
        }}
        maxWidth={'xs'}
        heading={'Disconnect Account'}
      >
        <div>Are you sure you want to disconnect this account?</div>
      </Modal>
    );
  };

  const renderFacebookInstagramRow = (isInstagram: boolean) => {
    const compactedFBPages = compact(facebookPages);
    const data = isInstagram ? filter(compactedFBPages, 'instagram_business_account') : compactedFBPages;

    const emptyText = `No ${isInstagram ? 'Instagram profiles' : 'Facebook pages'} available.`;

    if (!data.length) {
      return (
        <Paper
          style={{
            marginTop: 8,
            marginBottom: 8,
            padding: 8,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div style={{ fontSize: 14, margin: '7px' }}>{emptyText}</div>
        </Paper>
      );
    }

    return data.map((page, i) => {
      const loading = isInstagram
        ? page?.instagram_business_account?.id
          ? loadingInstagramIds.includes(page.instagram_business_account.id)
          : false
        : page?.id
        ? loadingPageIds.includes(page.id)
        : false;
      const activeProfile = getActiveProfile(isInstagram ? SocialChannel.Instagram : SocialChannel.Facebook, page);

      if (!page) return null;

      return (
        <FacebookInstaRow
          isInstagram={isInstagram}
          key={page?.id || i}
          page={page}
          loading={loading}
          profile={activeProfile}
          handleAdd={() => {
            handleAdd(page, isInstagram ? SocialChannel.Instagram : SocialChannel.Facebook);
          }}
        />
      );
    });
  };

  const renderTroubleshootingModal = () => {
    const { palette } = theme;
    return (
      isTroubleshootingOpen && (
        <Dialog
          open={isTroubleshootingOpen}
          onClose={handleToggleTroubleshooting}
          maxWidth={'lg'}
          fullScreen={mobileView}
        >
          <div
            style={{
              maxWidth: '660px',
              padding: '20px',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Troubleshooting />
            <Button
              onClick={handleToggleTroubleshooting}
              style={{
                alignSelf: 'flex-end',
                marginTop: '20px',
                color: '#fff',
                backgroundColor: palette.primary.main
              }}
            >
              Close
            </Button>
          </div>
        </Dialog>
      )
    );
  };

  const renderTroubleshooting = () => {
    const { palette, spacing } = theme;
    const instagramPages = filter(facebookPages, 'instagram_business_account');

    return (
      <Grid
        container
        item
        xs={12}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        {Boolean(instagramPages.length > 0) ? (
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              marginBottom: spacing(8)
            }}
          >
            <Typography variant='body1' component='span'>
              Can't see the account you're looking for?&nbsp;
            </Typography>
            <ButtonBase onClick={handleToggleTroubleshooting}>
              <Typography variant='body1' component='span' style={{ cursor: 'pointer', color: palette.primary.main }}>
                Troubleshoot here
              </Typography>
            </ButtonBase>
          </Grid>
        ) : (
          <Grid item xs={12} style={{ flexDirection: 'column' }}>
            <div
              style={{
                flexDirection: 'column',
                margin: '10px'
              }}
            >
              <span style={{ fontWeight: 500 }}>Is your Instagram account connected to a Facebook page?</span>
              <Button
                variant='outlined'
                style={{
                  margin: '5px',
                  maxWidth: '150px'
                }}
                onClick={handleToggleTroubleshooting}
              >
                Check
              </Button>
            </div>
          </Grid>
        )}
        {renderDisconnectButton()}
      </Grid>
    );
  };

  const cacheError = profiles?.reduce((acc, curr) => {
    if (curr?.cacheError) acc = true;
    return acc;
  }, false);

  const showFacebookVerification = Boolean(
    !user?.isFacebookVerified || user?.doesRequireReauthentication || cacheError
  );

  return (
    <Grid data-cy={`instagram-profile-management`} container spacing={0} style={{ margin: 0, padding: 0 }}>
      <Grid item xs={12} lg={12} style={{ padding: mobileView ? 3 : `0px ${theme.spacing(5)}`, marginBottom: 30 }}>
        {!showFacebookVerification && renderTroubleshooting()}
        {!showFacebookVerification && renderTroubleshootingModal()}
        {showFacebookVerification && <InstagramVerification />}
        <Typography variant='body1' style={{ fontWeight: 600 }}>
          Instagram
        </Typography>
        {renderFacebookInstagramRow(true)}
        <Typography variant='body1' style={{ fontWeight: 600, marginTop: 12 }}>
          Facebook
        </Typography>
        {renderFacebookInstagramRow(false)}
        {renderRemoveAccountModal()}
      </Grid>
      <Grid item xs={12} lg={12} style={{ padding: mobileView ? 3 : `0px ${theme.spacing(5)}`, marginBottom: 30 }}>
        <YoutubeProfile />
      </Grid>
      <Grid item xs={12} lg={12} style={{ padding: mobileView ? 3 : `0px ${theme.spacing(5)}` }}>
        <TiktokProfiles />
      </Grid>
    </Grid>
  );
};

const FacebookInstaRow = ({
  page,
  loading,
  profile,
  handleAdd,
  isInstagram
}: {
  page: FacebookPageInfo;
  loading: boolean;
  profile?: Profile | null;
  handleAdd: () => void;
  isInstagram: boolean;
}) => {
  const theme = useTheme();
  const [useFallbackImage, setUseFallbackImage] = useState(false);

  const name = isInstagram ? `@${page?.instagram_business_account?.username}` : page?.name || '';

  const avatarUrl = page?.instagram_business_account?.profile_picture_url || page?.picture?.data?.url || '';
  const username = page?.instagram_business_account?.username || page?.id || '';
  const pageId = page?.id || '';

  return (
    <div key={pageId}>
      <Grid
        container
        alignItems='center'
        spacing={0}
        justifyContent={'space-between'}
        style={{
          margin: 0,
          padding: `${theme.spacing(3)} 0px`,
          display: 'flex'
        }}
      >
        <Grid
          item
          container
          xs={9}
          sm={10}
          lg={10}
          style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap'
          }}
        >
          <Grid item xs={12} md={4} style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                padding: '5px'
              }}
            >
              <Avatar
                src={useFallbackImage ? 'https://media.q-83.com/site/user.png' : avatarUrl}
                style={{ width: 30, height: 30 }}
                onError={(e) => {
                  // tslint:disable-line
                  e.persist();
                  setUseFallbackImage(true);
                }}
              />
              <div style={{ marginLeft: theme.spacing(3) }}>
                <Typography variant='body1'>{name}</Typography>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={3} sm={2} style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <FormControlLabel
            label={
              <span style={{ color: profile ? undefined : lighten(theme.palette.common.black, 0.6) }}>
                {profile ? (loading ? 'Disconnecting...' : 'Disconnect') : loading ? 'Connecting...' : 'Connect'}
              </span>
            }
            style={{ marginLeft: -30 }}
            control={
              <IOSSwitch
                data-cy={`${username}-${profile ? 'disconnect' : 'connect'}-button`}
                checked={Boolean(profile)}
                style={{
                  color: theme.palette.primary.main
                }}
                disabled={loading}
                onClick={() => {
                  handleAdd();
                }}
              />
            }
          />
        </Grid>
      </Grid>
      <Divider style={{ backgroundColor: '#BBBBBB' }} />
    </div>
  );
};

export default PageManagement;
