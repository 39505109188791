import React from 'react';

import { Grid, ButtonBase, useTheme } from '@mui/material';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const paginationPageSize = 20;
const paginationLinkLimit = 10;

const Pagination = ({
  page,
  mediaCount,
  pageSize,
  handlePageChange
}: {
  page: number;
  mediaCount: number;
  pageSize?: number;
  handlePageChange: (page: number) => void;
}) => {
  const theme = useTheme();
  const pageSizeToUse = pageSize || paginationPageSize;
  let numberOfPages = Math.ceil(mediaCount / pageSizeToUse);

  let startPage = 0;
  const pagesMidpoint = Math.ceil(paginationLinkLimit / 2);
  if (numberOfPages > paginationLinkLimit) {
    // pages > 10
    if (page > pagesMidpoint) {
      // current page > 5
      if (page > numberOfPages - pagesMidpoint) {
        // current page > pages - 5
        startPage = numberOfPages - paginationLinkLimit;
      } else {
        startPage = page - pagesMidpoint;
      }
    }
  }

  const pageButtonArray: number[] = [];
  for (let i = startPage; i < Math.min(numberOfPages, startPage + paginationLinkLimit); i++) {
    pageButtonArray.push(i);
  }

  const showFirstAndLastButtons = Boolean(numberOfPages > paginationLinkLimit);

  return (
    <Grid container justifyContent='center'>
      {showFirstAndLastButtons && (
        <Grid item>
          <ButtonBase disabled={page === 0} onClick={() => handlePageChange(0)}>
            First
          </ButtonBase>
        </Grid>
      )}
      <Grid item>
        <ButtonBase
          disabled={page === 0}
          onClick={() => (page > 0 ? handlePageChange(page - 1) : {})}
          style={{ width: 30 }}
        >
          <ChevronLeftIcon />
        </ButtonBase>
      </Grid>
      {pageButtonArray.map((p, index) => {
        const currentPage = Boolean(p === page);
        return (
          <Grid item key={index}>
            <ButtonBase
              disabled={currentPage}
              onClick={() => handlePageChange(p)}
              style={{
                width: 30,
                color: currentPage ? theme.palette.primary.main : '',
                borderBottom: currentPage ? `2px solid ${theme.palette.primary.main}` : ''
              }}
            >
              {p + 1}
            </ButtonBase>
          </Grid>
        );
      })}
      <Grid item>
        <ButtonBase
          disabled={page === numberOfPages - 1}
          onClick={() => (page < numberOfPages - 1 ? handlePageChange(page + 1) : {})}
          style={{ width: 30 }}
        >
          <ChevronRightIcon />
        </ButtonBase>
      </Grid>
      {showFirstAndLastButtons && (
        <Grid item>
          <ButtonBase disabled={page === numberOfPages - 1} onClick={() => handlePageChange(numberOfPages - 1)}>
            Last
          </ButtonBase>
        </Grid>
      )}
    </Grid>
  );
};

export default Pagination;
