import React, { useState, useEffect, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Typography, CircularProgress, Button, ButtonBase, Box, Grid } from '@mui/material';
import { useGetInstagramMediaLazyQuery, InstagramMedia } from 'types/generated';
import Pagination from 'components/Pagination';
import InstagramMediaList from './InstagramMediaList';
import { isEmpty, compact } from 'lodash';
import { useErrorHandlerHook } from 'hooks/useErrorCatchHook';

import { ElementContentType, SelectedMediaContent } from 'types/generated';

import { EditBoxProps } from './MediaKitEditBox';
import AddIcon from 'assets/components/AddIcon';
import ProfileSelector from 'components/Fields/ProfileSelector';

const styles = makeStyles()((theme) => ({
  error: { color: 'red', marginTop: 12 },
  textPreview: { marginTop: 12, fontStyle: 'italic', fontSize: 14 },
  previewContainer: { fontSize: 14, padding: 10, paddingBottom: 20, width: '100%' },
  previewEditContainer: {
    backgroundColor: theme.palette.info.light,
    padding: 10,
    marginBottom: 10,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'center'
  },
  previewTitle: { fontSize: 16, fontWeight: 500 },
  placeholderContainer: {
    width: '100%',
    paddingBottom: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 200
  },
  placeholderTitle: {
    color: theme.palette.primary.main,
    margin: 4
  },
  placeholderImage: {
    width: 28,
    height: 28
  },
  metricsContainer: {
    backgroundColor: theme.palette.info.light,
    padding: 10,
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    marginBottom: 20
  },
  profileItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch'
  },
  item: {
    textAlign: 'center',
    marginRight: 10,
    width: '100%',
    paddingRight: 2,
    paddingLeft: 2
  },
  itemCard: {
    width: '100%',
    marginBottom: 10,
    height: 100
  },
  cardTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  cardTitleSpacer: {
    width: 24
  },
  cardDivider: {
    display: 'block',
    border: 'none',
    width: 31,
    height: 2,
    margin: '4px auto',
    backgroundImage: `linear-gradient(
          to left,
          #cccccc,
          ${theme.palette.background.default} 
        )`
  },
  icon: {
    width: 12,
    height: 12,
    marginRight: 8
  },
  text1: {
    fontSize: 22,
    color: theme.palette.primary.main
  },
  text2: {
    fontSize: 14,
    fontWeight: 600
  },
  text3: {
    fontSize: 10
  },
  text4: {
    fontSize: 10
  },
  slider: {
    width: '100%'
  },
  slideContainer: { position: 'relative' }
}));

const mediaToDisplayPerPage = 8;

function isSelectedMediaContent(content?: ElementContentType | null): content is SelectedMediaContent {
  return content && (content as SelectedMediaContent).selected_media_content !== undefined ? true : false;
}

const SelectedMediaBox = ({ element: { content }, handleUpdate, isEditing, setIsEditing }: EditBoxProps) => {
  const { classes } = styles();
  const { apolloHandler } = useErrorHandlerHook();

  const [getInstagramMedia, { data, loading, error }] = useGetInstagramMediaLazyQuery();

  const [page, setPage] = useState(0);
  const [selectedProfileId, setSelectedProfileId] = useState<string | undefined>();
  const [selectedMedia, setSelectedMedia] = useState<Array<string>>([]);

  const selectedMediaContent = useMemo(
    () => (isSelectedMediaContent(content) ? content?.selected_media_content || {} : {}),
    [content]
  );

  useEffect(() => {
    if (selectedProfileId) {
      setPage(0);
      getInstagramMedia({ variables: { _id: selectedProfileId } });
    }
  }, [selectedProfileId, getInstagramMedia]);

  useEffect(() => {
    if (!isEditing) {
      setSelectedMedia(selectedMediaContent ? compact(selectedMediaContent.instagram_media) : []);
      setSelectedProfileId(selectedMediaContent?.profileId || undefined);
    }
  }, [selectedMediaContent, setSelectedProfileId, setSelectedMedia, isEditing]);

  useEffect(() => {
    setPage(0);
  }, [isEditing]);

  useEffect(() => {
    if (error) {
      apolloHandler(error);
    }
  }, [error, apolloHandler]);

  const mediaOptions = useMemo(() => {
    return data?.getSingleProfile?.latestInstagramPosts ? compact(data.getSingleProfile.latestInstagramPosts) : [];
  }, [data]);

  const paginatedMediaOptions = useMemo(() => {
    const mediaToSkip = page * mediaToDisplayPerPage;
    return mediaOptions.slice(mediaToSkip, mediaToSkip + mediaToDisplayPerPage);
  }, [mediaOptions, page]);

  const selectedMediaForPreview = useMemo(() => {
    const mediaToSkip = page * mediaToDisplayPerPage;
    const mediaDirectory: { [key: string]: InstagramMedia } = {};
    mediaOptions.forEach((o) => {
      if (o.id) {
        mediaDirectory[o.id] = o;
      }
    });
    return compact(
      selectedMedia.slice(mediaToSkip, mediaToSkip + mediaToDisplayPerPage).map((id) => mediaDirectory[id])
    );
  }, [mediaOptions, selectedMedia, page]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return (
      <Typography align='center' variant='h5' className={classes.error}>
        {String(error.message)}
      </Typography>
    );
  }

  if (isEditing) {
    return (
      <>
        <ProfileSelector
          channels={['instagram']}
          selectedId={selectedProfileId}
          setSelectedId={(id) => {
            setSelectedProfileId(id);
            setSelectedMedia([]);
          }}
          emptyLabel='Select profile'
        />
        <Box className={classes.previewEditContainer}>
          <Typography>Select Media</Typography>
          <InstagramMediaList
            media={paginatedMediaOptions}
            selectedMedia={selectedMedia}
            setSelectedMedia={setSelectedMedia}
          />
          <Pagination
            mediaCount={mediaOptions.length}
            pageSize={mediaToDisplayPerPage}
            page={page}
            handlePageChange={setPage}
          />
        </Box>

        <Grid container justifyContent='flex-start'>
          <Button
            variant='outlined'
            color='primary'
            onClick={() => {
              handleUpdate({
                ...content,
                selected_media_content: {
                  profileId: selectedProfileId,
                  instagram_media: selectedMedia
                }
              });
            }}
          >
            {`Save & Close`}
          </Button>
        </Grid>
      </>
    );
  }

  return (
    <Box className={classes.previewContainer}>
      {isEmpty(selectedMediaForPreview) ? (
        <ButtonBase className={classes.placeholderContainer} onClick={() => setIsEditing(true)}>
          <AddIcon size={35} strokeWidth={3} />
        </ButtonBase>
      ) : (
        <Box>
          <InstagramMediaList media={selectedMediaForPreview} />
          {Boolean(selectedMedia.length > mediaToDisplayPerPage) && (
            <Pagination
              mediaCount={selectedMedia.length}
              pageSize={mediaToDisplayPerPage}
              page={page}
              handlePageChange={setPage}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default SelectedMediaBox;
