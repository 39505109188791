import { useCallback, useState, useMemo, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from 'tss-react/mui';
import { useTheme } from '@mui/material';
import FakeButton from 'components/FakeButton';
import deliverables from 'lib/deliverables';
import channels from 'lib/channels';
import { TrackingLinkActions } from 'lib/constants';
import { SNACK_BAR_MESSAGES } from 'lib/snackbarMessages';
import trim from 'lodash/trim';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';
import moment from 'moment';
import {
  Collaboration,
  CollaborationStatus,
  Deliverable,
  GetCollaborationListQuery,
  SocialDeliverable,
  useUpdateCollaborationMutation
} from 'types/generated';
import { useErrorHandlerHook } from 'hooks/useErrorCatchHook';
import { CollabEditMenu } from './CollabEditMenu';
import { DeliverableCreation } from './DeliverableCreation';
import { DeliverableEditMenu } from './DeliverableEditMenu';
import 'components/LineClamp.css';
import StatusSelector from './StatusSelector';
import { COLORS } from 'lib/constants';
import DeliverableNotes from './DeliverableNotes';
import CollabNotes from './CollabNotes';
import { currencyOptions } from '../../../lib/invoiceValues';
import ExpandIcon from 'components/Shared/ExpandIcon';
import FileUploadsField from './FileUploadsField';
import { Collapse } from '@mui/material';
import { useSnackbar } from 'notistack';
import LinkIcon from 'assets/icons/LinkIcon.svg';
import useCopyText from 'hooks/useCopyText';

type PickedCollab = Collaboration;
type PickedDeliverables = Deliverable[];

const backupUserImg = 'https://media.q-83.com/site/user.png';

interface CollabListProps {
  collaborationList: GetCollaborationListQuery['getCollaborationList'];
  refetch: () => void;
}

type CurrencyTotal = {
  value: number;
  symbol: string;
};

export const CollabList = (props: CollabListProps) => {
  const { collaborationList, refetch } = props;

  if (isEmpty(collaborationList)) return null;

  return (
    <div>
      {collaborationList?.map((collab, i) =>
        collab ? <CollabCard key={`campaign-${i}`} collab={collab} refetch={refetch} /> : null
      )}
    </div>
  );
};

const CollabCard = (props: { collab: PickedCollab; refetch: () => void }) => {
  const { collab } = props;
  const { classes } = useStyles();
  const theme = useTheme();

  const { apolloHandler } = useErrorHandlerHook();

  const [updateCollab, { error: updateError, loading: updateLoading }] = useUpdateCollaborationMutation({
    onCompleted: props.refetch
  });

  useEffect(() => {
    if (updateError) {
      apolloHandler(updateError);
    }
  }, [updateError, apolloHandler]);

  const [showDeliverables, setShowDeliverables] = useState<boolean>(false);

  const { deliverables: collabDeliverables } = collab;
  const hasDeliverables = collabDeliverables && !isEmpty(collabDeliverables);
  const numberOfDeliverables = collabDeliverables?.length || 0;

  const totalsByCurrency = useMemo(() => {
    let totals: { [key: string]: CurrencyTotal } = {};

    collabDeliverables?.forEach((o) => {
      if (o?.currency) {
        const currency = find(currencyOptions, { value: o.currency });
        if (currency) {
          if (!totals[currency.value]) {
            totals[currency.value] = { value: 0, symbol: currency.symbol };
          }
          totals[currency.value].value += o.price || 0;
        }
      }
    });

    return totals;
  }, [collabDeliverables]);

  if (!collab?._id) {
    return null;
  }

  return (
    <Paper className={classes.cardContainer}>
      <div className={classes.cardHeader}>
        <Typography style={{ fontSize: 16 }}>{collab.name || ''}</Typography>
        <div className={classes.options}>
          {collab.canEdit && (
            <DeliverableCreation selectedCollabId={collab._id}>
              <FakeButton variant='outlined' size='small' className={classes.addDeliverable}>
                <Typography className={classes.buttonText}>Add deliverable</Typography>
              </FakeButton>
            </DeliverableCreation>
          )}
          <CollabEditMenu collab={collab} collabId={collab._id} />
        </div>
      </div>
      <Grid container direction='column'>
        <Grid item xs={12} container style={{ marginBottom: 5 }} alignItems='center' justifyContent='space-between'>
          <Grid item xs={8} container>
            <Grid item style={{ marginBottom: 5 }}>
              <Typography style={{ fontWeight: 500 }} component='span'>
                Status:
              </Typography>
              <StatusSelector
                loading={updateLoading}
                value={collab.status || CollaborationStatus.Draft}
                setValue={(value) => {
                  updateCollab({
                    variables: {
                      id: collab._id!,
                      updates: {
                        status: value
                      }
                    }
                  });
                }}
              />
            </Grid>

            <Grid item container style={{ marginBottom: 5 }}>
              <Typography style={{ fontWeight: 500 }} component='span'>
                Total Deliverables:
              </Typography>
              <Typography component='span' style={{ marginLeft: 5 }}>
                {numberOfDeliverables}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={4} container justifyContent={'flex-end'} alignItems={'center'}>
            {collab.notes && (
              <CollabNotes
                notes={collab.notes || ''}
                id={collab._id}
                name={collab?.name || ''}
                canEdit={collab?.canEdit || false}
              />
            )}
          </Grid>
        </Grid>
        {!isEmpty(totalsByCurrency) && (
          <Grid item container style={{ marginBottom: 10 }}>
            <Grid item>
              <Typography style={{ fontWeight: 500 }} component='span'>
                Total Amount:
              </Typography>
            </Grid>
            <Grid item container xs={6} direction={'column'} style={{ marginLeft: 5 }}>
              {Object.keys(totalsByCurrency).map((key) => {
                const row = totalsByCurrency[key];

                return (
                  <Grid item key={key}>
                    <Typography component='span'>{`${row.symbol}${row.value} ${key || ''}`}</Typography>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        )}
        {collab.fileUploads && (
          <>
            <Grid item xs={12} style={{ marginBottom: 5 }}>
              <Typography style={{ fontWeight: 500 }} component='span'>
                Attachments:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FileUploadsField value={collab.fileUploads} />
            </Grid>
          </>
        )}
      </Grid>
      <Grid container>
        <Grid item container justifyContent='flex-end'>
          {hasDeliverables && (
            <ButtonBase
              onClick={() => setShowDeliverables(!showDeliverables)}
              style={{ color: theme.palette.primary.main }}
            >
              {showDeliverables ? (
                <>
                  <KeyboardArrowUpIcon />
                  <span>Hide</span>
                </>
              ) : (
                <>
                  <KeyboardArrowDownIcon />
                  <span>Show</span>
                </>
              )}
            </ButtonBase>
          )}
        </Grid>
        <Grid item container>
          {Boolean(hasDeliverables && showDeliverables) && (
            <DeliverablesList deliverables={collab.deliverables} collab={collab} />
          )}
        </Grid>
        <ProgressBar deliverables={collab.deliverables ? compact(collab.deliverables) : []} />
      </Grid>
    </Paper>
  );
};

const DeliverablesList = (props: { deliverables: PickedCollab['deliverables']; collab: Collaboration }) => {
  const { deliverables, collab } = props;
  const { classes } = useStyles();

  const renderTableHead = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography variant='body1' className={classes.tableHeader}>
              Deliverable
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant='body1' className={classes.tableHeader}>
              Due Date
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant='body1' className={classes.tableHeader}>
              Value
            </Typography>
          </TableCell>
          <TableCell>{/* Empty for notes */}</TableCell>
          <TableCell>{/* Empty for links */}</TableCell>
          <TableCell>{/* Empty for menu */}</TableCell>
        </TableRow>
      </TableHead>
    );
  };

  return (
    <Table size='small'>
      {renderTableHead()}
      <TableBody>
        {deliverables?.map((o) =>
          o ? <DeliverableRow key={`deliverable-${o!._id}`} deliverable={o} collab={collab} /> : null
        )}
      </TableBody>
    </Table>
  );
};

const DeliverableRow = (props: { deliverable: NonNullable<PickedDeliverables>[0]; collab: Collaboration }) => {
  const { deliverable, collab } = props;
  const { classes } = useStyles();
  const [isLinksExpanded, setIsLinksExpanded] = useState(false);
  const trackingLinks = deliverable?.trackingLinks;
  const toggleLinksExpand = () => setIsLinksExpanded(!isLinksExpanded);
  const { enqueueSnackbar } = useSnackbar();
  const copyText = useCopyText();

  const currency = useMemo(() => {
    if (deliverable?.currency) {
      const currency = find(currencyOptions, { value: deliverable.currency });
      if (currency) {
        return currency;
      }
    }

    return currencyOptions[0];
  }, [deliverable]);

  const copyLink = useCallback(
    (text?: string | null) => {
      copyText(text, (error) => {
        if (error) {
          enqueueSnackbar(error);
        } else {
          enqueueSnackbar(SNACK_BAR_MESSAGES.BITLY_LINK_COPIED);
        }
      });
    },
    [enqueueSnackbar, copyText]
  );

  const deliverableProfiles = useMemo(() => {
    if (!isEmpty(deliverable?.profiles)) {
      return deliverable.profiles!;
    }
    return [{ _id: 'blank_profile', profile_picture_url: backupUserImg, displayName: 'No profile assigned' }];
  }, [deliverable]);

  if (!deliverable?._id) {
    return null;
  }

  const { endDate, socialChannel, socialDeliverable, price, startDate, storyFrameCount } = deliverable;

  const dateFormatted = `${startDate ? `${moment(startDate).format('D MMM YY')} - ` : ''}${moment(endDate).format(
    'D MMM YY'
  )}`;

  const channel = channels.find((o) => o.value === socialChannel?.toLowerCase());
  const deliverableLabel =
    socialDeliverable === SocialDeliverable.IgStory && storyFrameCount && storyFrameCount > 1
      ? `${storyFrameCount}x Story frames`
      : deliverables.find((o) => o.value === socialDeliverable)?.label || '';
  return (
    <>
      <TableRow className={classes.tableRowRoot}>
        <TableCell>
          <Grid container alignItems='center' wrap='nowrap'>
            <Grid item container style={{ width: 'min-content' }} wrap='nowrap'>
              <AvatarGroup max={3} classes={{ avatar: classes.avatarGroup }}>
                {deliverableProfiles.slice(0, 101).map((o) => (
                  <Avatar
                    key={o._id}
                    alt='profile picture'
                    src={o?.profile_picture_url || ''}
                    style={{ width: 24, height: 24, marginRight: 4 }}
                    imgProps={{ style: { borderRadius: '50%' } }}
                  >
                    {o?.displayName ? trim(o.displayName, '@')[0] : null}
                  </Avatar>
                ))}
              </AvatarGroup>
            </Grid>
            <Divider orientation='vertical' style={{ width: 2, height: 16, margin: '0px 8px' }} />
            <Grid item container alignItems='center'>
              <Grid item>
                {channel && <img src={channel.logo} alt={channel.label} className={classes.deliverableLogo} />}
              </Grid>
              <Grid item>
                {deliverableLabel && <Typography style={{ fontWeight: 500 }}>{deliverableLabel}</Typography>}
              </Grid>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>
          <Grid item container style={{ padding: '2px 0px' }}>
            <Typography style={{ fontWeight: 500 }}>{dateFormatted}</Typography>
          </Grid>
        </TableCell>
        <TableCell>
          {price && (
            <Typography component='span' style={{ marginLeft: 5 }}>{`${currency.symbol}${price} ${
              currency.value || ''
            }`}</Typography>
          )}
        </TableCell>
        <TableCell>
          <DeliverableNotes
            deliverableNotes={deliverable.notes || ''}
            deliverableId={deliverable._id}
            deliverableName={deliverable?.deliverableName || ''}
            canEdit={collab?.canEdit || false}
          />
        </TableCell>
        <TableCell>
          {!!trackingLinks?.length && (
            <Grid container>
              <Typography style={{ fontWeight: 500 }}>Links</Typography>
              <ExpandIcon isOpen={isLinksExpanded} onClick={toggleLinksExpand} />
            </Grid>
          )}
        </TableCell>
        <TableCell>
          <DeliverableEditMenu deliverable={deliverable} deliverableId={deliverable?._id} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={isLinksExpanded} timeout='auto' unmountOnExit>
            {Boolean(!isEmpty(trackingLinks) && isLinksExpanded) && (
              <>
                {trackingLinks?.map((o, i) => {
                  return (
                    <Grid container key={o?._id || `link_${i}`}>
                      <Typography className={`${classes.notBold} ${classes.notes}`}>
                        {o?.actionType ? `${TrackingLinkActions[o.actionType]}: ` : ''}
                      </Typography>
                      <ButtonBase
                        onClick={() => {
                          copyLink(o?.link);
                        }}
                      >
                        <img src={LinkIcon} alt='' style={{ marginRight: 8 }} />
                        <input
                          value={o?.link || ''}
                          id={`campaign-trackinglink-${o?._id || i}`}
                          type='text'
                          readOnly={true}
                          style={{ position: 'absolute', left: 99999, zIndex: -100 }} // Input hidden from view
                        />
                      </ButtonBase>
                      {o?.longUrl && (
                        <ButtonBase target='_blank' href={o.longUrl} style={{ fontSize: 13 }}>
                          <Typography className={`${classes.notBold} ${classes.notes}`}>{o.link}</Typography>
                        </ButtonBase>
                      )}
                      <Typography className={`${classes.notBold} ${classes.notes}`}>{o?.totalClicks || 0}</Typography>
                    </Grid>
                  );
                })}
              </>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const ProgressBar = (props: { deliverables: PickedDeliverables }) => {
  const { deliverables } = props;
  const completed =
    deliverables?.reduce((acc: PickedDeliverables, curr: NonNullable<PickedDeliverables>[0]) => {
      const now = moment();
      const isCompleted = moment(curr?.endDate).isBefore(now);
      if (isCompleted) acc?.push(curr);
      return acc;
    }, []) || [];

  const completedCount = completed.length || 0;
  const deliverablesCount = deliverables?.length || 0;

  let progress = 0;
  if (completedCount && deliverablesCount) {
    progress = (completedCount / deliverablesCount) * 100;
  }
  if (progress > 100) {
    progress = 100;
  }

  const useStyles = makeStyles()({
    root: {
      height: 4,
      backgroundColor: '#CCCCCC',
      width: '100%',
      position: 'absolute',
      bottom: 0,
      left: 0,
      borderRadius: '0 0 3px 3px'
    },
    bar: { backgroundColor: '#FBD725' }
  });
  const { classes } = useStyles();

  return <LinearProgress value={progress} variant={'determinate'} classes={{ root: classes.root, bar: classes.bar }} />;
};

const useStyles = makeStyles()((theme) => {
  return {
    tableRowRoot: {
      '& > *': {
        borderBottom: 'unset'
      }
    },
    cardContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      padding: '15px 20px 10px',
      marginBottom: 20,
      position: 'relative',
      boxShadow: 'none'
    },
    cardHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 5
    },
    options: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      flexWrap: 'nowrap'
    },
    editIcon: {
      fontSize: 20,
      marginLeft: 15
    },
    collabOverview: {
      fontWeight: 500,
      marginBottom: 5,
      display: 'flex',
      alignItems: 'center'
    },
    notBold: {
      fontWeight: 200
    },
    deliverableContainer: {
      marginTop: 5
    },
    deliverable: {
      display: 'flex',
      flexDirection: 'column'
    },
    deliverableRow: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    deliverableDetails: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexWrap: 'nowrap',
      flexDirection: 'row',
      padding: '6px 0'
    },
    deliverableGrids: {
      display: 'flex',
      alignItems: 'flex-start',
      height: 'fit-content'
    },
    deliverableLogo: {
      height: 15,
      width: 15,
      marginRight: 5
    },
    deliverableSubDetails: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginBottom: 5
    },
    profileDivider: {
      margin: '5px 0'
    },
    addDeliverable: {
      marginRight: 10,
      whiteSpace: 'nowrap'
    },
    expandNotes: {
      color: '#808080',
      marginLeft: 3,
      height: 'fit-content'
    },
    notes: {
      paddingRight: 10
    },
    buttonText: {
      fontWeight: 500,
      fontSize: 14,
      textTransform: 'none',
      color: COLORS.MAROON
    },
    tableHeader: {
      fontSize: 12,
      color: '#6C6C72'
    },
    avatarGroup: {
      fontSize: 10,
      height: 24,
      width: 24
    }
  };
});
