export enum MetricDisplayType {
  DEFAULT = 'DEFAULT',
  PERCENTAGE = 'PERCENTAGE',
  RATIO = 'RATIO'
}

export type MetricDetails = {
  title: string;
  key: string;
  channel?: string;
  displayType?: MetricDisplayType;
  description?: string;
};

export const insightList: Array<MetricDetails> = [
  {
    title: 'Engagement',
    description: 'Last 100 Media',
    key: 'mediaStats.engagement_percentageOfReach.median',
    displayType: MetricDisplayType.PERCENTAGE,
    channel: 'INSTAGRAM'
  },
  {
    title: 'Reach',
    description: 'Last 100 Media',
    key: 'mediaStats.reach.median',
    channel: 'INSTAGRAM'
  },
  {
    title: 'Story Reach',
    description: 'Last 14 Days',
    key: 'storyStats.reach.median',
    channel: 'INSTAGRAM'
  },

  { title: 'Views', key: 'channelStats.viewCount', channel: 'YOUTUBE' },
  {
    title: 'Watch Time',
    key: 'videoStats.estimatedMinutesWatched.median',
    channel: 'YOUTUBE'
  },
  {
    title: 'View Duration',
    key: 'videoStats.averageViewDuration.median',
    channel: 'YOUTUBE'
  },
  {
    title: 'Engagement',
    key: 'videoStats.engagement_percentageOfViews.median',
    channel: 'YOUTUBE'
  },

  {
    title: 'Impressions',
    key: 'pageStats.page_impressions',
    channel: 'FACEBOOK'
  },
  { title: 'Clicks', key: 'pageStats.page_total_actions', channel: 'FACEBOOK' },
  {
    title: 'Reactions',
    key: 'pageStats.page_actions_post_reactionsTotal',
    channel: 'FACEBOOK'
  },
  {
    title: 'Positive Feedback',
    key: 'pageStats.page_positive_feedbackTotal',
    channel: 'FACEBOOK'
  },
  {
    title: 'People Talking',
    key: 'pageStats.post_activity_unique',
    channel: 'FACEBOOK'
  },

  { title: 'Followers', key: 'public.stats.followerCount', channel: 'TIKTOK' },
  {
    title: 'Engagement',
    key: 'tiktokMediaStats.totalEngagement_percentageOfPlays.median',
    displayType: MetricDisplayType.PERCENTAGE,
    channel: 'TIKTOK'
  },
  {
    title: 'Media Plays',
    key: 'tiktokMediaStats.view_count.median',
    channel: 'TIKTOK'
  },
  {
    title: 'Viral Video Ratio',
    key: 'tiktokMediaStats.viralVideoPercentage',
    displayType: MetricDisplayType.RATIO,
    channel: 'TIKTOK'
  }
];
