import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useErrorHandlerHook } from 'hooks/useErrorCatchHook';
import q83API from 'api/q83API';
import { get } from 'lodash';

export const useToken = () => {
  const { axiosHandler } = useErrorHandlerHook();

  const location = useLocation();
  const navigate = useNavigate();

  const { pathname, search } = location;

  const [isAuth, setIsAuth] = useState<boolean | null>(null);
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    if (pathname === '/access') {
      if (search) {
        const searchQuery = new URLSearchParams(search);
        const queryToken = searchQuery.get('token');
        if (queryToken) {
          sessionStorage.setItem('auth-token', queryToken);
          setToken(queryToken);
          setIsAuth(true);
          navigate('/');

          return;
        }
      }
      setIsAuth(false);
    } else if (pathname?.startsWith('/login-referral')) {
      const referralToken = pathname.replace('/login-referral/', '');

      if (referralToken) {
        const referralObject = JSON.parse(atob(decodeURIComponent(referralToken)));
        if (referralObject.bearer) {
          if (referralObject.signedBrowserSessionToken) {
            localStorage.setItem('browser-session-token', referralObject.signedBrowserSessionToken);
          }
          sessionStorage.setItem('auth-token', referralObject.bearer);
          setIsAuth(true);
          setToken(referralObject.bearer);
          if (typeof referralObject.path === 'string') {
            navigate(referralObject.path);
          } else {
            navigate(`/`);
          }
        } else {
          setIsAuth(false);
        }
      } else {
        setIsAuth(false);
      }
    } else if (pathname?.startsWith('/login-token')) {
      const loginToken = pathname.replace('/login-token/', '');

      if (loginToken) {
        q83API
          .post('verify-email', { token: loginToken })
          .then((response) => {
            const userToken = get(response, ['data', 'data', 'token']);
            if (userToken) {
              sessionStorage.setItem('auth-token', userToken);
              setIsAuth(true);
              setToken(userToken);
              navigate('/');
            }
          })
          .catch((ex) => {
            setIsAuth(false);
            axiosHandler(ex);
          });
      } else {
        setIsAuth(false);
      }
    } else if (pathname?.startsWith('/login-admin')) {
      const paramToken = pathname.replace('/login-admin/', '');
      if (paramToken) {
        const referralObject = JSON.parse(atob(decodeURIComponent(paramToken)));
        if (referralObject.token) {
          q83API
            .post(
              'admin/tokenLogin',
              { token: referralObject.token },
              { headers: { Authorization: `Bearer ${referralObject.bearer}` } }
            )
            .then((response) => {
              const userToken = get(response, ['data', 'data', 'token']);
              if (userToken) {
                sessionStorage.setItem('auth-token', userToken);
                setIsAuth(true);
                setToken(userToken);
                navigate('/');
              }
            })
            .catch((ex) => {
              setIsAuth(false);
              axiosHandler(ex);
            });
        } else {
          setIsAuth(false);
        }
      } else {
        setIsAuth(false);
      }
    } else {
      const localToken = sessionStorage.getItem('auth-token');

      if (localToken) {
        setIsAuth(true);
        setToken(localToken);

        return;
      }

      const devToken = process.env.REACT_APP_AUTH_TOKEN;

      if (devToken) {
        setIsAuth(true);
        setToken(devToken);

        return;
      }

      const signedBrowserSessionToken = localStorage.getItem('browser-session-token');

      if (signedBrowserSessionToken) {
        q83API
          .post('login/browser-session', { signedBrowserSessionToken })
          .then((response) => {
            const userToken = get(response, ['data', 'data', 'token']);
            if (userToken) {
              sessionStorage.setItem('auth-token', userToken);
              setIsAuth(true);
              setToken(userToken);
              navigate('/');
            }
          })
          .catch((ex) => {
            localStorage.removeItem('browser-session-token');
            setIsAuth(false);
            axiosHandler(ex);
          });

        return;
      }

      setIsAuth(false);
    }
  }, [setIsAuth, setToken, pathname, search, axiosHandler, navigate]);

  return { isAuth, token };
};
