import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { Link, Grid, Paper, Typography, Button, ButtonBase, CircularProgress } from '@mui/material';
import {
  useGetMyUserQuery,
  useGetConnectedAccountLinkLazyQuery,
  useGetConnectedAccountLazyQuery
} from 'types/generated';
import { useErrorHandlerHook } from 'hooks/useErrorCatchHook';
import { Loader } from 'components/Loader';
import ConnectWithStripeButtonSvg from '../../assets/images/ConnectWithStripeButtonSvg';
import StripeButton from '../../assets/images/connect_with_stripe_blurple.svg';
import StripeButtonHover from '../../assets/images/connect_with_stripe_blurple_hover.svg';
import Modal from '../Modal';
import useSubscribePopupCtx from 'context/useSubscribePopupCtx';

const InvoiceSettings = () => {
  const { classes } = styles();
  const navigate = useNavigate();
  const params: { redirectfrom?: string } = useParams();
  const { redirectfrom } = params;

  const { setSelectedPlanId } = useSubscribePopupCtx();

  const [confirmationMessage, setConfirmationMessage] = useState<string>('');
  const { data: userData, loading: userLoading, error: userError } = useGetMyUserQuery();

  const [getConnectedAccountLink, { loading: connectAccountLinkLoading, error: connectAccountLinkError }] =
    useGetConnectedAccountLinkLazyQuery({
      variables: { returnPath: redirectfrom || '' },
      onCompleted: (data) => {
        const accountLinkUrl = data?.getConnectedAccountLink?.url;
        if (accountLinkUrl) {
          window.location.assign(accountLinkUrl);
        }
      }
    });

  const [
    getConnectedAccount,
    { data: connectAccountData, loading: connectAccountLoading, error: connectAccountError }
  ] = useGetConnectedAccountLazyQuery();

  const connectedType = connectAccountData?.getConnectedAccount?.type;

  const hasSubmittedDetails = connectAccountData?.getConnectedAccount?.details_submitted;

  const dashboardLink =
    connectAccountData?.getConnectedAccount?.expressDashboardLink || 'https://dashboard.stripe.com/login';

  const connectedEmail = connectAccountData?.getConnectedAccount?.email;

  const { apolloHandler } = useErrorHandlerHook();

  useEffect(() => {
    if (redirectfrom === 'campaigns' && hasSubmittedDetails) {
      setConfirmationMessage('Stripe details have been submitted.');
    }
  }, [redirectfrom, hasSubmittedDetails]);

  useEffect(() => {
    if (userError) {
      apolloHandler(userError, `Unable to get current invoice settings`);
    }
  }, [userError, apolloHandler]);

  useEffect(() => {
    if (connectAccountLinkError) {
      apolloHandler(connectAccountLinkError, `Unable to create a Stripe Account Link`);
    }
  }, [connectAccountLinkError, apolloHandler]);

  useEffect(() => {
    if (connectAccountError) {
      apolloHandler(connectAccountError, `Unable to get Stripe Account information`);
    }
  }, [connectAccountError, apolloHandler]);

  const user = userData?.myUser;
  const stripeAccountId = user?.stripeAccountId;

  useEffect(() => {
    if (stripeAccountId) {
      getConnectedAccount();
    }
  }, [stripeAccountId, getConnectedAccount]);

  const renderConfirmationAlert = () => {
    return (
      <Modal
        isOpen={Boolean(confirmationMessage)}
        onToggle={() => {
          setConfirmationMessage('');
        }}
        onConfirm={() => {
          setConfirmationMessage('');
          if (redirectfrom === 'campaigns') navigate('/campaigns');
        }}
        confirmLabel={redirectfrom === 'campaigns' ? 'Return to Campaigns' : 'Close'}
        hideCancelButton={true}
        heading={'Invoice settings'}
      >
        <div style={{ margin: '10px 0 15px' }}>{confirmationMessage}</div>
      </Modal>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper>
          {Boolean(userLoading) ? (
            <Grid item xs={12} className={classes.container}>
              <Loader />
            </Grid>
          ) : (
            <Grid item container xs={12} className={classes.container}>
              {hasSubmittedDetails ? (
                <>
                  <Grid item xs={12} style={{ marginBottom: 20 }}>
                    <Typography style={{ fontSize: 14 }}>
                      {`For all payout receipts and account information please login to stripe${
                        connectedEmail ? ` using ${connectedEmail}.` : ':'
                      }`}
                    </Typography>
                  </Grid>
                  <Button variant='contained' color='primary' onClick={() => window.open(dashboardLink, '_blank')}>
                    Login to Stripe
                  </Button>
                </>
              ) : (
                <>
                  {Boolean(connectAccountLinkLoading || connectAccountLoading) ? (
                    <ButtonBase
                      style={{
                        background: '#635bff',
                        height: 40,
                        textDecoration: 'none',
                        width: 180,
                        borderRadius: 4
                      }}
                    >
                      <CircularProgress style={{ color: '#fff', width: 30, height: 30 }} />
                    </ButtonBase>
                  ) : (
                    <>
                      <Grid item xs={12} style={{ marginBottom: 20 }}>
                        {stripeAccountId ? (
                          <Typography style={{ fontSize: 14 }}>
                            Stripe account is missing important details, please press the below button to complete
                            account setup
                          </Typography>
                        ) : (
                          <Typography style={{ fontSize: 14 }}>
                            Create and send invoices on the go and get paid faster! Connect with Stripe to access real
                            time payments. Have your invoices paid globally through a trusted, secure portal.
                            <div style={{ marginTop: 10 }}>
                              Invoice payments via Kitly will be visible within your accounting software.
                            </div>
                          </Typography>
                        )}
                        <Typography>
                          Stripe invoice fees are dependant on your Kitly subscription, between 1% & 3% , see{' '}
                          <Link
                            component='a'
                            onClick={() => setSelectedPlanId('kitly-usd-2')}
                            style={{ cursor: 'pointer' }}
                            underline='hover'
                          >
                            subscriptions
                          </Link>{' '}
                          to save on your invoice fees.
                        </Typography>
                      </Grid>
                      <ButtonBase
                        onClick={() =>
                          connectedType === 'express' ? window.open(dashboardLink, '_blank') : getConnectedAccountLink()
                        }
                        className={classes.stripeButton}
                        style={{ height: 40, width: 180 }}
                      >
                        <ConnectWithStripeButtonSvg />
                      </ButtonBase>
                    </>
                  )}
                </>
              )}
            </Grid>
          )}
        </Paper>
      </Grid>
      {renderConfirmationAlert()}
    </Grid>
  );
};

const styles = makeStyles()((theme) => ({
  container: {
    paddingTop: 20,
    paddingLeft: 30,
    paddingBottom: 20,
    borderBottom: `1px solid ${theme.palette.background.default}`
  },
  stripeButton: {
    height: 38,
    width: 180,
    background: `url(${StripeButton}) no-repeat center center`,
    '&:hover': {
      background: `url(${StripeButtonHover}) no-repeat center center`
    }
  }
}));

export default InvoiceSettings;
