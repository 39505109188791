import { MediaKitTheme } from '../types/generated';

type ThemeConfig = {
  primary: string;
};

export const availableThemes: { [key in MediaKitTheme]: ThemeConfig } = {
  DEFAULT: { primary: '#FBCDDF' },
  PINK: { primary: '#CC215D' },
  GRAY: { primary: '#333333' },
  AQUA: { primary: '#8BD7E2' },
  TEAL: { primary: '#C2EDE8' },
  LIGHTSALMON: { primary: '#F5CDBB' },
  LIGHTGRAY: { primary: '#909090' },
  LIGHTORANGE: { primary: '#FAD147' },
  MEDIUMPURPLE: { primary: '#9090D1' },
  LINEN: { primary: '#FFEEE8' },
  SNOW: { primary: '#F8F8F8' },
  SALMON: { primary: '#FF8E6C' },
  DARKBLUE: { primary: '#000080' },
  PALEBLUE: { primary: '#84C6FF' },
  TURQUOISE: { primary: '#60D8CA' },
  KB_PURPLE: { primary: '#5C5CB9' }
};
