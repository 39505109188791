import { useState, useMemo } from 'react';
import { Box, Button, Grid, Paper, Tooltip, Typography, Avatar, ButtonBase, GridSize } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import EditIcon from 'assets/icons/EditIcon.svg';
import 'components/LineClamp.css';
import { HorizontalInviteBanner } from 'components/ReferAndEarn';
import { Brand, isContact, useBrandRequest } from 'hooks/useBrandRequest';
import { useDialog } from 'hooks/useDialog';
import { formatName, getInitials } from 'lib/formatStrings';
import { filter } from 'lodash';
import {
  Contact,
  Ambassador,
  AmbassadorStatus,
  GetContactsDocument,
  useConnectContactMutation,
  useUpdateAmbassadorMutation
} from 'types/generated';
import { AddContactButton } from './AddContactButton';
import { BrandCreation } from './BrandCreation';
import { NotOnKBDialog } from './NotOnKBDialog';
import Modal from 'components/Modal';

interface Props {
  setSelectedBrand: (arg: any) => void;
  displayArchived?: boolean;
}

export const BrandList = (props: Props) => {
  const { invites, refetch } = useBrandRequest();
  const { bind, onOpen } = useDialog();
  const { setSelectedBrand, displayArchived } = props;
  const { classes } = useStyles();
  const [connectContact] = useConnectContactMutation();
  const [KBInviteBrand, setKBInviteBrand] = useState<undefined | Contact>(undefined);
  const [dismissBrandId, setDismissBrandId] = useState<string>();
  const [updateAmbassador] = useUpdateAmbassadorMutation({
    refetchQueries: [{ query: GetContactsDocument }]
  });

  const contacts = useMemo(() => {
    if (displayArchived) {
      return filter(invites, (o) => {
        if (!isContact(o) && o.isArchive) {
          return true;
        }
        return false;
      });
    }
    return filter(invites, (o) => {
      if (isContact(o) || !o.isArchive) {
        return true;
      }
      return false;
    });
  }, [displayArchived, invites]);

  const toggleDismissModal = () => {
    setDismissBrandId(undefined);
  };

  const DismissConnectionModal = ({ brandId, companyName }: { brandId: string; companyName: string }) => (
    <Modal
      isOpen={Boolean(dismissBrandId)}
      onToggle={toggleDismissModal}
      onConfirm={() => {
        if (dismissBrandId && dismissBrandId === brandId) {
          updateAmbassador({ variables: { inputValues: { ids: [dismissBrandId], updatedStatus: false } } });
          toggleDismissModal();
        }
      }}
      maxWidth={'xs'}
      heading={'Dismiss brand'}
    >
      <div style={{ margin: '15px 0' }}>
        <Typography>Are you sure you want to dismiss {companyName || 'this brand'}?</Typography>
      </div>
    </Modal>
  );

  const renderButtons = (brand: Brand) => {
    let primaryButtonText;
    let secondaryButtonText;
    let isPrimaryButtonDisabled = false;
    let onPrimaryButtonSubmit = () => {};
    let onSecondaryButtonSubmit = () => {};

    if (brand._id) {
      if (isContact(brand)) {
        primaryButtonText = 'Connect';
        onPrimaryButtonSubmit = () =>
          connectContact({ variables: { id: brand._id! } }).then((res) => {
            if (!res?.data?.connectContact?.isKBUserExisted) {
              setKBInviteBrand(brand);
              onOpen();
            } else {
              refetch();
            }
          });
      } else {
        switch (brand.status) {
          case AmbassadorStatus.Accepted:
            primaryButtonText = 'Connected';
            secondaryButtonText = 'Disconnect';
            isPrimaryButtonDisabled = true;
            onSecondaryButtonSubmit = () =>
              updateAmbassador({ variables: { inputValues: { ids: [brand._id!], updatedStatus: false } } });
            break;
          case AmbassadorStatus.PendingInviteFromInfluencer:
            primaryButtonText = 'Pending';
            secondaryButtonText = 'Cancel';
            isPrimaryButtonDisabled = true;
            onSecondaryButtonSubmit = () =>
              updateAmbassador({ variables: { inputValues: { ids: [brand._id!], updatedStatus: false } } });
            break;
          default:
            primaryButtonText = 'Connect';
            if (brand.status !== AmbassadorStatus.DeclinedInviteFromBrand) {
              secondaryButtonText = 'Dismiss';
            }
            onPrimaryButtonSubmit = () =>
              updateAmbassador({ variables: { inputValues: { ids: [brand._id!], updatedStatus: true } } });
            onSecondaryButtonSubmit = () => setDismissBrandId(brand._id);
            break;
        }
      }
    }

    return (
      <Box mt='21px'>
        <Grid container justifyContent='center'>
          <Button variant='outlined' disabled={isPrimaryButtonDisabled} onClick={onPrimaryButtonSubmit}>
            {primaryButtonText}
          </Button>
          {secondaryButtonText && <Button onClick={onSecondaryButtonSubmit}>{secondaryButtonText}</Button>}
        </Grid>
      </Box>
    );
  };

  const renderBrandCard = (o: Brand, xs: GridSize) => {
    let _id: string | undefined;
    let avatar: string;
    let companyName: string;
    let children: Contact['children'];
    let brand: Contact | Ambassador['brand'];

    if (isContact(o)) {
      companyName = o.companyName || '';
      avatar = o.avatar || '';
      children = o.children || [];
      brand = o;
      _id = o?._id || undefined;
    } else {
      companyName = o?.contact?.companyName || o?.brand?.companyName || '';
      avatar = o?.contact?.avatar || o?.brand?.avatar || '';
      children = o?.contact?.children || [];
      brand = o?.contact || o?.brand;
      _id = o?.contact?._id || o?.brand?._id || '';
    }

    const companyNameInitials = getInitials({ firstInput: companyName, isSingleInitial: true });

    return (
      <Grid item xs={xs} key={`brand-${_id}`} className={classes.brandContainer}>
        <Paper className={classes.brandCard}>
          <Grid justifyContent='flex-end' container>
            <BrandCreation editableBrand={brand}>
              <img src={EditIcon} alt='Edit' className={classes.editIcon} />
            </BrandCreation>
          </Grid>
          <ButtonBase
            onClick={() => {
              setSelectedBrand(isContact(o) ? o : o.contact || undefined);
            }}
            className={classes.brandButton}
          >
            <Avatar src={avatar} className={classes.avatar}>
              {companyNameInitials}
            </Avatar>
            <Typography variant='h5' className={`${classes.companyName} line-clamp clamp-1`}>
              {companyName}
            </Typography>
          </ButtonBase>
          <div className={classes.contactsContainer}>
            <AddContactButton brandId={_id} />
            {children?.map((child, i) => {
              const fullName = formatName({ firstName: child?.firstName || '', lastName: child?.lastName || '' });
              const contactInitials = getInitials({
                firstInput: child?.firstName || '',
                secondInput: child?.lastName || '',
                isSingleInitial: true
              });
              return (
                <Tooltip key={`contact-${child?._id || i}`} arrow={true} title={fullName || ''}>
                  <Avatar src={child?.avatar || ''} className={classes.contactAvatars}>
                    {contactInitials}
                  </Avatar>
                </Tooltip>
              );
            })}
          </div>
          {renderButtons(o)}
          {dismissBrandId === o._id && <DismissConnectionModal brandId={o._id} companyName={companyName} />}
        </Paper>
      </Grid>
    );
  };

  const initialBrandList = contacts.slice(0, 2);
  const remainingBrandList = contacts.slice(2, contacts.length);

  return (
    <Grid container>
      <Grid container item xs={6}>
        {initialBrandList.map((o) => renderBrandCard(o, 6))}
      </Grid>
      <Grid xs={6} item>
        <Box pt='10px'>
          <HorizontalInviteBanner style={{ height: '288px', marginLeft: 'auto', marginRight: 10 }} />
        </Box>
      </Grid>
      <Grid container xs={12} item>
        {remainingBrandList.map((o) => renderBrandCard(o, 3))}
      </Grid>
      {KBInviteBrand && <NotOnKBDialog {...bind} contact={KBInviteBrand} />}
    </Grid>
  );
};

const useStyles = makeStyles()((theme) => {
  return {
    brandContainer: {
      padding: 10
    },
    brandCard: {
      padding: '10px 20px',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      minHeight: 288
    },
    brandButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      width: '100%'
    },
    editIcon: {
      fontSize: 20
    },
    avatar: {
      width: 65,
      height: 65,
      marginBottom: 10,
      fontSize: 16,
      fontWeight: 500,
      backgroundColor: '#F7E690'
    },
    companyName: {
      fontWeight: 200,
      marginBottom: 10
    },
    contactsContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 10
    },
    contactAvatars: {
      height: 32,
      width: 32,
      marginRight: '-5px',
      backgroundColor: '#FAD416',
      fontSize: 14,
      fontWeight: 600,
      position: 'relative',
      left: -8
    }
  };
});
