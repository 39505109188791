import React, { ReactNode } from 'react';

import DashboardIcon from 'assets/components/DashboardIcon';
import CollabsIcon from 'assets/components/CollabsIcon';
import InviteIcon from 'assets/components/InviteIcon';
import BrandsIcon from 'assets/components/BrandsIcon';
import ReportingIcon from 'assets/components/ReportingIcon';
import SettingsIcon from 'assets/components/SettingsIcon';
import NotesIcon from 'assets/components/NotesIcon';
import MediaKitIcon from 'assets/components/MediaKitIcon';
import FinanceIcon from 'assets/components/FinanceIcon';
import TalentSearchIcon from 'assets/components/TalentSearchIcon';

export enum side_draw_type_enum {
  SectionTitle = 'SECTION_TITLE',
  Label = 'Label'
}
export type sideDrawLabel = {
  type: side_draw_type_enum;
  label: string;
  iconPath?: string;
  url?: string;
  iconComponent?: ReactNode;
  usageKey?: string;
};

export const brandsLabel = 'My Connections';

export const sideDrawItems: sideDrawLabel[] = [
  {
    type: side_draw_type_enum.Label,
    label: 'Dashboard',
    url: '/',
    iconComponent: <DashboardIcon />
  },
  {
    type: side_draw_type_enum.SectionTitle,
    label: 'Creator Network'
  },
  {
    type: side_draw_type_enum.Label,
    label: 'Account Categories',
    url: '/account-categories',
    iconComponent: <TalentSearchIcon />
  },
  {
    type: side_draw_type_enum.SectionTitle,
    label: 'Manage'
  },
  {
    type: side_draw_type_enum.Label,
    label: 'Account Performance',
    url: '/account-performance',
    iconComponent: <ReportingIcon />,
    usageKey: 'profilesUsage'
  },
  {
    type: side_draw_type_enum.Label,
    label: 'Media Kits',
    url: '/media-kits',
    iconComponent: <MediaKitIcon />,
    usageKey: 'mediaKitsUsage'
  },
  {
    type: side_draw_type_enum.Label,
    label: 'Reports',
    url: '/reports',
    iconComponent: <NotesIcon />,
    usageKey: 'reportsUsage'
  },
  {
    type: side_draw_type_enum.Label,
    label: 'Campaigns',
    url: '/campaigns',
    iconComponent: <CollabsIcon />,
    usageKey: 'collaborationsUsage'
  },
  {
    type: side_draw_type_enum.Label,
    label: 'Finance',
    url: '/finance',
    iconComponent: <FinanceIcon />
  },
  {
    type: side_draw_type_enum.SectionTitle,
    label: 'Brands'
  },
  {
    type: side_draw_type_enum.Label,
    label: brandsLabel,
    url: '/my-brands',
    iconComponent: <BrandsIcon />
  },
  {
    type: side_draw_type_enum.Label,
    label: 'Invite',
    url: '/my-brands/invite',
    iconComponent: <InviteIcon />
  },
  {
    type: side_draw_type_enum.SectionTitle,
    label: 'Settings'
  },
  {
    type: side_draw_type_enum.Label,
    label: 'Settings',
    url: '/settings',
    iconComponent: <SettingsIcon />
  }
];
