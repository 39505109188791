import { useState, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { makeStyles } from 'tss-react/mui';
import CircularProgress from '@mui/material/CircularProgress';
import { filter, find, isEmpty, orderBy } from 'lodash';
import { Deliverable, useGetCollaborationListQuery, CollaborationStatus } from 'types/generated';
import { CollabList } from './CollabList';
import { DeliverableOverview } from './DeliverableOverview';
import { formatError } from 'lib/formatStrings';

interface ICollabsManagement {
  status?: CollaborationStatus;
}

const CollabsManagement = ({ status }: ICollabsManagement) => {
  const { classes } = useStyles();

  const { loading, data, error, refetch } = useGetCollaborationListQuery({ variables: { status } });
  const collaborationList = useMemo(() => data?.getCollaborationList || [], [data]);

  const orderedCollaborationList = orderBy(collaborationList, ['created_at'], ['desc']);
  const [selectedCollabId, setSelectedCollabId] = useState('');

  const Loader = () => (
    <Grid item xs={12} className={`${classes.centred} ${classes.loaderContainer}`}>
      <CircularProgress />
    </Grid>
  );

  const ErrorMessage = () => {
    if (!error) return null;
    return (
      <Grid item xs={12} className={`${classes.centred} ${classes.loaderContainer}`}>
        {formatError(error)}
      </Grid>
    );
  };

  const CollabFilter = () => {
    const title = 'All Campaigns';

    return (
      <Select
        variant='standard'
        value={selectedCollabId}
        onChange={(e: any) => setSelectedCollabId(e.target.value)}
        displayEmpty={true}
        renderValue={(selected: any) => {
          if (selected === '') return title;
          const collabName = find(collaborationList, { _id: selected })?.name || `#${selected}`;
          return collabName;
        }}
        disableUnderline
      >
        <MenuItem value=''>{title}</MenuItem>
        {orderedCollaborationList.map((o) =>
          o?._id ? (
            <MenuItem key={`filter-${o._id}`} value={o._id}>
              {o.name}
            </MenuItem>
          ) : null
        )}
      </Select>
    );
  };

  const shouldShowFilter = Boolean(collaborationList.length > 1);
  const selectedCollab = find(collaborationList, { _id: selectedCollabId });

  const deliverableList = useMemo(() => {
    let deliverableList =
      collaborationList?.reduce<Deliverable[]>((acc, curr) => {
        if (!isEmpty(curr?.deliverables)) acc = acc.concat(curr.deliverables!);
        return acc;
      }, []) || [];
    if (selectedCollabId) {
      deliverableList = filter(deliverableList, (o) => {
        return o?.collaboration?._id === selectedCollabId;
      });
    }
    deliverableList = orderBy(deliverableList, ['endDate', 'endTime'], ['asc', 'asc']);
    return deliverableList;
  }, [collaborationList, selectedCollabId]);

  return (
    <div style={{ marginTop: 30 }}>
      {loading ? (
        <Loader />
      ) : (
        <Grid container spacing={6}>
          <ErrorMessage />
          <Grid item xs={7}>
            {shouldShowFilter && <CollabFilter />}
            <CollabList collaborationList={orderedCollaborationList} refetch={refetch} />
          </Grid>
          <Grid container item xs={5}>
            <DeliverableOverview deliverableList={deliverableList} selectedCollab={selectedCollab} />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default CollabsManagement;

const useStyles = makeStyles()((theme) => {
  return {
    subtitle: {
      fontWeight: 500,
      marginBottom: 12
    },
    centred: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    loaderContainer: {
      height: '85vh'
    },
    header: {
      marginBottom: 1
    },
    button: {
      margin: 5
    }
  };
});
