import { useMemo, useState, useEffect, Dispatch, SetStateAction } from 'react';

import {
  Container,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  Typography,
  Table,
  Chip,
  Badge,
  Box,
  Avatar,
  lighten,
  Tooltip,
  TextField,
  ButtonBase,
  Autocomplete
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Add from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SelectChannel from '../../../components/Fields/SelectChannel';
import CategoriesEditMenu from './CategoriesEditMenu';
import {
  useGetProfilesListQuery,
  GetProfilesListDocument,
  Profile,
  SocialChannel,
  useUpdateProfileMutation,
  MediaKitConfig
} from '../../../types/generated';
import { useErrorHandlerHook } from 'hooks/useErrorCatchHook';
import { channelIcons } from '../../../lib/constants';
import CategoryEditModal from './CategoryEditModal';
import { availableCategories } from 'lib/categories';
import AppConfig from '../../../config/appConfig';

import { compact, find, isEmpty, intersection } from 'lodash';
import { Loader } from '../../../components/Loader';
import { formatNumber } from 'lib/formatNumber';
import { NewMediaKitButton } from '../../../components/pages/MediaKitsPage/NewMediaKitButton';
import MediaKitsIcon from 'assets/icons/MediaKitsIcon';

interface TalentNetworkTableRowProps {
  key: string;
  profile: Profile;
  selectedTalent?: Profile;
  setSelectedTalent: Dispatch<SetStateAction<Profile | undefined>>;
  selectedChannel?: SocialChannel;
}

const TalentNetworkTable = () => {
  const { classes } = useStyles();
  const { apolloHandler } = useErrorHandlerHook();
  const [selectedChannel, setSelectedChannel] = useState<SocialChannel>();

  const [selectedTalent, setSelectedTalent] = useState<Profile>();

  const selectedChannelFollowerNumberHeading = useMemo(() => {
    let heading = 'Followers';
    if (selectedChannel === SocialChannel.Facebook) heading = 'Fans';
    if (selectedChannel === SocialChannel.Youtube) heading = 'Subscribers';
    return heading;
  }, [selectedChannel]);

  const selectedChannelHighlightedMetricHeading = useMemo(() => {
    let heading = 'True Reach';
    if (selectedChannel === SocialChannel.Facebook) heading = 'Median Unique Post Impressions';
    if (selectedChannel === SocialChannel.Youtube) heading = 'Median Views';
    return heading;
  }, [selectedChannel]);

  const {
    data: profilesData,
    loading: profilesLoading,
    error: profilesError
  } = useGetProfilesListQuery({
    fetchPolicy: 'network-only'
  });

  const profileList = useMemo(
    () => (profilesData?.getProfiles ? compact(profilesData.getProfiles) : []),
    [profilesData]
  );

  const instagramAmbassadors = useMemo(
    () => (profileList || []).filter((o: Profile) => Boolean(o?.profileType === SocialChannel.Instagram)),
    [profileList]
  );
  const facebookAmbassadors = useMemo(
    () => (profileList || []).filter((o: Profile) => Boolean(o?.profileType === SocialChannel.Facebook)),
    [profileList]
  );
  const youtubeAmbassadors = useMemo(
    () => (profileList || []).filter((o: Profile) => Boolean(o?.profileType === SocialChannel.Youtube)),
    [profileList]
  );

  const tiktokAmbassadors = useMemo(
    () => (profileList || []).filter((o: Profile) => Boolean(o?.profileType === SocialChannel.Tiktok)),
    [profileList]
  );

  const ambassadorsToDisplay = useMemo(() => {
    if (selectedChannel === SocialChannel.Instagram) {
      return instagramAmbassadors;
    }
    if (selectedChannel === SocialChannel.Facebook) {
      return facebookAmbassadors;
    }
    if (selectedChannel === SocialChannel.Youtube) {
      return youtubeAmbassadors;
    }
    if (selectedChannel === SocialChannel.Tiktok) {
      return tiktokAmbassadors;
    }
    return [];
  }, [selectedChannel, instagramAmbassadors, facebookAmbassadors, youtubeAmbassadors, tiktokAmbassadors]);

  const channelsToShow = useMemo(() => {
    let channelsWithAmbassadors: SocialChannel[] = [];
    if (instagramAmbassadors?.length) channelsWithAmbassadors.push(SocialChannel.Instagram);
    if (facebookAmbassadors?.length) channelsWithAmbassadors.push(SocialChannel.Facebook);
    if (youtubeAmbassadors?.length) channelsWithAmbassadors.push(SocialChannel.Youtube);
    if (tiktokAmbassadors?.length) channelsWithAmbassadors.push(SocialChannel.Tiktok);
    if (channelsWithAmbassadors.length) {
      return channelsWithAmbassadors;
    }
    return [SocialChannel.Instagram, SocialChannel.Facebook, SocialChannel.Youtube];
  }, [instagramAmbassadors, facebookAmbassadors, youtubeAmbassadors, tiktokAmbassadors]);

  useEffect(() => {
    if (!selectedChannel && !isEmpty(profileList)) {
      const fb = facebookAmbassadors?.length || 0;
      const ig = instagramAmbassadors?.length || 0;
      const yt = youtubeAmbassadors?.length || 0;

      if (ig) {
        setSelectedChannel(SocialChannel.Instagram);
      } else if (fb) {
        setSelectedChannel(SocialChannel.Facebook);
      } else if (yt) {
        setSelectedChannel(SocialChannel.Youtube);
      } else {
        setSelectedChannel(SocialChannel.Instagram);
      }
    }
  }, [setSelectedChannel, facebookAmbassadors, instagramAmbassadors, youtubeAmbassadors, profileList, selectedChannel]);

  useEffect(() => {
    if (profilesError) {
      apolloHandler(profilesError);
    }
  }, [profilesError, apolloHandler]);

  const toggleCreateModal = () => {
    setSelectedTalent(selectedTalent ? undefined : selectedTalent);
  };

  return (
    <Container>
      <Grid item container justifyContent='space-between' style={{ marginBottom: 20 }}>
        <Grid item>
          {!profilesLoading && (
            <Typography style={{ fontSize: 28 }}>{`${ambassadorsToDisplay.length} Total Talent Listed`}</Typography>
          )}
        </Grid>
        <Grid item>
          <SelectChannel
            channels={selectedChannel ? [selectedChannel] : []}
            setChannels={setSelectedChannel}
            availableChannels={channelsToShow}
            buttonPadding={10}
          />
        </Grid>
      </Grid>
      {profilesLoading ? (
        <Loader containerHeight={300} />
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.shadedColumn}>
                  <Typography className={classes.columnHeading}>Connected Accounts</Typography>
                </TableCell>
                <TableCell className={classes.shadedColumn}>
                  <Typography className={classes.columnHeading}>{selectedChannelFollowerNumberHeading}</Typography>
                </TableCell>
                <TableCell className={classes.shadedColumn}>
                  <Typography className={classes.columnHeading}>{selectedChannelHighlightedMetricHeading}</Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.columnHeading}>Categories (Choose up to 3)</Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.columnHeading}>Media Kit</Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.columnHeading}></Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ambassadorsToDisplay.map((o, i) => {
                if (!o) return null;
                return (
                  <TalentNetworkTableRow
                    key={`talent-row-${i}`}
                    profile={o}
                    selectedTalent={selectedTalent}
                    setSelectedTalent={setSelectedTalent}
                    selectedChannel={selectedChannel}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {selectedTalent && <CategoryEditModal editableTalent={selectedTalent} toggleModal={toggleCreateModal} />}
    </Container>
  );
};

const noSelectedMediaKit: MediaKitConfig = {
  _id: 'None selected',
  title: ''
};

const TalentNetworkTableRow = ({
  profile,
  selectedTalent,
  setSelectedTalent,
  selectedChannel
}: TalentNetworkTableRowProps) => {
  const { classes } = useStyles();
  const { classes: badgeClasses } = badgeStyles();
  const { apolloHandler } = useErrorHandlerHook();
  const availableCategoryValues = availableCategories.map((c) => c.value);

  const [selectedMediaKitId, setSelectedMediaKitId] = useState<string>(
    profile?.selectedMediaKitId ? profile?.selectedMediaKitId : 'None selected'
  );

  const [updateProfile, { error: updateSelectedMediaKitError }] = useUpdateProfileMutation({
    refetchQueries: [GetProfilesListDocument]
  });

  const mediaKits = useMemo(() => {
    return profile?.mediaKitConfigs
      ? compact(profile?.mediaKitConfigs.concat(noSelectedMediaKit))
      : [noSelectedMediaKit];
  }, [profile]);

  const selectedMediaKitConfig = useMemo(() => {
    return find(mediaKits, { _id: selectedMediaKitId }) || noSelectedMediaKit;
  }, [selectedMediaKitId, mediaKits]);

  const selectedChannelHighlightedMetric = useMemo(() => {
    let highlightedMetric = 0;
    if (selectedChannel === SocialChannel.Instagram) highlightedMetric = profile.mediaStats?.reach?.median || 0;
    if (selectedChannel === SocialChannel.Facebook)
      highlightedMetric = profile.postStats?.post_impressions_unique?.median || 0;
    if (selectedChannel === SocialChannel.Youtube) highlightedMetric = profile.videoStats?.views?.median || 0;
    return highlightedMetric;
  }, [selectedChannel, profile]);

  useEffect(() => {
    if (updateSelectedMediaKitError) {
      apolloHandler(updateSelectedMediaKitError);
    }
  }, [updateSelectedMediaKitError, apolloHandler]);

  // Only want to run this effect when the selectedMediaKitId is changed
  // eslint-disable-next-line
  useEffect(() => handleMediaKitSelection(), [selectedMediaKitId]);

  const handleMediaKitSelection = () => {
    if (profile?._id && selectedMediaKitId) {
      updateProfile({
        variables: {
          profileId: profile._id,
          updates: {
            selectedMediaKitId: selectedMediaKitId !== 'None selected' ? selectedMediaKitId : null
          }
        }
      });
    }
  };

  const handleViewMediaKit = (id: string, isDraft?: boolean): void => {
    window.open(`${AppConfig.MEDIAKIT_URL}/#/${isDraft ? 'preview' : 'view'}/${id}`, '_blank');
  };

  const followerCount = profile?.followerCount || 0;
  const talentCategories = intersection(availableCategoryValues, profile.profileCategory || []);

  return (
    <TableRow>
      <TableCell className={classes.shadedColumn}>
        <Grid item container xs={12} wrap='nowrap' alignItems='center'>
          <Grid item xs={3}>
            <Badge
              classes={badgeClasses}
              overlap='circular'
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              badgeContent={
                <Box className={classes.badgeIcon}>
                  {profile?.profileType === SocialChannel.Instagram && (
                    <img src={channelIcons.instagram} alt='instagram' className={classes.badgeImage} />
                  )}
                  {profile?.profileType === SocialChannel.Youtube && (
                    <img src={channelIcons.youtube} alt='youtube' className={classes.badgeImage} />
                  )}
                  {profile?.profileType === SocialChannel.Facebook && (
                    <img src={channelIcons.facebook} alt='facebook' className={classes.badgeImage} />
                  )}
                  {profile?.profileType === SocialChannel.Tiktok && (
                    <img src={channelIcons.tiktok} alt='tiktok' className={classes.badgeImage} />
                  )}
                </Box>
              }
            >
              <Avatar className={classes.badgeAvatar} src={profile?.profile_picture_url || undefined}>
                {profile?.displayName}
              </Avatar>
            </Badge>
          </Grid>
          <Grid item container xs={9}>
            <Grid item xs={12}>
              <Typography>{profile.displayName}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell className={classes.shadedColumn}>
        <Typography>{formatNumber(followerCount)}</Typography>
      </TableCell>
      <TableCell className={classes.shadedColumn}>
        <Typography>{formatNumber(selectedChannelHighlightedMetric)}</Typography>
      </TableCell>
      <TableCell>
        {talentCategories.length ? (
          talentCategories.map((c, i) => {
            const categoryInfo = find(availableCategories, (category) => category.value === c);
            if (!categoryInfo) return null;

            return (
              <Chip
                key={`category-chip-${i}`}
                className={classes.categoryChip}
                classes={{ label: classes.categoryChipLabel }}
                avatar={<img src={categoryInfo.icon} alt='' style={{ height: 16, width: 16 }} />}
                label={categoryInfo.label}
                onClick={() => setSelectedTalent(profile!)}
              />
            );
          })
        ) : (
          <Chip
            key={`add-category-chip-`}
            className={classes.categoryChip}
            classes={{ label: classes.addCategoryChip }}
            label={<Add />}
            onClick={() => setSelectedTalent(profile!)}
          />
        )}
      </TableCell>
      <TableCell style={{ paddingRight: 20 }}>
        {mediaKits.length ? (
          <Autocomplete
            fullWidth
            value={selectedMediaKitConfig}
            renderInput={(params) => {
              return <TextField {...params} variant='standard' />;
            }}
            renderTags={(tagValue) => {
              return tagValue.map((option, index) => {
                return option.title || 'Untitled media kit';
              });
            }}
            options={mediaKits}
            getOptionLabel={(option: MediaKitConfig) => (option ? `${option?.title}` : 'Untitled media kit')}
            filterOptions={(options: MediaKitConfig[]) => options.filter((option) => option._id !== 'None selected')}
            renderOption={(props, option: MediaKitConfig) => {
              return (
                <li {...props}>
                  <Grid container justifyContent='space-between' wrap='nowrap'>
                    {option?.title || 'Untitled media kit'}
                    <Tooltip title='View media kit'>
                      <ButtonBase onClick={() => handleViewMediaKit(option._id)}>
                        <MediaKitsIcon size={24} />
                      </ButtonBase>
                    </Tooltip>
                  </Grid>
                </li>
              );
            }}
            popupIcon={<ExpandMoreIcon style={{ fontSize: 20 }} />}
            onChange={(e, value, reason) => {
              setSelectedMediaKitId(reason === 'clear' ? 'None selected' : value?._id);
              handleMediaKitSelection();
            }}
          />
        ) : (
          <NewMediaKitButton justifyContent={'flex-start'} alignItems={'flex-start'} />
        )}
      </TableCell>
      <TableCell style={{ paddingRight: 10 }}>
        <Typography>
          <CategoriesEditMenu talent={profile} selectedTalent={selectedTalent} setSelectedTalent={setSelectedTalent} />
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default TalentNetworkTable;

const useStyles = makeStyles()((theme) => ({
  shadedColumn: {
    backgroundColor: 'rgba(92,92,185, 0.04)'
  },
  columnHeading: {
    fontSize: 12
  },
  addCategoryChip: {
    marginTop: 4,
    padding: '0 4px'
  },
  categoryChip: {
    backgroundColor: '#FFF',
    border: `0.5px solid ${theme.palette.primary.main}`,
    marginRight: 5
  },
  categoryChipLabel: {
    fontSize: 14
  },
  badgeIcon: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    padding: 3,
    border: `1px solid ${theme.palette.background.default}`,
    backgroundColor: '#fff'
  },
  badgeImage: {
    width: 12,
    height: 12
  },
  badgeAvatar: {
    width: 28,
    height: 28,
    marginRight: 12,
    marginLeft: 10,
    border: '1px solid #BFBFF3',
    boxShadow: `0 0 4px ${lighten(theme.palette.primary.main, 0.3)}`
  }
}));

const badgeStyles = makeStyles()({
  root: {
    width: 30,
    height: 30
  },
  badge: {
    left: 15,
    padding: 0
  }
});
