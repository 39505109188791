import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React from 'react';

interface IStatusLabel {
  link: any;
}
const StatusLabel: React.FC<IStatusLabel> = (props) => {
  const { link } = props;
  const { classes } = useClasses();
  const labelType = () => {
    if (link?.isExpired) return '#c0dbff';
    if (link?.isOpened) return '#ffe789';
    return '#c3eee9';
  };
  const labelText = () => {
    if (link?.isExpired) return 'expired';
    if (link?.isOpened) return 'opened';
    return 'sent';
  };

  const label_with_status = (
    <>
      {link ? (
        <Box className={classes.labelWrapper} style={{ backgroundColor: labelType() }}>
          {labelText().toUpperCase()}
        </Box>
      ) : null}
    </>
  );

  return <>{link?.length <= 0 ? null : label_with_status}</>;
};

const useClasses = makeStyles()((theme) => ({
  labelWrapper: {
    display: 'inline-block',
    width: '58px',
    height: '14px',
    fontWeight: 'bold',
    lineHeight: '14px',
    textAlign: 'center',
    borderRadius: '5px',
    fontSize: '10px'
  }
}));

export default StatusLabel;
