import React from 'react';
import { useTheme } from '@mui/material';

const MediaKitsIcon = ({ fill, size }: { fill?: string; size?: number }) => {
  const theme = useTheme();

  const iconSize = size || 24;

  const fillColour = fill || theme.palette.primary.main;
  return (
    <svg
      width={`${iconSize}px`}
      height={`${iconSize}px`}
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>Media Kits Icon</title>
      <g
        id='12.-Talent-Search-'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <g id='Talent-network-' transform='translate(-486.000000, -670.000000)' stroke={fillColour}>
          <g id='saved-items' transform='translate(487.000000, 671.000000)'>
            <path
              d='M20,0 C21.1045695,0 22,0.8954305 22,2 L22,20 C22,21.1045695 21.1045695,22 20,22 L2,22 C0.8954305,22 0,21.1045695 0,20 L0,2 C0,0.8954305 0.8954305,0 2,0'
              id='Path'
            ></path>
            <polygon id='Path' points='6 3.03954393e-14 16 3.03954393e-14 16 12 11 8 6 12'></polygon>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default MediaKitsIcon;
