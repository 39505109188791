import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material';
import useCacheOperations from '../../hooks/useCacheOperations';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import PlansModal from './PlansModal';
import Checkout from './Checkout';
import Modal from 'components/Modal';
import useTracking from 'context/useTrackingCtx';
import { useGetUserPaymentHistoryQuery } from '../../types/generated';
import { useMobileView } from '../../hooks/useMobileView';
import { useErrorHandlerHook } from '../../hooks/useErrorCatchHook';
import q83API from '../../api/q83API';
import getPlan, { getPlanForStripeId } from './getPlan';
import get from 'lodash/get';
import partyPopperIcon from 'assets/icons/PartyPopper.svg';

interface ISubscribePopup {
  selectedPlanId?: string;
  closeSubscribePopup?: () => void;
  stripeSessionId?: string;
}

const itunesSubscriptionsLink = `https://apps.apple.com/account/subscriptions`;
const activeStripeSubscriptionStatuses = ['active', 'past_due'];

const SubscribePopup = ({ stripeSessionId, selectedPlanId, closeSubscribePopup }: ISubscribePopup) => {
  const theme = useTheme();
  const { trackEvent } = useTracking();
  const [mobileView] = useMobileView();
  const [chosenPlan, setChosenPlan] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [paymentCompleted, setPaymentCompleted] = useState(false);

  const { resetStoreSafely } = useCacheOperations();

  const {
    data: userData,
    loading: userLoading,
    error: userError,
    refetch
  } = useGetUserPaymentHistoryQuery({
    fetchPolicy: 'network-only'
  });

  const { apolloHandler, axiosHandler } = useErrorHandlerHook();

  useEffect(() => {
    if (userError) {
      apolloHandler(userError, `Unable to get current invoice settings`);
    }
  }, [userError, apolloHandler]);

  const user = userData?.myUser;
  const planDetails = chosenPlan ? getPlan(chosenPlan) : null;
  const braintreeSubscription = user?.braintreeSubscription;
  const stripeSubscription = user?.stripeCustomer?.firstSubscription;
  const appSubscription = user?.appSubscription;

  const hasActiveStripeSubscription = Boolean(
    stripeSubscription?.status && activeStripeSubscriptionStatuses.includes(stripeSubscription?.status)
  );

  const hasBraintreeSubscription =
    braintreeSubscription?.status === 'Active' && braintreeSubscription?.paymentMethodToken;

  const currentPlan = hasActiveStripeSubscription
    ? getPlanForStripeId(stripeSubscription?.firstItem?.price?.id)
    : braintreeSubscription
    ? getPlan(braintreeSubscription?.planId)
    : null;

  useEffect(() => {
    if (stripeSessionId) {
      q83API
        .put('stripe/session', {
          stripeSessionId
        })
        .then((response) => {
          if (response && response.data && response.data.subscription) {
            const planNickname = get(response, ['data', 'subscription', 'items', 'data', 0, 'price', 'nickname']);
            console.log('Plan switched to:', planNickname);
            trackEvent('subscription', `changed subscription to ${planNickname}`);
            setPaymentCompleted(true);
            refetch();
            resetStoreSafely();
          }
        })
        .catch((ex) => {
          console.log('Subscribe EXCEPTION', ex);
          axiosHandler(ex, `Error subscribing to plan.`);
        })
        .finally(() => setIsLoading(false));
    }
  }, [stripeSessionId, axiosHandler, refetch, resetStoreSafely, trackEvent]);

  const handleSwitchPlan = (planId: string) => {
    if (hasActiveStripeSubscription) {
      setIsLoading(true);
      q83API
        .put('stripe/subscription', {
          planId
        })
        .then((response) => {
          if (response && response.data && response.data.subscription) {
            setPaymentCompleted(true);
            trackEvent('subscription', `changed subscription to ${planId}`);
            refetch();
            resetStoreSafely();
          }
        })
        .catch((ex) => {
          console.log('Subscribe EXCEPTION', ex);
          axiosHandler(ex, `Error subscribing to plan.`);
        })
        .finally(() => setIsLoading(false));
    } else if (hasBraintreeSubscription) {
      setIsLoading(true);
      q83API
        .put('payment/subscription', { planId })
        .then((response) => {
          setPaymentCompleted(true);
          trackEvent('subscription', `changed subscription to ${planId}`);
          resetStoreSafely();
          refetch();
        })
        .catch((ex) => {
          axiosHandler(ex, `Error subscribing to plan.`);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const renderDialog = (content) => {
    return (
      <Dialog
        open
        onClose={(event, reason) => {
          if (closeSubscribePopup && reason !== 'backdropClick') {
            closeSubscribePopup();
          }
        }}
        maxWidth={false}
        fullScreen={mobileView}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '20px',
            justifyContent: 'center',
            alignItems: 'center',
            height: mobileView ? '100%' : 'initial',
            width: mobileView ? '100%' : 'initial'
          }}
        >
          {content}
        </div>
      </Dialog>
    );
  };

  const renderLoading = () => {
    return renderDialog(
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '30px' }}>
        <CircularProgress />
      </div>
    );
  };

  const renderSuccess = () => {
    const planName = planDetails?.name;
    const primaryColor = theme.palette?.primary?.main;
    return renderDialog(
      <>
        <div style={{ height: 48, width: 48, marginBottom: 25 }}>
          <img src={partyPopperIcon} alt='success' />
        </div>
        <Typography variant='h5' style={{ fontWeight: 500, marginBottom: 25 }}>
          You have successfully changed{' '}
          {planName ? (
            <>
              to <span style={{ color: primaryColor }}>{planName}</span>
            </>
          ) : (
            'your plan'
          )}{' '}
        </Typography>
        {closeSubscribePopup && (
          <Button
            data-cy='subscribe-success-close'
            onClick={() => {
              setPaymentCompleted(false);
              closeSubscribePopup();
            }}
            variant='outlined'
          >
            Close
          </Button>
        )}
      </>
    );
  };

  const renderPayment = () => {
    if (!planDetails) {
      return null;
    }

    if (hasActiveStripeSubscription || hasBraintreeSubscription) {
      return (
        <Modal
          isOpen={true}
          onToggle={() => {
            setChosenPlan(undefined);
          }}
          onConfirm={() => {
            handleSwitchPlan(planDetails.planId);
          }}
          confirmLabel={'Switch Now'}
          heading={'Change plans'}
        >
          <Typography style={{ marginBottom: 10 }}>
            Are you sure you want to switch to the plan: {planDetails.name}
          </Typography>
        </Modal>
      );
    }

    return <Checkout chosenPlan={planDetails} closeSubscribePopup={closeSubscribePopup} />;
  };

  const renderInAppPurchaseNotice = () => {
    return (
      <Modal
        isOpen={true}
        onConfirm={() => {
          window.open(itunesSubscriptionsLink, '_blank');
        }}
        onCancel={closeSubscribePopup}
        confirmLabel={'Open Apple Subscriptions'}
        heading={'In App Purchase'}
        maxWidth={'sm'}
      >
        <Box pb={8}>
          <Typography>
            Looks like you have bought a Kitly subscription through an in-app purchase on your Apple iOS device. To
            manage your account, please login to the Kitly app on your iOS device or use the link below
          </Typography>
        </Box>
      </Modal>
    );
  };

  if (isLoading || userLoading) {
    return renderLoading();
  }

  if (paymentCompleted) {
    return renderSuccess();
  }

  if (appSubscription) {
    return renderInAppPurchaseNotice();
  }

  if (planDetails) {
    return renderPayment();
  }

  return (
    <PlansModal
      subscription={currentPlan}
      startingPlanId={selectedPlanId}
      onSelect={(planId) => setChosenPlan(planId)}
      handleClose={closeSubscribePopup}
    />
  );
};

export default SubscribePopup;
