import { useEffect, useState, useMemo, ChangeEvent, useContext } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useTheme } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TextField from '@mui/material/TextField';
import Modal from 'components/Modal';
import useTracking from 'context/useTrackingCtx';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import compact from 'lodash/compact';
import sortBy from 'lodash/sortBy';
import reverse from 'lodash/reverse';
import moment from 'moment';
import {
  useGetMyUserQuery,
  useCreateInvoiceMutation,
  InvoiceStatus,
  useGetInvoicesLazyQuery,
  useGetCollaborationListQuery,
  useUpdateInvoiceMutation,
  GetInvoicesDocument,
  UserCurrency,
  CollaborationInvoice,
  Deliverable,
  Collaboration,
  SocialChannel
} from 'types/generated';
import { useLocation, useNavigate } from 'react-router-dom';
import { SingleDatePicker } from 'react-dates';
import IconButton from '@mui/material/IconButton';
import channels from '../../../lib/channels';
import deliverablesData from 'lib/deliverables';
import { useCheckInvoiceHooks } from '../../../hooks/useCheckInoviceHooks';
import { useErrorHandlerHook } from 'hooks/useErrorCatchHook';
import { currencyOptions } from '../../../lib/invoiceValues';
import ContactSelector from '../../Fields/ContactSelector';
import { formatNumbersWithCommas } from 'lib/formatNumber';
import { getLabelForContact } from '../../Fields/ContactSelector';
import { numberToMomentHoursMinutes } from 'utils/utils';
import createDataContext from 'lib/createDataContext';
import InvoiceShareContext from 'context/InvoiceShareContext';
import AppConfig from 'config/appConfig';
import CollabSelector from 'components/pages/ReportPage/Modal/SelectorFields/CollabSelector';

const defaultState = {
  invoiceTo: undefined,
  dueDate: moment().add(30, 'days').toDate(),
  currency: undefined,
  taxType: 'GST',
  taxPercentage: 0,
  taxStatus: 'noTax',
  status: 'DRAFT',
  deliverableIds: []
};

export const { Context, Provider } = createDataContext(
  (state, action) => {
    switch (action.type) {
      case 'SET_INVOICE_TO':
        return { ...state, invoiceTo: action.payload };
      case 'SET_DUE_DATE':
        return { ...state, dueDate: action.payload };
      case 'SET_CURRENCY':
        return { ...state, currency: action.payload };
      case 'SET_TAX_TYPE':
        return { ...state, taxType: action.payload };
      case 'SET_TAX_PERCENTAGE':
        return { ...state, taxPercentage: action.payload };
      case 'SET_TAX_STATUS':
        return { ...state, taxStatus: action.payload };
      case 'SET_STATUS':
        return { ...state, status: action.payload };
      case 'SET_SELECTED_DELIVERABLE_IDS':
        return { ...state, deliverableIds: action.payload };
      case 'CLEAR_ALL':
        return defaultState;
      default:
        return state;
    }
  },
  {
    setInvoiceTo: (dispatch: any) => (payload: string) => dispatch({ type: 'SET_INVOICE_TO', payload }),
    setDueDate: (dispatch: any) => (payload: Date) => dispatch({ type: 'SET_DUE_DATE', payload }),
    setCurrency: (dispatch: any) => (payload: string) => dispatch({ type: 'SET_CURRENCY', payload }),
    setTaxType: (dispatch: any) => (payload: string) => dispatch({ type: 'SET_TAX_TYPE', payload }),
    setTaxPercentage: (dispatch: any) => (payload: number) => dispatch({ type: 'SET_TAX_PERCENTAGE', payload }),
    setTaxStatus: (dispatch: any) => (payload: string) => dispatch({ type: 'SET_TAX_STATUS', payload }),
    setStatus: (dispatch: any) => (payload: string) => dispatch({ type: 'SET_STATUS', payload }),
    setDeliverableIds: (dispatch: any) => (payload: string[]) =>
      dispatch({ type: 'SET_SELECTED_DELIVERABLE_IDS', payload }),
    clearAll: (dispatch: any) => () => dispatch({ type: 'CLEAR_ALL' })
  },
  defaultState
);
interface IDeliverablesData {
  value: string;
  label: string;
  channel: string | string[];
}

interface InvoiceCreationProps {
  children: JSX.Element;
  collabForInvoice?: Collaboration;
  toggleModal?: (newInvoiceId?: string) => void;
  invoiceIdForEditing?: string;
  size?: number;
}

interface CreationModalProps extends InvoiceCreationProps {
  isOpen: boolean;
}

type CurrencyTotal = {
  ids: string[];
  currency: {
    title: string;
    value: string;
    symbol: string;
  };
};

export const InvoiceCreation = (props: InvoiceCreationProps) => {
  const { children, invoiceIdForEditing } = props;
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    const hasNewPath = location.search.includes('create');
    if (hasNewPath && !invoiceIdForEditing) {
      openModal();
    }
    // only want to run this once on initial component mount, otherwise any state changes from parent component will re-run this hook
    // eslint-disable-next-line
  }, []);

  const dismissModal = (newInvoiceId?: string) => {
    setIsOpen(false);
    if (props.toggleModal) props.toggleModal(newInvoiceId);
  };

  return (
    <span style={{ display: 'block', width: '100%' }}>
      {isOpen && <InvoiceCreationModal {...props} isOpen={isOpen} toggleModal={dismissModal} />}
      <ButtonBase style={{ width: '100%' }} onClick={() => openModal()}>
        {children}
      </ButtonBase>
    </span>
  );
};

const InvoiceCreationModal = (props: CreationModalProps) => {
  const { isOpen, toggleModal, collabForInvoice: inheritedCollab, invoiceIdForEditing } = props;

  const [selectedCollabId, setSelectedCollabId] = useState<string | undefined>();

  useEffect(() => {
    if (inheritedCollab?._id) {
      setSelectedCollabId(inheritedCollab._id);
    }
  }, [inheritedCollab, setSelectedCollabId]);

  const { classes } = useStyles();
  const navigate = useNavigate();
  const { apolloHandler } = useErrorHandlerHook();

  const {
    data: collaborationData,
    error: collaborationError,
    loading: collaborationLoading
  } = useGetCollaborationListQuery();

  const [getInvoiceForId, { data: getInvoiceForIdData, error: getInvoiceForIdError, loading: getInvoiceForIdLoading }] =
    useGetInvoicesLazyQuery({
      onCompleted: (data) => {
        if (data.getCollaborationInvoices && data.getCollaborationInvoices[0]) {
          setSelectedCollabId(data.getCollaborationInvoices[0]?.collaborationId || undefined);
        }
      }
    });

  const existingInvoice = useMemo(() => {
    if (getInvoiceForIdData?.getCollaborationInvoices?.length) {
      return getInvoiceForIdData?.getCollaborationInvoices[0] || undefined;
    }

    return undefined;
  }, [getInvoiceForIdData]);

  const collabForInvoice = useMemo(() => {
    return inheritedCollab
      ? inheritedCollab
      : selectedCollabId && collaborationData?.getCollaborationList?.length
      ? find(collaborationData.getCollaborationList, { _id: selectedCollabId })
      : null;
  }, [inheritedCollab, selectedCollabId, collaborationData]);

  const isDisabled = !collabForInvoice;

  const redirectToInvoiceSettings = () => navigate('/settings/invoice/campaigns');

  const deliverables = useMemo(() => {
    return collabForInvoice?.deliverables || [];
  }, [collabForInvoice]);

  useEffect(() => {
    if (invoiceIdForEditing) {
      getInvoiceForId({
        variables: {
          id: invoiceIdForEditing
        }
      });
    }
  }, [getInvoiceForId, invoiceIdForEditing]);

  useEffect(() => {
    if (getInvoiceForIdError) {
      apolloHandler(getInvoiceForIdError);
    }
  }, [apolloHandler, getInvoiceForIdError]);

  useEffect(() => {
    if (collaborationError) {
      apolloHandler(collaborationError);
    }
  }, [apolloHandler, collaborationError]);

  const [isInvoiceValidated, hasSubmittedDetails] = useCheckInvoiceHooks();
  const canCreateInvoice = isInvoiceValidated && hasSubmittedDetails;

  const Loader = () => (
    <Grid item xs={12} className={`${classes.centred} ${classes.loaderContainer}`}>
      <CircularProgress />
    </Grid>
  );

  const brandAvatar = collabForInvoice?.brand?.avatar;
  const brandName = collabForInvoice?.brand ? getLabelForContact(collabForInvoice?.brand) : undefined;
  if (!isOpen) {
    return null;
  }

  const dismissModal = () => {
    if (toggleModal) {
      toggleModal();
    }
  };

  if (!canCreateInvoice) {
    const isLoadingInvoiceValidity = Boolean(
      typeof isInvoiceValidated !== 'boolean' || typeof hasSubmittedDetails !== 'boolean'
    );
    return (
      <Modal
        heading={' '}
        isOpen={true}
        onToggle={dismissModal}
        onConfirm={redirectToInvoiceSettings}
        hideConfirmButton={isLoadingInvoiceValidity}
        confirmLabel={'Update'}
        maxWidth={'sm'}
      >
        {isLoadingInvoiceValidity ? (
          <CircularProgress style={{ margin: '20px auto' }} />
        ) : (
          <Typography
            variant='body2'
            align='center'
            style={{ fontSize: 16, fontWeight: 500, width: 295, marginBottom: 16 }}
          >
            {`To generate an invoice you'll first need to update your invoice template.`}
          </Typography>
        )}
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={true}
      onToggle={dismissModal}
      hideConfirmationButtons
      maxWidth={'sm'}
      heading={`${invoiceIdForEditing ? 'Update' : 'Generate'} Invoice`}
    >
      <Grid container className={classes.modalContainer}>
        <Provider>
          {Boolean(getInvoiceForIdLoading || collaborationLoading) ? (
            <Loader />
          ) : (
            <>
              {inheritedCollab?.brand ? (
                <>
                  <Grid
                    item
                    container
                    xs={12}
                    alignItems='center'
                    className={classes.input}
                    style={{ marginBottom: 15 }}
                  >
                    {brandAvatar ? (
                      <Avatar variant='circular' src={brandAvatar} />
                    ) : (
                      <Avatar variant='circular'>{brandName?.length ? brandName[0] : 'B'}</Avatar>
                    )}

                    <Typography variant='h6' style={{ marginLeft: 10, fontStyle: !brandName ? 'italic' : undefined }}>
                      {brandName || 'no brand'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.input}>
                    <Typography style={{ fontSize: 12, fontWeight: 'bold' }}>
                      Collaboration: {inheritedCollab.name}
                    </Typography>
                  </Grid>
                </>
              ) : (
                <CollabSelector selectCollab={(id) => setSelectedCollabId(id)} selectedId={selectedCollabId} />
              )}

              <Grid item xs={12} className={classes.input}>
                <InvoiceToField
                  collaboration={collabForInvoice || undefined}
                  existingInvoice={existingInvoice}
                  disabled={isDisabled}
                />
              </Grid>
              {!isDisabled && (
                <Grid item xs={12} className={classes.input}>
                  <Typography variant='body1' className={classes.inputTitle}>
                    Invoice Due Date DD/MM/YYYY
                  </Typography>
                  <DateField disabled={isDisabled} />
                </Grid>
              )}
              <Grid item xs={12} className={classes.input}>
                <CurrencyField deliverables={compact(deliverables)} disabled={isDisabled} />
              </Grid>

              {!isDisabled && (
                <Grid item container xs={12} className={classes.input}>
                  <TaxStatusSelector existingInvoice={existingInvoice} disabled={isDisabled} />
                </Grid>
              )}

              <Grid item container xs={12} className={classes.input}>
                <TaxFields existingInvoice={existingInvoice} disabled={isDisabled} />
              </Grid>

              <Grid item container justifyContent='space-between' className={classes.input}>
                <Grid item container xs={3} direction='column' justifyContent='flex-end'>
                  <Typography variant='h6'>Total Value</Typography>
                </Grid>
                <InvoiceTotalDetails deliverables={compact(deliverables)} />
              </Grid>
              <StatusButton existingInvoice={existingInvoice} />
              <Grid item container className={classes.input}>
                <DeliverableList deliverables={compact(deliverables)} />
              </Grid>
              <Grid item xs={12}>
                <GenerateButtons
                  toggleModal={toggleModal}
                  invoiceIdForEditing={invoiceIdForEditing}
                  collaboration={collabForInvoice || undefined}
                />
              </Grid>
            </>
          )}
        </Provider>
      </Grid>
    </Modal>
  );
};

const useStyles = makeStyles()((theme) => {
  return {
    centred: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    loaderContainer: {
      height: '30vh'
    },
    modalContainer: {
      overflowX: 'hidden',
      '&::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      'scrollbar-width': 'none' /* Firefox */
    },
    input: {
      margin: '5px 0'
    },
    inputTitle: {
      fontSize: 12,
      fontWeight: 500,
      color: '#AE9FAF'
    },
    finishButton: {
      width: '100%'
    },
    error: {
      color: theme.palette.error.main,
      fontSize: 12,
      marginTop: 10,
      textAlign: 'center'
    },
    taxTypeInput: {
      height: '100%'
    }
  };
});

const GenerateButtons = ({
  toggleModal,
  invoiceIdForEditing,
  collaboration
}: {
  toggleModal?: (id?: string) => void;
  invoiceIdForEditing?: string;
  collaboration?: Collaboration;
}) => {
  const { trackEvent } = useTracking();
  const { apolloHandler } = useErrorHandlerHook();
  const { setPreviewInvoiceUrl } = useContext(InvoiceShareContext);
  const {
    state: { taxStatus, taxPercentage, deliverableIds, taxType, dueDate, invoiceTo, invoiceStatus },
    setTaxPercentage,
    setTaxType,
    setTaxStatus
  } = useContext(Context);

  const [createInvoice, { error: createInvoiceError, loading: createInvoiceLoading }] = useCreateInvoiceMutation();

  const [updateInvoice, { error: updateInvoiceError, loading: updateInvoiceLoading }] = useUpdateInvoiceMutation({
    refetchQueries: [{ query: GetInvoicesDocument }]
  });

  const [getInvoice, { data, error, loading }] = useGetInvoicesLazyQuery();

  const {
    data: myUserData,
    error: myUserError,
    loading: myUserLoading
  } = useGetMyUserQuery({
    fetchPolicy: 'network-only'
  });
  useEffect(() => {
    if (myUserError) {
      apolloHandler(myUserError);
    }
  }, [apolloHandler, myUserError]);

  useEffect(() => {
    if (createInvoiceError) {
      apolloHandler(createInvoiceError);
    }
  }, [createInvoiceError, apolloHandler]);

  useEffect(() => {
    if (updateInvoiceError) {
      apolloHandler(updateInvoiceError);
    }
  }, [apolloHandler, updateInvoiceError]);

  useEffect(() => {
    if (error) {
      apolloHandler(error);
    }
  }, [apolloHandler, error]);

  const userStripeTaxRate = myUserData?.myUser?.stripeTaxRate || '';
  const taxRateDetails = useMemo(() => myUserData?.myUser?.stripeTaxRateDetails, [myUserData]);

  useEffect(() => {
    if (taxRateDetails && !isEmpty(taxRateDetails)) {
      const { display_name, inclusive, percentage } = taxRateDetails;
      setTaxPercentage(percentage!);
      setTaxStatus(inclusive ? 'includeTax' : 'excludeTax');
      setTaxType(display_name!);
    }
    // Disabling warning to prevent render loop
    // eslint-disable-next-line
  }, [taxRateDetails]);

  const isValid = useMemo(() => {
    const validTax = Boolean(taxStatus === 'noTax' || Boolean(taxStatus && taxType && taxPercentage));
    if (dueDate && invoiceTo && validTax && !isEmpty(deliverableIds)) return true;
    return false;
  }, [taxStatus, taxType, taxPercentage, dueDate, invoiceTo, deliverableIds]);

  const isSameTaxRate = useMemo(() => {
    if (taxRateDetails && !isEmpty(taxRateDetails)) {
      const { display_name, inclusive, percentage } = taxRateDetails;
      if (
        display_name === taxType &&
        inclusive === Boolean(taxStatus === 'includeTax') &&
        percentage === taxPercentage
      ) {
        return true;
      }
    }
    return false;
  }, [taxPercentage, taxRateDetails, taxStatus, taxType]);

  useEffect(() => {
    if (data?.getCollaborationInvoices?.length && data.getCollaborationInvoices[0]) {
      if (setPreviewInvoiceUrl) {
        setPreviewInvoiceUrl(
          data.getCollaborationInvoices[0].stripeInvoiceUrl ||
            `${AppConfig.INVOICE_URL}/#/view/${data.getCollaborationInvoices[0]._id}`
        );
      }
      if (toggleModal && data.getCollaborationInvoices[0]._id) {
        toggleModal(data.getCollaborationInvoices[0]._id);
      }
    }
  }, [data, toggleModal, setPreviewInvoiceUrl]);

  const handleCreateInvoice = () => {
    const updatesToSend = {
      stripeTaxRateId: isSameTaxRate ? userStripeTaxRate : '',
      invoiceToContactId: invoiceTo,
      deliverableIds: deliverableIds,
      collaborationId: collaboration?._id,
      dueDate,
      status: InvoiceStatus[invoiceStatus as InvoiceStatus],
      taxStatus,
      taxType,
      taxPercentage
    };

    if (invoiceIdForEditing) {
      updateInvoice({
        variables: {
          id: invoiceIdForEditing,
          updates: updatesToSend
        }
      }).then(({ data }) => {
        if (data && data?.updateCollaborationInvoice?.insertedId) {
          trackEvent('invoice', 'updated invoice');
          getInvoice({ variables: { id: data?.updateCollaborationInvoice?.insertedId } });
        }
      });
    } else if (collaboration?._id) {
      createInvoice({
        variables: {
          updates: updatesToSend
        }
      }).then(({ data }) => {
        if (data?.createCollaborationInvoice) {
          trackEvent('invoice', 'created invoice');
          getInvoice({ variables: { id: data.createCollaborationInvoice } });
        }
      });
    }
  };

  const isLoading = createInvoiceLoading || updateInvoiceLoading || myUserLoading || loading;

  return (
    <>
      <Button
        onClick={handleCreateInvoice}
        variant='contained'
        color='primary'
        disabled={!isValid}
        style={{ marginRight: 10, marginTop: 10 }}
      >
        {isLoading ? <CircularProgress style={{ color: '#FFF' }} /> : invoiceIdForEditing ? 'Update' : 'Save & Preview'}
      </Button>
      {toggleModal && (
        <Button onClick={() => toggleModal()} variant='outlined' style={{ marginRight: 10, marginTop: 10 }}>
          Close
        </Button>
      )}
    </>
  );
};

const DeliverableList = ({
  deliverables,
  existingInvoice
}: {
  deliverables: Deliverable[];
  existingInvoice?: CollaborationInvoice;
}) => {
  const theme = useTheme();
  const {
    state: { deliverableIds, currency },
    setDeliverableIds
  } = useContext(Context);

  useEffect(() => {
    if (existingInvoice?.deliverableIds) {
      setDeliverableIds(compact(existingInvoice?.deliverableIds));
    }
    // Disabling warning to prevent render loop
    // eslint-disable-next-line
  }, [existingInvoice]);

  const currencyDetails = useMemo(() => {
    if (currency) {
      const currencyRecord = find(currencyOptions, { value: currency });

      if (currencyRecord) {
        return currencyRecord;
      }
    }
    return currencyOptions[0];
  }, [currency]);

  const handleChange = (deliverable) => {
    let currentSelectedDeliverables = [...deliverableIds];
    const index = currentSelectedDeliverables.indexOf(deliverable._id);
    Boolean(index > -1)
      ? currentSelectedDeliverables.splice(index, 1)
      : currentSelectedDeliverables.push(deliverable._id);
    setDeliverableIds(currentSelectedDeliverables);
  };

  if (!deliverables?.length) {
    return null;
  }
  return (
    <>
      <Typography variant='h6'>Select Deliverables</Typography>
      <Table padding='none'>
        <TableBody>
          {compact(deliverables).map((d, i) => {
            const deliverableProfiles = compact(d.profiles);
            const firstProfile = deliverableProfiles ? deliverableProfiles[0] : undefined;
            const profileAvatar = firstProfile ? firstProfile.profile_picture_url : undefined;
            const avatarInitial = firstProfile?.name ? firstProfile.name[0] : 'k';
            const deliverableId = d?._id || '';
            const deliverablePrice = d?.price;
            const socialDeliverable = d?.socialDeliverable;
            const deliverableInfo = find(deliverablesData, { value: socialDeliverable }) as IDeliverablesData;
            const deliverableCurrency = d.currency
              ? find(currencyOptions, { value: d.currency }) || currencyDetails
              : currencyDetails;
            const channelForDeliverable = isArray(deliverableInfo?.channel)
              ? deliverableInfo?.channel[0]
              : deliverableInfo?.channel;
            const channel = find(channels, (o) => o.value === channelForDeliverable);
            const deliverableLabel = deliverableInfo?.label;
            const isDisabled = Boolean(d.currency !== currency);

            return (
              <TableRow key={`deliverable_${i}`} style={{ opacity: isDisabled ? 0.4 : 1 }}>
                <TableCell>
                  <Grid container alignItems='center'>
                    <Avatar variant='circular' src={profileAvatar || ''} style={{ height: 20, width: 20 }}>
                      {avatarInitial}
                    </Avatar>
                    <Divider orientation='vertical' flexItem style={{ marginLeft: 8, marginRight: 8 }} />
                    {channel && (
                      <Avatar
                        variant='rounded'
                        src={channel.logo}
                        alt={channel.label}
                        style={{
                          height: 16,
                          width: channel.value === SocialChannel.Youtube ? 22 : 16,
                          marginRight: 5
                        }}
                      />
                    )}
                    <Typography variant='h6'>{deliverableLabel}</Typography>
                  </Grid>
                </TableCell>
                <TableCell>
                  <Typography variant='h6'>{`${moment(d?.endDate).format('D MMM YY')}${
                    typeof d?.endTime === 'number' ? ` at ${numberToMomentHoursMinutes(d.endTime).format('h:mma')}` : ''
                  }`}</Typography>
                </TableCell>
                <TableCell>
                  {deliverablePrice && (
                    <Typography variant='body2'>{`${deliverableCurrency.symbol}${deliverablePrice} ${
                      deliverableCurrency.value || ''
                    }`}</Typography>
                  )}
                </TableCell>
                <TableCell align='right'>
                  <IconButton onClick={() => handleChange(d)} disabled={isDisabled}>
                    {deliverableIds.includes(deliverableId) ? (
                      <CheckCircleIcon style={{ color: theme.palette.primary.main }} />
                    ) : (
                      <CheckCircleIcon style={{ color: theme.palette.grey[500] }} />
                    )}
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

const InvoiceToField = ({
  collaboration,
  existingInvoice,
  disabled
}: {
  collaboration?: Collaboration;
  existingInvoice?: CollaborationInvoice;
  disabled?: boolean;
}) => {
  const {
    state: { invoiceTo },
    setInvoiceTo
  } = useContext(Context);

  useEffect(() => {
    if (existingInvoice?.invoiceToContactId) {
      setInvoiceTo(existingInvoice.invoiceToContactId);
    }
    // Disabling warning to prevent render loop
    // eslint-disable-next-line
  }, [existingInvoice]);

  useEffect(() => {
    if (collaboration?.brand?._id) {
      setInvoiceTo(collaboration.brand._id);
    }
    // Disabling warning to prevent render loop
    // eslint-disable-next-line
  }, [collaboration]);

  return (
    <ContactSelector
      selectedId={invoiceTo}
      setSelectedId={(id) => (id ? setInvoiceTo(id) : {})}
      emptyLabel={`Invoice to Brand...`}
      disabled={disabled}
    />
  );
};

const DateField = ({ existingInvoice, disabled }: { existingInvoice?: CollaborationInvoice; disabled?: boolean }) => {
  const {
    state: { dueDate },
    setDueDate
  } = useContext(Context);
  const [focusedInput, setFocusedInput] = useState<boolean>(false);

  useEffect(() => {
    if (existingInvoice?.dueDate) {
      setDueDate(existingInvoice.dueDate);
    }
    // Disabling warning to prevent render loop
    // eslint-disable-next-line
  }, [existingInvoice]);

  return (
    <SingleDatePicker
      disabled={disabled}
      displayFormat='DD/MM/YYYY'
      monthFormat='MMM YYYY'
      date={dueDate ? moment(dueDate) : null}
      onDateChange={(date) => (date ? setDueDate(date.toDate()) : null)}
      focused={focusedInput}
      id='invoice-single-date-picker'
      onFocusChange={(state) => setFocusedInput(Boolean(state.focused))}
      hideKeyboardShortcutsPanel
      initialVisibleMonth={() => moment(dueDate)}
      numberOfMonths={1}
      noBorder={true}
      daySize={25}
      block={true}
      small={true}
    />
  );
};

const InvoiceTotalDetails = ({ deliverables }: { deliverables: Deliverable[] }) => {
  const {
    state: { taxStatus, taxPercentage, currency, deliverableIds }
  } = useContext(Context);

  const currencyDetails = useMemo(() => {
    if (currency) {
      const currencyRecord = find(currencyOptions, { value: currency });

      if (currencyRecord) {
        return currencyRecord;
      }
    }
    return currencyOptions[0];
  }, [currency]);

  const deliverablesTotal = useMemo(() => {
    if (!deliverables) return 0;
    const total = deliverables.reduce((acc, curr) => {
      if (curr?._id && deliverableIds.includes(curr?._id)) {
        return (acc += curr.price || 0);
      }
      return acc;
    }, 0);
    return total;
  }, [deliverables, deliverableIds]);

  const subtotalString = `Subtotal - ${currencyDetails.symbol}${formatNumbersWithCommas(deliverablesTotal)}`;
  const taxAmount = parseFloat(((deliverablesTotal * taxPercentage) / 100).toFixed(2));
  const invoiceTotal = taxStatus === 'excludeTax' ? deliverablesTotal + taxAmount : deliverablesTotal;
  const taxString = `Total tax ${taxPercentage || 0}% - ${currencyDetails.symbol}${formatNumbersWithCommas(
    taxAmount || 0
  )}`;

  return (
    <Grid item container xs={4}>
      <Grid item xs={12}>
        <Typography variant='body2' align='right'>
          {subtotalString}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {deliverablesTotal > 0 && taxStatus !== 'noTax' && (
          <Typography variant='body2' align='right'>
            {taxString}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h6' align='right'>{`${currencyDetails.value} ${
          currencyDetails.symbol
        }${formatNumbersWithCommas(invoiceTotal)}`}</Typography>
      </Grid>
    </Grid>
  );
};

const statusButtonStyles = makeStyles()(() => ({
  statusButton: {
    minWidth: 105,
    margin: 5,
    fontSize: 12,
    color: '#333333',
    textTransform: 'none'
  },
  inactive: {
    backgroundColor: '#F3F1F1'
  },
  active: {
    border: 'solid 0.5px #979797'
  },
  activeIndicator: {
    height: 6,
    width: 6,
    borderRadius: '100%',
    backgroundColor: '#01D311',
    marginRight: 5
  }
}));

const statusColors: { [key: string]: string } = {
  Draft: '#FFB96F',
  Sent: 'rgba(255,208,21,0.5)',
  Paid: '#C2EDE8',
  Overdue: '#F68D8D',
  Void: '#BBBBBB'
};

const statuses = Object.keys(InvoiceStatus);

const getKeyForEnumValue = (status) => {
  for (let [key, value] of Object.entries(InvoiceStatus)) {
    if (value === status) {
      return key;
    }
  }
};

const StatusButton = ({ existingInvoice }: { existingInvoice?: CollaborationInvoice }) => {
  const {
    state: { status },
    setStatus
  } = useContext(Context);
  const { classes } = useStyles();
  const { classes: buttonClasses } = statusButtonStyles();

  useEffect(() => {
    if (existingInvoice?.status) {
      setStatus(getKeyForEnumValue(existingInvoice.status));
    }
    // Disabling warning to prevent render loop
    // eslint-disable-next-line
  }, [existingInvoice]);

  if (!Boolean(existingInvoice && !existingInvoice?.stripeInvoiceUrl)) {
    return null;
  }

  return (
    <Grid item container xs={12} className={classes.input}>
      <Typography variant='body1' className={classes.inputTitle}>
        Select Invoice Status *
      </Typography>
      <Grid item container>
        {statuses.map((key) => (
          <Grid item xs={3}>
            <Button
              onClick={() => setStatus(key)}
              size='small'
              className={`${buttonClasses.statusButton} ${
                status === key ? buttonClasses.active : buttonClasses.inactive
              }`}
            >
              <div className={buttonClasses.activeIndicator} style={{ backgroundColor: statusColors[key] }} />
              {key === 'Draft' ? 'Working on it' : key}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

const CurrencyField = ({ deliverables, disabled }: { deliverables: Deliverable[]; disabled?: boolean }) => {
  const {
    state: { currency },
    setCurrency,
    setDeliverableIds
  } = useContext(Context);

  const totalsByCurrency = useMemo(() => {
    let totals: { [key: string]: CurrencyTotal } = {};

    deliverables?.forEach((o) => {
      if (o?.currency && o._id) {
        const currency = find(currencyOptions, { value: o.currency });
        if (currency) {
          if (!totals[currency.value]) {
            totals[currency.value] = { ids: [], currency };
          }
          totals[currency.value].ids.push(o._id);
        }
      }
    });

    return totals;
  }, [deliverables]);

  useEffect(() => {
    if (currency && totalsByCurrency[currency]) {
      setDeliverableIds(totalsByCurrency[currency].ids);
    }
    // Disabling warning to prevent render loop
    // eslint-disable-next-line
  }, [currency, totalsByCurrency]);

  const availableCurrencies = useMemo(() => {
    let sortedCurrencies = sortBy(
      Object.keys(totalsByCurrency).map((key) => ({
        ...totalsByCurrency[key].currency,
        count: totalsByCurrency[key].ids.length
      })),
      'count'
    );

    reverse(sortedCurrencies);

    return sortedCurrencies;
  }, [totalsByCurrency]);

  useEffect(() => {
    if (availableCurrencies.length) {
      setCurrency(availableCurrencies[0].value as UserCurrency);
    }
    // Disabling warning to prevent render loop
    // eslint-disable-next-line
  }, [availableCurrencies]);

  return (
    <FormControl variant='standard' fullWidth={true}>
      <InputLabel>Currency</InputLabel>
      <Select
        variant='standard'
        disabled={disabled}
        value={currency || ''}
        onChange={(e: any) => setCurrency(e.target.value)}
        fullWidth={true}
        renderValue={(selected: any) => {
          return find(currencyOptions, { value: selected })?.title || selected;
        }}
      >
        <MenuItem disabled value=''>
          N/A
        </MenuItem>
        {availableCurrencies?.map((o) => (
          <MenuItem key={`${o.value}`} value={o.value}>
            {o.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const TaxFields = ({ existingInvoice, disabled }: { existingInvoice?: CollaborationInvoice; disabled?: boolean }) => {
  const {
    state: { taxStatus, taxType, taxPercentage },
    setTaxType,
    setTaxPercentage
  } = useContext(Context);

  const handleChangeTaxType = (event: SelectChangeEvent<string>) => {
    setTaxType(event.target.value as string);
  };

  const handleChangeTaxPercentage = (event: ChangeEvent<{ value: unknown }>) => {
    const parsedValue = parseFloat(event.target.value as string);
    setTaxPercentage(parseFloat(parsedValue.toFixed(2)));
  };

  useEffect(() => {
    if (existingInvoice?.taxType) {
      setTaxType(existingInvoice.taxType);
    }
    if (existingInvoice?.taxPercentage) {
      setTaxPercentage(existingInvoice.taxPercentage);
    }
    // Disabling warning to prevent render loop
    // eslint-disable-next-line
  }, [existingInvoice]);

  useEffect(() => {
    if (taxStatus === 'noTax') {
      setTaxType('');
      setTaxPercentage(0);
    }
    // Disabling warning to prevent render loop
    // eslint-disable-next-line
  }, [taxStatus]);

  if (taxStatus === 'noTax') {
    return null;
  }
  return (
    <>
      <Grid item xs={12} sm={6} style={{ paddingRight: 8 }}>
        <FormControl variant='standard' fullWidth={true}>
          <InputLabel>Tax type</InputLabel>
          <Select
            variant='standard'
            fullWidth
            label='Tax'
            value={taxType}
            onChange={(e: SelectChangeEvent) => handleChangeTaxType(e)}
            style={{ height: '100%' }}
            disabled={disabled}
          >
            <MenuItem value='GST'>GST</MenuItem>
            <MenuItem value='VAT'>VAT</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} style={{ paddingLeft: 8 }}>
        <TextField
          variant='standard'
          disabled={disabled}
          fullWidth
          label='%'
          type='number'
          inputProps={{ min: 0 }}
          value={taxPercentage}
          onChange={handleChangeTaxPercentage}
        />
      </Grid>
    </>
  );
};

const TaxStatusSelector = ({
  existingInvoice,
  disabled
}: {
  existingInvoice?: CollaborationInvoice;
  disabled?: boolean;
}) => {
  const theme = useTheme();

  const {
    state: { taxStatus },
    setTaxStatus
  } = useContext(Context);

  const handleChangeTaxStatus = (event: ChangeEvent<HTMLInputElement>) => {
    setTaxStatus(event.target.value);
  };

  useEffect(() => {
    if (existingInvoice?.taxStatus) {
      setTaxStatus(existingInvoice.taxStatus);
    }
    // Disabling warning to prevent render loop
    // eslint-disable-next-line
  }, [existingInvoice]);

  return (
    <RadioGroup aria-label='tax status' value={taxStatus} onChange={handleChangeTaxStatus}>
      <FormControlLabel
        value='includeTax'
        control={
          <Radio
            disabled={disabled}
            size='small'
            icon={<RadioButtonUncheckedIcon style={{ color: theme.palette.secondary.main }} />}
            checkedIcon={<RadioButtonCheckedIcon style={{ color: theme.palette.primary.main }} />}
          />
        }
        label='Includes tax'
      />
      <FormControlLabel
        value='excludeTax'
        control={
          <Radio
            disabled={disabled}
            size='small'
            icon={<RadioButtonUncheckedIcon style={{ color: theme.palette.secondary.main }} />}
            checkedIcon={<RadioButtonCheckedIcon style={{ color: theme.palette.primary.main }} />}
          />
        }
        label='Excludes tax'
      />
      <FormControlLabel
        value='noTax'
        control={
          <Radio
            disabled={disabled}
            size='small'
            icon={<RadioButtonUncheckedIcon style={{ color: theme.palette.secondary.main }} />}
            checkedIcon={<RadioButtonCheckedIcon style={{ color: theme.palette.primary.main }} />}
          />
        }
        label='No tax'
      />
    </RadioGroup>
  );
};
