/**
 * The component is to preventing the flash loading component
 *
 */
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Loader } from 'components/Loader';
import React, { useState, useEffect } from 'react';

interface IFlashPreventLoading {
  loadingStatus: boolean;
  position?: 'absolute' | 'fixed' | 'relative' | 'static';
}
const FlashPreventLoading: React.FC<IFlashPreventLoading> = (props) => {
  const { classes } = useClasses();
  const { loadingStatus } = props;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [loadingStatus]);
  return (
    <>
      {loading && (
        <Box className={classes.modalLoading} style={{ position: props.position }}>
          <Loader />
        </Box>
      )}
    </>
  );
};

FlashPreventLoading.defaultProps = {
  position: 'absolute'
};

export default FlashPreventLoading;

const useClasses = makeStyles()((theme) => ({
  modalLoading: {
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 99999,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));
