import { useState, useEffect, useMemo } from 'react';
import {
  Badge,
  Box,
  CircularProgress,
  Hidden,
  Typography,
  useMediaQuery,
  useTheme,
  AppBar,
  CssBaseline,
  Drawer,
  List,
  ListItemIcon,
  ListItemText,
  IconButton,
  Toolbar,
  Theme,
  ListItemButton
} from '@mui/material';

import { Menu } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';
import { defaultColors } from '../config/muiTheme';

import { brandsLabel, sideDrawItems, sideDrawLabel, side_draw_type_enum } from 'Constants/UI/SideDrawer';

import { useNavigate } from 'react-router-dom';
import logo from '../assets/images/logo.svg';
import Avatar from './Avatar';
import ThemeButton from './ThemeButton';
import WelcomeModal from './kitlyIntro/WelcomeModal';
import VersionInfo from './VersionInfo';
import StyledMenu from './Styled/StyledMenu';
import { isContact, useBrandRequest } from 'hooks/useBrandRequest';
import { useGetMyUserUsageLazyQuery } from 'types/generated';
import { useToken } from 'hooks/useAuthHooks';
import { useErrorHandlerHook } from 'hooks/useErrorCatchHook';
import useSubscribePopupCtx from 'context/useSubscribePopupCtx';
import { WALKTHROUGHS } from 'types/custom';
import get from 'lodash/get';
import filter from 'lodash/filter';
import SystemAlert from 'components/SystemAlert';
import NewReleaseUpdatesModal from './NewReleaseUpdatesModal';
import moment from 'moment';

const drawerWidth = 240;
const versionStringForNewReleaseModal = 'V2.0';
const disableNewReleaseModalDate = '2023-03-31';

type Props = {
  children: JSX.Element;
};

export default function CustomDrawer({ children }: Props) {
  const theme = useTheme();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { isAuth, token } = useToken();
  const { apolloHandler } = useErrorHandlerHook();

  const mediumDown = useMediaQuery(theme.breakpoints.down('md'));

  const [getMyUser, { data, loading, error, refetch: refetchUser }] = useGetMyUserUsageLazyQuery();
  const [showWalkthrough, setShowWalkthrough] = useState<boolean>(true);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(true);
  const [topOffset, setTopOffset] = useState(0);
  const user = data?.myUser;

  const { invites } = useBrandRequest();
  const totalPendingBrandInvites = invites
    ? filter(invites, (o) => {
        if (isContact(o)) {
          return false;
        }
        if (o.acceptedInvite || o.isArchive) {
          return false;
        }
        if (o.userId === user?._id) {
          return false;
        }
        return true;
      })
    : null;

  useEffect(() => {
    if (isAuth && token) {
      getMyUser();
    }
  }, [isAuth, token, getMyUser]);

  useEffect(() => {
    if (error) {
      apolloHandler(error);
    }
  }, [error, apolloHandler]);

  useEffect(() => {
    setDrawerOpen(!mediumDown);
  }, [mediumDown, setDrawerOpen]);

  const { setSelectedPlanId } = useSubscribePopupCtx();

  const myUserWalkthrough = useMemo(() => user?.walkthrough || [], [user]);

  const isFirstLogin = !myUserWalkthrough.includes(WALKTHROUGHS.FIRST_LOGIN);
  const showNewReleaseUpdatesModal =
    user &&
    !myUserWalkthrough.includes(versionStringForNewReleaseModal) &&
    moment().isBefore(disableNewReleaseModalDate);

  if (!isAuth) {
    return (
      <main className={classes.content} style={{ marginTop: 43 + topOffset }}>
        <Box display='flex' justifyContent='center'>
          <CircularProgress />
        </Box>
      </main>
    );
  }

  if (isFirstLogin && !loading && showWalkthrough) {
    return (
      <WelcomeModal
        refetchUser={refetchUser}
        showWalkthrough={showWalkthrough}
        dismissWalkthrough={() => {
          setShowWalkthrough(false);
          if (user?.signupPlanId) {
            setSelectedPlanId(user.signupPlanId);
          }
        }}
      />
    );
  }

  const isOnHighestPlan = Boolean(
    user?.activeSubscription === 'kitly-usd-2' || user?.activeSubscription === 'kitly-usd-2-yearly'
  );

  const handleUpgrade = () => {
    setSelectedPlanId('kitly-usd-2');
  };

  // tool bar component
  const toolBarComponent = (
    <AppBar position='fixed' className={classes.appBar} elevation={1}>
      <SystemAlert onChangeDisplay={(offset) => setTopOffset(offset)} />
      <Toolbar>
        <Hidden lgUp>
          <div>
            <IconButton
              aria-haspopup='true'
              onClick={() => {
                setDrawerOpen(!drawerOpen);
              }}
            >
              <Menu style={{ color: '#ccc', fontSize: '20px' }} />
            </IconButton>
          </div>
          <div
            style={{
              height: 54,
              marginLeft: 10,
              marginRight: 20,
              borderRight: 1,
              borderRightStyle: 'solid',
              borderRightColor: '#ccc'
            }}
          />
        </Hidden>
        <img src={logo} alt='logo' className={classes.logo} />
        {/* <ThemedInput className={classes.theme_input} startAdornment={<SearchIcon />} /> */}
        <Hidden xsDown>
          <Box style={{ flexGrow: 1 }} />
        </Hidden>
        <Hidden xsDown>
          <div className={classes.avatarWrapper}>
            {!isOnHighestPlan && (
              <ThemeButton className={classes.barBtn} text='Upgrade' _onClick={handleUpgrade} height={40} width={110} />
            )}
            <Avatar
              firstName={user?.firstName ?? ''}
              lastName={user?.lastName ?? ''}
              src={user?.avatar ?? ''}
              size={32}
              initailsFontSize={14}
            />
            <StyledMenu />
          </div>
        </Hidden>
      </Toolbar>
    </AppBar>
  );

  // Drawer Items component
  const sideDrawItemListComponent = (
    <>
      {sideDrawItems.map((sideDrawItem: sideDrawLabel, index) => {
        const sectionTitle = sideDrawItem.type === side_draw_type_enum.SectionTitle;

        const usageInfo = sideDrawItem.usageKey ? get(user, sideDrawItem.usageKey) : null;

        const remaining = Boolean(typeof usageInfo?.count === 'number' && usageInfo?.limit < 1000)
          ? usageInfo!.limit - usageInfo!.count
          : undefined;

        const renderRemainingText = () => {
          if (typeof remaining !== 'number') {
            return undefined;
          }

          return (
            <Typography variant={'subtitle1'} style={{ fontStyle: 'italic', fontSize: '10px' }}>{`${
              remaining > 0 ? remaining : 0
            } remaining`}</Typography>
          );
        };

        return (
          <ListItemButton
            onClick={() => {
              if (sideDrawItem.url) {
                navigate(`${sideDrawItem.url}`);
              }
            }}
            key={`${sideDrawItem.label}${index}`}
            className={sectionTitle ? classes.drawSectionTitleItem : classes.drawSectionItem}
          >
            {sideDrawItem.iconPath && (
              <ListItemIcon className={classes.drawItemIcon}>
                <img src={sideDrawItem.iconPath} alt='logo' />
              </ListItemIcon>
            )}

            {sideDrawItem.label === brandsLabel ? (
              sideDrawItem.iconComponent && (
                <Badge
                  badgeContent={totalPendingBrandInvites?.length}
                  color='primary'
                  classes={{ badge: classes.badge }}
                >
                  <ListItemIcon className={classes.drawItemIcon}>{sideDrawItem.iconComponent}</ListItemIcon>
                </Badge>
              )
            ) : (
              <ListItemIcon className={classes.drawItemIcon}>{sideDrawItem.iconComponent}</ListItemIcon>
            )}

            <ListItemText
              style={{ marginLeft: 10 }}
              className={sectionTitle ? classes.drawSectionTitleText : classes.drawItemText}
              primary={<Typography variant={sectionTitle ? 'subtitle2' : 'inherit'}>{sideDrawItem.label}</Typography>}
              secondary={renderRemainingText()}
            />
          </ListItemButton>
        );
      })}
    </>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      {toolBarComponent}
      <Drawer
        className={classes.drawer}
        variant={mediumDown ? 'temporary' : 'persistent'}
        open={drawerOpen}
        anchor='left'
        transitionDuration={500}
        onClose={() => {
          setDrawerOpen(false);
        }}
        classes={{
          paper: classes.drawerPaper
        }}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
      >
        <Toolbar />
        <div style={{ paddingTop: 20 + topOffset, position: 'relative', height: 'calc(100% - 64px)' }}>
          <div className={classes.drawerContainer}>
            <List>{sideDrawItemListComponent}</List>
          </div>
          <div className={classes.versionInfo}>
            <VersionInfo theme={theme} />
          </div>
        </div>
      </Drawer>
      <main className={classes.content} style={{ marginTop: 43 + topOffset }}>
        <Toolbar />
        <Box display='flex' justifyContent='center'>
          {children}
        </Box>
      </main>
      {showNewReleaseUpdatesModal && (
        <NewReleaseUpdatesModal versionString={versionStringForNewReleaseModal} refetchUser={refetchUser} />
      )}
    </div>
  );
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    position: 'relative'
  },
  appBar: {
    backgroundColor: theme.palette.secondary.main,
    zIndex: theme.zIndex.drawer + 1,
    top: 'auto'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    transition: 'all 1s ease'
  },
  barBtn: {
    marginRight: 20
  },
  drawSectionTitleItem: {
    marginTop: 0
  },
  drawSectionItem: {
    minHeight: 50,
    paddingTop: 0,
    paddingBottom: 0
  },
  drawSectionTitleText: {
    fontSize: 10,
    fontWeight: 500
  },
  drawItemText: {
    fontSize: 14,
    fontWeight: 500,
    color: defaultColors.fontColorLight,
    opacity: 0.7
  },
  drawItemIcon: {
    minWidth: 30
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: defaultColors.secondaryBackground,
    overflow: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    '-ms-overflow-style': 'none' /* IE and Edge */,
    'scrollbar-width': 'none' /* Firefox */
  },
  drawerContainer: {
    overflow: 'auto',
    paddingLeft: 10,
    paddingBottom: 60
  },
  content: {
    margin: '43px auto 68px',
    flexGrow: 1,
    transition: 'all 1s ease'
  },
  avatarWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 60
  },
  logo: {
    height: 50,
    width: 86,
    marginLeft: 10
  },
  theme_input: {
    marginLeft: 193,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 20,
      flexGrow: 1
    }
  },
  versionInfo: {
    position: 'fixed',
    bottom: 0,
    width: drawerWidth - 2,
    backgroundColor: defaultColors.secondaryBackground,
    paddingTop: 10,
    paddingBottom: 20
  },
  badge: {
    backgroundColor: '#84C6FF',
    border: '2px solid white',
    padding: 2
  }
}));
