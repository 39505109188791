import { useState } from 'react';
import { Dialog, ButtonBase, Box, Button, Grid, Typography } from '@mui/material';

import { makeStyles } from 'tss-react/mui';
import referImage from 'assets/images/refer.png';
import { COLORS } from 'lib/constants';
import { useGetCouponsQuery } from 'types/generated';
import useCopyText from 'hooks/useCopyText';
import { TextField } from '@mui/material';
import { useInput } from 'hooks/useInput';
import CONFIG from 'config/appConfig';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const InviteDialog = (props: Props) => {
  const { isOpen, onClose } = props;
  const { classes } = useStyles();
  const { data, loading } = useGetCouponsQuery({ errorPolicy: 'ignore', fetchPolicy: 'no-cache' });
  const couponCode = data?.getCoupons?.length ? data.getCoupons[0]?.code : undefined;
  const [isCouponCopied, setIsCouponCopied] = useState(false);
  const [isShareClicked, setIsShareClicked] = useState(false);

  const copyText = useCopyText();

  // Next line ignored because first value isn't used.
  // eslint-disable-next-line
  const [nameInput, bindNameInput] = useInput('');

  // Next line ignored because first value isn't used.
  // eslint-disable-next-line
  const [emailInput, bindEmailInput] = useInput('');

  const handleClose = () => {
    onClose();
    setIsShareClicked(false);
  };

  const handleCopyCoupon = () => {
    if (couponCode) {
      copyText(couponCode, (error) => {
        if (!error) {
          setIsCouponCopied(true);
        }
      });
    }
  };

  const renderContent = () => {
    return isShareClicked ? (
      <Box mt='23.5px' mb='31px' alignSelf='flex-start' width='100%'>
        <TextField variant='standard' label='Name*' fullWidth {...bindNameInput} />
        <TextField variant='standard' label='Email Address*' fullWidth {...bindEmailInput} />
      </Box>
    ) : (
      <Box mt='35px' mb='53px'>
        <Typography align='center' className={classes.bodyText}>
          Share your code with a brand you’ve worked with. When they join Kitly Business you’ll receive a{' '}
          <Typography className={`${classes.bodyText} ${classes.redText}`}>USD$50 rewards card.</Typography>
          <Box mt='10px'>
            <ButtonBase
              href='https://support.q-83.com/en/articles/4341096-how-referrals-work-for-kitly-business'
              target='_blank'
              rel='noopener noreferrer'
            >
              <Typography className={classes.linkText}>How referrals work?</Typography>
            </ButtonBase>
          </Box>
        </Typography>
      </Box>
    );
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby='simple-dialog-title' open={isOpen}>
      <Grid className={classes.container} alignItems='center' container direction='column'>
        <Typography className={classes.title}>Invite to Kitly</Typography>
        <Box mt='23px' mb='28px'>
          <img src={referImage} className={classes.referImage} alt='refer' />
        </Box>
        <Typography className={classes.subtitleText}>YOUR CODE</Typography>
        <Typography className={`${classes.codeText} ${couponCode && classes.clickableText}`} onClick={handleCopyCoupon}>
          {loading ? 'LOADING YOUR CODE...' : couponCode || 'Code unavailable'}
          {isCouponCopied && (
            <Typography align='center' className={classes.copiedText}>
              copied!
            </Typography>
          )}
        </Typography>
        {renderContent()}
        <Grid container wrap='nowrap' style={{ columnGap: 10 }} justifyContent='center'>
          {/* TODO */}
          {/* <Button variant='contained' className={`${classes.button} ${classes.shareButton}`} onClick={handleShare}>
            Share
          </Button> */}
          <Button variant='outlined' className={`${classes.button} ${classes.cancelButton}`} onClick={handleClose}>
            Cancel
          </Button>
        </Grid>
        <Box mt='12px'>
          <ButtonBase href={CONFIG.TERMS_URL} target='_blank'>
            <Typography className={classes.termsText}>*Terms and conditions apply</Typography>
          </ButtonBase>
        </Box>
      </Grid>
    </Dialog>
  );
};

const useStyles = makeStyles()((theme) => {
  return {
    container: { padding: '52px 76px 22px', minWidth: 548 },
    title: {
      fontSize: 22
    },
    referImage: {
      width: 137,
      height: 104
    },
    subtitleText: {
      fontSize: 10,
      fontWeight: 500,
      color: '#BBBBBB',
      marginBottom: '11.5px'
    },
    codeText: {
      fontWeight: 500,
      fontSize: 15,
      textTransform: 'capitalize',
      padding: '18.5px 24.5px',
      color: COLORS.MAROON,
      border: '1px dashed #CCCCCC'
    },
    clickableText: {
      '&:hover': {
        cursor: 'pointer'
      }
    },
    copiedText: {
      textTransform: 'lowercase',
      color: theme.palette.primary.main,
      fontWeight: 500
    },
    bodyText: {
      fontSize: 14,
      lineHeight: '22px'
    },
    linkText: {
      color: theme.palette.primary.main,
      fontSize: 12,
      fontWeight: 500
    },
    redText: {
      color: theme.palette.primary.main
    },
    dialogTitle: {
      textAlign: 'center'
    },
    button: {
      minWidth: 134
    },
    shareButton: {
      backgroundColor: COLORS.MAROON
    },
    cancelButton: {
      color: COLORS.MAROON,
      borderColor: COLORS.MAROON
    },
    termsText: {
      fontSize: 8,
      color: theme.palette.primary.main
    }
  };
});
