import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import { startCase, lowerCase } from 'lodash';
import { CollaborationStatus } from 'types/generated';

interface StatusProps {
  status: string;
}

export const statusColors: { [key: string]: string } = {
  NEGOTIATION: '#F4800C',
  DRAFT: '#F4800C',
  ACTIVE: '#01D311',
  COMPLETED: '#383838',
  ARCHIVED: '#383838'
};

export const Status = (props: StatusProps) => {
  const { status } = props;
  const { classes } = useStyles();
  const displayStatus = status === CollaborationStatus.Negotiation ? 'Working on it' : startCase(lowerCase(status));

  return (
    <span className={classes.statusBox}>
      <span className={classes.activeIndicator} style={{ backgroundColor: statusColors[status] }} />
      <Typography variant='caption' className={classes.statusText}>
        {displayStatus}
      </Typography>
    </span>
  );
};

const useStyles = makeStyles()((theme) => {
  return {
    activeIndicator: {
      height: 6,
      width: 6,
      borderRadius: '100%',
      marginRight: 3
    },
    statusBox: {
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'center',
      textTransform: 'capitalize',
      marginLeft: 5
    },
    statusText: {
      fontWeight: 200,
      fontSize: 12,
      color: theme.palette.common.black,
      textTransform: 'capitalize'
    }
  };
});
