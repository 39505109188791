import { makeStyles } from 'tss-react/mui';
import { Avatar, Grid, Typography, Box, ButtonBase, Dialog } from '@mui/material';
import { Button } from '@mui/material';
import { Contact } from 'types/generated';
import { InviteDialog } from 'components/Shared/InviteDialog';
import { useDialog } from 'hooks/useDialog';
import CONFIG from 'config/appConfig';

interface Props {
  isOpen: boolean;
  contact: Contact;
  onClose: () => void;
}

export const NotOnKBDialog = (props: Props) => {
  const { isOpen, onClose, contact } = props;
  const { classes } = useStyles();
  const { bind, onOpen } = useDialog();

  return (
    <Dialog onClose={onClose} aria-labelledby='simple-dialog-title' open={isOpen}>
      <Grid style={{ padding: '43px 74px' }}>
        <Grid container justifyContent='center'>
          <Avatar src={contact.avatar || ''} className={classes.avatar}>
            {contact.companyName?.[0]}
          </Avatar>
        </Grid>
        <Typography align='center'>
          <Typography variant='h5' className={classes.boldText}>
            {contact.companyName} is not on
          </Typography>
          <Typography className={classes.KBText}>Kitly Business</Typography>
          <Box mt='10px' mb='35px'>
            <Typography className={classes.bodyText}>
              Invite them to earn a $50 prepaid rewards card for your efforts*.
            </Typography>
          </Box>
        </Typography>
        <Grid container style={{ columnGap: 20 }} justifyContent='center'>
          {/* TODO invite onclick flow: KY-959 */}
          <Button variant='contained' onClick={onOpen}>
            Invite
          </Button>
          <Button variant='outlined' onClick={onClose}>
            Cancel
          </Button>
        </Grid>
        <Box mt='35px' width='100%' display='flex' justifyContent='center'>
          <ButtonBase href={CONFIG.TERMS_URL} target='_blank'>
            <Typography className={`${classes.termsText}`}>*Terms and conditions apply</Typography>
          </ButtonBase>
        </Box>
      </Grid>
      <InviteDialog {...bind} />
    </Dialog>
  );
};
const useStyles = makeStyles()((theme) => ({
  avatar: {
    height: 80,
    width: 80
  },
  boldText: {
    marginTop: 19,
    marginBottom: 10
  },
  KBText: {
    fontSize: 18,
    color: '#4141AE',
    fontWeight: 500
  },
  bodyText: {
    fontSize: 14,
    lineHeight: '19px'
  },
  termsText: {
    fontSize: 8,
    color: theme.palette.primary.main
  }
}));
