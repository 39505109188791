import { useState } from 'react';
import { useForm } from '../../hooks/useForm';

import {
  Grid,
  Checkbox,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  CircularProgress
} from '@mui/material';
import TextInput from '../TextInput';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBox from 'assets/components/CheckBox';
import ContactSelector from './ContactSelector';

import { useCreateContactMutation, ContactUpdates, ContactType } from 'types/generated';
import useTracking from 'context/useTrackingCtx';
import validate from 'validate.js';
import isEmpty from 'lodash/isEmpty';

export type SharePayload = {
  contactIds?: string[];
  contactDetails?: {
    firstName: string;
    lastName: string;
    email: string;
  };
  saveContact?: boolean;
};

interface IShareFields {
  defaultPayload?: SharePayload;
  children?: any;
  onShare: (payload: SharePayload) => void;
  checkIfEnabled?: () => boolean;
  onDismiss?: () => void;
  loading?: boolean;
  sendNewContactRequest?: boolean;
  radioButtonColor?: 'primary' | 'secondary';
}

const contactConstraints = {
  firstName: {
    presence: { allowEmpty: false }
  },
  email: {
    presence: { allowEmpty: false },
    email: true
  }
};

const extraContactConstraints = {
  contactName: {
    presence: { allowEmpty: false }
  }
};

const ShareFields = ({
  defaultPayload,
  children,
  onShare,
  checkIfEnabled,
  loading,
  sendNewContactRequest,
  radioButtonColor
}: IShareFields) => {
  const [brandId, setBrandId] = useState<string | undefined>();
  const [contactIds, setContactIds] = useState(defaultPayload?.contactIds || []);
  const [contactDetails, setContactDetails] = useForm(
    defaultPayload?.contactDetails || {
      firstName: '',
      lastName: '',
      email: ''
    }
  );
  const [shouldSaveContact, setShouldSaveContact] = useState(true);
  const [contactName, setContactName] = useState('');
  const [shareMethod, setShareMethod] = useState<'contact' | 'email'>('contact');
  const [fieldErrors, setFieldErrors] = useState<any>({});
  const handleError = (name: string) => fieldErrors.hasOwnProperty(name);

  const [createContact, { loading: contactLoading }] = useCreateContactMutation();

  const isLoading = Boolean(loading || contactLoading);

  const { trackEvent } = useTracking();

  const handleShare = () => {
    const shouldShare = checkIfEnabled ? checkIfEnabled() : true;

    if (shouldShare) {
      if (shareMethod === 'contact' && isEmpty(contactIds)) {
        setFieldErrors({ contactIds: 'Please select at least one contact' });
        return;
      }

      if (shareMethod === 'email') {
        const fields = shouldSaveContact ? { ...contactDetails, contactName } : contactDetails;
        const constraints = shouldSaveContact
          ? { ...contactConstraints, ...extraContactConstraints }
          : contactConstraints;
        const errors = validate(fields, constraints);
        if (errors) {
          setFieldErrors(errors);
          return;
        }
      }

      setFieldErrors({});

      if (sendNewContactRequest && shouldSaveContact && shareMethod === 'email') {
        handleContactCreation();
      }

      if (shareMethod === 'email') {
        onShare({ contactDetails, saveContact: sendNewContactRequest ? undefined : shouldSaveContact });
      } else {
        onShare({ contactIds });
      }
    }
  };

  const handleContactCreation = () => {
    const contactUpdates = { type: ContactType.Company, companyName: contactName };
    createContact({ variables: { updates: contactUpdates } })
      .then((response) => {
        const contactId = response?.data?.createContact?.insertedId;
        trackEvent('contact', 'created contact');
        if (contactId) {
          const updates: ContactUpdates = {
            ...contactDetails,
            type: ContactType.Individual,
            parentId: contactId
          };
          createContact({ variables: { updates } })
            .then(() => trackEvent('contact', 'created contact'))
            .catch((error) => console.log(`error creating contact for ${contactId}:`, error));
        }
      })
      .catch((error) => {
        console.log('error creating contact:', error);
      });
  };

  const renderRecipientToggle = () => {
    return (
      <Grid container style={{ width: '100%', display: 'flex', marginTop: '8px' }}>
        <Grid item xs={12}>
          <Typography style={{ fontWeight: 500 }}>Would you like to share</Typography>
        </Grid>
        <Grid item xs={12} style={{ paddingRight: 5 }}>
          <RadioGroup
            value={shareMethod}
            onChange={(event: any) => setShareMethod(event?.target?.value)}
            style={{ marginLeft: '-10px' }}
          >
            <FormControlLabel
              value={'contact'}
              control={
                <Radio
                  checked={Boolean(shareMethod === 'contact')}
                  color={radioButtonColor ? radioButtonColor : undefined}
                  style={{ fontSize: 12 }}
                />
              }
              label='To an existing contact'
              style={{ margin: 0 }}
            />
            <FormControlLabel
              value={'email'}
              control={
                <Radio
                  checked={Boolean(shareMethod === 'email')}
                  color={radioButtonColor ? radioButtonColor : undefined}
                  style={{ fontSize: 12 }}
                />
              }
              label='To an email address'
              style={{ margin: 0 }}
            />
          </RadioGroup>
        </Grid>
      </Grid>
    );
  };

  const renderNameFields = () => {
    return (
      <Grid container style={{ margin: '0 auto' }}>
        <Grid item xs={12}>
          <TextInput
            dataCy={'contact_name-input'}
            error={handleError('contactName')}
            errorMessage={fieldErrors['contactName']}
            id='contact'
            label='Client'
            value={contactName}
            onChange={(e: any) => setContactName(e?.target?.value)}
            margin='dense'
            disabled={!shouldSaveContact}
          />
        </Grid>

        <Grid item xs={12} sm={6} style={{ paddingRight: 5, paddingTop: 10 }}>
          <TextInput
            dataCy={'first_name-input'}
            error={handleError('firstName')}
            errorMessage={fieldErrors['firstName']}
            id='firstname'
            label='First Name'
            value={contactDetails.firstName}
            onChange={(e: any) => setContactDetails('firstName', e?.target?.value)}
            margin='dense'
          />
        </Grid>
        <Grid item xs={12} sm={6} style={{ paddingLeft: 5, paddingTop: 10 }}>
          <TextInput
            required={'false'}
            dataCy={'last_name-input'}
            error={handleError('lastName')}
            errorMessage={fieldErrors['lastName']}
            id='lastname'
            label='Last Name'
            value={contactDetails.lastName}
            onChange={(e: any) => setContactDetails('lastName', e?.target?.value)}
            margin='dense'
          />
        </Grid>
      </Grid>
    );
  };

  const renderEmailFields = () => {
    return (
      <Grid container style={{ margin: '10px auto' }}>
        <Grid item xs={12}>
          <TextInput
            dataCy={'email-input'}
            error={handleError('email')}
            errorMessage={fieldErrors['email']}
            id='email'
            label='Email Address'
            value={contactDetails.email}
            onChange={(e: any) => {
              const updateString = e?.target?.value?.replace(/\s/g, '')?.toLowerCase();
              setContactDetails('email', updateString);
            }}
            margin='dense'
          />
        </Grid>
      </Grid>
    );
  };

  const renderAddContact = () => {
    const checkboxSize = 19;
    return (
      <Grid container justifyContent='flex-start' alignItems='center' spacing={2}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={shouldSaveContact}
                onChange={() => setShouldSaveContact(!shouldSaveContact)}
                value='checked'
                color='primary'
                icon={<CheckBoxOutlineBlankIcon style={{ fontSize: checkboxSize }} />}
                checkedIcon={<CheckBox size={19} />}
              />
            }
            label={<span style={{ fontSize: 12 }}>Save to contacts</span>}
            style={{ margin: '0 0 0 -8px' }}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <div
      style={{ display: 'flex', justifyItems: 'center', flexDirection: 'column', minWidth: 413, overflow: 'hidden' }}
    >
      {renderRecipientToggle()}
      {children}
      {shareMethod === 'contact' ? (
        <>
          <ContactSelector
            emptyLabel='Client'
            selectedId={brandId}
            setSelectedId={(id) => setBrandId(id)}
            errorMessage={fieldErrors['contactIds']}
          />
          {brandId && (
            <ContactSelector
              displayEmail
              parentContactId={brandId}
              emptyLabel='Client Contact'
              selectedIds={contactIds}
              setSelectedIds={(ids) => setContactIds(ids)}
              errorMessage={fieldErrors['contactIds']}
              hideIfOneOption
            />
          )}
        </>
      ) : (
        <>
          {renderNameFields()}
          {renderEmailFields()}
          {renderAddContact()}
        </>
      )}
      <Grid container justifyContent='flex-start' style={{ marginTop: 30 }}>
        <Grid item>
          <Button variant='contained' color='primary' onClick={handleShare} disabled={isLoading}>
            {isLoading ? <CircularProgress /> : 'Share'}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default ShareFields;
