import React, { useState, useMemo, useEffect, useContext } from 'react';
import {
  Grid,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Table,
  ButtonBase,
  Typography,
  Button,
  CircularProgress
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useGetReportConfigListQuery, GetMyUserUsageDocument } from 'types/generated';
import moment from 'moment';
import ContactCell from '../../../Shared/ContactCell';
import StatusLabel from '../StatusLabel';
import ReportOperations from './ReportOperations';
import { useMutation } from '@apollo/client';
import { DELETE_REPORT_CONFIG } from 'graphql/mutations/reports';
import { AuthContext } from 'context/useAuthCtx';
import { generateBearerLink } from 'utils/utils';
import useTracking from 'context/useTrackingCtx';
import { useErrorHandlerHook } from 'hooks/useErrorCatchHook';

const pageWidth = 827;
interface IReportTableState {
  lastCreatedReport: string;
  monitoringStatus: boolean;
  shareModalDisplay: boolean;
  deleteEnsureDialog: boolean;
}

interface IDataTable {
  toggleEditorHandler: (reportId: string) => void;
  toggleShare: (reportId: string) => void;
  shareModalDisplay: boolean;
  editorDisplay: boolean;
  latestCreatedReportId: string;
}

const DataTable: React.FC<IDataTable> = (props) => {
  const { toggleEditorHandler, editorDisplay, toggleShare, shareModalDisplay, latestCreatedReportId } = props;
  const { trackEvent } = useTracking();
  const { classes } = useClasses();
  const { _id: userId, email: userEmail, type: userType, token } = useContext(AuthContext);
  const [deleteReportConfig, { error: deleteError }] = useMutation(DELETE_REPORT_CONFIG, {
    refetchQueries: [{ query: GetMyUserUsageDocument }],
    awaitRefetchQueries: true
  });
  const { data, error, refetch: getReportConfigListQueryRefetch } = useGetReportConfigListQuery();
  const [reportTableState, setReportTableState] = useState<IReportTableState>({
    lastCreatedReport: '',
    monitoringStatus: editorDisplay,
    shareModalDisplay: shareModalDisplay,
    deleteEnsureDialog: false
  });
  const [deletingReportId, setDeletingReportId] = useState<string | undefined>();

  const { apolloHandler } = useErrorHandlerHook();

  const reportListData = useMemo(() => {
    if (data?.getReportConfigList && data?.getReportConfigList.length > 0) {
      return data.getReportConfigList;
    }

    return [];
  }, [data]);

  useEffect(() => {
    if (editorDisplay !== reportTableState.monitoringStatus) {
      getReportConfigListQueryRefetch();
      setReportTableState((preState) => ({
        ...preState,
        monitoringStatus: editorDisplay
      }));
    }

    if (shareModalDisplay !== reportTableState.shareModalDisplay) {
      getReportConfigListQueryRefetch();
      setReportTableState((preState) => ({
        ...preState,
        shareModalDisplay: shareModalDisplay
      }));
    }
  }, [editorDisplay, getReportConfigListQueryRefetch, reportTableState, shareModalDisplay]);

  useEffect(() => {
    if (error) {
      apolloHandler(error);
    }
    if (deleteError) {
      apolloHandler(deleteError);
    }
  }, [error, deleteError, apolloHandler]);

  const _operations = {
    _handleLinkClick: async (reportId: string) => {
      const link = generateBearerLink(reportId, token);
      window.open(link, '_blank');
      if (!userId || !userType || !userEmail) {
        return;
      }
      await trackEvent('report', 'report viewed');
    },
    _onToggleReportDeleteDialog: async () => {
      setReportTableState({
        ...reportTableState,
        deleteEnsureDialog: !reportTableState.deleteEnsureDialog
      });
    },
    _reportDeleteHandler: async (reportId: string) => {
      setDeletingReportId(reportId);
      const deleteResponse = await deleteReportConfig({ variables: { reportId } });
      setDeletingReportId(undefined);
      if (deleteResponse.data?.deleteReportConfig.success) {
        getReportConfigListQueryRefetch();
      }
    },
    _onReportShare: async (reportId: string) => {
      await toggleShare(reportId);
      await getReportConfigListQueryRefetch();
    }
  };

  // header components
  const table_header = (
    <>
      <TableHead style={{ width: '100%' }} component='div'>
        <TableRow>
          {tableTab.map((item: { text: string; label: string; width: number }, index: number) => (
            <TableCell
              key={`header_${index}`}
              style={{
                padding: 0,
                width: item.width,
                minWidth: item.width < pageWidth / tableTab.length ? '' : pageWidth / tableTab.length
              }}
            >
              <Typography className={classes.tableHeaderText}>{item.text}</Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );

  // table body
  const table_body = (
    <>
      {reportListData.map((report: any, index: number) => {
        const createdAt = moment(report.created_at).format('D MMM YY');
        const reportName = report.reportName;

        return (
          <TableRow
            key={`${report._id}_${index}`}
            style={{ backgroundColor: report._id === latestCreatedReportId ? '#FCE3ED' : '' }}
          >
            <TableCell className={classes.tableCell}>
              <Typography className={classes.reportRow}>{createdAt}</Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
              <ButtonBase
                onClick={() => {
                  _operations._handleLinkClick(report._id);
                }}
              >
                <Typography className={`${classes.reportRow} ${classes.linkText}`}>{reportName}</Typography>
              </ButtonBase>
            </TableCell>
            <TableCell className={classes.tableCell}>
              {Boolean(report.reportLinks && report.reportLinks[0]) ? <ContactCell link={report.reportLinks[0]} /> : ''}
            </TableCell>
            <TableCell className={classes.tableCell}>
              <StatusLabel link={report.reportLinks[0]} />
            </TableCell>
            <TableCell className={classes.tableCell} style={{ padding: 4 }}>
              <Grid container justifyContent='flex-end'>
                <Button variant='outlined' size='small' onClick={() => _operations._onReportShare(report._id)}>
                  Share
                </Button>
              </Grid>
            </TableCell>
            <TableCell className={classes.tableCell} style={{ padding: 4 }}>
              <Grid container justifyContent='flex-end' alignItems='center'>
                {deletingReportId === report._id ? (
                  <CircularProgress size={20} />
                ) : (
                  <ReportOperations
                    deleteEnsureDialog={reportTableState.deleteEnsureDialog}
                    toggleDeleteEnsureDialog={() => _operations._onToggleReportDeleteDialog()}
                    toggleReport={() => toggleEditorHandler(report._id)}
                    deleteReportWidthId={() => _operations._reportDeleteHandler(report._id)}
                  />
                )}
              </Grid>
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );

  return (
    <Box mt={'30px'}>
      <Table size={'small'} style={{ width: 892 }}>
        {table_header}
        {table_body}
      </Table>
    </Box>
  );
};

const tableTab = [
  {
    label: 'report-date',
    text: 'Report Ran Date',
    width: 60
  },
  {
    label: 'report-author',
    text: '',
    width: 101
  },
  {
    label: 'report-share-to',
    text: 'Shared to',
    width: 134
  },
  {
    label: 'report-status',
    text: 'Status',
    width: 26
  },
  {
    label: 'report-operation',
    text: '',
    width: 24
  },
  {
    label: 'share-operation',
    text: '',
    width: 24
  }
];

const useClasses = makeStyles()((theme) => ({
  tabWrapper: {
    minWidth: pageWidth / tableTab.length
  },
  tableHeaderText: {
    fontSize: '12px',
    fontFamily: 'Open Sans !important',
    color: '#6C6C72',
    marginBottom: 15
  },
  reportRow: {
    fontSize: '14px',
    fontFamily: 'Open Sans',
    color: '#333333',
    textAlign: 'left'
  },
  tableCell: {
    padding: '6px 16px 6px 0px'
  },
  linkText: {
    color: theme.palette.primary.main
  }
}));

export default DataTable;
