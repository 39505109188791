import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import mainImage from 'assets/images/media_kits_header.png';
import { NewMediaKitButton } from './NewMediaKitButton';
import { WALKTHROUGHS } from 'types/custom';
import { useWalkthrough } from 'hooks/useWalkthrough';

export const Walkthrough = () => {
  const { classes } = useClasses();

  const { handleWalkthrough: handleMaybeLater, loading: maybeLaterLoading } = useWalkthrough(
    WALKTHROUGHS.MEDIA_KITS,
    '/media-kits'
  );

  return (
    <Box className={classes.root} mt='50px'>
      <Grid container justifyContent='center'>
        <Box mb={'44px'}>
          <Typography style={{ color: '#333333', fontWeight: 400, fontSize: 28 }} align='center'>
            Manage Your Media Kits
          </Typography>
        </Box>
        <img alt='campaign set up' src={mainImage} className={classes.image} />
        <Box mb='48px' mt='20px'>
          <Typography style={{ fontSize: 16, lineHeight: '24px', color: '#333333' }} align='center'>
            There’s more to you than just insights. Customise your media kits to express your personality and creative
            flare.
          </Typography>
        </Box>
        <Grid container justifyContent='space-between' wrap='nowrap' spacing={4}>
          <Grid item>
            <NewMediaKitButton
              buttonText='Create a media kit'
              buttonVariant='contained'
              buttonSize='large'
              alignItems='center'
            />
          </Grid>
          <Grid item>
            <Button
              variant='outlined'
              size='large'
              className={`${classes.button} ${classes.smallButton}`}
              onClick={handleMaybeLater}
            >
              {maybeLaterLoading && <CircularProgress style={{ marginRight: 10 }} size={20} />}
              Maybe later
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const useClasses = makeStyles()((theme) => {
  return {
    root: {
      maxWidth: 574
    },
    button: {
      minWidth: '277px'
    },
    buttonText: {
      color: '#FFFFFF',
      fontWeight: 500,
      fontSize: 16
    },
    smallButton: {
      fontSize: 14,
      textTransform: 'none'
    },
    image: {
      height: 258
    }
  };
});
