import React, { useState, useEffect, useMemo } from 'react';
import { ButtonBase, Grid } from '@mui/material';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { InstagramMedia } from 'types/generated';

const deletedImg = 'https://media.q-83.com/site/deleted.jpg';
const cachedMediaURL = `https://cache-global.q-83.com/instagram_records`;

const InstagramMediaOption = (props: {
  media: InstagramMedia;
  isSelected: boolean;
  onSelect: (id: string) => void;
  classes: any;
}) => {
  const { media, isSelected, classes } = props;

  const [imageUrl, setImageUrl] = useState<string | undefined>();

  useEffect(() => {
    if (media) {
      setImageUrl(media.thumbnail_url || media.media_url || undefined);
    }
  }, [media]);

  let fallbackImage = useMemo(() => {
    return Boolean(media.media_type !== 'VIDEO' && media.profileId)
      ? `${cachedMediaURL}/media/${media.profileId}/${media.id}/media.jpg`
      : null;
  }, [media]);

  if (!props.media.id) {
    return null;
  }

  return (
    <Grid item>
      <ButtonBase
        onClick={() => {
          props.onSelect(props.media.id!);
        }}
        className={classes.mediaButton}
      >
        <img
          src={imageUrl}
          alt={`preview`}
          className={classes.mediaImg}
          onError={(e: any) => {
            e.persist();
            if (e.target.src.indexOf(cachedMediaURL) > -1 || !fallbackImage) {
              setImageUrl(deletedImg);
            } else {
              setImageUrl(fallbackImage);
            }
          }}
        />
        {isSelected && <CheckCircleIcon className={classes.tickIcon} />}
      </ButtonBase>
    </Grid>
  );
};

export default InstagramMediaOption;
