import { SocialChannel, SocialDeliverable } from '../types/generated';

const deliverables = [
  {
    value: SocialDeliverable.IgTv,
    label: 'IGTV',
    channel: SocialChannel.Instagram,
    hidden: true
  },
  {
    value: SocialDeliverable.IgStory,
    label: 'Story',
    channel: SocialChannel.Instagram
  },
  {
    value: SocialDeliverable.IgGridPost,
    label: 'Grid Post',
    channel: SocialChannel.Instagram
  },
  {
    value: SocialDeliverable.IgReel,
    label: 'Reel',
    channel: SocialChannel.Instagram
  },
  {
    value: SocialDeliverable.IgImageCarousel,
    label: 'Image Carousel',
    channel: SocialChannel.Instagram
  },
  {
    value: SocialDeliverable.IgLive,
    label: 'Live',
    channel: SocialChannel.Instagram
  },
  {
    value: SocialDeliverable.YtVideo,
    label: 'Video',
    channel: SocialChannel.Youtube
  },
  {
    value: SocialDeliverable.FbPagePost,
    label: 'Page Post',
    channel: SocialChannel.Facebook
  },
  {
    value: SocialDeliverable.ScVideo,
    label: 'Video',
    channel: SocialChannel.Snapchat
  },
  {
    value: SocialDeliverable.ScImage,
    label: 'Image',
    channel: SocialChannel.Snapchat
  },
  {
    value: SocialDeliverable.Retweet,
    label: 'Re-tweet',
    channel: SocialChannel.Twitter
  },
  {
    value: SocialDeliverable.TwVideo,
    label: 'Video',
    channel: SocialChannel.Twitter
  },
  {
    value: SocialDeliverable.TwImage,
    label: 'Image',
    channel: SocialChannel.Twitter
  },
  {
    value: SocialDeliverable.Tweet,
    label: 'Tweet',
    channel: SocialChannel.Twitter
  },
  {
    value: SocialDeliverable.TtVideo,
    label: 'Video',
    channel: SocialChannel.Tiktok
  },
  {
    value: SocialDeliverable.TtShare,
    label: 'Share',
    channel: SocialChannel.Tiktok
  },
  {
    value: SocialDeliverable.Article,
    label: 'Article',
    channel: SocialChannel.Blog
  },
  {
    value: SocialDeliverable.BlVideo,
    label: 'Video',
    channel: SocialChannel.Blog
  },
  {
    value: SocialDeliverable.BlShare,
    label: 'Share',
    channel: SocialChannel.Blog
  },
  {
    value: SocialDeliverable.Byline,
    label: 'Byline',
    channel: SocialChannel.Blog
  },
  {
    value: SocialDeliverable.LiStory,
    label: 'Story',
    channel: SocialChannel.Linkedin
  },
  {
    value: SocialDeliverable.LiPost,
    label: 'Post',
    channel: SocialChannel.Linkedin
  },
  {
    value: SocialDeliverable.LiShare,
    label: 'Share',
    channel: SocialChannel.Linkedin
  },
  {
    value: SocialDeliverable.LiPublishedArticle,
    label: 'Published Article',
    channel: SocialChannel.Linkedin
  },
  {
    value: SocialDeliverable.Custom,
    label: 'Custom',
    channel: SocialChannel.Custom
  },
  {
    value: SocialDeliverable.LinkInBio,
    label: 'Link In Bio',
    channel: [
      SocialChannel.Instagram,
      SocialChannel.Youtube,
      SocialChannel.Facebook,
      SocialChannel.Snapchat,
      SocialChannel.Twitter,
      SocialChannel.Tiktok,
      SocialChannel.Linkedin,
      SocialChannel.Twitch,
      SocialChannel.Podcast
    ]
  },
  {
    value: SocialDeliverable.IgStoryHighlight,
    label: 'Story Highlight',
    channel: SocialChannel.Instagram
  },
  {
    value: SocialDeliverable.TcLiveStream,
    label: 'Live Stream',
    channel: SocialChannel.Twitch
  },
  {
    value: SocialDeliverable.TcVideo,
    label: 'Video',
    channel: SocialChannel.Twitch
  },
  {
    value: SocialDeliverable.TcMention,
    label: 'Mention',
    channel: SocialChannel.Twitch
  },
  {
    value: SocialDeliverable.TcTournament,
    label: 'Tournament',
    channel: SocialChannel.Twitch
  },
  {
    value: SocialDeliverable.ViOneHourShoot,
    label: '1 hour shoot',
    channel: SocialChannel.Videography
  },
  {
    value: SocialDeliverable.ViHalfDayShoot,
    label: 'Half day shoot',
    channel: SocialChannel.Videography
  },
  {
    value: SocialDeliverable.ViFullDayShoot,
    label: 'Full day shoot',
    channel: SocialChannel.Videography
  },
  {
    value: SocialDeliverable.ViEditing,
    label: 'Editing',
    channel: SocialChannel.Videography
  },
  {
    value: SocialDeliverable.PhOneHourShoot,
    label: '1 hour shoot',
    channel: SocialChannel.Photography
  },
  {
    value: SocialDeliverable.PhHalfDayShoot,
    label: 'Half day shoot',
    channel: SocialChannel.Photography
  },
  {
    value: SocialDeliverable.PhFullDayShoot,
    label: 'Full day shoot',
    channel: SocialChannel.Photography
  },
  {
    value: SocialDeliverable.PhEditing,
    label: 'Editing',
    channel: SocialChannel.Photography
  },
  {
    value: SocialDeliverable.PoMention,
    label: 'Mention',
    channel: SocialChannel.Podcast
  },
  {
    value: SocialDeliverable.PoSegmentAd,
    label: 'Segment Ad',
    channel: SocialChannel.Podcast
  },
  {
    value: SocialDeliverable.PoInterview,
    label: 'Interview',
    channel: SocialChannel.Podcast
  },
  {
    value: SocialDeliverable.ApMeetAndGreet,
    label: 'Meet and greet',
    channel: SocialChannel.Appearance
  },
  {
    value: SocialDeliverable.ApInterview,
    label: 'Interview',
    channel: SocialChannel.Appearance
  },
  {
    value: SocialDeliverable.ApVideoMessage,
    label: 'Video message',
    channel: SocialChannel.Appearance
  },
  {
    value: SocialDeliverable.ApKeyNote,
    label: 'Keynote',
    channel: SocialChannel.Appearance
  }
];

export default deliverables;
