import { useState, useEffect } from 'react';

import { Grid, Chip, Button, useTheme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import Modal from 'components/Modal';
import { compact, isEmpty } from 'lodash';

import { Profile, GetProfilesListDocument, useUpdateProfileMutation } from 'types/generated';
import { useErrorHandlerHook } from 'hooks/useErrorCatchHook';
import { availableCategories } from 'lib/categories';

interface CategoryEditModalProps {
  editableTalent?: Profile;
  toggleModal: () => void;
}

const CategoryEditModal = ({ editableTalent, toggleModal }: CategoryEditModalProps) => {
  const theme = useTheme();
  const { classes } = useStyles();
  const { apolloHandler } = useErrorHandlerHook();
  const [selectedCategories, setSelectedCategories] = useState<Array<string>>(
    compact(editableTalent?.profileCategory) || []
  );

  const [updateProfile, { error: updateProfileCategoriesError }] = useUpdateProfileMutation({
    refetchQueries: [GetProfilesListDocument]
  });

  useEffect(() => {
    if (updateProfileCategoriesError) {
      apolloHandler(updateProfileCategoriesError);
    }
  }, [apolloHandler, updateProfileCategoriesError]);

  const handleConfirmCategories = () => {
    if (editableTalent?._id) {
      updateProfile({
        variables: {
          profileId: editableTalent._id,
          updates: {
            profileCategory: selectedCategories
          }
        },
        onCompleted: () => toggleModal()
      });
    }
  };

  const handleCategoryClick = (category: string) => {
    if (category === 'private') {
      setSelectedCategories([]);
    } else {
      let currentSelection = [...selectedCategories];
      currentSelection = currentSelection.includes(category)
        ? currentSelection.filter((c) => c !== category)
        : [...currentSelection, category].slice(-3); // Keeps the last 3 categories selected
      setSelectedCategories(currentSelection);
    }
  };

  return (
    <Modal isOpen hideConfirmButton hideCancelButton onToggle={toggleModal}>
      <Grid container alignItems='center' justifyContent='center' style={{ maxWidth: 350 }}>
        <Typography variant='h1' style={{ fontSize: 22, marginBottom: 30 }}>
          Pick 3 Categories for this account
        </Typography>
        <Grid item container xs={12} alignItems='center' justifyContent='center' style={{ marginBottom: 25 }}>
          {availableCategories.map((c, i) => {
            const highlightedChip =
              selectedCategories?.includes(c.value) || (isEmpty(selectedCategories) && c.value === 'private');
            return (
              <Chip
                key={`category-chip-${i}`}
                className={classes.categoryChip}
                classes={{ label: classes.categoryChipLabel }}
                style={{ backgroundColor: highlightedChip ? theme.palette.primary.light : '#FFF' }}
                avatar={<img src={c.icon} alt='' style={{ height: 16, width: 16 }} />}
                label={c.label}
                onClick={() => handleCategoryClick(c.value)}
              />
            );
          })}
        </Grid>
        <Grid item xs={12}>
          <Button variant='contained' onClick={handleConfirmCategories} fullWidth style={{ height: 55 }}>
            Finished
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default CategoryEditModal;

const useStyles = makeStyles()((theme) => ({
  categoryChip: {
    backgroundColor: '#FFF',
    border: `0.5px solid ${theme.palette.primary.main}`,
    marginRight: 10,
    marginBottom: 10,
    '&:focus': {
      backgroundColor: '#FFF'
    }
  },
  selectedCategoryChip: {
    backgroundColor: theme.palette.primary.light
  },
  categoryChipLabel: {
    fontSize: 14
  }
}));
