import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useStripe } from '@stripe/react-stripe-js';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { makeStyles } from 'tss-react/mui';
import moment from 'moment';
import get from 'lodash/get';
import q83API from '../../api/q83API';
import { useGetUserPaymentHistoryQuery, BraintreeSubscription } from '../../types/generated';
import { getPlanForStripeId } from './getPlan';

import { CheckoutPlan } from './Checkout';
import { useMobileView } from 'hooks/useMobileView';

interface CartProps {
  chosenPlan: CheckoutPlan;
  userType: string;
  signupCouponCode?: string;
  subscription?: BraintreeSubscription;
}

type StripeInvoice = {
  amount_due: number;
};

const styles = makeStyles()((theme) => ({
  bodyContainer: {
    minHeight: 350,
    display: 'flex',
    flexDirection: 'column'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '100%',
    height: '100%',
    paddingBottom: 20
  },
  icon: {
    height: 100,
    width: 100
  },
  title: { margin: 20 },
  discounts: {
    color: theme.palette.primary.main,
    fontSize: 14
  },
  items: {
    color: theme.palette.secondary.main
  },
  error: {
    maxWidth: 350,
    width: '100%',
    color: 'red'
  },
  listItem: {
    paddingRight: 130,
    fontSize: 14
  },
  planListItem: {
    paddingRight: 130,
    fontSize: 14,
    backgroundColor: '#FFFFFF'
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
    margin: 16
  },
  priceTotal: { color: theme.palette.primary.main },
  actionContainer: {
    paddingBottom: 10,
    width: '100%'
  },
  link: {
    textDecoration: 'none'
  },
  button: {
    width: '100%',
    maxWidth: 330,
    textTransform: 'none',
    textDecoration: 'none',
    fontWeight: 700,
    height: 56
  },
  codeButton: {
    color: theme.palette.text.primary,
    backgroundColor: '#FFFFFF',
    textTransform: 'none',
    textDecoration: 'none',
    fontWeight: 600,
    fontSize: 14
  }
}));

const StripeCart = ({ chosenPlan }: CartProps) => {
  const { classes } = styles();
  const stripe = useStripe();
  const [mobileView] = useMobileView();

  const [sessionError, setSessionError] = useState<string | null>(null);
  const [stripeSessionId, setStripeSessionId] = useState<string | undefined>();

  const { data: userData, loading: userLoading, error: userError } = useGetUserPaymentHistoryQuery();

  const user = userData?.myUser;
  const loading = userLoading;
  const errorMessage = sessionError || userError?.message;
  const existingSubscription = user?.stripeCustomer?.firstSubscription;

  const [upcominginvoice, setUpcomingInvoice] = useState<StripeInvoice | undefined>();

  useEffect(() => {
    if (!stripeSessionId && chosenPlan) {
      q83API
        .get(`stripe/kitly-web/session/${chosenPlan.stripeId}`)
        .then((response) => {
          if (response?.data?.sessionId) {
            setStripeSessionId(response.data.sessionId);
            setUpcomingInvoice(response.data.invoice);
          } else {
            setSessionError('No session id returned');
          }
        })
        .catch((ex) => {
          console.log('getStripeSessionId EXCEPTION', ex);
          setSessionError(`Error obtaining session id: ${get(ex, 'response.data.error') || ex.message}`);
        });
    }

    // Disabling warning to prevent render loop
    // eslint-disable-next-line
  }, [stripeSessionId, chosenPlan]);

  const openStripeCheckout = () => {
    if (stripeSessionId) {
      stripe
        ?.redirectToCheckout({
          sessionId: stripeSessionId
        })
        .then((result) => {
          console.log('result', result);
        });
    }
  };

  const stripeSubscription = user?.stripeCustomer?.firstSubscription;

  const currentPlan = getPlanForStripeId(stripeSubscription?.firstItem?.price?.id);

  const isUpdatingPayment = currentPlan && currentPlan.planId === chosenPlan?.id;
  const isUpdatingPlan = currentPlan && currentPlan.planId !== chosenPlan?.id;
  const totalCost = isUpdatingPayment ? Number(currentPlan?.price) : chosenPlan ? chosenPlan.price : 0;
  const billingStartDate = moment(existingSubscription?.current_period_end).format('ll');

  const lineItemStyle = {
    style: {
      fontSize: 14
    }
  };

  return (
    <div className={classes.bodyContainer} style={{ minWidth: mobileView ? 300 : 495 }}>
      <div className={classes.container}>
        <div style={{ width: '100%' }}>
          {chosenPlan && (
            <div>
              <List>
                <ListItem className={classes.planListItem}>
                  <ListItemText
                    primaryTypographyProps={{
                      style: {
                        fontSize: 14
                      }
                    }}
                    primary={chosenPlan.name}
                    secondary={
                      <>
                        <span style={{ fontSize: 14 }}>
                          Billed {chosenPlan.billingFrequency === 12 ? 'yearly' : 'monthly'} -{' '}
                        </span>
                        <span className={classes.discounts}>{`${
                          isUpdatingPayment || isUpdatingPlan ? 'next' : 'starting'
                        }: ${billingStartDate}`}</span>
                      </>
                    }
                  />
                  <ListItemSecondaryAction style={{ bottom: 6, top: 'auto' }}>
                    <PriceDisplay amount={chosenPlan.price} />
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </div>
          )}
          {isUpdatingPlan && <ProrationNotice invoice={upcominginvoice} />}

          {loading && <CircularProgress color='primary' />}
          <div className={classes.discounts}>
            <List>
              {errorMessage && (
                <ListItem className={classes.error}>
                  <ListItemText primaryTypographyProps={lineItemStyle} primary={errorMessage} />
                </ListItem>
              )}
            </List>
          </div>
          <div className={classes.items}>
            <List>
              <ListItem className={classes.listItem}>
                <ListItemText primaryTypographyProps={lineItemStyle} primary={`Sub Total`} />
                <ListItemSecondaryAction>
                  <PriceDisplay amount={totalCost * 0.9} />
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem>
                <ListItemText primaryTypographyProps={lineItemStyle} primary={`Tax inc.`} />
                <ListItemSecondaryAction>
                  <PriceDisplay amount={totalCost * 0.1} />
                </ListItemSecondaryAction>
              </ListItem>
            </List>
            <Divider className={classes.divider} />
            <List>
              <ListItem>
                <ListItemSecondaryAction>
                  <PriceDisplay amount={totalCost} className={classes.priceTotal} />
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </div>
        </div>
        <div className={classes.actionContainer}>
          {isUpdatingPlan ? (
            <Link to='/s/update' className={classes.link}>
              <Button variant='contained' color='primary' className={classes.button}>
                Update Plan
              </Button>
            </Link>
          ) : (
            <Button
              variant='contained'
              color='primary'
              className={classes.button}
              onClick={openStripeCheckout}
              disabled={Boolean(!stripeSessionId)}
            >
              Checkout
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

const PriceDisplay = (props: { amount: number; className?: string }) => {
  return (
    <Grid
      container
      justifyContent='space-between'
      style={{ width: 100, fontSize: 14, fontWeight: 600 }}
      className={props.className}
    >
      <Grid item>USD $</Grid>
      <Grid item>{(typeof props.amount === 'number' ? props.amount : Number(props.amount) || 0).toFixed(2)}</Grid>
    </Grid>
  );
};

const ProrationNotice = ({ invoice }: { invoice: any }) => {
  const endingBalance = get(invoice, 'ending_balance');

  const proratedAmount = endingBalance ? endingBalance * 0.01 : 0;

  if (proratedAmount < 0) {
    return (
      <Typography>{`You will be credited a prorated amount of $${formatPrice(
        0 - proratedAmount
      )} towards your next bill`}</Typography>
    );
  } else if (proratedAmount > 0) {
    return <Typography>{`You will be charged a prorated amount of USD $${formatPrice(proratedAmount)}`}</Typography>;
  }
  return null;
};

const formatPrice = (price?: number) => {
  if (price) {
    return price.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }
  return '0';
};

export default StripeCart;
