import { CustomFontSource } from '@stripe/stripe-js';

const fontList: Array<CustomFontSource> = [
  {
    family: 'Open Sans',
    src: `url("https://media.q-83.com/fonts/OpenSans-Light.ttf") format("truetype")`,
    weight: '200'
  },
  {
    family: 'Open Sans',
    src: `url("https://media.q-83.com/fonts/OpenSans-LightItalic.ttf") format("truetype")`,
    weight: '200',
    style: 'italic'
  },
  {
    family: 'Open Sans',
    src: `url("https://media.q-83.com/fonts/OpenSans-Regular.ttf") format("truetype")`,
    weight: '400'
  },
  {
    family: 'Open Sans',
    src: `url("https://media.q-83.com/fonts/OpenSans-Italic.ttf") format("truetype")`,
    weight: '400',
    style: 'italic'
  },
  {
    family: 'Open Sans',
    src: `url("https://media.q-83.com/fonts/OpenSans-SemiBold.ttf") format("truetype")`,
    weight: '600'
  },
  {
    family: 'Open Sans',
    src: `url("https://media.q-83.com/fonts/OpenSans-SemiBoldItalic.ttf") format("truetype")`,
    weight: '600',
    style: 'italic'
  },
  {
    family: 'Open Sans',
    src: `url("https://media.q-83.com/fonts/OpenSans-Bold.ttf") format("truetype")`,
    weight: '700'
  },
  {
    family: 'Open Sans',
    src: `url("https://media.q-83.com/fonts/OpenSans-BoldItalic.ttf") format("truetype")`,
    weight: '700',
    style: 'italic'
  },
  {
    family: 'Open Sans',
    src: `url("https://media.q-83.com/fonts/OpenSans-ExtraBold.ttf") format("truetype")`,
    weight: '800'
  },
  {
    family: 'Open Sans',
    src: `url("https://media.q-83.com/fonts/OpenSans-ExtraBoldItalic.ttf") format("truetype")`,
    weight: '800',
    style: 'italic'
  }
];

export default fontList;
