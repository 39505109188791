import { useState } from 'react';
import { Grid, Radio, Button, ButtonBase } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { MediaKitTheme } from 'types/generated';
import { SketchPicker } from 'react-color';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { availableThemes } from 'lib/themeVariables';
import { useUserConfig } from 'hooks/useUserConfig';
import LockIcon from '../../../assets/icons/lock.svg';
import useSubscribePopupCtx from 'context/useSubscribePopupCtx';

interface IColorSelector {
  title?: string;
  selectedTheme: MediaKitTheme | null;
  selectedHex: string | null;
  setTheme: (theme: MediaKitTheme | null) => void;
  setHex: (hex: string | null) => void;
  hideLightColors?: boolean;
}

const allThemes = [
  MediaKitTheme.Default,
  MediaKitTheme.Pink,
  MediaKitTheme.Gray,
  MediaKitTheme.Turquoise,
  MediaKitTheme.Paleblue,
  MediaKitTheme.Teal,
  MediaKitTheme.Lightsalmon,
  MediaKitTheme.Lightorange,
  MediaKitTheme.Mediumpurple,
  MediaKitTheme.Linen,
  MediaKitTheme.Snow,
  MediaKitTheme.Salmon
];

const darkThemes = [
  MediaKitTheme.Pink,
  MediaKitTheme.Gray,
  MediaKitTheme.Turquoise,
  MediaKitTheme.Paleblue,
  MediaKitTheme.Lightorange,
  MediaKitTheme.Mediumpurple,
  MediaKitTheme.Salmon
];

const ColorSelector = ({ title, selectedTheme, selectedHex, setTheme, setHex, hideLightColors }: IColorSelector) => {
  const { classes } = styles();
  const { classes: radioClasses } = radioStyles();
  const { config } = useUserConfig();
  const { setSelectedPlanId } = useSubscribePopupCtx();

  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [isColorsExpanded, setIsColorsExpanded] = useState(false);

  const themesToUse = hideLightColors ? darkThemes : allThemes;

  const themesToDisplay = isColorsExpanded ? themesToUse : themesToUse.slice(0, 4);

  return (
    <Grid container direction='row' alignItems='center' justifyContent='space-between' spacing={2}>
      <Grid item container justifyContent='space-between'>
        <Grid item xs={5}>
          {title && title}
        </Grid>
        <Grid item xs={7} container justifyContent='flex-end'>
          {themesToDisplay.map((key) => {
            return (
              <Radio
                key={key}
                checked={selectedTheme === key}
                onChange={(event: any) => {
                  setHex(null);
                  setTheme(event.target.value as MediaKitTheme);
                }}
                value={key}
                name='inputColourTheme'
                inputProps={{ 'aria-label': key }}
                classes={radioClasses}
                style={{ backgroundColor: availableThemes[key as MediaKitTheme].primary }}
                icon={<></>}
                checkedIcon={<></>}
              />
            );
          })}
        </Grid>
      </Grid>
      <Grid item container justifyContent='flex-end'>
        <ButtonBase onClick={() => setIsColorsExpanded(!isColorsExpanded)}>
          {isColorsExpanded ? (
            <>
              Less
              <ExpandLessIcon />
            </>
          ) : (
            <>
              More
              <ExpandMoreIcon />
            </>
          )}
        </ButtonBase>
      </Grid>
      <Grid item container justifyContent='flex-end'>
        <Button
          variant='contained'
          size='small'
          onClick={
            config.custom_media_kits ? () => setIsPickerOpen(!isPickerOpen) : () => setSelectedPlanId('kitly-usd-1')
          }
          className={classes.customButton}
          style={{ backgroundColor: config.custom_media_kits ? undefined : 'rgb(208, 180, 189)' }}
        >
          {config.custom_media_kits ? (
            isPickerOpen ? (
              'Close'
            ) : (
              'Custom Colour'
            )
          ) : (
            <Grid container justifyContent='space-between' alignItems='center'>
              <Grid item>
                <div className={classes.customIcon} />
              </Grid>
              <Grid item>Custom Colour</Grid>
              <Grid item>
                <img src={LockIcon} alt='locked element' className={classes.customIcon} />
              </Grid>
            </Grid>
          )}
        </Button>
      </Grid>
      {isPickerOpen && (
        <Grid item container justifyContent='center'>
          <SketchPicker
            color={selectedHex || undefined}
            onChangeComplete={(color) => {
              setHex(color.hex);
              setTheme(null);
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};

const styles = makeStyles()((theme) => ({
  customButton: {
    color: '#333333',
    backgroundColor: theme.palette.secondary.main,
    width: 165,
    height: 25,
    fontSize: 10
  },
  customIcon: {
    width: 18,
    height: 18,
    display: 'block'
  }
}));

const radioStyles = makeStyles()((theme) => ({
  root: {
    padding: 0,
    height: '25px',
    width: '25px',
    marginLeft: '6px',
    marginBottom: '6px',
    position: 'relative',
    '& .MuiIconButton-label': {
      opacity: 0
    },
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      zIndex: 0,
      left: -3,
      top: -3,
      width: 'calc(100% + 2px)',
      height: 'calc(100% + 2px)',
      border: '2px solid transparent',
      borderRadius: '50%',
      transition: 'all 0.25s'
    },
    '&.Mui-checked::before': {
      border: `2px solid ${theme.palette.primary.main}`
    }
  }
}));

export default ColorSelector;
