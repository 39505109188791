import { useState, useEffect, useMemo, useCallback, useReducer } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useUpdateMediaKitDraftConfigMutation,
  usePublishMediaKitDraftConfigMutation,
  useCreateElementMutation,
  useGetMediaKitConfigLazyQuery,
  MediaKitElement,
  MediaKitTheme,
  useDeleteElementMutation
} from 'types/generated';

import {
  CircularProgress,
  Container,
  Button,
  Grid,
  TextField,
  Typography,
  Box,
  Paper,
  IconButton,
  ButtonBase,
  createTheme,
  Theme,
  ThemeProvider
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import EyeIcon from '@mui/icons-material/RemoveRedEye';

import MediaKitEditBox from './MediaKitEditBox';
import ShareMediaKitModal from 'components/pages/MediaKitsPage/ShareMediaKitModal';
import FakeButton from 'components/FakeButton';
import { availableElements } from 'lib/availableElements';
import { availableThemes } from 'lib/themeVariables';

import KitHeader from './KitHeader';
import KitFooter from './KitFooter';
import KitEmptyState from './KitEmptyState';
import ArrowBack from '@mui/icons-material/ArrowBack';
import MoveIcon from '../../../assets/icons/move.svg';
import LockIcon from '../../../assets/icons/lock.svg';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import usePrevious from 'hooks/usePrevious';
import { find, omitBy, isEmpty, isEqual } from 'lodash';
import { useSnackbar } from 'notistack';
import { SNACK_BAR_MESSAGES } from 'lib/snackbarMessages';
import AppConfig from 'config/appConfig';
import { useErrorHandlerHook } from 'hooks/useErrorCatchHook';
import { FileUploadProvider } from 'context/fileUploadingContext';
import { useUserConfig } from 'hooks/useUserConfig';
import useSubscribePopupCtx from 'context/useSubscribePopupCtx';

import './MediaKitBuilder.css';
import ColorSelector from './ColorSelector';

const noop = () => {};

const dropReorder = (list: Array<any>, startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const dropInsert = (source: Array<any>, destination: Array<any>, droppableSource: any, droppableDestination: any) => {
  if (source[droppableSource.index]) {
    return elementInsert(
      destination,
      { ...source[droppableSource.index], content: { type: source[droppableSource.index].type } },
      droppableDestination.index
    );
  }
  return destination;
};

const elementInsert = (array: Array<any>, obj: any, index: number) => {
  return [...array.slice(0, index), obj, ...array.slice(index)];
};

const getListStyle = (isDraggingOver?: boolean) => ({
  opacity: isDraggingOver ? 0.4 : 1,
  outline: isDraggingOver ? '5px dashed rgba(0,0,0,0.3)' : 'none'
});

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  outline: 'none',
  userSelect: 'none',
  padding: 0,
  margin: `0 0 0 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'transparent',

  // styles we need to apply on draggables
  ...draggableStyle
});

const defaultTitle = 'My Media Kit';

type MediaKitElementContent = Omit<MediaKitElement, '_id'> & { _id?: string };

function elementReducer(state: Array<MediaKitElementContent>, action: any) {
  switch (action.type) {
    case 'update':
      return state.map((o, i) => {
        if (i === action.index) {
          return { ...o, ...action.payload };
        }
        return o;
      });
    case 'replace':
      return state.map((o, i) => {
        if (i === action.index) {
          return action.payload;
        }
        return o;
      });
    case 'remove':
      return state.filter((o) => {
        return action.payload ? o._id !== action.payload._id : true;
      });
    case 'set':
      return action.payload;
    default:
      throw new Error();
  }
}

const MediaKitBuilder = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [colorTheme, setColorTheme] = useState<MediaKitTheme | null>(null);
  const [colorHex, setColorHex] = useState<string | null>(null);
  const [backgroundColorTheme, setBackgroundColorTheme] = useState<MediaKitTheme | null>(null);
  const [backgroundColorHex, setBackgroundColorHex] = useState<string | null>(null);

  const [elements, dispatch] = useReducer(elementReducer, []);
  const [title, setTitle] = useState(defaultTitle);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const previousElements = usePrevious(elements);
  const { apolloHandler } = useErrorHandlerHook();
  const { config } = useUserConfig();
  const { setSelectedPlanId } = useSubscribePopupCtx();

  const themePalette = useMemo(
    () => ({
      primary: colorHex
        ? colorHex
        : colorTheme && availableThemes[colorTheme]
        ? availableThemes[colorTheme].primary
        : availableThemes.DEFAULT.primary,
      background: backgroundColorHex
        ? backgroundColorHex
        : backgroundColorTheme && availableThemes[backgroundColorTheme]
        ? availableThemes[backgroundColorTheme].primary
        : availableThemes.DEFAULT.primary
    }),
    [colorHex, backgroundColorHex, backgroundColorTheme, colorTheme]
  );

  const customTheme = useMemo(() => {
    return (theme: Theme) =>
      createTheme({
        ...theme,
        palette: {
          ...theme.palette,
          primary: {
            ...theme.palette.primary,
            main: themePalette.primary,
            light: themePalette.primary,
            dark: themePalette.primary
          },
          divider: themePalette.primary,
          background: {
            ...theme.palette.background,
            default: themePalette.background
          }
        }
      });
  }, [themePalette]);

  const { classes } = styles();

  const [getMediaKit, { data, loading, error, refetch }] = useGetMediaKitConfigLazyQuery({
    fetchPolicy: 'network-only'
  });
  const [updateMediaKit, { loading: updateLoading, error: updateError }] = useUpdateMediaKitDraftConfigMutation({
    onCompleted: () => refetch()
  });
  const [publishMediaKit, { loading: publishLoading, error: publishError }] = usePublishMediaKitDraftConfigMutation({
    onCompleted: () => refetch()
  });

  const configId = params.id;

  useEffect(() => {
    if (error) {
      apolloHandler(error);
    }
  }, [error, apolloHandler]);

  useEffect(() => {
    if (updateError) {
      apolloHandler(updateError);
    }
  }, [updateError, apolloHandler]);

  useEffect(() => {
    if (publishError) {
      apolloHandler(publishError);
    }
  }, [publishError, apolloHandler]);

  const mediaKitConfig = useMemo(() => {
    if (data && data.getMediaKitConfig) {
      if (data.getMediaKitConfig.draft) {
        const cleanedDraft = omitBy(data.getMediaKitConfig.draft, (v) => Boolean(typeof v === 'undefined'));
        return {
          ...data.getMediaKitConfig,
          ...cleanedDraft
        };
      }
      return data.getMediaKitConfig;
    }
    return;
  }, [data]);

  const hasDraftUpdated = useMemo(() => {
    if (!mediaKitConfig) return false;
    const { draft } = mediaKitConfig;
    const isDraftElements = elements?.reduce((acc: boolean, curr: MediaKitElement) => {
      if (curr.draft) acc = true;
      return acc;
    }, false);
    const haveElementsUpdated = Boolean(!isEmpty(previousElements) && !isEqual(elements, previousElements));
    const hasTitleUpdated = Boolean(draft && draft.title !== title) || Boolean(mediaKitConfig?.title !== title);
    const hasColorThemeUpdated =
      Boolean(draft && draft.colorTheme !== colorTheme) || Boolean(mediaKitConfig?.colorTheme !== colorTheme);
    const isUpdated = Boolean(isDraftElements || haveElementsUpdated || hasTitleUpdated || hasColorThemeUpdated);
    return isUpdated;
  }, [previousElements, elements, mediaKitConfig, title, colorTheme]);

  const handleUpdate = useCallback(
    (callback = noop) => {
      if (mediaKitConfig?._id) {
        const titleToSave = title || defaultTitle;
        updateMediaKit({
          variables: {
            id: mediaKitConfig._id,
            updates: {
              elementIds: elements.map((o: MediaKitElement) => o._id),
              colorTheme,
              colorHex,
              backgroundColorTheme,
              backgroundColorHex,
              title: titleToSave
            }
          }
        }).then(() => {
          if (callback) callback();
        });
      }
    },
    [mediaKitConfig, elements, colorTheme, colorHex, backgroundColorTheme, backgroundColorHex, title, updateMediaKit]
  );

  useEffect(() => {
    if (mediaKitConfig) {
      if (mediaKitConfig.elements) {
        dispatch({
          type: 'set',
          payload: mediaKitConfig.elements.map((o) => {
            let returnElement = o;
            let content: any;
            try {
              if (o?.draft) {
                returnElement = { ...o, ...omitBy(o.draft, (v) => Boolean(typeof v === 'undefined')) };
              }
              if (returnElement?.stringifiedContent) {
                content = JSON.parse(returnElement.stringifiedContent);
              }
            } catch (ex) {
              console.log('Set element EXCEPTION', ex);
            }
            return { ...returnElement, content };
          })
        });
      }

      if (mediaKitConfig.colorTheme) {
        setColorTheme(mediaKitConfig.colorTheme);
      }
      if (mediaKitConfig.colorHex) {
        setColorHex(mediaKitConfig.colorHex);
      }
      if (mediaKitConfig.backgroundColorTheme) {
        setBackgroundColorTheme(mediaKitConfig.backgroundColorTheme);
      }
      if (mediaKitConfig.backgroundColorHex) {
        setBackgroundColorHex(mediaKitConfig.backgroundColorHex);
      }
      if (mediaKitConfig.title) {
        setTitle(mediaKitConfig.title);
      }
      // Detect if the media kit was just created
      if (mediaKitConfig.updated_at === mediaKitConfig.created_at) {
        enqueueSnackbar(SNACK_BAR_MESSAGES.MEDIA_KIT_CREATED);
      }
    }
  }, [mediaKitConfig, enqueueSnackbar]);

  useEffect(() => {
    if (configId && isEmpty(mediaKitConfig)) {
      getMediaKit({ variables: { id: configId } });
    }
  }, [configId, getMediaKit, mediaKitConfig]);

  const toggleShareModal = useCallback(() => {
    setIsShareModalOpen(!isShareModalOpen);
  }, [isShareModalOpen, setIsShareModalOpen]);

  const handlePublish = useCallback(async () => {
    if (mediaKitConfig?._id) {
      if (hasDraftUpdated) {
        handleUpdate(async () => {
          await publishMediaKit({ variables: { id: mediaKitConfig._id! } });
          toggleShareModal();
          enqueueSnackbar(SNACK_BAR_MESSAGES.MEDIA_KIT_PUBLISHED);
        });
      } else {
        await publishMediaKit({ variables: { id: mediaKitConfig._id } });
        toggleShareModal();
        enqueueSnackbar(SNACK_BAR_MESSAGES.MEDIA_KIT_PUBLISHED);
      }
      window.open(`${AppConfig.MEDIAKIT_URL}/#/view/${mediaKitConfig._id}`, '_blank');
    }
  }, [mediaKitConfig, publishMediaKit, toggleShareModal, hasDraftUpdated, handleUpdate, enqueueSnackbar]);

  const onDragEnd = useCallback(
    (result) => {
      const { source, destination } = result;

      if (destination) {
        // dropped within preview - run reorder
        if (destination.droppableId === 'KitBuilderPreview' && source.droppableId === destination.droppableId) {
          dispatch({ type: 'set', payload: dropReorder(elements, source.index, destination.index) });
        }

        // drop item from cards to preview
        if (source.droppableId === 'KitBuilderCards' && destination.droppableId === 'KitBuilderPreview') {
          dispatch({ type: 'set', payload: dropInsert(availableElements, elements, source, destination) });
        }
      }
    },
    [elements, dispatch]
  );

  const hasLinkedAccountsAdded = useMemo(
    () => Boolean(find(elements, { content: { type: 'LINKED_ACCOUNTS' } })),
    [elements]
  );

  const hasDemographicsOnlyAdded = useMemo(
    () => Boolean(find(elements, { content: { type: 'AUDIENCE_DEMOGRAPHICS' } })),
    [elements]
  );

  return (
    <FileUploadProvider>
      {isShareModalOpen && (
        <ShareMediaKitModal
          selectedMediaKitId={configId}
          onExternalToggle={toggleShareModal}
          externalShareMetricsOpen={isShareModalOpen}
          isModalOnly={true}
          isNewlyPublished={true}
        />
      )}
      <Box className={classes.header}>
        <Container>
          <Box py={2}>
            <Grid container alignItems='center'>
              <Grid item xs>
                <Button onClick={() => navigate('/media-kits')} startIcon={<ArrowBack />}>
                  Back to Kitly
                </Button>
              </Grid>
              <Grid item xs>
                <TextField
                  data-cy={'mediakit_title-input'}
                  id='title'
                  value={title}
                  onChange={(e: any) => setTitle(e?.target?.value)}
                  margin='none'
                  fullWidth={true}
                  variant='filled'
                  inputProps={{ className: classes.titleInput }}
                  InputProps={{ disableUnderline: true }}
                />
              </Grid>
              <Grid item xs>
                <Grid container justifyContent='flex-end' spacing={3}>
                  {mediaKitConfig && (
                    <Grid item>
                      {Boolean(mediaKitConfig.status === 'DRAFT' || hasDraftUpdated) ? (
                        <Button variant='contained' color='primary' onClick={handlePublish}>
                          Publish & View
                        </Button>
                      ) : (
                        <ShareMediaKitModal selectedMediaKitId={configId}>
                          <FakeButton variant='contained'>Share</FakeButton>
                        </ShareMediaKitModal>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Container maxWidth='md'>
        {Boolean(loading || updateLoading || publishLoading) && <CircularProgress />}
        {error && <Typography className={classes.error}>{String(error.message)}</Typography>}
        {updateError && <Typography className={classes.error}>{String(updateError.message)}</Typography>}
        {publishError && <Typography className={classes.error}>{String(publishError.message)}</Typography>}
        <Box py={5}>
          {mediaKitConfig && (
            <Box py={6}>
              <Button
                variant='outlined'
                color='primary'
                size='small'
                endIcon={updateLoading ? null : <EyeIcon />}
                onClick={() => {
                  const openPreview = () =>
                    window.open(`${AppConfig.MEDIAKIT_URL}/#/preview/${mediaKitConfig._id}`, '_blank');
                  if (hasDraftUpdated) {
                    handleUpdate(() => openPreview());
                  } else {
                    openPreview();
                  }
                }}
                style={{ height: 32, minWidth: 160 }}
              >
                {updateLoading ? <CircularProgress size={20} /> : 'Save & Preview'}
              </Button>
            </Box>
          )}
          <DragDropContext onDragEnd={onDragEnd}>
            <Grid container spacing={5}>
              <Grid item xs md={7}>
                <ThemeProvider theme={customTheme}>
                  <Box className={classes.builderPreview}>
                    <Box mb={5}>
                      <Typography align='center' variant='h6'>
                        DROP YOUR CARDS HERE
                      </Typography>
                    </Box>
                    <KitHeader />

                    <Droppable droppableId='KitBuilderPreview'>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          className={classes.builderPreviewDroppable}
                          style={{ ...getListStyle(snapshot.isDraggingOver), backgroundColor: themePalette.background }}
                        >
                          {elements.map((o: MediaKitElement, i: number) => {
                            return (
                              <ItemTemplatesCard
                                key={i}
                                index={i}
                                element={o}
                                classes={classes}
                                mediaKitId={mediaKitConfig?._id ? mediaKitConfig._id : null}
                                handleRemove={(element) => {
                                  dispatch({ type: 'remove', payload: element });
                                  handleUpdate();
                                }}
                                handleUpdate={(element, index) => dispatch({ type: 'update', payload: element, index })}
                              />
                            );
                          })}
                          {!Boolean(elements.length) && <KitEmptyState />}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                    <KitFooter />
                  </Box>
                </ThemeProvider>
              </Grid>
              <Grid item xs md={5}>
                <Box mb={5}>
                  <Typography align='center' variant='h6'>
                    CARDS
                  </Typography>
                </Box>
                <Paper className={classes.cardTheme}>
                  <ColorSelector
                    title='Colour Theme'
                    selectedTheme={colorTheme}
                    selectedHex={colorHex}
                    setTheme={setColorTheme}
                    setHex={setColorHex}
                    hideLightColors
                  />
                </Paper>
                <Paper className={classes.cardTheme}>
                  <ColorSelector
                    title='Background Theme'
                    selectedTheme={backgroundColorTheme}
                    selectedHex={backgroundColorHex}
                    setTheme={setBackgroundColorTheme}
                    setHex={setBackgroundColorHex}
                  />
                </Paper>
                <Droppable droppableId='KitBuilderCards'>
                  {(provided) => (
                    <Grid container ref={provided.innerRef} style={getListStyle(false)} spacing={3}>
                      {availableElements.map((o, index) => {
                        const alreadyAdded =
                          (o.type === 'LINKED_ACCOUNTS' && hasLinkedAccountsAdded) ||
                          (o.type === 'AUDIENCE_DEMOGRAPHICS' && hasDemographicsOnlyAdded);

                        const isBlockedByApar =
                          o.type === 'AUDIENCE_DEMOGRAPHICS' && hasLinkedAccountsAdded ? true : false;

                        const isRestricted = config.custom_media_kits ? false : o.restricted;

                        const elementDisabled = alreadyAdded || isRestricted || isBlockedByApar;
                        return (
                          <Draggable key={o.title} draggableId={o.title} index={index} isDragDisabled={elementDisabled}>
                            {(provided) => (
                              <Grid
                                item
                                xs={o.width || 12}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <ButtonBase
                                  onClick={() => setSelectedPlanId('kitly-usd-1')}
                                  disabled={!elementDisabled}
                                  className={classes.cardButton}
                                >
                                  <Paper className={elementDisabled ? classes.disabledCard : classes.itemCard}>
                                    <Grid container direction='column'>
                                      <Grid item container justifyContent='space-between'>
                                        <Grid item>
                                          <b>{o.title}</b>
                                        </Grid>
                                        <Grid item>
                                          <Grid container justifyContent='flex-end'>
                                            {isRestricted && (
                                              <Grid item>
                                                <Typography variant='caption'>Upgrade to Unlock</Typography>
                                              </Grid>
                                            )}
                                            <Grid item>
                                              {elementDisabled ? (
                                                <IconButton disabled={true} className={classes.cardIcon}>
                                                  <img
                                                    src={LockIcon}
                                                    alt='locked element'
                                                    className={classes.customIcon}
                                                  />
                                                </IconButton>
                                              ) : (
                                                <IconButton disabled={true} className={classes.cardIcon}>
                                                  <img
                                                    src={MoveIcon}
                                                    alt='move element'
                                                    className={classes.customIcon}
                                                  />
                                                </IconButton>
                                              )}
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Grid item>
                                        {o.subtitle && (
                                          <Typography className={classes.cardSubtitle}>{o.subtitle}</Typography>
                                        )}
                                      </Grid>
                                      <Grid item>
                                        {isBlockedByApar ? (
                                          <Typography variant='caption'>
                                            Remove the APAR card to Add this card
                                          </Typography>
                                        ) : alreadyAdded ? (
                                          <Typography variant='caption'>Already added</Typography>
                                        ) : null}
                                      </Grid>
                                    </Grid>
                                  </Paper>
                                </ButtonBase>
                              </Grid>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </Grid>
                  )}
                </Droppable>
              </Grid>
            </Grid>
          </DragDropContext>
        </Box>
      </Container>
    </FileUploadProvider>
  );
};

interface IItemTemplatesCard {
  index: number;
  element: MediaKitElement;
  handleUpdate: (element: MediaKitElementContent, index: number) => void;
  handleRemove: (element: MediaKitElementContent) => void;
  classes: any;
  mediaKitId: string | null;
}

const ItemTemplatesCard = ({ index, element, handleUpdate, handleRemove, classes, mediaKitId }: IItemTemplatesCard) => {
  const [isEditing, setIsEditing] = useState(false);
  const { apolloHandler } = useErrorHandlerHook();

  const [createElement, { error: createElementError }] = useCreateElementMutation({
    onCompleted: (createElementData) => {
      if (createElementData && createElementData.createElement && element._id !== createElementData.createElement) {
        handleUpdate({ _id: createElementData.createElement }, index);
        setIsEditing(true);
      }
    }
  });

  const [deleteElement, { error: deleteElementError, loading: deleteLoading }] = useDeleteElementMutation({
    onCompleted: (deleteElementData) => {
      if (deleteElementData.deleteElement && element._id !== deleteElementData.deleteElement) {
        handleRemove({ _id: element._id });
      }
    }
  });

  useEffect(() => {
    if (deleteElementError) {
      apolloHandler(deleteElementError);
    }
  }, [deleteElementError, apolloHandler]);

  useEffect(() => {
    if (createElementError) {
      apolloHandler(createElementError);
    }
  }, [createElementError, apolloHandler]);

  const cardId = element?._id;

  useEffect(() => {
    if (!element?._id && element.content?.type && mediaKitId) {
      createElement({ variables: { type: element.content.type, ...element, mediaKitId, index } });
    }
  }, [createElement, element, mediaKitId, index]);

  const handleSave = useCallback(
    (content: any) => {
      handleUpdate({ content }, index);
      setIsEditing(false);
    },
    [index, handleUpdate]
  );

  const handleDelete = useCallback(() => {
    deleteElement({ variables: { id: element._id } });
  }, [element, deleteElement]);

  if (!cardId) {
    return <CircularProgress />;
  }

  return (
    <Draggable draggableId={cardId} key={cardId} index={index} isDragDisabled={isEditing}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
        >
          <MediaKitEditBox
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            handleDelete={handleDelete}
            elementId={cardId}
            element={element}
            onUpdate={handleSave}
            deleteLoading={deleteLoading}
          />
          {createElementError && (
            <Typography className={classes.error}>{String(createElementError.message)}</Typography>
          )}
        </div>
      )}
    </Draggable>
  );
};

const styles = makeStyles()((theme) => ({
  error: {
    color: 'red'
  },
  header: {
    backgroundColor: '#ffffff',
    boxShadow: '0 0 3px 0 rgba(0,0,0,0.3)'
  },
  builderPreview: {
    overflowX: 'hidden',
    overflowY: 'auto'
    // maxHeight: '90vh'
  },
  builderPreviewDroppable: {
    minHeight: 260,
    // border: '0.5px solid #ACACAC',
    borderTop: 'none',
    backgroundColor: '#F7F6F9',
    padding: '8px',
    transition: 'all 0.3s',
    position: 'relative'
  },
  cardTheme: {
    padding: '15px 20px',
    marginBottom: '15px'
  },
  cardSubtitle: {
    fontSize: 14,
    color: '#333333',
    fontStyle: 'italic',
    opacity: 0.5,
    marginTop: 6,
    textAlign: 'left'
  },
  cardButton: {
    width: '100%'
  },
  itemCard: {
    padding: '15px 10px 15px 20px',
    width: '100%'
  },
  disabledCard: {
    padding: '15px 10px 15px 20px',
    color: theme.palette.info.dark,
    borderColor: '#97979780',
    borderWidth: 1,
    backgroundColor: '#D8D8D880',
    width: '100%'
  },
  previewMenu: {
    minWidth: 'auto',
    letterSpacing: '1px',
    fontWeight: 500,
    fontSize: '16px'
  },
  previewMainImg: {
    width: '100%'
  },
  previewHeading: {
    textAlign: 'center',
    padding: '30px'
  },
  customIcon: {
    width: 18,
    height: 18
  },
  titleInput: {
    background: '#FFF',
    textAlign: 'center',
    padding: 7,
    '&:hover': {
      background: '#F7F7FA',
      cursor: 'pointer'
    },
    '&:focus': {
      background: '#F7F7FA',
      cursor: 'pointer'
    }
  },
  cardIcon: {
    paddingTop: 0,
    paddingRight: 4
  }
}));

export default MediaKitBuilder;
