import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import FakeButton from 'components/FakeButton';
import { BrandCreation } from './BrandCreation';

export const Header = () => {
  const { classes } = useStyles();

  return (
    <Grid item xs={12} className={classes.header}>
      <Typography variant='h2'>My Connections</Typography>
      <BrandCreation>
        <FakeButton variant='contained'>
          <Typography className={classes.buttonText}>Add</Typography>
        </FakeButton>
      </BrandCreation>
    </Grid>
  );
};

const useStyles = makeStyles()((theme) => {
  return {
    header: {
      marginTop: 14,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    buttonText: {
      color: '#FFFFFF',
      fontSize: 14,
      fontWeight: 500
    }
  };
});
