import { useState, useMemo, useEffect } from 'react';

import { Avatar, Badge, CircularProgress, Grid, Box, TextField, Tooltip, Autocomplete, useTheme } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';

import { intersection, isEqual, isEmpty, find, filter, compact } from 'lodash';
import { useGetProfilesListQuery, Profile, SocialChannel } from 'types/generated';
import { isProfile } from 'types/custom';
import InstagramIcon from 'assets/images/icon-instagram.svg';
import FacebookIcon from 'assets/images/icon-facebook.svg';
import TiktokIcon from 'assets/icons/tikChannel.svg';
import { CustomChip } from './CustomChip';
import YouTubeIcon from 'assets/images/yt_logo.svg';
import { COLORS } from 'lib/constants';

const backupUserImg = 'https://media.q-83.com/site/user.png';

const styles = makeStyles()((theme) => ({
  badgeIcon: {
    width: 18,
    height: 18,
    borderRadius: '50%',
    border: `1px solid ${theme.palette.background.default}`,
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  badgeImage: {
    width: 10,
    height: 10
  },
  profileAvatar: {
    width: '100%',
    height: '100%'
  },
  text: {
    textAlign: 'left'
  }
}));

const badgeStyles = makeStyles()({
  root: {
    marginRight: 5,
    width: 30,
    height: 30
  },

  badge: {
    bottom: 0,
    right: 'auto',
    left: '100%',
    transform: 'translateX(-50%) translateY(0)',
    padding: 0
  }
});

interface FormProps {
  emptyLabel?: string;
  channels?: string[];
  selectedId?: string | undefined;
  setSelectedId?: (selectedId: string | undefined) => void;
  selectedIds?: string[];
  setSelectedIds?: (selectedIds: string[]) => void;
  isAllSelected?: boolean;
  profileIds?: string[];
  profileTags?: string[];
  shouldPreselectPrimaryAccount?: boolean;
  errorText?: string;
}

const selectLabel = 'Assign to Social Profile';

const ProfileSelector = ({
  channels,
  emptyLabel,
  selectedId,
  setSelectedId,
  selectedIds,
  setSelectedIds,
  isAllSelected,
  profileIds,
  profileTags,
  shouldPreselectPrimaryAccount,
  errorText
}: FormProps) => {
  const [isInitialised, setIsInitialised] = useState(false);
  const theme = useTheme();

  const {
    data: profilesData,
    loading: profilesLoading,
    error: profilesError
  } = useGetProfilesListQuery({
    fetchPolicy: 'no-cache'
  });

  const profilesList = useMemo(() => {
    return profilesData?.getProfiles || [];
  }, [profilesData]);

  const filteredProfiles = useMemo(() => {
    if (!profilesLoading) {
      const profiles = filter(profilesList, (o) => {
        if (!isEmpty(profileTags)) {
          if (!Boolean(o.profileNote?.categories && !isEmpty(intersection(o.profileNote.categories, profileTags!)))) {
            return false;
          }
        } else if (!isEmpty(profileIds)) {
          if (!Boolean(o._id && profileIds!.includes(o._id))) {
            return false;
          }
        }

        return Boolean(isEmpty(channels) || (o?.profileType && channels?.includes(o.profileType.toLowerCase())));
      });

      const filteredIds = profiles.map((o) => o._id);

      if (setSelectedIds && selectedIds) {
        if (isAllSelected) {
          if (!isEqual(filteredIds, selectedIds)) {
            setSelectedIds(compact(filteredIds));
          }
        } else {
          const commonIds = intersection(filteredIds, selectedIds);

          if (!isEqual(commonIds, selectedIds)) {
            setSelectedIds(compact(commonIds));
          }
        }
      } else if (setSelectedId && selectedId) {
        if (!filteredIds.includes(selectedId)) {
          setSelectedId(undefined);
        }
      }

      return profiles;
    }
    return [];
  }, [
    profilesList,
    channels,
    setSelectedIds,
    selectedIds,
    setSelectedId,
    selectedId,
    profilesLoading,
    isAllSelected,
    profileIds,
    profileTags
  ]);

  const selectedProfiles = useMemo(() => {
    if (selectedIds) {
      return filter(filteredProfiles, (o) => Boolean(o._id && selectedIds.includes(o._id)));
    }
    if (selectedId) {
      const profile = find(filteredProfiles, { _id: selectedId });
      if (profile) {
        return [profile];
      }
    }
    return [];
  }, [filteredProfiles, selectedIds, selectedId]);

  // Concat any personal profiles:
  const cachedProfiles = useMemo(
    () => filteredProfiles.filter((profile) => Boolean(profile.cache_completed_at)),
    [filteredProfiles]
  );

  const uncachedProfiles = useMemo(
    () =>
      filteredProfiles.reduce((acc, curr) => {
        if (curr.displayName && !curr.cache_completed_at) {
          // @ts-ignore: type is checked on return -- TOFIX JAMES
          acc.push(curr.displayName);
        }
        return acc;
      }, []),
    [filteredProfiles]
  );

  const fieldOptions = useMemo(
    () => [
      '',
      ...compact(cachedProfiles).sort((a, b) => {
        if (a?.displayName && b?.displayName) {
          return a.displayName.localeCompare(b.displayName);
        }
        return 0;
      })
    ],
    [cachedProfiles]
  );

  useEffect(() => {
    if (!isInitialised && shouldPreselectPrimaryAccount && cachedProfiles.length && profilesList.length) {
      setIsInitialised(true);
      if (profilesList[0]._id && cachedProfiles[0]._id === profilesList[0]._id) {
        if (setSelectedId && !selectedId) {
          setSelectedId(profilesList[0]._id);
        }
        if (setSelectedIds && selectedIds?.length === 0) {
          setSelectedIds([profilesList[0]._id]);
        }
      }
    }

    // Disable next line to only activate when cachedProfiles change
    // eslint-disable-next-line
  }, [shouldPreselectPrimaryAccount, cachedProfiles, profilesList, setIsInitialised, isInitialised]);

  if (profilesLoading) {
    return (
      <Grid item container style={{ minHeight: '33px' }} alignItems='center' justifyContent='center'>
        <Grid item>
          <CircularProgress style={{ height: 15, width: 15 }} />
        </Grid>
      </Grid>
    );
  }

  if (profilesError) {
    return (
      <Tooltip title={String(profilesError)}>
        <Grid container style={{ minHeight: '33px' }} alignItems='center' justifyContent='flex-start'>
          <Grid item>
            <p style={{ margin: 0 }}>
              There was an error <InfoIcon />
            </p>
          </Grid>
        </Grid>
      </Tooltip>
    );
  }

  if (isEmpty(cachedProfiles)) {
    return (
      <Tooltip title={'Please either connect a social account or add one or more talent to your talent database.'}>
        <Grid container style={{ minHeight: '33px' }} alignItems='center' justifyContent='flex-start'>
          <Grid item>
            <p style={{ margin: 0 }}>
              No social accounts <InfoIcon />
            </p>
          </Grid>
        </Grid>
      </Tooltip>
    );
  }
  return (
    <Grid container style={{ minHeight: '33px' }}>
      <Grid item xs={12}>
        {setSelectedIds ? (
          <Autocomplete
            filterSelectedOptions
            data-cy='ambassador-handle-select'
            loading={profilesLoading}
            value={selectedProfiles}
            multiple
            fullWidth
            disableClearable
            disableCloseOnSelect
            renderInput={(params) => <TextField {...params} label={emptyLabel || selectLabel} variant='standard' />}
            popupIcon={<ExpandMoreIcon style={{ fontSize: 20 }} />}
            renderTags={(tagValue, getTagProps) => {
              if (isAllSelected) {
                return <CustomChip label={'All talent'} />;
              }
              return tagValue.map((option, index) => (
                <CustomChip {...getTagProps({ index })} label={option.displayName || emptyLabel} />
              ));
            }}
            // @ts-ignore: type is checked on return -- TOFIX JAMES
            options={fieldOptions}
            onChange={(_, values: Profile[]) => {
              if (setSelectedIds) {
                setSelectedIds(compact(values.map((e) => e._id)));
              }
            }}
            getOptionLabel={(option: Profile) => (option ? `${option.displayName}` : emptyLabel || selectLabel)}
            renderOption={(props, option: Profile) => (
              <li {...props}>{!option._id ? option.displayName || emptyLabel : <ProfileOption profile={option} />}</li>
            )}
          />
        ) : (
          <Autocomplete
            data-cy='ambassador-handle-select'
            loading={profilesLoading}
            value={selectedProfiles[0] || ''}
            fullWidth
            disableCloseOnSelect
            renderInput={(params) => <TextField {...params} label={emptyLabel || selectLabel} variant='standard' />}
            popupIcon={<ExpandMoreIcon style={{ fontSize: 20 }} />}
            renderTags={(options: Array<Profile | string>) =>
              options.map((option) => (isProfile(option) ? option?.displayName || '' : emptyLabel)).join(', ')
            }
            options={fieldOptions}
            onChange={(_, value) => {
              if (setSelectedId && isProfile(value) && value._id) {
                setSelectedId(value._id);
              }
            }}
            getOptionLabel={(option: Profile | string) => {
              if (isProfile(option)) {
                if (option.displayName) {
                  return option.displayName;
                }
              }
              return emptyLabel || selectLabel;
            }}
            renderOption={(props, option: Profile | string) => (
              <li {...props}>{isProfile(option) ? <ProfileOption profile={option} /> : emptyLabel}</li>
            )}
          />
        )}
        {errorText ? (
          <Grid item xs={'auto'} style={{ minHeight: '22px' }}>
            <span style={{ color: COLORS.RED, fontWeight: 700 }}>{errorText}</span>
          </Grid>
        ) : Boolean(uncachedProfiles.length > 0) ? (
          <Grid item xs={'auto'} style={{ minHeight: '22px' }}>
            <span style={{ color: theme.palette.primary.main }}>
              {`* We are still collecting data on your profile(s): ${uncachedProfiles.join(
                ', '
              )}. Please check back here soon.`}
            </span>
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
};

const ProfileOption = ({ profile }: { profile: Profile }) => {
  const { classes } = styles();
  const { classes: badgeClasses } = badgeStyles();

  const { displayName, profile_picture_url } = profile;
  const [displayImage, setDisplayImage] = useState(profile_picture_url || backupUserImg);

  return (
    <Grid container style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
      <Badge
        classes={badgeClasses}
        overlap='circular'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        badgeContent={
          <Box className={classes.badgeIcon}>
            {profile.profileType === SocialChannel.Instagram && (
              <img src={InstagramIcon} alt='instagram' className={classes.badgeImage} />
            )}
            {profile.profileType === SocialChannel.Youtube && (
              <img src={YouTubeIcon} alt='youtube' className={classes.badgeImage} />
            )}
            {profile.profileType === SocialChannel.Facebook && (
              <img src={FacebookIcon} alt='facebook' className={classes.badgeImage} />
            )}
            {profile.profileType === SocialChannel.Tiktok && (
              <img src={TiktokIcon} alt='tiktok' className={classes.badgeImage} />
            )}
          </Box>
        }
      >
        <Avatar
          src={displayImage}
          onError={(e) => {
            // tslint:disable-line
            e.persist();
            if (displayImage !== backupUserImg) {
              setDisplayImage(backupUserImg);
            }
          }}
          className={classes.profileAvatar}
        />
      </Badge>
      <Grid item xs={9} style={{ marginLeft: 10 }}>
        {`${displayName}`}
      </Grid>
    </Grid>
  );
};

export default ProfileSelector;
