import React from 'react';
import { useTheme } from '@mui/material';

const FinanceIcon = () => {
  const theme = useTheme();

  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>90CBBFB7-F2B7-4962-9733-791CD7A2D4CB@1x</title>
      <g id='Dashboard' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='2.1-Dashboard-Default_Empty_state' transform='translate(-20.000000, -355.000000)'>
          <g id='Nav'>
            <rect id='Base' fill='none' x='0' y='0' width='250' height='1024'></rect>
            <g
              id='Group-5'
              transform='translate(20.000000, 154.000000)'
              stroke={theme.palette.primary.dark}
              strokeLinecap='square'
            >
              <g id='Text/Nav-Copy-3' transform='translate(0.000000, 201.000000)'>
                <g id='Group-8' transform='translate(2.000000, 2.000000)'>
                  <path
                    d='M18.1818182,10 L18.1818182,3.63636364 L1.81818182,3.63636364 C0.813636364,3.63636364 0,2.82272727 0,1.81818182 L0,17.2727273 C0,18.7790909 1.22090909,20 2.72727273,20 L18.1818182,20 L18.1818182,13.6363636'
                    id='Path'
                  ></path>
                  <path
                    d='M14.5454545,0.909090909 L14.5454545,0 L1.81818182,0 C0.813636364,0 0,0.813636364 0,1.81818182 L0,1.81818182 C0,2.82272727 0.813636364,3.63636364 1.81818182,3.63636364'
                    id='Path'
                  ></path>
                  <path
                    d='M20,13.6363636 L15.4545455,13.6363636 C14.45,13.6363636 13.6363636,12.8227273 13.6363636,11.8181818 L13.6363636,11.8181818 C13.6363636,10.8136364 14.45,10 15.4545455,10 L20,10 L20,13.6363636 Z'
                    id='Path'
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default FinanceIcon;
