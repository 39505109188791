import React, { createContext, useState, ReactNode, useEffect } from 'react';
import ShareInvoiceModal from '../components/pages/CollabsPage/ShareInvoiceModal';

interface IInvoiceShareContext {
  shareInvoiceId?: string;
  previewInvoiceUrl?: string;
  isNewlyCreated?: boolean;
  setShareInvoiceId: (id?: string, isNew?: boolean) => void;
  setPreviewInvoiceUrl?: (url?: string) => void;
}

const defaultValue: IInvoiceShareContext = {
  shareInvoiceId: undefined,
  previewInvoiceUrl: undefined,
  isNewlyCreated: false,
  setShareInvoiceId: () => {},
  setPreviewInvoiceUrl: () => {}
};

const InvoiceShareContext = createContext<IInvoiceShareContext>(defaultValue);

export const InvoiceShareProvider = ({ children }: { children?: ReactNode }) => {
  const [shareInvoiceId, setShareInvoiceId] = useState<string | undefined>();
  const [previewInvoiceUrl, setPreviewInvoiceUrl] = useState<string | undefined>();
  const [isNew, setIsNew] = useState(false);

  useEffect(() => {
    if (previewInvoiceUrl) {
      window.open(previewInvoiceUrl, '_blank');
      setPreviewInvoiceUrl(undefined);
    }
  }, [previewInvoiceUrl, setPreviewInvoiceUrl]);

  return (
    <InvoiceShareContext.Provider
      value={{
        shareInvoiceId,
        previewInvoiceUrl,
        setShareInvoiceId: (id, isNew) => {
          setShareInvoiceId(id);
          if (isNew) {
            setIsNew(true);
          }
        },
        setPreviewInvoiceUrl
      }}
    >
      {shareInvoiceId && (
        <ShareInvoiceModal
          isModalOnly
          externalShareMetricsOpen
          isNewlyCreated={isNew}
          invoiceToShareId={shareInvoiceId}
          onExternalToggle={() => {
            setShareInvoiceId(undefined);
            setIsNew(false);
          }}
        />
      )}
      {children}
    </InvoiceShareContext.Provider>
  );
};

export default InvoiceShareContext;
