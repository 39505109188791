import { useEffect, useMemo, useState, useCallback } from 'react';
import { Grid, CircularProgress, Button, Box, Typography, ButtonBase, useTheme, Avatar } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import {
  useGetCollaborationInvoiceTotalsLazyQuery,
  useGetCollaborationTotalsLazyQuery,
  CollaborationInvoice
} from '../../../types/generated';
import { useErrorHandlerHook } from 'hooks/useErrorCatchHook';
import StripeSetupBox from './StripeSetupBox';
import ContactCell from '../../Shared/ContactCell';
import { currencyOptions as currencies } from 'lib/invoiceValues';
import moment, { Moment } from 'moment';
import { filter, compact, uniq, find } from 'lodash';
import { COLORS } from '../../../lib/constants';
import { formatLongNumber } from '../../../lib/formatNumber';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import CollabsIcon from 'assets/icons/collabs_dark.svg';
import HandoutIcon from 'assets/icons/handout.svg';
import CardIcon from 'assets/icons/card.svg';
import ActivityTag from '../../../components/Shared/ActivityTag';
import { InvoiceEditMenu } from './InvoiceEditMenu';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import DropdownMenu from './DropdownMenu';
import { InvoiceCreation } from '../Finance/InvoiceCreation';

const FinanceOverview = () => {
  const { classes } = useStyles();
  const theme = useTheme();

  const [currency, setCurrency] = useState(currencies.length ? currencies[0].value : undefined);
  const [timePeriod, setTimePeriod] = useState('all_time');
  const [selectedContactId, setSelectedContactId] = useState<undefined | string>();
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(null);
  const [customStartDate, setCustomStartDate] = useState<Moment | null>(null);
  const [customEndDate, setCustomEndDate] = useState<Moment | null>(null);

  const targetDate = useMemo(() => {
    if (timePeriod === 'all_time') {
      return null;
    }

    return moment().subtract(
      timePeriod.includes('last_') ? 1 : 0,
      timePeriod === 'last_year' ? 'year' : timePeriod === 'last_quarter' ? 'quarter' : 'month'
    );
  }, [timePeriod]);

  const startDate = useMemo(() => {
    if (targetDate) {
      return moment(targetDate).startOf(
        timePeriod.includes('year') ? 'year' : timePeriod.includes('quarter') ? 'quarter' : 'month'
      );
    }
    return null;
  }, [timePeriod, targetDate]);

  const endDate = useMemo(() => {
    if (targetDate) {
      return moment(targetDate).endOf(
        timePeriod.includes('year') ? 'year' : timePeriod.includes('quarter') ? 'quarter' : 'month'
      );
    }
    return null;
  }, [timePeriod, targetDate]);

  const [getCollaborationInvoiceTotals, { data, loading, error }] = useGetCollaborationInvoiceTotalsLazyQuery({
    fetchPolicy: 'cache-and-network'
  });

  const [getCollaborationTotals, { data: collabData, loading: collabLoading, error: collabError }] =
    useGetCollaborationTotalsLazyQuery({
      fetchPolicy: 'cache-and-network'
    });

  const handleGetTotals = useCallback(
    (startDate: Moment | null, endDate: Moment | null) => {
      let requestVariables: { startDate?: any; endDate?: any } = {};
      if (startDate) {
        requestVariables.startDate = startDate.toDate();
      }
      if (endDate) {
        requestVariables.endDate = endDate.toDate();
      }
      getCollaborationInvoiceTotals({ variables: requestVariables });
      getCollaborationTotals({ variables: requestVariables });
    },
    [getCollaborationInvoiceTotals, getCollaborationTotals]
  );

  useEffect(() => {
    if (timePeriod !== 'range') {
      setCustomEndDate(null);
      setCustomStartDate(null);
      handleGetTotals(startDate, endDate);
    }
  }, [timePeriod, startDate, endDate, setCustomEndDate, setCustomStartDate, handleGetTotals]);

  const invoicesList = useMemo(
    () => (data?.getCollaborationInvoiceTotals?.invoices ? compact(data.getCollaborationInvoiceTotals.invoices) : []),
    [data]
  );

  const { apolloHandler } = useErrorHandlerHook();

  useEffect(() => {
    if (error) {
      apolloHandler(error);
    }
  }, [error, apolloHandler]);

  useEffect(() => {
    if (collabError) {
      apolloHandler(collabError);
    }
  }, [collabError, apolloHandler]);

  const availableContacts = useMemo(() => {
    return uniq(
      compact(
        data?.getCollaborationInvoiceTotals?.totals?.map((o) => {
          return o?.contact?._id;
        })
      )
    );
  }, [data]);

  useEffect(() => {
    if (availableContacts?.length) {
      setSelectedContactId(availableContacts[0]);
    }
  }, [availableContacts]);

  const availableCurrencies = useMemo(() => {
    return uniq([
      ...compact(
        data?.getCollaborationInvoiceTotals?.totals?.map((o) => {
          return o?.currency;
        })
      ),
      ...compact(
        collabData?.getCollaborationTotals?.totals?.map((o) => {
          return o?.currency;
        })
      )
    ]);
  }, [data, collabData]);

  useEffect(() => {
    if (availableCurrencies?.length) {
      setCurrency(availableCurrencies[0]);
    }
  }, [availableCurrencies]);

  const currencyOptions = useMemo(() => {
    return filter(currencies, (o) => availableCurrencies.includes(o.value));
  }, [availableCurrencies]);

  const selectedCurrency = useMemo(() => {
    if (currency) {
      return find(currencies, { value: currency });
    }
    return null;
  }, [currency]);

  const filteredTotals = useMemo(() => {
    if (data?.getCollaborationInvoiceTotals?.totals) {
      return filter(data.getCollaborationInvoiceTotals.totals, (o) => {
        return Boolean(o?.currency === currency);
      });
    }
    return null;
  }, [data, currency]);

  const incomeTotals = useMemo(() => {
    return find(filteredTotals, { contactId: 'total' });
  }, [filteredTotals]);

  const collabTotals = useMemo(() => {
    return collabData?.getCollaborationTotals?.totals
      ? find(collabData.getCollaborationTotals.totals, {
          contactId: 'total',
          currency
        })
      : null;
  }, [collabData, currency]);

  const maxTotal = useMemo(() => {
    return Math.max(collabTotals?.totalPrice || 0, incomeTotals?.max || 0);
  }, [collabTotals, incomeTotals]);

  const selectedContactTotals = useMemo(() => {
    return find(filteredTotals, (o) => {
      if (!o?.contactId && !selectedContactId) {
        return true;
      }

      if (o?.contactId === selectedContactId) {
        return true;
      }
      return false;
    });
  }, [filteredTotals, selectedContactId]);

  const brandTotals = useMemo(() => filter(compact(filteredTotals), (o) => o.contactId !== 'total'), [filteredTotals]);

  const chartRows = useMemo(
    () =>
      compact(
        brandTotals?.map((o, i) => {
          if (o.all && o.all > 0) {
            const isSelected = (!o.contactId && !selectedContactId) || o.contactId === selectedContactId;
            return {
              value: o.all || 0,
              key: o.contactId || `contact-segment-${i}`,
              fill: isSelected ? '#84C6FF' : '#FBCDDF',
              name: o.contact?.name || '',
              onPress: () => setSelectedContactId(o.contactId || undefined)
            };
          }
          return null;
        })
      ),
    [brandTotals, selectedContactId]
  );

  const Loader = () => (
    <Grid item xs={12} className={`${classes.centred} ${classes.loaderContainer}`}>
      <CircularProgress />
    </Grid>
  );

  const isLoading = loading || collabLoading;

  return (
    <Grid container>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Grid item xs={12}>
            <StripeSetupBox returnPath={'/finance/overview'} />
          </Grid>
          <Grid item xs={7} container direction='column'>
            <Grid item>
              <Box className={classes.overviewBox}>
                <Grid container>
                  <Grid item container xs={12} justifyContent={'space-between'}>
                    <Typography className={classes.overviewTitle}>Income</Typography>
                    <div className='hidden-date-picker'>
                      <DateRangePicker
                        startDateId={'start-date'}
                        endDateId={'end-date'}
                        startDate={customStartDate}
                        endDate={customEndDate}
                        onDatesChange={(event) => {
                          setCustomStartDate(event.startDate);
                          setCustomEndDate(event.endDate);
                          if (event.startDate && event.endDate) {
                            setTimePeriod('range');
                            handleGetTotals(event.startDate, event.endDate);
                          }
                        }}
                        focusedInput={focusedInput}
                        onFocusChange={(input) => setFocusedInput(input)}
                        monthFormat={'MMM YYYY'}
                        displayFormat={'DD/MM/YYYY'}
                        startDatePlaceholderText={'From DD/MM/YYYY*'}
                        endDatePlaceholderText={'Until DD/MM/YYYY*'}
                        isOutsideRange={() => false}
                        daySize={25}
                        small
                        block
                        noBorder
                        hideKeyboardShortcutsPanel
                      />
                    </div>
                    <DropdownMenu
                      value={timePeriod}
                      displayValue={
                        timePeriod === 'range'
                          ? `${customStartDate?.format('DD MMM YY')} - ${customEndDate?.format('DD MMM YY')}`
                          : undefined
                      }
                      onChange={(value) => setTimePeriod(value)}
                      options={[
                        { title: 'All Time', value: 'all_time' },
                        { title: 'This Month', value: 'month' },
                        { title: 'Last Month', value: 'last_month' },
                        { title: 'This Quarter', value: 'quarter' },
                        { title: 'Last Quarter', value: 'last_quarter' },
                        { title: 'This Year', value: 'year' },
                        { title: 'Last Year', value: 'last_year' },
                        {
                          title: 'Date range',
                          value: 'range',
                          onClick: () => {
                            setFocusedInput('startDate');
                          }
                        }
                      ]}
                      placeholder={'Date range'}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography className={classes.incomeTitle}>{`${selectedCurrency?.value}${
                      selectedCurrency?.symbol
                    }${formatLongNumber(incomeTotals?.all)}`}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    justifyContent='space-between'
                    alignItems='center'
                    className={classes.incomeSubtitleContainer}
                  >
                    <Typography className={classes.incomeSubtitle}>
                      <span style={{ color: theme.palette.primary.main }}>
                        {timePeriod === 'all_time'
                          ? `Income for all time`
                          : timePeriod === 'range'
                          ? `Income between ${customStartDate?.format('D MMM YY')} and ${customEndDate?.format(
                              'D MMM YY'
                            )}`
                          : `Income between ${startDate?.format('D MMM YY')} and ${endDate?.format('D MMM YY')}`}
                      </span>
                    </Typography>
                    <DropdownMenu
                      value={currency}
                      onChange={(value) => setCurrency(value)}
                      options={currencyOptions}
                      placeholder={'Currency'}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    justifyContent='flex-start'
                    alignItems='center'
                    spacing={2}
                    className={classes.incomeRow}
                  >
                    <Grid item>
                      <img src={CollabsIcon} alt='due' />
                    </Grid>
                    <Grid>
                      <Typography className={classes.incomeRowText}>
                        {`${selectedCurrency?.value}${selectedCurrency?.symbol}${formatLongNumber(
                          collabTotals?.totalPrice
                        )} -
                      Collabs Value`}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className={classes.incomeBar}>
                    <GradientBar
                      widthPercentage={
                        Boolean(collabTotals?.totalPrice && maxTotal && collabTotals?.totalPrice > 0)
                          ? (collabTotals?.totalPrice! / maxTotal!) * 100
                          : 0
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    justifyContent='flex-start'
                    alignItems='center'
                    spacing={2}
                    className={classes.incomeRow}
                  >
                    <Grid item>
                      <img src={HandoutIcon} alt='due' />
                    </Grid>
                    <Grid>
                      <Typography className={classes.incomeRowText}>
                        {`${selectedCurrency?.value}${selectedCurrency?.symbol}${formatLongNumber(incomeTotals?.due)} -
                      Invoiced & Due`}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className={classes.incomeBar}>
                    <GradientBar
                      widthPercentage={
                        Boolean(incomeTotals?.due && maxTotal && incomeTotals?.due > 0)
                          ? (incomeTotals!.due! / maxTotal!) * 100
                          : 0
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    justifyContent='flex-start'
                    alignItems='center'
                    spacing={2}
                    className={classes.incomeRow}
                  >
                    <Grid item>
                      <img src={CardIcon} alt='paid' />
                    </Grid>
                    <Grid item>
                      <Typography className={classes.incomeRowText}>
                        {`${selectedCurrency?.value}${selectedCurrency?.symbol}${formatLongNumber(incomeTotals?.paid)} -
                      Received`}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className={classes.incomeBar}>
                    <GradientBar
                      widthPercentage={
                        Boolean(incomeTotals?.paid && maxTotal && incomeTotals?.paid > 0)
                          ? (incomeTotals!.paid! / maxTotal!) * 100
                          : 0
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item>
              <Box className={classes.overviewBox}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <Typography className={classes.overviewTitle}>Invoices</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography className={classes.overviewHeader}>Invoice Number</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography className={classes.overviewHeader} style={{ textAlign: 'center' }}>
                          Due
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    {invoicesList.map((o) => (
                      <Grid item xs={12} key={o._id}>
                        <InvoiceRow invoice={o} />
                      </Grid>
                    ))}
                  </Grid>
                  <Grid item container xs={12} style={{ marginTop: 20 }} justifyContent='flex-start'>
                    <Grid item>
                      <InvoiceCreation size={14}>
                        <Button variant='outlined'>New Invoice</Button>
                      </InvoiceCreation>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Box className={classes.overviewBox}>
              <Grid container>
                <Grid item container xs={12} justifyContent={'space-between'}>
                  <Typography className={classes.overviewTitle}>Split by brand/agency</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.splitTitle}>{`${selectedCurrency?.value}${
                    selectedCurrency?.symbol
                  }${formatLongNumber(incomeTotals?.all)}`}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.splitSubtitle}>Total Income</Typography>
                </Grid>
                {chartRows.length ? (
                  <Grid item xs={12} className={classes.chartContainer}>
                    <ResponsiveContainer>
                      <PieChart>
                        <Pie data={chartRows} innerRadius={60} dataKey='value' startAngle={450} endAngle={90}>
                          {chartRows.map((entry) => (
                            <Cell key={entry.key} fill={entry.fill} onClick={entry.onPress} />
                          ))}
                        </Pie>
                        <Tooltip
                          formatter={(value) =>
                            `${selectedCurrency?.symbol || ''}${
                              typeof value === 'number' ? formatLongNumber(value) : value
                            }`
                          }
                        />
                      </PieChart>
                    </ResponsiveContainer>
                    <Grid
                      container
                      className={classes.contactTotalsContainer}
                      justifyContent='center'
                      alignItems='center'
                    >
                      <Grid item className={classes.contactTotals}>
                        {`${selectedCurrency?.value}${selectedCurrency?.symbol}${formatLongNumber(
                          selectedContactTotals?.all
                        )}`}
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={12}
                    container
                    className={classes.chartEmptyContainer}
                    justifyContent='center'
                    alignItems='center'
                  >
                    <Typography>Looks like there are no invoices for this time period</Typography>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {brandTotals?.map((o, i) => (
                      <Grid item key={o.contactId || `brand_total_${i}`}>
                        <ButtonBase onClick={() => setSelectedContactId(o.contactId || undefined)}>
                          <Grid
                            container
                            justifyContent={'center'}
                            alignItems={'center'}
                            className={classes.contactCellContainer}
                          >
                            <ContactCell contact={o?.contact || { name: 'Total' }} avatarSize={18} />
                          </Grid>
                        </ButtonBase>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export const GradientBar = ({ widthPercentage }: { widthPercentage: number }) => {
  return (
    <div
      style={{
        height: 12,
        borderRadius: 6,
        width: `${Math.round(widthPercentage)}%`,
        background: 'linear-gradient(45deg, #FBCDDF, #C5D5E2)'
      }}
    ></div>
  );
};

const InvoiceRow = ({ invoice }: { invoice: CollaborationInvoice }) => {
  const { classes } = invoiceRowStyles();
  const collaborationName = invoice?.collaboration?.name || '';

  const firstShareLink = invoice?.links ? invoice.links[0] : undefined;

  if (!invoice._id) {
    return null;
  }

  return (
    <Grid container className={classes.container} alignItems='center'>
      <Grid item xs={6}>
        <Grid container alignItems='center' wrap='nowrap'>
          <Avatar src={invoice?.collaboration?.brand?.avatar || ''} variant='circular' className={classes.avatar}>
            {collaborationName[0]}
          </Avatar>
          <Grid item container direction='column' style={{ marginLeft: 10 }}>
            <Typography className={classes.rowText}>{collaborationName}</Typography>
            <Typography className={classes.rowText} style={{ fontSize: 10 }}>
              {firstShareLink?.contact?.email || firstShareLink?.contactDetails?.email || ''}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={3}>
        <Typography className={classes.rowText}>{invoice?.invoiceId || ''}</Typography>
      </Grid>
      <Grid item xs={2}>
        <ActivityTag action={invoice?.status || 'DRAFT'} />
      </Grid>
      <Grid item xs={1} container justifyContent='flex-end'>
        <Grid item>{Boolean(invoice?.status !== 'VOID') && <InvoiceEditMenu invoiceId={invoice._id} />}</Grid>
      </Grid>
    </Grid>
  );
};

const invoiceRowStyles = makeStyles()(() => ({
  container: {
    borderBottomColor: '#BBBBBB',
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    paddingTop: 10,
    paddingBottom: 10
  },
  avatar: {
    height: 32,
    width: 32,
    fontSize: 12
  },
  rowText: {
    color: '#333333',
    fontSize: 14
  }
}));

const useStyles = makeStyles()(() => ({
  centred: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  loaderContainer: {
    height: '85vh'
  },
  avatar: {
    height: 24,
    width: 24,
    fontSize: 12
  },
  tableHeader: {
    fontSize: 12,
    color: '#6C6C72'
  },
  overviewBox: {
    borderWidth: 0.5,
    borderStyle: 'solid',
    borderColor: '#BBBBBB',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 1px 2px 0 rgba(211,211,211,0.5)',
    borderRadius: 4,
    margin: 6,
    padding: 29
  },
  overviewTitle: {
    color: '#333333',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '22px',
    marginBottom: 10
  },
  overviewHeader: {
    fontSize: 12,
    color: '#6C6C72'
  },
  incomeTitle: {
    color: COLORS.MAROON,
    fontSize: 40,
    fontWeight: 500
  },
  incomeSubtitleContainer: {
    marginBottom: 25
  },
  incomeSubtitle: {
    color: '#6C6C72',
    fontSize: 14,
    fontWeight: 200
  },
  incomeRow: {
    marginBottom: 10
  },
  incomeRowText: {
    fontSize: 14,
    fontWeight: 500,
    color: '#333333'
  },
  incomeBar: {
    marginBottom: 27
  },
  splitTitle: {
    color: COLORS.MAROON,
    fontSize: 22,
    fontWeight: 500
  },
  splitSubtitle: {
    color: '#333333',
    fontSize: 14,
    fontWeight: 200
  },
  contactCellContainer: {
    height: 30,
    paddingRight: 6,
    border: '0.5px solid #BBBBBB7F',
    borderRadius: 15,
    backgroundColor: '#FFFFFF',
    boxShadow: '0 2px 4px 0 #CC215D7F'
  },
  chartContainer: {
    position: 'relative',
    height: 250
  },
  chartEmptyContainer: {
    height: 250
  },
  contactTotalsContainer: {
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    position: 'absolute'
  },
  contactTotals: {
    borderRadius: 1,
    backgroundColor: '#4C4C4C',
    boxShadow: '-2px 8px 8px 0 rgba(0,0,0,0.06)',
    fontSize: 12,
    color: '#FFFFFF',
    paddingLeft: 6,
    paddingTop: 3,
    paddingBottom: 3,
    paddingRight: 12
  }
}));

export default FinanceOverview;
