import { useEffect, useState } from 'react';
import { SQS, config as AWS_CONFIG } from 'aws-sdk';
import { Consumer } from 'sqs-consumer';
import { useGetMyUserAwsCredentialsQuery } from 'types/generated';
import { useSnackbar } from 'notistack';

const useAWSQueue = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { data: userData, refetch } = useGetMyUserAwsCredentialsQuery({ fetchPolicy: 'network-only' });

  const [consumer_client, setConsumer] = useState<Consumer | null>(null);

  useEffect(() => {
    if (!consumer_client?.isRunning && userData?.myUser?.sqs_url) {
      const credentials = userData.myUser.awsTemporaryCredentials;
      if (
        credentials?.AccessKeyId &&
        credentials?.SecretAccessKey &&
        credentials?.region &&
        credentials?.apiVersion &&
        credentials?.SessionToken
      ) {
        AWS_CONFIG.update({
          credentials: {
            accessKeyId: credentials.AccessKeyId,
            secretAccessKey: credentials.SecretAccessKey,
            sessionToken: credentials.SessionToken
          },
          region: credentials.region,
          apiVersion: credentials.apiVersion
        });

        console.log(`Subscribing to queue`, userData.myUser.sqs_url);

        const SQS_CLIENT = Consumer.create({
          queueUrl: userData.myUser.sqs_url,
          region: credentials.region,
          handleMessage: async (message) => {
            console.log('message', message);
            enqueueSnackbar(message.Body);
          },
          sqs: new SQS()
        });

        if (SQS_CLIENT) {
          SQS_CLIENT.start();
          setConsumer(SQS_CLIENT);
        }
      }
    }
  }, [consumer_client, enqueueSnackbar, userData, setConsumer]);

  useEffect(() => {
    if (consumer_client) {
      consumer_client.on('error', (err) => {
        console.log('SQS error', err);
        consumer_client.stop();
        refetch();
        return;
      });

      consumer_client.on('processing_error', (err) => {
        console.log('SQS processing_error', err);
        consumer_client.stop();
        refetch();
        return;
      });

      consumer_client.on('timeout_error', (err) => {
        console.log('SQS timeout_error', err);
        consumer_client.stop();
        refetch();
        return;
      });
    }

    return () => {
      consumer_client?.removeAllListeners();
    };
  }, [consumer_client, setConsumer, refetch]);
};

export default useAWSQueue;

export const AWSQueueNotifier = () => {
  useAWSQueue();

  return <></>;
};
