import ButtonBase from '@mui/material/ButtonBase';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import EditIcon from 'assets/icons/EditIcon.svg';
import Modal from 'components/Modal';
import useTracking from 'context/useTrackingCtx';
import { formatError } from 'lib/formatStrings';
import React from 'react';
import {
  GetCollaborationListDocument,
  GetCompanyContactListDocument,
  useDeleteDeliverableMutation,
  Deliverable
} from 'types/generated';
import { DeliverableCreation } from './DeliverableCreation';
import DeliverableDetails from './DeliverableDetails';

interface DeliverableEditMenuProps {
  deliverable: Deliverable;
  deliverableId: string;
}

export const DeliverableEditMenu = (props: DeliverableEditMenuProps) => {
  const { deliverable, deliverableId } = props;
  const { trackEvent } = useTracking();
  const { useState } = React;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [deleteDeliverable, { loading: deleteLoading, error: deleteError }] = useDeleteDeliverableMutation({
    refetchQueries: [{ query: GetCollaborationListDocument }, { query: GetCompanyContactListDocument }]
  });

  const canEditDeliverable = Boolean(deliverable?.canEdit);

  const deleteErrorMessage = deleteError ? formatError(deleteError) : '';

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  const toggleDeleteModal = () => {
    setDeleteModalOpen(!deleteModalOpen);
    if (anchorEl) closeMenu();
  };

  const deleteAction = () => {
    deleteDeliverable({ variables: { id: deliverableId } }).then(() => {
      trackEvent('deliverables', 'deleted deliverable');
      toggleDeleteModal();
    });
  };

  const DeleteModal = () => (
    <Modal
      isOpen={deleteModalOpen}
      onToggle={toggleDeleteModal}
      onConfirm={deleteAction}
      isLoadingConfirm={deleteLoading}
      confirmLabel={'Delete'}
      errorMessage={deleteErrorMessage}
      showErrorMessage={Boolean(deleteError)}
      heading={'Delete deliverable'}
      maxWidth={'sm'}
    >
      <Typography style={{ padding: '10px 0 20px' }}>Are you sure you want to delete this deliverable?</Typography>
    </Modal>
  );

  const MenuOptions = () => (
    <Menu id='long-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={closeMenu}>
      <MenuItem
        onClick={() => {
          setIsDetailsOpen(true);
          if (anchorEl) closeMenu();
        }}
      >
        View
      </MenuItem>
      <DeliverableCreation editableDeliverable={deliverable} toggleModal={closeMenu}>
        <MenuItem style={{ width: '100%', margin: 0 }}>Edit</MenuItem>
      </DeliverableCreation>
      {canEditDeliverable && <MenuItem onClick={toggleDeleteModal}>Delete</MenuItem>}
    </Menu>
  );

  return (
    <>
      {anchorEl && <MenuOptions />}
      {deleteModalOpen && <DeleteModal />}
      <Modal
        isOpen={isDetailsOpen}
        onToggle={() => setIsDetailsOpen(false)}
        hideConfirmButton
        cancelLabel={'Done'}
        heading={'Deliverable Details'}
        maxWidth={'sm'}
      >
        {deliverable && <DeliverableDetails deliverable={deliverable} />}
      </Modal>
      <ButtonBase onClick={(event) => openMenu(event)}>
        <img src={EditIcon} alt='Edit' style={{ fontSize: 22 }} />
      </ButtonBase>
    </>
  );
};
