import React from 'react';
import { useTheme } from '@mui/material';

const LinkIcon = ({
  fill,
  style,
  width,
  height
}: {
  fill?: string;
  style?: React.CSSProperties;
  width?: number;
  height?: number;
}) => {
  const theme = useTheme();

  return (
    <svg
      width={`${width || 16}px`}
      height={`${height || 18}px`}
      viewBox='0 0 16 18'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      style={style ? style : {}}
    >
      <g id='6.-Talent-Management' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='6.-Talent-Management/Share'
          transform='translate(-675.000000, -671.000000)'
          fill={fill || theme.palette.primary.main}
          fillRule='nonzero'
        >
          <g id='Popup' transform='translate(445.000000, 147.000000)'>
            <g id='Kitly-CTA/Secondary' transform='translate(74.000000, 512.206522)'>
              <g id='Icon/General/Search-Copy-2' transform='translate(156.000000, 12.793478)'>
                <g id='web-hyperlink' transform='translate(0.000000, 2.000000)'>
                  <path
                    d='M9.5,0 C10.8258967,0 11.9100717,1.03113919 11.9946843,2.3355767 L12,2.5 L12,6.5 C12,7.82589668 10.9688608,8.91007173 9.6644233,8.9946843 L9.5,9 L6.5,9 C6.22385763,9 6,8.77614237 6,8.5 C6,8.25454011 6.17687516,8.05039163 6.41012437,8.00805567 L6.5,8 L9.5,8 C10.2801013,8 10.9204963,7.40549326 10.9931376,6.64452731 L11,6.5 L11,2.5 C11,1.71989871 10.4054933,1.07950365 9.64452731,1.00686237 L9.5,1 L2.5,1 C1.71989871,1 1.07950365,1.59450674 1.00686237,2.35547269 L1,2.5 L1,6.5 C1,7.32885763 1.67114237,8 2.5,8 C2.77614237,8 3,8.22385763 3,8.5 C3,8.77614237 2.77614237,9 2.5,9 C1.17410332,9 0.0899282656,7.96886081 0.00531569594,6.6644233 L0,6.5 L0,2.5 C0,1.17410332 1.03113919,0.0899282656 2.3355767,0.00531569594 L2.5,0 L9.5,0 Z'
                    id='Path'
                  ></path>
                  <path
                    d='M9.5,4 C9.77614237,4 10,4.22385763 10,4.5 C10,4.74545989 9.82312484,4.94960837 9.58987563,4.99194433 L9.5,5 L6.5,5 C5.71989871,5 5.07950365,5.59450674 5.00686237,6.35547269 L5,6.5 L5,10.5 C5,11.2801013 5.59450674,11.9204963 6.35547269,11.9931376 L6.5,12 L13.5,12 C14.2801013,12 14.9204963,11.4054933 14.9931376,10.6445273 L15,10.5 L15,6.5 C15,5.67114237 14.3288576,5 13.5,5 C13.2238576,5 13,4.77614237 13,4.5 C13,4.22385763 13.2238576,4 13.5,4 C14.8258967,4 15.9100717,5.03113919 15.9946843,6.3355767 L16,6.5 L16,10.5 C16,11.8258967 14.9688608,12.9100717 13.6644233,12.9946843 L13.5,13 L6.5,13 C5.17410332,13 4.08992827,11.9688608 4.0053157,10.6644233 L4,10.5 L4,6.5 C4,5.17410332 5.03113919,4.08992827 6.3355767,4.0053157 L6.5,4 L9.5,4 Z'
                    id='Path'
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default LinkIcon;
