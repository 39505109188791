import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Grid } from '@mui/material';
import InstagramMediaOption from './InstagramMediaOption';
import { InstagramMedia } from 'types/generated';
import { compact } from 'lodash';

const styles = makeStyles()((theme: any) => ({
  actionButton: { color: '#fff' },
  header: {
    height: 120,
    backgroundColor: theme.palette.background.dark,
    borderBottom: `solid 0.5px ${theme.palette.primary.main}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  centerColumn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  subheading: {
    color: theme.palette.common.blueGray,
    textTransform: 'uppercase',
    letterSpacing: '1px',
    fontSize: 12
  },
  backButton: {
    fontWeight: 500
  },
  emptyDiv: {
    width: 162
  },
  dialogContent: {
    padding: '20px 90px 100px'
  },
  selectAndTrackContent: {
    maxWidth: 700
  },
  dropdown: {
    margin: '15px 0'
  },
  warningBox: {
    backgroundColor: '#fff8e6',
    color: '#7990b7',
    borderRadius: 5,
    boxShadow: 'none',
    border: '1px solid #bfc6d3',
    fontSize: 14
  },
  tickIcon: {
    position: 'absolute',
    bottom: 10,
    right: 10,
    fontSize: 22,
    color: theme.palette.primary.main,
    backgroundColor: '#FFF',
    borderRadius: '100%'
  },
  mediaButton: {
    padding: 5,
    position: 'relative'
  },
  mediaImg: {
    height: 105,
    width: 105,
    objectFit: 'cover'
  },
  titleText: {
    width: '100%',
    padding: '10px 5px'
  },
  addButton: {
    padding: 20,
    position: 'absolute',
    bottom: 0,
    width: '100%'
  }
}));

const InstagramMediaList = ({
  page,
  media,
  selectedMedia,
  setSelectedMedia,
  paginationPageSize
}: {
  page?: number;
  media: Array<InstagramMedia>;
  selectedMedia?: Array<string>;
  setSelectedMedia?: (id: string[]) => void;
  paginationPageSize?: number;
}) => {
  const { classes } = styles();
  const currentPage = page || 0;
  const pageSize = paginationPageSize || 8;
  return (
    <Grid container justifyContent='flex-start'>
      {media.slice(pageSize * currentPage, pageSize * (currentPage + 1)).map((o, i) => {
        const isSelected = Boolean(o.id && selectedMedia?.includes(o.id));
        return (
          <InstagramMediaOption
            key={`media-${i}-${o.id}`}
            media={o}
            isSelected={Boolean(isSelected)}
            classes={classes}
            onSelect={() => {
              if (isSelected && setSelectedMedia) {
                let newSelected = selectedMedia ? [...selectedMedia] : [];
                newSelected.splice(newSelected.indexOf(o.id!), 1);
                setSelectedMedia(newSelected);
              } else if (setSelectedMedia) {
                if (o.id) {
                  setSelectedMedia(compact(selectedMedia ? selectedMedia.concat([o.id]) : [o.id]));
                }
              }
            }}
          />
        );
      })}
    </Grid>
  );
};

export default InstagramMediaList;
