import { useEffect, useRef, useState } from 'react';

import { makeStyles } from 'tss-react/mui';
import {
  useTheme,
  Avatar,
  Button,
  ButtonBase,
  CircularProgress,
  Dialog,
  DialogActions,
  Grid,
  Hidden,
  Typography,
  Paper
} from '@mui/material';

import EditIcon from 'assets/icons/EditIcon.svg';
import EditProfileAvatarModal from 'components/settings/EditProfileAvatarModal';
import { COLORS } from 'lib/constants';
import { useNavigate, useParams } from 'react-router-dom';
import { useDeleteMyUserMutation } from 'types/generated';
import { useErrorHandlerHook } from '../../hooks/useErrorCatchHook';
import { useGetUserPaymentHistoryQuery } from '../../types/generated';
import EditProfile from '../settings/EditProfile';
import InvoiceSettings from '../settings/InvoiceSettings';
import PageManagement from '../settings/PageManagement';
import SimpleTabs from '../settings/SimpleTabs';
import MembershipSettings from './MembershipSettings';

import { ChargeHistory } from 'components/subscribe/ChargeHistory';
import ContentfulAdBanner from 'components/Dashboard/ContentfulAdBanner';

const styles = makeStyles()((theme) => ({
  confirmButton: {
    backgroundColor: COLORS.MAROON,
    borderColor: COLORS.MAROON
  },
  paper: {
    width: 420,
    padding: '44px 29px 46px',
    borderColor: COLORS.MAROON
  },
  dialogActionsRoot: {
    display: 'flex',
    justifyContent: 'center',
    columnGap: 18,
    marginTop: 24
  },
  dialogActionButtons: {
    minWidth: 110
  },
  settingsSection: {
    maxWidth: '922px',
    display: 'flex',
    flexDirection: 'row'
  },
  membershipSubscriptionType: {
    backgroundColor: theme.palette.background.default
  },
  headerColorBlock: {
    width: '100%',
    height: 140,
    marginBottom: 50,
    backgroundColor: '#F8F6F9'
  },
  inviteBanner: {
    width: '100%',
    maxWidth: '170px',
    margin: '60px 0 0 20px',
    height: 'fit-content',
    borderRadius: '5px',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column'
  },
  inviteText: {
    padding: 20
  },
  paddingBottom: {
    paddingBottom: '15px'
  },
  subContent: {
    overflowX: 'hidden',
    padding: 0,
    [theme.breakpoints.up('md')]: {
      height: `calc(100% - 64px)`
    },
    [theme.breakpoints.down('sm')]: {
      height: `calc(100%)`
    },
    overflowY: 'auto'
  }
}));

const userImg = 'https://media.q-83.com/site/user.png';

const Settings = () => {
  const generalSettingsRef = useRef<HTMLDivElement>(null);
  const instagramSettingsRef = useRef<HTMLDivElement>(null);
  const billingSettingsRef = useRef<HTMLDivElement>(null);
  const invoiceSettingsRef = useRef<HTMLDivElement>(null);

  const [deleteAccountModalOpen, setDeleteAccountModalOpen] = useState(false);
  const [editProfileAvatarModalOpen, setEditProfileAvatarModalOpen] = useState(false);

  const { data: userData, error: userError } = useGetUserPaymentHistoryQuery();

  const { apolloHandler } = useErrorHandlerHook();
  const navigate = useNavigate();
  const params = useParams<{ activeTab?: string }>();
  const theme = useTheme();
  const { classes } = styles();

  const [deleteMyUser, { loading: deleteMyUserLoading }] = useDeleteMyUserMutation({
    onCompleted: (data) => {
      if (data.deleteMyUser?.success) {
        navigate('/logout', { state: { deletedAccount: true } });
      }
    },
    onError: (ex) => {
      console.error('Account delete EXCEPTION', ex);
      apolloHandler(ex);
    }
  });

  const user = userData?.myUser;

  useEffect(() => {
    if (userError) {
      apolloHandler(userError, `Unable to get current invoice settings`);
    }
  }, [userError, apolloHandler]);

  const handleDeleteAccount = () => {
    setDeleteAccountModalOpen(true);
  };

  const handleClose = (action) => () => {
    setDeleteAccountModalOpen(false);
    if (action === 'Agree') {
      deleteMyUser();
    }
  };

  const handleToggleProfileAvatarModal = () => {
    setEditProfileAvatarModalOpen(!editProfileAvatarModalOpen);
  };

  const renderDeleteAlert = () => {
    return (
      <Dialog
        classes={{ paper: classes.paper }}
        open={deleteAccountModalOpen}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <Typography variant='h5' align='center'>
          Are you sure you want to delete your account? All your data will be lost.
        </Typography>
        <DialogActions classes={{ root: classes.dialogActionsRoot }}>
          <Button
            onClick={handleClose('Agree')}
            autoFocus
            variant='contained'
            className={`${classes.confirmButton} ${classes.dialogActionButtons}`}
          >
            {deleteMyUserLoading && <CircularProgress style={{ marginRight: 8, color: '#fff' }} size={12} />}
            Yes
          </Button>
          <Button
            onClick={handleClose('Disagree')}
            color='primary'
            variant='outlined'
            className={classes.dialogActionButtons}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderSettingsMenu = () => {
    const activeTab = params?.activeTab || 'accountdetails';

    let settingsTabContent = [
      {
        label: 'Account Details',
        param: 'accountdetails',
        content: (
          <div style={{ marginTop: 30 }}>
            <EditProfile />
          </div>
        )
      },
      {
        label: 'Linked Accounts',
        param: 'linkedaccounts',
        content: (
          <div style={{ marginTop: 30 }}>
            <div ref={instagramSettingsRef} />
            {renderSettingsInstagram()}
          </div>
        )
      }
    ];

    settingsTabContent.push({
      label: 'Subscription and Billing',
      param: 'subscription',
      content: (
        <div style={{ marginTop: 30 }}>
          <div ref={billingSettingsRef} />
          <MembershipSettings />
          <Grid container spacing={0}>
            <Grid item xs={12} md={12} style={{ position: 'relative', marginBottom: 20 }}>
              <Paper style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                <Grid
                  item
                  style={{
                    paddingTop: 20,
                    paddingLeft: 30,
                    paddingBottom: 20
                  }}
                >
                  <SettingsSectionHeader heading='Charge History' />
                </Grid>
                <Grid item container>
                  <Grid item xs={12} style={{ padding: '10px 30px' }}>
                    <ChargeHistory />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </div>
      )
    });

    settingsTabContent.push({
      label: 'Invoice Setings',
      param: 'invoice',
      content: (
        <div style={{ marginTop: 30 }}>
          <div ref={invoiceSettingsRef} />
          <InvoiceSettings />
        </div>
      )
    });

    return (
      <Grid
        container
        direction='column'
        style={{
          marginBottom: 25
        }}
      >
        <Grid item style={{ position: 'relative' }}>
          <ButtonBase onClick={handleToggleProfileAvatarModal}>
            <img
              src={EditIcon}
              alt='edit icon'
              style={{
                height: 24,
                width: 24,
                position: 'absolute',
                top: -130 / 2,
                left: 112,
                transform: 'translate(-24px, 8px)',
                zIndex: 99,
                backgroundColor: '#fff',
                padding: 3,
                borderRadius: '50%'
              }}
            />
          </ButtonBase>
          <Avatar src={user?.avatar || userImg} style={{ width: 112, height: 112, position: 'absolute', top: -130 }} />
        </Grid>
        <Grid item>
          <SimpleTabs
            tabContent={settingsTabContent}
            availableParam={activeTab}
            page={'settings'}
            scrollButtons='auto'
            variant='scrollable'
          />
        </Grid>
      </Grid>
    );
  };

  const renderSettingsInstagram = () => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} md={12} style={{ position: 'relative' }}>
          <Paper
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingBottom: theme.spacing(3.5)
            }}
          >
            <Typography
              variant='h5'
              data-cy='instagram-settings-heading'
              style={{ marginTop: theme.spacing(5), marginBottom: theme.spacing(1), marginLeft: theme.spacing(7.5) }}
            >
              Linked accounts
            </Typography>
            <div style={{ padding: theme.spacing(2.5) }}>
              <PageManagement />
            </div>
          </Paper>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <div className={classes.subContent}>
        <div className={classes.headerColorBlock} />
        <div
          style={{
            paddingLeft: window.matchMedia('(max-width: 425px)').matches ? 0 : 20,
            paddingRight: window.matchMedia('(max-width: 425px)').matches ? 0 : 20,
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <div className={classes.settingsSection}>
            <div style={{ width: 550 }}>
              <div style={{ height: 20 }} ref={generalSettingsRef} />
              <div>{renderSettingsMenu()}</div>
              <Grid container justifyContent='center'>
                <Grid item style={{ margin: 5 }}>
                  <Button data-cy='delete-account-button' onClick={handleDeleteAccount} color='primary'>
                    Delete Account
                  </Button>
                </Grid>
              </Grid>
            </div>
            <Hidden xsDown>
              <div style={{ padding: '60px 0 0 20px' }}>
                <ContentfulAdBanner orientation='vertical' adLocation='settings' />
              </div>
            </Hidden>
          </div>
        </div>
        <div style={{ height: 70 }} />
      </div>
      <EditProfileAvatarModal
        editProfileAvatarModalOpen={editProfileAvatarModalOpen}
        onToggleProfileAvatarModal={handleToggleProfileAvatarModal}
      />
      {renderDeleteAlert()}
    </>
  );
};

export default Settings;

const SettingsSectionHeader = ({ heading }: { heading: string }) => (
  <Typography style={{ fontSize: 16, fontWeight: 500 }}>{heading}</Typography>
);
