import React from 'react';
import { useTheme } from '@mui/material';

const DashboardIcon = () => {
  const theme = useTheme();

  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>82A6C4CB-D63A-4BC8-AB3A-93B4BE93900D@1x</title>
      <g id='-' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' strokeLinecap='square'>
        <g
          id='Kitlfy-for-Business-Style-Guide_2'
          transform='translate(-413.000000, -331.000000)'
          stroke={theme.palette.primary.dark}
        >
          <g id='Icon/Browse-Copy-3' transform='translate(413.000000, 331.000000)'>
            <g id='layout-11' transform='translate(3.000000, 1.000000)'>
              <rect id='Rectangle' x='0.5' y='0.5' width='7' height='12'></rect>
              <rect id='Rectangle' x='0.5' y='16.5' width='7' height='6'></rect>
              <rect id='Rectangle' x='11.5' y='0.5' width='7' height='6'></rect>
              <rect id='Rectangle' x='11.5' y='10.5' width='7' height='12'></rect>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default DashboardIcon;
