import React, { useState, useEffect } from 'react';
import { useUserConfig } from 'hooks/useUserConfig';
import { Typography, Grid, ButtonBase, CircularProgress, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Modal from 'components/Modal';
import appConfig from 'config/appConfig';
import PlusIcon from '@mui/icons-material/Add';
import FacebookIcon from 'assets/images/facebook.svg';
import InstagramIcon from 'assets/images/instagram.svg';
import ManImage from 'assets/images/man_top.png';

const ReauthenticationModal = () => {
  const theme = useTheme();
  const { classes } = styles();
  const { userData, loading } = useUserConfig();

  const [isFacebookModalOpen, setIsFacebookModalOpen] = useState(false);
  const [userIdToUse, setUserIdToUse] = useState<undefined | string>();

  useEffect(() => {
    if (userData?.myUser?.doesRequireReauthentication === true) {
      if (userIdToUse !== userData?.myUser?._id) {
        setUserIdToUse(userData?.myUser?._id || undefined);
        setIsFacebookModalOpen(true);
      }
    }
  }, [userData, userIdToUse, setUserIdToUse, setIsFacebookModalOpen]);

  if (isFacebookModalOpen && userIdToUse) {
    return (
      <Modal
        isOpen
        onToggle={() => setIsFacebookModalOpen(false)}
        showCloseIcon
        hideConfirmationButtons
        maxWidth={'sm'}
        paperStyle={{
          borderColor: theme.palette.primary.main,
          borderStyle: 'solid',
          borderWidth: 0.5,
          borderRadius: 4
        }}
      >
        <Grid container justifyContent='flex-start' alignItems='center' direction='column' style={{ height: 500 }}>
          <Grid item className={classes.row}>
            <img src={ManImage} alt='reauthentication header' />
          </Grid>
          <Grid item className={classes.row}>
            <Typography style={{ textAlign: 'center', fontWeight: 500 }}>
              Uh Oh! Looks like your Kitly account has been disconnected from Facebook and Instagram.
            </Typography>
          </Grid>
          <Grid item className={classes.row}>
            <Typography style={{ textAlign: 'center' }}>
              Thats ok, It happens, to ensure you and your brand partners don’t lose any media insights reconnect your
              accounts by loging in with Facebook below.
            </Typography>
          </Grid>
          <Grid item className={classes.row}>
            <Grid container alignItems='center' spacing={2}>
              <Grid item>
                <img src={FacebookIcon} alt='facebook' className={classes.socialIcon} />
              </Grid>
              <Grid item>
                <PlusIcon color='primary' />
              </Grid>
              <Grid item>
                <img src={InstagramIcon} alt='instagram' className={classes.socialIcon} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <ButtonBase
              href={`https://www.facebook.com/v6.0/dialog/oauth?client_id=${appConfig.FACEBOOK_APP_ID}&display=popup&scope=${appConfig.FACEBOOK_APP_PERMISSIONS}&redirect_uri=${appConfig.FACEBOOK_REDIRECT_URI}&state=${userIdToUse}`}
            >
              <Grid
                container
                justifyContent='center'
                alignItems='center'
                style={{ backgroundColor: '#4D7CFE', height: 46, width: 206, borderRadius: 4 }}
              >
                {loading ? (
                  <CircularProgress style={{ color: '#fff' }} />
                ) : (
                  <Grid
                    item
                    style={{
                      fontSize: 14,
                      textTransform: 'uppercase',
                      fontFamily: '.SF NS Display Regular',
                      color: theme.palette.common.white,
                      lineHeight: '17px'
                    }}
                  >
                    Login with Facebook
                  </Grid>
                )}
              </Grid>
            </ButtonBase>
          </Grid>
        </Grid>
      </Modal>
    );
  }

  return null;
};

const styles = makeStyles()(() => ({
  socialIcon: {
    width: 30,
    height: 30
  },
  row: {
    marginBottom: 25,
    maxWidth: 384
  }
}));

export default ReauthenticationModal;
