import { Route, Routes } from 'react-router-dom';
import CustomDrawer from 'components/CustomDrawer';
import Dashboard from 'components/Dashboard/index';
import { MediaKitsPage } from './MediaKitsPage';
import { CollabsPage } from './CollabsPage';
import { BrandsPage } from './BrandsPage';
import ReportPage from './ReportPage/ReportPage';
import AccountPerformance from 'components/Dashboard/AccountPerformance/AccountPerformance';
import Settings from './Settings';
import { AWSQueueNotifier } from 'hooks/useAWSQueue';
import FinancePage from './Finance/FinancePage';
import AccountCategoriesPage from './AccountCategoriesPage/AccountCategoriesPage';

const DashboardPage = () => {
  return (
    <>
      <AWSQueueNotifier />
      <CustomDrawer>
        <Routes>
          <Route path={'account-categories'} element={<AccountCategoriesPage />} />
          <Route path={'account-performance/:id'} element={<AccountPerformance />} />
          <Route path={'account-performance'} element={<AccountPerformance />} />
          <Route path={'media-kits/:activeTab'} element={<MediaKitsPage />} />
          <Route path={'media-kits'} element={<MediaKitsPage />} />
          <Route path={'/reports'} element={<ReportPage />} />
          <Route path={'/campaigns/:activeTab'} element={<CollabsPage />} />
          <Route path={'/campaigns'} element={<CollabsPage />} />
          <Route path={'/finance/:activeTab'} element={<FinancePage />} />
          <Route path={'/finance'} element={<FinancePage />} />
          <Route path={'/my-brands/new'} element={<BrandsPage />} />
          <Route path={'/my-brands/invite'} element={<BrandsPage />} />
          <Route path={'/my-brands/view/:contactId'} element={<BrandsPage />} />
          <Route path={'/my-brands'} element={<BrandsPage />} />
          <Route path={'/settings/:activeTab/:redirectfrom'} element={<Settings />} />
          <Route path={'/settings/:activeTab'} element={<Settings />} />
          <Route path={'/settings'} element={<Settings />} />
          <Route path={'/'} element={<Dashboard />} />
        </Routes>
      </CustomDrawer>
    </>
  );
};

export default DashboardPage;
