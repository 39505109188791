import { Button, ButtonBase, Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { CSSProperties } from 'react';
import ChevronSmall from 'assets/icons/ChevronSmall.svg';
import referBg from 'assets/images/refer-bg.png';
import { InviteDialog } from 'components/Shared/InviteDialog';
import { useDialog } from 'hooks/useDialog';

const styles = makeStyles()((theme: any) => ({
  clickableText: {
    color: '#C12265'
  },
  bodyText: {
    fontSize: 14,
    lineHeight: '19px'
  },
  textHighlight: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    textAlign: 'left'
  },
  articleLink: {
    color: theme.palette.primary.main,
    fontSize: 12,
    marginBottom: 5
  },
  articleArrow: {
    marginLeft: 10
  },
  inviteBanner: {
    width: '100%',
    maxWidth: '170px',
    height: 'fit-content',
    borderRadius: '5px',
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    flexDirection: 'column'
  },
  inviteBannerHorizontal: {
    backgroundColor: '#F7F7FA',
    width: 449,
    position: 'relative',
    overflow: 'hidden'
  },
  inviteTextContainer: {
    maxWidth: 255
  },
  inviteText: {
    padding: 20
  },
  paddingTop: {
    paddingTop: 10
  },
  paddingBottom: {
    paddingBottom: 15
  },
  cardHeading: {
    fontSize: 14,
    marginBottom: 20,
    fontWeight: 500
  },
  cardText: {
    marginBottom: 5,
    fontSize: 12
  },
  cardImage: {
    maxWidth: 53,
    borderRadius: '50%'
  }
}));

const InviteTitle = () => <span>'Enjoying Kitly?'</span>;

const InviteBody = () => {
  const { classes } = styles();
  return (
    <Typography className={classes.bodyText}>
      Invite the brands you’ve worked with to join Kitly Business and receive a $50 E-rewards card when they subscribe.
    </Typography>
  );
};

const ArticleLink = () => {
  const { classes } = styles();
  return (
    <ButtonBase
      href='https://support.q-83.com/en/articles/4341096-how-referrals-work-for-kitly-business'
      target='_blank'
      rel='noopener noreferrer'
      className={classes.articleLink}
    >
      <Typography className={`${classes.bodyText} ${classes.clickableText}`}>How Referrals work</Typography>
      <img src={ChevronSmall} alt='' className={classes.articleArrow} />
    </ButtonBase>
  );
};

const VerticalInviteBannerBase = () => {
  const { classes } = styles();
  const { bind, onOpen } = useDialog();
  return (
    <div className={classes.inviteBanner}>
      <div className={classes.inviteText}>
        <Typography variant='h5' className={classes.paddingBottom}>
          <InviteTitle />
        </Typography>
        <Typography variant='body1' className={classes.paddingBottom}>
          <InviteBody />
        </Typography>
        <ArticleLink />
        <div className={`${classes.paddingBottom} ${classes.paddingTop}`}>
          <Button variant='contained' onClick={onOpen}>
            Invite
          </Button>
        </div>
      </div>
      <img
        src={'https://media.q-83.com/kitly-business/InvitePaperPlane.png'}
        alt=''
        style={{ width: '100%', borderRadius: 3 }}
      />
      <InviteDialog {...bind} />
    </div>
  );
};

const HorizontalInviteBannerBase = ({ style }: { style?: CSSProperties }) => {
  const { classes } = styles();
  const { bind, onOpen } = useDialog();

  return (
    <div className={classes.inviteBannerHorizontal} style={{ ...style }}>
      <div className={classes.inviteTextContainer}>
        <div className={classes.inviteText}>
          <Typography variant='h5' className={classes.paddingBottom}>
            <InviteTitle />
          </Typography>
          <Typography variant='body1' className={classes.paddingBottom}>
            <InviteBody />
          </Typography>
          <ArticleLink />
          <div className={`${classes.paddingBottom} ${classes.paddingTop}`}>
            <Button variant='contained' onClick={onOpen}>
              Invite
            </Button>
          </div>
        </div>
      </div>
      <img src={referBg} alt='' style={{ position: 'absolute', bottom: '-130px', right: '-165px', maxHeight: 360 }} />
      <InviteDialog {...bind} />
    </div>
  );
};

const InviteCardBase = () => {
  const { classes } = styles();
  const { bind, onOpen } = useDialog();
  return (
    <Paper
      style={{
        display: 'flex',
        flexDirection: 'inherit',
        padding: 20,
        backgroundColor: '#F7F7FA'
      }}
    >
      <Grid item className={classes.cardHeading}>
        <InviteTitle />
      </Grid>
      <Grid item container className={classes.cardText}>
        <Grid item md={10} style={{ paddingRight: 5 }}>
          <InviteBody />
        </Grid>
        <Grid item md={2}>
          <img src={'https://media.q-83.com/kitly-business/SmilingLady.png'} alt='' className={classes.cardImage} />
        </Grid>
      </Grid>
      <Grid item>
        <ArticleLink />
      </Grid>
      <Grid item>
        <Button variant='contained' onClick={onOpen}>
          Invite
        </Button>
      </Grid>
      <InviteDialog {...bind} />
    </Paper>
  );
};

const VerticalInviteBanner = VerticalInviteBannerBase;
const HorizontalInviteBanner = HorizontalInviteBannerBase;
const InviteCard = InviteCardBase;

export { VerticalInviteBanner, HorizontalInviteBanner, InviteCard };
