import AtheleteIcon from '../assets/icons/talent-categories/category_athlete.svg';
import BeautyLoverIcon from '../assets/icons/talent-categories/category_beauty_lover.svg';
import CreativeIcon from '../assets/icons/talent-categories/category_creative.svg';
import FashionistaIcon from '../assets/icons/talent-categories/category_fashionista.svg';
import FoodieIcon from '../assets/icons/talent-categories/category_foodie.svg';
import GamerIcon from '../assets/icons/talent-categories/category_gamer.svg';
import HealthIcon from '../assets/icons/talent-categories/category_health.svg';
import JournalistIcon from '../assets/icons/talent-categories/category_journalist.svg';
import LifestylistIcon from '../assets/icons/talent-categories/category_lifestylist.svg';
import PrivateIcon from '../assets/icons/talent-categories/category_lock.svg';
import LuxurianIcon from '../assets/icons/talent-categories/category_luxurian.svg';
import MotorheadIcon from '../assets/icons/talent-categories/category_motorhead.svg';
import ParentIcon from '../assets/icons/talent-categories/category_parent.svg';
import PetIcon from '../assets/icons/talent-categories/category_pet.svg';
import PublicFigureEntrepreneurIcon from '../assets/icons/talent-categories/category_public_figure_entrepreneur.svg';
import TradieIcon from '../assets/icons/talent-categories/category_tradie.svg';
import TravellerIcon from '../assets/icons/talent-categories/category_traveller.svg';
import FitnessIcon from '../assets/icons/talent-categories/Fitness.png';
import DIYIcon from '../assets/icons/talent-categories/DIY.png';
import ExpertIcon from '../assets/icons/talent-categories/Expert.png';
import InteriorsIcon from '../assets/icons/talent-categories/Interiors.png';

export interface ICategory {
  value: string;
  label: string;
  icon: string;
}

export const availableCategories: ICategory[] = [
  { value: 'fashionista', label: 'Fashion', icon: FashionistaIcon },
  { value: 'entrepreneur', label: 'Entrepreneur', icon: PublicFigureEntrepreneurIcon },
  { value: 'traveller', label: 'Traveller', icon: TravellerIcon },
  { value: 'foodie', label: 'Foodie', icon: FoodieIcon },
  { value: 'athlete', label: 'Athlete', icon: AtheleteIcon },
  { value: 'beauty_lover', label: 'Beauty', icon: BeautyLoverIcon },
  { value: 'tradie', label: 'Tradie', icon: TradieIcon },
  { value: 'creative', label: 'Creative', icon: CreativeIcon },
  { value: 'journalist', label: 'Journalist', icon: JournalistIcon },
  { value: 'parent', label: 'Parent', icon: ParentIcon },
  { value: 'health', label: 'Health', icon: HealthIcon },
  { value: 'luxurian', label: 'Luxurian', icon: LuxurianIcon },
  { value: 'public_figure', label: 'Public Figure', icon: PublicFigureEntrepreneurIcon },
  { value: 'lifestylist', label: 'Lifestyle', icon: LifestylistIcon },
  { value: 'gamer', label: 'Gamer', icon: GamerIcon },
  { value: 'motorhead', label: 'Motorhead', icon: MotorheadIcon },
  { value: 'pet', label: 'Pet', icon: PetIcon },
  { value: 'fitness', label: 'Fitness', icon: FitnessIcon },
  { value: 'diy', label: 'DIY', icon: DIYIcon },
  { value: 'expert', label: 'Expert', icon: ExpertIcon },
  { value: 'interiors', label: 'Interiors', icon: InteriorsIcon },
  { value: 'private', label: 'Private', icon: PrivateIcon }
];
