import React, { useMemo } from 'react';
import { Grid, Typography } from '@mui/material';
import Avatar from 'components/Avatar';
import { CollaborationInvoiceLink, ReportLink, MediaKitLink, Contact } from 'types/generated';
import { isCollaborationInvoicelink, isReportLink, isMediaKitLink } from 'types/custom';
import { makeStyles } from 'tss-react/mui';
interface IContactCell {
  link?: CollaborationInvoiceLink | ReportLink | MediaKitLink;
  contact?: Omit<Contact, '_id'> & { _id?: string };
  avatarSize?: number;
}
type ContactDetails = { avatar?: string; name?: string; email?: string };
const ContactCell: React.FC<IContactCell> = (props) => {
  const { link, contact, avatarSize } = props;
  const { classes } = useClasses();

  const recipient: ContactDetails | undefined = useMemo(() => {
    if (contact) {
      return {
        avatar: contact.avatar || undefined,
        email: contact.email || undefined,
        name: contact.name || undefined
      };
    }

    if (link?.contact) {
      return {
        avatar: link.contact.avatar || link.contact.parent?.avatar || undefined,
        email: link.contact.email || undefined,
        name: link.contact.name || link.contact.parent?.name || undefined
      };
    }

    if (isCollaborationInvoicelink(link) && link.contactDetails) {
      return {
        email: link.contactDetails.email || undefined,
        name: `${link.contactDetails.firstName} ${link.contactDetails.lastName}`
      };
    }

    if (isReportLink(link) || isMediaKitLink(link)) {
      return {
        email: link.email || undefined,
        name: link.name || undefined
      };
    }

    return undefined;
  }, [link, contact]);

  if (!recipient) {
    return null;
  }

  return (
    <Grid container alignItems='center' className={classes.container}>
      <Grid item className={classes.avatar}>
        {recipient.avatar && <Avatar src={recipient.avatar} size={avatarSize} />}
      </Grid>

      <Grid item>
        <Typography className={classes.title}>{recipient.email || recipient.name}</Typography>
        {Boolean(recipient.email && recipient.name) && (
          <Typography className={classes.subtitle}>{recipient.name}</Typography>
        )}
      </Grid>
    </Grid>
  );
};

const useClasses = makeStyles()(() => ({
  container: {
    marginRight: 5
  },
  avatar: { margin: 5 },
  title: { size: 14 },
  subtitle: {
    fontSize: 10
  }
}));

export default ContactCell;
