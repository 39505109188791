export function formatPercentage(num?: number | null, multiplier?: number, decimalPlaces?: number) {
  if (typeof num === 'number' && multiplier !== 0) {
    if (typeof multiplier === 'number') {
      num /= multiplier;
    }

    return num ? (Math.round(num * 10000) / 100).toFixed(decimalPlaces ?? 2) : '0';
  }

  return '0';
}

export const getPercentage = (value: number, sum: number) => {
  if (value > 0 && sum > 0) return ((value / sum) * 100).toFixed(0);

  return 0;
};

export const rnum = (max: number = 100) => Math.floor(Math.random() * Math.floor(max));

export function renderWholeNumber(num: number) {
  return num ? Math.round(num).toLocaleString() : '0';
}

export function formatRatio(a: number, b: number) {
  const canCalculate = Boolean(typeof a === 'number' && typeof b === 'number' && b !== 0);
  if (canCalculate) {
    const ratio = a / b;
    return ratio;
  } else {
    return null;
  }
}

// Formats number to fixed decimal place while keeping type as number instead of string
export function formatToFixed(num: number, decimal: number) {
  if (typeof num === 'number' && decimal) {
    return parseFloat(num.toFixed(decimal));
  } else {
    return num;
  }
}

// Formats numbers (1000+) down to three digits with k, m, b (keeps numbers whole under 1000)
export function formatNumber(raw?: number | null) {
  if (typeof raw === 'number') {
    const num = Math.abs(raw);

    if (num > 999999999999) {
      return `${(raw / 1000000000).toFixed(2)}T`;
    } else if (num > 99999999999) {
      return `${(raw / 1000000000).toFixed(0)}B`;
    } else if (num > 9999999999) {
      return `${(raw / 1000000000).toFixed(1)}B`;
    } else if (num > 999999999) {
      return `${(raw / 1000000000).toFixed(2)}B`;
    } else if (num > 99999999) {
      return `${(raw / 1000000).toFixed(0)}M`;
    } else if (num > 9999999) {
      return `${(raw / 1000000).toFixed(1)}M`;
    } else if (num > 999999) {
      return `${(raw / 1000000).toFixed(2)}M`;
    } else if (num > 99999) {
      return `${(raw / 1000).toFixed(0)}K`;
    } else if (num > 9999) {
      return `${(raw / 1000).toFixed(1)}K`;
    } else {
      return Math.round(raw).toLocaleString();
    }
  }
  return '0';
}

// Formats all numbers down to three digits with k, m, b (always three digits, not always whole numbers)
export function formatNumberAll(raw: number) {
  if (typeof raw === 'number') {
    const num = Math.abs(raw);

    if (num > 999999999999) {
      return `${(raw / 1000000000).toFixed(2)}T`;
    } else if (num > 99999999999) {
      return `${(raw / 1000000000).toFixed(0)}B`;
    } else if (num > 9999999999) {
      return `${(raw / 1000000000).toFixed(1)}B`;
    } else if (num > 999999999) {
      return `${(raw / 1000000000).toFixed(2)}B`;
    } else if (num > 99999999) {
      return `${(raw / 1000000).toFixed(0)}M`;
    } else if (num > 9999999) {
      return `${(raw / 1000000).toFixed(1)}M`;
    } else if (num > 999999) {
      return `${(raw / 1000000).toFixed(2)}M`;
    } else if (num > 99999) {
      return `${(raw / 1000).toFixed(0)}K`;
    } else if (num > 9999) {
      return `${(raw / 1000).toFixed(1)}K`;
    } else if (num > 999) {
      return `${Math.round(raw).toLocaleString()}`;
    } else if (num > 99) {
      return `${raw.toFixed(1)}`;
    } else if (num > 9) {
      return `${raw.toFixed(2)}`;
    } else if (num === 0) {
      return raw.toLocaleString();
    } else {
      return raw.toLocaleString();
    }
  }
  return '0';
}

export function formatLongNumber(raw?: number | null) {
  if (typeof raw === 'number') {
    return Math.round(raw)
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return '0.00';
}

export const formatMinutes = (minutes: number) => `${Math.floor(minutes / 60)}h ${Math.round(minutes % 60)}m`;

export const formatSeconds = (seconds: number) => `${Math.floor(seconds / 60)}m ${Math.round(seconds % 60)}s`;

export const formatNumbersWithCommas = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
