import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { Grid, Typography } from '@mui/material';
import ThemeButton from 'components/ThemeButton';

import ChartImage from 'assets/images/chart-bar-33@1x.svg';

const NoConnectHint = () => {
  const { classes } = useClasses();
  const navigate = useNavigate();
  return (
    <Grid container direction={'row'} justifyContent={'space-between'} wrap={'nowrap'} className={classes.cardWrapper}>
      <Grid item container xs={12} sm={8} direction={'column'} justifyContent={'space-around'}>
        <Grid item style={{ marginBottom: 20 }}>
          <Typography className={classes.cardSubtitle}>No Accounts linked</Typography>
        </Grid>
        <Grid item className={classes.cardItem}>
          <Typography className={classes.sectionTitle}>Connect your accounts</Typography>
        </Grid>
        <Grid item className={classes.cardItem}>
          <Typography className={classes.cardSubtitle}>
            To view your insights and run reports on your content, you’ll need to{' '}
            <b>connect your social accounts to Kitly.</b>
          </Typography>
        </Grid>
        <Grid item className={classes.actionContainer}>
          <ThemeButton
            text='Connect'
            variant='outlined'
            _onClick={() => {
              navigate('/settings/linkedaccounts');
            }}
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} sm={4} alignItems='center' justifyContent='center'>
        <Grid item>
          <img src={ChartImage} style={{ width: 77, height: 77 }} alt='Chart' />
        </Grid>
      </Grid>
    </Grid>
  );
};

const useClasses = makeStyles()((theme) => ({
  cardWrapper: {
    width: '100%'
  },
  cardTitle: {
    fontWeight: 'bold',
    fontSize: 16
  },
  cardItem: {
    marginBottom: 8
  },
  cardSubtitle: {
    fontSize: 12,
    color: '#6B6B6B'
  },
  sectionTitle: {
    color: theme.palette.primary.dark,
    fontSize: 14,
    fontWeight: 'bold'
  },
  actionContainer: {
    marginTop: 20
  }
}));

export default NoConnectHint;
