import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { useMobileView } from '../../hooks/useMobileView';
import Button from '@mui/material/Button';
import q83API from '../../api/q83API';

const styles = makeStyles()((theme) => ({
  bodyContainer: {
    minHeight: 350,
    display: 'flex',
    flexDirection: 'column',
    padding: '10px'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxWidth: '100%',
    height: '100%',
    paddingBottom: 20,
    width: 500,
    margin: '0 auto'
  },
  error: {
    color: 'red'
  },
  progress: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    height: 200
  },
  payment: {
    minHeight: 200,
    padding: 10
  },
  actionContainer: {
    paddingBottom: 10,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  link: {
    width: '100%',
    maxWidth: 330,
    textDecoration: 'none',
    color: theme.palette.text.primary,
    marginTop: 15
  },
  button: {
    width: '100%',
    maxWidth: 330,
    textTransform: 'none',
    textDecoration: 'none',
    fontWeight: 700,
    height: 56
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 16
  }
}));

interface IPayment {
  chosenPlanId?: string;
  handleComplete?: () => void;
  handleCancel?: () => void;
}

const StripePayment = ({ chosenPlanId, handleComplete, handleCancel }: IPayment) => {
  const mobileView = useMobileView();
  const [isStripeCardReady, setIsStripeCardReady] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const { classes } = styles();

  const handleClick = async () => {
    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements?.getElement(CardElement);

    if (cardElement && stripe) {
      // Use your card Element with other Stripe.js APIs
      setIsLoading(true);
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement
      });

      if (error) {
        console.log('[Stripe ERROR]', error);
        setError(error.message);
        setIsLoading(false);
      } else {
        console.log('[PaymentMethod]', paymentMethod);

        if (paymentMethod) {
          q83API
            .put('stripe/subscription', {
              stripePaymentMethodId: paymentMethod.id,
              planId: chosenPlanId
            })
            .then((response) => {
              if (response && response.data && response.data.subscription) {
                if (handleComplete) handleComplete();
              } else {
                setError('There was a problem with your request');
              }
            })
            .catch((ex) => {
              console.log('Subscribe EXCEPTION', ex);
              setError(ex.message || ex);
            })
            .finally(() => setIsLoading(false));
        } else {
          setError('No valid payment method provided');
          setIsLoading(false);
        }
      }
    }
  };

  return (
    <div className={classes.bodyContainer} style={{ minWidth: mobileView ? 300 : 495 }}>
      <div className={classes.container}>
        <Typography className={classes.title}>Update Payment Method</Typography>
        <CardElement
          className={classes.payment}
          onReady={() => {
            setIsStripeCardReady(true);
          }}
          options={{
            hidePostalCode: true,
            style: {
              base: {
                fontFamily: 'Open Sans',
                fontSize: '16px',
                color: '#424770',
                '::placeholder': {
                  color: '#aab7c4'
                }
              },
              invalid: {
                color: '#9e2146'
              }
            }
          }}
        />
        {Boolean(!isStripeCardReady) && (
          <div className={classes.progress}>
            <Typography>Loading payment options...</Typography>
            <CircularProgress />
          </div>
        )}

        {error && <Typography className={classes.error}>{error}</Typography>}
        <div className={classes.actionContainer}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Button
              variant='contained'
              color='primary'
              className={classes.button}
              onClick={handleClick}
              disabled={Boolean(!isStripeCardReady)}
            >
              Continue
            </Button>
          )}
          <Button variant='outlined' color='primary' className={classes.button} onClick={handleCancel}>
            Back
          </Button>
        </div>
      </div>
    </div>
  );
};

export default StripePayment;
