import React from 'react';
import { useTheme } from '@mui/material';

const Tick = ({ active }: { active?: boolean }) => {
  const theme = useTheme();

  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>Icon/Ticked Copy 4@1x</title>
      <defs>
        <path
          d='M12,0 C18.627417,0 24,5.372583 24,12 C24,18.627417 18.627417,24 12,24 C5.372583,24 0,18.627417 0,12 C0,5.372583 5.372583,0 12,0 Z M17.8045054,8.68033307 C17.543846,8.43972231 17.1225244,8.43972231 16.8618649,8.68033307 L16.8618649,8.68033307 L10.0000556,15.0143651 L7.13813505,12.3725698 C6.87747563,12.131959 6.456154,12.131959 6.19549457,12.3725698 C5.93483514,12.6131805 5.93483514,13.0020961 6.19549457,13.2427069 L6.19549457,13.2427069 L9.52873531,16.3195708 C9.6587317,16.4395685 9.82939363,16.499875 10.0000556,16.499875 C10.1707175,16.499875 10.3413794,16.4395685 10.4713758,16.3195708 L10.4713758,16.3195708 L17.8045054,9.55047018 C18.0651649,9.30985942 18.0651649,8.92094383 17.8045054,8.68033307 Z'
          id='path-1'
        ></path>
      </defs>
      <g id='---' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Kitlfy-for-Business-Style-Guide_2' transform='translate(-621.000000, -669.000000)'>
          <g id='Group' transform='translate(621.000000, 669.000000)'>
            <mask id='mask-2' fill='white'>
              <use xlinkHref='#path-1'></use>
            </mask>
            <use id='Combined-Shape' fill='#FFFFFF' xlinkHref='#path-1'></use>
            <g mask='url(#mask-2)' fill={active ? theme.palette.primary.main : '#CCCCCC'} id='Rectangle-Copy-15'>
              <g>
                <rect x='0' y='0' width='100' height='100'></rect>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Tick;
