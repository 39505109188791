import { TableRow, TableHead, TableCell, Typography, useTheme, TableRowProps, TableCellProps } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

type Props = {
  children: { label: string; id: string; disablePadding?: boolean; style?: object }[];
};

export const StyledHeader = (props: Props): JSX.Element => {
  const theme: any = useTheme();
  const { classes } = useStyles();
  const { children: columns } = props;

  return (
    <TableHead>
      <TableRow>
        {columns.map((column) => {
          return (
            <TableCell
              classes={{ root: classes.tableCell }}
              style={{ ...column.style, fontWeight: 200 }}
              key={column.id}
              padding={column.disablePadding ? 'none' : 'normal'}
            >
              <Typography style={{ fontSize: 12, color: theme.palette.common.blueGray }}>{column.label}</Typography>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export const StyledTableRow = (props: TableRowProps) => {
  const { classes } = useStyles();
  return (
    <TableRow classes={{ hover: classes.tableRowHover }} {...props}>
      {props.children}
    </TableRow>
  );
};

export const StyledTableCell = (props: TableCellProps) => {
  const { classes } = useStyles();
  return (
    <TableCell classes={{ root: classes.tableCell }} {...props}>
      {props.children}
    </TableCell>
  );
};

const useStyles = makeStyles()({
  tableRowHover: {
    '&:hover': {
      // backgroundColor: '#eefaf5 !important'
    }
  },
  tableCell: {
    fontWeight: 200,
    maxWidth: '350px',
    padding: '15px 0px',
    verticalAlign: 'top'
  }
});
