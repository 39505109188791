import React, { useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useTheme } from '@mui/material';

import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import CircularProgress from '@mui/material/CircularProgress';

import isEmpty from 'lodash/isEmpty';

import PaymentPopup from './PaymentPopup';
import { formatError } from '../../lib/formatStrings';
import EditIcon from '../../assets/icons/EditIcon.svg';

interface PaymentMethod {
  token: string;
  expirationDate: string;
  imageUrl: string;
  last4: string;
  email: string;
  cardType: string;
  default: boolean;
}

interface MutationArgs {
  variables: {
    id: string;
  };
}

interface PaymentMethodMenuProps {
  paymentMethod: PaymentMethod;
  setShowPaymentPopup: (args: boolean) => void;
  makeDefaultPaymentMethod: (args: MutationArgs) => void;
  deletePaymentMethod?: (args: MutationArgs) => void;
}

const GET_USER_PAYMENT_METHODS = gql`
  query GetMyUser {
    myUser {
      _id
      email
      braintreeCustomer {
        paymentMethods
      }
    }
  }
`;

const MAKE_DEFAULT_PAYMENT_METHOD = gql`
  mutation SetPaymentMethodToDefault($id: String!) {
    setPaymentMethodToDefault(id: $id)
  }
`;

const DELETE_PAYMENT_METHOD = gql`
  mutation DeletePaymentMethod($id: String!) {
    deletePaymentMethod(id: $id)
  }
`;

const PaymentMethods = (): JSX.Element => {
  const theme = useTheme();

  const [showPaymentPopup, setShowPaymentPopup] = useState<boolean>(false);

  const {
    data: userPaymentMethodsData,
    loading: paymentMethodDataLoading,
    error: paymentMethodDataError,
    refetch: refetchPaymentMethods
  } = useQuery(GET_USER_PAYMENT_METHODS, { fetchPolicy: 'no-cache' });
  const userPaymentMethods = userPaymentMethodsData?.myUser?.braintreeCustomer?.paymentMethods || [];

  const [makeDefaultPaymentMethod, { loading: makeDefaultMutationLoading, error: makeDefaultMutationError }] =
    useMutation(MAKE_DEFAULT_PAYMENT_METHOD, {
      onCompleted: () => refetchPaymentMethods()
    });
  const [
    deletePaymentMethod,
    { loading: deletePaymentMethodMutationLoading, error: deletePaymentMethodMutationError }
  ] = useMutation(DELETE_PAYMENT_METHOD, {
    onCompleted: () => refetchPaymentMethods()
  });

  const loading = paymentMethodDataLoading || makeDefaultMutationLoading || deletePaymentMethodMutationLoading;
  const error = paymentMethodDataError || makeDefaultMutationError || deletePaymentMethodMutationError;

  const numberOfPaymentMethods = userPaymentMethods?.length || 0;

  if (loading) {
    return (
      <Grid container justifyContent='center' alignItems='center' style={{ height: 60 }}>
        <Grid>
          <CircularProgress style={{ color: theme.palette.primary.main }} />
        </Grid>
      </Grid>
    );
  }

  if (isEmpty(userPaymentMethodsData)) {
    return (
      <Grid container direction='column'>
        <Grid item>No payment methods found</Grid>
        {error && (
          <Grid item style={{ color: '#F00' }}>
            {formatError(error)}
          </Grid>
        )}
      </Grid>
    );
  }

  return (
    <Grid container direction='column'>
      {userPaymentMethods.map((pm: PaymentMethod, i: number) => {
        return (
          <Grid item container alignItems='center' key={`payment-method-${i}`} style={{ marginBottom: 10 }}>
            <Grid item container alignItems='center' justifyContent='space-between' xs={8}>
              <Grid item>
                <img src={pm.imageUrl} alt={pm.cardType} />
              </Grid>
              <Grid item>{`Card ending in ${pm.last4}.`}</Grid>
              <Grid item>{`Exp ${pm.expirationDate}`}</Grid>
            </Grid>
            <Grid item container justifyContent='flex-end' xs={4}>
              {pm.default && <Grid item>Primary Card</Grid>}
              <Grid item style={{ marginLeft: 30 }}>
                <PaymentMethodMenu
                  paymentMethod={pm}
                  setShowPaymentPopup={setShowPaymentPopup}
                  makeDefaultPaymentMethod={makeDefaultPaymentMethod}
                  deletePaymentMethod={numberOfPaymentMethods > 1 ? deletePaymentMethod : undefined}
                />
              </Grid>
            </Grid>
          </Grid>
        );
      })}
      <Grid item style={{ marginBottom: 30 }}>
        <Button
          data-cy='edit-payment-button'
          onClick={() => setShowPaymentPopup(true)}
          color='primary'
          variant='outlined'
        >
          Add a New Card
        </Button>
      </Grid>
      <PaymentPopup
        open={showPaymentPopup}
        handlePopup={() => {
          setShowPaymentPopup(!showPaymentPopup);
        }}
        refetch={refetchPaymentMethods}
      />
    </Grid>
  );
};

const PaymentMethodMenu = (props: PaymentMethodMenuProps): JSX.Element => {
  const { paymentMethod, makeDefaultPaymentMethod, deletePaymentMethod } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  return (
    <>
      <ButtonBase onClick={openMenu}>
        <img src={EditIcon} alt='Edit payment options menu' />
      </ButtonBase>
      <Menu open={Boolean(anchorEl)} id='edit-payment-method-menu' anchorEl={anchorEl} keepMounted onClose={closeMenu}>
        <MenuItem
          data-cy={`edit-payment-method`}
          onClick={() => {
            makeDefaultPaymentMethod({ variables: { id: paymentMethod?.token } });
            closeMenu();
          }}
        >
          Make Default
        </MenuItem>
        {deletePaymentMethod && (
          <MenuItem
            data-cy={`edit-payment-method`}
            onClick={() => {
              deletePaymentMethod({ variables: { id: paymentMethod?.token } });
              closeMenu();
            }}
          >
            Delete
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default PaymentMethods;
