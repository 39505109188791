import React, { useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material';
import { useErrorHandlerHook } from '../../hooks/useErrorCatchHook';
import { useGetMyUserUsageQuery } from '../../types/generated';

interface ILimitTracker {
  unlimited?: boolean;
}

const LimitTracker = ({ unlimited }: ILimitTracker) => {
  const theme = useTheme();

  const { data: usageData, loading: usageLoading, error: usageError } = useGetMyUserUsageQuery();

  const { apolloHandler } = useErrorHandlerHook();

  useEffect(() => {
    if (usageError) {
      apolloHandler(usageError);
    }
  }, [usageError, apolloHandler]);

  if (usageLoading) {
    return (
      <Grid item xs={6} container alignContent='center' justifyContent='center'>
        <CircularProgress />
      </Grid>
    );
  }

  const reportsUsage = usageData?.myUser?.reportsUsage;
  const collabUsage = usageData?.myUser?.collaborationsUsage;
  const mediaKitUsage = usageData?.myUser?.mediaKitsUsage;

  // Fetch collabs:
  return (
    <Grid item container xs={6} direction='column' alignContent='center' justifyContent='center' spacing={1}>
      <Grid item style={{ marginBottom: 10 }}>
        Used / Remaining
      </Grid>
      <Grid item>
        <div>
          {unlimited ? (
            'Unlimited Reports'
          ) : (
            <span style={{ color: theme.palette.primary.main }}>
              {`${reportsUsage?.count || 0} / ${unlimited ? `∞` : reportsUsage?.limit || 0}`}
              {' Reports'}
            </span>
          )}
        </div>
      </Grid>
      <Grid item>
        <div>
          {unlimited ? (
            'Unlimited Campaigns'
          ) : (
            <span style={{ color: theme.palette.primary.main }}>
              {`${collabUsage?.count || 0} / ${unlimited ? `∞` : collabUsage?.limit || 0}`}
              {' Campaigns'}
            </span>
          )}
        </div>
      </Grid>
      <Grid item>
        <div>
          {unlimited ? (
            'Unlimited Media Kits'
          ) : (
            <span style={{ color: theme.palette.primary.main }}>
              {`${mediaKitUsage?.count || 0} / ${unlimited ? `∞` : mediaKitUsage?.limit || 0}`}
              {' Media Kits'}
            </span>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default LimitTracker;
