import { AppBar, ButtonBase, CircularProgress, Grid, Toolbar } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import logo from 'assets/images/logo.svg';
import { CenteredContainer } from 'components/Shared/CenteredContainer';
import AppConfig from '../../config/appConfig';

const Logout = () => {
  const { classes } = useStyles();

  return (
    <>
      <AppBar position='fixed' className={classes.appBar} elevation={1}>
        <Toolbar>
          <ButtonBase href={AppConfig.APP_URL}>
            <img src={logo} alt='logo' className={classes.logo} />
          </ButtonBase>
        </Toolbar>
      </AppBar>
      <CenteredContainer>
        <Grid container direction='column' alignItems='center' style={{ rowGap: 20 }}>
          <CircularProgress color='secondary' />
        </Grid>
      </CenteredContainer>
    </>
  );
};

export default Logout;

const useStyles = makeStyles()((theme) => ({
  appBar: {
    backgroundColor: theme.palette.secondary.main,
    zIndex: theme.zIndex.drawer + 1
  },
  logo: {
    height: 50,
    width: 86
  },
  centeredContainer: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute'
  }
}));
