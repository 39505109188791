import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { makeStyles } from 'tss-react/mui';
import ProfileSelectSlider from 'components/Dashboard/AccountPerformance/ProfileSelectSlider';
import { TileContainer } from 'components/Containers/TileContainer';
import { useMyUserProfilesQuery, SocialChannel, Profile } from 'types/generated';
import YouTubeCardInsights from '../../AccountPerformance/insights/YTTabComponent/YoutubeCardInsights';
import FacebookCardInsights from '../../AccountPerformance/insights/FacebookTabComponent/FacebookCardInsights';
import InstagramCardInsights from '../../AccountPerformance/insights/InsTabComponents/InstagramCardInsights';
import TiktokCardInsights from 'components/Dashboard/AccountPerformance/insights/TiktokCardInsights';
import PerformanceBadge from '../../AccountPerformance/PerformanceBadge';

interface IAccountPerformanceCard {}
const AccountPerformanceCard: React.FC<IAccountPerformanceCard> = () => {
  const { classes } = useClasses();
  const [selectedProfile, setSelectedProfile] = useState<Profile | undefined>();
  const navigate = useNavigate();

  // true: already displayed
  // false: not displayed yet

  const { data } = useMyUserProfilesQuery();
  const myUser = data?.myUser;
  // use the profileId and the user id to make a request to the backend

  return (
    <TileContainer
      title={'Account Performance'}
      topRightContent={
        Boolean(myUser?.profiles?.length) && (
          <Button
            variant='outlined'
            size='small'
            color='primary'
            disabled={Boolean(!selectedProfile)}
            onClick={() => navigate(`/account-performance/${selectedProfile?.id}`)}
          >
            View More
          </Button>
        )
      }
    >
      <Container className={classes.cardWrapper}>
        {Boolean(myUser?.profiles?.length) && <Typography className={classes.cardSubtitle}>Select channel</Typography>}
        <ProfileSelectSlider
          value={selectedProfile?.id}
          onSelect={(profile) => {
            setSelectedProfile(profile || undefined);
          }}
        />
        <>
          {selectedProfile?._id && (
            <>
              <PerformanceBadge profile={selectedProfile} />
              {selectedProfile.profileType === SocialChannel.Instagram && (
                <InstagramCardInsights profileId={selectedProfile._id} />
              )}
              {selectedProfile.profileType === SocialChannel.Facebook && (
                <FacebookCardInsights profileId={selectedProfile._id} />
              )}
              {selectedProfile.profileType === SocialChannel.Youtube && selectedProfile.id && (
                <YouTubeCardInsights channelId={selectedProfile.id} />
              )}
              {selectedProfile.profileType === SocialChannel.Tiktok && (
                <TiktokCardInsights profileId={selectedProfile._id} />
              )}
            </>
          )}
        </>
      </Container>
    </TileContainer>
  );
};

const useClasses = makeStyles()((theme) => ({
  cardWrapper: {
    paddingLeft: 0
  },
  cardSubtitle: {
    fontSize: 12,
    color: '#19252E',
    fontWeight: 500,
    fontFamily: 'Open Sans'
  },
  sectionTitle: {
    color: theme.palette.primary.dark,
    fontSize: 14
  }
}));

export default AccountPerformanceCard;
