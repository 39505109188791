import { Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import addBrandIcon from 'assets/icons/add-brand.png';
import editIcon from 'assets/icons/EditIcon.svg';
import InviteIconGray from 'assets/icons/InviteIconGray.svg';
import { HorizontalInviteBanner } from 'components/ReferAndEarn';
import { COLORS } from 'lib/constants';
import { useNavigate, useLocation } from 'react-router-dom';
import { Header } from './Header';
import { BrandCreation } from './BrandCreation';

export const NoData = () => {
  const { classes } = useStyles();
  const location = useLocation();
  const isModalOpen = location.pathname.includes('/new') || false;
  const navigate = useNavigate();

  const renderEmptyCard = () => {
    return (
      <Grid component={Paper} className={classes.root} alignItems='center' container direction='column'>
        <Grid item container justifyContent='flex-end'>
          <img src={editIcon} alt='edit icon' className={classes.editIcon} />
        </Grid>
        <BrandCreation isModalOpen={isModalOpen} disableButtonRipple>
          <img
            src={addBrandIcon}
            alt='add brand icon'
            className={classes.addBrandIcon}
            onClick={() => {
              navigate('/my-brands/new');
            }}
          />
        </BrandCreation>
        <Typography className={classes.text}>New Brand</Typography>
        <div className={classes.addPersonIconContainer}>
          <img src={InviteIconGray} alt='Add Contact' className={classes.addPersonIcon} />
        </div>
      </Grid>
    );
  };

  return (
    <>
      <Header />
      <Grid container className={classes.container}>
        {renderEmptyCard()}
        <HorizontalInviteBanner />
      </Grid>
    </>
  );
};

const useStyles = makeStyles()((theme) => {
  return {
    addBrandIcon: {
      marginBottom: 10,
      '&:hover': {
        cursor: 'pointer'
      }
    },
    container: {
      marginTop: 21
    },
    root: {
      padding: '10px 21px',
      width: 215,
      height: 257,
      marginRight: 20
    },
    circle: {
      backgroundColor: '#F7E690',
      height: 64,
      width: 64,
      marginBottom: 10,
      borderRadius: '50%'
    },
    text: {
      fontSize: 16,
      marginBottom: 15
    },
    editIcon: {
      height: 24,
      width: 24
    },
    addPersonIcon: {
      width: 18,
      height: 18,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)'
    },
    addPersonIconContainer: {
      borderRadius: '50%',
      border: `0.5px solid ${COLORS.GRAY_1}`,
      height: 32,
      width: 32,
      position: 'relative'
    }
  };
});
