import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material';
import {
  useDeleteProfileMutation,
  useGetMyUserQuery,
  useListYoutubeProfilesQuery,
  useCreateProfileMutation,
  SocialChannel
} from '../../types/generated';

import {
  Grid,
  Button,
  Paper,
  Typography,
  Avatar,
  CircularProgress,
  Divider,
  FormControlLabel,
  lighten
} from '@mui/material';

import isEmpty from 'lodash/isEmpty';

import Modal from 'components/Modal';
import AppConfig from '../../config/appConfig';
import useTracking from 'context/useTrackingCtx';
import { channelIcons } from 'lib/constants';
import { useMobileView } from '../../hooks/useMobileView';
import { useErrorHandlerHook } from 'hooks/useErrorCatchHook';
import { IOSSwitch } from 'components/Fields/IOSSwitch';

const googleAuthLink = `https://accounts.google.com/o/oauth2/v2/auth`;

const fallbackProfileImg = 'https://media.q-83.com/site/user.png';

const YoutubeProfile = () => {
  const theme = useTheme();

  const { data: userData, loading: userLoading, error: userError } = useGetMyUserQuery();

  const {
    data: profilesData,
    loading: profilesLoading,
    error: profilesError,
    refetch: profilesRefetch
  } = useListYoutubeProfilesQuery();

  const [creatingYTProfile, setCreatingYTProfile] = useState<string | undefined>();

  const [createYoutubeProfile, { loading: createLoading, error: createError }] = useCreateProfileMutation({
    onCompleted: () => {
      setCreatingYTProfile(undefined);
      profilesRefetch();
    }
  });

  const [deletingYTProfile, setDeletingYTProfile] = useState<string | undefined>();

  const [deleteYoutubeProfile, { loading: deleteLoading, error: deleteError }] = useDeleteProfileMutation({
    onCompleted: () => {
      setDeletingYTProfile(undefined);
      profilesRefetch();
    }
  });

  const { trackEvent } = useTracking();

  const { apolloHandler } = useErrorHandlerHook();

  useEffect(() => {
    if (createError) {
      apolloHandler(createError, `Unable to create YouTube profile`);
    }
  }, [createError, apolloHandler]);

  useEffect(() => {
    if (deleteError) {
      apolloHandler(deleteError, `Unable to delete YouTube profile`);
    }
  }, [deleteError, apolloHandler]);

  useEffect(() => {
    if (profilesError) {
      apolloHandler(profilesError, `Unable to get social profiles`);
    }
  }, [profilesError, apolloHandler]);

  useEffect(() => {
    if (userError) {
      apolloHandler(userError, `Unable to get user info`);
    }
  }, [userError, apolloHandler]);

  const [mobileView] = useMobileView();

  const isLoading = deleteLoading || userLoading || profilesLoading;

  const user = userData?.myUser;

  const youtubeProfiles = profilesData?.listYoutubeProfiles;

  const noYoutubeProfiles = Boolean(!profilesLoading && youtubeProfiles && isEmpty(youtubeProfiles));

  const handleDeleteProfile = (profileID: string) => {
    if (profileID === deletingYTProfile && user) {
      deleteYoutubeProfile({ variables: { profileId: profileID, pageId: user?._id } });
    }
  };

  const renderConfirmDeleteModal = () => {
    if (!deletingYTProfile) return null;
    return (
      <Modal
        isOpen={true}
        onToggle={() => {
          setDeletingYTProfile(undefined);
        }}
        onConfirm={() => {
          handleDeleteProfile(deletingYTProfile);
          setDeletingYTProfile(undefined);
        }}
        confirmLabel={'Disconnect'}
        heading={'Are you sure?'}
        fullScreen={mobileView}
        maxWidth={'sm'}
      >
        <div>Are you sure you want to disconnect this profile?</div>
      </Modal>
    );
  };

  const renderAuthenticateButton = () => {
    const googleAuthParams = [
      `scope=https://www.googleapis.com/auth/yt-analytics.readonly%20https://www.googleapis.com/auth/youtube.readonly`,
      `access_type=offline`,
      `include_granted_scopes=true`,
      `state=${user?._id}`,
      `redirect_uri=${AppConfig.API_URL}kitly-web/google/oauth`,
      `response_type=code`,
      `prompt=consent`,
      `client_id=${AppConfig.GOOGLE_OAUTH_CLIENT_ID}`
    ];

    return (
      <Button
        variant='outlined'
        href={`${googleAuthLink}?${googleAuthParams.join('&')}`}
        onClick={() => {
          trackEvent('profile', 'connected youtube channel');
        }}
      >
        <Grid container justifyContent='center' alignItems='center'>
          <Grid item>{`Login with`}</Grid>
          <Grid item container justifyContent='center' style={{ width: 'auto' }}>
            <img src={channelIcons.youtube} style={{ height: 16, marginLeft: 8 }} alt='youtube icon' />
          </Grid>
        </Grid>
      </Button>
    );
  };

  const renderExistingProfiles = () => {
    if (noYoutubeProfiles) {
      return (
        <Paper
          key='blank'
          style={{
            marginTop: 8,
            marginBottom: 8,
            padding: 8,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div style={{ fontSize: 14, margin: '7px' }}>No Youtube profiles available.</div>
        </Paper>
      );
    }

    return youtubeProfiles?.map((o, i) => {
      if (!o) return null;
      let profileImage = o.thumbnails?.default?.url || fallbackProfileImg;
      const loading =
        Boolean(deleteLoading && deletingYTProfile === o._id) || Boolean(createLoading && creatingYTProfile === o._id);
      return (
        <div key={i}>
          <Grid
            container
            key={i}
            alignItems='center'
            style={{ margin: 0, padding: `${theme.spacing(3)} 0px`, justifyContent: 'space-between' }}
          >
            <Grid container spacing={0} style={{ margin: 0, padding: 0 }}>
              <Grid item xs={6} sm={10}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: '5px'
                  }}
                >
                  <Avatar
                    src={profileImage}
                    style={{ width: 30, height: 30 }}
                    onError={(e) => {
                      // tslint:disable-line
                      e.persist();

                      const imageElement = e.target as HTMLImageElement;
                      if (imageElement) {
                        imageElement.src = fallbackProfileImg;
                      }
                    }}
                  />
                  <div style={{ marginLeft: 4 }}>
                    <div>{o.displayName}</div>
                    <div style={{ fontSize: 10 }}>YouTube</div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={3} sm={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <FormControlLabel
                  label={
                    <span style={{ color: o.isAddedByOwner ? undefined : lighten(theme.palette.common.black, 0.6) }}>
                      {o.isAddedByOwner
                        ? loading
                          ? 'Disconnecting...'
                          : 'Disconnect'
                        : loading
                        ? 'Connecting...'
                        : 'Connect'}
                    </span>
                  }
                  control={
                    <IOSSwitch
                      data-cy={`${o.displayName}-${o.isAddedByOwner ? 'disconnect' : 'connect'}-button`}
                      checked={Boolean(o?.isAddedByOwner)}
                      style={{
                        color: theme.palette.primary.main
                      }}
                      disabled={loading}
                      onClick={() => {
                        if (o._id) {
                          if (o.isAddedByOwner) {
                            setDeletingYTProfile(o._id);
                          } else {
                            setCreatingYTProfile(o._id);
                            createYoutubeProfile({
                              variables: { info: { id: o.id, profileType: SocialChannel.Youtube } }
                            });
                          }
                        }
                      }}
                    />
                  }
                />
                {renderConfirmDeleteModal()}
              </Grid>
            </Grid>
          </Grid>
          <Divider style={{ backgroundColor: '#BBBBBB' }} />
        </div>
      );
    });
  };

  const renderYoutubeVerification = () => {
    return (
      <Grid
        item
        container
        direction='column'
        style={{
          width: '100%',
          display: 'flex'
        }}
      >
        <Grid item container justifyContent='space-between' style={{ marginBottom: 10 }}>
          <Grid item container xs={4} alignItems='center'>
            <Grid item>
              <img src={channelIcons.youtubeWithText} style={{ height: 24 }} alt='youtube logo' />
            </Grid>
          </Grid>
          <Grid item>
            <Grid item xs={12}>
              {renderAuthenticateButton()}
            </Grid>
          </Grid>
        </Grid>
        <Divider style={{ backgroundColor: '#BBBBBB' }} />
        {isEmpty(youtubeProfiles) && (
          <Grid item style={{ marginTop: 10 }}>
            <Typography variant='body1'>
              To link your YouTube account to Kitly Business you'll need to log in with YouTube. This allows us to
              display your insights accurately and update them in real time. We take the privacy of your data very
              seriously -
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  };

  if (isLoading) {
    return (
      <div data-cy='instagram-settings-loader' style={{ width: '100%' }}>
        <CircularProgress />
      </div>
    );
  }
  return (
    <Grid container spacing={0} style={{ margin: 0, padding: 0 }}>
      <Grid item xs={12}>
        {renderYoutubeVerification()}
      </Grid>
      <Grid item xs={12} lg={12}>
        {renderExistingProfiles()}
      </Grid>
    </Grid>
  );
};

export default YoutubeProfile;
