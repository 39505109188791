import React, { useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { makeStyles } from 'tss-react/mui';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import AvatarUpload from 'components/Fields/AvatarUpload';
import ContactSelector from 'components/Fields/ContactSelector';
import Modal from 'components/Modal';
import useTracking from 'context/useTrackingCtx';
import { formatError } from 'lib/formatStrings';
import { isEmpty, get, identity, pickBy } from 'lodash';

import {
  Contact,
  ContactUpdates,
  GetBasicContactListDocument,
  GetCompanyContactListDocument,
  GetContactsDocument,
  useCreateContactMutation,
  useDeleteContactMutation,
  useUpdateContactMutation
} from 'types/generated';
import validate from 'validate.js';
import { Context, Provider } from './index';

interface ContactCreationProps {
  children?: JSX.Element;
  editableContact?: Contact | null;
  selectedParentId?: string;
  defaultInput?: string;
  toggleModal?: (newContactId?: string) => void;
}

interface CreationModalProps {
  editableContact?: Contact | null;
  selectedParentId?: string;
  defaultInput?: string;
  toggleModal: (newContactId?: string) => void;
}

interface TextInputProps {
  name: string;
  title: string;
  defaultInput?: string;
  columnWidth?: any;
  className: any;
  required?: boolean;
  requiredIfFieldExists?: string;
  hideIfFieldExists?: string;
}

interface AddUpdateContactProps {
  editableContact?: Contact | null;
  toggleModal: (newContactId?: string) => void;
}

const constraints = {
  firstName: { presence: { allowEmpty: false } },
  email: { email: true }
};

const parentConstraints = {
  companyName: { presence: { allowEmpty: false } },
  email: { email: true }
};

export const ContactCreation = (props: ContactCreationProps) => {
  const { children } = props;
  const [isOpen, setIsOpen] = useState(children ? false : true);
  const toggleModal = (newContactId?: string) => {
    setIsOpen(!isOpen);
    if (props.toggleModal) {
      props.toggleModal(newContactId);
    }
  };
  return (
    <>
      {isOpen && <CreationModal {...props} defaultInput={props.defaultInput} toggleModal={toggleModal} />}
      {children && <ButtonBase onClick={() => setIsOpen(!isOpen)}>{children}</ButtonBase>}
    </>
  );
};

const CreationModal = (props: CreationModalProps) => {
  const { classes } = useStyles();

  const { toggleModal, editableContact, selectedParentId } = props;
  const { trackEvent } = useTracking();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [deleteContact] = useDeleteContactMutation({
    refetchQueries: [
      { query: GetBasicContactListDocument },
      { query: GetCompanyContactListDocument },
      { query: GetContactsDocument }
    ]
  });

  const toggleDeleteModal = () => setIsDeleteModalOpen(!isDeleteModalOpen);

  const DeleteModal = () => (
    <Modal
      isOpen={isDeleteModalOpen}
      onToggle={toggleDeleteModal}
      onConfirm={() => {
        if (editableContact?._id) {
          deleteContact({ variables: { id: editableContact._id } });
          trackEvent('contact', 'deleted contact');
          toggleDeleteModal();
          toggleModal();
        }
      }}
      maxWidth={'xs'}
      heading={'Delete Contact'}
    >
      <div className={classes.deleteModal}>
        Are you sure you want to delete {editableContact?.firstName} {editableContact?.lastName} (
        {editableContact?.role})?
      </div>
    </Modal>
  );

  return (
    <Modal
      isOpen={true}
      onToggle={toggleModal}
      onConfirm={() => {}}
      hideConfirmationButtons={true}
      maxWidth={'sm'}
      heading={editableContact?._id ? 'Update Contact' : 'New Contact'}
    >
      <Provider>
        <Grid container className={classes.modalContainer}>
          {editableContact?._id && (
            <>
              <ButtonBase onClick={toggleDeleteModal} className={classes.deleteButton}>
                <DeleteIcon className={classes.deleteIcon} />
              </ButtonBase>
              <DeleteModal />
            </>
          )}
          <Grid item xs={12} className={`${classes.input} ${classes.centred}`}>
            <AvatarUpload size={100} />
          </Grid>
          <ContextualContactSelector selectedParentId={selectedParentId} />
          <TextInput
            name={'companyName'}
            defaultInput={props.defaultInput}
            title='Company Name'
            required
            className={classes.input}
            hideIfFieldExists={'parentId'}
          />
          <Grid item container style={{ columnGap: 10 }} wrap='nowrap'>
            <TextInput
              name={'firstName'}
              title={'First Name'}
              requiredIfFieldExists={'parentId'}
              columnWidth={6}
              className={classes.input}
            />
            <TextInput name={'lastName'} title='Last Name' columnWidth={6} className={classes.input} />
          </Grid>
          <TextInput name={'email'} title='Email' required className={classes.input} />
          <TextInput name={'role'} title='Role' className={classes.input} />
          <TextInput name={'phone'} title='Phone' className={classes.input} />
          <Grid container alignItems={'center'} justifyContent={'space-around'} spacing={2}>
            <Grid item>
              <Button onClick={() => props.toggleModal()} variant={'outlined'}>
                Cancel
              </Button>
            </Grid>
            <AddUpdateContact toggleModal={props.toggleModal} editableContact={props.editableContact} />
          </Grid>
        </Grid>
      </Provider>
    </Modal>
  );
};

const ContextualContactSelector = ({ selectedParentId }: { selectedParentId?: string }) => {
  const {
    state: { contactUpdates },
    setContactUpdates
  } = useContext(Context);

  useEffect(() => {
    if (selectedParentId && !contactUpdates.parentId) {
      setContactUpdates({ ...contactUpdates, parentId: selectedParentId });
    }
    // Disabling warning to prevent render loop
    // eslint-disable-next-line
  }, [selectedParentId, setContactUpdates]);

  if (selectedParentId) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <ContactSelector
        selectedId={contactUpdates.parentId}
        setSelectedId={(id) => setContactUpdates({ ...contactUpdates, parentId: id })}
        emptyLabel={'Select a parent brand'}
      />
    </Grid>
  );
};

const TextInput = (props: TextInputProps) => {
  const { name, title, columnWidth, className, requiredIfFieldExists, hideIfFieldExists, required, defaultInput } =
    props;
  const {
    state: { contactUpdates },
    setContactUpdates
  } = useContext(Context);

  const isHidden = hideIfFieldExists ? Boolean(get(contactUpdates, hideIfFieldExists)) : false;

  useEffect(() => {
    if (defaultInput && !contactUpdates[name]) {
      setContactUpdates({ ...contactUpdates, [name]: defaultInput });
    }
    // Disabling warning to prevent render loop
    // eslint-disable-next-line
  }, [defaultInput, name, setContactUpdates]);

  if (isHidden) {
    return null;
  }

  const isRequired =
    typeof required === 'boolean'
      ? required
      : requiredIfFieldExists
      ? Boolean(get(contactUpdates, requiredIfFieldExists))
      : false;

  return (
    <Grid item xs={columnWidth || 12} className={className}>
      <TextField
        variant='standard'
        name={name}
        value={contactUpdates[name] || ''}
        onChange={(e: any) => {
          setContactUpdates({ ...contactUpdates, [e.target.name]: e.target.value });
        }}
        label={`${title}${isRequired ? ' *' : ''}`}
        fullWidth={true}
        inputProps={{ contentEditable: true }}
      />
    </Grid>
  );
};

const AddUpdateContact = (props: AddUpdateContactProps) => {
  const { toggleModal, editableContact } = props;
  const { classes } = useStyles();
  const { trackEvent } = useTracking();

  const {
    state: { avatar, contactUpdates, editingId },
    setAvatar,
    setEditingId,
    setContactUpdates
  } = useContext(Context);

  const [error, setError] = useState<string>('');

  const [createContact, { loading: createLoading }] = useCreateContactMutation({
    refetchQueries: [
      { query: GetBasicContactListDocument },
      { query: GetCompanyContactListDocument },
      { query: GetContactsDocument }
    ]
  });

  const [updateContact, { loading: updateLoading }] = useUpdateContactMutation({
    refetchQueries: [
      { query: GetBasicContactListDocument },
      { query: GetCompanyContactListDocument },
      { query: GetContactsDocument }
    ]
  });

  const loading = createLoading || updateLoading;

  useEffect(() => {
    if (editableContact && !editingId) {
      setEditingId(editableContact._id);
      setAvatar(editableContact?.avatar || null);
      setContactUpdates({
        avatar: editableContact?.avatar || null,
        parentId: editableContact?.parentId || '',
        firstName: editableContact?.firstName || '',
        lastName: editableContact?.lastName || '',
        email: editableContact?.email || '',
        phone: editableContact?.phone || '',
        role: editableContact?.role || ''
      });
    }

    //Disabling warning to prevent render loop
    // eslint-disable-next-line
  }, [editableContact, setContactUpdates, setEditingId, setAvatar, editingId]);

  const validateContact = () => {
    // Payload:
    const updates = {
      ...pickBy(contactUpdates, identity),
      type: contactUpdates.parentId ? 'INDIVIDUAL' : 'COMPANY'
    } as ContactUpdates;
    if (avatar && typeof avatar !== 'string') updates.avatar = avatar;
    // Validate:
    let error = validate(updates, updates.parentId ? constraints : parentConstraints);
    const firstKey = !isEmpty(error) ? Object.keys(error)[0] : null;
    if (firstKey && error[firstKey]) {
      setError(error[firstKey]);
      return null;
    } else {
      setError('');
    }
    // Create/update
    if (editableContact?._id) {
      updateContact({ variables: { id: editableContact?._id, updates } })
        .then(() => {
          trackEvent('contact', 'updated contact');
          toggleModal(editableContact?._id!);
        })
        .catch((error) => {
          const errorMessage = formatError(error) || 'Error updating contact';
          setError(errorMessage);
        });
    } else {
      createContact({ variables: { updates } })
        .then((response) => {
          trackEvent('contact', 'created contact');
          toggleModal(response?.data?.createContact?.insertedId || undefined);
        })
        .catch((error) => {
          const errorMessage = formatError(error) || 'Error creating contact';
          setError(errorMessage);
        });
    }
  };

  return (
    <>
      <Grid item>
        <Button onClick={validateContact} variant='contained'>
          {loading ? <CircularProgress style={{ color: '#FFF' }} /> : editableContact?._id ? 'Update' : 'Add'}
        </Button>
      </Grid>
      <Grid item xs={12}>
        {error && <div className={classes.error}>Error: {error}</div>}
      </Grid>
    </>
  );
};

const useStyles = makeStyles()((theme) => ({
  centred: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  modalContainer: {
    position: 'relative',
    overflowX: 'hidden',
    paddingRight: 10
  },
  deleteButton: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  deleteIcon: {
    // @ts-ignore
    color: theme.palette.common.blueGray,
    fontSize: 24
  },
  deleteModal: {
    margin: '15px 0'
  },
  input: {
    margin: '15px 0'
  },
  inputTitle: {
    fontSize: 12,
    fontWeight: 500
  },
  statusButton: {
    minWidth: 110,
    margin: 5,
    fontSize: 12,
    color: '#333333'
  },
  inactive: {
    backgroundColor: '#F3F1F1'
  },
  active: {
    border: 'solid 0.5px #979797'
  },
  activeIndicator: {
    height: 6,
    width: 6,
    borderRadius: '100%',
    backgroundColor: '#01D311',
    marginRight: 5
  },
  error: {
    color: theme.palette.error.main,
    fontSize: 12,
    marginTop: 10,
    textAlign: 'center'
  }
}));
