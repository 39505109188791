import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgress, Grid, Typography, Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import FakeButton from 'components/FakeButton';
import { useUserConfig } from 'hooks/useUserConfig';
import { WALKTHROUGHS } from 'types/custom';
import { useGetCollaborationListQuery, CollaborationStatus } from 'types/generated';
import { CollabCreation } from './CollabCreation';
import { Walkthrough } from './Walkthrough';
import CollabsManagement from './CollabsManagement';
import SimpleTabs from '../../settings/SimpleTabs';
import { InvoiceShareProvider } from 'context/InvoiceShareContext';
import ContentfulAdBanner from 'components/Dashboard/ContentfulAdBanner';

export const CollabsPage = () => {
  const { classes } = useStyles();
  const params = useParams<{ activeTab?: string }>();
  const activeTab = params?.activeTab || 'collablist';

  const { config, loading: userLoading } = useUserConfig();
  const { loading, data } = useGetCollaborationListQuery();
  const collaborationList = useMemo(() => data?.getCollaborationList || [], [data]);

  const isLoading = userLoading || loading;

  const shouldShowWalkthrough = useMemo(() => {
    const hasWalkedCollaborations = config?.walkthrough?.includes(WALKTHROUGHS.COLLABORATIONS);

    if (collaborationList?.length) {
      return false;
    }

    if (loading || userLoading) {
      return false;
    }

    return !hasWalkedCollaborations;
  }, [collaborationList, loading, userLoading, config]);

  const Loader = () => (
    <Grid item xs={12} className={`${classes.centred} ${classes.loaderContainer}`}>
      <CircularProgress />
    </Grid>
  );

  return (
    <InvoiceShareProvider>
      <Box px={5} maxWidth={971} style={{ width: '100%' }}>
        {Boolean(isLoading && !data) ? (
          <Loader />
        ) : shouldShowWalkthrough ? (
          <Walkthrough />
        ) : (
          <Grid container spacing={6}>
            <Grid item xs={12} className={classes.header} direction='column' container>
              <Typography variant='subtitle1' className={classes.subtitle}>
                Manage
              </Typography>
              <Grid container justifyContent='space-between'>
                <Grid item>
                  <Typography variant='h2'>Campaigns</Typography>
                </Grid>
                {activeTab === 'collablist' && (
                  <Grid item justifyContent='flex-end'>
                    <CollabCreation>
                      <FakeButton variant='contained' className={classes.button}>
                        New Campaign
                      </FakeButton>
                    </CollabCreation>
                  </Grid>
                )}
                <Grid item container style={{ marginTop: 25 }}>
                  <ContentfulAdBanner adLocation='collabs' />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.header} direction='column' container>
              <SimpleTabs
                tabContent={[
                  {
                    label: 'All',
                    param: 'collablist',
                    content: <CollabsManagement />
                  },
                  {
                    label: 'Draft',
                    param: 'draftlist',
                    content: <CollabsManagement status={CollaborationStatus.Draft} />
                  },
                  {
                    label: 'Active',
                    param: 'activelist',
                    content: <CollabsManagement status={CollaborationStatus.Active} />
                  },
                  {
                    label: 'Archive',
                    param: 'archivedlist',
                    content: <CollabsManagement status={CollaborationStatus.Archived} />
                  }
                ]}
                scrollButtons='auto'
                variant='scrollable'
                availableParam={activeTab}
                page={'collabs'}
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </InvoiceShareProvider>
  );
};

const useStyles = makeStyles()(() => {
  return {
    subtitle: {
      fontWeight: 500,
      marginBottom: 12
    },
    centred: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    loaderContainer: {
      height: '85vh'
    },
    header: {
      marginBottom: 1
    },
    marketingBox: {
      padding: 15,
      marginBottom: 15,
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      boxShadow: '0 1px 2px 0 rgba(211,211,211,0.5)'
    },
    marketingHeader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      marginBottom: 10
    },
    marketingTitle: {
      fontWeight: 500,
      marginLeft: 15,
      fontSize: 14
    },
    description: {
      lineHeight: '18px'
    },
    button: {
      margin: 5
    },
    stripeContainer: {
      backgroundColor: '#33333325',
      borderRadius: 4,
      paddingTop: 14,
      paddingBottom: 20,
      paddingLeft: 23,
      paddingRight: 35
    },
    stripeTextContainer: {
      maxWidth: 600
    },
    bodyText: {
      color: '#333',
      fontWeight: 500,
      fontSize: 12,
      marginBottom: 11
    },
    stripeLogo: {
      width: 82,
      height: 34
    }
  };
});
