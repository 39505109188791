const LogoutIcon = ({ stroke }: { stroke?: string }) => {
  return (
    // <?xml version="1.0" encoding="UTF-8"?>
    <svg width='16px' height='15px' viewBox='0 0 16 15' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <title>4F93EA0C-DFC5-4C11-86B0-53AF4F67D6B8@1x</title>
      <g
        id='-'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <g
          id='Kitlfy-for-Business-Style-Guide_2'
          transform='translate(-971.000000, -468.000000)'
          stroke={stroke || '#5C5CB9'}
        >
          <g id='Icon/16px/logout' transform='translate(971.000000, 468.000000)'>
            <g id='log-in'>
              <path
                d='M5.5,4.5 L5.5,1.5 C5.5,0.948 5.948,0.5 6.5,0.5 L14.5,0.5 C15.052,0.5 15.5,0.948 15.5,1.5 L15.5,13.5 C15.5,14.052 15.052,14.5 14.5,14.5 L6.5,14.5 C5.948,14.5 5.5,14.052 5.5,13.5 L5.5,10.5'
                id='Path'
              ></path>
              <line x1='0.5' y1='7.5' x2='11.5' y2='7.5' id='Path'></line>
              <polyline id='Path' points='8.5 4.5 11.5 7.5 8.5 10.5'></polyline>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default LogoutIcon;
