import React, { useState } from 'react';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import moment from 'moment';

// date picker component
interface IReportDatePicker {
  payload: any;
  setPayload: any;
}
const ReportDatePicker: React.FC<IReportDatePicker> = (props) => {
  const { payload, setPayload } = props;
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(null);

  return (
    <DateRangePicker
      startDate={payload.report_start ? moment(payload.report_start) : null}
      endDate={payload.report_end ? moment(payload.report_end) : null}
      startDateId='report_start'
      startDatePlaceholderText={'From DD/MM/YYYY'}
      endDateId='report_end'
      endDatePlaceholderText={'To DD/MM/YYYY'}
      onDatesChange={(event) => {
        setPayload(Object.assign({}, payload, { report_start: event.startDate }, { report_end: event.endDate }));
      }}
      focusedInput={focusedInput}
      onFocusChange={setFocusedInput}
      numberOfMonths={1}
      monthFormat={'MMM YYYY'}
      displayFormat={'DD/MM/YYYY'}
      noBorder={true}
      daySize={25}
      block={true}
      small={true}
      isOutsideRange={() => false}
      hideKeyboardShortcutsPanel={true}
    />
  );
};

export default ReportDatePicker;
