import { useState, useEffect } from 'react';

import { useTheme, Grid, Button, ButtonBase, Dialog } from '@mui/material';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LockIcon from '@mui/icons-material/Lock';

import PdfButtons from '../../Fields/PdfButtons';
import ShareFields, { SharePayload } from 'components/Fields/ShareFields';

import { useUpdateUserProgressMutation, useShareAparLinkToContactMutation } from 'types/generated';
import useTracking from 'context/useTrackingCtx';
import { useErrorHandlerHook } from 'hooks/useErrorCatchHook';
import { useUserConfig } from 'hooks/useUserConfig';
import Modal from 'components/Modal';
import SubscribePopup from 'components/subscribe/SubscribePopup';
import useSubscribePopupCtx from '../../../context/useSubscribePopupCtx';

interface ComponentProps {
  aparLink?: string;
  profileId?: string;
  onComplete?: () => void;
  isModalOnly?: boolean;
  children?: any;
}

const ShareAuditReportModal = ({ aparLink, profileId, onComplete, isModalOnly, children }: ComponentProps) => {
  const theme = useTheme() as any;
  const { apolloHandler } = useErrorHandlerHook();
  const { openSubscribePopup } = useSubscribePopupCtx();

  const { trackEvent } = useTracking();

  // Modal states:
  const [shareAparOpen, setShareAparOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [isSubscribePopupOpen, setIsSubscribePopupOpen] = useState(false);

  const [updateAccountProgress] = useUpdateUserProgressMutation();

  const [shareAparLinkToContact, { loading: shareLoading, error: shareAparError }] =
    useShareAparLinkToContactMutation();

  const { config: userConfig, user } = useUserConfig();

  const primaryColor = theme?.palette?.primary?.main;

  const mediaKitLocked = Boolean(userConfig.media_kit_disable === true);

  useEffect(() => {
    if (shareAparError) {
      apolloHandler(shareAparError);
    }
  }, [shareAparError, apolloHandler]);

  const handleToggleShareApar = (open?: boolean) => {
    const isOpen = typeof open === 'boolean' ? open : !shareAparOpen;
    setShareAparOpen(isOpen);
  };

  const handleToggleSuccess = (open?: boolean) => {
    const isOpen = typeof open === 'boolean' ? open : !successOpen;
    setSuccessOpen(isOpen);
  };

  const handleShare = (payload: SharePayload) => {
    if (!aparLink) return;
    // Submit payload:
    shareAparLinkToContact({ variables: { aparLink, profileId, ...payload } }).then(() => {
      trackEvent('APAR', 'shared APAR');
      if (onComplete) onComplete();
      handleToggleSuccess(true);
      handleToggleShareApar(false);
      const progressRecorded = user?.accountProgress?.includes('shareAPAR');
      if (!progressRecorded) updateAccountProgress({ variables: { steps: ['shareAPAR'] } });
    });
  };

  const renderSuccessModal = () => {
    let color = theme?.palette?.primary?.dark;
    return (
      <Dialog open={true} onClose={() => handleToggleSuccess(false)}>
        <Grid container direction='column' alignItems='center' style={{ padding: 15 }}>
          <Grid item>
            <CheckCircleOutlineIcon style={{ fontSize: '36px', color, margin: '15px' }} />
          </Grid>
          <Grid item>
            <span style={{ marginBottom: '5px' }}>Successfully shared your APAR</span>
          </Grid>
          <Grid item>
            <Button onClick={() => handleToggleSuccess(false)} color='primary'>
              Okay
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    );
  };

  const renderHeader = () => {
    return (
      <div style={{ position: 'relative', width: '100%' }}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          Share your APAR
        </div>
        <ButtonBase onClick={() => handleToggleShareApar()} style={{ position: 'absolute', top: 0, right: 0 }}>
          <CloseRoundedIcon style={{ fontSize: 22 }} />
        </ButtonBase>
      </div>
    );
  };

  const renderShareAparLinkModal = () => {
    if (isSubscribePopupOpen === true) {
      return (
        <SubscribePopup
          closeSubscribePopup={() => setIsSubscribePopupOpen(false)}
          selectedPlanId={userConfig.limit_media_kit_shares_planId}
        />
      );
    }

    return (
      <Modal
        isOpen={true}
        onToggle={handleToggleShareApar}
        hideConfirmButton={true}
        hideCancelButton={Boolean(aparLink)}
        subConfirmationContent={<PdfButtons aparLink={aparLink} />}
        heading={renderHeader()}
        maxWidth={'sm'}
      >
        {!aparLink ? (
          <div style={{ display: 'flex', justifyItems: 'center', flexDirection: 'column', minWidth: 413 }}>
            No APAR Link selected
          </div>
        ) : (
          <div style={{ display: 'flex', justifyItems: 'center', flexDirection: 'column', minWidth: 413 }}>
            <ShareFields
              onShare={handleShare}
              onDismiss={handleToggleShareApar}
              loading={shareLoading}
              sendNewContactRequest={true}
            />
          </div>
        )}
      </Modal>
    );
  };

  return (
    <>
      {shareAparOpen && renderShareAparLinkModal()}
      {successOpen && renderSuccessModal()}
      <div
        onClick={() => {
          mediaKitLocked && openSubscribePopup ? openSubscribePopup() : handleToggleShareApar(true);
        }}
      >
        {isModalOnly ? (
          <div />
        ) : children ? (
          children
        ) : (
          <Button
            style={{ backgroundColor: primaryColor, color: '#FFFFFF', fontSize: 12, minHeight: '42px' }}
            size='medium'
            color='primary'
            variant='contained'
            fullWidth={true}
          >
            {mediaKitLocked && <LockIcon style={{ marginRight: '7px' }} />}
            Share APAR
          </Button>
        )}
      </div>
    </>
  );
};

// @ts-ignore withTheme() conflicting with connect()
export default ShareAuditReportModal;
