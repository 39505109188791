import { useMemo, useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Box, FormControl, FormHelperText, TextField, Grid, Avatar, Typography, Autocomplete } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import { useBrandRequest } from 'hooks/useBrandRequest';
import { Contact } from '../../types/generated';
import { Loader } from 'components/Loader';
import { filter, find, compact, sortBy } from 'lodash';
import { CustomChip } from './CustomChip';
import { formatError } from '../../lib/formatStrings';
import { ContactCreation } from '../../components/pages/BrandsPage/ContactCreation';

const backupUserImg = 'https://media.q-83.com/site/user.png';

interface IContactSelector {
  emptyLabel?: string;
  displayEmail?: boolean;
  parentContactId?: string;
  selectedId?: string;
  setSelectedId?: (selectedId: string | undefined) => void;
  selectedIds?: string[];
  setSelectedIds?: (selectedIds: string[]) => void;
  errorMessage?: string;
  hideIfOneOption?: boolean;
  disabled?: boolean;
}

const ContactSelector = ({
  parentContactId,
  displayEmail,
  emptyLabel,
  selectedId,
  setSelectedId,
  selectedIds,
  setSelectedIds,
  errorMessage,
  hideIfOneOption,
  disabled
}: IContactSelector) => {
  const { classes: autocompleteErrorClasses } = autocompleteErrorStyles();
  const { classes: textErrorClasses } = textErrorStyles();

  const { allContacts, error, loading, refetch } = useBrandRequest();
  const [newContactOpen, setNewContactOpen] = useState(false);
  const [searchInput, setSearchInput] = useState('');

  const contacts: Array<Contact> = useMemo(() => {
    if (allContacts) {
      return sortBy(
        filter(allContacts, (o) => {
          if (displayEmail && !o?.email) {
            return false;
          }

          if (parentContactId) {
            if (o?.parentId !== parentContactId && o?._id !== parentContactId) {
              return false;
            }
            return true;
          }

          if (o?.parentId && !displayEmail) {
            return false;
          }

          return true;
        }),
        'type'
      );
    }

    return [];
  }, [allContacts, parentContactId, displayEmail]);

  useEffect(() => {
    if (hideIfOneOption && contacts.length && contacts[0]._id) {
      if (setSelectedIds) {
        setSelectedIds([contacts[0]._id]);
      } else if (setSelectedId) {
        setSelectedId(contacts[0]._id);
      }
    }
  }, [contacts, hideIfOneOption, setSelectedId, setSelectedIds]);

  const contactOptions: Array<Contact | 'add-new'> = useMemo(() => {
    if (contacts.length) {
      return ['add-new', ...contacts];
    }
    if (selectedId) {
      return [];
    }
    return ['add-new'];
  }, [contacts, selectedId]);

  const selectedOptions = useMemo(() => {
    if (selectedIds) {
      return filter(contactOptions, (o) => (o !== 'add-new' && o._id ? selectedIds.includes(o._id) : false));
    }
    if (selectedId) {
      const profile = find(contactOptions, { _id: selectedId });
      if (profile) {
        return [profile];
      }
    }
    return [];
  }, [contactOptions, selectedIds, selectedId]);

  if (newContactOpen) {
    return (
      <ContactCreation
        selectedParentId={parentContactId}
        defaultInput={searchInput}
        toggleModal={(newContactId) => {
          refetch();
          if (newContactId) {
            if (setSelectedId) {
              setSelectedId(newContactId);
            } else if (setSelectedIds && selectedIds) {
              setSelectedIds([...selectedIds, newContactId]);
            }
          }
          setNewContactOpen(false);
        }}
      />
    );
  }

  if (hideIfOneOption && contacts.length === 1) {
    return null;
  }

  const shouldShowError = Boolean(errorMessage);

  const emptyLabelToDisplay = errorMessage || emptyLabel || 'Select contact';

  const defaultLabel = displayEmail && !contacts.length ? 'No contacts with an email address' : '';

  return (
    <Box width='100%' mb='10px'>
      {loading && (
        <Box textAlign='center' width='100%'>
          <Loader />
        </Box>
      )}
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <FormControl variant='standard' fullWidth={true} margin='dense'>
          {setSelectedIds ? (
            <Autocomplete
              disabled={disabled}
              filterSelectedOptions
              data-cy='contact-select'
              loading={loading}
              value={selectedOptions || []}
              multiple
              fullWidth
              classes={shouldShowError ? autocompleteErrorClasses : undefined}
              disableClearable
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={emptyLabelToDisplay}
                  variant='standard'
                  InputLabelProps={{ classes: shouldShowError ? textErrorClasses : undefined }}
                />
              )}
              popupIcon={<ExpandMoreIcon style={{ fontSize: 20 }} />}
              renderTags={renderTags}
              options={[defaultLabel, ...contactOptions]}
              onChange={(_, values) => {
                if (values?.length && values[values.length - 1] === 'add-new') {
                  setNewContactOpen(true);
                } else if (setSelectedIds) {
                  setSelectedIds(compact(values.map((e) => (typeof e !== 'string' ? e?._id : undefined))));
                }
              }}
              filterOptions={(options, state) => {
                setSearchInput(state.inputValue);
                return filter(options, (o) =>
                  o === 'add-new'
                    ? true
                    : state.getOptionLabel(o).toLowerCase().includes(state.inputValue.toLowerCase())
                );
              }}
              getOptionLabel={(option) => getLabelForContact(option)}
              renderOption={(props, o, state) => (
                <li {...props}>
                  {o === 'add-new' ? (
                    <CreateOption inputValue={state.inputValue} />
                  ) : typeof o !== 'string' ? (
                    <ContactOption contact={o} displayEmail={displayEmail} />
                  ) : (
                    <div>{o}</div>
                  )}
                </li>
              )}
            />
          ) : (
            <Autocomplete
              disabled={disabled}
              data-cy='contact-select'
              loading={loading}
              value={selectedOptions[0] || ''}
              fullWidth
              classes={shouldShowError ? autocompleteErrorClasses : undefined}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={emptyLabelToDisplay}
                  variant='standard'
                  InputLabelProps={{ classes: shouldShowError ? textErrorClasses : undefined }}
                />
              )}
              popupIcon={<ExpandMoreIcon style={{ fontSize: 20 }} />}
              renderTags={renderTags}
              options={[defaultLabel, ...contactOptions]}
              onChange={(_, value) => {
                if (value === 'add-new') {
                  setNewContactOpen(true);
                } else if (setSelectedId) {
                  if (typeof value === 'string') {
                    setSelectedId(value);
                  } else {
                    setSelectedId(value?._id ? value._id : undefined);
                  }
                }
              }}
              filterOptions={(options, state) => {
                setSearchInput(state.inputValue);
                return filter(options, (o) =>
                  o === 'add-new'
                    ? true
                    : state.getOptionLabel(o).toLowerCase().includes(state.inputValue.toLowerCase())
                );
              }}
              getOptionLabel={(option) => getLabelForContact(option)}
              renderOption={(props, o, state) => (
                <li {...props}>
                  {o === 'add-new' ? (
                    <CreateOption inputValue={state.inputValue} />
                  ) : typeof o !== 'string' ? (
                    <ContactOption contact={o} displayEmail={displayEmail} />
                  ) : (
                    <div>{o}</div>
                  )}
                </li>
              )}
            />
          )}
          {error && (
            <Box>
              <FormHelperText style={{ color: 'red' }}>{formatError(error)}</FormHelperText>
            </Box>
          )}
        </FormControl>
      </Box>
    </Box>
  );
};

const renderTags = (tagValue, getTagProps) => {
  return tagValue.map((o, i) => (
    <CustomChip {...getTagProps({ index: i })} label={getLabelForContact(o)} color='primary' variant='default' />
  ));
};

export const getLabelForContact = (contact?: Contact | string) => {
  if (typeof contact === 'string') {
    return '';
  }
  if (contact) {
    const name = contact.companyName
      ? `${contact.companyName}`
      : `${contact.firstName || ''} ${contact.lastName || ''}`;

    if (name.trim()) {
      return name;
    }
  }
  return 'Unnamed contact';
};

export default ContactSelector;

const CreateOption = ({ inputValue }: { inputValue?: string }) => {
  return (
    <Grid container alignItems={'center'} justifyContent={'flex-start'} spacing={1} style={{ padding: 5 }}>
      <Grid item style={{ width: 20, height: 20, marginRight: 5 }}>
        <AddIcon color='primary' fontSize={'large'} style={{ marginTop: -8, marginLeft: -8 }} />
      </Grid>
      <Grid item style={{ marginLeft: 10 }}>
        <Typography>Add New: </Typography>
      </Grid>
      <Grid item>
        <Typography style={{ fontWeight: 'bold' }}>{inputValue}</Typography>
      </Grid>
    </Grid>
  );
};

const ContactOption = ({ contact, displayEmail }: { contact: Contact; displayEmail?: boolean }) => {
  const [displayImage, setDisplayImage] = useState(contact?.avatar || contact?.parent?.avatar || backupUserImg);

  return (
    <Grid container alignItems={'center'} justifyContent={'space-between'}>
      <Avatar
        src={displayImage}
        onError={(e) => {
          e.persist();
          if (displayImage !== backupUserImg) {
            setDisplayImage(backupUserImg);
          }
        }}
        style={{ width: 20, height: 20, margin: '0 5px' }}
      />
      <Grid item style={{ marginLeft: 10, flexGrow: 1 }}>
        {`${getLabelForContact(contact)}`}
      </Grid>
    </Grid>
  );
};

const autocompleteErrorStyles = makeStyles()(() => ({
  inputRoot: {
    color: 'red',
    '&::before': {
      borderBottomColor: 'red !important'
    }
  }
}));

const textErrorStyles = makeStyles()(() => ({
  root: {
    color: 'red'
  }
}));
