import React, { useMemo } from 'react';
import HorizontalRatioChart from 'components/Charts/HorizontalRationChart';
import { formatMinutes, formatNumber, formatPercentage, getPercentage } from 'lib/formatNumber';

interface IPerformanceBarChart {
  data: any;
  gradient?: string;
  borderRadius?: number;
  ratioChartHeight?: number | string;
}

const PerformanceBarChart = ({ data, gradient, borderRadius, ratioChartHeight }: IPerformanceBarChart) => {
  const largestValue = useMemo(() => {
    if (!data) return 0;
    const maxList = data.map((item: any) => {
      return item.value;
    });
    return Math.max(...maxList);
  }, [data]);

  const contentChartElements = Object.keys(data).map((key) => {
    const { name, value, icon, displayType, percentageValue } = data[key];
    const background = 'linear-gradient(45deg, #FBCDDF, #C5D5E2)';

    return {
      icon,
      name,
      value,
      showTooltip: false,
      style: {
        background,
        borderRadius: borderRadius !== undefined ? borderRadius : '2em',
        padding: ratioChartHeight || '8px 0',
        transition: 'width 2s',
        width: `${getPercentage(value, largestValue)}%`
      },
      displayType,
      percentageValue
    };
  });

  return (
    <>
      {contentChartElements.map((item) => {
        const dataList = [item];
        const label = (
          <div>
            {item.icon && <img src={item.icon} alt={item.name} style={{ marginRight: 6 }} />}
            {`${item.name} - ${item.displayType === 'time' ? formatMinutes(item.value) : formatNumber(item.value)}${
              item.percentageValue ? ` (${formatPercentage(item.percentageValue)}%)` : ''
            }`}
          </div>
        );

        return <HorizontalRatioChart key={item.name} leftLabel={label} dataList={dataList} />;
      })}
    </>
  );
};

export default PerformanceBarChart;
