import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material';
import {
  useGetMyUserQuery,
  useGetTiktokProfilesQuery,
  useConnectTiktokProfileMutation,
  useDisconnectTiktokProfileMutation,
  Profile
} from '../../types/generated';

import {
  Grid,
  Button,
  Paper,
  Typography,
  Avatar,
  CircularProgress,
  Divider,
  FormControlLabel,
  lighten
} from '@mui/material';

import Modal from 'components/Modal';
import { channelIcons } from 'lib/constants';
import { useMobileView } from '../../hooks/useMobileView';
import { useErrorHandlerHook } from 'hooks/useErrorCatchHook';
import { IOSSwitch } from 'components/Fields/IOSSwitch';
import { isEmpty, compact } from 'lodash';
import AppConfig from '../../config/appConfig';

const fallbackProfileImg = 'https://media.q-83.com/site/user.png';

const TiktokProfileManagement = () => {
  const theme = useTheme();

  const { data: userData, loading: userLoading, error: userError } = useGetMyUserQuery();

  const {
    data: profilesData,
    loading: profilesLoading,
    error: profilesError,
    refetch: profilesRefetch
  } = useGetTiktokProfilesQuery();

  const [creatingTTProfile, setCreatingTTProfile] = useState<string | undefined>();

  const [connectTiktokProfile, { loading: createLoading, error: createError }] = useConnectTiktokProfileMutation({
    onCompleted: () => {
      setCreatingTTProfile(undefined);
      profilesRefetch();
    }
  });

  const [disconnectingTTProfile, setDisconnectingTTProfile] = useState<string | undefined>();

  const [disconnectTiktokProfile, { loading: deleteLoading, error: deleteError }] = useDisconnectTiktokProfileMutation({
    onCompleted: () => {
      setDisconnectingTTProfile(undefined);
      profilesRefetch();
    }
  });

  const { apolloHandler } = useErrorHandlerHook();

  useEffect(() => {
    if (createError) {
      apolloHandler(createError, `Unable to create TikTok profile`);
    }
  }, [createError, apolloHandler]);

  useEffect(() => {
    if (deleteError) {
      apolloHandler(deleteError, `Unable to disconnect TikTok profile`);
    }
  }, [deleteError, apolloHandler]);

  useEffect(() => {
    if (profilesError) {
      apolloHandler(profilesError, `Unable to get social profiles`);
    }
  }, [profilesError, apolloHandler]);

  useEffect(() => {
    if (userError) {
      apolloHandler(userError, `Unable to get user info`);
    }
  }, [userError, apolloHandler]);

  const [mobileView] = useMobileView();

  const isLoading = deleteLoading || userLoading || profilesLoading;

  const user = userData?.myUser;
  const tiktokAuthUrl = `${AppConfig.API_URL}tiktok/oauth?userId=${user?._id}&host=${window.location.host}`;

  const tiktokProfiles: Profile[] = compact(profilesData?.getTiktokProfiles) as Profile[];
  const noTiktokProfiles = Boolean(!profilesLoading && tiktokProfiles && isEmpty(tiktokProfiles));

  const handleDisconnectProfile = (profileID: string) => {
    if (profileID === disconnectingTTProfile && user) {
      disconnectTiktokProfile({ variables: { profileId: profileID } });
    }
  };

  const renderConfirmDeleteModal = () => {
    if (!disconnectingTTProfile) return null;
    return (
      <Modal
        isOpen={true}
        onToggle={() => {
          setDisconnectingTTProfile(undefined);
        }}
        onConfirm={() => {
          handleDisconnectProfile(disconnectingTTProfile);
        }}
        confirmLabel={'Disconnect'}
        heading={'Are you sure?'}
        fullScreen={mobileView}
        maxWidth={'sm'}
      >
        <div>Are you sure you want to disconnect this profile?</div>
      </Modal>
    );
  };

  const renderExistingProfiles = () => {
    if (noTiktokProfiles) {
      return (
        <Paper
          key='blank'
          style={{
            marginTop: 8,
            marginBottom: 8,
            padding: 8,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div style={{ fontSize: 14, margin: '7px' }}>No TikTok profiles available.</div>
        </Paper>
      );
    }

    return tiktokProfiles?.map((o, i) => {
      if (!o) return null;
      let profileImage = o?.profile_picture_url || fallbackProfileImg;
      const loading =
        Boolean(deleteLoading && disconnectingTTProfile === o._id) ||
        Boolean(createLoading && creatingTTProfile === o._id);
      return (
        <div key={i}>
          <Grid
            container
            key={i}
            alignItems='center'
            style={{ margin: 0, padding: `${theme.spacing(3)} 0px`, justifyContent: 'space-between' }}
          >
            <Grid container spacing={0} style={{ margin: 0, padding: 0 }}>
              <Grid item xs={6} sm={10}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: '5px'
                  }}
                >
                  <Avatar
                    src={profileImage}
                    style={{ width: 30, height: 30 }}
                    onError={(e) => {
                      // tslint:disable-line
                      e.persist();

                      const imageElement = e.target as HTMLImageElement;
                      if (imageElement) {
                        imageElement.src = fallbackProfileImg;
                      }
                    }}
                  />
                  <div style={{ marginLeft: 4 }}>
                    <div>{o.displayName}</div>
                    <div style={{ fontSize: 10 }}>TikTok</div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={3} sm={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <FormControlLabel
                  label={
                    <span style={{ color: o.isAddedByOwner ? undefined : lighten(theme.palette.common.black, 0.6) }}>
                      {o.isAddedByOwner
                        ? loading
                          ? 'Disconnecting...'
                          : 'Disconnect'
                        : loading
                        ? 'Connecting...'
                        : 'Connect'}
                    </span>
                  }
                  control={
                    <IOSSwitch
                      data-cy={`${o.displayName}-${o.isAddedByOwner ? 'disconnect' : 'connect'}-button`}
                      checked={Boolean(o?.isAddedByOwner)}
                      style={{
                        color: theme.palette.primary.main
                      }}
                      disabled={loading}
                      onClick={() => {
                        if (o._id) {
                          if (o.isAddedByOwner) {
                            setDisconnectingTTProfile(o._id);
                          } else {
                            setCreatingTTProfile(o._id);
                            connectTiktokProfile({ variables: { profileId: o._id } });
                          }
                        }
                      }}
                    />
                  }
                />
                {renderConfirmDeleteModal()}
              </Grid>
            </Grid>
          </Grid>
          <Divider style={{ backgroundColor: '#BBBBBB' }} />
        </div>
      );
    });
  };

  if (isLoading) {
    return (
      <div data-cy='instagram-settings-loader' style={{ width: '100%' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Grid container spacing={0} style={{ margin: 0, padding: 0 }}>
      <Grid
        item
        container
        xs={12}
        direction='column'
        style={{
          width: '100%',
          display: 'flex'
        }}
      >
        <Grid item container justifyContent='space-between' style={{ marginBottom: 10 }}>
          <Grid item container xs={4} alignItems='center'>
            <Grid item>
              <img src={channelIcons.tiktokWithText} style={{ height: 24 }} alt='TikTok logo' />
            </Grid>
          </Grid>
          <Grid item>
            <Grid item xs={12}>
              <Button variant='outlined' href={tiktokAuthUrl}>
                <Grid container justifyContent='center' alignItems='center'>
                  <Grid item>{`Connect with `}</Grid>
                  <Grid item container justifyContent='center' style={{ width: 'auto' }}>
                    <img src={channelIcons.tiktok} style={{ height: 16, marginLeft: 8 }} alt='TikTok icon' />
                  </Grid>
                </Grid>
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Divider style={{ backgroundColor: '#BBBBBB' }} />
        {isEmpty(tiktokProfiles) && (
          <Grid item style={{ marginTop: 10 }}>
            <Typography variant='body1'>
              To link your TikTok account to Kitly you'll need to enter your TikTok username. This allows us to display
              your insights accurately and update them in real time. We take the privacy of your data very seriously.
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} lg={12}>
        {renderExistingProfiles()}
      </Grid>
    </Grid>
  );
};

export default TiktokProfileManagement;
