import React, { useCallback } from 'react';
import { Grid, ButtonBase } from '@mui/material';
import LinkIcon from '../assets/icons/LinkIcon';
import { useSnackbar } from 'notistack';
import { SNACK_BAR_MESSAGES } from 'lib/snackbarMessages';
import useCopyText from 'hooks/useCopyText';

const CopyableText = ({
  children,
  text,
  message,
  label
}: {
  children?: React.ReactNode | null;
  text?: string | null;
  message?: string;
  label?: string | null;
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const copyText = useCopyText();

  const copyLink = useCallback(() => {
    copyText(text, (error) => {
      if (error) {
        enqueueSnackbar(error);
      } else {
        enqueueSnackbar(message || SNACK_BAR_MESSAGES.BITLY_LINK_COPIED);
      }
    });
  }, [text, enqueueSnackbar, copyText, message]);

  return (
    <Grid container>
      {label && <Grid item>{label}</Grid>}
      <Grid item style={{ overflow: 'hidden', position: 'relative', width: 21 }}>
        <ButtonBase
          style={{ marginLeft: 5 }}
          onClick={() => {
            copyLink();
          }}
        >
          {children ? children : <LinkIcon />}
        </ButtonBase>
      </Grid>
    </Grid>
  );
};

export default CopyableText;
