import React, { CSSProperties } from 'react';

const RoundTick = ({ fill, outline, style }: { fill?: string; outline?: boolean; style?: CSSProperties }) => {
  if (outline) {
    return (
      <svg
        width='24px'
        height='24px'
        viewBox='0 0 24 24'
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
      >
        <g
          id='5.-V3---Collabs---Uploaded'
          stroke='none'
          strokeWidth='1'
          fill='none'
          fillRule='evenodd'
          strokeLinecap='round'
          strokeLinejoin='round'
        >
          <g
            id='5.1-New-Collab_collab-Details_Add-New-Brand'
            transform='translate(-567.000000, -320.000000)'
            stroke={fill || '#D8D8D8'}
          >
            <g id='c-check' transform='translate(567.000000, 320.000000)'>
              <circle id='Oval' cx='12' cy='12' r='11.25'></circle>
              <polyline id='Path' points='6.75 11.25 10.5 15 18 7.5'></polyline>
            </g>
          </g>
        </g>
      </svg>
    );
  }

  return (
    <svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <defs>
        <path
          d='M10.0000556,16.499875 C9.82939363,16.499875 9.6587317,16.4395685 9.52873531,16.3195708 L6.19549457,13.2427069 C5.93483514,13.0020961 5.93483514,12.6131805 6.19549457,12.3725698 C6.456154,12.131959 6.87747563,12.131959 7.13813505,12.3725698 L10.0000556,15.0143651 L16.8618649,8.68033307 C17.1225244,8.43972231 17.543846,8.43972231 17.8045054,8.68033307 C18.0651649,8.92094383 18.0651649,9.30985942 17.8045054,9.55047018 L10.4713758,16.3195708 C10.3413794,16.4395685 10.1707175,16.499875 10.0000556,16.499875'
          id='path-1'
        ></path>
      </defs>
      <g id='---' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Kitlfy-for-Business-Style-Guide_2' transform='translate(-655.000000, -669.000000)'>
          <g id='Group' transform='translate(655.000000, 669.000000)'>
            <circle id='Oval' fill={fill || '#D8D8D8'} cx='12' cy='12' r='12'></circle>
            <mask id='mask-2' fill='white'>
              <use xlinkHref='#path-1'></use>
            </mask>
            <use id='Icon-Checkmark' fill='#FFFFFF' xlinkHref='#path-1'></use>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default RoundTick;
