import { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AppConfig from 'config/appConfig';
import { MediaKitConfig } from 'types/generated';
import Modal from 'components/Modal';
import { useMobileView } from '../../../hooks/useMobileView';

type Props = {
  anchorRef: HTMLElement | null;
  handleEdit?: (id: string) => void;
  handleRename?: () => void;
  handleDelete?: (id: string) => void;
  handleClose: () => void;
  mediaKitConfig: MediaKitConfig;
  isDraft: boolean;
};

const MediaKitOptionsMenu = (props: Props) => {
  const { anchorRef, handleClose, mediaKitConfig, isDraft } = props;
  const [mobileView] = useMobileView();
  const id = mediaKitConfig._id;

  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState<boolean>(false);

  if (!id) {
    return null;
  }

  const handleView = () => {
    window.open(`${AppConfig.MEDIAKIT_URL}/#/view/${id}`, '_blank');
    handleClose();
  };

  const handlePreview = () => {
    window.open(`${AppConfig.MEDIAKIT_URL}/#/preview/${id}`, '_blank');
    handleClose();
  };

  const handleEdit = (): void => {
    if (props.handleEdit) props.handleEdit(id);
    handleClose();
  };

  const handleRename = (): void => {
    if (props.handleRename) props.handleRename();
    handleClose();
  };

  const handleDelete = (): void => {
    if (props.handleDelete) props.handleDelete(id);
    handleClose();
  };

  return (
    <Menu id='long-menu' anchorEl={anchorRef} keepMounted open={Boolean(anchorRef)} onClose={handleClose}>
      {!mediaKitConfig.isEmpty && <MenuItem onClick={handleView}>View</MenuItem>}
      {isDraft && <MenuItem onClick={handlePreview}>Preview Draft</MenuItem>}
      <MenuItem onClick={handleEdit}>Edit</MenuItem>
      <MenuItem onClick={handleRename}>Rename</MenuItem>
      <MenuItem onClick={() => setConfirmDeleteModalOpen(true)}>Delete</MenuItem>
      {confirmDeleteModalOpen && (
        <Modal
          isOpen={true}
          onToggle={() => {
            setConfirmDeleteModalOpen(!confirmDeleteModalOpen);
          }}
          onConfirm={() => handleDelete()}
          confirmLabel={'Delete'}
          heading={'Are you sure?'}
          fullScreen={mobileView}
          maxWidth={'sm'}
        >
          <div>Are you sure you want to delete this media kit?</div>
        </Modal>
      )}
    </Menu>
  );
};

export default MediaKitOptionsMenu;
