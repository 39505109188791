import {
  Ambassador,
  SentEmail,
  Profile,
  SupplementMedia,
  SupplementMediaInput,
  BraintreeTransaction,
  StripeCharge
} from '../types/generated';

export function isAmbassador(brand: SentEmail | Ambassador | undefined): brand is Ambassador {
  return brand && (brand as Ambassador).ambassadorId !== undefined ? true : false;
}

export function isSentEmail(brand: SentEmail | Ambassador | undefined): brand is SentEmail {
  return brand && (brand as SentEmail).recipientEmail !== undefined ? true : false;
}

export function isProfile(item?: Profile | string | null): item is Profile {
  return item && (item as Profile)._id !== undefined ? true : false;
}

export function isSupplementMedia(item?: SupplementMedia | SupplementMediaInput | null): item is SupplementMedia {
  return item && (item as SupplementMedia)._id !== undefined ? true : false;
}

export function isStripeCharge(charge?: BraintreeTransaction | StripeCharge): charge is StripeCharge {
  return charge && (charge as StripeCharge).__typename === 'StripeCharge' ? true : false;
}
