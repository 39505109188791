import ytLogo from 'assets/images/yt_logo.svg';
import ytLogoWithText from 'assets/images/yt_logo_with_text.svg';

export const TrackingLinkActions: { [key: string]: string } = {
  swipe_ups: 'Swipe Ups',
  link_in_bio: 'Link in Bio',
  link_clicks: 'Link Clicks'
};

export const channelIcons: { [key: string]: string } = {
  default: 'https://media.q-83.com/site/channel_icons/kitlybusiness.png',
  instagram: 'https://media.q-83.com/site/channel_icons/instagram.png',
  facebook: 'https://media.q-83.com/site/channel_icons/facebook.svg',
  youtube: ytLogo,
  youtubeWithText: ytLogoWithText,
  twitter: 'https://media.q-83.com/site/channel_icons/twitter.svg',
  snapchat: 'https://media.q-83.com/site/channel_icons/snapchat.svg',
  tiktok: 'https://media.q-83.com/site/channel_icons/tiktok.svg',
  tiktokWithText: 'https://media.q-83.com/site/channel_icons/TikTok_logo.svg',
  blog: 'https://media.q-83.com/site/channel_icons/blog.svg',
  linkedin: 'https://media.q-83.com/site/channel_icons/linkedin.svg',
  twitch: 'https://media.q-83.com/site/channel_icons/twitch.png',
  videography: 'https://media.q-83.com/site/channel_icons/camera-video.png',
  photography: 'https://media.q-83.com/site/channel_icons/camera-dslr.png',
  podcast: 'https://media.q-83.com/site/channel_icons/microphone.png',
  appearance: 'https://media.q-83.com/site/channel_icons/speaker.png'
};

export const COLORS = {
  MAROON: '#B91F5A',
  GRAY_1: '#A1A1AD',
  RED: '#FF7171'
};
