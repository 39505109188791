import { useEffect, useState } from 'react';
import { Grid, ButtonBase, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import SuccessIcon from 'assets/icons/successIcon.svg';
import { useParams, useLocation } from 'react-router-dom';

const styles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    padding: 20
  },
  floatContainer: {
    background: 'white',
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: '#333333'
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    height: 240,
    width: 240,
    padding: 10
  },
  text: {
    color: '#CC215D',
    fontSize: 16,
    fontWeight: 'bold',
    marginTop: 16
  },
  icon: {
    height: 100,
    width: 100,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  title: { margin: 20 },
  link: {
    textDecoration: 'none'
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    width: '100%',
    maxWidth: 300,
    textTransform: 'none',
    fontWeight: 700,
    padding: 10
  }
}));

const OpenApp = () => {
  const { classes } = styles();

  const { search } = useLocation();
  const params = useParams<{ token: string }>();

  const [messageToDisplay, setMessageToDisplay] = useState<undefined | string>();

  useEffect(() => {
    const query = new URLSearchParams(search);
    const message = query.get('message');
    if (message) {
      setMessageToDisplay(message);
      query.delete('message');
    }
  }, [search]);

  return (
    <div>
      <div className={classes.floatContainer}>
        <div className={classes.iconContainer}>
          <img src={SuccessIcon} alt='success' />
          {messageToDisplay ? (
            <Typography className={classes.text}>{messageToDisplay}</Typography>
          ) : (
            <Typography className={classes.text}>TikTok login is complete!</Typography>
          )}

          <Grid container justifyContent='center' style={{ padding: 10 }}>
            {params.token ? (
              <ButtonBase
                href={`kitlyapp://tiktok-login?tiktok-login-token=${params.token}`}
                style={{ textAlign: 'center' }}
              >
                Click here to continue authentication in Kitly
              </ButtonBase>
            ) : (
              <ButtonBase href={`kitlyapp://`} style={{ textAlign: 'center' }}>
                Click here to return to Kitly
              </ButtonBase>
            )}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default OpenApp;
