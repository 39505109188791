import React, { useContext } from 'react';
import Dropzone from 'react-dropzone';
import AvatarEditor from 'react-avatar-editor';
import Slider from 'rc-slider';

import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Grid from '@mui/material/Grid';

import { Context } from 'components/pages/BrandsPage';

interface AvatarUploadProps {
  size?: number;
}

const AvatarUpload = ({ size }: AvatarUploadProps) => {
  const {
    state: { avatar },
    setAvatar
  } = useContext(Context);

  const { useState, useRef } = React;
  const [avatarScale, setAvatarScale] = useState<number>(1);
  const [isImageEditable, setIsImageEditable] = useState<boolean>(false);
  const [droppedImage, setDroppedImage] = useState('');

  const isAvatarPresent = Boolean(avatar || isImageEditable);
  const setEditorRef: any = useRef();

  const canvasSize = size || 300;

  const dropImage = (dropped: any) => {
    const image = dropped[0] || '';
    setDroppedImage(image);
    setAvatar(image);
    setIsImageEditable(true);
  };

  const saveEdit = async () => {
    if (setEditorRef) {
      const canvas = setEditorRef?.current;

      const blob: BlobPart = await new Promise((resolve) => canvas.getImageScaledToCanvas().toBlob(resolve));
      var file = new File([blob], 'scaled_avatar.png');
      if (blob) {
        setAvatar(file);
      }
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
      <Dropzone onDrop={dropImage} disabled={isImageEditable}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} style={{ width: canvasSize, height: canvasSize, cursor: 'pointer' }}>
            <input {...getInputProps()} />
            {isImageEditable ? (
              <AvatarEditor
                ref={setEditorRef}
                onMouseUp={isImageEditable ? saveEdit : () => {}}
                image={droppedImage}
                width={canvasSize}
                height={canvasSize}
                color={[255, 255, 255, 0.7]}
                border={0}
                scale={avatarScale || 1}
                style={{ backgroundColor: '#ccc', borderRadius: '100%' }}
                rotate={0}
              />
            ) : (
              <Avatar src={avatar} style={{ height: canvasSize, width: canvasSize }} />
            )}
          </div>
        )}
      </Dropzone>
      {isAvatarPresent && (
        <Grid container direction='column' alignItems='center'>
          {isImageEditable && (
            <Grid item container justifyContent='space-between' alignItems='center' style={{ marginTop: 10 }}>
              <Grid item>
                <button
                  style={{ cursor: 'pointer', background: 'none', border: 'none', verticalAlign: 'middle' }}
                  onClick={() => {
                    if (avatarScale > 0.2) {
                      setAvatarScale(avatarScale - 0.1);
                    }
                  }}
                >
                  <RemoveCircleIcon style={{ color: '#ccc' }} />
                </button>
              </Grid>
              <Grid item>
                <Slider
                  defaultValue={1}
                  value={avatarScale}
                  step={0.01}
                  min={0.2}
                  max={2}
                  onChange={(data: number) => {
                    setAvatarScale(data);
                  }}
                  onAfterChange={() => saveEdit()}
                  style={{ width: size || 100 }}
                />
              </Grid>
              <Grid item>
                <button
                  style={{ cursor: 'pointer', background: 'none', border: 'none', verticalAlign: 'middle' }}
                  onClick={() => {
                    if (avatarScale < 2) {
                      setAvatarScale(avatarScale + 0.1);
                    }
                  }}
                >
                  <AddCircleIcon style={{ color: '#ccc' }} />
                </button>
              </Grid>
            </Grid>
          )}
          <Grid item>
            <Dropzone onDrop={dropImage}>
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} style={{ cursor: 'pointer', marginTop: 10 }}>
                  <input {...getInputProps()} />
                  <Typography variant='caption' style={{ fontWeight: 200 }}>
                    Re-upload image
                  </Typography>
                </div>
              )}
            </Dropzone>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default AvatarUpload;
