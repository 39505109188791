import { useMemo } from 'react';
import { useGetMyUserUsageQuery, GetMyUserUsageQuery, User } from 'types/generated';

interface ClientConfig {
  cmt_manage: boolean;
  cmt_apply: boolean;
  cmt_disable: boolean;
  manage_instagram: boolean;
  requires_instagram: boolean;
  invite_influencers: boolean;
  search_influencers: boolean;
  browse_influencers: boolean;
  manage_shortlists: boolean;
  manage_brands: boolean;
  browse_brands: boolean;
  signup_type: string;
  custom_media_kits: boolean;
}

export const useUserConfig = (): {
  config: any;
  user?: User;
  userData?: GetMyUserUsageQuery;
  refetch: () => void;
  loading: boolean;
} => {
  const { data, refetch, loading } = useGetMyUserUsageQuery();
  const config = useMemo(() => {
    const user = data?.myUser || ({} as NonNullable<GetMyUserUsageQuery['myUser']>);

    let config = {} as ClientConfig;

    // General config (unaffected by permissions):
    config.cmt_manage = false;
    config.cmt_apply = false;
    config.cmt_disable = false;
    config.manage_instagram = true;
    // config.manage_youtube = true;
    config.requires_instagram = false;
    config.invite_influencers = true;
    config.search_influencers = true;
    config.browse_influencers = true;
    config.manage_shortlists = false;
    config.manage_brands = true;
    config.browse_brands = false;
    config.signup_type = 'type-agnostic';
    config.custom_media_kits = !Boolean(!user.activeSubscription || user.activeSubscription === 'kitly-usd-0');

    return { ...user, ...config } as const;
  }, [data]);

  return {
    config,
    user: data?.myUser || undefined,
    userData: data,
    refetch,
    loading
  };
};
