import React, { createContext, useState, useContext, useEffect, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import Smartlook from 'smartlook-client';
import Mixpanel from 'mixpanel-browser';
import { useGetMyUserQuery, User } from '../types/generated';
import { klaviyoTrack } from 'lib/klaviyoApi';

interface ITrackingContext {
  acceptedCookies?: boolean;
  setAcceptedCookies: (accepted: boolean) => void;
  analyticsUser?: User;
}

const trackingContextInitial = {
  setAcceptedCookies: (accepted: boolean) => {}
};

export const TrackingContext = createContext<ITrackingContext>(trackingContextInitial);

export const TrackingProvider = ({ children }: { children: React.ReactNode }) => {
  const { data } = useGetMyUserQuery();

  const analyticsUser = useMemo(() => data?.myUser || undefined, [data]);

  // Tracking enabled by default since this site is only accessed by authenticated users.
  const [acceptedCookies, setAcceptedCookies] = useState<boolean | undefined>(true);
  const [lastTrackedPage, setLastTrackedPage] = useState<undefined | string>();

  const location = useLocation();

  useEffect(() => {
    if (acceptedCookies && location.pathname && location.pathname !== lastTrackedPage) {
      setLastTrackedPage(location.pathname);
      trackPageView(location.pathname);
    }
  }, [acceptedCookies, location, lastTrackedPage, setLastTrackedPage]);

  useEffect(() => {
    if (acceptedCookies) {
      initialiseTracking(analyticsUser);
    }
  }, [acceptedCookies, analyticsUser]);

  return (
    <>
      <TrackingContext.Provider
        value={{
          acceptedCookies,
          setAcceptedCookies,
          analyticsUser
        }}
      >
        {children}
      </TrackingContext.Provider>
    </>
  );
};

let config: {
  MIXPANEL_TOKEN: string;
  SMARTLOOK_KEY: string;
  KLAVIYO_TOKEN: string;
  FACEBOOK_PIXEL_CODE: string;
  TAG_MANAGER_ID?: string;
  GA_TOKEN?: string;
} = {
  MIXPANEL_TOKEN: '788fb211e906fc4de734d130c8362ff5',
  SMARTLOOK_KEY: '5e4829d4dfb4d747476c07e6daa25704537c83dc',
  KLAVIYO_TOKEN: 'LR2nK5',
  GA_TOKEN: 'UA-128585569-8',
  FACEBOOK_PIXEL_CODE: '837489200029679'
};

if (process.env.REACT_APP_DEPLOYMENT === 'production') {
  config.MIXPANEL_TOKEN = '99e982863fc3e7e7e365112cbfc2aedd';
  config.SMARTLOOK_KEY = '5ba338eac6b4488a6c9a180864e6bc5398b3d94e';
  config.GA_TOKEN = 'UA-128585569-7';
}

const useTracking = () => {
  const { acceptedCookies, setAcceptedCookies, analyticsUser } = useContext(TrackingContext);

  const initialiseTrackingWithUser = useCallback(() => initialiseTracking(analyticsUser), [analyticsUser]);
  const trackPageViewWithUser = useCallback(
    (pageName: string, otherData?: any) => trackPageView(pageName, analyticsUser, otherData),
    [analyticsUser]
  );
  const trackEventWithUser = useCallback(
    (category: string, actionName?: string, otherData?: any) =>
      trackEvent(category, actionName, analyticsUser, otherData),
    [analyticsUser]
  );
  const identifyUserWithUser = useCallback(() => (analyticsUser ? identifyUser(analyticsUser) : {}), [analyticsUser]);

  return {
    initialiseTracking: initialiseTrackingWithUser,
    trackPageView: trackPageViewWithUser,
    trackEvent: trackEventWithUser,
    identifyUser: identifyUserWithUser,
    acceptedCookies,
    setAcceptedCookies
  };
};

function initialiseTracking(user?: User) {
  initialiseMixpanel();
  initialiseSmartlook();
  if (user) {
    identifyUser(user);
  }
}

export function trackPageView(pageName: string, user?: User, otherData?: any) {
  const fbq = (window as any).fbq;
  if (fbq) {
        const userWithoutLastName = user ? (() => {
      const { last_name, lastName, ...rest } = user;
      return rest;
    })() : undefined;

    fbq('track', 'Page View', { ...otherData, page: pageName, user: userWithoutLastName });
  }

  if (user) {
    trackMixpanel(user, pageName, otherData);
  }
  if (user && user.email) {
    klaviyoTrack(user, pageName);
  }
}

function trackEvent(category: string, actionName?: string, user?: User, otherData?: any) {
  const fbq = (window as any).fbq;
  if (fbq) {
        const userWithoutLastName = user ? (() => {
      const { last_name, lastName, ...rest } = user;
      return rest;
    })() : undefined;

        fbq('trackCustom', actionName, { ...otherData, content_category: category, content_name: actionName, user: userWithoutLastName });

  }

  if (user && actionName) {
    trackMixpanel(user, actionName, otherData);
    trackSmartlook(actionName, otherData);
    if (user && user.email) {
      klaviyoTrack(user, actionName);
    }
  }
}

export function identifyUser(user: User) {
  try {
    console.log('identifying user', user._id);
    identifyMixpanel(user);
    Smartlook.identify(String(user._id), {
      email: user.email || 'unknown',
      type: user.type || 'unknown'
    });

    const smartlookWindow = window as any;

    if (smartlookWindow.smartlook) {
      smartlookWindow.smartlook(function () {
        if (smartlookWindow.smartlook.playUrl) {
          Mixpanel.people.set({
            $smartlookPlayUrl: smartlookWindow.smartlook.playUrl
          });
          if (smartlookWindow.Intercom) {
            smartlookWindow.Intercom('update', {
              smartlookPlayUrl: smartlookWindow.smartlook.playUrl
            });
          }
        }
      });
    }
  } catch (ex) {
    console.log('smartlook.identify EXCEPTION', ex);
  }
}

//== MixPanel ==//
function identifyMixpanel(user: User) {
  try {
    Mixpanel.identify(user._id);
    Mixpanel.people.set({
      $email: user.email,
      $distinct_id: user._id,
      $first_name: user.first_name,
      $last_name: user.last_name,
      $created: user.created_at,
      $country_code: user.country,
      $timezone: user.timezone
    });
  } catch (error) {
    console.log('Mixpanel identify error', error);
  }
}

function trackMixpanel(user: User, activity: string, otherData?: any) {
  try {
    const defaultData = Boolean(user && user._id) ? { distinct_id: user._id } : {};
    otherData = otherData ? Object.assign(otherData, defaultData) : defaultData;
    Mixpanel.track(activity, otherData);
  } catch (error) {
    console.log('Mixpanel track error', error);
  }
}

function initialiseMixpanel() {
  try {
    console.log('initialise mixpanel', config.MIXPANEL_TOKEN);
    Mixpanel.init(config.MIXPANEL_TOKEN, {
      protocol: 'https'
    });
  } catch (error) {
    console.log('Mixpanel initialising error', error);
  }
}

function trackSmartlook(actionName: string, otherData?: any) {
  try {
    Smartlook.track(actionName, otherData);
  } catch (ex) {
    console.log('trackSmartlook EXCEPTION', ex);
  }
}

function initialiseSmartlook() {
  try {
    console.log('initialise smartlook', config.SMARTLOOK_KEY);
    Smartlook.init(config.SMARTLOOK_KEY);
  } catch (ex) {
    console.log('initialiseSmartlook EXCEPTION', ex);
  }
}

export default useTracking;
