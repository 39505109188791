import React, { useState, useRef, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dropzone from 'react-dropzone';
import AvatarEditor from 'react-avatar-editor';
import Avatar from '@mui/material/Avatar';
import Slider from 'rc-slider';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useUpdateUserMutation, useGetMyUserQuery } from '../../types/generated';
import { useErrorHandlerHook } from '../../hooks/useErrorCatchHook';
import { Loader } from 'components/Loader';

type Props = {
  editProfileAvatarModalOpen?: any;
  onToggleProfileAvatarModal?: () => void;
};

const EditProfileAvatarModal = (props: Props): JSX.Element => {
  const { editProfileAvatarModalOpen, onToggleProfileAvatarModal } = props;
  const [imageSrc, setImageSrc] = useState<any>(null);
  const [imgLoaded, setImageLoaded] = useState(false);
  const [avatarScale, setAvatarScale] = useState(1.2);
  const [updateUserAvatar] = useUpdateUserMutation();
  const { data: userData, loading: userLoading, error: userError } = useGetMyUserQuery();
  const [serverError, setServerError] = useState<null | string>(null);
  const { palette, spacing } = useTheme();
  const avatarRef = useRef<any>(null);

  const { apolloHandler } = useErrorHandlerHook();

  useEffect(() => {
    if (userError) {
      apolloHandler(userError, `Unable to get current invoice settings`);
    }
  }, [userError, apolloHandler]);

  const user = userData?.myUser;

  useEffect(() => {
    if (user?.avatar) {
      setImageSrc(user.avatar);
    }
  }, [user]);

  const handleAvatarUpload = async () => {
    if (!imageSrc) return null;

    if (avatarRef && avatarRef.current) {
      const blob: BlobPart = await new Promise((resolve) => avatarRef.current.getImageScaledToCanvas().toBlob(resolve));
      var file = new File([blob], 'scaled_avatar.png');
      updateUserAvatar({
        variables: {
          updates: {
            avatar: file
          }
        }
      })
        .then(() => {
          if (onToggleProfileAvatarModal) onToggleProfileAvatarModal();
        })
        .catch((err) => setServerError(err.message));
    }
  };

  const handleDrop = (dropped: File[]) => {
    setImageSrc(dropped[0]);
    setImageLoaded(true);
  };

  return (
    <Dialog open={Boolean(editProfileAvatarModalOpen)} onClose={onToggleProfileAvatarModal}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div
          style={{
            height: '300px',
            width: '300px',
            textAlign: 'center',

            paddingBottom: 8
          }}
        >
          <Dropzone data-cy='upload-profile-image' onDrop={handleDrop} disabled={imageSrc !== null}>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} style={{ width: '200px', height: '200px' }}>
                <input {...getInputProps()} />
                {Boolean(imageSrc && imgLoaded) ? (
                  <AvatarEditor
                    ref={avatarRef}
                    image={imageSrc}
                    width={300}
                    height={300}
                    color={[255, 255, 255, 0.7]}
                    border={0}
                    scale={avatarScale || 1}
                    style={{ backgroundColor: '#ccc', borderRadius: '100%' }}
                    rotate={0}
                  />
                ) : (
                  <Avatar
                    src={typeof imageSrc === 'string' ? imageSrc : undefined}
                    style={{ width: 300, height: 300 }}
                  />
                )}
              </div>
            )}
          </Dropzone>
        </div>
      </div>
      <div style={{ textAlign: 'center', paddingBottom: 10, paddingTop: 20 }}>
        {imageSrc ? (
          <button
            style={{
              color: 'darkred',
              cursor: 'pointer',
              background: 'none',
              border: 'none'
            }}
            onClick={() => {
              setImageSrc(null);
              setImageLoaded(false);
            }}
          >
            Click here to re-upload
          </button>
        ) : (
          'Click on the image to upload profile photo'
        )}
      </div>
      {imgLoaded && (
        <div
          style={{
            paddingTop: 10,
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 20,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <button
            style={{ cursor: 'pointer', background: 'none', border: 'none' }}
            onClick={() => {
              if (avatarScale > 0.2) {
                setAvatarScale(avatarScale - 0.1);
                setImageLoaded(true);
              }
            }}
          >
            <RemoveCircleIcon style={{ color: '#ccc' }} />
          </button>
          <div style={{ paddingLeft: 16, paddingRight: 16, flex: 1 }}>
            <Slider
              defaultValue={1}
              value={avatarScale}
              step={0.01}
              min={0.2}
              max={2}
              onChange={(data) => {
                setAvatarScale(data);
                setImageLoaded(true);
              }}
            />
          </div>
          <button
            style={{ cursor: 'pointer', background: 'none', border: 'none' }}
            onClick={() => {
              if (avatarScale < 2) {
                setAvatarScale(avatarScale + 0.1);
                setImageLoaded(true);
              }
            }}
          >
            <AddCircleIcon style={{ color: '#ccc' }} />
          </button>
        </div>
      )}
      {serverError && (
        <Typography style={{ color: palette.error.main, marginBottom: spacing(2) }}>{serverError}</Typography>
      )}
      {userLoading ? (
        <Loader />
      ) : (
        <Grid container>
          <Button variant='contained' style={{ marginRight: spacing(4) }} onClick={handleAvatarUpload}>
            Save
          </Button>
          <Button variant='outlined' onClick={onToggleProfileAvatarModal}>
            Cancel
          </Button>
        </Grid>
      )}
    </Dialog>
  );
};

export default EditProfileAvatarModal;
