import React, { useReducer, Dispatch, Reducer } from 'react';

interface Action {
  type: string;
  payload: any;
}

type BoundAction = (dispatch: Dispatch<Action>) => UsableAction;

type UsableAction = (payload: any) => void;

interface ActionList {
  [key: string]: BoundAction;
}

interface UsableActionList {
  [key: string]: UsableAction;
}

const newDataContext = (reducer: Reducer<any, Action>, actions: ActionList, defaultValue: any) => {
  const Context = React.createContext(defaultValue);

  const Provider = ({ children }: { children: any }) => {
    const [state, dispatch] = useReducer(reducer, defaultValue);

    const boundActions: UsableActionList = {};
    Object.keys(actions).forEach((key: string) => {
      boundActions[key] = actions[key](dispatch);
    });

    return <Context.Provider value={{ state, ...boundActions }}>{children}</Context.Provider>;
  };

  return { Context, Provider };
};

export default newDataContext;
