import React, { useState, useEffect, useCallback, useRef } from 'react';
import { makeStyles } from 'tss-react/mui';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import {
  Typography,
  TextField,
  Grid,
  Box,
  Button,
  IconButton,
  Avatar,
  FormLabel,
  ButtonBase,
  CircularProgress
} from '@mui/material';
import Delete from '@mui/icons-material/Delete';
import ImageIcon from '@mui/icons-material/Image';
import { useFileReader } from 'hooks/useFileReader';
import { useDropzone } from 'react-dropzone';
import AvatarEditor from 'react-avatar-editor';

import InputAdornment from '@mui/material/InputAdornment';

import { EditBoxProps } from './MediaKitEditBox';

import RcSlider from 'rc-slider';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import CloseButton from './CloseButton';
import AddIcon from 'assets/components/AddIcon';

import { ElementContentType, LinksContent, LinkInput } from 'types/generated';
import { compact, isEmpty, omit } from 'lodash';

import FallbackImage from '../../../assets/images/link75.png';

const SliderConfig = {
  dots: true,
  dotsClass: 'slider-dots',
  arrows: true,
  slidesToScroll: 1,
  infinite: false
};

const styles = makeStyles()((theme) => ({
  error: { color: 'red' },
  textEditor: { padding: 15, minHeight: 300, width: '100%' },
  textPreview: { marginTop: 12, fontStyle: 'italic', fontSize: 14 },
  previewContainer: { paddingTop: 10, fontSize: 14, marginTop: 10, paddingBottom: 20, width: '100%' },
  linkContainer: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
  imageEditContainer: { display: 'flex', justifyContent: 'center', position: 'relative' },
  imageSection: { backgroundColor: theme.palette.info.light, padding: 10, marginBottom: 10 },
  imageEditor: { width: '100%' },
  imagePreview: { height: 200, width: 200 },
  imagePlaceholder: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: 16,
    color: theme.palette.primary.main,
    textAlign: 'center',
    padding: 12
  },
  imagePlaceholderTitle: {
    color: theme.palette.primary.main,
    margin: 4
  },
  editorField: {
    marginTop: 10
  },
  slideContainer: { position: 'relative' },
  placeholderContainer: {
    width: '100%',
    paddingBottom: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 200
  },
  imageUploadSubtitle: {
    fontStyle: 'italic',
    marginTop: 4
  }
}));

function isLinksContent(content?: ElementContentType | null): content is LinksContent {
  return content && (content as LinksContent).links_content !== undefined ? true : false;
}

const LinksBox = ({ element: { content, title }, handleUpdate, isEditing, setIsEditing }: EditBoxProps) => {
  const { classes } = styles();
  const [linksContent, setLinksContent] = useState<Array<LinkInput>>([]);

  const handleUpdateLink = (link: LinkInput, index: number) => {
    setLinksContent(linksContent.map((o, i) => (i === index ? link : o)));
  };

  const handleAddLink = () => {
    setLinksContent([...linksContent, { title: '', link: '' }]);
  };

  const handleRemoveLink = (index: number, shouldSave?: boolean) => {
    const updatedContent = linksContent.filter((o, i) => (i === index ? false : true));
    setLinksContent(updatedContent);
    if (shouldSave) {
      handleSave(updatedContent);
    }
  };

  const storedLinksContent = isLinksContent(content) ? content.links_content : undefined;

  useEffect(() => {
    if (!isEditing) {
      setLinksContent(storedLinksContent ? compact(storedLinksContent) : []);
    }
  }, [storedLinksContent, setLinksContent, isEditing]);

  useEffect(() => {
    if (isEditing && isEmpty(linksContent)) {
      setLinksContent([{ link: '', title: '' }]);
    }
  }, [isEditing, linksContent, setLinksContent]);

  const handleSave = (updatedContent?: LinkInput[]) => {
    const cleanedContent = (updatedContent || linksContent).filter((o) => Boolean(o.title && o.link));
    const uploadContent = cleanedContent.map((o) => omit(o, ['iconUrl']));

    handleUpdate({ ...content, links_content: uploadContent }, { ...content, links_content: cleanedContent });
    setLinksContent(cleanedContent);
  };

  if (isEditing) {
    return (
      <>
        {linksContent.map((o, i) => (
          <LinkPair
            key={`link_${i}`}
            item={o}
            onChange={(link) => (link ? handleUpdateLink(link, i) : handleRemoveLink(i))}
            classes={classes}
          />
        ))}
        <Grid container justifyContent={'flex-end'}>
          <Grid item>
            <Button size={'small'} onClick={() => handleAddLink()}>
              Add Another
            </Button>
          </Grid>
        </Grid>
        <Grid container justifyContent='flex-start'>
          <Button variant='outlined' color='primary' onClick={() => handleSave()}>
            {`Save & Close`}
          </Button>
        </Grid>
      </>
    );
  }
  const slidesToShow = Math.min(3, linksContent.length) || 1;
  return (
    <Box className={classes.previewContainer}>
      {isEmpty(linksContent) ? (
        <ButtonBase className={classes.placeholderContainer} onClick={() => setIsEditing(true)}>
          <AddIcon size={35} strokeWidth={3} />
        </ButtonBase>
      ) : (
        <Slider {...SliderConfig} slidesToShow={slidesToShow} className='links-slider slider-theme'>
          {linksContent.map((o, i) => {
            return (
              <Box key={`link_${i}`} className={classes.slideContainer}>
                {handleRemoveLink && (
                  <CloseButton
                    onPress={() => {
                      handleRemoveLink(i, true);
                    }}
                    filledCircle
                    size={20}
                  />
                )}
                <img src={o.iconUrl ? o.iconUrl : FallbackImage} alt={`link_${i}`} />
                <LinkPair key={`link_${i}`} item={o} classes={classes} />
              </Box>
            );
          })}
        </Slider>
      )}
    </Box>
  );
};

const LinkPair = ({
  item,
  onChange,
  classes
}: {
  item: LinkInput;
  onChange?: (item?: LinkInput) => void;
  classes: any;
}) => {
  const { readFile, readFileLoading } = useFileReader();
  const [avatarScale, setAvatarScale] = useState(1.2);
  const [iconContent, setIconContent] = useState<string | undefined>();
  const avatarRef = useRef<AvatarEditor | null>(null);
  const [isEditingIcon, setIsEditingIcon] = useState(false);

  useEffect(() => {
    if (!iconContent) {
      if (typeof item?.iconUrl === 'string') {
        setIconContent(item.iconUrl);
      } else if (item?.icon) {
        readFile(item.icon, (name, blob, encodedString) => {
          setIconContent(encodedString);
        });
      }
    }
  }, [item, iconContent, setIconContent, readFile]);

  const dropImage = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file: File) => {
        readFile(file, (name, blob, encodedString) => {
          setIconContent(encodedString);
          setIsEditingIcon(true);
          if (onChange) {
            onChange({ ...item, icon: blob });
          }
        });
      });
    },
    [readFile, setIconContent, item, onChange]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: dropImage,
    accept: 'image/jpg, image/jpeg, image/png, image/heic, image/heif',
    maxSize: 100000000
  });

  if (onChange) {
    return (
      <Grid container>
        <Grid item xs={12} className={classes.editorField}>
          <TextField
            variant='standard'
            fullWidth
            label={'Link Name'}
            value={item.title}
            onChange={(e: any) => {
              onChange({ ...item, title: e.target.value });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton onClick={() => onChange()}>
                    <Delete />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={12} className={classes.editorField}>
          <TextField
            variant='standard'
            fullWidth
            label={'Link URL'}
            value={item.link}
            onChange={(e: any) => {
              onChange({ ...item, link: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={12} className={classes.editorField}>
          <FormLabel style={{ marginBottom: 10, display: 'flex', fontSize: 11, color: '#333333', opacity: 0.7 }}>
            Link Thumbnail (optional)
          </FormLabel>
          <Box className={classes.imageSection}>
            <Box className={classes.imageEditContainer}>
              {iconContent ? (
                <Grid container>
                  <Grid item xs={12} container justifyContent='center'>
                    <Grid item>
                      {isEditingIcon ? (
                        <AvatarEditor
                          ref={avatarRef}
                          image={iconContent}
                          width={300}
                          height={300}
                          color={[255, 255, 255, 0.7]}
                          border={0}
                          scale={avatarScale || 1}
                          style={{ backgroundColor: '#ccc', borderRadius: '100%' }}
                          rotate={0}
                        />
                      ) : (
                        <Avatar src={iconContent} className={classes.imagePreview} />
                      )}
                    </Grid>
                    <CloseButton
                      onPress={() => {
                        setIconContent(undefined);
                        onChange({ ...item, icon: undefined });
                      }}
                    />
                  </Grid>
                  {isEditingIcon && (
                    <Grid item xs={12} container justifyContent='space-between'>
                      <Grid
                        item
                        style={{
                          paddingTop: 10,
                          paddingLeft: 20,
                          paddingRight: 20,
                          paddingBottom: 20,
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          flex: 1
                        }}
                      >
                        <button
                          style={{ cursor: 'pointer', background: 'none', border: 'none' }}
                          onClick={() => {
                            if (avatarScale > 0.2) {
                              setAvatarScale(avatarScale - 0.1);
                            }
                          }}
                        >
                          <RemoveCircleIcon style={{ color: '#ccc' }} />
                        </button>
                        <div style={{ paddingLeft: 16, paddingRight: 16, flex: 1 }}>
                          <RcSlider
                            defaultValue={1}
                            value={avatarScale}
                            step={0.01}
                            min={0.2}
                            max={2}
                            onChange={(data) => {
                              setAvatarScale(data);
                            }}
                          />
                        </div>
                        <button
                          style={{ cursor: 'pointer', background: 'none', border: 'none' }}
                          onClick={() => {
                            if (avatarScale < 2) {
                              setAvatarScale(avatarScale + 0.1);
                            }
                          }}
                        >
                          <AddCircleIcon style={{ color: '#ccc' }} />
                        </button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant={'contained'}
                          onClick={() => {
                            if (avatarRef?.current) {
                              const canvas = avatarRef.current.getImage().toDataURL();
                              fetch(canvas)
                                .then((res) => res.blob())
                                .then((blob) => {
                                  const previewUrl = URL.createObjectURL(blob);
                                  setIconContent(previewUrl);
                                  if (onChange) {
                                    onChange({
                                      ...item,
                                      icon: new File([blob], 'scaled_icon_image.png'),
                                      iconUrl: previewUrl
                                    });
                                  }
                                  setIsEditingIcon(false);
                                });
                            } else {
                              setIsEditingIcon(false);
                            }
                          }}
                        >
                          Done
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              ) : (
                <div {...getRootProps()} className={classes.imageEditor}>
                  <input {...getInputProps()} />
                  <Box className={classes.imagePlaceholder}>
                    {readFileLoading ? (
                      <CircularProgress />
                    ) : (
                      <>
                        <ImageIcon fontSize={'large'} />
                        <Typography className={classes.imagePlaceholderTitle}>Drop an image or</Typography>
                        <Button variant='outlined' color='primary'>
                          Browse
                        </Button>
                        <Typography variant='caption' className={classes.imageUploadSubtitle}>
                          Accepts JPEG or PNG images up to 100MB
                        </Typography>
                      </>
                    )}
                  </Box>
                </div>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  }

  const amendedLink = item.link?.startsWith('http') ? item.link : `http://${item.link}`;
  const displayLink = item.title || amendedLink;

  return (
    <Grid container>
      <Grid item xs={12} className={classes.linkContainer}>
        <a href={amendedLink} target='_blank' rel='noopener noreferrer'>
          {displayLink}
        </a>
      </Grid>
    </Grid>
  );
};

export default LinksBox;
