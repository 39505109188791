import { Box, Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import signUpInsights from 'assets/images/sign-up-insights.png';

export const NoData = () => {
  const { classes } = useStyles();

  return (
    <>
      <Box>
        <Grid container justifyContent='space-between'>
          <Grid item xs={6}>
            <Typography className={classes.bodyText}>
              View and share your content performance. Filter it by the{' '}
              <Typography component='span' className={`${classes.bodyText} ${classes.boldText}`}>
                {' '}
                #hashtags
              </Typography>{' '}
              or{' '}
              <Typography component='span' className={`${classes.bodyText} ${classes.boldText}`}>
                @mentions
              </Typography>{' '}
              you used.
            </Typography>
          </Grid>
          <Grid container item xs={6} justifyContent='flex-end'>
            <img src={signUpInsights} alt='sign up insights' className={classes.image} />
          </Grid>
        </Grid>
      </Box>
      <Box mt={'auto'}></Box>
    </>
  );
};

const useStyles = makeStyles()({
  boldText: {
    fontWeight: 500
  },
  image: {
    height: '100%'
  },
  bodyText: {
    lineHeight: '20px',
    color: '#606060'
  }
});
