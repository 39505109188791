import React, { useState, useEffect, useMemo } from 'react';
import {
  TextField,
  Button,
  ButtonBase,
  FilledInput,
  FormControl,
  FormControlLabel,
  Select,
  Grid,
  InputLabel,
  MenuItem,
  Typography,
  RadioGroup,
  Radio,
  LinearProgress,
  CircularProgress,
  useTheme
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ProfileSelector from 'components/Fields/ProfileSelector';
import SelectChannel from 'components/Fields/SelectChannel';
import Modal from 'components/Modal';
import DeliverableDetails from './DeliverableDetails';
import PaginatedForm, { PageContent } from './PaginatedForm';
import TimeRangeField from './TimeRangeField';
import DateRangeField from './DateRangeField';
import FileUploadsField from './FileUploadsField';

import useTracking from 'context/useTrackingCtx';
import { useUserConfig } from 'hooks/useUserConfig';
import { useErrorHandlerHook } from 'hooks/useErrorCatchHook';
import deliverables from 'lib/deliverables';
import channels from 'lib/channels';

import 'date-fns';
import { formatError } from 'lib/formatStrings';

import { filter, find, isEmpty, compact, pick } from 'lodash';
import moment, { Moment } from 'moment';
import {
  GetCollaborationListDocument,
  Deliverable,
  SocialChannel,
  SocialDeliverable,
  DeliverableUpdates,
  useCreateDeliverableMutation,
  useUpdateDeliverableMutation,
  UserCurrency,
  useGetCollaborationListQuery,
  FileInput
} from 'types/generated';
import validate from 'validate.js';
import { currencyOptions } from '../../../lib/invoiceValues';

interface DeliverableCreationProps {
  children: JSX.Element;
  editableDeliverable?: Deliverable;
  selectedCollabId?: string | null;
  selectedInfluencerId?: string;
  toggleModal?: () => void;
  refetch?: () => void;
}

interface CreationModalProps extends DeliverableCreationProps {
  isOpen: boolean;
  onCancel: () => void;
}

interface CurrencyValueInputsProps {
  currency?: UserCurrency;
  setCurrency: React.Dispatch<React.SetStateAction<UserCurrency | undefined>>;
  price: string;
  setPrice: React.Dispatch<React.SetStateAction<string>>;
}

interface LimitedEditFormProps extends CurrencyValueInputsProps {
  editableDeliverable?: Deliverable;
  validateDeliverable: () => null | undefined;
  isLoading?: boolean;
}
interface NotesInputProps {
  notes: string;
  setNotes: (arg: string) => void;
  classes: any;
  q83: any;
}

const constraints = {
  collaborationId: { presence: { allowEmpty: false } },
  endDate: { presence: { allowEmpty: false } },
  endTime: {
    numericality: { notGreaterThan: 2399 }
  },
  socialChannel: { presence: { allowEmpty: false } },
  socialDeliverable: { presence: { allowEmpty: false } }
};

const currencyDirectory = currencyOptions.reduce<Record<string, any>>((acc, curr) => {
  return { ...acc, [curr.value]: curr };
}, {});

const dateFormat = 'YYYY-MM-DD';

export const DeliverableCreation = (props: DeliverableCreationProps) => {
  const { children } = props;
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const dismissModal = () => {
    setIsOpen(false);
    if (props.toggleModal) props.toggleModal();
  };

  return (
    <span style={{ display: 'block', width: '100%' }}>
      {isOpen && (
        <CreationModal
          {...props}
          isOpen={isOpen}
          toggleModal={dismissModal}
          onCancel={() => {
            dismissModal();
          }}
        />
      )}
      <ButtonBase style={{ width: '100%' }} onClick={openModal}>
        {children}
      </ButtonBase>
    </span>
  );
};

const CreationModal = (props: CreationModalProps) => {
  const { isOpen, toggleModal, editableDeliverable, selectedCollabId, selectedInfluencerId, refetch } = props;
  const { classes } = useStyles();
  const theme = useTheme();
  const { userData } = useUserConfig();
  const { apolloHandler } = useErrorHandlerHook();
  const { trackEvent } = useTracking();

  const determineDefaultIds = () => {
    let defaultInfluencerIds: Array<string> = [];
    if (selectedInfluencerId) {
      defaultInfluencerIds = [selectedInfluencerId];
    } else if (editableDeliverable && !isEmpty(editableDeliverable?.profiles)) {
      defaultInfluencerIds =
        compact(editableDeliverable?.profiles).reduce<string[]>((acc, curr) => {
          if (curr && curr._id) acc.push(curr._id);
          return acc;
        }, []) || [];
    }
    return defaultInfluencerIds;
  };

  const limitedEditableFields = Boolean(editableDeliverable && !editableDeliverable?.canEdit);
  const [startDate, setStartDate] = useState<Moment | null>(
    editableDeliverable?.startDate ? moment(editableDeliverable.startDate) : null
  );
  const [endDate, setEndDate] = useState<Moment | null>(
    editableDeliverable?.endDate ? moment(editableDeliverable.endDate) : null
  );
  const [startTime, setStartTime] = useState<number | null>(editableDeliverable?.startTime || null);
  const [endTime, setEndTime] = useState<number | null>(editableDeliverable?.endTime || null);
  const [socialChannel, setSocialChannel] = useState(editableDeliverable?.socialChannel || SocialChannel.Instagram);
  const [socialDeliverable, setSocialDeliverable] = useState<SocialDeliverable | undefined>(
    editableDeliverable?.socialDeliverable || undefined
  );
  const [influencerIds, setInfluencerIds] = useState(determineDefaultIds());
  const [notes, setNotes] = useState(editableDeliverable?.notes || '');
  const [error, setError] = useState('');
  const [price, setPrice] = useState<string>(editableDeliverable?.price?.toString() || '');
  const [currency, setCurrency] = useState<UserCurrency | undefined>(editableDeliverable?.currency || undefined);
  const [storyFrameCount, setStoryFrameCount] = useState(editableDeliverable?.storyFrameCount ?? 1);
  const [fileUploads, setFileUploads] = useState<Array<FileInput>>(
    editableDeliverable?.fileUploads ? compact(editableDeliverable.fileUploads) : []
  );

  const [collaborationId, setCollaborationId] = useState(
    editableDeliverable?.collaboration?._id || selectedCollabId || ''
  );
  const [includeSocialAccount, setIncludeSocialAccount] = useState('true');

  const { loading: collabLoading, data: collabData, error: collaborationListError } = useGetCollaborationListQuery();

  const selectedSocialChannel = useMemo(() => find(channels, { value: socialChannel }), [socialChannel]);

  const collaborationList = useMemo(() => {
    if (collabData && collabData?.getCollaborationList) {
      return collabData?.getCollaborationList;
    }
    return [];
  }, [collabData]);

  const [createDeliverable, { loading: createLoading }] = useCreateDeliverableMutation({
    refetchQueries: [{ query: GetCollaborationListDocument }],
    onCompleted: () => {
      if (refetch) {
        refetch();
      }
    }
  });

  const [updateDeliverable, { loading: updateLoading }] = useUpdateDeliverableMutation({
    refetchQueries: [{ query: GetCollaborationListDocument }],
    onCompleted: () => {
      if (refetch) {
        refetch();
      }
    }
  });

  const isLoading = createLoading || updateLoading;

  useEffect(() => {
    if (collaborationListError) {
      apolloHandler(collaborationListError);
    }
  }, [collaborationListError, apolloHandler]);

  useEffect(() => {
    if (userData?.myUser?.paymentDetails?.currency) {
      const collabCurrency = userData.myUser.paymentDetails.currency as string as UserCurrency;
      setCurrency(collabCurrency);
    }
  }, [userData, setCurrency]);

  const selectedCollaboration = useMemo(() => {
    if (collaborationList && collaborationId) {
      return find(collaborationList, { _id: collaborationId });
    }
    return undefined;
  }, [collaborationId, collaborationList]);

  const deliverableUpdates: DeliverableUpdates = useMemo(
    () => ({
      collaborationId,
      startDate: startDate?.format(dateFormat),
      endDate: endDate?.format(dateFormat),
      startTime,
      endTime,
      socialChannel,
      socialDeliverable,
      profileIds: influencerIds,
      notes,
      fileUploads: fileUploads.map((o) => pick(o, ['link', 'title', 'file', 'fileSize'])),
      price: parseFloat(price),
      currency: currency as UserCurrency,
      storyFrameCount
    }),
    [
      collaborationId,
      startDate,
      startTime,
      endDate,
      endTime,
      socialChannel,
      socialDeliverable,
      influencerIds,
      notes,
      fileUploads,
      price,
      currency,
      storyFrameCount
    ]
  );

  const isFirstPageComplete = useMemo(() => {
    if (!endDate) return false;
    if (!socialDeliverable) return false;
    if (!socialChannel) return false;
    return true;
  }, [endDate, socialDeliverable, socialChannel]);

  const validateDeliverable = () => {
    // Validate:
    validate.validators.presence.message = 'is required';
    let errors = validate(deliverableUpdates, constraints);

    const firstKey = !isEmpty(errors) ? Object.keys(errors)[0] : null;
    if (firstKey && errors[firstKey]) {
      setError(errors[firstKey][0]);
      return null;
    } else {
      setError('');
    }
    // Create/update:
    if (editableDeliverable?._id) {
      updateDeliverable({ variables: { id: editableDeliverable?._id, updates: deliverableUpdates } })
        .then((response) => {
          if (response?.data?.updateAndCreateDeliverables?.success) {
            trackEvent('deliverable', 'updated deliverable');
            toggleModal?.();
          } else {
            setError(response?.data?.updateAndCreateDeliverables?.message || '');
          }
        })
        .catch((errors) => {
          const errorMessage = formatError(errors) || 'Error updating deliverable';
          setError(errorMessage);
        });
    } else {
      createDeliverable({ variables: { updates: deliverableUpdates } })
        .then((response) => {
          if (response?.data?.createMultipleDeliverables?.success) {
            trackEvent('deliverable', 'created deliverable');
            toggleModal?.();
          } else {
            setError(response?.data?.createMultipleDeliverables?.message || '');
          }
        })
        .catch((errors) => {
          const errorMessage = formatError(errors) || 'Error creating deliverable';
          setError(errorMessage);
        });
    }
  };

  const selectSocialChannel = (channel: SocialChannel) => {
    setSocialChannel(channel);
    if (socialDeliverable) {
      const deliverableChannel = find(deliverables, { value: socialDeliverable })?.channel;
      if (deliverableChannel !== channel) {
        setSocialDeliverable(undefined);
      }
    }
  };

  const CollaborationDropdown = () => {
    return (
      <Grid item xs={12} className={classes.input}>
        {collabLoading ? (
          <LinearProgress style={{ width: '100%' }} />
        ) : (
          <FormControl variant='standard' fullWidth={true}>
            <InputLabel>Campaign</InputLabel>
            <Select
              variant='standard'
              disabled={Boolean(editableDeliverable?.collaboration?._id || selectedCollabId)}
              value={collaborationId}
              onChange={(e: any) => setCollaborationId(e.target.value)}
              fullWidth={true}
              renderValue={(selected: any) => {
                const collabName = find(collaborationList, { _id: selected })?.name || `#${selected}`;
                return collabName;
              }}
            >
              <MenuItem disabled value=''>
                Select collaboration
              </MenuItem>
              {collaborationList?.map((o) =>
                o?._id ? (
                  <MenuItem key={`campaign-${o._id}`} value={o._id}>
                    {o.name}
                  </MenuItem>
                ) : null
              )}
            </Select>
          </FormControl>
        )}
      </Grid>
    );
  };

  const DeliverableDropdown = () => {
    const title = `Select ${
      typeof selectedSocialChannel === 'object' ? `${selectedSocialChannel.label} ` : ''
    }Deliverable`;
    const filteredDeliverables = filter(deliverables, (o) => {
      if (o.hidden) {
        return false;
      }
      if (socialChannel) {
        return o.channel.constructor === Array ? o.channel.includes(socialChannel) : o.channel === socialChannel;
      }
      return true;
    });

    return (
      <Grid
        item
        xs={socialDeliverable === SocialDeliverable.IgStory ? 6 : 12}
        style={{
          paddingRight: socialDeliverable === SocialDeliverable.IgStory ? 8 : undefined,
          marginTop: 10,
          marginBottom: 15
        }}
        className={classes.input}
      >
        <FormControl variant='standard' fullWidth={true}>
          <InputLabel>{title}</InputLabel>
          <Select
            variant='standard'
            value={socialDeliverable}
            disabled={limitedEditableFields}
            onChange={(e: any) => setSocialDeliverable(e.target.value)}
            fullWidth={true}
            renderValue={(selected: any) => {
              const name = find(deliverables, { value: selected })?.label || selected;
              return name;
            }}
          >
            <MenuItem disabled value=''>
              {title}
            </MenuItem>
            {filteredDeliverables?.map((o: { value: string; label: string }) => (
              <MenuItem key={`endTime-${o.value}`} value={o.value}>
                {o.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const pages: PageContent[] = [
    {
      title: 'Deliverable Details',
      content: (
        <div style={{ paddingRight: 10 }}>
          <Typography variant='h3' style={{ marginBottom: 15, marginTop: 15 }}>{`${
            editableDeliverable?._id ? 'Edit' : 'New'
          } Deliverable`}</Typography>
          <CollaborationDropdown />
          <Grid container item xs={12} style={{ marginTop: 10 }}>
            <SelectChannel channels={[socialChannel]} setChannels={selectSocialChannel} />
          </Grid>
          <Grid container item xs={12}>
            <DeliverableDropdown />
            {socialDeliverable === SocialDeliverable.IgStory && (
              <Grid item container xs={6} className={classes.input}>
                <TextField
                  variant='standard'
                  label='Number of frames'
                  fullWidth
                  type='number'
                  value={storyFrameCount}
                  inputProps={{
                    min: 1,
                    step: 1
                  }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined) => {
                    const inputValue = e?.target.value;
                    setStoryFrameCount(
                      typeof inputValue === 'string' && inputValue && inputValue !== '0' ? parseInt(inputValue) : 1
                    );
                  }}
                  style={{ marginTop: 5 }}
                />
              </Grid>
            )}
          </Grid>
          <DateRangeField startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
          <TimeRangeField startTime={startTime} setStartTime={setStartTime} endTime={endTime} setEndTime={setEndTime} />
          <Grid item xs={12} className={classes.input} style={{ marginTop: 25 }}>
            <Typography style={{ fontSize: 14, fontWeight: 500 }}>Value</Typography>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: 50, marginTop: 10 }}>
            <CurrencyValueInputs currency={currency} setCurrency={setCurrency} price={price} setPrice={setPrice} />
          </Grid>
          {/* <BitlyTracking /> */}
        </div>
      ),
      renderActions: ({ nextPage }) => (
        <Grid container>
          <Grid item>
            <Button variant='contained' color='primary' onClick={nextPage} disabled={!isFirstPageComplete}>
              Next
            </Button>
          </Grid>
        </Grid>
      )
    },
    {
      title: 'Brief',
      content: (
        <div style={{ overflowX: 'hidden', paddingRight: 10 }}>
          <Typography variant='h3' style={{ marginBottom: 15, marginTop: 15 }}>
            Brief
          </Typography>
          <NotesInput notes={notes} setNotes={setNotes} classes={classes} q83={(theme as any).q83} />
          <Grid item xs={12} className={classes.input} style={{ marginBottom: 30 }}>
            <FileUploadsField value={fileUploads} setValue={setFileUploads} />
          </Grid>
        </div>
      ),
      renderActions: ({ nextPage, previousPage }) => (
        <Grid container spacing={2} style={{ width: '100%' }}>
          <Grid item>
            <Button variant='outlined' color='primary' onClick={previousPage}>
              Back
            </Button>
          </Grid>
          <Grid item>
            <Button variant='contained' color='primary' onClick={nextPage}>
              Next
            </Button>
          </Grid>
        </Grid>
      )
    },
    {
      title: 'Assign Account',
      content: (
        <div style={{ overflowX: 'hidden', paddingRight: 10, marginBottom: 30 }}>
          <Typography variant='h3' style={{ marginBottom: 15, marginTop: 15 }}>
            Assign Account
          </Typography>
          <Grid item xs={12} className={classes.input}>
            <DeliverableDetails
              deliverable={{
                ...editableDeliverable,
                ...deliverableUpdates,
                _id: editableDeliverable?._id || 'new',
                collaboration: selectedCollaboration
              }}
            />
          </Grid>
          <Grid item xs={12} className={classes.input}>
            <RadioGroup
              value={includeSocialAccount}
              onChange={(event: any) => {
                setIncludeSocialAccount(event?.target?.value);
                setStartDate(null);
              }}
            >
              <Grid container justifyContent='flex-start' direction='column'>
                <FormControlLabel
                  value={'false'}
                  style={{ marginLeft: 2 }}
                  control={
                    <Radio
                      checked={includeSocialAccount === 'false'}
                      style={{ fontSize: 12, color: theme.palette.primary.main }}
                    />
                  }
                  label={`Don't link Social Account`}
                />
                <FormControlLabel
                  value={'true'}
                  style={{ marginLeft: 2 }}
                  control={
                    <Radio
                      checked={includeSocialAccount === 'true'}
                      style={{ fontSize: 12, color: theme.palette.primary.main }}
                    />
                  }
                  label={`Link Social Account`}
                />
              </Grid>
            </RadioGroup>
          </Grid>
          {includeSocialAccount === 'true' && (
            <Grid item xs={12} className={classes.input}>
              <ProfileSelector
                shouldPreselectPrimaryAccount
                emptyLabel='Link profiles'
                selectedIds={influencerIds}
                setSelectedIds={setInfluencerIds}
                profileIds={selectedCollaboration?.profileIds ? compact(selectedCollaboration.profileIds) : undefined}
                profileTags={
                  selectedCollaboration?.profileTags ? compact(selectedCollaboration.profileTags) : undefined
                }
              />
            </Grid>
          )}
        </div>
      ),
      renderActions: ({ previousPage }) => (
        <Grid container spacing={2} style={{ width: '100%' }}>
          <Grid item>
            <Button variant='outlined' color='primary' onClick={previousPage}>
              Back
            </Button>
          </Grid>
          <Grid item>
            <Button variant='contained' color='primary' onClick={validateDeliverable}>
              {editableDeliverable ? 'Update' : 'Create'}
            </Button>
          </Grid>
          {error && (
            <Grid item xs={12}>
              <Typography color='error'>{error}</Typography>
            </Grid>
          )}
        </Grid>
      )
    }
  ];

  return (
    <Modal
      isOpen={isOpen}
      onToggle={toggleModal}
      showCloseIcon
      hideConfirmationButtons
      isLoadingConfirm={isLoading}
      errorMessage={error}
      showErrorMessage={Boolean(error)}
      maxWidth={'sm'}
      disableBackdropClick={true}
      paperStyle={{ borderColor: theme.palette.primary.main, borderStyle: 'solid', borderWidth: 0.5, borderRadius: 4 }}
    >
      {limitedEditableFields ? (
        <LimitedEditForm
          editableDeliverable={editableDeliverable}
          currency={currency}
          setCurrency={setCurrency}
          price={price}
          setPrice={setPrice}
          validateDeliverable={validateDeliverable}
          isLoading={isLoading}
        />
      ) : (
        <PaginatedForm pages={pages} isLoading={isLoading} />
      )}
    </Modal>
  );
};

const NotesInput = (props: NotesInputProps) => {
  const { notes, setNotes, classes } = props;
  const textInputPropsSmall = {
    style: {
      fontSize: 14,
      letterSpacing: 0.5,
      lineHeight: 'normal',
      backgroundColor: 'rgba(132,	198,	255, 0.07)'
    }
  };
  return (
    <Grid item xs={12} className={classes.input}>
      <FilledInput
        value={notes}
        onChange={(e: any) => setNotes(e.target.value)}
        multiline={true}
        rows={5}
        fullWidth={true}
        inputProps={Object.assign({ contentEditable: true, suppressContentEditableWarning: true }, textInputPropsSmall)}
        disableUnderline={true}
        placeholder={
          'Include things here like Do’s and Don’ts along with any specific deliverable details or attachments '
        }
      />
    </Grid>
  );
};

const LimitedEditForm = ({
  editableDeliverable,
  currency,
  setCurrency,
  price,
  setPrice,
  validateDeliverable,
  isLoading
}: LimitedEditFormProps) => {
  return (
    <Grid container style={{ overflowX: 'hidden', paddingRight: 10 }}>
      <Typography variant='h3' style={{ marginBottom: 15, marginTop: 15 }}>{`${
        editableDeliverable?._id ? 'Edit' : 'New'
      } Deliverable`}</Typography>
      {editableDeliverable && <DeliverableDetails deliverable={editableDeliverable} />}
      <Grid item container style={{ marginBottom: 20 }}>
        <CurrencyValueInputs currency={currency} setCurrency={setCurrency} price={price} setPrice={setPrice} />
      </Grid>

      {isLoading ? (
        <Grid item xs={12} container justifyContent='center'>
          <CircularProgress />
        </Grid>
      ) : (
        <Grid item>
          <Button variant='contained' color='primary' onClick={validateDeliverable}>
            {editableDeliverable ? 'Update' : 'Create'}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

const CurrencyValueInputs = ({ currency, setCurrency, price, setPrice }: CurrencyValueInputsProps) => {
  return (
    <Grid container item xs={12} style={{ columnGap: '20px' }} wrap='nowrap'>
      <Grid item xs={6}>
        <Select
          variant='standard'
          value={currency || 'Currency'}
          onChange={(e: any) => setCurrency(e.target.value)}
          fullWidth={true}
          renderValue={(selected: any) => {
            return currencyDirectory[selected] ? currencyDirectory[selected].title || selected : selected;
          }}
        >
          <MenuItem disabled value='Currency'>
            Currency
          </MenuItem>
          {currencyOptions?.map((o) => (
            <MenuItem key={`${o.value}`} value={o.value}>
              {o.title}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={6}>
        <TextField
          variant='standard'
          placeholder='Value'
          fullWidth
          type='number'
          value={price}
          onChange={(e) => setPrice(e.target.value)}
        />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles()((theme) => {
  return {
    input: {
      margin: '5px 0'
    },
    finishButton: {
      width: '100%'
    },
    error: {
      color: theme.palette.error.main,
      fontSize: 12,
      marginBottom: 10,
      textAlign: 'center'
    },
    notesTitle: {
      marginBottom: 10
    },
    assignedDetails: {
      backgroundColor: '#84C6FF17',
      paddingTop: 14,
      paddingBottom: 14,
      paddingLeft: 24,
      paddingRight: 24,
      width: '100%',
      marginBottom: 15,
      marginTop: 15
    },
    assignedDetailsRow: {
      marginTop: 3
    },
    attachmentsTitle: {
      fontSize: 14,
      marginTop: 10
    },
    checkboxIcon: {
      width: 18,
      height: 18
    }
  };
});
