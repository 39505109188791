import { Box, CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import collabsImg from 'assets/images/collabs.png';
import { TileContainer } from 'components/Containers/TileContainer';
import React from 'react';
import { useWalkthrough } from 'hooks/useWalkthrough';
import { WALKTHROUGHS } from 'types/custom';

export const NoCollabsDataTile = () => {
  const { classes } = useClasses();
  const { handleWalkthrough: handleNewCollab, loading: newCollabLoading } = useWalkthrough(
    WALKTHROUGHS.COLLABORATIONS,
    '/campaigns?action=create'
  );

  return (
    <TileContainer bgcolor={'#FCF2F6'} border='none'>
      <Box height={392} display='flex' flexDirection='column'>
        <Box mb='29px'>
          <Typography variant='h6'>Campaigns</Typography>
        </Box>
        <Box mb='38px' display='flex' justifyContent='center'>
          <img src={collabsImg} alt='campaigns' className={classes.image} />
        </Box>
        <Box mb={'30px'}>
          <Typography className={`${classes.boldText} ${classes.bodyText}`}>Set Up Campaign</Typography>
        </Box>
        <Typography className={classes.bodyText}>
          To get started, create a current/potential campaign, assign it to a brand and add your deliverables and dates.
        </Typography>
        <Box mt='auto' mb={'6px'} display='flex' justifyContent='center'>
          <Button className={classes.button} size='small' onClick={handleNewCollab}>
            {newCollabLoading && <CircularProgress size={16} style={{ marginRight: 8 }} />}Create Campaign
          </Button>
        </Box>
      </Box>
    </TileContainer>
  );
};

const useClasses = makeStyles()((theme) => {
  return {
    button: {
      minWidth: 224,
      backgroundColor: '#ffffff',
      border: `0.5px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main
    },
    boldText: {
      fontWeight: 600
    },
    bodyText: {
      color: '#4B4B4B'
    },
    image: {
      height: '100%'
    }
  };
});
