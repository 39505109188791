import React, { useState, useMemo } from 'react';

import { makeStyles } from 'tss-react/mui';
import { RadioGroup, Radio, FormControlLabel, Grid, TextField, Autocomplete } from '@mui/material';
import moment from 'moment';
import { isEmpty } from 'lodash';

interface ITimeRangePicker {
  startTime: number | null;
  setStartTime: (arg: number | null) => void;
  endTime: number | null;
  setEndTime: (arg: number | null) => void;
  startTimeError?: string;
  endTimeError?: string;
}

const getTimeOptions = () => {
  let timeStops: { [key: string]: number } = {};
  let startTime = moment().startOf('day');
  const endTime = moment().endOf('day');

  while (startTime.isBefore(endTime)) {
    timeStops[startTime.format('h:mma')] = parseInt(startTime.format('Hmm'));
    startTime.add(15, 'minutes');
  }

  return timeStops;
};

const formControlStyles = makeStyles()(() => ({
  label: {
    fontSize: 14,
    fontWeight: 500
  }
}));

const TimeRangeField = ({ startTime, endTime, setStartTime, setEndTime }: ITimeRangePicker) => {
  const { classes: formControlClasses } = formControlStyles();
  const [isRange, setIsRange] = useState(startTime ? 'true' : 'false');

  const timeOptions = useMemo(() => getTimeOptions(), []);
  const timeKeys = Object.keys(timeOptions);
  const timeValues = Object.values(timeOptions);
  const selectedStartIndex = startTime ? timeValues.indexOf(startTime) : -1;
  const selectedEndIndex = endTime ? timeValues.indexOf(endTime) : -1;

  const fromTimeValues = isRange === 'true' && selectedEndIndex > -1 ? timeKeys.slice(0, selectedEndIndex) : timeKeys;
  const toTimeValues =
    isRange === 'true' && selectedStartIndex > -1 ? timeKeys.slice(selectedStartIndex, timeKeys.length - 1) : timeKeys;

  return (
    <Grid container style={{ marginBottom: 15 }}>
      <Grid item xs={12}>
        <RadioGroup
          value={isRange}
          onChange={(event: any) => {
            setIsRange(event?.target?.value);
            setStartTime(null);
          }}
        >
          <Grid container justifyContent='flex-start' direction='row'>
            <FormControlLabel
              value={'false'}
              labelPlacement={'start'}
              classes={formControlClasses}
              style={{ marginLeft: 0 }}
              control={<Radio checked={isRange === 'false'} color='primary' />}
              label='Time'
            />
            <FormControlLabel
              value={'true'}
              labelPlacement={'start'}
              classes={formControlClasses}
              control={<Radio checked={isRange === 'true'} color='primary' />}
              label='Time Range'
            />
          </Grid>
        </RadioGroup>
      </Grid>
      {!isEmpty(timeOptions) && (
        <Grid item xs={12} container spacing={1}>
          {isRange === 'true' && (
            <Grid item xs={6}>
              <Autocomplete
                fullWidth
                options={['', ...fromTimeValues]}
                value={selectedStartIndex ? timeKeys[selectedStartIndex] || '' : ''}
                onChange={(_, key) => setStartTime(key ? timeOptions[key] : null)}
                renderInput={(params) => <TextField {...params} placeholder={'From time'} variant='standard' />}
                onOpen={() => {
                  if (!startTime && fromTimeValues.includes('12:00pm')) {
                    setStartTime(1200);
                  }
                }}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <Autocomplete
              fullWidth
              options={['', ...toTimeValues]}
              value={selectedEndIndex ? timeKeys[selectedEndIndex] || '' : ''}
              onChange={(_, key) => setEndTime(key ? timeOptions[key] : null)}
              renderInput={(params) => (
                <TextField {...params} placeholder={isRange === 'true' ? 'Until time' : 'Time'} variant='standard' />
              )}
              onOpen={() => {
                if (!endTime && toTimeValues.includes('12:00pm')) {
                  setEndTime(1200);
                }
              }}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default TimeRangeField;
