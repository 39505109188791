import React from 'react';
import { useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import StripeCart from './StripeCart';
import Modal from '../Modal';
import TickOutlined from '../../assets/components/TickOutlined';

interface CheckoutProps {
  chosenPlan: CheckoutPlan;
  closeSubscribePopup?: () => void;
}

export interface CheckoutPlan {
  planId: string;
  name: string;
  price: number;
  title: string;
  id: string;
  actionText: string;
  freeTrialDays?: number;
  billingFrequency: number;
  stripeId?: string;
}

const styles = makeStyles()(() => ({
  modalContent: {
    maxWidth: 495
  }
}));

const Checkout = ({ chosenPlan, closeSubscribePopup }: CheckoutProps) => {
  const { classes } = styles();
  const theme = useTheme();

  const primaryColor = theme.palette.primary.main;

  const CheckoutProgress = () => {
    const TickIcon = ({ active }: { active: boolean }) => (
      <div style={{ marginRight: 7 }}>
        <TickOutlined active={active} />
      </div>
    );
    const StepText = ({ active, text }: { active: boolean; text: string }) => (
      <Typography style={{ fontWeight: 500, marginRight: 7, color: active ? primaryColor : '#CCC' }}>{text}</Typography>
    );
    return (
      <div
        style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', flexWrap: 'nowrap', marginBottom: 20 }}
      >
        <TickIcon active={true} />
        <StepText active={true} text={'Cart'} />
        <Divider style={{ width: 119, height: 2, marginRight: 7, backgroundColor: '#D8D8D8' }} />
        <TickIcon active={false} />
        <StepText active={false} text='Payment' />
      </div>
    );
  };

  return (
    <Modal
      isOpen={true}
      onToggle={closeSubscribePopup}
      hideConfirmationButtons={true}
      heading={'Checkout'}
      showCloseIcon={true}
      disableBackdropClick={true}
    >
      <div className={classes.modalContent}>
        <CheckoutProgress />
        <StripeCart userType='influencer' chosenPlan={chosenPlan} />
      </div>
    </Modal>
  );
};

export default Checkout;
