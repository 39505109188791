import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import packageInfo from '../../package.json';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import AppConfig from './appConfig';
import { createUploadLink } from 'apollo-upload-client';
import { customFetch } from 'utils/request.utils';

const httpLink = createUploadLink({
  uri: `${AppConfig.API_URL}graphql`,
  fetch: customFetch
});

const authLink = setContext((_, { headers }) => {
  const localSessionToken = sessionStorage.getItem('auth-token');

  if (localSessionToken) {
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${localSessionToken}`
      }
    };
  }

  return {
    headers
  };
});

const errorLink = onError(({ graphQLErrors, networkError, forward, operation }) => {
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  } else if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );
    forward(operation);
  }
});

export const client = new ApolloClient({
  name: packageInfo.name,
  version: packageInfo.version,
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          myUser: { merge: true }
        }
      },
      User: {
        fields: {
          paymentDetails: { merge: true },
          stripeCustomer: { merge: true }
        }
      }
    }
  })
});
