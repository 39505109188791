import { useMemo, useEffect, createRef, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { lighten, CircularProgress, Grid, Typography, Divider, Box, ButtonBase } from '@mui/material';
import moment from 'moment';
import { isEmpty } from 'lodash';

import InstagramSummary from './InstagramProfile/Summary';
import FacebookSummary from './FacebookProfile/Summary';
import YoutubeSummary from './YoutubeProfile/Summary';
import TiktokSummary from './TiktokProfile/Summary';
import { VerticalInviteBanner } from 'components/Dashboard/ReferAndEarn';
import AppConfig from 'config/appConfig';
import { useGetSingleProfileLazyQuery, MediaKitConfig, SocialChannel } from 'types/generated';
import { useToken } from 'hooks/useAuthHooks';
import Card from 'assets/images/card.png';
import { makeStyles } from 'tss-react/mui';

const pageMaxWidth = 940;
const headerMinHeight = 126;

const styles = makeStyles()((theme) => ({
  container: {
    width: '100%',
    height: `calc(100% - 64px)`,
    minHeight: 800,
    maxWidth: pageMaxWidth,
    padding: 10,
    marginTop: 10,
    position: 'relative'
  },
  navText: {
    color: lighten(theme.palette.common.black, 0.33),
    letterSpacing: '0.5px',
    fontWeight: 500,
    margin: '20px 0'
  },
  title: {
    fontSize: 18,
    marginBottom: 10
  },
  actionButton: {
    marginRight: 6
  },
  scrollHeader: {
    position: 'fixed',
    maxWidth: pageMaxWidth,
    minHeight: headerMinHeight,
    margin: '0 auto',
    top: 64,
    right: 0,
    left: 300,
    zIndex: 1,
    backgroundColor: '#fff',
    [theme.breakpoints.down('sm')]: {
      left: 0
    }
  },
  headerContent: {
    padding: 5,
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  checkIcon: {
    color: '#209E47',
    fontSize: 24,
    marginRight: 5
  },
  addTalentLoader: {
    marginRight: 7
  },
  accountTitle: {
    fontSize: 16
  },
  accountInfo: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    flexGrow: 1
  },
  avatarContainer: {
    position: 'relative',
    marginRight: 25,
    height: 'fit-content'
  },
  avatar: {
    width: 49,
    height: 49
  },
  bioText: {
    fontSize: 12
  },
  channelIcon: {
    height: 16,
    width: 16
  },
  deleteText: {
    fontSize: 16,
    fontWeight: 500,
    margin: '15px 0 25px'
  },
  divider: {
    margin: '10px 0 15px'
  },
  insightsContainer: {
    border: 'none',
    width: '100%',
    backgroundColor: theme.palette.background.default
  },
  mediaKitsContainer: {
    padding: '0 0 10px 20px',
    display: 'flex',
    flexDirection: 'column'
  }
}));

const InfluencerProfile = () => {
  const { classes } = styles();

  const { id: profileId } = useParams<{ id?: string }>();

  const [scrollTop, setScrollTop] = useState(0);

  const [getSingleProfile, { data, loading, error }] = useGetSingleProfileLazyQuery({
    variables: {
      id: profileId
    }
  });

  useEffect(() => {
    if (profileId) {
      getSingleProfile();
    }
  }, [profileId, getSingleProfile]);

  const profile = useMemo(() => (data ? data.getSingleProfile : null), [data]);
  const user = useMemo(() => (profile ? profile.user : null), [profile]);

  const hasMediaKits = useMemo(() => Boolean(profile && !isEmpty(profile.mediaKitConfigs)), [profile]);

  const { token: authToken } = useToken();

  const profileLink = useMemo(() => {
    if (profile) {
      const bearer = authToken;
      const returnString = JSON.stringify({ bearer, profileId });

      return `${AppConfig.MEDIAKIT_URL}/#/insights/${btoa(returnString)}`;
    }
    return;
  }, [profile, authToken, profileId]);

  const scrollingContentContainer = createRef<HTMLDivElement>();
  const insightsContainerRef = createRef<HTMLIFrameElement>();

  const handleSetIframeHeight = useCallback(
    (updatedHeight: number) => {
      if (insightsContainerRef.current && updatedHeight) {
        insightsContainerRef.current.style.height = `${updatedHeight}px`;
      }
    },
    [insightsContainerRef]
  );

  const receiveMessage = useCallback(
    (event: { origin: string; data: { height?: number } }) => {
      if (event.origin !== AppConfig.MEDIAKIT_URL) {
        // Ignore messages from other origins
        return;
      }
      const { height } = event.data;
      if (height) {
        handleSetIframeHeight(height);
      }
    },
    [handleSetIframeHeight]
  );

  const sendScrollTop = useCallback(() => {
    const insightsContainerCurrent = insightsContainerRef.current;
    if (insightsContainerCurrent) {
      if (insightsContainerCurrent.contentWindow)
        insightsContainerCurrent.contentWindow.postMessage({ scrollTop }, '*');
    }
  }, [insightsContainerRef, scrollTop]);

  const updateAndSendScrollOffset = useCallback(() => {
    const currentScrollTop = window.scrollY;
    setScrollTop(currentScrollTop);
    sendScrollTop();
  }, [sendScrollTop]);

  useEffect(() => {
    window.addEventListener('message', receiveMessage);

    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, [receiveMessage]);

  useEffect(() => {
    window.addEventListener('scroll', updateAndSendScrollOffset);

    return () => {
      window.removeEventListener('scroll', updateAndSendScrollOffset);
    };
  }, [scrollingContentContainer, updateAndSendScrollOffset]);

  if (loading) {
    return (
      <Box className={classes.container} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!user || !profile) {
    return (
      <Box className={classes.container} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography>{`No profile found for ${profileId}`}</Typography>
        {error && <Typography>{String(error)}</Typography>}
      </Box>
    );
  }

  return (
    <Grid
      ref={scrollingContentContainer}
      container
      spacing={2}
      direction={'column'}
      className={classes.container}
      wrap='nowrap'
    >
      <Grid item container spacing={2}>
        {profile?.profileType === SocialChannel.Instagram ? (
          <InstagramSummary profile={profile} />
        ) : profile?.profileType === SocialChannel.Facebook ? (
          <FacebookSummary profile={profile} />
        ) : profile?.profileType === SocialChannel.Youtube ? (
          <YoutubeSummary profile={profile} />
        ) : profile?.profileType === SocialChannel.Tiktok ? (
          <TiktokSummary profile={profile} />
        ) : null}
      </Grid>
      <Divider className={classes.divider} />
      <Grid item container direction={'row'} style={{ flex: 1, flexWrap: 'nowrap' }}>
        <Grid item style={{ width: '100%' }}>
          {profileLink && (
            <iframe
              ref={insightsContainerRef}
              title='Influencer Profile'
              src={profileLink}
              className={classes.insightsContainer}
            />
          )}
        </Grid>
        <Grid item className={classes.mediaKitsContainer}>
          {hasMediaKits ? (
            <>
              <Typography className={classes.title}>Media kits</Typography>
              {profile?.mediaKitConfigs?.map((o, i) => {
                if (!o) return null;
                return <MediaKitTile key={`tile${i}-${o._id}`} config={o} />;
              })}
            </>
          ) : (
            <div>
              <VerticalInviteBanner />
            </div>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const kitStyles = makeStyles()((theme) => ({
  container: {
    marginBottom: 10,
    width: 180,
    height: 180,
    position: 'relative',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.primary.light
  },
  textContainer: {
    padding: 6,
    position: 'absolute',
    backgroundColor: '#f7f7fa9e',
    bottom: 0,
    left: 0,
    right: 0
  },
  kitTitle: {
    color: '#333333',
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'Open Sans'
  }
}));

interface MediaKitTileProps {
  config: MediaKitConfig;
}

const MediaKitTile = ({ config }: MediaKitTileProps) => {
  const { classes } = kitStyles();
  const { _id, headerImage, title, colorTheme, created_at } = config;
  const displayImage = headerImage || Card;
  return (
    <ButtonBase
      href={`${AppConfig.MEDIAKIT_URL}/#/view/${_id}`}
      target='_blank'
      rel='noopener noreferrer'
      className={classes.container}
      style={{ backgroundImage: `url(${displayImage})`, backgroundSize: headerImage ? 'cover' : 'contain' }}
    >
      <Box className={classes.textContainer}>
        <Typography className={classes.kitTitle}>{title || colorTheme}</Typography>
        {created_at && <Typography>{`Created ${moment(created_at).format("Do MMM 'YY")}`}</Typography>}
      </Box>
    </ButtonBase>
  );
};

export default InfluencerProfile;
