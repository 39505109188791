import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import Menu, { MenuProps } from '@mui/material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SubscribePopup from '../subscribe/SubscribePopup';

import SettingsIcon from 'assets/icons/SettingsIcon';
import TagIcon from 'assets/icons/TagIcon';
import LogoutIcon from 'assets/icons/LogoutIcon';
import {
  Dialog,
  Typography,
  Button,
  DialogActions,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  useTheme,
  Theme,
  lighten,
  MenuItemProps
} from '@mui/material';
import { COLORS } from 'lib/constants';
import { useDialog } from 'hooks/useDialog';

const styles = makeStyles()((theme: Theme) => ({
  dropdownIcon: {
    width: 25,
    height: 25,
    color: theme.palette.common.black
  },
  menuIconContainer: {
    minWidth: 26,
    marginRight: 5
  },
  menuIcon: {
    height: 16
  },
  textContainer: {
    lineHeight: 1.3,
    padding: '0 5px'
  },
  text: {
    fontSize: 12,
    padding: 0,
    margin: 0,
    color: theme.palette.common.black
  },
  listText: {
    fontSize: 12,
    '& > span': {
      color: lighten(theme.palette.common.black, 0.2)
    }
  }
}));

const CustomMenuItem = (props: MenuItemProps) => {
  const { classes } = menuItemStyles();
  return (
    <MenuItem {...props} classes={{ root: classes.menuItem }}>
      {props.children}
    </MenuItem>
  );
};

const menuItemStyles = makeStyles()((theme) => ({
  menuItem: {
    '&:hover': {
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.primary.main
      }
    }
  }
}));

const CustomMenu = (props: MenuProps) => {
  const { classes } = customMenuStyles();
  return (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      {...props}
      classes={{ paper: classes.paperStyles }}
    >
      {props.children}
    </Menu>
  );
};

const customMenuStyles = makeStyles()({
  paperStyles: {
    border: '1px solid #d3d4d5'
  }
});

const StyledMenu = (props: any): JSX.Element => {
  const theme = useTheme();
  const { classes } = styles();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isSubscribeOpen, setIsSubscribeOpen] = useState(false);
  const { bind, onOpen } = useDialog();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);
  const handleMembership = () => {
    setAnchorEl(null);
    setIsSubscribeOpen(true);
  };

  const handleLogout = () => {
    onOpen();
    setAnchorEl(null);
  };

  const renderMenuContent = () => {
    return (
      <CustomMenu
        id='custom-user-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        autoFocus={false}
      >
        <CustomMenuItem onClick={() => navigate('/settings')}>
          <ListItemIcon className={classes.menuIconContainer}>
            <SettingsIcon stroke={theme.palette.primary.main} />
          </ListItemIcon>
          <ListItemText primary='Settings' classes={{ root: classes.listText }} className={classes.listText} />
        </CustomMenuItem>
        <CustomMenuItem onClick={handleMembership}>
          <ListItemIcon className={classes.menuIconContainer}>
            <TagIcon stroke={theme.palette.primary.main} />
          </ListItemIcon>
          <ListItemText primary='Subscription' classes={{ root: classes.listText }} className={classes.listText} />
        </CustomMenuItem>
        <CustomMenuItem onClick={handleLogout}>
          <ListItemIcon className={classes.menuIconContainer}>
            <LogoutIcon stroke={theme.palette.primary.main} />
          </ListItemIcon>
          <ListItemText primary='Logout' classes={{ root: classes.listText }} className={classes.listText} />
        </CustomMenuItem>
      </CustomMenu>
    );
  };
  return (
    <div>
      <IconButton aria-label='toggle user panel options' onClick={handleClick}>
        <ArrowDropDownIcon className={classes.dropdownIcon} />
      </IconButton>
      {renderMenuContent()}
      {isSubscribeOpen && <SubscribePopup closeSubscribePopup={() => setIsSubscribeOpen(false)} />}
      <LogOutDialog {...bind} />
    </div>
  );
};

export default StyledMenu;

interface LogOutDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const LogOutDialog = (props: LogOutDialogProps) => {
  const { classes } = useStyles();
  const { isOpen, onClose } = props;
  const navigate = useNavigate();

  const handleConfirm = () => {
    navigate('/logout');
  };

  return (
    <Dialog
      classes={{ paper: classes.logoutDialogPaper }}
      open={isOpen}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <Typography variant='h5' align='center'>
        Are you sure you want to log out?
      </Typography>
      <DialogActions classes={{ root: classes.dialogActionsRoot }}>
        <Button
          onClick={handleConfirm}
          variant='contained'
          className={`${classes.dialogActionButtons} ${classes.dialogConfirmButton}`}
        >
          Yes
        </Button>
        <Button onClick={onClose} size='medium' variant='outlined' className={classes.dialogActionButtons}>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles()((theme) => ({
  logoutDialogPaper: {
    width: 420,
    display: 'flex',
    justifyContent: 'center',
    borderColor: COLORS.MAROON,
    padding: '44px 29px 46px'
  },
  dialogActionsRoot: {
    marginTop: 46,
    display: 'flex',
    justifyContent: 'center',
    columnGap: 18
  },
  dialogActionButtons: {
    minWidth: 110,
    borderColor: COLORS.MAROON
  },
  dialogConfirmButton: {
    backgroundColor: COLORS.MAROON
  }
}));
