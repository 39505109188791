import React from 'react';
import { Box, Grid } from '@mui/material';
import { useGetSingleProfileQuery } from 'types/generated';
import { Loader } from 'components/Loader';
import MediaKitCard from 'components/Dashboard/Cards/MediaKitCard';
import PerformanceBarChart from './PerformanceBarChart';

import FollowersIcon from 'assets/images/followers.svg';
import ReachIcon from 'assets/images/reach.svg';
import EngagementIcon from 'assets/images/engagement.svg';

const TiktokCardInsights = (props: { profileId: string }) => {
  const { data, error, loading } = useGetSingleProfileQuery({ variables: { _id: props.profileId } });

  let performanceRows: any[] = [];

  if (data?.getSingleProfile?.public?.stats?.followerCount)
    performanceRows.push({
      name: 'Followers',
      value: data.getSingleProfile.public.stats.followerCount,
      icon: FollowersIcon
    });
  if (data?.getSingleProfile?.tiktokMediaStats?.view_count?.median)
    performanceRows.push({
      name: 'Median Plays',
      value: data.getSingleProfile.tiktokMediaStats.view_count.median,
      icon: ReachIcon
    });

  if (data?.getSingleProfile?.tiktokMediaStats?.totalEngagement?.median)
    performanceRows.push({
      name: 'Engagement',
      value: data.getSingleProfile.tiktokMediaStats.totalEngagement.median,
      percentageValue: data.getSingleProfile.tiktokMediaStats.totalEngagement_percentageOfPlays?.median,
      icon: EngagementIcon
    });

  // LOADING COMPONENT
  const loading_component = (
    <>
      {loading && (
        <Box display='flex' justifyContent='center' alignItems='center' mt={4}>
          <Loader />
        </Box>
      )}
    </>
  );

  return (
    <div>
      {loading_component}
      {(!loading || Boolean(error)) && (
        <>
          <Grid container>
            <Grid item sm={7} xs={12}>
              {
                // account performance
              }
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box className={'insights-container'}>
                      <PerformanceBarChart data={performanceRows} />
                    </Box>
                  </Grid>
                </Grid>
              </>
            </Grid>
            <Grid item sm={4} xs={12}>
              <MediaKitCard />
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

export default TiktokCardInsights;
