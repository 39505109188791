import find from 'lodash/find';
import moment from 'moment';
import ReactCalendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useCalendarStyles } from 'styles/calendar';

interface Props {
  deliverableList: any;
  selectedDate: string;
  setSelectedDate: (arg: string) => void;
}

export const Calendar = (props: Props) => {
  const { classes } = useCalendarStyles();
  const { deliverableList, selectedDate, setSelectedDate } = props;
  return (
    <div className={classes.container}>
      <ReactCalendar
        onClickDay={(date: any) => {
          const formatted = moment(date).format('YYYY-MM-DD');
          setSelectedDate(formatted);
        }}
        tileDisabled={({ date, view }: any) => {
          const formatted = moment(date).format('YYYY-MM-DD');
          const isDeliverableDue = find(deliverableList, { endDate: formatted });
          if (view === 'month' && !isDeliverableDue) {
            return true;
          } else {
            return false;
          }
        }}
        tileClassName={({ date, view }: any) => {
          let dayStyling = `${classes.allDates}`;
          const now = moment().format('YYYY-MM-DD');
          const formatted = moment(date).format('YYYY-MM-DD');
          const isPastDate = moment(date).isBefore(moment(now));
          const isDeliverableDue = find(deliverableList, { endDate: formatted });
          const isTileActive = Boolean(formatted === selectedDate);
          if (view === 'month') {
            if (isPastDate) dayStyling = `${dayStyling} ${classes.pastDate}`;
            if (isDeliverableDue) dayStyling = `${dayStyling} ${classes.deliverableDate}`;
            if (isTileActive) dayStyling = `${dayStyling} ${classes.activeDate}`;
          }
          return dayStyling;
        }}
      />
    </div>
  );
};
