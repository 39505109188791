import { Box } from '@mui/material';
import React from 'react';

interface IMediaKitCard {
  imageSrc?: string;
  title?: string;
  createdAt?: Date | string;
  width?: number | string;
}
const MediaKitCard: React.FC<IMediaKitCard> = (props) => {
  return <Box></Box>;
};

MediaKitCard.defaultProps = {
  width: '175px'
};

export default MediaKitCard;
