import { useState, useEffect, useCallback } from 'react';
import packageJson from '../../package.json';

interface VersionInfoProps {
  theme: {
    palette: {
      primary: {
        main: string;
      };
    };
  };
  fontSize?: string | number | undefined;
}

const VersionInfo = (props: VersionInfoProps) => {
  const [isPressed, setIsPressed] = useState(false);
  const qPress = useKeyPress('q');

  const pokemonNumber = padNumber(getWholeNumberFromVersion(packageJson.version), 3);

  const primaryColor = props.theme?.palette.primary.main || 'inherit';

  const showPokemon = Boolean(isPressed && qPress);

  return (
    <div
      onTouchStart={() => {
        setIsPressed(true);
      }}
      onTouchEnd={() => {
        setIsPressed(false);
      }}
      onMouseDown={() => {
        setIsPressed(true);
      }}
      onMouseUp={() => {
        setIsPressed(false);
      }}
      onMouseLeave={() => {
        setIsPressed(false);
      }}
      style={{ height: 30, width: '100%', textAlign: 'center', overflow: 'visible' }}
    >
      <img
        style={{ display: showPokemon ? 'block' : 'none' }}
        alt='version_image'
        src={`https://media.q-83.com/pokesprites/${pokemonNumber}MS.png`}
      />
      <div
        style={{
          color: 'rgb(111, 120, 136)',
          fontSize: Boolean(props && props.fontSize) ? props.fontSize : 12,
          display: showPokemon ? 'none' : 'block'
        }}
      >
        <div>
          Brought to you by <span style={{ color: primaryColor, fontWeight: 500 }}>Q-83</span>
        </div>
        {getVersionNumber()}
      </div>
    </div>
  );
};

const getWholeNumberFromVersion = (version: string) => {
  let returnNumber = 0;

  version.split('.').forEach((o, i) => {
    let number = parseInt(o);
    if (typeof number === 'number') {
      switch (i) {
        case 0:
          returnNumber += number * 100;
          break;
        case 1:
          returnNumber += number * 10;
          break;
        default:
          returnNumber += number;
          break;
      }
    }
  });

  return returnNumber % 809;
};

const padNumber = (number: number, digits: number) => {
  return String(number).padStart(digits, '0');
};

export const getVersionNumber = () => {
  return `${packageJson.version}`;
};

function useKeyPress(targetKey: string) {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false);

  // If pressed key is our target key then set to true
  const downHandler = useCallback(
    ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(true);
      }
    },
    [targetKey]
  );

  // If released key is our target key then set to false
  const upHandler = useCallback(
    ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(false);
      }
    },
    [targetKey]
  );

  // Add event listeners
  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, [downHandler, upHandler]); // Empty array ensures that effect is only run on mount and unmount

  return keyPressed;
}

export default VersionInfo;
