import React, { useState } from 'react';
import ButtonBase from '@mui/material/ButtonBase';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import Typography from '@mui/material/Typography';
import EditIcon from 'assets/icons/EditIcon.svg';
import Modal from 'components/Modal';
import useTracking from 'context/useTrackingCtx';
import { formatError } from 'lib/formatStrings';

import {
  GetCollaborationListDocument,
  GetCompanyContactListDocument,
  useDeleteCollaborationMutation,
  GetMyUserUsageDocument,
  Collaboration
} from 'types/generated';
import { CollabCreation } from './CollabCreation';
import { InvoiceCreation } from '../Finance/InvoiceCreation';

interface Props {
  collab: Collaboration;
  collabId: string;
}

export const CollabEditMenu = (props: Props) => {
  const { collab, collabId } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteCollaboration, { loading: deleteLoading, error: deleteError }] = useDeleteCollaborationMutation({
    refetchQueries: [
      { query: GetCollaborationListDocument },
      { query: GetCompanyContactListDocument },
      {
        query: GetMyUserUsageDocument
      }
    ],
    awaitRefetchQueries: true
  });
  const { trackEvent } = useTracking();

  const deleteErrorMessage = deleteError ? formatError(deleteError) : '';

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  const toggleDeleteModal = () => {
    setDeleteModalOpen(!deleteModalOpen);
    if (anchorEl) closeMenu();
  };

  const deleteCollab = () => {
    deleteCollaboration({ variables: { id: collabId } }).then(() => {
      trackEvent('campaigns', 'deleted campaign');
      toggleDeleteModal();
    });
  };

  const DeleteModal = () => (
    <Modal
      isOpen={deleteModalOpen}
      onToggle={toggleDeleteModal}
      onConfirm={deleteCollab}
      isLoadingConfirm={deleteLoading}
      confirmLabel={'Delete'}
      errorMessage={deleteErrorMessage}
      showErrorMessage={Boolean(deleteError)}
      heading={'Delete campaign'}
      maxWidth={'sm'}
    >
      <Typography style={{ padding: '10px 0 20px' }}>
        Are you sure you want to delete your {collab?.name} collaboration?
      </Typography>
    </Modal>
  );

  const MenuOptions = () => (
    <Menu id='long-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={closeMenu}>
      {collab.canEdit && (
        <CollabCreation editableCollab={collab} toggleModal={closeMenu} size={14}>
          <MenuItem style={{ width: '100%' }}>Edit</MenuItem>
        </CollabCreation>
      )}
      <InvoiceCreation
        collabForInvoice={collab}
        toggleModal={() => {
          closeMenu();
        }}
        size={14}
      >
        <MenuItem style={{ width: '100%' }}>Generate Invoice</MenuItem>
      </InvoiceCreation>
      {collab.canEdit && <MenuItem onClick={toggleDeleteModal}>Delete</MenuItem>}
    </Menu>
  );

  return (
    <>
      {anchorEl && <MenuOptions />}
      {deleteModalOpen && <DeleteModal />}
      <ButtonBase onClick={(event) => openMenu(event)} style={{ borderRadius: '100%' }}>
        <img src={EditIcon} alt='Edit' style={{ fontSize: 22 }} />
      </ButtonBase>
    </>
  );
};
