import { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { Avatar, Grid, Link, Table, TableBody, Typography, Button, Badge, Box, Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { compact, filter, uniq } from 'lodash';

import { SocialChannel, useMyUserProfilesQuery } from 'types/generated';
import { useErrorHandlerHook } from 'hooks/useErrorCatchHook';

import { getInitials } from 'lib/formatStrings';
import { channelIcons } from 'lib/constants';
import CopyableText from 'components/CopyableText';
import { Loader } from 'components/Loader';
import ShareAuditReportModal from './ShareAuditReportModal';
import { StyledHeader, StyledTableCell, StyledTableRow } from 'components/Styled/StyledTableComponents';

const backupUserImg = 'https://media.q-83.com/site/user.png';

const columns = [
  { id: 'profile', numeric: false, disablePadding: false, label: 'Profile' },
  { id: 'location', numeric: false, disablePadding: false, label: 'Location' },
  { id: 'channel', numeric: false, disablePadding: false, label: 'Channels', style: { textAlign: 'center' } },
  { id: 'link', numeric: false, disablePadding: false, label: 'Report Link', style: { paddingLeft: 20 } },
  { id: 'buttons', numeric: false, disablePadding: false, label: '' }
];

const AuditReports = () => {
  const { classes } = useStyles();
  const badgeClasses = badgeStyles();
  const { apolloHandler } = useErrorHandlerHook();

  const [selectedAparLink, setSelectedAparLink] = useState<string>();

  const { data, loading, error } = useMyUserProfilesQuery();

  const user = useMemo(() => data?.myUser, [data]);

  const profiles = useMemo(() => compact(filter(user?.profiles, 'isAddedByOwner')), [user]);
  const channels = uniq(compact(profiles.map((o) => o?.profileType?.toLowerCase())));
  const userFullName = `${user?.firstName} ${user?.lastName}`;
  const userInitials = getInitials({
    firstInput: user?.firstName || '',
    secondInput: user?.lastName || ''
  });

  const userTimezoneComponents = user?.timezone?.split('/');
  const userLocation =
    userTimezoneComponents?.length && userTimezoneComponents.length > 1 ? userTimezoneComponents[1] : undefined;
  const userLink = user?.userAuditReportLink || '';

  useEffect(() => {
    if (error) {
      apolloHandler(error);
    }
  }, [error, apolloHandler]);

  if (loading) return <Loader containerHeight={250} />;

  const profileRows: JSX.Element[] = profiles.map((p, i) => {
    const profileDisplayName = p?.displayName || 'Unnamed';
    const profilePictureUrl = p?.profile_picture_url || backupUserImg;
    const profileLink = p?.profileAuditReportLink || '';
    return (
      <StyledTableRow key={`profile-row-${i}`}>
        <StyledTableCell className={i !== profiles.length - 1 ? classes.noBorderBottom : undefined}></StyledTableCell>
        <StyledTableCell className={i !== profiles.length - 1 ? classes.noBorderBottom : undefined}></StyledTableCell>
        <StyledTableCell align='center'>
          <Tooltip title={profileDisplayName}>
            <Badge
              classes={badgeClasses}
              overlap='circular'
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              badgeContent={
                <Box className={classes.badgeIcon}>
                  {p.profileType === SocialChannel.Instagram && (
                    <img src={channelIcons.instagram} alt='instagram' className={classes.badgeImage} />
                  )}
                  {p.profileType === SocialChannel.Youtube && (
                    <img src={channelIcons.youtube} alt='youtube' className={classes.badgeImage} />
                  )}
                  {p.profileType === SocialChannel.Facebook && (
                    <img src={channelIcons.facebook} alt='facebook' className={classes.badgeImage} />
                  )}
                  {p.profileType === SocialChannel.Tiktok && (
                    <img src={channelIcons.tiktok} alt='tiktok' className={classes.badgeImage} />
                  )}
                </Box>
              }
            >
              <ProfileAvatar
                profileDisplayName={profileDisplayName}
                profilePictureUrl={profilePictureUrl}
                profileUserInitials={userInitials}
              />
            </Badge>
          </Tooltip>
        </StyledTableCell>
        <StyledTableCell>
          {profileLink && (
            <Grid container>
              <Grid item style={{ width: 160 }}>
                <Link href={profileLink} target='_blank' underline='hover'>
                  {profileLink}
                </Link>
              </Grid>
              <Grid item style={{ marginLeft: 5 }}>
                <CopyableText text={profileLink} />
              </Grid>
            </Grid>
          )}
        </StyledTableCell>
        <StyledTableCell align='right'>
          <ShareAuditReportModal
            aparLink={selectedAparLink}
            profileId={p._id}
            onComplete={() => setSelectedAparLink(undefined)}
          >
            <Button
              size='small'
              color='primary'
              variant='outlined'
              className={classes.shareButton}
              onClick={() => setSelectedAparLink(profileLink)}
            >
              Share
            </Button>
          </ShareAuditReportModal>
        </StyledTableCell>
      </StyledTableRow>
    );
  });

  return (
    <Grid container>
      <Grid item container justifyContent='space-between' className={classes.container}>
        <Grid item>
          <Typography variant='h4'>{`${profiles.length} Profiles`}</Typography>
        </Grid>
      </Grid>
      <Table>
        <StyledHeader children={columns} />
        <TableBody>
          <StyledTableRow>
            <StyledTableCell className={classes.noBorderBottom}>
              <Grid container alignItems='center'>
                <Avatar alt={userFullName} src={user?.avatar || backupUserImg} className={classes.avatar}>
                  {userInitials}
                </Avatar>
                <Typography>{userFullName}</Typography>
              </Grid>
            </StyledTableCell>
            <StyledTableCell className={classes.noBorderBottom}>{userLocation}</StyledTableCell>
            <StyledTableCell align='center'>
              <Typography>
                {channels.map((channel, index) => (
                  <img
                    alt={`${channel} logo`}
                    key={`${channel} logo ${index}`}
                    src={channelIcons[channel as keyof typeof channelIcons]}
                    className={classes.image}
                  />
                ))}
              </Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Grid container>
                <Grid item style={{ width: 160 }}>
                  <Link href={userLink} target='_blank' underline='hover'>
                    {userLink}
                  </Link>
                </Grid>
                <Grid item style={{ marginLeft: 5 }}>
                  <CopyableText text={userLink} />
                </Grid>
              </Grid>
            </StyledTableCell>
            <StyledTableCell align='right'>
              <ShareAuditReportModal aparLink={selectedAparLink} onComplete={() => setSelectedAparLink(undefined)}>
                <Button
                  size='small'
                  color='primary'
                  variant='outlined'
                  className={classes.shareButton}
                  onClick={() => setSelectedAparLink(userLink)}
                >
                  Share
                </Button>
              </ShareAuditReportModal>
            </StyledTableCell>
          </StyledTableRow>
          {profileRows}
        </TableBody>
      </Table>
    </Grid>
  );
};

export default AuditReports;

interface IProfileAvatar {
  profileDisplayName: string;
  profilePictureUrl: string;
  profileUserInitials: string;
}

const ProfileAvatar = ({ profileDisplayName, profilePictureUrl, profileUserInitials }: IProfileAvatar) => {
  const [avatarSrc, setAvatarSrc] = useState<string>(profilePictureUrl);

  return (
    <Avatar
      src={avatarSrc}
      alt={profileDisplayName}
      onError={(e: SyntheticEvent) => {
        // tslint:disable-line
        e.persist();
        if (avatarSrc !== backupUserImg) {
          setAvatarSrc(backupUserImg);
        }
      }}
      style={{ width: '20px', height: '20px', margin: '0 5px' }}
    >
      {profileUserInitials}
    </Avatar>
  );
};

const useStyles = makeStyles()((theme) => {
  const { spacing } = theme;
  return {
    avatar: {
      marginRight: spacing(2),
      height: 24,
      width: 24
    },
    image: {
      width: 18,
      height: 18,
      marginRight: spacing(2)
    },
    expansion: {
      border: 'none',
      boxShadow: 'none',
      margin: 0,
      backgroundColor: 'transparent',
      padding: 0
    },
    noBorderBottom: {
      borderBottom: 'none'
    },
    container: {
      marginTop: spacing(4),
      marginBottom: spacing(4),
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'space-between'
    },
    shareButton: {
      marginRight: spacing(2)
    },
    badgeIcon: {
      width: 20,
      height: 20,
      borderRadius: '50%',
      padding: 3,
      border: `1px solid ${theme.palette.background.default}`,
      backgroundColor: '#fff'
    },
    badgeImage: {
      width: 10,
      height: 10
    }
  };
});

const badgeStyles = makeStyles()({
  root: {
    width: 30,
    height: 30
  },

  badge: {
    bottom: 0,
    left: 25,
    transform: 'translateX(-50%) translateY(0)',
    padding: 0
  }
});
