import React, { useState } from 'react';
import { useTheme } from '@mui/material';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import CircularProgress from '@mui/material/CircularProgress';

import isEmpty from 'lodash/isEmpty';

import StripePaymentPopup from './StripePaymentPopup';
import { formatError } from '../../lib/formatStrings';
import EditIcon from '../../assets/icons/EditIcon.svg';

import {
  useGetUserPaymentHistoryQuery,
  useDeletePaymentMethodMutation,
  useSetPaymentMethodToDefaultMutation,
  StripePaymentMethod,
  DeletePaymentMethodMutationFn,
  SetPaymentMethodToDefaultMutationFn
} from '../../types/generated';

interface PaymentMethodMenuProps {
  paymentMethod?: StripePaymentMethod | null;
  setShowPaymentPopup: (args: boolean) => void;
  makeDefaultPaymentMethod?: SetPaymentMethodToDefaultMutationFn;
  deletePaymentMethod?: DeletePaymentMethodMutationFn;
}

const pmBrandDisplay = {
  visa: 'VISA',
  amex: 'AMEX',
  jcb: 'JCB',
  diners: 'Diners Club',
  mastercard: 'MasterCard',
  unionpay: 'UnionPay',
  discover: 'Discover',
  unknown: ''
};

const PaymentMethods = (): JSX.Element => {
  const theme = useTheme();

  const [showPaymentPopup, setShowPaymentPopup] = useState<boolean>(false);

  const {
    data: userPaymentMethodsData,
    loading: paymentMethodDataLoading,
    error: paymentMethodDataError,
    refetch: refetchPaymentMethods
  } = useGetUserPaymentHistoryQuery({ fetchPolicy: 'network-only' });

  const myUser = userPaymentMethodsData?.myUser;
  const userPaymentMethods = myUser?.stripeCustomer?.cards || [];
  const defaultPaymentMethod = myUser?.stripeCustomer?.defaultPaymentMethod;

  const [makeDefaultPaymentMethod, { loading: makeDefaultMutationLoading, error: makeDefaultMutationError }] =
    useSetPaymentMethodToDefaultMutation({
      onCompleted: () => refetchPaymentMethods()
    });
  const [
    deletePaymentMethod,
    { loading: deletePaymentMethodMutationLoading, error: deletePaymentMethodMutationError }
  ] = useDeletePaymentMethodMutation({
    onCompleted: () => refetchPaymentMethods()
  });

  const loading = paymentMethodDataLoading || makeDefaultMutationLoading || deletePaymentMethodMutationLoading;
  const error = paymentMethodDataError || makeDefaultMutationError || deletePaymentMethodMutationError;

  const numberOfPaymentMethods = userPaymentMethods?.length || 0;

  if (loading) {
    return (
      <Grid container justifyContent='center' alignItems='center' style={{ height: 60 }}>
        <Grid>
          <CircularProgress style={{ color: theme.palette.primary.main }} />
        </Grid>
      </Grid>
    );
  }

  if (isEmpty(userPaymentMethodsData)) {
    return (
      <Grid container direction='column'>
        <Grid item>No payment methods found</Grid>
        {error && (
          <Grid item style={{ color: '#F00' }}>
            {formatError(error)}
          </Grid>
        )}
      </Grid>
    );
  }

  return (
    <Grid container direction='column'>
      {userPaymentMethods.map((pm, i) => {
        return (
          <Grid item container alignItems='center' key={`payment-method-${i}`} style={{ marginBottom: 10 }}>
            <Grid item container alignItems='center' justifyContent='space-between' xs={8}>
              <Grid item>
                <Typography>{pm?.card?.brand ? pmBrandDisplay[pm.card.brand] || '' : ''}</Typography>
              </Grid>
              <Grid item>{`Card ending in ${pm?.card?.last4}.`}</Grid>
              <Grid item>{`Exp ${pm?.card?.exp_month}/${pm?.card?.exp_year}`}</Grid>
            </Grid>
            <Grid item xs={2}>
              <Typography align='center'>{pm?.id === defaultPaymentMethod && 'Default'}</Typography>
            </Grid>
            <Grid item container justifyContent='flex-end' xs={2}>
              <Grid item style={{ marginLeft: 30 }}>
                <PaymentMethodMenu
                  paymentMethod={pm}
                  setShowPaymentPopup={setShowPaymentPopup}
                  makeDefaultPaymentMethod={makeDefaultPaymentMethod}
                  deletePaymentMethod={numberOfPaymentMethods > 1 ? deletePaymentMethod : undefined}
                />
              </Grid>
            </Grid>
          </Grid>
        );
      })}
      <Grid item style={{ marginBottom: 30 }}>
        <Button
          data-cy='edit-payment-button'
          onClick={() => setShowPaymentPopup(true)}
          color='primary'
          variant='outlined'
        >
          Add a New Card
        </Button>
      </Grid>
      <StripePaymentPopup
        open={showPaymentPopup}
        handlePopup={() => {
          setShowPaymentPopup(!showPaymentPopup);
        }}
        refetch={refetchPaymentMethods}
      />
    </Grid>
  );
};

const PaymentMethodMenu = (props: PaymentMethodMenuProps): JSX.Element => {
  const { paymentMethod, makeDefaultPaymentMethod, deletePaymentMethod } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  return (
    <>
      <ButtonBase onClick={openMenu}>
        <img src={EditIcon} alt='Edit payment options menu' />
      </ButtonBase>
      <Menu open={Boolean(anchorEl)} id='edit-payment-method-menu' anchorEl={anchorEl} keepMounted onClose={closeMenu}>
        <MenuItem
          data-cy={`edit-payment-method`}
          onClick={() => {
            if (makeDefaultPaymentMethod && paymentMethod?.id) {
              makeDefaultPaymentMethod({ variables: { id: paymentMethod.id } });
            }
            closeMenu();
          }}
        >
          Make Default
        </MenuItem>
        {Boolean(deletePaymentMethod && paymentMethod?.id) && (
          <MenuItem
            data-cy={`edit-payment-method`}
            onClick={() => {
              deletePaymentMethod!({ variables: { id: paymentMethod!.id } });
              closeMenu();
            }}
          >
            Delete
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default PaymentMethods;
