import React from 'react';
import { useTheme } from '@mui/material';

const CheckBox = ({ size }: { size: number }) => {
  const theme = useTheme();

  const sizeInPixels = `${size}px`;

  return (
    <svg
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox={`0 0 ${size} ${size}`}
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>15C190F6-718D-46C3-BC47-64B8228B9E4F@1x</title>
      <g id='6.-Talent-Management' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='6.-Talent-Management/Share' transform='translate(-519.000000, -459.000000)'>
          <g id='Popup' transform='translate(445.000000, 147.000000)'>
            <g id='Icon/General/Checkbox-tick' transform='translate(74.000000, 312.206522)'>
              <rect id='Rectangle' fill={theme.palette.primary.main} x='0' y='0' width='18' height='18' rx='4'></rect>
              <g
                id='Icon/Check'
                transform='translate(5.000000, 5.000000)'
                stroke='#FFFFFF'
                strokeLinecap='round'
                strokeLinejoin='round'
              >
                <g id='check' transform='translate(0.000000, 0.888889)'>
                  <polyline id='Path' points='0 4.14814815 2.18181818 6.22222222 8 0'></polyline>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CheckBox;
