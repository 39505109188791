import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Modal from 'components/Modal';
import { formatError } from 'lib/formatStrings';
import { useSnackbar } from 'notistack';
import { SNACK_BAR_MESSAGES } from 'lib/snackbarMessages';
import { useUpdateMediaKitConfigMutation } from 'types/generated';

interface ComponentProps {
  mediaKitId: string | null;
  mediaKitName: string | null;
  isOpen: boolean;
  toggleModal: () => void;
  refetchKits: () => void;
}

const defaultTitle = 'My Media Kit';

const NameMediaKitModal = (props: ComponentProps) => {
  const { mediaKitId, mediaKitName, isOpen, toggleModal, refetchKits } = props;
  console.log('mediaKitId', mediaKitId);
  const [title, setTitle] = useState(mediaKitName || defaultTitle);
  const { enqueueSnackbar } = useSnackbar();
  const [updateMediaKitConfig, { loading, error }] = useUpdateMediaKitConfigMutation({
    onCompleted: () => enqueueSnackbar(SNACK_BAR_MESSAGES.MEDIA_KIT_UPDATED)
  });
  const updateMediaKitTitle = () => {
    const newTitle = title || defaultTitle;
    if (mediaKitId) {
      updateMediaKitConfig({
        variables: {
          id: mediaKitId,
          updates: { title: newTitle }
        }
      })
        .then(() => {
          toggleModal();
          refetchKits();
        })
        .catch((err) => {
          console.log('media kit name error:', err);
        });
    } else {
      enqueueSnackbar('Media kit id not found');
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onToggle={toggleModal}
      onConfirm={updateMediaKitTitle}
      isLoadingConfirm={loading}
      confirmLabel={'Save'}
      errorMessage={formatError(error)}
      showErrorMessage={Boolean(error)}
      heading={`${mediaKitName ? 'Rename ' : 'Name '}your media kit`}
    >
      <TextField
        variant='standard'
        label={'Name'}
        placeholder={defaultTitle}
        style={{ minWidth: 400, marginBottom: 30 }}
        fullWidth={true}
        margin='normal'
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
    </Modal>
  );
};

export default NameMediaKitModal;
