import { User } from '../types/generated';
import axios from 'axios';

export const klaviyoTrack = async (user: User, name: string, properties?: any) => {
  // Adapted from:
  // https://developers.klaviyo.com/en/v1-2/reference/track-post

  try {
    const options = {
      headers: { accept: 'text/html', 'content-type': 'application/x-www-form-urlencoded' }
    };

    axios.post(
      'https://a.klaviyo.com/api/track',
      new URLSearchParams({
        data: `{"token": "LR2nK5", "event": "${name}", "customer_properties": {"$email": "${
          user.email
        }"}, "properties": ${JSON.stringify(properties)}}`
      }),
      options
    );
  } catch (ex) {
    console.error(`klaviyoTrack ERROR`, ex);
  }
};
