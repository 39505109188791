import React, { useState, Dispatch, SetStateAction } from 'react';
import { ButtonBase, Menu, MenuItem } from '@mui/material';

import EditIcon from '../../../assets/icons/EditIcon.svg';

import { Profile } from '../../../types/generated';

interface TalentNetworkEditMenuProps {
  talent: Profile;
  selectedTalent?: Profile;
  setSelectedTalent: Dispatch<SetStateAction<Profile | undefined>>;
}

const TalentNetworkEditMenu = (props: TalentNetworkEditMenuProps) => {
  const { talent, selectedTalent, setSelectedTalent } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    if (selectedTalent) setSelectedTalent(undefined);
    setAnchorEl(null);
  };

  const toggleCreateModal = () => {
    setSelectedTalent(selectedTalent ? undefined : talent);
    if (anchorEl) closeMenu();
  };

  const MenuOptions = () => (
    <Menu id='long-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={closeMenu}>
      <MenuItem onClick={toggleCreateModal}>
        <div style={{ width: '100%' }}>Edit Categories</div>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      {anchorEl && <MenuOptions />}
      <ButtonBase onClick={(event) => openMenu(event)}>
        <img src={EditIcon} alt='Edit' style={{ fontSize: 22 }} />
      </ButtonBase>
    </>
  );
};

export default TalentNetworkEditMenu;
