import { Button, Dialog, DialogActions, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { COLORS } from 'lib/constants';
import React from 'react';

interface IDeleteEnsureDialog {
  isOpen: boolean;
  _onConfirm: () => void;
  _onClose: () => void;
}
const DeleteEnsureDialog: React.FC<IDeleteEnsureDialog> = (props) => {
  const { classes } = useStyles();
  const { isOpen, _onClose, _onConfirm } = props;

  return (
    <Dialog
      classes={{ paper: classes.logoutDialogPaper }}
      open={isOpen}
      onClose={_onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <Typography variant='h5' align='center'>
        Are you sure you want to delete this report ?
      </Typography>
      <DialogActions classes={{ root: classes.dialogActionsRoot }}>
        <Button
          onClick={_onConfirm}
          variant='contained'
          className={`${classes.dialogActionButtons} ${classes.dialogConfirmButton}`}
        >
          Yes
        </Button>
        <Button onClick={_onClose} size='medium' variant='outlined' className={classes.dialogActionButtons}>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles()((theme) => ({
  logoutDialogPaper: {
    width: 420,
    display: 'flex',
    justifyContent: 'center',
    borderColor: COLORS.MAROON,
    padding: '44px 29px 46px'
  },
  dialogActionsRoot: {
    marginTop: 46,
    display: 'flex',
    justifyContent: 'center',
    columnGap: 18
  },
  dialogActionButtons: {
    minWidth: 110,
    borderColor: COLORS.MAROON
  },
  dialogConfirmButton: {
    backgroundColor: COLORS.MAROON
  }
}));

export default DeleteEnsureDialog;
