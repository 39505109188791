import { Avatar, ButtonBase, Grid, Typography, lighten } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Card } from 'components/Dashboard/DeliverablesTile/Card';
import channels from 'lib/channels';
import { getInitials } from 'lib/formatStrings';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import remove from 'lodash/remove';
import moment from 'moment';
import { useState } from 'react';
import { Deliverable, Collaboration } from 'types/generated';
import { DeliverableCalendar } from './DeliverableCalendar';

interface DeliverableOverviewProps {
  deliverableList: Deliverable[];
  selectedCollab?: Collaboration | null;
}

export const DeliverableOverview = (props: DeliverableOverviewProps) => {
  const { deliverableList, selectedCollab } = props;
  const { classes } = useStyles();

  const [selectedDate, setSelectedDate] = useState('');
  const [canViewPrevious, setCanViewPrevious] = useState(false);
  const toggleCanViewPrevious = () => setCanViewPrevious(!canViewPrevious);

  const now = moment().format('YYYY-MM-DD');
  const upcomingDeliverables = [...(deliverableList || [])];
  let pastDeliverables = remove(upcomingDeliverables, (o) => {
    const hasPassed = moment(o?.endDate).isBefore(moment(now));
    if (hasPassed) return true;
  });
  if (!isEmpty(pastDeliverables)) pastDeliverables = orderBy(pastDeliverables, ['endDate'], ['desc']);

  const CollabSummary = () => {
    if (!selectedCollab) return null;
    const { avatar, name } = selectedCollab;
    const collabChannels =
      selectedCollab?.deliverables?.reduce((acc: Array<string>, curr) => {
        const channel = curr?.socialChannel?.toLowerCase();
        if (channel && !acc.includes(channel)) {
          acc.push(channel);
        }
        return acc;
      }, []) || [];

    const relevantChannels = channels.filter((o) => collabChannels.includes(o.value));
    const count = selectedCollab?.deliverables?.length || 0;
    const deliverableCount = count ? `${count} Deliverables` : 'No deliverables';
    const collabInitials = getInitials({ firstInput: name || '' });

    return (
      <Grid item xs={12} className={classes.collabContainer}>
        <Avatar src={avatar || ''} className={classes.collabLogo}>
          {collabInitials}
        </Avatar>
        <div className={classes.collabDetails}>
          <Typography className={classes.collabName}>{name}</Typography>
          <div className={classes.deliverableCount}>
            {relevantChannels.map((channel) => {
              return <img src={channel.logo} alt={channel.label} className={classes.channelLogo} key={channel.value} />;
            })}
            <Typography variant='caption' className={classes.notBold}>
              {deliverableCount}
            </Typography>
          </div>
        </div>
      </Grid>
    );
  };

  const doesListExist = !isEmpty(deliverableList);

  return (
    <Grid container className={classes.background} spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h5'>View</Typography>
      </Grid>
      <CollabSummary />
      <Grid item xs={12}>
        <DeliverableCalendar
          deliverableList={deliverableList}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='body1' style={{ fontSize: 14 }}>
          {doesListExist ? 'List of Deliverables' : 'No deliverables assigned yet'}{' '}
        </Typography>
        {upcomingDeliverables.map((o: any) => (
          <Card key={`deliverablecard-${o?._id}`} deliverable={o} selectedDate={selectedDate} />
        ))}
        {!isEmpty(pastDeliverables) && (
          <ButtonBase onClick={toggleCanViewPrevious}>
            <div className={classes.expander}>
              <Typography variant='caption'>Previous deliverables</Typography>
              {canViewPrevious ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </div>
          </ButtonBase>
        )}
        {canViewPrevious &&
          pastDeliverables.map((o: any) => (
            <Card key={`deliverablecard-${o?._id}`} deliverable={o} selectedDate={selectedDate} />
          ))}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles()((theme) => {
  return {
    background: {
      backgroundColor: lighten(theme.palette.primary.main, 0.94),
      padding: '11px 26px',
      borderRadius: '3px',
      height: 'fit-content'
    },
    collabContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 5
    },
    collabLogo: {
      height: 40,
      width: 40,
      marginRight: 10
    },
    collabDetails: {
      display: 'flex',
      flexDirection: 'column'
    },
    collabName: {
      fontSize: 12,
      fontWeight: 500,
      marginBottom: 5
    },
    deliverableCount: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      flexWrap: 'nowrap'
    },
    expander: {
      display: 'flex',
      '& svg': {
        height: 15,
        width: 15
      }
    },
    channelLogo: {
      height: 14,
      width: 14,
      marginRight: 5
    },
    notBold: {
      fontWeight: 200
    }
  };
});
