import { gql } from '@apollo/client';

export const GET_PROFILES = gql`
  query GetProfiles($profileType: String, $profileTypeList: [String]) {
    getProfiles(profileType: $profileType, profileTypeList: $profileTypeList) {
      _id
      id
      displayName
      username
      profileType
      followers_count
      fan_count
      profile_picture_url
      profile_category
      cache_completed_at
      profile_cached_at
      profileCategory
      snippet
      isRestricted
      cacheError
      pageStats {
        page_fans
      }
      channelStats {
        subscriberCount
      }
      thumbnails {
        default {
          url
        }
      }
      public {
        signature
        stats {
          followerCount
        }
      }
      tiktokMediaStats {
        viralVideoPercentage
        view_count {
          median
        }
        totalEngagement_percentageOfPlays {
          median
        }
      }
    }
  }
`;

export const GET_SINGLE_PROFILE = gql`
  query GetSingleProfile($_id: ObjectId, $id: String, $username: String) {
    getSingleProfile(_id: $_id, id: $id, username: $username) {
      _id
      id
      displayName
      user {
        _id
        firstName
        lastName
      }
      profileType
      profile_picture_url
      followers_count
      fan_count
      name
      title
      username
      biography
      instagramFeedStats {
        reach {
          median
          average
        }
        engagement_percentageOfReach {
          median
        }
      }
      instagramReelStats {
        reach {
          median
          average
        }
        total_interactions_percentageOfReach {
          median
        }
      }
      storyStats {
        reach {
          median
        }
      }
      pageStats {
        page_fans
        page_impressions
        page_positive_feedbackTotal
        page_negative_feedbackTotal
      }
      postStats {
        post_engaged_users_percentageOfReach {
          median
        }
        post_reactions_like_total {
          actual
        }
      }
      channelStats {
        subscriberCount
      }
      videoStats {
        views {
          average
        }
        engagement_percentageOfViews {
          average
        }
      }
      public {
        signature
        stats {
          followerCount
        }
      }
      tiktokMediaStats {
        viralVideoPercentage
        view_count {
          median
        }
        totalEngagement {
          median
        }
        totalEngagement_percentageOfPlays {
          median
        }
      }
      mediaKitConfigs {
        _id
        headerImage
        colorTheme
        title
        created_at
      }
      rankedInstagramMedia {
        topMedia {
          id
          _id
          media_url
          thumbnail_url
          profileId
        }
      }
      rankedTiktokMedia {
        topMedia {
          _id
          id
          profileId
          official {
            cover_image_url
            video_description
          }
        }
      }
    }
  }
`;

export const GET_MY_PROFILES = gql`
  query MyUserProfiles {
    myUser {
      _id
      firstName
      lastName
      avatar
      timezone
      country
      userAuditReportLink
      profiles {
        _id
        id
        name
        displayName
        biography
        profileType
        profile_picture_url
        followers_count
        fan_count
        isAddedByOwner
        profileAuditReportLink
        channelStats {
          subscriberCount
        }
        pageStats {
          page_fans
        }
        public {
          signature
          stats {
            followerCount
          }
        }
        tiktokMediaStats {
          viralVideoPercentage
          view_count {
            median
          }
          totalEngagement_percentageOfPlays {
            median
          }
        }
        latestPerformanceReport {
          _id
          isLimited
          performance {
            percentile
          }
        }
        isRestricted
        userId
        cache_completed_at
        created_at
        cacheError
      }
    }
  }
`;

export const GET_PROFILE_LIST = gql`
  query GetProfilesList {
    getProfiles {
      _id
      id
      displayName
      profile_picture_url
      name
      username
      profileType
      cache_completed_at
      followerCount
      followers_count
      fan_count
      profileCategory
      selectedMediaKitId
      mediaStats {
        reach {
          median
        }
        engagement_percentageOfReach {
          median
        }
      }
      storyStats {
        reach {
          median
        }
      }
      videoStats {
        views {
          median
        }
        estimatedMinutesWatched {
          median
        }
        averageViewDuration {
          median
        }
        engagement_percentageOfViews {
          median
        }
      }
      pageStats {
        page_fans
        page_impressions
        page_actions_post_reactionsTotal
        page_positive_feedbackTotal
        post_activity_unique
      }
      postStats {
        post_impressions_unique {
          median
        }
      }
      public {
        signature
        stats {
          followerCount
        }
      }
      tiktokMediaStats {
        viralVideoPercentage
        view_count {
          median
        }
        totalEngagement_percentageOfPlays {
          median
        }
      }
      profileNote {
        _id
        notes
        categories
        profile_category
        selectedMediaKitId
      }
      mediaKitConfigs {
        _id
        title
      }
    }
  }
`;

export const GET_FACEBOOK_PAGES = gql`
  query GetFacebookPages {
    getFacebookPages {
      id
      name
      picture {
        data {
          url
        }
      }
      instagram_business_account {
        id
        name
        username
        profile_picture_url
        followers_count
      }
    }
  }
`;

export const LIST_YOUTUBE_PROFILES = gql`
  query ListYoutubeProfiles {
    listYoutubeProfiles {
      _id
      id
      isAddedByOwner
      displayName
      profileType
      profile_picture_url
      profile_category
      cache_completed_at
      profile_cached_at
      profileCategory
      snippet
      isRestricted
      cacheError
      channelStats {
        subscriberCount
      }
      thumbnails {
        default {
          url
        }
      }
    }
  }
`;

export const GET_TIKTOK_PROFILES = gql`
  query GetTiktokProfiles {
    getTiktokProfiles {
      _id
      id
      isAddedByOwner
      displayName
      profileType
      profile_picture_url
      profile_category
      cache_completed_at
      profile_cached_at
      profileCategory
      isRestricted
      cacheError
    }
  }
`;

export const GET_INSTAGRAM_PROFILE = gql`
  query GetSingleProfileForInsta($id: ObjectId) {
    getSingleProfile(_id: $id) {
      _id
      id
      name
      displayName
      username
      profileType
      profile_picture_url
      biography
      followers_count
      timezone
      onlineFollowers
      userId
      lifetimeInsights {
        audience_city
        audience_country
        audience_age
        audience_gender {
          male {
            actual
          }
          female {
            actual
          }
          unspecified {
            actual
          }
        }
      }
      mediaStats {
        impressions {
          median
        }
        reach {
          median
        }
        engagement {
          median
        }
        engagement_percentageOfReach {
          median
        }
      }
      storyStats {
        impressions {
          median
        }
        reach {
          median
        }
        engagement {
          median
        }
        engagement_percentageOfReach {
          median
        }
      }
      historicalStats {
        count
        follower_count {
          actual
          average
        }
        follows_count {
          actual
          average
        }
        media_count {
          actual
          average
        }
        profileRequestCount {
          actual
          average
        }
        email_contacts {
          actual
          average
        }
        profileRequestCount {
          actual
          average
        }
        profile_views {
          actual
          average
        }
        get_directions_clicks {
          actual
          average
        }
        phone_call_clicks {
          actual
          average
        }
        text_message_clicks {
          actual
          average
        }
        website_clicks {
          actual
          average
        }
        reach {
          actual
          average
        }
        impressions {
          actual
          average
        }
      }
      latestInstagramPosts {
        _id
        id
        profileId
        media_url
        thumbnail_url
        media_type
        caption
        timestamp
        like_count
        isAd
        comments_count
        insights {
          reach
          impressions
          engagement
          saved
        }
        children {
          id
          media_url
          media_type
        }
      }
      latestInstagramStories {
        _id
        id
        profileId
        media_url
        media_type
        thumbnail_url
        caption
        timestamp
        isAd
        insights {
          reach
          impressions
          exits
          replies
          taps_forward
          taps_back
        }
      }
      weeklyInstagramStats {
        start
        follower_count
        followers_lost
        followers_net
        mediaStats {
          reach
          impressions
          engagement
          engagement_percentageOfReach
          viewRate
        }
        storyStats {
          reach
          impressions
          viewRate
        }
        change {
          followers_net
          mediaStats {
            reach
            engagement_percentageOfReach
          }
          storyStats {
            reach
            impressions
            viewRate
          }
        }
      }
      dailyInstagramStats {
        date
        followers_count
      }
      instagramFeedTrends {
        dailyAverages {
          date
          like_count
          insights {
            reach
            engagement
            engagement_percentageOfReach
          }
        }
        recentAverages {
          like_count
          insights {
            reach
            engagement
            engagement_percentageOfReach
          }
        }
        comparisonAverages {
          like_count
          insights {
            reach
            engagement
            engagement_percentageOfReach
          }
        }
      }
      instagramStoryTrends {
        dailyAverages {
          date
          insights {
            reach
            impressions
            taps_forward
            taps_back
            exits
            replies
          }
        }
        recentAverages {
          insights {
            reach
            impressions
            taps_forward
            taps_back
            exits
            replies
          }
        }
        comparisonAverages {
          insights {
            reach
            impressions
            taps_forward
            taps_back
            exits
            replies
          }
        }
      }
      rankedInstagramMedia {
        topMedia {
          id
          _id
          media_url
          thumbnail_url
          profileId
        }
      }
      rankedTiktokMedia {
        topMedia {
          _id
          id
          profileId
          official {
            cover_image_url
            video_description
          }
        }
      }
    }
  }
`;

export const GET_STORIES_FOR_DATE = gql`
  query GetStoriesForDate($profileId: ObjectId!, $date: Date!) {
    getStoriesForDate(profileId: $profileId, date: $date) {
      _id
      id
      profileId
      media_url
      media_type
      thumbnail_url
      caption
      timestamp
      isAd
      insights {
        reach
        impressions
        exits
        replies
        taps_forward
        taps_back
      }
    }
  }
`;

export const GET_FACEBOOK_PROFILE = gql`
  query GetSingleProfileForFB($id: ObjectId) {
    getSingleProfile(_id: $id) {
      _id
      id
      name
      displayName
      biography
      profileType
      profile_picture_url
      fan_count
      userId
      facebookPostTrends {
        dailyAverages {
          date
          insights {
            post_clicks
            post_reactionsTotal
            post_impressions_unique
            post_engaged_users
          }
        }
        recentAverages {
          insights {
            post_clicks
            post_reactionsTotal
            post_impressions_unique
            post_engaged_users
          }
        }
        comparisonAverages {
          insights {
            post_clicks
            post_reactionsTotal
            post_impressions_unique
            post_engaged_users
          }
        }
      }

      pageStats {
        page_impressions
        page_post_engagements
        page_total_actions
        page_video_views

        page_actions_post_reactions_anger_total
        page_actions_post_reactions_haha_total
        page_actions_post_reactions_like_total
        page_actions_post_reactions_love_total
        page_actions_post_reactions_sorry_total
        page_actions_post_reactions_wow_total
        page_actions_post_reactionsTotal

        page_call_phone_clicks_logged_in_unique
        page_content_activity
        page_content_activity_by_action_type_unique {
          other
        }
        page_get_directions_clicks_logged_in_unique
        page_places_checkin_mobile
        page_posts_impressions
        page_tab_clicksTotal
        page_views_total
        page_website_clicks_logged_in_unique

        page_positive_feedback_by_type {
          answer
          claim
          comment
          like
          link
          other
          rsvp
        }
        page_positive_feedbackTotal

        page_negative_feedback_by_type {
          hide_clicks
          hide_all_clicks
          report_spam_clicks
          unlike_page_clicks
        }
        page_negative_feedbackTotal

        page_fans
        page_fans_city
        page_fans_country
        page_fans_age
        page_fans_gender {
          male {
            actual
          }
          female {
            actual
          }
          unspecified {
            actual
          }
        }
        page_content_activity_by_gender_unique {
          male {
            actual
          }
          female {
            actual
          }
          unspecified {
            actual
          }
        }
        page_content_activity_by_age_unique
        page_content_activity_by_country_unique
        page_impressions_by_gender_unique {
          male {
            actual
          }
          female {
            actual
          }
          unspecified {
            actual
          }
        }
        page_impressions_by_age_unique
        page_impressions_by_country_unique
        page_cta_clicks_by_gender_logged_in_unique {
          male {
            actual
          }
          female {
            actual
          }
          unspecified {
            actual
          }
        }
        page_cta_clicks_by_age_logged_in_unique
        page_cta_clicks_by_country_logged_in_unique
        page_call_phone_clicks_by_gender_logged_in_unique {
          male {
            actual
          }
          female {
            actual
          }
          unspecified {
            actual
          }
        }
        page_call_phone_clicks_by_age_logged_in_unique
        page_call_phone_clicks_by_country_logged_in_unique
        page_get_directions_clicks_by_gender_logged_in_unique {
          male {
            actual
          }
          female {
            actual
          }
          unspecified {
            actual
          }
        }
        page_get_directions_clicks_by_age_logged_in_unique
        page_get_directions_clicks_by_country_logged_in_unique
        page_website_clicks_by_gender_logged_in_unique {
          male {
            actual
          }
          female {
            actual
          }
          unspecified {
            actual
          }
        }
        page_website_clicks_by_age_logged_in_unique
        page_website_clicks_by_country_logged_in_unique
        page_places_checkins_by_gender {
          male {
            actual
          }
          female {
            actual
          }
          unspecified {
            actual
          }
        }
        page_places_checkins_by_age
        page_places_checkins_by_country
      }
      postStats {
        count
        post_activity {
          median
        }
        post_activity_percentageOfReach {
          median
        }
        post_engaged_users {
          median
        }
        post_engaged_users_percentageOfReach {
          median
        }
        post_impressions_unique {
          median
        }
      }
      historicalFacebookStats {
        date
        page_fans
        page_video_views
        page_impressions
        page_post_engagements
        page_total_actions
      }
      weeklyFacebookStats {
        date
        page_fan_adds_unique
        page_fan_removes_unique
        page_fans_netChange
        page_impressions
        page_post_engagements
        page_total_actions
        page_video_views
        page_actions_post_reactionsTotal
        page_fans_bySource {
          ads {
            adds
            removes
            netChange
          }
          feed {
            adds
            removes
            netChange
          }
          page_suggestions {
            adds
            removes
            netChange
          }
          deactivated_accounts {
            adds
            removes
            netChange
          }
          search {
            adds
            removes
            netChange
          }
          other {
            adds
            removes
            netChange
          }
        }
      }
      latestFacebookPosts {
        _id
        id
        pageId
        created_time
        timestamp
        full_picture
        story
        message
        permalink_url
        insights {
          post_clicks
          post_engaged_fan
          post_engaged_users
          post_impressions
          post_activity
        }
      }
    }
  }
`;

export const GET_YOUTUBE_PROFILE = gql`
  query GetSingleProfileForYT($id: String!) {
    recentYoutubeMedia(id: $id) {
      mediaCount
      youtubeMedia {
        title
        date
        timestamp
        publishedAt
        description
        video
        thumbnails {
          default {
            url
          }
          standard {
            url
          }
          medium {
            url
          }
          high {
            url
          }
          maxres {
            url
          }
        }
        views
        comments
        likes
        dislikes
        estimatedMinutesWatched
        averageViewDuration
        annotationImpressions
        annotationClickableImpressions
        annotationCloses
        annotationCloseRate
        annotationClicks
        annotationClickThroughRate
        cardImpressions
        cardClicks
        cardClickRate
      }
      youtubeMediaAverages {
        views {
          median
        }
        comments {
          median
        }
        likes {
          median
        }
        dislikes {
          median
        }
        videosAddedToPlaylists {
          median
        }
        videosRemovedFromPlaylists {
          median
        }
        shares {
          median
        }

        estimatedMinutesWatched {
          median
        }
        estimatedRedMinutesWatched {
          median
        }
        averageViewDuration {
          median
        }

        annotationClickThroughRate {
          median
        }
        annotationCloseRate {
          median
        }
        annotationImpressions {
          median
        }
        annotationClickableImpressions {
          median
        }
        annotationClosableImpressions {
          median
        }
        annotationClicks {
          median
        }
        annotationCloses {
          median
        }

        cardClickRate {
          median
        }
        cardTeaserClickRate {
          median
        }
        cardImpressions {
          median
        }
        cardTeaserImpressions {
          median
        }
        cardClicks {
          median
        }
        cardTeaserClicks {
          median
        }
        netSubscribers {
          actual
        }
        subscribersGained {
          median
        }
        subscribersLost {
          median
        }
      }
    }
    getSingleProfile(id: $id) {
      _id
      id
      title
      displayName
      description
      userId
      profileType
      profile_picture_url
      country
      ageGroupBreakdown {
        ageGroup
        viewerPercentage
      }
      genderBreakdown {
        gender
        viewerPercentage
      }
      locationBreakdown {
        country
        views
      }
      myChannelReport {
        day
        views
        averageViewDuration
        averageViewPercentage
        estimatedMinutesWatched
        subscribersGained
        annotationClickThroughRate
      }
      subscriberChange {
        day
        subscribersGained
        subscribersLost
      }
      youtubeVideoTrends {
        dailyAverages {
          date
          views
          redViews
          averageViewDuration
          averageViewPercentage
          estimatedMinutesWatched
          estimatedRedMinutesWatched
          estimatedTotalMinutesWatched
          subscribersGained
          subscribersLost
        }
        recentAverages {
          views
          redViews
          averageViewDuration
          averageViewPercentage
          estimatedMinutesWatched
          estimatedRedMinutesWatched
          estimatedTotalMinutesWatched
        }
        comparisonAverages {
          views
          redViews
          averageViewDuration
          averageViewPercentage
          estimatedMinutesWatched
          estimatedRedMinutesWatched
          estimatedTotalMinutesWatched
        }
      }
      channelStats {
        viewCount
        commentCount
        subscriberCount
        hiddenSubscriberCount
        videoCount
      }
    }
  }
`;

export const GET_INSTAGRAM_MEDIA = gql`
  query GetInstagramMedia($id: String, $_id: ObjectId) {
    getSingleProfile(id: $id, _id: $_id) {
      _id
      id
      name
      displayName
      username
      profileType
      profile_picture_url
      biography
      followers_count
      timezone
      onlineFollowers
      userId
      latestInstagramPosts {
        _id
        id
        profileId
        media_url
        thumbnail_url
        media_type
        caption
        timestamp
        like_count
        isAd
        comments_count
        media_product_type
        insights {
          reach
          impressions
          engagement
          saved
        }
        children {
          id
          media_url
          media_type
        }
      }
    }
  }
`;
