import Box from '@mui/material/Box';
import { makeStyles } from 'tss-react/mui';

interface Props {
  parentHeight: number;
  children: JSX.Element | JSX.Element[];
}

export const ScrollableBoxNoScroll = ({ children, parentHeight }: Props) => {
  const height = parentHeight - 20 - 20 - 37 - 30 - 10;
  const { classes } = useClasses();

  return (
    <Box overflow='scroll' className={classes.root} height={height}>
      {children}
    </Box>
  );
};

const useClasses = makeStyles()((theme) => {
  return {
    root: {
      '&::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      'scrollbar-width': 'none' /* Firefox */
    }
  };
});
