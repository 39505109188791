import React from 'react';
import { useTheme } from '@mui/material';

const TalentSearchIcon = () => {
  const theme = useTheme();

  return (
    <svg width='23px' height='24px' viewBox='0 0 23 24' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <title>search</title>
      <g id='Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Icon/Browse' transform='translate(-1.000000, -1.000000)' stroke={theme.palette.primary.main}>
          <g id='search-3' transform='translate(1.000000, 2.000000)'>
            <line x1='17.571' y1='17.071' x2='22.5' y2='22' id='Path'></line>
            <path
              d='M11.492,9.737 L13.7,11.4 C14.204,11.778 14.5,12.37 14.5,13 L14.5,14 L6.5,14 L6.5,13 C6.5,12.37 6.796,11.778 7.3,11.4 L9.508,9.737'
              id='Path'
            ></path>
            <path
              d='M10.5,10 L10.5,10 C9.395,10 8.5,9.105 8.5,8 L8.5,7 C8.5,5.895 9.395,5 10.5,5 L10.5,5 C11.605,5 12.5,5.895 12.5,7 L12.5,8 C12.5,9.105 11.605,10 10.5,10 Z'
              id='Path'
              strokeLinecap='square'
            ></path>
            <circle id='Oval' strokeLinecap='square' cx='10.5' cy='10' r='10'></circle>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default TalentSearchIcon;
