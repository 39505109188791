import React from 'react';
import { Container, Grid } from '@mui/material';
import ContentfulAdBanner from 'components/Dashboard/ContentfulAdBanner';
import TalentNetworkTable from './AccountCategoriesTable';

const AccountCategoriesPage = () => {
  return (
    <Container maxWidth='lg'>
      <Grid item>
        <div style={{ height: 20 }} />
        <div style={{ fontSize: 10 }}>Manage</div>
      </Grid>
      <Grid item container style={{ marginBottom: 20 }}>
        <Grid item>
          <div style={{ fontSize: 28 }}>Creator Network</div>
        </Grid>
      </Grid>
      <Grid item container style={{ marginTop: 25, marginBottom: 25 }}>
        <ContentfulAdBanner adLocation='talent_network' />
      </Grid>
      <Grid item container>
        <TalentNetworkTable />
      </Grid>
    </Container>
  );
};

export default AccountCategoriesPage;
