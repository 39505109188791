import React, { useEffect } from 'react';

import { makeStyles } from 'tss-react/mui';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';

import { useErrorHandlerHook } from 'hooks/useErrorCatchHook';
import {
  useUpdateWalkthroughsMutation,
  UpdateWalkthroughsMutation,
  UpdateWalkthroughsMutationVariables,
  useGetMyUserQuery
} from '../../types/generated';
import { FetchResult, MutationHookOptions } from '@apollo/client';
import { Exact, GetMyUserQuery } from 'types/generated';
import { ApolloQueryResult } from '@apollo/client/core/types';
import { WALKTHROUGHS } from 'types/custom';
import { isSafari } from 'utils/utils';

const styles = makeStyles()((theme: any) => ({
  centredStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'
  },
  backgroundStyle: {
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.background.dark,
    position: 'relative'
  },
  welcomeImageStyle: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    maxHeight: 400,
    [theme.breakpoints.down('md')]: {
      maxHeight: 250
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  stagesStyle: {
    position: 'relative',
    height: '100%',
    maxHeight: '800px',
    width: '100%',
    maxWidth: '1200px'
  },
  stackedStyle: {
    position: 'absolute',
    top: 0,
    height: '85%',
    width: '100%'
  },
  finishStyle: {
    position: 'absolute',
    bottom: 0,
    height: '15%',
    width: '100%'
  },
  arrowCircle: {
    height: '70vh',
    maxHeight: 525
  },
  imageStyle: {
    maxHeight: 300,
    [theme.breakpoints.down('md')]: {
      maxHeight: 250
    }
  },
  descriptionStyle: {
    maxWidth: 300,
    flexDirection: 'column',
    margin: 20,
    color: 'rgba(51, 51, 51, 0.5)',
    cursor: 'default'
  },
  activeStyle: {
    color: theme.palette?.primary?.main || 'intial'
  },
  rowStyle: {
    width: '95%',
    display: 'flex'
  },
  topRowStyle: {
    minHeight: '33%',
    justifyContent: 'center',
    alignItems: 'flex-start'
  },
  middleRowStyle: {
    minHeight: '33%',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  bottomRowStyle: {
    minHeight: '33%',
    justifyContent: 'center',
    alignItems: 'flex-end'
  },
  declineButtonStyle: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    margin: 27,
    textTransform: 'none'
  }
}));

interface KitlyIntroProps {
  refetchUser:
    | ((
        variables?:
          | Partial<
              Exact<{
                [key: string]: never;
              }>
            >
          | undefined
      ) => Promise<ApolloQueryResult<GetMyUserQuery>>)
    | undefined;
  classes?: any;
  dismissWalkthrough: () => void;
}

interface CommonScreenProps extends KitlyIntroProps {
  updateWalkthroughs: (
    options?: MutationHookOptions<UpdateWalkthroughsMutation, UpdateWalkthroughsMutationVariables>
  ) => Promise<FetchResult<UpdateWalkthroughsMutation>>;
}

interface MobileStagesScreenProps extends CommonScreenProps {
  activeStyle: any;
  imageStyle: any;
}

interface WelcomeScreenProps extends CommonScreenProps {
  setPage: (arg: number) => void;
}

interface StageShape {
  bolded: string;
  regular: string;
  image: string;
}

interface StageDescriptionProps {
  stageKey: string;
  setActive: () => void;
  isActive?: boolean;
  activeStyle?: any;
  descriptionStyle: any;
}

const stages: { [key: string]: StageShape } = {
  Grow: {
    bolded: 'Grow your audience and increase engagement.',
    regular: 'Access exclusive audience and performance metrics + compare your account performance with your peers.',
    image: 'https://media.q-83.com/site/kitly/Grow.png'
  },
  Customise: {
    bolded: 'There’s more to you than just metrics.',
    regular: 'Customise a media kit that shows off your skills, personality and creative flare.',
    image: 'https://media.q-83.com/site/kitly/Customise.png'
  },
  Manage: {
    bolded: 'Stay organised.',
    regular: 'Manage your deliverable timelines, brand campaigns and invoices from one place.',
    image: 'https://media.q-83.com/site/kitly/Manage.png'
  },
  Share: {
    bolded: 'Share',
    regular:
      ' insights, professional reports and media kits directly with brands thorugh one link that updates every hour, forever.',
    image: 'https://media.q-83.com/site/kitly/Share.png'
  }
};

const KitlyIntro = (props: KitlyIntroProps) => {
  const { refetchUser } = props;
  const { apolloHandler } = useErrorHandlerHook();
  const [page, setPage] = React.useState(0);
  const [updateWalkthroughs, { error }] = useUpdateWalkthroughsMutation();
  const { classes } = styles();

  useEffect(() => {
    if (error) {
      apolloHandler(error);
    }
  }, [error, apolloHandler]);

  return (
    <>
      {Boolean(page === 0) && (
        <WelcomeScreen
          {...props}
          setPage={setPage}
          updateWalkthroughs={updateWalkthroughs}
          refetchUser={refetchUser}
          classes={classes}
        />
      )}
      {Boolean(page === 1) && (
        <StagesScreen {...props} updateWalkthroughs={updateWalkthroughs} refetchUser={refetchUser} classes={classes} />
      )}
    </>
  );
};

const WelcomeScreen = (props: WelcomeScreenProps) => {
  const { setPage, updateWalkthroughs, refetchUser, dismissWalkthrough } = props;
  const { centredStyle, backgroundStyle, welcomeImageStyle, declineButtonStyle } = props.classes;
  const { data } = useGetMyUserQuery();
  const { apolloHandler } = useErrorHandlerHook();

  const firstName = data?.myUser?.firstName || '';

  const NameSpan = () => (
    <>
      <span>,&nbsp;</span>
      <span style={{ fontWeight: 500 }}>{firstName}</span>
    </>
  );
  return (
    <div className={`${centredStyle} ${backgroundStyle}`}>
      <img src='https://media.q-83.com/site/kitly/woman_laptop.png' className={welcomeImageStyle} alt='' />
      <div className={centredStyle} style={{ maxWidth: 600, padding: 15 }}>
        <Typography variant='h2' style={{ marginBottom: 35 }}>
          Welcome to Kitly{firstName && <NameSpan />}
        </Typography>
        <Typography variant='h3' style={{ fontWeight: 'normal' }}>
          Here you’ll find tools to customise, manage and share everything you need for your next brand campaign.
        </Typography>
        <Button onClick={() => setPage(1)} variant='contained' size='large' style={{ marginTop: 53 }}>
          Take the Kitly Tour
        </Button>
        <ButtonBase
          onClick={() => {
            updateWalkthroughs({ variables: { update: WALKTHROUGHS.FIRST_LOGIN } })
              .then(() => refetchUser!())
              .catch((ex) => apolloHandler(ex));
            dismissWalkthrough();
          }}
          classes={{ root: declineButtonStyle }}
        >
          No thanks, I'd like to see the Dashboard
        </ButtonBase>
      </div>
    </div>
  );
};

const StagesScreen = (props: CommonScreenProps) => {
  const { updateWalkthroughs, refetchUser, dismissWalkthrough } = props;
  const { apolloHandler } = useErrorHandlerHook();
  const {
    centredStyle,
    stagesStyle,
    stackedStyle,
    finishStyle,
    imageStyle,
    descriptionStyle,
    activeStyle,
    rowStyle,
    topRowStyle,
    middleRowStyle,
    bottomRowStyle,
    arrowCircle
  } = props.classes;

  const [activeHover, setActiveHover] = React.useState('Analyse');
  const isDesktopView = useMediaQuery('(min-width:1100px)');

  const checkIsActive = (stage: string) => {
    return Boolean(stage === activeHover);
  };

  if (!isDesktopView) {
    return (
      <MobileStagesScreen
        activeStyle={`${descriptionStyle} ${activeStyle}`}
        imageStyle={imageStyle}
        updateWalkthroughs={updateWalkthroughs}
        dismissWalkthrough={dismissWalkthrough}
        refetchUser={refetchUser}
      />
    );
  }

  return (
    <div className={`${stagesStyle} ${centredStyle}`} style={{ position: 'relative' }}>
      <div className={`${stackedStyle} ${centredStyle}`} style={{ zIndex: 0 }}>
        <StageImage
          image={stages[activeHover]?.image || 'https://media.q-83.com/site/kitly/marketing_message1.png'}
          imageStyle={imageStyle}
        />
      </div>
      <div className={`${stackedStyle} ${centredStyle}`} style={{ zIndex: 1 }}>
        <img
          className={arrowCircle}
          src={'https://media.q-83.com/site/kitly/arrows_circle.svg'}
          alt=''
          style={{ position: 'absolute', top: '12%', transform: 'scale(0.95)' }}
        />
      </div>
      <div className={`${stackedStyle} ${centredStyle}`} style={{ zIndex: 2 }}>
        <div className={`${rowStyle} ${topRowStyle}`}>
          <StageDescription
            stageKey='Grow'
            setActive={() => setActiveHover('Grow')}
            isActive={checkIsActive('Grow')}
            descriptionStyle={descriptionStyle}
            activeStyle={activeStyle}
          />
        </div>
        <div className={`${rowStyle} ${middleRowStyle}`}>
          <StageDescription
            stageKey='Share'
            setActive={() => setActiveHover('Share')}
            isActive={checkIsActive('Share')}
            descriptionStyle={descriptionStyle}
            activeStyle={activeStyle}
          />
          <StageDescription
            stageKey='Customise'
            setActive={() => setActiveHover('Customise')}
            isActive={checkIsActive('Customise')}
            descriptionStyle={descriptionStyle}
            activeStyle={activeStyle}
          />
        </div>
        <div className={`${rowStyle} ${bottomRowStyle}`}>
          <StageDescription
            stageKey='Manage'
            setActive={() => setActiveHover('Manage')}
            isActive={checkIsActive('Manage')}
            descriptionStyle={descriptionStyle}
            activeStyle={activeStyle}
          />
        </div>
      </div>
      <div className={`${finishStyle} ${centredStyle}`}>
        <Button
          onClick={() => {
            updateWalkthroughs({ variables: { update: WALKTHROUGHS.FIRST_LOGIN } })
              .then(() => refetchUser!())
              .catch((ex) => apolloHandler(ex));
            dismissWalkthrough();
          }}
          variant='contained'
          size='large'
          style={{ maxHeight: 60 }}
        >
          Finish
        </Button>
      </div>
    </div>
  );
};

const StageImage = (props: { image: string; imageStyle: any }) => {
  const { image, imageStyle } = props;
  return <img className={imageStyle} src={image} alt='' />;
};

const StageDescription = (props: StageDescriptionProps) => {
  const { stageKey, setActive, isActive, descriptionStyle, activeStyle } = props;
  const stage = stages[stageKey];
  const buttonStyle = Boolean(isActive && activeStyle) ? `${descriptionStyle} ${activeStyle}` : descriptionStyle;
  return (
    <ButtonBase onClick={setActive} onMouseOver={setActive} className={buttonStyle}>
      <Typography variant='h4' color={isActive ? 'primary' : 'initial'} style={{ marginBottom: 20 }}>
        {stageKey}
      </Typography>
      <div style={{ lineHeight: '1.5em' }}>
        <span style={{ fontWeight: 500 }}>{stage.bolded}</span>&nbsp;<span>{stage.regular}</span>
      </div>
    </ButtonBase>
  );
};

const MobileStagesScreen = (props: MobileStagesScreenProps) => {
  const { activeStyle, imageStyle, updateWalkthroughs, refetchUser, dismissWalkthrough } = props;
  const { apolloHandler } = useErrorHandlerHook();
  const stageKeys = Object.keys(stages);
  const isSafariAgent = isSafari();
  return (
    <div style={{ width: '100%', height: '100%' }}>
      {stageKeys.map((key) => {
        const stage = stages[key];
        return (
          <div key={`stage-${key}`} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <StageImage image={stage.image} imageStyle={imageStyle} />
            <StageDescription stageKey={key} setActive={() => {}} descriptionStyle={activeStyle} />
          </div>
        );
      })}
      <div>
        <Button
          onClick={() => {
            updateWalkthroughs({ variables: { update: WALKTHROUGHS.FIRST_LOGIN } })
              .then(() => refetchUser!())
              .catch((ex) => apolloHandler(ex));
            dismissWalkthrough();
          }}
          variant='contained'
          size='large'
          style={{ margin: 20, maxHeight: 60, ...(isSafariAgent && { margin: '20px 20px 100px 20px' }) }}
        >
          Finish
        </Button>
      </div>
    </div>
  );
};

export default KitlyIntro;
