import React from 'react';
import { useTheme } from '@mui/material';

const InviteIcon = () => {
  const theme = useTheme();

  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>80FA9A47-8590-4395-A030-24149CA01E90@1x</title>
      <g id='-' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='Kitlfy-for-Business-Style-Guide_2'
          transform='translate(-837.000000, -329.000000)'
          stroke={theme.palette.primary.dark}
        >
          <g id='Icon/Talent-Copy-4' transform='translate(837.000000, 329.000000)'>
            <g id='a-add' transform='translate(2.000000, 1.000000)'>
              <path
                d='M9.5,12.5 C6.676,12.5 4.171,13.138 2.525,13.693 C1.31,14.104 0.5,15.249 0.5,16.532 L0.5,20.5 C0.5,20.5 10.458,20.5 10.5,20.5'
                id='Path'
              ></path>
              <path
                d='M9.5,12.5 L9.5,12.5 C6.739,12.5 4.5,9.261 4.5,6.5 L4.5,5.5 C4.5,2.739 6.739,0.5 9.5,0.5 L9.5,0.5 C12.261,0.5 14.5,2.739 14.5,5.5 L14.5,6.5 C14.5,9.261 12.261,12.5 9.5,12.5 Z'
                id='Path'
                strokeLinecap='square'
              ></path>
              <line x1='17.5' y1='15.5' x2='17.5' y2='21.5' id='Path' strokeLinecap='square'></line>
              <line x1='14.5' y1='18.5' x2='20.5' y2='18.5' id='Path' strokeLinecap='square'></line>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default InviteIcon;
