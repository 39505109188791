import { Box, Divider, Grid, IconButton, Typography } from '@mui/material';
import moreHozIcon from 'assets/icons/more-horizontal.svg';
import React, { Fragment } from 'react';
import { GetMediaKitConfigListQuery } from 'types/generated';

interface Props {
  mediaKitData: NonNullable<GetMediaKitConfigListQuery['getMediaKitConfigList']>;
  onMenuClick: (event: React.MouseEvent<HTMLButtonElement>, id: string) => void;
}

export const Data = (props: Props) => {
  const { mediaKitData, onMenuClick } = props;

  return (
    <>
      <Box mt={'19px'}>
        <Typography style={{ color: '#6C6C72' }}>Recent Media Kit Details</Typography>
      </Box>
      <Box mt={'11px'} mb={'17px'}>
        <Divider />
      </Box>
      {mediaKitData.map((o, i) => {
        return (
          <Fragment key={i}>
            <Grid container justifyContent='space-between'>
              <Grid item>
                <Box>
                  <Typography>{o?.title || ''}</Typography>
                </Box>
              </Grid>
              <Grid item>
                {o?._id && (
                  <IconButton
                    aria-label='more'
                    aria-controls='long-menu'
                    aria-haspopup='true'
                    onClick={(e) => onMenuClick(e, o._id!)}
                    style={{ padding: 0 }}
                  >
                    <img src={moreHozIcon} alt='more icon' style={{ height: 24, width: 24 }} />
                  </IconButton>
                )}
              </Grid>
            </Grid>
            {i !== mediaKitData.length - 1 && mediaKitData.length !== 1 && (
              <Box marginY={'17px'}>
                <Divider />
              </Box>
            )}
          </Fragment>
        );
      })}
    </>
  );
};
