import { useEffect, useMemo, useState } from 'react';
import {
  useTheme,
  Avatar,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ButtonBase,
  Typography
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { InvoiceEditMenu } from './InvoiceEditMenu';
import ActivityTag from '../../../components/Shared/ActivityTag';

import { useGetCollaborationInvoiceTotalsLazyQuery, CollaborationInvoice } from '../../../types/generated';
import { useErrorHandlerHook } from 'hooks/useErrorCatchHook';
import AppConfig from '../../../config/appConfig';
import moment, { Moment } from 'moment';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import { formatLongNumber } from '../../../lib/formatNumber';
import DropdownMenu from './DropdownMenu';
import { currencyDirectory } from 'lib/invoiceValues';
import { compact, filter, isEmpty } from 'lodash';

export const InvoiceHistory = () => {
  const { classes } = useStyles();
  const tableClasses = tableStyles();

  const [timePeriod, setTimePeriod] = useState('30_days');
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(null);
  const [customStartDate, setCustomStartDate] = useState<Moment | null>(null);
  const [customEndDate, setCustomEndDate] = useState<Moment | null>(null);

  const startDate = useMemo(() => {
    if (timePeriod === 'all_time') {
      return null;
    }
    return moment()
      .subtract(timePeriod === '30_days' ? 30 : timePeriod === '14_days' ? 14 : 7, 'days')
      .toDate();
  }, [timePeriod]);

  const endDate = useMemo(() => {
    if (timePeriod === 'all_time') {
      return null;
    }
    return moment().add(2, 'months').toDate();
  }, [timePeriod]);

  const [getCollaborationTotals, { data, loading, error }] = useGetCollaborationInvoiceTotalsLazyQuery({
    fetchPolicy: 'cache-and-network'
  });

  useEffect(() => {
    if (timePeriod !== 'range') {
      setCustomEndDate(null);
      setCustomStartDate(null);
      getCollaborationTotals({ variables: { startDate, endDate } });
    }
  }, [timePeriod, startDate, endDate, setCustomEndDate, setCustomStartDate, getCollaborationTotals]);

  const { apolloHandler } = useErrorHandlerHook();

  useEffect(() => {
    if (error) {
      apolloHandler(error);
    }
  }, [error, apolloHandler]);

  const invoices = useMemo(() => {
    if (data?.getCollaborationInvoiceTotals?.invoices) {
      return compact(data.getCollaborationInvoiceTotals.invoices);
    }

    return [];
  }, [data]);

  const dueInvoices = useMemo(() => {
    return filter(invoices, (o) => !Boolean(o.stripeInvoice?.status_transitions?.paid_at));
  }, [invoices]);

  const paidInvoices = useMemo(() => {
    return filter(invoices, (o) => Boolean(o.stripeInvoice?.status_transitions?.paid_at));
  }, [invoices]);

  const totals = useMemo(
    () =>
      data?.getCollaborationInvoiceTotals?.totals
        ? filter(data?.getCollaborationInvoiceTotals?.totals, (o) => Boolean(o?.contactId === 'total'))
        : [],
    [data]
  );

  const hasUnpaidInvoices = Boolean(dueInvoices.length);
  const hasPaidInvoices = Boolean(paidInvoices.length);

  const Loader = () => (
    <Grid item xs={12} className={`${classes.centred} ${classes.loaderContainer}`}>
      <CircularProgress />
    </Grid>
  );

  return (
    <Grid container>
      <Grid item xs={12} container justifyContent={'flex-end'}>
        <div className='hidden-date-picker'>
          <DateRangePicker
            startDateId={'start-date'}
            endDateId={'end-date'}
            startDate={customStartDate}
            endDate={customEndDate}
            onDatesChange={(event) => {
              setCustomStartDate(event.startDate);
              setCustomEndDate(event.endDate);
              if (event.startDate && event.endDate) {
                setTimePeriod('range');
                getCollaborationTotals({
                  variables: { startDate: event.startDate.toDate(), endDate: event.endDate.toDate() }
                });
              }
            }}
            focusedInput={focusedInput}
            onFocusChange={(input) => setFocusedInput(input)}
            monthFormat={'MMM YYYY'}
            displayFormat={'DD/MM/YYYY'}
            startDatePlaceholderText={'From DD/MM/YYYY*'}
            endDatePlaceholderText={'Until DD/MM/YYYY*'}
            isOutsideRange={() => false}
            daySize={25}
            small
            block
            noBorder
            hideKeyboardShortcutsPanel
          />
        </div>
        <DropdownMenu
          value={timePeriod}
          displayValue={
            timePeriod === 'range'
              ? `${customStartDate?.format('DD MMM YY')} - ${customEndDate?.format('DD MMM YY')}`
              : undefined
          }
          onChange={(value) => setTimePeriod(value)}
          options={[
            { title: 'Last 7 Days', value: '7_days' },
            { title: 'Last 14 Days', value: '14_days' },
            { title: 'Last 30 Days', value: '30_days' },
            { title: 'All Time', value: 'all_time' },
            {
              title: 'Date range',
              value: 'range',
              onClick: () => {
                setFocusedInput('startDate');
              }
            }
          ]}
          placeholder={'Date range'}
        />
      </Grid>

      {loading ? (
        <Loader />
      ) : (
        <Grid container>
          <Grid item className={classes.overviewContainer}>
            <Grid container className={classes.overviewBox} direction='column' alignItems='center'>
              {totals?.map((o, i) => {
                const currency = o?.currency ? currencyDirectory[o.currency] : null;

                return (
                  <Grid item key={o?.currency || `total_${i}`}>
                    <Typography className={classes.totalTitle}>{`${currency?.value || ''}${
                      currency?.symbol || ''
                    }${formatLongNumber(o?.overdue)}`}</Typography>
                  </Grid>
                );
              })}
              <Grid item>
                <Typography className={classes.totalSubtitle}>Overdue</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.overviewContainer}>
            <Grid container className={classes.overviewBox} direction='column' alignItems='center'>
              {totals?.map((o, i) => {
                const currency = o?.currency ? currencyDirectory[o.currency] : null;

                return (
                  <Grid item key={o?.currency || `total_${i}`}>
                    <Typography className={classes.totalTitle}>{`${currency?.value || ''}${
                      currency?.symbol || ''
                    }${formatLongNumber(o?.due)}`}</Typography>
                  </Grid>
                );
              })}
              <Typography className={classes.totalSubtitle}>Not Due Yet</Typography>
            </Grid>
          </Grid>
          <Grid item className={classes.overviewContainer}>
            <Grid container className={classes.overviewBox} direction='column' alignItems='center'>
              {totals?.map((o, i) => {
                const currency = o?.currency ? currencyDirectory[o.currency] : null;

                return (
                  <Grid item key={o?.currency || `total_${i}`}>
                    <Typography className={classes.totalTitle}>{`${currency?.value || ''}${
                      currency?.symbol || ''
                    }${formatLongNumber(o?.paid)}`}</Typography>
                  </Grid>
                );
              })}
              <Grid item>
                <Typography className={classes.totalSubtitle}>Paid</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item container xs={12} style={{ marginTop: 10 }}>
            {isEmpty(invoices) ? (
              <Grid item xs={12} style={{ margin: '30px 0' }}>
                <Typography variant='body2' align='center'>
                  Looks like you have no Invoices to display
                </Typography>
              </Grid>
            ) : (
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant='body1' className={classes.tableHeader}>
                        Date
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body1' className={classes.tableHeader}>
                        Collab
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body1' className={classes.tableHeader}>
                        Invoice Number
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body1' className={classes.tableHeader}>
                        Due In
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body1' className={classes.tableHeader}>
                        Status
                      </Typography>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                {hasUnpaidInvoices && (
                  <TableBody>
                    {dueInvoices.map((inv, i) => (
                      <InvoiceRow invoice={inv} key={inv._id || `inv_${i}`} />
                    ))}
                  </TableBody>
                )}
              </Table>
            )}
          </Grid>
          {hasPaidInvoices && (
            <Grid item xs={12} style={{ marginTop: hasUnpaidInvoices ? 60 : 30 }}>
              <Table size='small'>
                <TableHead>
                  <TableRow classes={tableClasses}>
                    <TableCell>
                      <Typography variant='body1' className={classes.tableHeader}>
                        Paid
                      </Typography>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paidInvoices.map((inv, i) => (
                    <InvoiceRow invoice={inv} key={inv._id || `inv_${i}`} />
                  ))}
                </TableBody>
              </Table>
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};

const InvoiceRow = ({ invoice }: { invoice: CollaborationInvoice }) => {
  const theme = useTheme();
  const { classes } = invoiceRowStyles();

  if (!invoice._id) {
    return null;
  }

  const collaborationName = invoice?.collaboration?.name || '';
  const numberOfDeliverables = invoice.deliverableIds?.length || 0;
  const invoiceDownloadLink = invoice.stripeInvoiceUrl
    ? invoice.stripeInvoiceUrl
    : `${AppConfig.INVOICE_URL}/#/view/${invoice._id}`;

  const sharedToLink = invoice?.links?.length ? invoice.links[0] : undefined;

  return (
    <TableRow key={invoice._id}>
      <TableCell>{moment(invoice.dueDate).format('D MMM YY')}</TableCell>
      <TableCell>
        <Grid container alignItems='center' wrap='nowrap'>
          <Avatar src={invoice?.collaboration?.brand?.avatar || ''} variant='circular' className={classes.avatar}>
            {collaborationName[0]}
          </Avatar>
          <Grid item container direction='column' style={{ marginLeft: 10 }}>
            <Typography className={classes.rowText}>{collaborationName}</Typography>
            <Typography className={classes.rowText} style={{ fontSize: 10 }}>
              {sharedToLink?.contact?.email || sharedToLink?.contactDetails?.email || ''}
            </Typography>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell>
        <Grid container direction='column'>
          <Typography variant='body1' style={{ fontSize: 14 }}>
            <ButtonBase target='_blank' href={invoiceDownloadLink} style={{ color: theme.palette.primary.main }}>
              {invoice.invoiceId}
            </ButtonBase>
          </Typography>
          <Typography variant='body2' style={{ fontSize: 10 }}>{`${numberOfDeliverables} deliverable${
            numberOfDeliverables > 1 ? 's' : ''
          } invoiced`}</Typography>
        </Grid>
      </TableCell>
      <TableCell>
        {invoice?.dueDate && moment().isBefore(invoice.dueDate) && (
          <Grid container justifyContent='center' alignItems='center' direction='column'>
            <Typography className={classes.dueText}>
              {moment.duration(moment(invoice.dueDate).diff(moment())).days()}
            </Typography>
            <Typography className={classes.dueText} style={{ fontSize: 10 }}>
              DAYS
            </Typography>
          </Grid>
        )}
      </TableCell>
      <TableCell>
        <ActivityTag action={invoice?.status || 'DRAFT'} />
      </TableCell>
      <TableCell>{Boolean(invoice?.status !== 'VOID') && <InvoiceEditMenu invoiceId={invoice._id} />}</TableCell>
    </TableRow>
  );
};

const invoiceRowStyles = makeStyles()(() => ({
  container: {
    borderBottomColor: '#BBBBBB',
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    paddingTop: 10,
    paddingBottom: 10
  },
  avatar: {
    height: 24,
    width: 24,
    fontSize: 12
  },
  rowText: {
    color: '#333333',
    fontSize: 14
  },
  dueText: {
    color: '#333333',
    fontSize: 14
  }
}));

const tableStyles = makeStyles()((theme) => ({
  root: {
    '& th': {
      borderBottomColor: theme.palette.primary.main
    }
  }
}));

const useStyles = makeStyles()(() => {
  return {
    centred: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    loaderContainer: {
      height: '85vh'
    },
    avatar: {
      height: 24,
      width: 24,
      fontSize: 12
    },
    tableHeader: {
      fontSize: 12,
      color: '#6C6C72'
    },
    overviewContainer: {
      padding: 6
    },
    overviewBox: {
      minWidth: 168,
      padding: 5,
      borderWidth: 0.5,
      borderStyle: 'solid',
      borderColor: '#BBBBBB',
      backgroundColor: '#FFFFFF',
      boxShadow: '0 1px 2px 0 rgba(211,211,211,0.5)',
      borderRadius: 4
    },
    totalTitle: {
      fontSize: 24,
      color: '#333333'
    },
    totalSubtitle: {
      fontSize: 10,
      color: '#333333',
      fontWeight: 600
    }
  };
});
