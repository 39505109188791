import { useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { makeStyles } from 'tss-react/mui';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import AvatarUpload from 'components/Fields/AvatarUpload';
import Modal from 'components/Modal';
import useTracking from 'context/useTrackingCtx';
import { formatError } from 'lib/formatStrings';
import isEmpty from 'lodash/isEmpty';
import { useNavigate } from 'react-router';
import {
  Contact,
  Ambassador,
  ContactUpdates,
  GetBasicContactListDocument,
  GetContactsDocument,
  useCreateContactMutation,
  useDeleteContactMutation,
  useUpdateContactMutation
} from 'types/generated';
import validate from 'validate.js';
import { Context, Provider } from './index';

interface Props {
  children: JSX.Element;
  editableBrand?: Contact | Ambassador['brand'];
  isModalOpen?: boolean;
  disableButtonRipple?: boolean;
}

interface CreationModalProps extends Props {
  isOpen: boolean;
  toggleModal: () => void;
}

const constraints = {
  companyName: { presence: { allowEmpty: false } }
};

export const BrandCreation = (props: Props) => {
  const { children, isModalOpen, disableButtonRipple } = props;

  const [isOpen, setIsOpen] = useState(isModalOpen ?? false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {isOpen && <CreationModal {...props} isOpen={isOpen} toggleModal={toggleModal} />}
      <ButtonBase onClick={toggleModal} disableRipple={disableButtonRipple ?? false}>
        {children}
      </ButtonBase>
    </>
  );
};

const CreationModal = (props: CreationModalProps) => {
  const { isOpen, toggleModal, editableBrand } = props;
  const { classes } = useStyles();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [deleteContact] = useDeleteContactMutation({ refetchQueries: [{ query: GetBasicContactListDocument }] });
  const toggleDeleteModal = () => setIsDeleteModalOpen(!isDeleteModalOpen);
  const { trackEvent } = useTracking();

  const DeleteModal = () => (
    <Modal
      isOpen={isDeleteModalOpen}
      onToggle={toggleDeleteModal}
      onConfirm={() => {
        if (editableBrand?._id) {
          deleteContact({ variables: { id: editableBrand._id } });
          trackEvent('brand', 'deleted brand');
          toggleDeleteModal();
          toggleModal();
        }
      }}
      maxWidth={'xs'}
      heading={'Delete brand'}
    >
      <div className={classes.deleteModal}>
        <Typography>Are you sure you want to delete {editableBrand?.companyName || 'this brand'}?</Typography>
      </div>
    </Modal>
  );

  return (
    <Modal
      isOpen={isOpen}
      onToggle={toggleModal}
      onConfirm={() => {}}
      hideConfirmationButtons={true}
      maxWidth={'sm'}
      heading={editableBrand?._id ? 'Update Brand' : 'New Brand'}
    >
      <Provider>
        <Grid container className={classes.modalContainer}>
          {editableBrand?._id && (
            <>
              <ButtonBase onClick={toggleDeleteModal} className={classes.deleteButton}>
                <DeleteIcon className={classes.deleteIcon} />
              </ButtonBase>
              <DeleteModal />
            </>
          )}
          <Grid item xs={12} className={`${classes.input} ${classes.centred}`}>
            <AvatarUpload size={100} />
          </Grid>
          <CompanyForm />
          <AddUpdateButton {...props} />
        </Grid>
      </Provider>
    </Modal>
  );
};

const CompanyForm = () => {
  const { classes } = useStyles();
  const {
    state: { companyName, email },
    setCompanyName,
    setEmail
  } = useContext(Context);

  return (
    <Grid item xs={12}>
      <TextField
        variant='standard'
        value={companyName}
        onChange={(e: any) => setCompanyName(e.target.value)}
        label={'Name *'}
        fullWidth={true}
        inputProps={{ contentEditable: true }}
        className={classes.input}
      />
      <TextField
        variant='standard'
        value={email}
        onChange={(e: any) => setEmail(e.target.value)}
        label={'Email (Optional)'}
        fullWidth={true}
        inputProps={{ contentEditable: true }}
        className={classes.input}
      />
    </Grid>
  );
};

const AddUpdateButton = (props: any): JSX.Element => {
  const { toggleModal, editableBrand } = props;
  const { trackEvent } = useTracking();
  const {
    state: { avatar, companyName, email },
    setAvatar,
    setCompanyName,
    setEmail
  } = useContext(Context);

  const { classes } = useStyles();
  const navigate = useNavigate();

  const [error, setError] = useState<string>('');

  const [createContact, { loading: createLoading }] = useCreateContactMutation({
    refetchQueries: [{ query: GetBasicContactListDocument }, { query: GetContactsDocument }]
  });

  const [updateContact, { loading: updateLoading }] = useUpdateContactMutation({
    refetchQueries: [{ query: GetBasicContactListDocument }, { query: GetContactsDocument }]
  });

  const loading = createLoading || updateLoading;

  useEffect(() => {
    setAvatar(editableBrand?.avatar || null);
    setCompanyName(editableBrand?.companyName || '');
    setEmail(editableBrand?.email || '');
    // Disabling warning to prevent render loop
    // eslint-disable-next-line
  }, [editableBrand]);

  const validateBrand = async () => {
    // Payload:
    const updates = { type: 'COMPANY', companyName, email } as ContactUpdates;
    if (avatar && typeof avatar !== 'string') {
      updates.avatar = avatar;
    }
    // Validate:
    let error = validate(updates, constraints);
    const firstKey = !isEmpty(error) ? Object.keys(error)[0] : null;
    if (firstKey && error[firstKey][0]) {
      setError(error[firstKey][0]);
      return null;
    } else {
      setError('');
    }

    // Create/update
    if (editableBrand?._id) {
      updateContact({ variables: { id: editableBrand?._id, updates } })
        .then(() => {
          trackEvent('brand', 'updated brand');
          toggleModal();
        })
        .catch((error: Object) => {
          const errorMessage = formatError(error) || 'Error updating brand';
          setError(errorMessage);
        });
    } else {
      createContact({ variables: { updates } })
        .then(() => {
          trackEvent('brand', 'created brand');
          toggleModal();
          navigate('/my-brands');
        })
        .catch((error) => {
          const errorMessage = formatError(error) || 'Error creating brand';
          setError(errorMessage);
        });
    }
  };

  return (
    <Grid item xs={12} className={classes.input}>
      <Button onClick={validateBrand} variant='contained' size='large' className={classes.finishButton}>
        {loading ? <CircularProgress style={{ color: '#FFF' }} /> : editableBrand?._id ? 'Update' : 'Add'}
      </Button>
      {error && <div className={classes.error}>Error: {error}</div>}
    </Grid>
  );
};

const useStyles = makeStyles()((theme) => ({
  centred: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  modalContainer: {
    position: 'relative'
  },
  deleteButton: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  deleteIcon: {
    // @ts-ignore
    color: theme.palette.common.blueGray,
    fontSize: 24
  },
  deleteModal: {
    margin: '15px 0'
  },
  input: {
    margin: '15px 0'
  },
  inputTitle: {
    fontSize: 12,
    fontWeight: 500
  },
  statusButton: {
    minWidth: 110,
    margin: 5,
    fontSize: 12,
    color: '#333333'
  },
  inactive: {
    backgroundColor: '#F3F1F1'
  },
  active: {
    border: 'solid 0.5px #979797'
  },
  activeIndicator: {
    height: 6,
    width: 6,
    borderRadius: '100%',
    backgroundColor: '#01D311',
    marginRight: 5
  },
  finishButton: {
    width: '100%'
  },
  error: {
    color: theme.palette.error.main,
    fontSize: 12,
    marginTop: 10,
    textAlign: 'center'
  }
}));
