import React from 'react';
import { useTheme } from '@mui/material';

const BrandsIcon = () => {
  const theme = useTheme();

  return (
    <svg
      width='24px'
      height='25px'
      viewBox='0 0 24 25'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>00A89A8E-129B-47CA-8E04-D765074FFE42@1x</title>
      <g id='-' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Kitlfy-for-Business-Style-Guide_2' transform='translate(-339.000000, -330.000000)'>
          <g id='Icon/Browse-Copy-2' transform='translate(339.000000, 331.000000)'>
            <g id='badge' transform='translate(1.000000, 0.000000)'>
              <polyline
                id='Path'
                stroke={theme.palette.primary.dark}
                strokeLinecap='square'
                points='13 5 22 5 22 22 0 22 0 5 9 5'
              ></polyline>
              <path
                d='M13,7 L9,7 L9,2 C9,0.895 9.895,0 11,0 L11,0 C12.105,0 13,0.895 13,2 L13,7 Z'
                id='Path'
                stroke={theme.palette.primary.dark}
                strokeLinecap='square'
              ></path>
              <line
                x1='14'
                y1='12'
                x2='18'
                y2='12'
                id='Path'
                stroke={theme.palette.primary.dark}
                strokeLinecap='square'
              ></line>
              <line
                x1='14'
                y1='16'
                x2='18'
                y2='16'
                id='Path'
                stroke={theme.palette.primary.dark}
                strokeLinecap='square'
              ></line>
              <path
                d='M3,18 C3,16.343 4.343,15 6,15 L8,15 C9.657,15 11,16.343 11,18 L3,18 Z'
                id='Path'
                fill={theme.palette.primary.dark}
                fillRule='nonzero'
              ></path>
              <circle id='Oval' fill={theme.palette.primary.dark} fillRule='nonzero' cx='7' cy='12' r='2'></circle>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default BrandsIcon;
