import { useEffect, useState } from 'react';

import { useTheme, Grid, Paper, Button, CircularProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import q83API from '../../api/q83API';

import { formatError } from '../../lib/formatStrings';
import AppConfig from '../../config/appConfig';
import { useParams } from 'react-router-dom';

interface TrackingApprovalProps {
  match?: {
    params?: {
      token?: string;
    };
  };
}

const useStyles = makeStyles()((theme) => ({
  pageContent: { width: '100%', display: 'flex', justifyContent: 'center', minHeight: '78vh', padding: '0 10px' },
  sizingContainer: { maxWidth: '625px' },
  contentContainer: {
    marginBottom: 70,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px 20px 30px',
    textAlign: 'center',
    flexDirection: 'column'
  },
  errorText: { color: '#ff0000' }
}));

const TrackingApproval = (props: TrackingApprovalProps) => {
  const theme: any = useTheme();
  const { classes } = useStyles();
  const { token } = useParams<{ token?: string }>();

  const [isLoading, setIsLoading] = useState(false);
  const [errorText, setErrorText] = useState<string | undefined>();
  const [messageText, setMessageText] = useState<string | undefined>();

  useEffect(() => {
    if (token) {
      setIsLoading(true);
      q83API
        .get(`tracking-response/${token}`)
        .then((response) => {
          setMessageText(response?.data?.message);
        })
        .catch((ex) => {
          const errorMessage = formatError(ex);
          if (errorMessage) {
            setErrorText(errorMessage);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [token, setIsLoading, setErrorText]);

  return (
    <div style={{ backgroundColor: theme.palette.background.default }} className='site-background'>
      <div style={{ position: 'relative', zIndex: 1 }}>
        <div>
          <Grid container className={classes.pageContent}>
            <Grid item sm={12} md={8} className={classes.sizingContainer}>
              <Paper className={classes.contentContainer}>
                {isLoading && <CircularProgress />}
                {messageText && <p>{messageText}</p>}
                {errorText && <p className={classes.errorText}>{errorText}</p>}
                {!isLoading && (
                  <p>
                    <Button href={AppConfig.HOME_URL} style={{ margin: '10px' }} variant='contained'>
                      Go to homepage
                    </Button>
                  </p>
                )}
              </Paper>
              <p />
              <p />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default TrackingApproval;
