import React, { useMemo } from 'react';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ManagementImage from 'assets/images/management.svg';
import { useWalkthrough } from 'hooks/useWalkthrough';
import { WALKTHROUGHS } from 'types/custom';
import Modal from 'components/Modal';
import { useGetInvoicesQuery } from 'types/generated';

const FinanceWalkthrough = () => {
  const { classes } = useClasses();

  const { data } = useGetInvoicesQuery();

  const hasNoCollabs = useMemo(() => {
    return data?.getCollaborationInvoices?.length === 0;
  }, [data]);

  const {
    handleWalkthrough: handleMaybeLater,
    loading: maybeLaterLoading,
    shouldShowWalkthrough
  } = useWalkthrough(WALKTHROUGHS.FINANCE);

  if (!hasNoCollabs) {
    return null;
  }

  if (shouldShowWalkthrough === false) {
    return null;
  }

  return (
    <Modal isOpen hideConfirmationButtons maxWidth='sm' onToggle={() => handleMaybeLater()}>
      <Box className={classes.root} mt='50px'>
        <Grid container justifyContent='center'>
          <Box mb={'44px'}>
            <Typography style={{ color: '#333333', fontWeight: 400, fontSize: 28 }} align='center'>
              Manage your Finances
            </Typography>
          </Box>
          <img alt='campaign set up' src={ManagementImage} className={classes.image} />
          <Box mb='48px' mt='20px'>
            <Typography style={{ fontSize: 16, lineHeight: '24px', color: '#333333' }} align='center'>
              Create Invoices, Manage income and receive alerts when you have been paid! The Invoice dashboard allows
              you to Manage and share you invoices from one place.
            </Typography>
          </Box>
          <Grid container justifyContent='center' wrap='nowrap' style={{ columnGap: 20 }}>
            <Button
              variant='contained'
              className={`${classes.button} ${classes.smallButton}`}
              onClick={handleMaybeLater}
            >
              {maybeLaterLoading && <CircularProgress style={{ marginRight: 10 }} size={20} />}
              View Dashboard
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default FinanceWalkthrough;

const useClasses = makeStyles()(() => {
  return {
    root: {
      maxWidth: 574
    },
    button: {
      minWidth: '277px'
    },
    buttonText: {
      color: '#FFFFFF',
      fontWeight: 500,
      fontSize: 16
    },
    smallButton: {
      fontSize: 14,
      textTransform: 'none'
    },
    image: {
      height: 260,
      width: 300
    }
  };
});
