import React, { useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { makeStyles } from 'tss-react/mui';

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
};

interface SimpleTabsProps {
  tabContent: { label: string; param?: string; content: JSX.Element | null }[];
  availableParam?: string;
  scrollButtons?: boolean | 'auto';
  variant?: 'fullWidth' | 'scrollable' | 'standard';
  page?: string;
}

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
};

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Grid>{children}</Grid>}
    </div>
  );
};

const SimpleTabs = (props: SimpleTabsProps): JSX.Element => {
  const { tabContent, availableParam, page } = props;
  const [tabValue, setTabValue] = React.useState(0);
  const { classes } = useStyles();

  useEffect(() => {
    if (availableParam) {
      tabContent.forEach((tab, i) => {
        if (tab.param === availableParam) {
          setTabValue(i);
        }
      });
    }
  }, [availableParam, tabContent]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    const newParam = tabContent[newValue]?.param;
    const newPath = `/#/${page}${newParam ? `/${newParam}` : ''}`;
    window.history.replaceState(null, 'New Page Title', newPath);
    setTabValue(newValue);
  };

  return (
    <>
      <Tabs
        value={tabValue}
        scrollButtons={props.scrollButtons}
        variant={props.variant}
        onChange={handleChange}
        aria-label='simple tabs'
        indicatorColor='primary'
        TabIndicatorProps={{
          style: {
            height: 4
          }
        }}
      >
        {tabContent.map(({ label }, index) => (
          <Tab key={label} disableTouchRipple className={classes.tab} label={label} {...a11yProps(index)} />
        ))}
      </Tabs>
      <Divider className={classes.divider} />
      {tabContent.map(({ content }, index) => (
        <TabPanel key={index} value={tabValue} index={index}>
          {content}
        </TabPanel>
      ))}
    </>
  );
};

const useStyles = makeStyles()(({ spacing, palette }: any) => ({
  tab: {
    minWidth: 0,
    padding: 0,
    marginRight: spacing(7),
    color: palette.common.blueGray,
    '& > span:nth-child(1)': {
      fontSize: 10
    }
  },
  divider: {
    backgroundColor: palette.primary.main,
    position: 'relative',
    top: '-1px'
  }
}));

export default SimpleTabs;
