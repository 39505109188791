import { Box, Typography } from '@mui/material';
import mediaKits from 'assets/images/media-kits.png';
import React from 'react';

export const NoData = () => {
  return (
    <Box>
      <Box mb={'17px'}>
        <Typography style={{ lineHeight: '20px' }}>
          <Typography style={{ fontWeight: 600 }} component='span'>
            There’s more to you than just metrics.
          </Typography>{' '}
          Express yourself and your creativity by customising your media kit.
        </Typography>
      </Box>
      <Box display='flex' justifyContent='center'>
        <img src={mediaKits} alt='media kits' style={{ height: '100%' }} />
      </Box>
    </Box>
  );
};
