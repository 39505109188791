import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import ButtonBase from '@mui/material/ButtonBase';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { InviteDialog } from 'components/Shared/InviteDialog';

// import FakeButton from './FakeButton';
import ChevronSmall from '../../assets/icons/ChevronSmall.svg';
import Icon1 from '../../assets/images/refer_and_earn_1.png';
import Icon2 from '../../assets/images/refer_and_earn_2.svg';
import GoldCoin from '../../assets/images/gold-coin.png';
import Airplane from '../../assets/images/Airplane.png';

const styles = makeStyles()((theme) => ({
  textHighlight: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    textAlign: 'left'
  },
  articleLink: {
    color: theme.palette.primary.main,
    fontSize: 12,
    marginBottom: 5
  },
  articleArrow: {
    marginLeft: 10
  },
  inviteBanner: {
    width: '100%',
    maxWidth: '170px',
    height: 'fit-content',
    borderRadius: '5px',
    backgroundColor: '#F7F7FA',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    minHeight: 535,
    minWidth: 70
  },
  inviteBannerHorizontal: {
    backgroundColor: '#F7F7FA',
    width: 449,
    position: 'relative',
    overflow: 'hidden'
  },
  inviteTextContainer: {
    maxWidth: 255
  },
  inviteText: {
    padding: 20
  },
  paddingTop: {
    paddingTop: 10
  },
  paddingBottom: {
    paddingBottom: 15
  },
  cardHeading: {
    fontSize: 14,
    marginBottom: 20,
    fontWeight: 500
  },
  cardText: {
    marginBottom: 5,
    fontSize: 12
  },
  cardImage: {
    maxWidth: 53,
    borderRadius: '50%'
  }
}));

const InviteTitle = () => {
  return <span>Enjoying Kitly?</span>;
};

const GiftCardTitle = ({ classes }) => {
  return <span className={classes.textHighlight}>Get a $50 Gift Card!</span>;
};

const InviteBody = ({ classes, plainText }: { classes: any; plainText?: boolean }) => (
  <>
    <span className={plainText ? '' : classes.textHighlight}>Invite</span> the brands you’ve worked with to join Kitly
    Business and receive a <span className={plainText ? '' : classes.textHighlight}>$50 E-rewards card</span> when they
    subscribe.
  </>
);

const ArticleLink = ({ classes }) => (
  <ButtonBase
    href='https://support.q-83.com/en/articles/4341096-how-referrals-work-for-kitly-business'
    target='_blank'
    rel='noopener noreferrer'
    className={classes.articleLink}
  >
    How referrals work <img src={ChevronSmall} alt='' className={classes.articleArrow} />
  </ButtonBase>
);

const VerticalInviteBanner = () => {
  const [isInviteOpen, setIsInviteOpen] = useState(false);
  const { classes } = styles();

  return (
    <>
      <div className={classes.inviteBanner}>
        <div className={classes.inviteText}>
          <Typography variant='h5' className={classes.paddingBottom}>
            <InviteTitle />
          </Typography>
          <Typography variant='body1' className={classes.paddingBottom}>
            <InviteBody classes={classes} />
          </Typography>
          <ArticleLink classes={classes} />
          <div className={`${classes.paddingBottom} ${classes.paddingTop}`}>
            <Button onClick={() => setIsInviteOpen(true)} variant={'contained'} color={'primary'} size={'small'}>
              Invite
            </Button>
          </div>
        </div>
        <img src={Icon2} alt='' style={{ position: 'absolute', left: 26, bottom: 154, height: 59, width: 59 }} />
        <img src={Icon1} alt='' style={{ position: 'absolute', right: 0, bottom: 0, height: 157, width: 157 }} />
        <img src={Airplane} alt='' style={{ position: 'absolute', bottom: 99, right: 9, height: 27, width: 27 }} />
      </div>
      <InviteDialog isOpen={isInviteOpen} onClose={() => setIsInviteOpen(false)} />
    </>
  );
};

const HorizontalInviteBanner = () => {
  const [isInviteOpen, setIsInviteOpen] = useState(false);
  const { classes } = styles();

  return (
    <>
      <div className={classes.inviteBannerHorizontal}>
        <div className={classes.inviteTextContainer}>
          <div className={classes.inviteText}>
            <Typography variant='h5' className={classes.paddingBottom}>
              <InviteTitle />
            </Typography>
            <Typography variant='body1' className={classes.paddingBottom}>
              <InviteBody classes={classes} />
            </Typography>
            <ArticleLink classes={classes} />
            <div className={`${classes.paddingBottom} ${classes.paddingTop}`}>
              <Button onClick={() => setIsInviteOpen(true)} variant={'contained'} color={'primary'} size={'small'}>
                Invite
              </Button>
            </div>
          </div>
        </div>
        <img
          src='http://media.q-83.com/kitly-business/ReferAndEarnClientGraphic.png'
          alt=''
          style={{ position: 'absolute', bottom: '-130px', right: '-165px', maxHeight: 360 }}
        />
      </div>
      <InviteDialog isOpen={isInviteOpen} onClose={() => setIsInviteOpen(false)} />
    </>
  );
};

const InviteCard = () => {
  const [isInviteOpen, setIsInviteOpen] = useState(false);
  const { classes } = styles();

  return (
    <>
      <Paper
        style={{
          display: 'flex',
          flexDirection: 'inherit',
          padding: 20,
          backgroundColor: '#F7F7FA'
        }}
      >
        <Grid container direction='column'>
          <Grid item className={classes.cardHeading}>
            <GiftCardTitle classes={classes} />
          </Grid>
          <Grid item container className={classes.cardText}>
            <Grid item md={10} style={{ paddingRight: 5 }}>
              <InviteBody classes={classes} plainText />
            </Grid>
            <Grid item md={2}>
              <img src={GoldCoin} alt='' className={classes.cardImage} />
            </Grid>
          </Grid>
          <Grid item>
            <ButtonBase
              onClick={() => setIsInviteOpen(true)}
              className={classes.textHighlight}
              style={{ padding: '8px 0' }}
            >
              Invite a Brand
            </ButtonBase>
          </Grid>
          <Grid item>
            <ArticleLink classes={classes} />
          </Grid>
        </Grid>
      </Paper>
      <InviteDialog isOpen={isInviteOpen} onClose={() => setIsInviteOpen(false)} />
    </>
  );
};

export { VerticalInviteBanner, HorizontalInviteBanner, InviteCard };
