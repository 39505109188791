import ButtonBase from '@mui/material/ButtonBase';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from 'tss-react/mui';

const styles = makeStyles()({
  expandNotes: {
    color: '#808080',
    marginLeft: 3,
    height: 'fit-content',
    '& svg': {
      width: 15,
      height: 15
    }
  }
});

interface ComponentProps {
  isOpen: boolean;
  onClick: () => void;
}

const ExpandIcon = ({ isOpen, onClick }: ComponentProps) => {
  const { classes } = styles();
  return (
    <ButtonBase onClick={onClick} className={classes.expandNotes}>
      {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
    </ButtonBase>
  );
};

export default ExpandIcon;
