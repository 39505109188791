import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useWalkthrough } from 'hooks/useWalkthrough';

import { WALKTHROUGHS } from 'types/custom';
import reportWalkthroughImage from 'assets/icons/report-no-data.png';

export const Walkthrough = () => {
  const { classes } = useClasses();
  const { handleWalkthrough: handleNewReport, loading: newReportLoading } = useWalkthrough(
    WALKTHROUGHS.REPORTS,
    '/reports?action=create'
  );
  const { handleWalkthrough: handleMaybeLater, loading: maybeLaterLoading } = useWalkthrough(
    WALKTHROUGHS.REPORTS,
    '/reports'
  );

  return (
    <Box className={classes.root} mt='50px'>
      <Grid container justifyContent='center' direction='column'>
        <Box mb={'44px'}>
          <Typography style={{ color: '#333333', fontWeight: 400, fontSize: 28 }} align='center'>
            Reporting
          </Typography>
        </Box>
        <Grid container justifyContent='center'>
          <img alt='campaign set up' src={reportWalkthroughImage} className={classes.image} />
        </Grid>

        <Box mb='48px' mt='20px'>
          <Typography style={{ fontSize: 16, lineHeight: '24px', color: '#333333' }} align='center'>
            Live performance reports help you measure and share the success of your campaigns with brands. You can
            filter the content by the #hashtag or @mention you used and the insights will update every hour, forever.
          </Typography>
        </Box>
        <Grid container justifyContent='space-between' wrap='nowrap'>
          <Button variant='contained' size='large' className={`${classes.button}`} onClick={handleNewReport}>
            {newReportLoading && <CircularProgress style={{ marginRight: 10 }} size={20} />}
            Create a New Report
          </Button>
          <Button
            variant='outlined'
            size='large'
            className={`${classes.button} ${classes.smallButton}`}
            onClick={handleMaybeLater}
          >
            {maybeLaterLoading && <CircularProgress style={{ marginRight: 10 }} size={20} />}
            Maybe later
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

const useClasses = makeStyles()((theme) => {
  return {
    root: {
      maxWidth: 574
    },
    button: {
      minWidth: '277px'
    },
    buttonText: {
      color: '#FFFFFF',
      fontWeight: 500,
      fontSize: 16
    },
    smallButton: {
      fontSize: 14,
      textTransform: 'none'
    },
    image: {
      height: 300
    }
  };
});
