import { ReportsTile } from 'components/pages/ReportPage';
import { useUserConfig } from 'hooks/useUserConfig';
import React, { useState } from 'react';
import { WALKTHROUGHS } from 'types/custom';
import { Walkthrough } from './Walkthrough';

const ReportPage = () => {
  const [latestReportId, setLatestReportId] = useState('');
  const { config } = useUserConfig();
  const hasWalkedReports = config?.walkthrough?.includes(WALKTHROUGHS.REPORTS);

  const _updateNewestReportId = (reportId: string) => {
    setLatestReportId(reportId);
  };

  return hasWalkedReports ? (
    <ReportsTile latestReportId={latestReportId} updateNewestReportId={_updateNewestReportId} />
  ) : (
    <Walkthrough />
  );
};

export default ReportPage;
