import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Loader } from 'components/Loader';
import useSubscribePopupCtx from 'context/useSubscribePopupCtx';
import moment from 'moment';
import { useErrorHandlerHook } from '../../hooks/useErrorCatchHook';
import { useGetUserPaymentHistoryQuery } from '../../types/generated';
import PaymentMethods from '../subscribe/PaymentMethods';
import StripePaymentMethods from '../subscribe/StripePaymentMethods';
import getPlan from '../subscribe/getPlan';
import LimitTracker from './LimitTracker';

const freeSubscriptions = ['kitly-usd-0'];

const unlimitedSubscriptions = ['kitly-usd-2', 'kitly-usd-2-monthly', 'kitly-usd-2-yearly'];

const MembershipSettings = () => {
  const theme = useTheme();
  const { palette } = theme;
  const { apolloHandler } = useErrorHandlerHook();

  const { setSelectedPlanId } = useSubscribePopupCtx();
  const [editMembershipSettings, setEditMembershipSettings] = useState(false);

  const { data: userData, loading: userLoading, error: userError } = useGetUserPaymentHistoryQuery();

  useEffect(() => {
    if (userError) {
      apolloHandler(userError, `Unable to get current invoice settings`);
    }
  }, [userError, apolloHandler]);

  const user = userData?.myUser;
  const planId = user?.activeSubscription || '';
  const isLimited = !unlimitedSubscriptions.includes(planId);

  const braintreeSubscription = user?.braintreeSubscription;
  const stripeCustomer = user?.stripeCustomer;
  const stripeSubscription = stripeCustomer?.firstSubscription;
  const currentPlan = planId ? getPlan(planId) : null;
  const isFreeSubscription = freeSubscriptions.includes(planId);

  if (userLoading) {
    return <Loader />;
  }

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} md={12} style={{ position: 'relative', marginBottom: 20 }}>
          <Paper style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
            <Grid
              item
              style={{
                paddingTop: 20,
                paddingLeft: 30,
                paddingBottom: 20,
                borderBottom: `1px solid ${palette.background.default}`
              }}
            >
              <SettingsSectionHeader heading='Subscription' />
            </Grid>
            <Grid item container>
              <Grid
                item
                container
                sm={6}
                direction='column'
                alignContent='center'
                alignItems='center'
                style={{
                  backgroundColor: palette.background.default,
                  padding: 20
                }}
              >
                <Grid item>You are currently on the</Grid>
                <Grid item>
                  <div style={{ fontWeight: 'bold', marginTop: 10, marginBottom: 10 }}>
                    {` ${currentPlan?.price || 'free'} account`}
                  </div>
                </Grid>
                <Grid item>
                  <Button
                    data-cy='edit-membership-button'
                    onClick={() => setSelectedPlanId('kitly-usd-1')}
                    color='primary'
                    variant='outlined'
                  >
                    Upgrade
                  </Button>
                </Grid>
                {editMembershipSettings && (
                  <>
                    {Boolean(!isFreeSubscription) && (
                      <Grid item style={{ margin: 5 }}>
                        <Button
                          data-cy='cancel-payment-button'
                          onClick={() => setSelectedPlanId('kitly-usd-0')}
                          color='primary'
                          variant='outlined'
                        >
                          Cancel Subscription
                        </Button>
                      </Grid>
                    )}
                    {stripeSubscription?.current_period_end &&
                      parseFloat(stripeSubscription?.current_period_end || '') > 0 && (
                        <Grid item style={{ margin: 5 }}>
                          Next billing date: <span>{moment(stripeSubscription?.current_period_end).format('ll')}</span>
                        </Grid>
                      )}
                    {braintreeSubscription?.nextBillingDate &&
                      parseFloat(braintreeSubscription.nextBillAmount || '') > 0 && (
                        <Grid item style={{ margin: 5 }}>
                          Next billing date: <span>{moment(braintreeSubscription.nextBillingDate).format('ll')}</span>
                        </Grid>
                      )}
                  </>
                )}
                <Grid item>
                  {currentPlan && (
                    <ButtonBase
                      data-cy='edit-membership-button'
                      onClick={() => setEditMembershipSettings(!editMembershipSettings)}
                      color='primary'
                      style={{ marginLeft: 10 }}
                    >
                      {editMembershipSettings ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                    </ButtonBase>
                  )}
                </Grid>
              </Grid>
              <LimitTracker unlimited={!isLimited} />
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12} style={{ position: 'relative', marginBottom: 20 }}>
          <Paper style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
            <Grid
              item
              style={{
                paddingTop: 20,
                paddingLeft: 30,
                paddingBottom: 20,
                borderBottom: `1px solid ${palette.background.default}`
              }}
            >
              <SettingsSectionHeader heading='Billing' />
            </Grid>
            <Grid item container>
              <Grid item xs={12} style={{ padding: '10px 30px' }}>
                {stripeSubscription ? <StripePaymentMethods /> : <PaymentMethods />}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

const SettingsSectionHeader = ({ heading }: { heading: string }) => (
  <Typography style={{ fontSize: 16, fontWeight: 500 }}>{heading}</Typography>
);

export default MembershipSettings;
