import React, { CSSProperties, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Accordion, Typography, AccordionDetails, AccordionSummary } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import q83API from 'api/q83API';
import { useErrorHandlerHook } from 'hooks/useErrorCatchHook';

const paperStyles = makeStyles()(() => ({
  root: {
    margin: `0 !important`,
    zIndex: 1201
  },
  expandIcon: {
    paddingTop: 0,
    paddingBottom: 0
  }
}));

interface ISystemAlert {
  onChangeDisplay?: (offset: number) => void;
}

const SystemAlert = ({ onChangeDisplay }: ISystemAlert) => {
  const [systemAlert, setSystemAlert] = useState<any>();
  const { axiosHandler } = useErrorHandlerHook();

  const { classes: paperClasses } = paperStyles();

  useEffect(() => {
    // Only start request once axiosHandler is set
    if (axiosHandler) {
      q83API
        .get('content/page/6D1Jm1hs8N1svu6aidQiGS')
        .then((response) => {
          if (response?.data) {
            setSystemAlert(response.data);
          }
        })
        .catch((error) => {
          axiosHandler(error);
        });
    }
  }, [axiosHandler]);

  useEffect(() => {
    if (systemAlert?.shouldDisplay === true) {
      if (onChangeDisplay) {
        onChangeDisplay(50);
      }
    } else {
      if (onChangeDisplay) {
        onChangeDisplay(0);
      }
    }
  }, [onChangeDisplay, systemAlert]);

  if (systemAlert?.shouldDisplay === true) {
    let alertStyle: CSSProperties = { border: 'none' };
    let alertFontStyle: CSSProperties = {};

    if (systemAlert.status === 1) {
      alertStyle.backgroundColor = 'rgb(255,69,58)';
      alertFontStyle.color = '#ffffff';
    } else if (systemAlert.status === 2) {
      alertStyle.backgroundColor = '#FFD015';
    }

    return (
      <Accordion style={alertStyle} classes={paperClasses}>
        <AccordionSummary
          classes={{ expandIconWrapper: paperClasses.expandIcon }}
          expandIcon={<ExpandMoreIcon fontSize='large' />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <Typography style={alertFontStyle}>{systemAlert.title}</Typography>
        </AccordionSummary>
        <AccordionDetails style={alertFontStyle}>
          <div dangerouslySetInnerHTML={{ __html: systemAlert.body }} />
        </AccordionDetails>
      </Accordion>
    );
  }

  return null;
};

export default SystemAlert;
