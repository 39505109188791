import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Grid,
  IconButton,
  TableCell,
  TableRow,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Button
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import moreHozIcon from 'assets/icons/more-horizontal.svg';
import fbIcon from 'assets/images/icon-facebook.svg';
import instaIcon from 'assets/images/icon-instagram.svg';
import ytIcon from 'assets/images/yt_logo.svg';
import {
  MediaKitStatus,
  SocialChannel,
  useGetMediaKitConfigListQuery,
  useDeleteMediaKitConfigMutation,
  GetMediaKitConfigListDocument,
  GetMyUserUsageDocument,
  GetMediaKitLinkListDocument
} from 'types/generated';
import MediaKitOptionsMenu from './MediaKitOptionsMenu';
import ShareMediaKitModal from './ShareMediaKitModal';
import { find, compact } from 'lodash';
import { useErrorHandlerHook } from 'hooks/useErrorCatchHook';
import { useSnackbar } from 'notistack';
import { SNACK_BAR_MESSAGES } from 'lib/snackbarMessages';
import NameMediaKitModal from './NameMediaKitModal';
import { formatDate } from 'lib/formatDates';
import AppConfig from 'config/appConfig';
import ButtonBase from '@mui/material/ButtonBase';

export const SavedTemplates = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { apolloHandler } = useErrorHandlerHook();
  const { data, refetch } = useGetMediaKitConfigListQuery({ fetchPolicy: 'network-only' });

  const [deleteKit, { loading: deleteLoading, error: deleteError }] = useDeleteMediaKitConfigMutation({
    onCompleted: () => {
      enqueueSnackbar(SNACK_BAR_MESSAGES.MEDIA_KIT_DELETED);
    },
    refetchQueries: [
      { query: GetMediaKitConfigListDocument },
      { query: GetMyUserUsageDocument },
      { query: GetMediaKitLinkListDocument }
    ],
    awaitRefetchQueries: true
  });
  const headers = ['Media Kit Name', 'Status', 'Channel', 'Created', 'Last Updated', null, null];
  const rows = data?.getMediaKitConfigList || [];
  const [renameMediaKitId, setRenameMediaKitId] = useState<string | undefined>(undefined);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedMediaKitId, setSelectedMediaKitId] = useState<undefined | string>();
  const [sharingMediaKitId, setSharingMediaKitId] = useState<string | undefined>();

  useEffect(() => {}, [apolloHandler, deleteError]);

  const selectedMediaKit = useMemo(
    () =>
      selectedMediaKitId && data?.getMediaKitConfigList
        ? find(data?.getMediaKitConfigList, { _id: selectedMediaKitId })
        : null,
    [data, selectedMediaKitId]
  );

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedMediaKitId(undefined);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
    setAnchorEl(event.currentTarget);
    setSelectedMediaKitId(id);
  };

  const handleToggleNameModal = () => {
    setRenameMediaKitId(undefined);
  };

  const renderNameModal = () => {
    if (!renameMediaKitId) return null;
    const mediaKitName =
      find(rows, (o) => {
        return Boolean(o?._id && o._id === renameMediaKitId);
      })?.title || '';
    return (
      <NameMediaKitModal
        mediaKitId={renameMediaKitId}
        mediaKitName={mediaKitName}
        isOpen={true}
        toggleModal={handleToggleNameModal}
        refetchKits={refetch}
      />
    );
  };

  const renderSocialChannels = (socialChannels: SocialChannel[] | undefined | null) => {
    if (!socialChannels?.length) return null;

    return (
      <Grid container style={{ columnGap: 9.5 }}>
        {socialChannels.map((o, i) => {
          const iconSource =
            o === SocialChannel.Instagram
              ? instaIcon
              : o === SocialChannel.Facebook
              ? fbIcon
              : o === SocialChannel.Youtube
              ? ytIcon
              : '';

          return (
            <Grid item key={i}>
              <img alt={o} src={iconSource} style={{ height: 18 }} />
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const renderTable = () => {
    return (
      <TableContainer>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              {headers.map((o, i) => (
                <TableCell key={i}>
                  <Typography>{o}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((o) =>
              o?._id ? (
                <TableRow key={o._id}>
                  <TableCell component='th' scope='row'>
                    <ButtonBase
                      onClick={() => {
                        window.open(`${AppConfig.MEDIAKIT_URL}/#/${o.isEmpty ? 'preview' : 'view'}/${o._id}`, '_blank');
                      }}
                    >
                      {o.title}
                    </ButtonBase>
                  </TableCell>
                  <TableCell>
                    <Typography
                      className={classes.templateStatusContainer}
                      style={{
                        backgroundColor: o.status === MediaKitStatus.Draft ? '#FFD015' : '#C2EDE8'
                      }}
                    >
                      {o.status}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {renderSocialChannels(compact(o.socialChannels?.map((channel) => channel as SocialChannel)))}
                  </TableCell>
                  <TableCell>{formatDate(o.created_at)}</TableCell>
                  <TableCell>{formatDate(o.updated_at)}</TableCell>

                  <TableCell align='right' style={{ paddingRight: 16 }}>
                    <Button
                      style={{ minWidth: 84 }}
                      variant='outlined'
                      color='primary'
                      onClick={() => setSharingMediaKitId(o._id!)}
                      size='small'
                    >
                      Share
                    </Button>
                  </TableCell>
                  <TableCell>
                    {Boolean(deleteLoading && selectedMediaKitId === o._id) ? (
                      <CircularProgress />
                    ) : (
                      <IconButton
                        aria-label='more'
                        aria-controls='long-menu'
                        aria-haspopup='true'
                        style={{ padding: 0 }}
                        onClick={(e) => handleMenuClick(e, o._id!)}
                      >
                        <img src={moreHozIcon} alt='more icon' style={{ height: 24, width: 24 }} />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ) : null
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Box mt='18px'>
      <Box mb={'10px'}>
        <Typography variant='h4'>Saved Media Kit Templates</Typography>
      </Box>
      {renderNameModal()}
      {renderTable()}
      {selectedMediaKit && (
        <MediaKitOptionsMenu
          anchorRef={anchorEl}
          handleClose={handleClose}
          mediaKitConfig={selectedMediaKit}
          isDraft={selectedMediaKit.status === MediaKitStatus.Draft}
          handleEdit={(id) => navigate(`/mediakit-edit/${id}`)}
          handleRename={() => {
            setRenameMediaKitId(selectedMediaKitId);
          }}
          handleDelete={(id) => deleteKit({ variables: { id } })}
        />
      )}
      {sharingMediaKitId && (
        <ShareMediaKitModal
          isModalOnly={true}
          externalShareMetricsOpen={true}
          onComplete={() => {
            setSharingMediaKitId(undefined);
          }}
          selectedMediaKitId={sharingMediaKitId}
          onExternalToggle={() => setSharingMediaKitId(undefined)}
        />
      )}
    </Box>
  );
};

const useStyles = makeStyles()({
  templateStatusContainer: {
    borderRadius: 5,
    fontSize: 10,
    color: '#000000',
    fontWeight: 600,
    padding: '1px 8px',
    display: 'inline-block',
    minWidth: 58,
    textAlign: 'center'
  }
});
