import React from 'react';
import { useTheme } from '@mui/material';

const ReportingIcon = () => {
  const theme = useTheme();

  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>AA478328-4A24-4DD4-8990-55D1412E8C6A@1x</title>
      <g id='-' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='Kitlfy-for-Business-Style-Guide_2'
          transform='translate(-891.000000, -329.000000)'
          stroke={theme.palette.primary.dark}
        >
          <g id='Icon/Talent-Copy-5' transform='translate(891.000000, 329.000000)'>
            <g id='p-chart' transform='translate(11.500000, 11.500000) scale(-1, 1) translate(-11.500000, -11.500000) '>
              <path
                d='M13.5,4.5 C18.471,4.5 22.5,8.529 22.5,13.5 C22.5,18.471 18.471,22.5 13.5,22.5 C8.529,22.5 4.5,18.471 4.5,13.5'
                id='Path'
                strokeLinecap='square'
              ></path>
              <path d='M9.5,0.5 C4.529,0.5 0.5,4.529 0.5,9.5 L9.5,9.5 L9.5,0.5 Z' id='Path'></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ReportingIcon;
