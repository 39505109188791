import React from 'react';

import Dialog from '@mui/material/Dialog';
import { useMobileView } from '../../hooks/useMobileView';
import Payment from './Payment';

interface IPaymentPopup {
  open?: boolean;
  handlePopup?: () => void;
  refetch?: () => void;
}

const PaymentPopup = ({ open, handlePopup, refetch }: IPaymentPopup) => {
  const [mobileView] = useMobileView();

  return (
    <Dialog
      open={Boolean(open)}
      onClose={(event, reason) => {
        if (handlePopup && reason !== 'backdropClick') {
          handlePopup();
        }
      }}
      maxWidth={false}
      fullScreen={mobileView}
    >
      <Payment
        handleCancel={() => {
          if (handlePopup) {
            handlePopup();
          }
        }}
        handleComplete={() => {
          if (handlePopup) {
            handlePopup();
          }
          if (refetch) {
            refetch();
          }
        }}
      />
    </Dialog>
  );
};

export default PaymentPopup;
