import React from 'react';

export const CheckTick = ({ fill, size }: { fill?: string; size?: number }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' height={size || '16'} width={size || '16'} viewBox='0 0 16 16'>
      <title>check single</title>
      <g fill={fill || '#212121'} className='nc-icon-wrapper'>
        <path
          d='M6,15a1,1,0,0,1-.707-.293l-5-5A1,1,0,1,1,1.707,8.293L5.86,12.445,14.178.431a1,1,0,1,1,1.644,1.138l-9,13A1,1,0,0,1,6.09,15C6.06,15,6.03,15,6,15Z'
          fill={fill || '#212121'}
        ></path>
      </g>
    </svg>
  );
};
