import React, { useState, useEffect } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import ButtonBase from '@mui/material/ButtonBase';
import NotesModal from './NotesModal';
import NotesIcon from '../../../assets/components/NotesIcon';
import { useUpdateDeliverableMutation, Maybe } from 'types/generated';
import { useErrorHandlerHook } from 'hooks/useErrorCatchHook';
import useTracking from 'context/useTrackingCtx';

interface NotesProps {
  deliverableNotes?: Maybe<string> | undefined;
  deliverableId: string;
  deliverableName: string;
  canEdit?: boolean;
}

const Notes = (props: NotesProps): JSX.Element => {
  const { deliverableNotes, deliverableId } = props;

  const [notesModalOpen, setNotesModalOpen] = useState<boolean>(false);
  const [payloadNotes, setPayloadNotes] = useState<string>(deliverableNotes || '');
  const mobileView = useMediaQuery('(max-width: 500px)');
  const { trackEvent } = useTracking();

  const [updateDeliverable, { loading: updateLoading, error }] = useUpdateDeliverableMutation();
  const loading = updateLoading;

  const { apolloHandler } = useErrorHandlerHook();

  useEffect(() => {
    if (error) {
      apolloHandler(error);
    }
  }, [error, apolloHandler]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setPayloadNotes(event.target.value);
  };

  const handleToggleNotesModal = () => {
    setNotesModalOpen(!notesModalOpen);
  };

  const handleSaveNotes = () => {
    if (deliverableId && payloadNotes) {
      updateDeliverable({
        variables: {
          id: deliverableId,
          updates: {
            notes: payloadNotes
          }
        }
      }).then(() => {
        trackEvent('deliverable', 'updated deliverable');
        handleToggleNotesModal();
      });
    }
  };

  return (
    <>
      {notesModalOpen && (
        <NotesModal
          modalOpen={notesModalOpen}
          toggleModal={handleToggleNotesModal}
          changeNotes={props.canEdit ? handleChange : undefined}
          saveNotes={props.canEdit ? handleSaveNotes : undefined}
          heading={props.deliverableName}
          notes={payloadNotes}
          loading={loading}
          mobileView={mobileView}
        />
      )}
      <ButtonBase data-cy={`${deliverableId}-notes-button`} onClick={handleToggleNotesModal}>
        <NotesIcon />
      </ButtonBase>
    </>
  );
};

export default Notes;
