import Chip, { ChipProps } from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';
import ClearIcon from '@mui/icons-material/Clear';

export const CustomChip = ({ variant, color, style, ...otherProps }: ChipProps) => {
  const theme = useTheme();

  const textColor = variant === 'filled' ? theme.palette.common.white : theme.palette.common.black;

  return (
    <Chip
      {...otherProps}
      style={
        style
          ? style
          : {
              fontSize: '14px',
              height: '28px',
              alignItems: 'center',
              marginRight: 5,
              color: textColor,
              fontWeight: 600
            }
      }
      deleteIcon={<ClearIcon style={{ color: textColor, height: 16 }} />}
      variant={variant || 'outlined'}
      color={color || 'secondary'}
    />
  );
};
