import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { EditBoxProps } from './MediaKitEditBox';

import { Typography, Button, Avatar, Box, IconButton, CircularProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageIcon from '@mui/icons-material/Image';
import { useFileReader } from '../../../hooks/useFileReader';
import Slider from 'react-slick';
import { GalleryInput } from 'types/generated';
import { isGalleryContent, isGalleryInput } from '../../../types/custom';

import compact from 'lodash/compact';

import { useDropzone } from 'react-dropzone';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const styles = makeStyles()((theme) => ({
  error: { color: 'red' },
  imageEditContainer: { display: 'flex', justifyContent: 'center' },
  imageEditor: { width: '100%' },
  imageSection: { backgroundColor: theme.palette.info.light, padding: 10, marginBottom: 10 },
  imagePreview: { width: '100%', height: 120 },
  imagePlaceholder: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: 16,
    color: theme.palette.primary.main,
    textAlign: 'center',
    padding: 12
  },
  imagePlaceholderTitle: {
    color: theme.palette.primary.main,
    margin: 4
  },
  slider: { width: '100%' },
  galleryImage: {
    position: 'relative',
    '& img': {
      height: 120,
      marginRight: 6,
      marginLeft: 6,
      objectFit: 'contain'
    }
  },
  galleryPreviewContainer: {
    padding: 10,
    paddingTop: 20,
    backgroundColor: theme.palette.info.dark,
    borderBottomColor: 'white',
    borderBottomStyle: 'solid',
    borderBottomWidth: 1
  },
  galleryImagePlaceholder: {
    margin: '0 auto',
    borderStyle: 'dashed',
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
    height: 120,
    width: 120
  },
  galleryImageActions: {
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 1
  },
  previewTitle: {
    color: 'white',
    fontSize: 16,
    fontWeight: 500
  },
  imageUploadSubtitle: {
    fontStyle: 'italic',
    marginTop: 4
  }
}));

const maximumGallerySize = 12;

const GalleryBox = ({ element: { content, title }, handleUpdate, isEditing }: EditBoxProps) => {
  const { classes } = styles();
  const [galleryContent, setGalleryContent] = useState<Array<string | File | GalleryInput>>([]);
  const { readFile, readFileLoading, getCachedImage } = useFileReader();

  const imagesToDisplay = useMemo(() => {
    return compact(
      galleryContent.map((o) => {
        if (typeof o === 'string') {
          return o;
        }
        if (isGalleryInput(o)) {
          return o.link;
        }
        return getCachedImage(o.name);
      })
    );
  }, [galleryContent, getCachedImage]);

  useEffect(() => {
    if (!isEditing) {
      if (isGalleryContent(content) && content?.gallery_content) {
        const newGalleryContent: any = compact(content.gallery_content);
        setGalleryContent(newGalleryContent);
      } else {
        setGalleryContent([]);
      }
    }
  }, [content, setGalleryContent, isEditing]);

  const dropImage = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file: File) => {
        readFile(file);
      });
      setGalleryContent([...galleryContent, ...acceptedFiles.slice(0, 12)]);
    },
    [readFile, setGalleryContent, galleryContent]
  );

  const handleRemoveImage = (index: number) => {
    setGalleryContent(galleryContent.filter((o, i) => i !== index));
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: dropImage,
    accept: 'image/jpg, image/jpeg, image/png, image/heic, image/heif',
    maxSize: 100000000
  });

  if (isEditing) {
    return (
      <>
        <Box className={classes.imageSection}>
          <GallerySlider classes={classes} content={imagesToDisplay} onPress={(index) => handleRemoveImage(index)} />
          <Box className={classes.imageEditContainer}>
            <div {...getRootProps()} className={classes.imageEditor}>
              <input {...getInputProps()} />

              <Box className={classes.imagePlaceholder}>
                {readFileLoading ? (
                  <CircularProgress />
                ) : (
                  <>
                    <ImageIcon fontSize={'large'} />
                    <Typography
                      className={classes.imagePlaceholderTitle}
                    >{`Drop up to ${maximumGallerySize} images or`}</Typography>
                    <Button variant='outlined' color='primary'>
                      Browse
                    </Button>
                    <Typography variant='caption' className={classes.imageUploadSubtitle}>
                      Accepts JPEG or PNG images up to 100MB
                    </Typography>
                  </>
                )}
              </Box>
            </div>
          </Box>
        </Box>
        <Button
          variant='outlined'
          color='primary'
          onClick={() => {
            handleUpdate({
              ...content,
              gallery_content: galleryContent.slice(0, maximumGallerySize).map((o) => {
                if (typeof o === 'string') {
                  return { link: o };
                }

                if (isGalleryInput(o)) {
                  return o;
                }
                return { file: o };
              })
            });
          }}
        >
          {`Save & Close`}
        </Button>
      </>
    );
  }

  return (
    <Box className={classes.galleryPreviewContainer}>
      <Typography className={classes.previewTitle}>{title}</Typography>
      <GallerySlider classes={classes} content={imagesToDisplay} />
    </Box>
  );
};

const GallerySlider = ({
  content,
  onPress,
  classes
}: {
  content: Array<string>;
  onPress?: (index: number) => void;
  classes: any;
}) => {
  const settings = {
    dots: true,
    dotsClass: 'slider-dots',
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1
  };

  return (
    <Slider {...settings} className={classes.slider}>
      {padArray(content, 'blank', 3).map((o, i) => {
        if (o === 'blank') {
          return (
            <div key={i} className={classes.galleryImage}>
              <div className={classes.galleryImagePlaceholder} />
            </div>
          );
        }
        return (
          <div key={i} className={classes.galleryImage}>
            {onPress && (
              <IconButton onClick={() => onPress(i)} className={classes.galleryImageActions}>
                <DeleteIcon />
              </IconButton>
            )}
            <Avatar src={o} variant='square' className={classes.imagePreview} />
          </div>
        );
      })}
    </Slider>
  );
};

const padArray = (array: Array<string>, content: any, length: number) => {
  let returnArray: string[] = [];

  for (let i = 0; i < length; i++) {
    if (array[i]) {
      returnArray.push(array[i]);
    } else {
      returnArray.push(content);
    }
  }

  return returnArray;
};

export default GalleryBox;
