const TagIcon = ({ stroke }: { stroke?: string }) => {
  return (
    // <?xml version="1.0" encoding="UTF-8"?>
    <svg width='16px' height='17px' viewBox='0 0 16 17' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <title>34B6B3B8-0E1E-4ABE-B4D1-15F63FC87B05@1x</title>
      <g
        id='Style-Guide'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <g
          id='Kitlfy-for-Business-Style-Guide_2'
          transform='translate(-834.000000, -466.000000)'
          stroke={stroke || '#5C5CB9'}
        >
          <g id='Icon/16px/tag' transform='translate(835.000000, 467.000000)'>
            <g id='tags-stack'>
              <path
                d='M8.621,14.707 L12.707,10.621 C13.0973819,10.2305001 13.0973819,9.59749985 12.707,9.207 L5.5,2 L-3.04645198e-13,2 L-3.04645198e-13,7.5 L7.207,14.707 C7.59749985,15.0973819 8.23050015,15.0973819 8.621,14.707 Z'
                id='Path'
              ></path>
              <polyline id='Path' points='14 7.5 6.5 0 1 0'></polyline>
              <circle id='Oval' cx='3.5' cy='5.5' r='1.5'></circle>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default TagIcon;
