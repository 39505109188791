import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Loader } from 'components/Loader';
import { isEmpty, orderBy } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { useGetCollaborationListQuery } from 'types/generated';
import { Calendar } from './Calendar';
import { Card } from './Card';

export const DeliverablesTile = () => {
  const { classes } = useStyles();
  const [selectedDeliverableDate, setSelectedDeliverableDate] = useState('');
  const { loading: queryLoading, data } = useGetCollaborationListQuery();

  const collaborationList = data?.getCollaborationList || [];

  const deliverableList =
    collaborationList?.reduce<any[]>((acc, curr) => {
      if (!isEmpty(curr?.deliverables))
        acc = acc.concat(
          curr?.deliverables?.map((o) => ({
            ...o,
            collaboration: { name: curr.name, _id: curr._id, status: curr.status }
          }))
        );
      return acc;
    }, []) || [];

  const hasDeliverables = !isEmpty(deliverableList);
  const upcomingDeliverables = orderBy(
    deliverableList.filter((deliverable) => {
      return moment(`${deliverable.endDate} ${deliverable.endTime}`, 'YYYY-MM-DD hhmm').isAfter(moment());
    }),
    ['endDate', 'endTime'],
    ['asc']
  );

  const renderContent = () => {
    if (queryLoading) {
      return <Loader />;
    }

    return (
      <>
        <Grid item xs={12}>
          <Typography variant='h6' style={{ fontSize: 12 }}>
            {hasDeliverables ? 'Upcoming Deliverables' : 'No deliverables assigned yet'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {upcomingDeliverables.map((o) => (
            <Card key={`deliverablecard-${o._id}`} deliverable={o} selectedDate={selectedDeliverableDate} />
          ))}
        </Grid>
      </>
    );
  };

  return (
    <Grid item container className={classes.tileContainer}>
      <Grid item xs={12}>
        <Typography variant='h6'>Deliverables</Typography>
      </Grid>
      <Grid item xs={12}>
        <Calendar
          deliverableList={deliverableList}
          selectedDate={selectedDeliverableDate}
          setSelectedDate={setSelectedDeliverableDate}
        />
      </Grid>
      {renderContent()}
    </Grid>
  );
};

const useStyles = makeStyles()(() => {
  return {
    tileContainer: {
      backgroundColor: '#FCF0F5',
      padding: '11px 37px 30px'
    }
  };
});
