import React from 'react';

import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import { channelIcons } from 'lib/constants';
import { Profile, SocialChannel } from '../../../types/generated';
import { useTheme } from '@mui/material';
import Tick from '../../../assets/components/Tick';

const backupUserImg = 'https://media.q-83.com/site/user.png';

interface IProfileListItem {
  profile: Profile;
  onPress?: () => void;
  isActive?: boolean;
}

const ProfileListItem = ({ profile, onPress, isActive }: IProfileListItem) => {
  const theme = useTheme();

  let subtitle = '';

  switch (profile.profileType) {
    case SocialChannel.Instagram:
      subtitle = profile.followers_count ? `${profile.followers_count} followers` : '';
      break;
    case SocialChannel.Facebook:
      const fansCount = profile.fan_count || profile.pageStats?.page_fans;
      subtitle = fansCount ? `${fansCount} fans` : '';
      break;
    case SocialChannel.Youtube:
      subtitle = profile.channelStats?.subscriberCount ? `${profile.channelStats.subscriberCount} subscribers` : '';
      break;
    case SocialChannel.Tiktok:
      subtitle = profile.public?.stats?.followerCount ? `${profile.public.stats.followerCount} followers` : '';
      break;
    default:
      break;
  }

  const channel = profile?.profileType?.toLowerCase() || '';
  const channelLogo = channelIcons[channel];

  const ProfilePic = () => (
    <>
      <div style={{ position: 'relative' }}>
        <Avatar
          src={profile.profile_picture_url || backupUserImg}
          onError={(e) => {
            e.persist();
            // @ts-ignore
            e.target.src = backupUserImg;
          }}
          style={{ width: 32, height: 32, margin: '6px 20px 6px 10px' }}
        />
        {channelLogo && (
          <Avatar
            src={channelLogo}
            style={{
              height: 12,
              width: 12,
              position: 'absolute',
              bottom: 5,
              right: 10,
              backgroundColor: '#FFF',
              borderRadius: '100%',
              border: 'solid #FBCDDF 0.5px',
              padding: 2
            }}
          />
        )}
      </div>
    </>
  );

  const Handle = () => (
    <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '150px' }}>
      <div className='line-clamp clamp-1' style={{ fontSize: 12 }}>
        {profile.displayName}
      </div>
      {subtitle && (
        <div className='line-clamp clamp-1' style={{ fontSize: 10 }}>
          {subtitle}
        </div>
      )}
    </div>
  );

  return (
    <div
      style={{
        height: '44px',
        border: `solid ${theme.palette.primary.main} 0.5px`,
        borderRadius: '3px',
        margin: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        backgroundColor: '#FFF'
      }}
    >
      <div
        style={{ display: 'flex', alignItems: 'center', height: '100%', width: '100%', borderRadius: '3px 0 0 3px' }}
      >
        <ProfilePic />
        <Handle />
      </div>
      <div>
        {onPress && (
          <Checkbox
            checked={isActive}
            disabled={!Boolean(onPress)}
            checkedIcon={<Tick active />}
            icon={<Tick />}
            onChange={onPress}
            color='primary'
          />
        )}
      </div>
    </div>
  );
};

export default ProfileListItem;
