import { useMemo } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import FileUploadsField from './FileUploadsField';
import LockIcon from 'assets/icons/lock.svg';
import { getDateDisplay, getTimeDisplay } from 'utils/utils';
import { find, isEmpty } from 'lodash';
import channels from 'lib/channels';
import deliverables from 'lib/deliverables';
import { Deliverable } from 'types/generated';

interface IDeliverableDetails {
  deliverable: Deliverable;
}

const DeliverableDetails = ({ deliverable }: IDeliverableDetails) => {
  const { classes } = useStyles();

  const selectedChannel = useMemo(() => {
    return deliverable.socialChannel ? find(channels, { value: deliverable.socialChannel.toLowerCase() }) : undefined;
  }, [deliverable]);
  const selectedDeliverable = useMemo(
    () => (deliverable.socialDeliverable ? find(deliverables, { value: deliverable.socialDeliverable }) : undefined),
    [deliverable]
  );

  const hasSelectedChannel = selectedChannel && typeof selectedChannel === 'object';

  return (
    <Box className={classes.assignedDetails}>
      <Grid container spacing={1} justifyContent={'space-between'}>
        <Grid item xs={6} container style={{ marginTop: 8 }} alignItems='center' spacing={2}>
          <Grid item>
            {hasSelectedChannel && (
              <img
                src={selectedChannel.logo}
                alt={selectedChannel.label}
                style={{ height: 24, width: 24, borderRadius: 5 }}
              />
            )}
          </Grid>
          <Grid item>
            {hasSelectedChannel && (
              <Typography>
                <b>{selectedChannel.label}</b>: {selectedDeliverable && selectedDeliverable?.label}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item xs={6} container spacing={2} justifyContent={'flex-end'} alignItems={'center'}>
          {!deliverable?.collaboration?.canEdit && (
            <>
              <Grid item>
                <Typography>These details are locked</Typography>
              </Grid>
              <Grid item>
                <img src={LockIcon} alt={'Locked details'} />
              </Grid>
            </>
          )}
        </Grid>
        <Grid item xs={8} container>
          {deliverable?.collaboration?.name && (
            <Grid item xs={12} className={classes.assignedDetailsRow}>
              <Typography>
                <b>Campaign Name</b>: {deliverable.collaboration.name}
              </Typography>
            </Grid>
          )}
          {deliverable?.collaboration?.brand?.companyName && (
            <Grid item xs={12} className={classes.assignedDetailsRow}>
              <Typography>
                <b>Client</b>: {deliverable.collaboration.brand.companyName}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} className={classes.assignedDetailsRow}>
            <Typography>
              <b>Due Date</b>: {getDateDisplay(deliverable)}
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.assignedDetailsRow}>
            <Typography>
              <b>Time</b>: {getTimeDisplay(deliverable)}
            </Typography>
          </Grid>

          {!isEmpty(deliverable?.fileUploads) && (
            <Grid item xs={12} container className={classes.assignedDetailsRow}>
              <Grid item xs={12}>
                <Typography className={classes.attachmentsTitle}>Attachments</Typography>
              </Grid>
              <FileUploadsField value={deliverable!.fileUploads!} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default DeliverableDetails;

const useStyles = makeStyles()((theme) => {
  return {
    input: {
      margin: '5px 0'
    },
    finishButton: {
      width: '100%'
    },
    error: {
      color: theme.palette.error.main,
      fontSize: 12,
      marginBottom: 10,
      textAlign: 'center'
    },
    notesTitle: {
      marginBottom: 10
    },
    assignedDetails: {
      backgroundColor: '#84C6FF17',
      paddingTop: 14,
      paddingBottom: 14,
      paddingLeft: 24,
      paddingRight: 24,
      width: '100%',
      marginBottom: 15,
      marginTop: 15
    },
    assignedDetailsRow: {
      marginTop: 3
    },
    attachmentsTitle: {
      fontSize: 14,
      marginTop: 10
    }
  };
});
