import { useState } from 'react';

export const useDialog = () => {
  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => setIsOpen(false);
  const onOpen = () => setIsOpen(true);

  const bind = {
    isOpen,
    onClose
  };
  return { bind, onOpen, onClose, isOpen };
};
