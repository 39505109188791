import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import invisibleIcon from 'assets/inputIcons/ic_invisibility.png';
import visibleIcon from 'assets/inputIcons/ic_visibility.png';

interface Props {
  onClick: () => void;
  showPassword: boolean;
}

export const TextFieldPasswordIcon = (props: Props) => {
  const { onClick, showPassword } = props;

  const { classes } = useClasses();

  return (
    <InputAdornment position='end' onClick={onClick}>
      <Box className={classes.fieldIcon} onClick={onClick}>
        {showPassword ? (
          <img src={visibleIcon} alt='Visibility icon' />
        ) : (
          <img src={invisibleIcon} alt='Visibility icon' />
        )}
      </Box>
    </InputAdornment>
  );
};

const useClasses = makeStyles()((theme) => ({
  fieldIcon: {
    cursor: 'pointer'
  }
}));
