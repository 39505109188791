import { Grid, Typography } from '@mui/material';
import { FormProps } from '../ReportEditorModel';
import ChipField from '../Chips/ChipField';
import { compact } from 'lodash';

const ReportTrackers = ({ payload, setPayload }: FormProps) => {
  let existingMentions: string[] = [];
  let existingHashtags: string[] = [];

  if (payload?.mentions?.length) {
    existingMentions = compact(payload.mentions).map((mention) => '@'.concat(mention));
  }
  if (payload?.hashtags?.length) {
    existingHashtags = compact(payload.hashtags).map((hashtag) => '#'.concat(hashtag));
  }

  let mentionsHashtagsArray = [...existingMentions, ...existingHashtags];

  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <Typography variant='body1' style={{ fontWeight: 500 }}>
          Up to 10 @mentions and #hashtags
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ChipField
          chips={mentionsHashtagsArray}
          limit={10}
          symbol='@/#'
          onChange={(chips) => {
            const mentions = chips.filter((chip) => chip[0] === '@').map((chip) => chip.substring(1));
            const hashtags = chips.filter((chip) => chip[0] === '#').map((chip) => chip.substring(1));
            setPayload(
              Object.assign({}, payload, {
                mentions,
                hashtags
              })
            );
          }}
        />
      </Grid>
    </Grid>
  );
};

export default ReportTrackers;
