import React, { useState } from 'react';
import { Grid, Typography, Divider, useTheme, CircularProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import RoundTick from '../../../assets/icons/RoundTick';
import { isEmpty } from 'lodash';

export type PageContent = {
  title: string;
  content: React.ReactNode;
  renderActions: (props: { previousPage?: () => void; nextPage?: () => void }) => React.ReactNode;
};

interface IPaginatedForm {
  pages: Array<PageContent>;
  isLoading?: boolean;
}

const PaginatedForm = ({ pages, isLoading }: IPaginatedForm) => {
  const [page, setPage] = useState(0);
  const theme = useTheme();
  const { classes } = useStyles();

  if (isEmpty(pages)) {
    return null;
  }

  return (
    <Grid container classes={{ root: classes.hiddenScrollbar }} style={{ overflowX: 'hidden', paddingLeft: 1 }}>
      <Grid
        item
        xs={12}
        container
        justifyContent='center'
        alignItems='center'
        wrap='nowrap'
        spacing={2}
        style={{ padding: 8 }}
      >
        {pages.map((o, i) => {
          return (
            <Grid item key={`page_${i}`}>
              <Grid container alignItems='center' spacing={1}>
                {i !== 0 && (
                  <Grid item>
                    <Divider
                      orientation='horizontal'
                      style={{
                        width: 33,
                        marginRight: 4,
                        backgroundColor: i < page + 1 ? theme.palette.primary.main : undefined
                      }}
                    />
                  </Grid>
                )}
                <Grid item>
                  <RoundTick
                    fill={i < page + 1 ? theme.palette.primary.main : undefined}
                    outline={page <= i}
                    style={{ display: 'block' }}
                  />
                </Grid>
                <Grid item style={{ maxWidth: pages.length > 2 ? 90 : 120 }}>
                  <Typography
                    style={{
                      fontWeight: 500,
                      fontSize: 16,
                      lineHeight: '22px',
                      color: i < page + 1 ? theme.palette.primary.main : '#D8D8D8',
                      marginLeft: 10
                    }}
                  >
                    {o.title}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      <Grid item xs={12}>
        {pages[page].content}
      </Grid>
      {isLoading ? (
        <Grid item xs={12} container justifyContent='center'>
          <CircularProgress />
        </Grid>
      ) : (
        <Grid item xs={12}>
          {pages[page].renderActions({
            previousPage: page > 0 ? () => setPage(page - 1) : undefined,
            nextPage: page < pages.length - 1 ? () => setPage(page + 1) : undefined
          })}
        </Grid>
      )}
    </Grid>
  );
};

export default PaginatedForm;

const useStyles = makeStyles()(() => {
  return {
    hiddenScrollbar: {
      '&::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      'scrollbar-width': 'none' /* Firefox */
    }
  };
});
