import { gql } from '@apollo/client';

export const DELETE_MEDIA_KIT_CONFIG = gql`
  mutation DeleteMediaKitConfig($id: ObjectId!) {
    deleteMediaKitConfig(id: $id) {
      success
    }
  }
`;

export const UPDATE_MEDIA_KIT = gql`
  mutation UpdateMediaKitDraftConfig($id: ObjectId!, $updates: MediaKitConfigUpdates!) {
    updateMediaKitDraftConfig(id: $id, updates: $updates) {
      success
      message
    }
  }
`;

export const CREATE_KIT_ELEMENT = gql`
  mutation CreateElement($type: ElementType, $title: String, $mediaKitId: ObjectId, $index: Int) {
    createElement(type: $type, title: $title, mediaKitId: $mediaKitId, index: $index)
  }
`;

export const PUBLISH_MEDIA_KIT = gql`
  mutation PublishMediaKitDraftConfig($id: ObjectId!) {
    publishMediaKitDraftConfig(id: $id) {
      success
      message
    }
  }
`;

export const UPDATE_KIT_ELEMENT = gql`
  mutation UpdateElementDraft($id: ObjectId!, $content: MediaKitElementContentInput) {
    updateElementDraft(id: $id, content: $content) {
      success
      message
    }
  }
`;

export const DELETE_KIT_ELEMENT = gql`
  mutation DeleteElement($id: ObjectId!) {
    deleteElement(id: $id) {
      success
      message
    }
  }
`;

export const SHARE_MEDIA_KIT_TO_CONTACT = gql`
  mutation ShareMediaKitToContact(
    $mediaKitId: ObjectId!
    $contactIds: [ObjectId]
    $coverLetter: String
    $contactDetails: MediaKitShareContact
  ) {
    shareMediaKitToContact(
      mediaKitId: $mediaKitId
      contactIds: $contactIds
      coverLetter: $coverLetter
      contactDetails: $contactDetails
    ) {
      success
      message
    }
  }
`;

export const UPDATE_PUBLISHED_MEDIA_KIT = gql`
  mutation UpdateMediaKitConfig($id: ObjectId!, $updates: MediaKitConfigUpdates!) {
    updateMediaKitConfig(id: $id, updates: $updates) {
      success
      message
    }
  }
`;

export const CREATE_MEDIA_KIT_LINK = gql`
  mutation CreateMediaKitLink($mediaKitId: ObjectId!) {
    createMediaKitLink(mediaKitId: $mediaKitId)
  }
`;

export const CREATE_MEDIA_KIT = gql`
  mutation CreateMediaKitConfig($updates: MediaKitConfigUpdates) {
    createMediaKitConfig(updates: $updates)
  }
`;

export const SHARE_APAR_LINK_TO_CONTACT = gql`
  mutation ShareAparLinkToContact(
    $aparLink: String!
    $profileId: ObjectId
    $contactId: ObjectId
    $contactIds: [ObjectId]
    $contactDetails: MediaKitShareContact
  ) {
    shareAparToContact(
      aparLink: $aparLink
      profileId: $profileId
      contactId: $contactId
      contactIds: $contactIds
      contactDetails: $contactDetails
    ) {
      success
      message
      insertedId
    }
  }
`;
