import React, { useState } from 'react';

import { RadioGroup, Radio, FormControlLabel, Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { SingleDatePicker, DateRangePicker } from 'react-dates';
import { Moment } from 'moment';

interface IDateRangePicker {
  startDate: Moment | null;
  setStartDate: (arg: Moment | null) => void;
  endDate: Moment | null;
  setEndDate: (arg: Moment | null) => void;
}

const formControlStyles = makeStyles()(() => ({
  label: {
    fontSize: 14,
    fontWeight: 500
  }
}));

const DateRangeField = ({ startDate, endDate, setStartDate, setEndDate }: IDateRangePicker) => {
  const { classes: formControlClasses } = formControlStyles();
  const [isRange, setIsRange] = useState(startDate ? 'true' : 'false');
  const [focusedInput, setFocusedInput] = useState<any | null>(null);
  const [isFocused, setIsFocused] = useState(false);

  return (
    <Grid container style={{ marginBottom: 15 }}>
      <Grid item xs={12}>
        <RadioGroup
          value={isRange}
          onChange={(event: any) => {
            setIsFocused(false);
            setFocusedInput(null);
            setIsRange(event?.target?.value);
            setStartDate(null);
          }}
        >
          <Grid container justifyContent='flex-start' direction='row'>
            <FormControlLabel
              value={'false'}
              labelPlacement={'start'}
              style={{ marginLeft: 0 }}
              classes={formControlClasses}
              control={<Radio checked={isRange === 'false'} color='primary' />}
              label='Date'
            />
            <FormControlLabel
              value={'true'}
              labelPlacement={'start'}
              classes={formControlClasses}
              control={<Radio checked={isRange === 'true'} color='primary' />}
              label='Date Range'
            />
          </Grid>
        </RadioGroup>
      </Grid>
      <Grid item xs={12} container spacing={1}>
        {isRange === 'true' ? (
          <Grid item xs={12}>
            <DateRangePicker
              startDateId={'start-date'}
              endDateId={'end-date'}
              startDate={startDate}
              endDate={endDate}
              onDatesChange={(event) => {
                setStartDate(event.startDate);
                setEndDate(event.endDate);
              }}
              focusedInput={focusedInput}
              onFocusChange={(input) => setFocusedInput(input)}
              monthFormat={'MMM YYYY'}
              displayFormat={'DD/MM/YYYY'}
              startDatePlaceholderText={'From DD/MM/YYYY*'}
              endDatePlaceholderText={'Until DD/MM/YYYY*'}
              isOutsideRange={() => false}
              daySize={25}
              small
              block
              noBorder
              hideKeyboardShortcutsPanel
            />
          </Grid>
        ) : (
          <Grid item xs={6}>
            <SingleDatePicker
              id={'single-end-date'}
              date={endDate}
              onDateChange={(date) => setEndDate(date)}
              focused={isFocused}
              onFocusChange={({ focused }) => setIsFocused(Boolean(focused))}
              monthFormat={'MMM YYYY'}
              placeholder={'DD/MM/YYYY*'}
              isOutsideRange={() => false}
              daySize={25}
              numberOfMonths={1}
              small
              block
              noBorder
              hideKeyboardShortcutsPanel
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default DateRangeField;
