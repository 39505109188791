import { gql } from '@apollo/client';

export const DELETE_REPORT_CONFIG = gql`
  mutation DeleteReportConfig($reportId: ObjectId!) {
    deleteReportConfig(reportId: $reportId) {
      success
    }
  }
`;

export const CREATE_REPORT_LINK = gql`
  mutation CreateReportLink($reportConfigId: ObjectId!) {
    createReportLink(reportConfigId: $reportConfigId)
  }
`;
