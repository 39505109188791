import { useEffect } from 'react';
import Box from '@mui/material/Box';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import Avatar from 'components/Avatar';
import AccountPerformanceCard from './Cards/AccountPerformanceCard/AccountPerformanceCard';
import { useGetCollaborationListQuery, useGetMyUserLazyQuery } from 'types/generated';
import { NoCollabsDataTile } from './CollabsTile/NoCollabsDataTile';
import { MediaKitsTile } from './MediaKitsTile';
import { ReportsTile } from './ReportsTile';
import { DeliverablesTile } from './DeliverablesTile';
import FinanceCard from './Cards/FinanceCard';
import { useToken } from 'hooks/useAuthHooks';
import { useErrorHandlerHook } from 'hooks/useErrorCatchHook';
import ContentfulAdBanner from './ContentfulAdBanner';

const rightColWidth = 360;

const Dashboard = () => {
  const { classes } = useClasses();
  const { isAuth, token } = useToken();
  const { apolloHandler } = useErrorHandlerHook();

  const [getMyUser, { data: userData, error }] = useGetMyUserLazyQuery();
  const user = userData?.myUser;

  const userFirstName = user?.firstName || '';
  const userAvatarUrl = user?.avatar || '';

  const { data: collabsData } = useGetCollaborationListQuery();
  const collabsList = collabsData?.getCollaborationList;

  useEffect(() => {
    if (isAuth && token) {
      getMyUser();
    }
  }, [isAuth, token, getMyUser]);

  useEffect(() => {
    if (error) {
      apolloHandler(error);
    }
  }, [error, apolloHandler]);

  return (
    <Box maxWidth='926px'>
      <Box className={classes.header}>
        <Avatar
          src={userAvatarUrl}
          size={40}
          avatarBackgroundColor='#FAD416'
          firstName={userFirstName}
          nameDisplay={false}
        />
        <Typography className={classes.header_text}>Dashboard</Typography>
      </Box>
      <ContentfulAdBanner style={{ marginBottom: 20 }} skeletonStyle={{ marginBottom: 20 }} adLocation='dashboard' />
      <div className={classes.body}>
        <div className={classes.mainColumn}>
          <div className={classes.mainColFirstRow}>
            <AccountPerformanceCard />
          </div>
          <div className={classes.mainColSecondRow}>
            <MediaKitsTile />
            <ReportsTile />
          </div>
        </div>
        <div className={classes.rightColumn}>
          <div className={classes.rightColContent}>
            <div style={{ width: rightColWidth }}>
              <FinanceCard />
            </div>
            <div style={{ width: rightColWidth }}>
              {!collabsList?.length ? <NoCollabsDataTile /> : <DeliverablesTile />}
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

const gridGap = 20;
const useClasses = makeStyles()((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20
  },
  header_text: {
    fontSize: 32,
    marginLeft: 20
  },
  body: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    columnGap: gridGap
  },
  mainColumn: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    rowGap: gridGap
  },
  mainColFirstRow: {
    width: 545
  },
  mainColSecondRow: {
    display: 'grid',
    gridColumn: '1 / -1',
    gridTemplateColumns: '1fr 1fr',
    columnGap: gridGap
  },
  mainColThirdRow: {
    display: 'grid',
    gridColumn: '1 / -1',
    gridTemplateColumns: '7fr 5fr',
    columnGap: gridGap
  },
  rightColumn: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '200px',
    rowGap: gridGap
  },
  rightColContent: {
    display: 'grid',
    rowGap: gridGap
  }
}));

export default Dashboard;
