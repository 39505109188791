import React, { useEffect, useState } from 'react';
// components;
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Loader } from 'components/Loader';
// query
import { useGetSingleProfileForFbQuery } from 'types/generated';
import FacebookPostSummary from './FacebookPostSummary';

interface IFacebookInsights {
  profileId: string;
  children?: any;
}

interface FacebookInsightTypes {
  profile: any;
  selectedTabIndex: number;
}

const FacebookInsights: React.FC<IFacebookInsights> = (props) => {
  const [facebookInsights, setFacebookInsights] = useState<FacebookInsightTypes>({
    profile: null,
    selectedTabIndex: 0
  });
  const { data, loading, error } = useGetSingleProfileForFbQuery({
    variables: {
      id: props.profileId
    }
  });

  // initialization
  useEffect(() => {
    if (data) {
      setFacebookInsights((prevState) => ({
        ...prevState,
        profile: data?.getSingleProfile
      }));
    }
  }, [data]);

  // LOADING COMPONENT
  const loading_component = (
    <>
      {loading && (
        <Box display='flex' justifyContent='center' alignItems='center' mt={4}>
          <Loader />
        </Box>
      )}
    </>
  );

  // facebook component
  const post_content = (
    <>
      {facebookInsights.selectedTabIndex === 0 && Boolean(facebookInsights.profile) && (
        <FacebookPostSummary profile={facebookInsights.profile} />
      )}
    </>
  );

  return (
    <>
      {loading_component}
      {(!loading || Boolean(error)) && (
        <>
          <Grid container>
            <Grid item sm={7} xs={12}>
              {post_content}
            </Grid>
            <Grid item sm={4} xs={12}>
              {props.children}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default FacebookInsights;
