import get from 'lodash/get';
import isArray from 'lodash/isArray';
import { ApolloError } from '@apollo/client';
import { AxiosError } from 'axios';

interface NameProps {
  username?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  fullName?: string | null;
  companyName?: string | null;
  name?: string | null;
}

interface InitialProps {
  firstInput: string;
  secondInput?: string;
  isSingleInitial?: boolean;
}

interface ErrorObject {
  message?: string;
}

interface ErrorProps {
  message?: string;
  error?: string | ErrorObject;
}

export const TrackingLinkActions: { [key: string]: string } = {
  swipe_ups: 'Swipe Ups',
  link_in_bio: 'Link in Bio',
  link_clicks: 'Link Clicks'
};

const fallbackError = 'Unknown error';
// For formatting error messages that return a .message or .error field:
export const formatError = (
  error: string | null | undefined | ErrorProps | ApolloError | AxiosError
): string | null => {
  if (
    typeof error === 'string' ||
    typeof error === 'undefined' ||
    error === null ||
    (typeof error === 'string' && error === '')
  ) {
    // @ts-ignore: type is checked on return
    return error;
    // pull out error messaging
  }

  if (
    error &&
    ((isAxiosError(error) && error?.message === 'Network Error') || (isApolloError(error) && error?.networkError))
  ) {
    return 'You need internet for this to work! Check your connection.';
  } else if (isArray(error)) {
    return error[0].message ? error[0].message : null;
  }
  let errorMessage =
    get(error, ['message']) || get(error, ['error', 'message']) || get(error, ['error']) || fallbackError;
  // if error message isn't a string, go to fallback
  if (typeof errorMessage !== 'string') {
    errorMessage = fallbackError;
  }
  return errorMessage;
};

const isAxiosError = (error: AxiosError | ApolloError | ErrorProps): error is AxiosError => {
  return (error as AxiosError).isAxiosError !== undefined;
};

const isApolloError = (error: AxiosError | ApolloError | ErrorProps): error is ApolloError => {
  return (error as ApolloError).graphQLErrors !== undefined || (error as ApolloError).networkError !== undefined;
};

// For formatting variants of names provided
export const formatName = (props: NameProps): string | null => {
  const { username, firstName, lastName, fullName, companyName } = props;

  let name;
  if (fullName) {
    name = fullName;
  } else if (firstName && lastName) {
    name = `${firstName} ${lastName}`;
  }

  if (username && name) {
    return `@${username} (${name})`;
  } else if (name) {
    return name;
  } else if (username) {
    return `@${username}`;
  } else if (firstName) {
    return firstName;
  } else if (companyName) {
    return companyName;
  } else {
    return null;
  }
};

export const formatInitials = (props: NameProps): string | null => {
  const { username, firstName, lastName, fullName, companyName } = props;

  let name;
  if (fullName) {
    name = fullName;
  } else if (firstName && lastName) {
    name = `${firstName} ${lastName}`;
  } else if (username && name) {
    name = username;
  } else if (companyName) {
    name = companyName;
  } else if (props.name) {
    name = props.name;
  }

  if (name) {
    let initialsArray: Array<string> = [];
    name.split(' ').forEach((o) => {
      if (o.length) {
        initialsArray.push(o[0].toUpperCase());
      }
    });

    return initialsArray.join('');
  }

  return null;
};

// Returns either:
// 1. first and second initial (firstInput + secondInput)
// 2. first letter of single input (singleInitial:true)
// 3. first two letters of single input (default for any one input)
export const getInitials = (props: InitialProps): string => {
  const { firstInput, secondInput, isSingleInitial } = props;
  let initials = '';
  let fallback = firstInput || secondInput;

  if (firstInput && secondInput) {
    const firstInitial = firstInput.slice(0, 1);
    const secondInitial = secondInput.slice(0, 1);
    initials = firstInitial + secondInitial;
  } else if (fallback && isSingleInitial) {
    initials = fallback.slice(0, 1);
  } else if (fallback) {
    initials = fallback.slice(0, 2);
  }

  return initials;
};
