import React, { useState, useEffect } from 'react';

// types
import { useGetSingleProfileForInstaQuery } from 'types/generated';
// components
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Loader } from 'components/Loader';
import { Spacer } from '../../AccountPerformance';
import InstagramFeedSummary from './InstagramFeedSummary';
// config

interface IInstagramInsights {
  profileId: string;
  children?: any;
}

interface InsProfileStateType {
  // TODO: Better to define the type of the Profile through the generated codegen file
  profile: any;
  selectedTab: number;
}
const InstagramCardInsights: React.FC<IInstagramInsights> = ({ profileId, children }) => {
  const [insightState, setInsightState] = useState<InsProfileStateType>({
    profile: undefined,
    selectedTab: 0
  });
  const { data, loading, error } = useGetSingleProfileForInstaQuery({
    variables: {
      id: profileId
    }
  });

  // -> INITIALIZATION
  useEffect(() => {
    if (data && data.getSingleProfile) {
      setInsightState({
        ...insightState,
        profile: data.getSingleProfile
      });
    }
    // eslint-disable-next-line
  }, [profileId, data]);

  // LOADING COMPONENT
  const loading_component = (
    <>
      {loading && (
        <Box display='flex' justifyContent='center' alignItems='center' mt={4}>
          <Loader />
        </Box>
      )}
    </>
  );

  return (
    <div>
      {loading_component}
      {(!loading || Boolean(error)) && (
        <>
          <Grid container>
            {
              // account performance
            }
            <Grid item xs={12}>
              <Spacer padding={10} />
              <InstagramFeedSummary profile={insightState.profile} />
              <Spacer padding={10} />
            </Grid>
            {
              // media kits
            }
            {children}
          </Grid>
        </>
      )}
    </div>
  );
};

export default InstagramCardInsights;
