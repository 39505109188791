import React, { createContext, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useToken } from 'hooks/useAuthHooks';
import { useGetMyUserLazyQuery } from 'types/generated';
import OpenApp from 'components/pages/OpenApp';
import OpenAppAfterFBLogin from 'components/pages/OpenAppAfterFBLogin';
import OpenAppAfterTiktokLogin from 'components/pages/OpenAppAfterTiktokLogin';
import Logout from 'components/pages/Logout';
import TrackingApproval from 'components/pages/TrackingApproval';
interface IAuth {
  _id: string;
  authState: boolean;
  error: string | null | undefined;
  token: string | null | undefined;
  userId: string | null | undefined;
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  type: string | null | undefined;
  email: string | null | undefined;
}

export const AuthContext = createContext<IAuth>({
  _id: '',
  authState: false,
  error: null,
  token: '',
  firstName: '',
  lastName: '',
  userId: '',
  type: '',
  email: ''
});

interface IAuthProvider {
  children: React.ReactNode;
}
const AuthProvider: React.FC<IAuthProvider> = (props) => {
  const { isAuth, token } = useToken();
  const [getUserInfo, { data }] = useGetMyUserLazyQuery();
  // TODO: put the user info here

  const [userState, setUserState] = useState<IAuth>({
    _id: '',
    authState: false,
    error: null,
    token: '',
    firstName: '',
    lastName: '',
    userId: '',
    type: '',
    email: ''
  });

  // redirect controller
  useEffect(() => {
    if (isAuth === true) {
      setUserState((prevStat) => ({
        ...prevStat,
        authState: true,
        error: null,
        token: token
      }));
      getUserInfo();
    }
  }, [isAuth, token, getUserInfo]);

  // User info controller
  useEffect(() => {
    if (data?.myUser) {
      // if data exists, the auth verification has passed
      const { firstName, lastName, _id, type, email } = data.myUser!;

      setUserState((prevStat) => ({
        ...prevStat,
        _id: _id ?? '',
        type: type ?? '',
        email: email ?? '',
        firstName: firstName ?? '',
        lastName: lastName ?? ''
      }));
    }
  }, [data]);

  return (
    <AuthContext.Provider
      value={{
        ...userState
      }}
    >
      <Routes>
        <Route path='/logout' element={<Logout />} />
        <Route path='/open-app' element={<OpenApp />} />
        <Route path='/open-app-after-fb-login/:token' element={<OpenAppAfterFBLogin />} />
        <Route path={'/open-app-after-tiktok-login/:token'} element={<OpenAppAfterTiktokLogin />} />
        <Route path={'/open-app-after-tiktok-login'} element={<OpenAppAfterTiktokLogin />} />
        <Route path='/tracking-response/:token' element={<TrackingApproval />} />
        {props.children}
      </Routes>
    </AuthContext.Provider>
  );
};

export { AuthProvider };
