import React, { useCallback, useReducer } from 'react';
import { useApolloClient, DocumentNode, TypedDocumentNode, ApolloError } from '@apollo/client';
import { omit } from 'lodash';
import { useErrorHandlerHook } from 'hooks/useErrorCatchHook';

export const FileUploadContext = React.createContext({
  loadingState: {} as { [key: string]: UploadProgressState },
  beginUpload: (id: string, mutation: DocumentNode | TypedDocumentNode, variables: any) => {}
});

interface FileUploadProviderProps {
  children: any;
}

type UploadProgressState = {
  percentage: number;
  loading?: boolean;
};

function loadingStateReducer(state: { [key: string]: UploadProgressState }, action: any) {
  switch (action.type) {
    case 'set':
      return { ...state, [action.id]: action.payload };
    case 'remove':
      return omit(state, [action.id]);
    default:
      throw new Error();
  }
}

const FileUploadProvider: React.FC<FileUploadProviderProps> = (props) => {
  const [loadingState, dispatch] = useReducer(loadingStateReducer, {});
  const { apolloHandler } = useErrorHandlerHook();
  const client = useApolloClient();

  const beginUpload = useCallback(
    async (id: string, mutation: DocumentNode | TypedDocumentNode, variables: any) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: 'set', id, payload: { loading: true, percentage: 0 } });
          const response = await client.mutate({
            mutation: mutation,
            variables,
            context: {
              fetchOptions: {
                onProgress: (progress: any) => {
                  dispatch({ type: 'set', id, payload: { loading: true, percentage: progress } });
                }
              }
            }
          });

          resolve(response);
        } catch (ex) {
          console.error('mediaKitUpdateMediaFile EXCEPTION', ex);
          apolloHandler(ex as ApolloError);
          reject(ex);
        } finally {
          dispatch({ type: 'remove', id });
        }
      }),

    [client, dispatch, apolloHandler]
  );

  return (
    <FileUploadContext.Provider
      value={{
        loadingState,
        beginUpload
      }}
    >
      {props.children}
    </FileUploadContext.Provider>
  );
};

export { FileUploadProvider };
