import { useState, useEffect, useMemo } from 'react';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import {
  Dialog,
  Box,
  Grid,
  List,
  ListItem,
  Typography,
  CircularProgress,
  Button,
  ButtonBase,
  ButtonGroup,
  useTheme
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import getPlan from './getPlan';
import { useGetUserPaymentHistoryQuery, useDeleteSubscriptionScheduleForCustomerMutation } from '../../types/generated';
import { useErrorHandlerHook } from 'hooks/useErrorCatchHook';
import { Alert as MuiAlert, AlertProps } from '@mui/material';
import get from 'lodash/get';
import moment from 'moment';
import q83API from 'api/q83API';
import { useStripe } from '@stripe/react-stripe-js';
import { CheckTick } from 'assets/icons/CheckTick';

interface PlansModalProps {
  subscription?: { planId?: string | null } | null;
  startingPlanId?: string | null;
  onSelect: (planId: string) => void;
  handleClose?: () => void;
  classes?: any;
}

const PlansModal = ({ subscription, onSelect, handleClose, startingPlanId }: PlansModalProps) => {
  const stripe = useStripe();
  const { classes } = styles();
  const theme = useTheme();

  const [isStripeSessionLoading, setIsStripeSessionLoading] = useState(false);
  const [sessionError, setSessionError] = useState<string | undefined>(undefined);

  const [isYearActive, setIsYearActive] = useState<boolean>(true);

  const {
    loading: userLoading,
    data: userData,
    refetch
  } = useGetUserPaymentHistoryQuery({
    fetchPolicy: 'network-only'
  });

  const [deleteSchedule, { loading: deleteLoading, data: deleteData, error: deleteError }] =
    useDeleteSubscriptionScheduleForCustomerMutation();

  const { apolloHandler } = useErrorHandlerHook();

  useEffect(() => {
    if (deleteError) {
      apolloHandler(deleteError, `Unable to delete scheduled change to subscription`);
    }
  }, [deleteError, apolloHandler]);

  useEffect(() => {
    if (sessionError) {
      apolloHandler(sessionError);
    }
  }, [apolloHandler, sessionError]);

  useEffect(() => {
    if (deleteData?.deleteSubscriptionScheduleForCustomer?.success === true) {
      refetch();
    }
  }, [deleteData, refetch]);

  const activeSchedule = useMemo(
    () => (userData ? get(userData, ['myUser', 'stripeCustomer', 'activeSubscriptionSchedules', 0]) : null),
    [userData]
  );

  const upcomingScheduleDate = useMemo(
    () => (activeSchedule ? moment(activeSchedule?.current_phase?.end_date) : undefined),
    [activeSchedule]
  );

  const remainingDaysUntilDowngrade = useMemo(
    () => (upcomingScheduleDate ? moment.duration(moment(upcomingScheduleDate).diff(moment())) : undefined),
    [upcomingScheduleDate]
  );

  // If pre-selected plan is monthly, switch to monthly view
  useEffect(() => {
    if (!startingPlanId?.includes('yearly')) {
      setIsYearActive(false);
    }
  }, [startingPlanId, setIsYearActive]);

  const currentPlanId = subscription?.planId || '';

  const currentPlan = getPlan(currentPlanId);

  const SignupButton = (props: { isDefault?: boolean; planId: string; signupText: string }) => {
    const { isDefault, planId, signupText } = props;

    const isCurrentPlan = Boolean(planId === currentPlanId) || Boolean(!currentPlanId && isDefault);

    const handleClick = () => {
      if (isCurrentPlan) return;
      const chosenPlan = getPlan(planId);

      if (chosenPlan?.stripeIds?.length) {
        setIsStripeSessionLoading(true);
        return q83API
          .get(`stripe/kitly-web/session/${chosenPlan.stripeIds[0]}`)
          .then((response) => {
            const stripeSessionId = response.data?.sessionId;
            if (stripeSessionId) {
              stripe
                ?.redirectToCheckout({
                  sessionId: stripeSessionId
                })
                .then((result) => {
                  console.log('result', result);
                });
            }
          })
          .catch((ex) => {
            console.log('getStripeSessionId EXCEPTION', ex);
            setSessionError(`Error obtaining session id: ${get(ex, 'response.data.error') || ex.message}`);
          })
          .finally(() => {
            setIsStripeSessionLoading(false);
            onSelect(planId);
          });
      } else {
        onSelect(planId);
      }
    };

    return (
      <Button
        onClick={handleClick}
        disabled={isCurrentPlan || userLoading || isStripeSessionLoading}
        variant={signupText === 'Upgrade now' ? 'contained' : 'outlined'}
        className={classes?.signupButton}
      >
        {isStripeSessionLoading && <CircularProgress size={16} style={{ marginRight: 6, color: '#BDBDBD' }} />}{' '}
        {isCurrentPlan ? 'Current Plan' : signupText}
      </Button>
    );
  };

  const FirstPlan = () => {
    const planId = 'kitly-usd-0';
    return (
      <Grid container item className={classes.planContainer}>
        <Grid item xs={12}>
          <Typography variant='h3' className={classes.planHeading}>
            Free
          </Typography>
        </Grid>
        <Grid item container justifyContent='center' alignItems='center' style={{ margin: '0 auto 10px' }}>
          <Grid item container xs={4} justifyContent='center'>
            <Grid item>
              <Typography
                align='right'
                style={{
                  color: theme.palette.primary.main,
                  fontSize: 26,
                  fontWeight: 600,
                  marginRight: 4
                }}
              >
                $0
              </Typography>
            </Grid>
            <Grid item container direction='column' justifyContent='center' style={{ width: 'min-content' }}>
              <Grid item>
                <Typography
                  style={{
                    color: theme.palette.primary.main,
                    fontSize: 8
                  }}
                >
                  USD
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container justifyContent='center' style={{ marginTop: 11 }}>
          <Typography align='center' className={classes.planDescriptionText}>
            Understanding your insights and learning about the tools you need to land your dream campaign.
          </Typography>
        </Grid>
        <List disablePadding className={classes.featureList}>
          <ListItem dense disableGutters className={classes.planListItem}>
            <Grid container>
              <Grid item className={classes.largeCheckTick}>
                <CheckTick fill={theme.palette.primary.main} />
              </Grid>
              <Grid item>
                <Typography style={{ fontWeight: 600, marginLeft: 30 }} className={classes.planSubheading}>
                  1 of each:
                </Typography>
              </Grid>
            </Grid>
            <List disablePadding>
              <ListItem className={classes.planSubListItem}>
                <Grid container>
                  <Grid item className={classes.smallCheckTick}>
                    <CheckTick fill={theme.palette.primary.main} size={10} />
                  </Grid>
                  <Grid item>
                    <Typography style={{ fontSize: 12 }}>Social Account</Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem className={classes.planSubListItem}>
                <Grid container>
                  <Grid item className={classes.smallCheckTick}>
                    <CheckTick fill={theme.palette.primary.main} size={10} />
                  </Grid>
                  <Grid item>
                    <Typography style={{ fontSize: 12 }}>Media Kit</Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem className={classes.planSubListItem}>
                <Grid container>
                  <Grid item className={classes.smallCheckTick}>
                    <CheckTick fill={theme.palette.primary.main} size={10} />
                  </Grid>
                  <Grid item>
                    <Typography style={{ fontSize: 12 }}>Report</Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem className={classes.planSubListItem}>
                <Grid container>
                  <Grid item className={classes.smallCheckTick}>
                    <CheckTick fill={theme.palette.primary.main} size={10} />
                  </Grid>
                  <Grid item>
                    <Typography style={{ fontSize: 12 }}>Campaign</Typography>
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </ListItem>
          <ListItem dense disableGutters className={classes.planListItem}>
            <Grid container>
              <Grid item className={classes.largeCheckTick}>
                <CheckTick fill={theme.palette.primary.main} />
              </Grid>
              <Grid item style={{ marginLeft: 30 }}>
                <Typography
                  style={{
                    fontWeight: 600
                  }}
                  className={classes.planSubheading}
                >
                  Basic Media Kit cards
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
          <ListItem dense disableGutters className={classes.planListItem}>
            <Grid container>
              <Grid item className={classes.largeCheckTick}>
                <CheckTick fill={theme.palette.primary.main} />
              </Grid>
              <Grid item style={{ marginLeft: 30 }}>
                <Typography
                  style={{
                    fontWeight: 600
                  }}
                  className={`${classes.planSubheading} ${classes.networkFeatureListItem}`}
                >
                  Profile listed in the network
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
          <ListItem dense disableGutters className={classes.planListItem}>
            <Grid container>
              <Grid item className={classes.largeCheckTick}>
                <CheckTick fill='#B0B0B0' />
              </Grid>
              <Grid item style={{ marginLeft: 30 }}>
                <Typography
                  style={{
                    fontWeight: 600,
                    color: '#B0B0B0'
                  }}
                  className={classes.planSubheading}
                >
                  Weekly Performance Reports
                </Typography>
                <Typography className={classes.planSubheading} style={{ color: '#B0B0B0' }}>
                  benchmarking and growth
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
          <ListItem dense disableGutters className={classes.planListItem} style={{ marginBottom: 10 }}>
            <Grid container>
              <Grid item className={classes.largeCheckTick}>
                <CheckTick fill={theme.palette.primary.main} />
              </Grid>
              <Grid item style={{ marginLeft: 30 }} className={classes.planSubheading}>
                <Typography component='span' style={{ fontWeight: 600 }} className={classes.planSubheading}>
                  3%
                </Typography>{' '}
                platform fee + standard card fees *
              </Grid>
            </Grid>
          </ListItem>
        </List>
        <SignupButton isDefault={true} planId={planId} signupText={'Downgrade'} />
      </Grid>
    );
  };

  const SecondPlan = () => {
    const planId = isYearActive ? 'kitly-usd-1-yearly' : 'kitly-usd-1';

    return (
      <Grid container item className={classes.planContainer}>
        <Grid item xs={12}>
          <Typography variant='h3' className={classes.planHeading}>
            Starter
          </Typography>
        </Grid>
        <Grid item container justifyContent='center' alignItems='center' style={{ margin: '0 auto 10px' }}>
          <Grid item container xs={6} justifyContent='center'>
            <Grid item>
              <Typography
                align='right'
                style={{
                  color: theme.palette.primary.main,
                  fontSize: 26,
                  fontWeight: 600,
                  marginRight: 4
                }}
              >
                {`$${isYearActive ? 7.42 : 9}`}
              </Typography>
            </Grid>

            <Grid item container direction='column' justifyContent='center' style={{ width: 'min-content' }}>
              <Grid item>
                <Typography style={{ color: theme.palette.primary.main, fontSize: 8 }}>USD</Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: theme.palette.primary.main, fontSize: 8 }}>{`/Mo`}</Typography>
              </Grid>
            </Grid>
            {isYearActive && (
              <Grid item xs={12}>
                <Typography
                  style={{ color: theme.palette.primary.main, fontSize: 8, textAlign: 'center' }}
                >{`* $89 billed annually`}</Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item container justifyContent='center' style={{ marginTop: isYearActive ? 0 : 11 }}>
          <Typography align='center' className={classes.planDescriptionText}>
            Ready to start working with brands or looking to land more paid campaigns? Professionalise yourself with{' '}
            <span style={{ color: theme.palette.primary.main }}>{'Starter.'}</span>
          </Typography>
        </Grid>
        <List disablePadding className={classes.featureList}>
          <ListItem dense disableGutters className={classes.planListItem}>
            <Grid container>
              <Grid item className={classes.largeCheckTick}>
                <CheckTick fill={theme.palette.primary.main} />
              </Grid>
              <Grid item>
                <Typography style={{ fontWeight: 600, marginLeft: 30 }} className={classes.planSubheading}>
                  2 of each:
                </Typography>
              </Grid>
            </Grid>
            <List disablePadding>
              <ListItem className={classes.planSubListItem}>
                <Grid container>
                  <Grid item className={classes.smallCheckTick}>
                    <CheckTick fill={theme.palette.primary.main} size={10} />
                  </Grid>
                  <Grid item>
                    <Typography style={{ fontSize: 12 }}>Social Accounts</Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem className={classes.planSubListItem}>
                <Grid container>
                  <Grid item className={classes.smallCheckTick}>
                    <CheckTick fill={theme.palette.primary.main} size={10} />
                  </Grid>
                  <Grid item>
                    <Typography style={{ fontSize: 12 }}>Custom Media Kits</Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem className={classes.planSubListItem}>
                <Grid container>
                  <Grid item className={classes.smallCheckTick}>
                    <CheckTick fill={theme.palette.primary.main} size={10} />
                  </Grid>
                  <Grid item>
                    <Typography style={{ fontSize: 12 }}>Reports</Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem className={classes.planSubListItem}>
                <Grid container>
                  <Grid item className={classes.smallCheckTick}>
                    <CheckTick fill={theme.palette.primary.main} size={10} />
                  </Grid>
                  <Grid item>
                    <Typography style={{ fontSize: 12 }}>Campaign</Typography>
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </ListItem>
          <ListItem dense disableGutters className={classes.planListItem}>
            <Grid container>
              <Grid item className={classes.largeCheckTick}>
                <CheckTick fill={theme.palette.primary.main} />
              </Grid>
              <Grid item style={{ marginLeft: 30 }}>
                <Typography
                  style={{
                    fontWeight: 600
                  }}
                  className={classes.planSubheading}
                >
                  Custom Media Kit cards
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
          <ListItem dense disableGutters className={classes.planListItem}>
            <Grid container>
              <Grid item className={classes.largeCheckTick}>
                <CheckTick fill={theme.palette.primary.main} />
              </Grid>
              <Grid item style={{ marginLeft: 30 }}>
                <Typography
                  style={{
                    fontWeight: 600
                  }}
                  className={`${classes.planSubheading} ${classes.networkFeatureListItem}`}
                >
                  Highlighted Pro user listing in the network
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
          <ListItem dense disableGutters className={classes.planListItem}>
            <Grid container>
              <Grid item className={classes.largeCheckTick}>
                <CheckTick fill={theme.palette.primary.main} />
              </Grid>
              <Grid item style={{ marginLeft: 30 }}>
                <Typography
                  style={{
                    fontWeight: 600
                  }}
                  className={classes.planSubheading}
                >
                  Weekly Performance Reports
                </Typography>
                <Typography className={classes.planSubheading}>benchmarking and growth</Typography>
              </Grid>
            </Grid>
          </ListItem>
          <ListItem dense disableGutters className={classes.planListItem} style={{ marginBottom: 10 }}>
            <Grid container>
              <Grid item className={classes.largeCheckTick}>
                <CheckTick fill={theme.palette.primary.main} />
              </Grid>
              <Grid item style={{ marginLeft: 30 }} className={classes.planSubheading}>
                <Typography component='span' style={{ fontWeight: 600 }} className={classes.planSubheading}>
                  2%
                </Typography>{' '}
                platform fee + standard card fees *
              </Grid>
            </Grid>
          </ListItem>
        </List>
        <SignupButton
          planId={planId}
          signupText={!currentPlanId || currentPlanId === 'kitly-usd-0' ? 'Upgrade now' : 'Downgrade'}
        />
      </Grid>
    );
  };

  const ThirdPlan = () => {
    const planId = isYearActive ? 'kitly-usd-2-yearly' : 'kitly-usd-2';

    return (
      <Grid container item className={classes.planContainer} style={{ position: 'relative' }}>
        <div className={classes.mostPopular}>MOST POPULAR</div>
        <Grid item xs={12}>
          <Typography variant='h3' className={classes.planHeading}>
            Creator
          </Typography>
        </Grid>
        <Grid item container justifyContent='center' alignItems='center' style={{ margin: '0 auto 10px' }}>
          <Grid item container xs={6} justifyContent='center'>
            <Grid item>
              <Typography
                align='right'
                style={{
                  color: theme.palette.primary.main,
                  fontSize: 26,
                  fontWeight: 600,
                  marginRight: 4
                }}
              >
                {`$${isYearActive ? 15.83 : 19}`}
              </Typography>
            </Grid>
            <Grid item container direction='column' justifyContent='center' style={{ width: 'min-content' }}>
              <Grid item>
                <Typography style={{ color: theme.palette.primary.main, fontSize: 8 }}>USD</Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: theme.palette.primary.main, fontSize: 8 }}>{`/Mo`}</Typography>
              </Grid>
            </Grid>
            {isYearActive && (
              <Grid item xs={12}>
                <Typography
                  style={{ color: theme.palette.primary.main, fontSize: 8, textAlign: 'center' }}
                >{`* $190 billed annually`}</Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item container justifyContent='center' style={{ marginTop: isYearActive ? 0 : 11 }}>
          <Typography align='center' className={classes.planDescriptionText}>
            Full-time Creator? We've got you. <span style={{ color: theme.palette.primary.main }}>{'Creator '}</span>
            has all of your campaign, reporting and invoicing covered.
          </Typography>
        </Grid>
        <List disablePadding className={classes.featureList}>
          <ListItem dense disableGutters className={classes.planListItem}>
            <Grid container>
              <Grid item className={classes.largeCheckTick}>
                <CheckTick fill={theme.palette.primary.main} />
              </Grid>
              <Grid item>
                <Typography style={{ fontWeight: 600, marginLeft: 30 }} className={classes.planSubheading}>
                  UNLIMITED:
                </Typography>
              </Grid>
            </Grid>
            <List disablePadding>
              <ListItem className={classes.planSubListItem}>
                <Grid container>
                  <Grid item className={classes.smallCheckTick}>
                    <CheckTick fill={theme.palette.primary.main} size={10} />
                  </Grid>
                  <Grid item>
                    <Typography style={{ fontSize: 12 }}>Social Accounts</Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem className={classes.planSubListItem}>
                <Grid container>
                  <Grid item className={classes.smallCheckTick}>
                    <CheckTick fill={theme.palette.primary.main} size={10} />
                  </Grid>
                  <Grid item>
                    <Typography style={{ fontSize: 12 }}>Custom Media Kits</Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem className={classes.planSubListItem}>
                <Grid container>
                  <Grid item className={classes.smallCheckTick}>
                    <CheckTick fill={theme.palette.primary.main} size={10} />
                  </Grid>
                  <Grid item>
                    <Typography style={{ fontSize: 12 }}>Reports</Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem className={classes.planSubListItem}>
                <Grid container>
                  <Grid item className={classes.smallCheckTick}>
                    <CheckTick fill={theme.palette.primary.main} size={10} />
                  </Grid>
                  <Grid item>
                    <Typography style={{ fontSize: 12 }}>Campaign</Typography>
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </ListItem>
          <ListItem dense disableGutters className={classes.planListItem}>
            <Grid container>
              <Grid item className={classes.largeCheckTick}>
                <CheckTick fill={theme.palette.primary.main} />
              </Grid>
              <Grid item style={{ marginLeft: 30 }}>
                <Typography
                  style={{
                    fontWeight: 600
                  }}
                  className={classes.planSubheading}
                >
                  Custom Media Kit cards
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
          <ListItem dense disableGutters className={classes.planListItem}>
            <Grid container>
              <Grid item className={classes.largeCheckTick}>
                <CheckTick fill={theme.palette.primary.main} />
              </Grid>
              <Grid item style={{ marginLeft: 30 }}>
                <Typography
                  style={{
                    fontWeight: 600
                  }}
                  className={`${classes.planSubheading} ${classes.networkFeatureListItem}`}
                >
                  Highlighted Pro user listing in the network
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
          <ListItem dense disableGutters className={classes.planListItem}>
            <Grid container>
              <Grid item className={classes.largeCheckTick}>
                <CheckTick fill={theme.palette.primary.main} />
              </Grid>
              <Grid item style={{ marginLeft: 30 }}>
                <Typography
                  style={{
                    fontWeight: 600
                  }}
                  className={classes.planSubheading}
                >
                  Weekly Performance Reports
                </Typography>
                <Typography className={classes.planSubheading}>benchmarking and growth</Typography>
              </Grid>
            </Grid>
          </ListItem>
          <ListItem dense disableGutters className={classes.planListItem} style={{ marginBottom: 10 }}>
            <Grid container>
              <Grid item className={classes.largeCheckTick}>
                <CheckTick fill={theme.palette.primary.main} />
              </Grid>
              <Grid item style={{ marginLeft: 30 }} className={classes.planSubheading}>
                <Typography component='span' style={{ fontWeight: 600 }} className={classes.planSubheading}>
                  1%
                </Typography>{' '}
                platform fee + standard card fees *
              </Grid>
            </Grid>
          </ListItem>
        </List>
        <SignupButton planId={planId} signupText={'Upgrade now'} />
      </Grid>
    );
  };

  return (
    <Dialog open={true} onClose={handleClose} fullScreen={true} classes={{ paper: classes.dialogPaper }}>
      <Grid container justifyContent='center' className={classes.sectionBackground}>
        <ButtonBase onClick={handleClose} className={classes?.closeButton}>
          <ClearRoundedIcon className={classes?.closeIcon} />
        </ButtonBase>
        <Box display='flex' justifyContent='center' flexDirection='column'>
          <ButtonGroup aria-label='outlined button group' className={classes.btnGroup}>
            <Button
              className={`${classes.button} ${isYearActive ? classes.buttonActive : ''}`}
              onClick={() => {
                setIsYearActive(false);
              }}
            >
              Month
            </Button>
            <Button
              className={`${classes.button} ${isYearActive ? '' : classes.buttonActive}`}
              onClick={() => {
                setIsYearActive(true);
              }}
            >
              Year
            </Button>
          </ButtonGroup>
          <Typography className={classes.productStatementText}>
            Free to use forever, or upgrade to watch your impact grow.
          </Typography>
          <Grid container justifyContent='center'>
            <FirstPlan />
            <SecondPlan />
            <ThirdPlan />
          </Grid>
          <Typography variant='body2' align='center' className={classes.footnoteText}>
            * Standard card fees in line with Stripe's fees and Pricing
          </Typography>
          {activeSchedule && (
            <Alert
              severity='success'
              action={
                <Button color='inherit' size='small' onClick={() => deleteSchedule()} disabled={deleteLoading}>
                  {deleteLoading ? <CircularProgress /> : 'CANCEL DOWNGRADE'}
                </Button>
              }
            >
              {`Downgrade Successful - you have ${remainingDaysUntilDowngrade?.days()} days remaining on your current subscription, ${
                currentPlan?.name
              } features will be unavailable
            from ${upcomingScheduleDate?.format('D/M/YYYY')}`}
            </Alert>
          )}
        </Box>
      </Grid>
    </Dialog>
  );
};

const styles = makeStyles()((theme) => ({
  dialogPaper: {
    padding: 0,
    position: 'relative',
    background: 'linear-gradient(90deg, #fceee9 0%, #dde4ef 100%)',
    '@media (max-width: 890px)': {
      background: 'linear-gradient(257.54deg, #89c8ff 0%, #fceee9 100%)'
    }
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 15
  },
  closeIcon: {
    fontSize: 30,
    color: theme.palette.common.black
  },
  mostPopular: {
    position: 'absolute',
    top: 16,
    right: -26,
    background: '#FAD417',
    fontSize: 9,
    color: '#333333',
    letterSpacing: '0.75px',
    boxShadow: '0 1px 2px 0 rgba(140,140,140,0.5)',
    borderRadius: 4,
    padding: '6px 8px',
    [theme.breakpoints.down('xs')]: {
      right: 16
    }
  },
  sectionBackground: {
    position: 'relative',
    padding: '70px 0',
    height: '100%'
  },
  productStatementText: {
    fontSize: 18,
    fontWeight: 600,
    margin: '30px auto',
    '@media (max-width: 890px)': {
      fontSize: 16,
      textAlign: 'center',
      maxWidth: 300
    }
  },
  btnGroup: {
    margin: '0 auto 20px'
  },
  button: {
    borderColor: '#FF72AA',
    backgroundColor: '#FF72AA',
    border: `solid 0.5px #FF72AA`,
    color: '#fff',
    paddingLeft: 30,
    paddingRight: 30,
    minHeight: 30,
    maxHeight: 30,
    minWidth: 100,
    maxWidth: 100,
    fontSize: 12,
    fontWeight: 500,
    '&:first-child': {
      borderRadius: '15px 0 0 15px'
    },
    '&:last-child': {
      borderRadius: '0 15px 15px 0'
    },
    '&:hover': {
      border: `solid 0.5px #FF72AA`,
      backgroundColor: '#FF72AA',
      color: '#000'
    }
  },
  buttonActive: {
    backgroundColor: '#fff',
    color: '#000',
    '&:hover': {
      border: `solid 0.5px #FF72AA`,
      backgroundColor: '#FF72AA',
      color: '#fff'
    }
  },
  signupButton: {
    width: '100%'
  },
  planContainer: {
    height: 525,
    backgroundColor: '#fff',
    borderRadius: 5,
    width: 260,
    padding: '0px 12px 30px 12px',
    position: 'relative',
    marginRight: 20,
    '@media (max-width: 890px)': {
      width: '60%',
      margin: '20px auto',
      borderRadius: 8
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '20px 30px',
      borderRadius: 8
    }
  },
  planHeading: {
    color: theme.palette.primary.main,
    fontSize: 18,
    fontWeight: 600,
    textAlign: 'center',
    margin: '16px auto 0'
  },
  planSubheading: {
    fontSize: 14
  },
  planDescriptionText: {
    fontSize: 10,
    lineHeight: '16px',
    maxWidth: 200,
    height: 60,
    marginBottom: 5,
    '@media (max-width: 890px)': {
      maxWidth: '80%'
    }
  },
  planListItem: {
    width: '100%',
    display: 'block',
    position: 'relative'
  },
  planSubListItem: {
    width: '100%',
    display: 'block',
    paddingLeft: 20,
    paddingTop: 0,
    paddingBottom: 0,
    flexWrap: 'nowrap',
    '@media (max-width: 890px)': {
      display: 'inline-block',
      width: '33%',
      minWidth: 135
    }
  },
  footnoteText: {
    color: '#333333',
    fontSize: 14,
    marginTop: 30,
    '@media (max-width: 890px)': {
      margin: '15px auto',
      maxWidth: 280
    }
  },
  largeCheckTick: { position: 'absolute', top: 10, left: 8 },
  smallCheckTick: { marginRight: 4 },
  featureList: {
    height: 290
  },
  networkFeatureListItem: {
    height: 45
  }
}));

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

export default PlansModal;
