import * as React from 'react';

import Grid from '@mui/material/Grid';
import FilledInput from '@mui/material/FilledInput';

import Modal from 'components/Modal';
import { Typography } from '@mui/material';

/* SETUP:
  <NotesModal
    modalOpen={boolean}
    toggleModal={function}
    changeNotes={function}
    saveNotes={function}
    username={string}
    notes={string}
    errorMessage={string}
    loading={boolean}
    mobileView={boolean}
  /> 
*/

interface NotesModalProps {
  modalOpen: boolean;
  toggleModal: () => void;
  changeNotes?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  saveNotes?: () => void;
  heading: string;
  notes: string;
  loading: boolean;
  mobileView: boolean;
}

const NotesModal: React.FunctionComponent<NotesModalProps> = ({
  modalOpen,
  toggleModal,
  changeNotes,
  saveNotes,
  heading,
  notes,
  loading,
  mobileView
}) => {
  // TextField won't push out past its minimum width, so width needs to be manually set:
  const minScreenWidth = (width: string): boolean => {
    return window.matchMedia(`(min-width: ${width})`).matches;
  };
  const width = minScreenWidth('400px') ? '360px' : '280px';

  return (
    <Modal
      isOpen={modalOpen}
      onToggle={toggleModal}
      onConfirm={saveNotes}
      isLoadingConfirm={loading}
      hideConfirmButton={Boolean(!saveNotes)}
      confirmLabel={'Save'}
      cancelLabel={saveNotes ? 'Cancel' : 'Done'}
      heading={`${heading || 'Deliverable'} notes`}
      fullScreen={mobileView || false}
    >
      <Grid container style={{ minWidth: width }}>
        <Grid item xs={12} style={{ marginBottom: '10px' }}>
          {changeNotes ? (
            <FilledInput
              value={notes}
              fullWidth={true}
              onChange={changeNotes}
              multiline={true}
              rows={20}
              maxRows={20}
              inputProps={{
                'data-cy': 'notes-textarea',
                contentEditable: true,
                suppressContentEditableWarning: true,
                style: { fontSize: 14, letterSpacing: 0.5, lineHeight: 'normal' }
              }}
            />
          ) : (
            <Typography>{notes}</Typography>
          )}
        </Grid>
      </Grid>
    </Modal>
  );
};

export default NotesModal;
