import { makeStyles } from 'tss-react/mui';
import InviteIcon from 'assets/icons/InviteIcon.svg';
import React from 'react';
import { ContactCreation } from './ContactCreation';

export const AddContactButton = (props: { brandId?: string }) => {
  const { brandId } = props;
  const { classes } = useStyles();

  return (
    <div className={classes.iconCircle}>
      <ContactCreation selectedParentId={brandId}>
        <img src={InviteIcon} alt='Add Contact' className={classes.icon} />
      </ContactCreation>
    </div>
  );
};

const useStyles = makeStyles()((theme) => {
  return {
    iconCircle: {
      width: 32,
      height: 32,
      borderRadius: '100%',
      border: `0.5px solid ${theme.palette.primary.main}`,
      background: '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 2
    },
    icon: {
      width: 18,
      height: 18
    }
  };
});
