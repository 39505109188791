import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from 'tss-react/mui';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {
  Avatar,
  Typography,
  TextField,
  Grid,
  Box,
  Button,
  IconButton,
  InputAdornment,
  FormLabel,
  ButtonBase,
  CircularProgress
} from '@mui/material';
import Delete from '@mui/icons-material/Delete';
import { useFileReader } from 'hooks/useFileReader';
import { useDropzone } from 'react-dropzone';
import CloseButton from './CloseButton';
import ImageIcon from '@mui/icons-material/Image';
import { isEmpty, omit, compact } from 'lodash';

import AddIcon from 'assets/components/AddIcon';
import { EditBoxProps } from './MediaKitEditBox';

import { ElementContentType, FileInput, FilesContent } from 'types/generated';

import FallbackImage from '../../../assets/images/link75.png';

const SliderConfig = {
  dots: true,
  dotsClass: 'slider-dots',
  arrows: true,
  slidesToScroll: 1,
  infinite: false
};

const styles = makeStyles()((theme) => ({
  error: { color: 'red' },
  textEditor: { padding: 15, minHeight: 300, width: '100%' },
  textPreview: { marginTop: 12, fontStyle: 'italic', fontSize: 14 },
  previewContainer: { paddingTop: 10, fontSize: 14, marginTop: 10, paddingBottom: 20, width: '100%' },
  previewTitle: { fontSize: 16, fontWeight: 500 },
  imageEditContainer: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    minHeight: 50,
    alignItems: 'center'
  },
  imageSection: { backgroundColor: theme.palette.info.light, padding: 10, marginBottom: 10 },
  imageEditor: { width: '100%' },
  imagePreview: { width: '100%', height: 300 },
  imagePlaceholder: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: 16,
    color: theme.palette.primary.main,
    textAlign: 'center',
    padding: 12
  },
  imagePlaceholderTitle: {
    color: theme.palette.primary.main,
    margin: 4
  },
  editorField: {
    marginTop: 10
  },
  slideContainer: { position: 'relative' },
  placeholderContainer: {
    width: '100%',
    paddingBottom: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 200
  },
  imageUploadSubtitle: {
    fontStyle: 'italic',
    marginTop: 4
  }
}));

function isFilesContent(content?: ElementContentType | null): content is FilesContent {
  return content && (content as FilesContent).file_content !== undefined ? true : false;
}

const FilesBox = ({ element: { content }, handleUpdate, isEditing, setIsEditing }: EditBoxProps) => {
  const { classes } = styles();
  const [fileContent, setFileContent] = useState<Array<FileInput>>([]);

  const handleUpdateItem = (item: FileInput, index: number) => {
    setFileContent(fileContent.map((o, i) => (i === index ? item : o)));
  };

  const handleAddItem = () => {
    setFileContent([...fileContent, { title: '' }]);
  };

  const handleRemoveItem = (index: number, shouldSave?: boolean) => {
    const updatedContent = fileContent.filter((o, i) => (i === index ? false : true));
    setFileContent(updatedContent);
    if (shouldSave) {
      handleSave(updatedContent);
    }
  };

  const storedFileContent = isFilesContent(content) ? content.file_content : undefined;

  useEffect(() => {
    if (!isEditing) {
      setFileContent(storedFileContent ? compact(storedFileContent) : []);
    }
  }, [storedFileContent, setFileContent, isEditing]);

  useEffect(() => {
    if (isEditing && isEmpty(fileContent)) {
      setFileContent([{ title: '' }]);
    }
  }, [isEditing, fileContent, setFileContent]);

  const handleSave = (updatedContent?: FileInput[]) => {
    let cleanedContentForPreview: FileInput[] = [];
    const cleanedContentForUpdate = (updatedContent || fileContent).map((o) => {
      cleanedContentForPreview.push({ ...o, title: o.title || 'Untitled' });
      return o.icon && o.iconUrl?.includes('data:image')
        ? { ...omit(o, 'iconUrl'), title: o.title || 'Untitled' }
        : { ...o, title: o.title || 'Untitled' };
    });
    handleUpdate(
      { ...content, file_content: cleanedContentForUpdate },
      { ...content, file_content: cleanedContentForPreview }
    );
  };

  if (isEditing) {
    return (
      <>
        {fileContent.map((o, i) => (
          <FilePair
            key={`link_${i}`}
            item={o}
            onChange={(link) => (link ? handleUpdateItem(link, i) : handleRemoveItem(i))}
            classes={classes}
          />
        ))}
        <Grid container justifyContent={'flex-end'}>
          <Grid item>
            <Button size={'small'} onClick={() => handleAddItem()}>
              Add Another
            </Button>
          </Grid>
        </Grid>
        <Grid container justifyContent='flex-start'>
          <Button variant='outlined' color='primary' onClick={() => handleSave()}>
            {`Save & Close`}
          </Button>
        </Grid>
      </>
    );
  }
  const slidesToShow = Math.min(3, fileContent.length) || 1;
  return (
    <Box className={classes.previewContainer}>
      {isEmpty(fileContent) ? (
        <ButtonBase className={classes.placeholderContainer} onClick={() => setIsEditing(true)}>
          <AddIcon size={35} strokeWidth={3} />
        </ButtonBase>
      ) : (
        <Slider {...SliderConfig} slidesToShow={slidesToShow} className='links-slider slider-theme'>
          {fileContent.map((o, i) => (
            <Box key={`file_${i}`} className={classes.slideContainer}>
              {handleRemoveItem && (
                <CloseButton
                  onPress={() => {
                    handleRemoveItem(i, true);
                  }}
                  filledCircle
                  size={20}
                />
              )}
              <img src={o.iconUrl ? o.iconUrl : FallbackImage} alt={`link_${i}`} />
              <FilePair item={o} classes={classes} />
            </Box>
          ))}
        </Slider>
      )}
    </Box>
  );
};

const FilePair = ({
  item,
  onChange,
  classes
}: {
  item: FileInput;
  onChange?: (item?: FileInput) => void;
  classes: any;
}) => {
  const { readFile, readFileLoading } = useFileReader();
  const [iconContent, setIconContent] = useState<string | undefined>();

  useEffect(() => {
    if (typeof item?.iconUrl === 'string') {
      setIconContent(item.iconUrl);
    } else if (item?.icon) {
      readFile(item.icon, (name, blob, encodedString) => {
        setIconContent(encodedString);
      });
    } else {
      setIconContent(undefined);
    }
  }, [item, setIconContent, readFile, onChange]);

  const dropFile = useCallback(
    (acceptedFiles) => {
      if (onChange) {
        acceptedFiles.forEach((file: File) => {
          onChange({ ...item, file, fileSize: file.size });
        });
      }
    },
    [onChange, item]
  );

  const dropImage = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file: File) => {
        readFile(file, (name, blob, encodedString) => {
          if (onChange) {
            onChange({ ...item, icon: blob, iconUrl: encodedString });
          }
          setIconContent(encodedString);
        });
      });
    },
    [readFile, setIconContent, onChange, item]
  );

  const fileDrop = useDropzone({ onDrop: dropFile, accept: 'application/pdf' });
  const iconDrop = useDropzone({
    onDrop: dropImage,
    accept: 'image/jpg, image/jpeg, image/png, image/heic, image/heif',
    maxSize: 100000000
  });

  if (onChange) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <TextField
            variant='standard'
            fullWidth
            label={'Document Name'}
            value={item.title}
            onChange={(e: any) => {
              onChange({ ...item, title: e.target.value });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton onClick={() => onChange()}>
                    <Delete />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Grid>
        {!item.link && (
          <Grid item xs={12} className={classes.editorField}>
            <Box className={classes.imageSection}>
              <Box className={classes.imageEditContainer}>
                {item.file ? (
                  <>
                    <Typography>{item.file.name}</Typography>
                    <CloseButton onPress={() => onChange(omit(item, ['file', 'fileSize']))} />
                  </>
                ) : (
                  <div {...fileDrop.getRootProps()} className={classes.imageEditor}>
                    <input {...fileDrop.getInputProps()} />
                    <Box className={classes.imagePlaceholder}>
                      <ImageIcon fontSize={'large'} />
                      <Typography className={classes.imagePlaceholderTitle}>Drop PDF file or</Typography>
                      <Button variant='outlined' color='primary'>
                        Browse
                      </Button>
                      <Typography variant='caption' className={classes.imageUploadSubtitle}>
                        Accepts JPEG or PNG images up to 100MB
                      </Typography>
                    </Box>
                  </div>
                )}
              </Box>
            </Box>
          </Grid>
        )}
        <Grid item xs={12} className={classes.editorField}>
          <FormLabel style={{ marginBottom: 10, display: 'flex', fontSize: 11, color: '#333333', opacity: 0.7 }}>
            Document Thumbnail (optional)
          </FormLabel>
          <Box className={classes.imageSection}>
            <Box className={classes.imageEditContainer}>
              {iconContent ? (
                <>
                  <Avatar src={iconContent} variant='square' className={classes.imagePreview} />
                  <CloseButton onPress={() => setIconContent(undefined)} />
                </>
              ) : (
                <div {...iconDrop.getRootProps()} className={classes.imageEditor}>
                  <input {...iconDrop.getInputProps()} />
                  <Box className={classes.imagePlaceholder}>
                    {readFileLoading ? (
                      <CircularProgress />
                    ) : (
                      <>
                        <ImageIcon fontSize={'large'} />
                        <Typography className={classes.imagePlaceholderTitle}>Drop an image or</Typography>
                        <Button variant='outlined' color='primary'>
                          Browse
                        </Button>
                      </>
                    )}
                  </Box>
                </div>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <a href={item.link || undefined} target='_blank' rel='noopener noreferrer'>
          {item.title}
        </a>
      </Grid>
    </Grid>
  );
};

export default FilesBox;
