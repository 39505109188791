import { Box, CircularProgress } from '@mui/material';
import React from 'react';

interface LoaderProps {
  containerHeight?: string | number;
}

export const Loader = ({ containerHeight }: LoaderProps) => {
  return (
    <Box margin='auto' height={containerHeight}>
      <CircularProgress />
    </Box>
  );
};
