import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import collabsSetupImg from 'assets/images/collabs-setup.png';
import FakeButton from 'components/FakeButton';
import { useWalkthrough } from 'hooks/useWalkthrough';
import { WALKTHROUGHS } from 'types/custom';
import { CollabCreation } from './CollabCreation';

export const Walkthrough = () => {
  const { classes } = useClasses();
  const { handleWalkthrough: handleNewCollab, loading: newCollabLoading } = useWalkthrough(
    WALKTHROUGHS.COLLABORATIONS,
    '/campaigns?action=create'
  );
  const { handleWalkthrough: handleMaybeLater, loading: maybeLaterLoading } = useWalkthrough(
    WALKTHROUGHS.COLLABORATIONS,
    '/campaigns'
  );

  return (
    <Box className={classes.root} mt='50px'>
      <Grid container justifyContent='center'>
        <Box mb={'44px'}>
          <Typography style={{ color: '#333333', fontWeight: 400, fontSize: 28 }} align='center'>
            Set Up Your Campaigns
          </Typography>
        </Box>
        <img alt='collaboration set up' src={collabsSetupImg} className={classes.image} />
        <Box mb='48px' mt='20px'>
          <Typography style={{ fontSize: 16, lineHeight: '24px', color: '#333333' }} align='center'>
            Never miss a deadline. Stay on top of your campaigns and deliverable timelines from one place. Create your
            first campaign and assign it to a brand.{' '}
          </Typography>
        </Box>
        <Grid container justifyContent='space-between' wrap='nowrap' style={{ columnGap: 20 }}>
          <CollabCreation>
            <FakeButton variant='contained' size='large' className={classes.button} onClick={handleNewCollab}>
              <Grid container alignItems='center'>
                {newCollabLoading && <CircularProgress style={{ color: '#fff', marginRight: 10 }} size={24} />}
                <Typography className={classes.buttonText}>Create New Campaign</Typography>
              </Grid>
            </FakeButton>
          </CollabCreation>
          <Button
            variant='outlined'
            size='large'
            className={`${classes.button} ${classes.smallButton}`}
            onClick={handleMaybeLater}
          >
            {maybeLaterLoading && <CircularProgress style={{ marginRight: 10 }} size={20} />}
            Maybe later
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

const useClasses = makeStyles()((theme) => {
  return {
    root: {
      maxWidth: 574
    },
    button: {
      minWidth: '277px'
    },
    buttonText: {
      color: '#FFFFFF',
      fontWeight: 500,
      fontSize: 16
    },
    smallButton: {
      fontSize: 14,
      textTransform: 'none'
    },
    image: {
      height: 300
    }
  };
});
