import { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import DataTable from './Table/DataTable';
import ReportEditorModel from './Modal/ReportEditorModel';
import ShareReportModal from './Modal/ShareReportModal';
import useSubscribePopupCtx, { PlanLimitType } from 'context/useSubscribePopupCtx';
import { useLocation } from 'react-router';
import ContentfulAdBanner from 'components/Dashboard/ContentfulAdBanner';

interface Props {
  updateNewestReportId: (reportId: string) => void;
  latestReportId: string;
}

export const ReportsTile = (props: Props) => {
  const { classes } = useClasses();
  const location = useLocation();
  const { latestReportId, updateNewestReportId } = props;
  const { executeLimitedAction } = useSubscribePopupCtx(PlanLimitType.REPORTS_USAGE);
  const [reportTileState, setReportTileState] = useState<{
    loadingDisplay: boolean;
    reportEditorDisplay: boolean;
    selectedReportId: string | undefined;
    shareModalDisplay: boolean;
    shareReportId: string;
  }>({
    loadingDisplay: true,
    reportEditorDisplay: false,
    selectedReportId: '',
    shareModalDisplay: false,
    shareReportId: ''
  });

  useEffect(() => {
    const hasNewPath = location.search.includes('create');
    if (hasNewPath) {
      executeLimitedAction(() => setReportTileState({ ...reportTileState, reportEditorDisplay: true }));
    }
    // only want to run this once on initial component mount, otherwise any state changes from parent component will re-run this hook
    // eslint-disable-next-line
  }, []);

  // list of operations
  const reportOperations = {
    toggleReport: (reportId: string) => {
      if (typeof reportId === 'object' && reportTileState.selectedReportId) {
        setReportTileState({
          ...reportTileState,
          reportEditorDisplay: !reportTileState.reportEditorDisplay,
          selectedReportId: ''
        });
      } else {
        setReportTileState({
          ...reportTileState,
          reportEditorDisplay: !reportTileState.reportEditorDisplay,
          selectedReportId: reportId
        });
      }
    },
    toggleShare: (reportId: string) => {
      const toggle_flag = reportId.length >= 1;

      if (toggle_flag) {
        setReportTileState({
          ...reportTileState,
          shareReportId: reportId,
          shareModalDisplay: true
        });
      } else {
        setReportTileState({
          ...reportTileState,
          shareReportId: reportId,
          shareModalDisplay: false
        });
      }
    }
  };

  // header component
  const header = (
    <Box display='flex' justifyContent='space-between' mt={'12px'}>
      <Typography className={classes.header_title}>Reporting</Typography>
      <Button
        onClick={() => executeLimitedAction(() => reportOperations.toggleReport(''))}
        variant='contained'
        className={classes.button}
      >
        New Report
      </Button>
    </Box>
  );

  // top navigation component
  const top_nav = (
    <>
      <Typography variant='subtitle1' className={classes.subtitle}>
        Manage
      </Typography>
    </>
  );

  return (
    <>
      <ReportEditorModel
        reportId={reportTileState.selectedReportId}
        isOpen={reportTileState.reportEditorDisplay}
        toggleCreationModal={(reportId: string) => reportOperations.toggleReport(reportId)}
        isEditingExisting={false}
        updateNewestReportId={(reportId: string) => updateNewestReportId(reportId)}
      />

      <ShareReportModal
        reportId={reportTileState.shareReportId}
        isOpen={reportTileState.shareModalDisplay}
        toggleShareModal={(reportId: string) => reportOperations.toggleShare(reportId)}
      />

      <Box mt={'0px'} style={{ position: 'relative' }}>
        {top_nav}
        {header}
        <ContentfulAdBanner adLocation='reports' style={{ marginTop: 20 }} skeletonStyle={{ marginTop: 20 }} />
        <DataTable
          latestCreatedReportId={latestReportId}
          toggleEditorHandler={(reportId: string) => reportOperations.toggleReport(reportId)}
          shareModalDisplay={reportTileState.shareModalDisplay}
          editorDisplay={reportTileState.reportEditorDisplay}
          toggleShare={(shareReportId: string) => reportOperations.toggleShare(shareReportId)}
        />
      </Box>
    </>
  );
};

const useClasses = makeStyles()((theme) => ({
  header_title: {
    fontSize: 28
  },
  subtitle: {
    fontWeight: 500,
    marginBottom: 12
  },
  button: {
    height: 40,
    minWidth: 123
  }
}));
