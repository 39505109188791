import React from 'react';

import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
// import MediaBrowseBox from "../Media/MediaBrowseBox";
// import PerformanceBarChart from "../Visualisations/PerformanceBarChart";
// import { LineChart, Line, Legend, ResponsiveContainer, XAxis } from "recharts";
import FollowersIcon from '../../../../../assets/images/followers.svg';
import ReachIcon from '../../../../../assets/images/reach.svg';
import EngagementIcon from '../../../../../assets/images/engagement.svg';
// import { formatNumber, formatPercentage } from "../../lib/formatNumber";
import isEmpty from 'lodash/isEmpty';
// import InstagramAccountStats from "./InstagramAccountStats";
import { Profile } from 'types/generated';
import PerformanceBarChart from '../PerformanceBarChart';

interface IInstagramFeedSummary {
  profile?: Profile;
}

const InstagramFeedSummary = ({ profile }: IInstagramFeedSummary) => {
  const mediaStats = profile?.mediaStats;
  const followers_count = profile?.followers_count;

  const { classes } = styles();

  let performanceRows: any = [];

  if (followers_count) {
    performanceRows.push({
      name: 'Followers',
      value: followers_count,
      icon: FollowersIcon
    });
  }
  if (mediaStats?.reach)
    performanceRows.push({
      name: 'Reach',
      value: mediaStats.reach?.median,
      icon: ReachIcon
    });
  if (mediaStats?.engagement)
    performanceRows.push({
      name: `Engagement`,
      value: mediaStats.engagement?.median,
      percentageValue: mediaStats.engagement_percentageOfReach?.median,
      icon: EngagementIcon
    });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box className={'insights-container'}>
          <Typography className={classes.cardTitle}>Feed content over last 100 posts</Typography>
          {isEmpty(performanceRows) ? (
            <Typography>No available data</Typography>
          ) : (
            <PerformanceBarChart data={performanceRows} />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

const styles = makeStyles()({
  cardTitle: {
    marginBottom: 10,
    fontSize: 16,
    fontWeight: 500
  },
  cardSubtitle: {
    marginBottom: 10,
    fontSize: 14
  },
  insightsContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 10,
    margin: 4
  },
  insightsAmount: {
    fontSize: 18,
    textAlign: 'center'
  },
  insightsChange: {
    fontSize: 12,
    verticalAlign: 'top'
  },
  insightsTitle: {
    fontSize: 14,
    textAlign: 'center'
  },
  insightsSubtitle: {
    color: '#7A7A7A',
    fontSize: 10,
    textAlign: 'center'
  }
});

export default InstagramFeedSummary;
