import React, { useState } from 'react';
import { Status } from './Status';
import { Menu, MenuItem, ButtonBase, Grid, CircularProgress } from '@mui/material';
import { CollaborationStatus } from 'types/generated';
import ExpandIcon from './ExpandIcon';

interface IStatusSelector {
  value: CollaborationStatus;
  setValue: (status: CollaborationStatus) => void;
  loading?: boolean;
}

const StatusSelector = ({ value, setValue, loading }: IStatusSelector) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleSelect = (status: CollaborationStatus) => {
    setAnchorEl(null);
    setValue(status);
  };

  return (
    <>
      <ButtonBase
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}
        disabled={loading}
      >
        <Grid container alignItems='center'>
          <Grid item>
            <Status status={value} />
          </Grid>
          <Grid item>
            {loading ? (
              <CircularProgress size={10} style={{ marginLeft: 6 }} />
            ) : (
              <ExpandIcon isOpen={Boolean(anchorEl)} />
            )}
          </Grid>
        </Grid>
      </ButtonBase>
      <Menu id='long-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={() => handleSelect(CollaborationStatus.Draft)}>
          <Status status={CollaborationStatus.Draft} />
        </MenuItem>
        <MenuItem onClick={() => handleSelect(CollaborationStatus.Active)}>
          <Status status={CollaborationStatus.Active} />
        </MenuItem>
        <MenuItem onClick={() => handleSelect(CollaborationStatus.Archived)}>
          <Status status={CollaborationStatus.Archived} />
        </MenuItem>
      </Menu>
    </>
  );
};

export default StatusSelector;
