import React from 'react';

import TextField from '@mui/material/TextField';
import { TextFieldPasswordIcon } from './Shared/TextFieldPasswordIcon';

interface ITextPassword {
  error?: boolean;
  mismatch?: boolean;
  errorMessage?: string;
  id: string;
  label: string;
  show: boolean;
  placeholder?: string;
  dataCy?: string;
  onShow: () => void;
  value?: string;
  onChange?: (event?: any) => void;
  onKeyDown?: (e: any) => void;
}

const TextPassword = (props: ITextPassword) => {
  return (
    <TextField
      variant='standard'
      required
      error={props.error || props.mismatch}
      helperText={props.errorMessage}
      id={props.id}
      label={props.label}
      placeholder={props.placeholder}
      type={props.show ? 'text' : 'password'}
      fullWidth={true}
      InputProps={{
        id: props.dataCy || props.id,
        inputProps: {
          contentEditable: true,
          style: {
            fontSize: 18,
            letterSpacing: 0.5
          }
        },
        endAdornment: <TextFieldPasswordIcon showPassword={props.show} onClick={props.onShow} />
      }}
      value={props.value}
      onChange={props.onChange}
      margin='normal'
      onKeyDown={props.onKeyDown}
    />
  );
};

export default TextPassword;
