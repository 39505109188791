import { Avatar, Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { DeliverableEditMenu } from 'components/pages/CollabsPage/DeliverableEditMenu';
import { Status } from 'components/pages/CollabsPage/Status';
import availableChannels from 'lib/channels';
import deliverables from 'lib/deliverables';
import { formatNumbersWithCommas } from 'lib/formatNumber';
import { find, isEmpty, compact } from 'lodash';
import moment from 'moment';
import { Deliverable, SocialDeliverable } from 'types/generated';

interface Props {
  deliverable: Deliverable & {
    collaboration: { name: string };
  };
  selectedDate: string;
}

export const Card = (props: Props) => {
  const { deliverable, selectedDate } = props;
  const { classes } = useStyles();
  const {
    _id,
    socialChannel,
    socialDeliverable,
    endDate,
    endTime,
    notes,
    profiles,
    collaboration,
    discount,
    price,
    storyFrameCount
  } = deliverable;

  if (!_id) {
    return null;
  }

  const channel = find(availableChannels, (o) => o.value === socialChannel?.toLowerCase());
  const deliverableLabel =
    socialDeliverable === SocialDeliverable.IgStory && storyFrameCount && storyFrameCount > 1
      ? `${storyFrameCount}x Story frames`
      : deliverables.find((o) => o.value === socialDeliverable)?.label || '';

  // Parse endDate and endTime into a formatted string
  const endTimeHours = Math.floor(endTime / 100);
  const endTimeMinutes = endTime % 100;
  const parseEndDate = endDate?.split('-') || [];
  const year = parseEndDate[0] || '';
  const month = parseInt(parseEndDate[1]) || 0;
  const dayOfMonth = parseEndDate[2] || '';
  // Moment format required month to be adjusted
  const endDateTimeFormattedString = endDate
    ? moment([year, month - 1, dayOfMonth, endTimeHours, endTimeMinutes]).format('D MMM YY [at] h:mma')
    : '';

  const renderSocials = () => {
    return compact(profiles).map((o, i) => {
      return (
        <Grid container key={i}>
          <Avatar src={o.user?.avatar || ''} className={classes.avatar} />
          <Typography className={classes.socialText}>{o.displayName}</Typography>
        </Grid>
      );
    });
  };

  const renderCost = () => {
    if (!price) return null;

    const safeDiscount = discount || 0;
    const calculatedPrice = formatNumbersWithCommas((price * (100 - safeDiscount)) / 100);

    return (
      <Grid item xs={12} className={classes.cardRow}>
        <Typography className={classes.bodyText}>AUD${calculatedPrice}</Typography>
      </Grid>
    );
  };
  const isActive = Boolean(endDate === selectedDate);

  return (
    <Paper key={`deliverable-${_id}`} className={`${classes.deliverableCard} ${isActive ? classes.activeCard : ''}`}>
      <Grid container spacing={1}>
        <Grid container item xs={12} className={classes.cardRow} justifyContent='space-between'>
          <Grid item>
            <Grid container alignItems='center'>
              {channel && (
                <img src={channel.logo} alt={channel.label || 'social channel'} className={classes.channelLogo} />
              )}
              {deliverableLabel && <Typography variant='h6'>{deliverableLabel}</Typography>}
            </Grid>
          </Grid>
          <DeliverableEditMenu deliverable={deliverable} deliverableId={_id} />
        </Grid>
        {!isEmpty(profiles) && (
          <>
            <Grid item xs={12} className={classes.cardRow}>
              {renderSocials()}
            </Grid>
          </>
        )}
        <Grid item xs={12} className={classes.cardRow}>
          <Typography className={classes.bodyText}>{endDateTimeFormattedString}</Typography>
        </Grid>
        <Grid item xs={12} className={classes.cardRow}>
          <Typography className={classes.bodyText}>{collaboration?.name || ''}</Typography>
        </Grid>
        <Grid item xs={12} className={classes.cardRow}>
          <Typography className={classes.bodyText}>Status </Typography>
          <Status status={collaboration?.status || ''} />
        </Grid>
        {renderCost()}
        {notes && (
          <Grid item xs={12} className={classes.cardColumn}>
            <Typography className={classes.bodyText}>Notes</Typography>
            <Typography className={classes.notesText}>{notes}</Typography>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

const useStyles = makeStyles()((theme) => {
  return {
    avatar: {
      height: 17,
      width: 17,
      marginRight: 9.5
    },
    socialText: {
      color: '#000000'
    },
    bodyText: {
      color: '#000000',
      fontWeight: 500
    },
    notesText: {
      fontSize: 10
    },
    deliverableCard: {
      padding: '10px 20px',
      margin: '15px 0',
      position: 'relative'
    },
    activeCard: {
      border: `solid 2px ${theme.palette.primary.main}`
    },
    cardRow: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      flexWrap: 'nowrap'
    },
    cardColumn: {
      display: 'flex',
      flexDirection: 'column'
    },
    channel: {
      fontWeight: 500
    },
    channelLogo: {
      height: 14,
      width: 14,
      marginRight: 5
    }
  };
});
