import React, { createContext } from 'react';
import { ApolloError } from '@apollo/client';
import { AxiosError } from 'axios';
import { ToastContainer, toast, ToastContainerProps, Slide } from 'react-toastify';
import './toast.css';
import _ from 'lodash';
import ClearIcon from '@mui/icons-material/Clear';
import { Box } from '@mui/material';

type errorTypes = 'info' | 'success' | 'warning' | 'error' | 'default' | 'dark' | undefined;

/**
 * ! please following the format, picking one form errorType
 * ! ending with
 * ! "_error"
 * ! "_warning"
 * ! "_success"
 */

export enum Error_Type_Enum {
  // errors
  api_error,
  auth_error,
  request_error,
  unknown_error,
  // warnings
  form_incomplete_warning,
  // success
  form_submit_success,
  // graphql_error
  graphql_error,
  // report
  report_retrieve_error,
  report_create_success,
  report_create_error,
  report_update_success,
  report_update_error,
  report_delete_success,
  report_delete_error,
  report_share_success,
  report_share_error,
  // general
  passwords_do_not_match,
  server_error,
  // first log in hint
  first_login_success,
  // sqs toast
  profile_ready_success
}

const ERROR_MESSAGE_LIST: { [key in keyof typeof Error_Type_Enum]: string } = {
  // ERRORS MSG
  request_error: 'Request Error',
  auth_error: 'Sorry, you are not authenticated yet',
  unknown_error: 'Sorry, there is unknown error, please try again later',
  api_error: 'Sorry, server error, try again later',
  // WARNING MSG
  form_incomplete_warning: 'Sorry, the form is not completed',
  // SUCCESS
  form_submit_success: 'Form has been submitted successfully',
  // graphql error
  graphql_error: 'graphQL error',
  // report
  report_retrieve_error: 'Error Retrieving Report',
  report_create_success: 'Report Generated Successfully',
  report_create_error: 'Failed to Generate the report',
  report_update_error: 'Report update failed',
  report_update_success: 'Report updated successfully',
  report_share_success: 'Report shared successfully',
  report_share_error: 'Report share failed, please try different details',
  report_delete_success: 'Report deleted successfully',
  report_delete_error: 'Error deleting report',
  //
  passwords_do_not_match: `Your confirmation password doesn't match`,
  server_error: `There was an error on the server`,
  // first log in
  first_login_success: '',
  profile_ready_success: ''
};

// created Context
export const ErrorHandlerCtx = createContext({
  catchError: (errorType: Error_Type_Enum, customizedErrorMsg?: any, error?: ApolloError | AxiosError) => {
    console.log(ERROR_MESSAGE_LIST[errorType], customizedErrorMsg);
  }
});

// component provider
interface IErrorHandlerProvider {
  children: React.ReactNode;
}

const ErrorHandlerProvider: React.FC<IErrorHandlerProvider> = (props) => {
  const CloseButton = ({ closeToast }) => (
    <Box mt={'12px'} onClick={closeToast}>
      <ClearIcon fontSize='medium' style={{ transform: 'scale(1.2)' }} />
    </Box>
  );

  const isAxiosError = (error: AxiosError | ApolloError): error is AxiosError => {
    return (error as AxiosError).isAxiosError !== undefined;
  };

  const catchError = (errorType: Error_Type_Enum, customizedErrorMsg?: any, error?: ApolloError | AxiosError) => {
    // need to sent the error to server
    if (
      error &&
      ((isAxiosError(error) && error?.message === 'Network Error') || (!isAxiosError(error) && error?.networkError))
    ) {
      toast('You need internet for this to work! Check your connection.', {
        ...toast_config,
        type: 'error',
        closeButton: CloseButton
      });
    } else {
      const type = _.last(Error_Type_Enum[errorType].split('_')) as errorTypes;

      if (customizedErrorMsg && typeof customizedErrorMsg !== 'string') {
        customizedErrorMsg = JSON.stringify(customizedErrorMsg);
      }

      const msgTemplate = `${ERROR_MESSAGE_LIST[Error_Type_Enum[errorType]]} ${
        customizedErrorMsg ? '' + customizedErrorMsg : ''
      }`;

      toast(msgTemplate, {
        ...toast_config,
        type: type,
        closeButton: CloseButton
      });
    }
  };

  return (
    <ErrorHandlerCtx.Provider value={{ catchError }}>
      <ToastContainer {...toast_container_config} />
      {props.children}
    </ErrorHandlerCtx.Provider>
  );
};

export { ErrorHandlerProvider };

// configuration
const toast_container_config: ToastContainerProps = {
  position: 'bottom-left',
  autoClose: 4000,
  hideProgressBar: true,
  newestOnTop: true,
  rtl: false,
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
  limit: 5
};

const toast_config: ToastContainerProps = {
  transition: Slide
};
