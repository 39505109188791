import { Box, BoxProps } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface Props extends BoxProps {
  children: JSX.Element | JSX.Element[];
}

export const CenteredContainer = (props: Props) => {
  const { classes } = useStyles();
  const { children, ...otherProps } = props;

  return (
    <Box className={classes.centeredContainer} {...otherProps}>
      {children}
    </Box>
  );
};

const useStyles = makeStyles()((theme) => ({
  centeredContainer: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute'
  }
}));
