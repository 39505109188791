import React from 'react';

const ArrowLeft = ({ fill, size }: { fill?: string; size?: number }) => {
  const iconSize = size || 24;
  return (
    <svg
      width={`${iconSize}px`}
      height={`${iconSize}px`}
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>A61D5B96-2119-469D-8AC4-E6541866D413@1x</title>
      <g id='3.-Influencer' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='3.1-Influencer-view_Instagram' transform='translate(-391.000000, -1481.000000)' fill={fill || '#EEEEEE'}>
          <g id='Performance' transform='translate(391.000000, 1231.000000)'>
            <path
              d='M12,250 C18.627417,250 24,255.372583 24,262 C24,268.627417 18.627417,274 12,274 C5.372583,274 0,268.627417 0,262 C0,255.372583 5.372583,250 12,250 Z M10.0909091,255.355472 L8.35547182,257.090909 L13.2645627,262 L8.35547182,266.909091 L10.0909091,268.644528 L15.8677186,262.867719 C16.347122,262.388315 16.347122,261.611679 15.8677186,261.132281 L10.0909091,255.355472 Z'
              id='Combined-Shape-Copy'
              transform='translate(12.000000, 262.000000) scale(-1, 1) translate(-12.000000, -262.000000) '
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ArrowLeft;
