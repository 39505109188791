import { useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';

import { find } from 'lodash';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

type DropdownOption = {
  value: string;
  title: string;
  onClick?: () => void;
};

const DropdownMenu = ({
  value,
  onChange,
  options,
  placeholder,
  displayValue
}: {
  value?: string;
  onChange: (value: string) => void;
  options: Array<DropdownOption>;
  placeholder?: string;
  displayValue?: string;
}) => {
  const [anchorEl, setAnchorEl] = useState<undefined | Element>();
  const selectedOption = find(options, { value });

  if (options.length === 0) {
    return null;
  }

  return (
    <>
      <Button
        onClick={(event) => setAnchorEl(event.currentTarget)}
        size='small'
        endIcon={<KeyboardArrowDownIcon fontSize='small' />}
        style={{ padding: 3 }}
      >
        {displayValue || selectedOption?.title || placeholder || 'Select'}
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(undefined)}>
        {options.map((o) => (
          <MenuItem
            key={o.value}
            onClick={() => {
              if (o.onClick) {
                o.onClick();
              } else {
                onChange(o.value);
              }
              setAnchorEl(undefined);
            }}
            style={{ fontSize: 10 }}
          >
            {o.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default DropdownMenu;
