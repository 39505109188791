import React from 'react';

import { useTheme } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import SnackbarContent from '@mui/material/SnackbarContent';
import Divider from '@mui/material/Divider';

import { useGetMyUserQuery } from '../../types/generated';

import appConfig from '../../config/appConfig';
import './InstagramVerification.css';

import Typography from '@mui/material/Typography';

const facebookLogoUrl = 'https://media.q-83.com/logo_facebook.png';

const InstagramVerification = () => {
  const theme = useTheme();

  const { data: userData, loading: userLoading, error: userError } = useGetMyUserQuery();

  const displayError = userError?.message;
  const loading = userLoading;

  const user = userData?.myUser;

  const renderButtonLogin = () => {
    return (
      <Grid item xs={12}>
        <Button
          variant='outlined'
          href={`https://www.facebook.com/v6.0/dialog/oauth?client_id=${appConfig.FACEBOOK_APP_ID}&display=popup&scope=${appConfig.FACEBOOK_APP_PERMISSIONS}&redirect_uri=${appConfig.FACEBOOK_REDIRECT_URI}&state=${user?._id}`}
        >
          {loading ? (
            <CircularProgress style={{ color: '#fff' }} />
          ) : (
            <div>
              {`Login with`}
              <img src={facebookLogoUrl} alt='' style={{ height: 16, width: 16, marginLeft: 8 }} />
            </div>
          )}
        </Button>
      </Grid>
    );
  };

  const renderServerError = () => {
    if (displayError) {
      return (
        <div
          style={{
            marginTop: '15px',
            width: '100%',
            maxWidth: '725px',
            padding: '0 5px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <SnackbarContent
            message={
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', fontSize: 12 }}>
                <WarningIcon fontSize='large' />
                <div style={{ marginLeft: 6 }}>{displayError}</div>
              </div>
            }
            style={{
              backgroundColor: '#fff8e6',
              color: '#7990b7',
              borderRadius: 5,
              boxShadow: 'none',
              border: '1px solid #bfc6d3'
            }}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <Grid
      item
      container
      direction='column'
      style={{
        width: '100%',
        maxWidth: '600px',
        display: 'flex'
      }}
    >
      <Grid item container justifyContent='space-between' style={{ marginBottom: 10 }}>
        <Grid item container xs={4} alignItems='center'>
          <Grid item>
            <img
              src={'https://media.q-83.com/site/instagram_logo.png'}
              alt=''
              style={{ height: theme.spacing(5), width: theme.spacing(5), marginRight: theme.spacing(2) }}
            />
          </Grid>
          <Grid>
            <span style={{ margin: '5px 5px 5px 7px', fontSize: '20px', fontWeight: 600 }}>Instagram</span>
          </Grid>
        </Grid>
        <Grid item>{renderButtonLogin()}</Grid>
      </Grid>
      <Divider style={{ backgroundColor: '#BBBBBB' }} />
      <Grid item style={{ marginTop: 10 }}>
        <Typography variant='body1'>
          To link your Instagram/Facebook accounts to Kitly you'll need to log in with Facebook. This allows us to
          display your insights accurately and update them in real time. We take the privacy of your data very seriously
          -
        </Typography>
      </Grid>
      {renderServerError()}
    </Grid>
  );
};

export default InstagramVerification;
