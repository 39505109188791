import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  BigInt: any;
  /** Convert Braintree Date to JS Date */
  BraintreeDate: any;
  /** Date custom scalar type */
  Date: any;
  /** Date in the format of YYYY-MM-DD */
  DateString: any;
  /** For parsing a javascript object into a string */
  JSObject: any;
  /** Mongo object id scalar type */
  ObjectId: any;
  /** Convert Stripe Timestamp to JS Date */
  StripeTimestamp: any;
  /** Time in hours from 0 to 2399 */
  TimeHours: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AwsTemporaryCredentials = {
  __typename?: 'AWSTemporaryCredentials';
  AccessKeyId?: Maybe<Scalars['String']>;
  SecretAccessKey?: Maybe<Scalars['String']>;
  SessionToken?: Maybe<Scalars['String']>;
  Expiration?: Maybe<Scalars['Date']>;
  region?: Maybe<Scalars['String']>;
  apiVersion?: Maybe<Scalars['String']>;
};

export type ActiveNotifications = {
  __typename?: 'ActiveNotifications';
  isAssignNewDeliverables?: Maybe<Scalars['Boolean']>;
  isMediaKitActivity?: Maybe<Scalars['Boolean']>;
  isPromotionsAndMarketing?: Maybe<Scalars['Boolean']>;
  isRememberDeliverables?: Maybe<Scalars['Boolean']>;
  isReportActivity?: Maybe<Scalars['Boolean']>;
  isSyncDeliverableToCalendar?: Maybe<Scalars['Boolean']>;
  isNotifiedOfWeeklyReport?: Maybe<Scalars['Boolean']>;
};

export type ActiveNotificationsUpdate = {
  isAssignNewDeliverables?: Maybe<Scalars['Boolean']>;
  isMediaKitActivity?: Maybe<Scalars['Boolean']>;
  isPromotionsAndMarketing?: Maybe<Scalars['Boolean']>;
  isRememberDeliverables?: Maybe<Scalars['Boolean']>;
  isReportActivity?: Maybe<Scalars['Boolean']>;
  isSyncDeliverableToCalendar?: Maybe<Scalars['Boolean']>;
  isNotifiedOfWeeklyReport?: Maybe<Scalars['Boolean']>;
};

export enum AdType {
  Kitlyweb = 'KITLYWEB',
  Kitlyapp = 'KITLYAPP',
  Kitlybusiness = 'KITLYBUSINESS'
}

export type AdminProfileInfo = {
  __typename?: 'AdminProfileInfo';
  profileInfo?: Maybe<Array<Maybe<Array<Maybe<Scalars['String']>>>>>;
};

export type AgeGroup = {
  __typename?: 'AgeGroup';
  ageGroup?: Maybe<Scalars['String']>;
  viewerPercentage?: Maybe<Scalars['Float']>;
};

export type Ambassador = {
  __typename?: 'Ambassador';
  _id: Scalars['ObjectId'];
  acceptedInvite?: Maybe<Scalars['Boolean']>;
  ambassadorId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ObjectId']>;
  brandId?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['ObjectId']>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
  disconnectedAt?: Maybe<Scalars['Date']>;
  inviteSent?: Maybe<Scalars['Date']>;
  /** @deprecated Use inviteSent */
  invite_sent?: Maybe<Scalars['Date']>;
  isArchive?: Maybe<Scalars['Boolean']>;
  talentNotes?: Maybe<Scalars['String']>;
  ambassadorCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  couponCode?: Maybe<Scalars['ObjectId']>;
  info?: Maybe<AmbassadorInfo>;
  clientIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  attachments?: Maybe<Array<Maybe<LinkPair>>>;
  invitedPublicUsername?: Maybe<Scalars['String']>;
  userDeletedDate?: Maybe<Scalars['Date']>;
  ambassadorAuditReportLink?: Maybe<Scalars['String']>;
  status?: Maybe<AmbassadorStatus>;
  user?: Maybe<User>;
  brand?: Maybe<User>;
  profile?: Maybe<Profile>;
  profiles?: Maybe<Array<Profile>>;
  profileUser?: Maybe<User>;
  contact?: Maybe<Contact>;
  mediaKits?: Maybe<Array<MediaKitConfig>>;
  activity?: Maybe<Array<AmbassadorActivity>>;
  clients?: Maybe<Array<Contact>>;
  avatar?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  collabsCount?: Maybe<Scalars['Int']>;
  deliverablesCount?: Maybe<Scalars['Int']>;
  instagramReelPerformance?: Maybe<Scalars['String']>;
  instagramFeedPerformance?: Maybe<Scalars['String']>;
  instagramStoryPerformance?: Maybe<Scalars['String']>;
  tiktokVideoPerformance?: Maybe<Scalars['String']>;
  youtubeVideoPerformance?: Maybe<Scalars['String']>;
  facebookPostPerformance?: Maybe<Scalars['String']>;
  networkAgency?: Maybe<Scalars['String']>;
  history?: Maybe<Array<Maybe<AmbassadorHistory>>>;
};

export type AmbassadorActivity = {
  __typename?: 'AmbassadorActivity';
  itemId?: Maybe<Scalars['ObjectId']>;
  timestamp?: Maybe<Scalars['Date']>;
  action?: Maybe<AmbassadorActivityAction>;
  type?: Maybe<AmbassadorActivityType>;
  profileId?: Maybe<Scalars['ObjectId']>;
  ambassadorId?: Maybe<Scalars['ObjectId']>;
  profile?: Maybe<Profile>;
  ambassador?: Maybe<Ambassador>;
  mediaKitLink?: Maybe<MediaKitLink>;
  reportConfig?: Maybe<ReportConfig>;
  deliverable?: Maybe<Deliverable>;
};

export enum AmbassadorActivityAction {
  Sent = 'SENT',
  Opened = 'OPENED',
  Added = 'ADDED',
  SetUp = 'SET_UP'
}

export enum AmbassadorActivityType {
  MediaKit = 'MEDIA_KIT',
  Report = 'REPORT',
  Deliverable = 'DELIVERABLE'
}

export type AmbassadorAgentDetails = {
  __typename?: 'AmbassadorAgentDetails';
  name?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type AmbassadorAgentDetailsInput = {
  name?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export enum AmbassadorBusinessType {
  Company = 'COMPANY',
  SoleTrader = 'SOLE_TRADER'
}

export type AmbassadorContractDetails = {
  __typename?: 'AmbassadorContractDetails';
  contractName?: Maybe<Scalars['String']>;
  renewalDate?: Maybe<Scalars['Date']>;
};

export type AmbassadorContractDetailsInput = {
  contractName?: Maybe<Scalars['String']>;
  renewalDate?: Maybe<Scalars['Date']>;
};

export type AmbassadorFrequentFlyerDetails = {
  __typename?: 'AmbassadorFrequentFlyerDetails';
  carrier?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export type AmbassadorFrequentFlyerDetailsInput = {
  carrier?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export type AmbassadorHistory = {
  __typename?: 'AmbassadorHistory';
  title?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  profileId?: Maybe<Scalars['String']>;
  profile?: Maybe<Profile>;
};

export type AmbassadorInfo = {
  __typename?: 'AmbassadorInfo';
  name?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  agentDetails?: Maybe<AmbassadorAgentDetails>;
  dateOfBirth?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<Scalars['String']>;
  shippingAddress?: Maybe<Scalars['String']>;
  businessType?: Maybe<AmbassadorBusinessType>;
  dietaryRequirements?: Maybe<Scalars['String']>;
  businessStructure?: Maybe<Scalars['String']>;
  gstRegistration?: Maybe<Scalars['Boolean']>;
  frequentFlyerDetails?: Maybe<AmbassadorFrequentFlyerDetails>;
  contractDetails?: Maybe<AmbassadorContractDetails>;
  contractRenews?: Maybe<Scalars['Date']>;
};

export type AmbassadorInfoInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['Upload']>;
  phoneNumber?: Maybe<Scalars['String']>;
  agentDetails?: Maybe<AmbassadorAgentDetailsInput>;
  dateOfBirth?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<Scalars['String']>;
  shippingAddress?: Maybe<Scalars['String']>;
  businessType?: Maybe<AmbassadorBusinessType>;
  dietaryRequirements?: Maybe<Scalars['String']>;
  businessStructure?: Maybe<Scalars['String']>;
  gstRegistration?: Maybe<Scalars['Boolean']>;
  frequentFlyerDetails?: Maybe<AmbassadorFrequentFlyerDetailsInput>;
  contractDetails?: Maybe<AmbassadorContractDetailsInput>;
};

export type AmbassadorInput = {
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedStatus?: Maybe<Scalars['Boolean']>;
  talentNotes?: Maybe<Scalars['String']>;
  ambassadorCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  info?: Maybe<AmbassadorInfoInput>;
  clientIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  attachments?: Maybe<Array<Maybe<FileInput>>>;
  invitedPublicUsername?: Maybe<Scalars['String']>;
};

export type AmbassadorLoginResult = {
  __typename?: 'AmbassadorLoginResult';
  type?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type AmbassadorRegistrationResult = {
  __typename?: 'AmbassadorRegistrationResult';
  success?: Maybe<Scalars['Boolean']>;
  user?: Maybe<User>;
  data?: Maybe<AmbassadorLoginResult>;
  message?: Maybe<Scalars['String']>;
};

export enum AmbassadorStatus {
  Accepted = 'ACCEPTED',
  PendingInviteFromInfluencer = 'PENDING_INVITE_FROM_INFLUENCER',
  PendingInviteFromBrand = 'PENDING_INVITE_FROM_BRAND',
  DeclinedInviteFromInfluencer = 'DECLINED_INVITE_FROM_INFLUENCER',
  DeclinedInviteFromBrand = 'DECLINED_INVITE_FROM_BRAND'
}

export type AppPurchaseRecord = {
  __typename?: 'AppPurchaseRecord';
  acknowledged?: Maybe<Scalars['Boolean']>;
  productId?: Maybe<Scalars['String']>;
  purchaseTime?: Maybe<Scalars['Date']>;
  purchaseState?: Maybe<Scalars['String']>;
  transactionReceipt?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  packageName?: Maybe<Scalars['String']>;
  originalOrderId?: Maybe<Scalars['String']>;
  originalPurchaseTime?: Maybe<Scalars['Float']>;
};

export type AppSubscription = {
  __typename?: 'AppSubscription';
  acknowledged?: Maybe<Scalars['Boolean']>;
  productId?: Maybe<Scalars['String']>;
  purchaseTime?: Maybe<Scalars['Date']>;
  purchaseState?: Maybe<Scalars['String']>;
  transactionReceipt?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  packageName?: Maybe<Scalars['String']>;
  originalOrderId?: Maybe<Scalars['String']>;
  originalPurchaseTime?: Maybe<Scalars['Float']>;
  _id: Scalars['ObjectId'];
  userId?: Maybe<Scalars['ObjectId']>;
  invalidatedById?: Maybe<Scalars['ObjectId']>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
  invalidatedBy?: Maybe<AppSubscription>;
};

export type AppSubscriptionInput = {
  acknowledged?: Maybe<Scalars['Boolean']>;
  productId?: Maybe<Scalars['String']>;
  transactionReceipt?: Maybe<Scalars['String']>;
  purchaseTime?: Maybe<Scalars['Date']>;
  purchaseState?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  packageName?: Maybe<Scalars['String']>;
  originalOrderId?: Maybe<Scalars['String']>;
  originalPurchaseTime?: Maybe<Scalars['Float']>;
};

export type AppUpdate = {
  __typename?: 'AppUpdate';
  version?: Maybe<Scalars['String']>;
  panels?: Maybe<Array<Maybe<AppUpdatePanel>>>;
  minimumVersion?: Maybe<Scalars['String']>;
  notifyUpToVersion?: Maybe<Scalars['String']>;
  notifyDownToVersion?: Maybe<Scalars['String']>;
  navigateTo?: Maybe<Scalars['String']>;
};

export type AppUpdatePanel = {
  __typename?: 'AppUpdatePanel';
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  widthPercentage?: Maybe<Scalars['Float']>;
  navigateTo?: Maybe<Scalars['String']>;
};

export type AudienceDemographicsContent = {
  __typename?: 'AudienceDemographicsContent';
  type?: Maybe<ElementType>;
  audience_demographics_content?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
};

export type AudienceGender = {
  __typename?: 'AudienceGender';
  male?: Maybe<MetricSummary>;
  female?: Maybe<MetricSummary>;
  unspecified?: Maybe<MetricSummary>;
};

export enum BankCode {
  Bsb = 'BSB',
  Bic = 'BIC',
  Swift = 'SWIFT',
  Chips = 'CHIPS',
  Bncc = 'BNCC',
  Bsc = 'BSC',
  Ifsc = 'IFSC'
}

export type BitlinkSummary = {
  __typename?: 'BitlinkSummary';
  unit_reference?: Maybe<Scalars['String']>;
  total_clicks?: Maybe<Scalars['Int']>;
  units?: Maybe<Scalars['Int']>;
  unit?: Maybe<Scalars['String']>;
};

export type BitlyReportStats = {
  __typename?: 'BitlyReportStats';
  totalsByCountry?: Maybe<Scalars['JSObject']>;
  totalsByType?: Maybe<Scalars['JSObject']>;
};

export type BraintreeCustomer = {
  __typename?: 'BraintreeCustomer';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['BraintreeDate']>;
  updatedAt?: Maybe<Scalars['BraintreeDate']>;
  addresses?: Maybe<Array<Scalars['JSObject']>>;
  company?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  paymentMethods?: Maybe<Array<Scalars['JSObject']>>;
  user?: Maybe<User>;
  transactions?: Maybe<Array<BraintreeTransaction>>;
};

export type BraintreePaymentMethod = {
  __typename?: 'BraintreePaymentMethod';
  token?: Maybe<Scalars['String']>;
  billingAgreementId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  payerId?: Maybe<Scalars['String']>;
  revokedAt?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<Scalars['JSObject']>;
  bin?: Maybe<Scalars['String']>;
  cardType?: Maybe<Scalars['String']>;
  cardholderName?: Maybe<Scalars['String']>;
  commercial?: Maybe<Scalars['String']>;
  countryOfIssue?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['BraintreeDate']>;
  customerId?: Maybe<Scalars['String']>;
  customer?: Maybe<BraintreeCustomer>;
  customerLocation?: Maybe<Scalars['String']>;
  debit?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['Boolean']>;
  durbinRegulated?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['String']>;
  expirationMonth?: Maybe<Scalars['String']>;
  expirationYear?: Maybe<Scalars['String']>;
  expired?: Maybe<Scalars['Boolean']>;
  healthcare?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  issuingBank?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  maskedNumber?: Maybe<Scalars['String']>;
  payroll?: Maybe<Scalars['String']>;
  prepaid?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  subscriptions?: Maybe<Array<Maybe<BraintreeSubscription>>>;
  uniqueNumberIdentifier?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['BraintreeDate']>;
};

export type BraintreeSubscription = {
  __typename?: 'BraintreeSubscription';
  id: Scalars['ID'];
  balance?: Maybe<Scalars['String']>;
  billingPeriodStartDate?: Maybe<Scalars['String']>;
  billingPeriodEndDate?: Maybe<Scalars['String']>;
  billingDayOfMonth?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['BraintreeDate']>;
  currentBillingCycle?: Maybe<Scalars['Int']>;
  daysPastDue?: Maybe<Scalars['Int']>;
  transactions?: Maybe<Array<BraintreeTransaction>>;
  firstBillingDate?: Maybe<Scalars['Date']>;
  price?: Maybe<Scalars['String']>;
  merchantAccountId?: Maybe<Scalars['String']>;
  nextBillAmount?: Maybe<Scalars['String']>;
  nextBillingDate?: Maybe<Scalars['BraintreeDate']>;
  nextBillingPeriodAmount?: Maybe<Scalars['String']>;
  neverExpires?: Maybe<Scalars['Boolean']>;
  paidThroughDate?: Maybe<Scalars['Date']>;
  paymentMethodToken?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['BraintreeDate']>;
  trialPeriod?: Maybe<Scalars['Boolean']>;
  failureCount?: Maybe<Scalars['Int']>;
  paymentMethod?: Maybe<BraintreePaymentMethod>;
  user?: Maybe<User>;
};

export type BraintreeTransaction = {
  __typename?: 'BraintreeTransaction';
  id: Scalars['ID'];
  additionalProccessorResponse?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['BraintreeDate']>;
  currencyIsoCode?: Maybe<Scalars['String']>;
  taxExempt?: Maybe<Scalars['Boolean']>;
  taxAmount?: Maybe<Scalars['String']>;
  processorResponseCode?: Maybe<Scalars['String']>;
  processorResponseText?: Maybe<Scalars['String']>;
  processorSettlementResponseCode?: Maybe<Scalars['String']>;
  processorSettlementResponseText?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['BraintreeDate']>;
  subscriptionId?: Maybe<Scalars['ID']>;
  refundedTransactionId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  discountAmount?: Maybe<Scalars['String']>;
  subscription?: Maybe<BraintreeSubscription>;
  refundedTransaction?: Maybe<BraintreeTransaction>;
  paymentMethodType?: Maybe<Scalars['String']>;
  paymentMethodDescription?: Maybe<Scalars['String']>;
  paymentMethodImageUrl?: Maybe<Scalars['String']>;
};

export type ChannelReport = {
  __typename?: 'ChannelReport';
  day?: Maybe<Scalars['String']>;
  views?: Maybe<Scalars['Int']>;
  /** @deprecated Use views */
  redViews?: Maybe<Scalars['Int']>;
  estimatedMinutesWatched?: Maybe<Scalars['Int']>;
  /** @deprecated Use estimatedMinutesWatched */
  estimatedRedMinutesWatched?: Maybe<Scalars['Int']>;
  averageViewDuration?: Maybe<Scalars['Int']>;
  averageViewPercentage?: Maybe<Scalars['Float']>;
  subscribersGained?: Maybe<Scalars['Int']>;
  /** @deprecated Not supported */
  subscribersLost?: Maybe<Scalars['Int']>;
  annotationClickThroughRate?: Maybe<Scalars['Float']>;
};

export type ChannelStats = {
  __typename?: 'ChannelStats';
  viewCount?: Maybe<Scalars['BigInt']>;
  commentCount?: Maybe<Scalars['BigInt']>;
  subscriberCount?: Maybe<Scalars['Int']>;
  hiddenSubscriberCount?: Maybe<Scalars['Boolean']>;
  videoCount?: Maybe<Scalars['Int']>;
};

export type Collaboration = {
  __typename?: 'Collaboration';
  _id: Scalars['ObjectId'];
  userId?: Maybe<Scalars['ObjectId']>;
  profileIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  avatar?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<CollaborationStatus>;
  brandId?: Maybe<Scalars['ObjectId']>;
  collaborationStart?: Maybe<Scalars['Date']>;
  collaborationEnd?: Maybe<Scalars['Date']>;
  mentions?: Maybe<Array<Maybe<Scalars['String']>>>;
  hashtags?: Maybe<Array<Maybe<Scalars['String']>>>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
  hasShareWithTalent?: Maybe<Scalars['Boolean']>;
  fileUploads?: Maybe<Array<Maybe<LinkPair>>>;
  profileTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  notes?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['ObjectId']>;
  ambassadorIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  reportId?: Maybe<Scalars['ObjectId']>;
  user?: Maybe<User>;
  canEdit?: Maybe<Scalars['Boolean']>;
  brand?: Maybe<Contact>;
  profiles?: Maybe<Array<Profile>>;
  trackingLinks?: Maybe<Array<TrackingLink>>;
  deliverables?: Maybe<Array<Deliverable>>;
  userRows?: Maybe<Array<CollaborationUserRow>>;
  profileRows?: Maybe<Array<Maybe<CollaborationProfileRow>>>;
};

export type CollaborationInvoice = {
  __typename?: 'CollaborationInvoice';
  _id: Scalars['ObjectId'];
  userId?: Maybe<Scalars['ObjectId']>;
  inactive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['Date']>;
  status?: Maybe<InvoiceStatus>;
  invoiceNumber?: Maybe<Scalars['Int']>;
  invoiceId?: Maybe<Scalars['String']>;
  collaborationId?: Maybe<Scalars['ObjectId']>;
  deliverableIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  invoiceToContactId?: Maybe<Scalars['ObjectId']>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeInvoiceUrl?: Maybe<Scalars['String']>;
  taxStatus?: Maybe<Scalars['String']>;
  taxType?: Maybe<Scalars['String']>;
  taxPercentage?: Maybe<Scalars['Float']>;
  totalPrice?: Maybe<Scalars['Float']>;
  currency?: Maybe<UserCurrency>;
  user?: Maybe<User>;
  deliverables?: Maybe<Array<Maybe<Deliverable>>>;
  collaboration?: Maybe<Collaboration>;
  profiles?: Maybe<Array<Profile>>;
  stripeInvoice?: Maybe<StripeInvoice>;
  pdfLink?: Maybe<Scalars['String']>;
  links?: Maybe<Array<CollaborationInvoiceLink>>;
};

export type CollaborationInvoiceLink = {
  __typename?: 'CollaborationInvoiceLink';
  _id: Scalars['ObjectId'];
  collaborationInvoiceId?: Maybe<Scalars['ObjectId']>;
  shortenedLink?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  contactDetails?: Maybe<InvoiceShareContact>;
  isOpened?: Maybe<Scalars['Boolean']>;
  openedAt?: Maybe<Scalars['Date']>;
  created_at?: Maybe<Scalars['Date']>;
  contact?: Maybe<Contact>;
};

export type CollaborationInvoiceLinkCreationResult = {
  __typename?: 'CollaborationInvoiceLinkCreationResult';
  collaborationInvoiceId?: Maybe<Scalars['ObjectId']>;
  link?: Maybe<Scalars['String']>;
};

export type CollaborationInvoiceTotals = {
  __typename?: 'CollaborationInvoiceTotals';
  currency?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  all?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  paid?: Maybe<Scalars['Float']>;
  due?: Maybe<Scalars['Float']>;
  overdue?: Maybe<Scalars['Float']>;
  allPercentage?: Maybe<Scalars['Float']>;
  paidPercentage?: Maybe<Scalars['Float']>;
  duePercentage?: Maybe<Scalars['Float']>;
  contact?: Maybe<Contact>;
};

export type CollaborationInvoiceTotalsResponse = {
  __typename?: 'CollaborationInvoiceTotalsResponse';
  totals?: Maybe<Array<Maybe<CollaborationInvoiceTotals>>>;
  invoices?: Maybe<Array<Maybe<CollaborationInvoice>>>;
  stripeInvoices?: Maybe<Array<Maybe<StripeInvoice>>>;
};

export type CollaborationInvoiceUpdates = {
  name?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['Date']>;
  status?: Maybe<InvoiceStatus>;
  collaborationId?: Maybe<Scalars['ObjectId']>;
  deliverableIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  invoiceToContactId?: Maybe<Scalars['ObjectId']>;
  taxStatus?: Maybe<Scalars['String']>;
  taxType?: Maybe<Scalars['String']>;
  taxPercentage?: Maybe<Scalars['Float']>;
  stripeTaxRateId?: Maybe<Scalars['String']>;
};

export type CollaborationProfileRow = {
  __typename?: 'CollaborationProfileRow';
  profile?: Maybe<Profile>;
  ambassador?: Maybe<Ambassador>;
  deliverables?: Maybe<Array<Maybe<Deliverable>>>;
};

export enum CollaborationStatus {
  Draft = 'DRAFT',
  Active = 'ACTIVE',
  Negotiation = 'NEGOTIATION',
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Archived = 'ARCHIVED'
}

export type CollaborationTotals = {
  __typename?: 'CollaborationTotals';
  currency?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  contact?: Maybe<Contact>;
  totalPrice?: Maybe<Scalars['Float']>;
};

export type CollaborationTotalsResponse = {
  __typename?: 'CollaborationTotalsResponse';
  totals?: Maybe<Array<Maybe<CollaborationTotals>>>;
  collaborations?: Maybe<Array<Maybe<Collaboration>>>;
};

export type CollaborationUpdateResponse = {
  __typename?: 'CollaborationUpdateResponse';
  success?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  collaboration?: Maybe<Collaboration>;
};

export type CollaborationUpdates = {
  name?: Maybe<Scalars['String']>;
  status?: Maybe<CollaborationStatus>;
  brandId?: Maybe<Scalars['ObjectId']>;
  avatar?: Maybe<Scalars['Upload']>;
  hasShareWithTalent?: Maybe<Scalars['Boolean']>;
  fileUploads?: Maybe<Array<Maybe<FileInput>>>;
  profileTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  profileIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  notes?: Maybe<Scalars['String']>;
  ambassadorIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  collaborationStart?: Maybe<Scalars['Date']>;
  collaborationEnd?: Maybe<Scalars['Date']>;
  mentions?: Maybe<Array<Maybe<Scalars['String']>>>;
  hashtags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CollaborationUserRow = {
  __typename?: 'CollaborationUserRow';
  user?: Maybe<User>;
  deliverables?: Maybe<Array<Maybe<Deliverable>>>;
};

export type CompressedImageInput = {
  url?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
};

export type ConnectReponse = {
  __typename?: 'ConnectReponse';
  isKBUserExisted?: Maybe<Scalars['Boolean']>;
};

export type Contact = {
  __typename?: 'Contact';
  _id: Scalars['ObjectId'];
  type?: Maybe<ContactType>;
  parentId?: Maybe<Scalars['ObjectId']>;
  avatar?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
  socialHandle?: Maybe<Scalars['String']>;
  socialChannel?: Maybe<SocialChannel>;
  collaborations?: Maybe<Array<Collaboration>>;
  name?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  parent?: Maybe<Contact>;
  children?: Maybe<Array<Contact>>;
  activity?: Maybe<Array<ContactActivity>>;
};

export type ContactActivity = {
  __typename?: 'ContactActivity';
  itemId?: Maybe<Scalars['ObjectId']>;
  action?: Maybe<ContactActivityAction>;
  type?: Maybe<ContactActivityType>;
  timestamp?: Maybe<Scalars['Date']>;
  contact?: Maybe<Contact>;
  user?: Maybe<User>;
};

export enum ContactActivityAction {
  Sent = 'SENT',
  Opened = 'OPENED',
  Added = 'ADDED',
  SetUp = 'SET_UP'
}

export enum ContactActivityType {
  MediaKit = 'MEDIA_KIT',
  Report = 'REPORT',
  Deliverable = 'DELIVERABLE',
  Collaboration = 'COLLABORATION'
}

export enum ContactType {
  Company = 'COMPANY',
  Individual = 'INDIVIDUAL'
}

export type ContactUpdates = {
  type?: Maybe<ContactType>;
  parentId?: Maybe<Scalars['ObjectId']>;
  avatar?: Maybe<Scalars['Upload']>;
  companyName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  socialHandle?: Maybe<Scalars['String']>;
  socialChannel?: Maybe<SocialChannel>;
};

export type ContentfulPlatformAd = {
  __typename?: 'ContentfulPlatformAd';
  mediaUrl?: Maybe<Scalars['String']>;
  linkUrl?: Maybe<Scalars['String']>;
  altText?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  freeUserAd?: Maybe<Scalars['Boolean']>;
  type?: Maybe<AdType>;
};

export type ContentfulPlatformAdParams = {
  containerWidth?: Maybe<Scalars['Float']>;
  containerHeight?: Maybe<Scalars['Float']>;
  path?: Maybe<Scalars['String']>;
  type?: Maybe<AdType>;
};

export type CouponCode = {
  __typename?: 'CouponCode';
  _id: Scalars['ObjectId'];
  userId?: Maybe<Scalars['ObjectId']>;
  code?: Maybe<Scalars['String']>;
  discountId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  headerImage?: Maybe<Scalars['String']>;
  headerImageMobile?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['Date']>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
  stripePromotionCodeId?: Maybe<Scalars['String']>;
};

export type CouponCodeUpdates = {
  code?: Maybe<Scalars['String']>;
  discountId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  headerImage?: Maybe<Scalars['String']>;
  headerImageMobile?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['Date']>;
};

export enum DataAccessType {
  All = 'ALL',
  Demographics = 'DEMOGRAPHICS',
  Media = 'MEDIA'
}

export type Delegate = {
  __typename?: 'Delegate';
  _id: Scalars['ObjectId'];
  role?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  delegateEmail?: Maybe<Scalars['String']>;
  masterId?: Maybe<Scalars['ObjectId']>;
  userId?: Maybe<Scalars['ObjectId']>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
  /** @deprecated Use brandProfiles. */
  brand_profiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use 'companyName'. */
  agency?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  type?: Maybe<UserType>;
  user?: Maybe<User>;
  master?: Maybe<User>;
  brandProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DelegateCreationInput = {
  role?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  delegateEmail?: Maybe<Scalars['String']>;
  masterId?: Maybe<Scalars['ObjectId']>;
  userId?: Maybe<Scalars['ObjectId']>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
  brand_profiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  companyName?: Maybe<Scalars['String']>;
  type?: Maybe<UserType>;
};

export type DelegateLoginResult = {
  __typename?: 'DelegateLoginResult';
  type?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type DelegateRegistrationInput = {
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  admin_verification_token?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  detected_timezone?: Maybe<Scalars['String']>;
  marketingOptIn?: Maybe<Scalars['Boolean']>;
  type?: Maybe<UserType>;
  agency?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
};

export type DelegateRegistrationResult = {
  __typename?: 'DelegateRegistrationResult';
  success?: Maybe<Scalars['Boolean']>;
  user?: Maybe<User>;
  delegate?: Maybe<Delegate>;
  data?: Maybe<DelegateLoginResult>;
  message?: Maybe<Scalars['String']>;
};

export type DelegateUserUpdates = {
  delegateId: Scalars['ObjectId'];
  userId?: Maybe<Scalars['ObjectId']>;
  role?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type Deliverable = {
  __typename?: 'Deliverable';
  _id: Scalars['ObjectId'];
  collaborationId?: Maybe<Scalars['ObjectId']>;
  userId?: Maybe<Scalars['ObjectId']>;
  created_at?: Maybe<Scalars['Date']>;
  customizeSocialDeliverable?: Maybe<Scalars['String']>;
  deliverableName?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Float']>;
  endDate?: Maybe<Scalars['DateString']>;
  endTime?: Maybe<Scalars['TimeHours']>;
  notes?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  currency?: Maybe<UserCurrency>;
  profileIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  socialChannel?: Maybe<SocialChannel>;
  socialDeliverable?: Maybe<SocialDeliverable>;
  startDate?: Maybe<Scalars['DateString']>;
  startTime?: Maybe<Scalars['DateString']>;
  updated_at?: Maybe<Scalars['Date']>;
  trackingLinkIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  fileUploads?: Maybe<Array<Maybe<LinkPair>>>;
  ambassadorIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  valueAddedMediaIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  storyFrameCount?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
  profiles?: Maybe<Array<Profile>>;
  collaboration?: Maybe<Collaboration>;
  canEdit?: Maybe<Scalars['Boolean']>;
  trackingLinks?: Maybe<Array<TrackingLink>>;
  isViewed?: Maybe<Scalars['Boolean']>;
  media?: Maybe<DeliverableMedia>;
};

export type DeliverableMedia = {
  __typename?: 'DeliverableMedia';
  instagramMedia?: Maybe<Array<Maybe<InstagramMedia>>>;
  youtubeMedia?: Maybe<Array<Maybe<YoutubeMedia>>>;
  facebookPosts?: Maybe<Array<Maybe<FacebookPost>>>;
  tiktokMedia?: Maybe<Array<Maybe<TiktokMedia>>>;
};

export type DeliverableRateField = {
  __typename?: 'DeliverableRateField';
  channel?: Maybe<SocialChannel>;
  deliverable?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type DeliverableRateInput = {
  channel?: Maybe<SocialChannel>;
  deliverable?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type DeliverableRatesContent = {
  __typename?: 'DeliverableRatesContent';
  type?: Maybe<ElementType>;
  deliverable_rates_content?: Maybe<Array<Maybe<DeliverableRateField>>>;
};

export type DeliverableUpdates = {
  collaborationId?: Maybe<Scalars['ObjectId']>;
  customizeSocialDeliverable?: Maybe<Scalars['String']>;
  deliverableName?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Float']>;
  endDate?: Maybe<Scalars['DateString']>;
  endTime?: Maybe<Scalars['TimeHours']>;
  notes?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  currency?: Maybe<UserCurrency>;
  profileIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  socialChannel?: Maybe<SocialChannel>;
  socialDeliverable?: Maybe<SocialDeliverable>;
  trackingLinkIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  startDate?: Maybe<Scalars['DateString']>;
  startTime?: Maybe<Scalars['TimeHours']>;
  fileUploads?: Maybe<Array<Maybe<FileInput>>>;
  ambassadorIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  storyFrameCount?: Maybe<Scalars['Int']>;
};

export type DemographicFilter = {
  value?: Maybe<Scalars['String']>;
  minPercentage?: Maybe<Scalars['Float']>;
};

export type ElementContentType =
  | TextContent
  | RichTextContent
  | ImageContent
  | ImageListContent
  | GalleryContent
  | LinksContent
  | FilesContent
  | RatesContent
  | DeliverableRatesContent
  | LinkedAccountsContent
  | FeaturedInsightsContent
  | VideoContent
  | AudienceDemographicsContent
  | SelectedMediaContent;

export enum ElementTarget {
  Template = 'TEMPLATE',
  Share = 'SHARE'
}

export enum ElementType {
  Text = 'TEXT',
  RichText = 'RICH_TEXT',
  Image = 'IMAGE',
  ImageList = 'IMAGE_LIST',
  Video = 'VIDEO',
  Gallery = 'GALLERY',
  Links = 'LINKS',
  Rates = 'RATES',
  DeliverableRates = 'DELIVERABLE_RATES',
  Files = 'FILES',
  LinkedAccounts = 'LINKED_ACCOUNTS',
  FeaturedInsights = 'FEATURED_INSIGHTS',
  AudienceDemographics = 'AUDIENCE_DEMOGRAPHICS',
  SelectedMedia = 'SELECTED_MEDIA'
}

export type FacebookActionStats = {
  __typename?: 'FacebookActionStats';
  checkin?: Maybe<Scalars['Int']>;
  coupon?: Maybe<Scalars['Int']>;
  event?: Maybe<Scalars['Int']>;
  fan?: Maybe<Scalars['Int']>;
  mention?: Maybe<Scalars['Int']>;
  page_post?: Maybe<Scalars['Int']>;
  question?: Maybe<Scalars['Int']>;
  user_post?: Maybe<Scalars['Int']>;
  other?: Maybe<Scalars['Int']>;
};

export type FacebookFanSource = {
  __typename?: 'FacebookFanSource';
  ads?: Maybe<FacebookFanSourceStats>;
  feed?: Maybe<FacebookFanSourceStats>;
  page_suggestions?: Maybe<FacebookFanSourceStats>;
  deactivated_accounts?: Maybe<FacebookFanSourceStats>;
  search?: Maybe<FacebookFanSourceStats>;
  other?: Maybe<FacebookFanSourceStats>;
};

export type FacebookFanSourceStats = {
  __typename?: 'FacebookFanSourceStats';
  adds?: Maybe<Scalars['Int']>;
  removes?: Maybe<Scalars['Int']>;
  netChange?: Maybe<Scalars['Int']>;
};

export type FacebookMarketingInsights = {
  __typename?: 'FacebookMarketingInsights';
  reach?: Maybe<Scalars['Float']>;
  impressions?: Maybe<Scalars['Float']>;
};

export type FacebookMediaAverages = {
  __typename?: 'FacebookMediaAverages';
  date?: Maybe<Scalars['Date']>;
  insights?: Maybe<FacebookPostAverages>;
};

export type FacebookMediaTrends = {
  __typename?: 'FacebookMediaTrends';
  dailyAverages?: Maybe<Array<Maybe<FacebookMediaAverages>>>;
  recentAverages?: Maybe<FacebookMediaAverages>;
  comparisonAverages?: Maybe<FacebookMediaAverages>;
};

export type FacebookPageCategory = {
  __typename?: 'FacebookPageCategory';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type FacebookPageEngagement = {
  __typename?: 'FacebookPageEngagement';
  count?: Maybe<Scalars['Int']>;
  social_sentence?: Maybe<Scalars['String']>;
};

export type FacebookPageInfo = {
  __typename?: 'FacebookPageInfo';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  picture?: Maybe<FacebookProfilePicture>;
  instagram_business_account?: Maybe<InstagramAccount>;
};

export type FacebookPageStats = {
  __typename?: 'FacebookPageStats';
  _id?: Maybe<Scalars['ObjectId']>;
  date?: Maybe<Scalars['Date']>;
  /** @deprecated Use profileId instead */
  pageId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  change?: Maybe<FacebookPageStats>;
  postStats?: Maybe<FacebookPostAverages>;
  page_impressions?: Maybe<Scalars['Int']>;
  page_post_engagements?: Maybe<Scalars['Int']>;
  page_total_actions?: Maybe<Scalars['Int']>;
  page_video_views?: Maybe<Scalars['Int']>;
  page_actions_post_reactionsTotal?: Maybe<Scalars['Int']>;
  page_actions_post_reactions_anger_total?: Maybe<Scalars['Int']>;
  page_actions_post_reactions_haha_total?: Maybe<Scalars['Int']>;
  page_actions_post_reactions_like_total?: Maybe<Scalars['Int']>;
  page_actions_post_reactions_love_total?: Maybe<Scalars['Int']>;
  page_actions_post_reactions_sorry_total?: Maybe<Scalars['Int']>;
  page_actions_post_reactions_wow_total?: Maybe<Scalars['Int']>;
  page_positive_feedbackTotal?: Maybe<Scalars['Int']>;
  page_negative_feedback_by_type?: Maybe<Page_Negative_Feedback_By_Type>;
  page_negative_feedbackTotal?: Maybe<Scalars['Int']>;
  page_positive_feedback_by_type?: Maybe<Page_Positive_Feedback_By_Type>;
  page_call_phone_clicks_logged_in_unique?: Maybe<Scalars['Int']>;
  page_content_activity?: Maybe<Scalars['Int']>;
  page_content_activity_by_action_type_unique?: Maybe<FacebookActionStats>;
  page_get_directions_clicks_logged_in_unique?: Maybe<Scalars['Int']>;
  page_places_checkin_mobile?: Maybe<Scalars['Int']>;
  page_posts_impressions?: Maybe<Scalars['Int']>;
  page_views_by_profile_tab_total?: Maybe<FacebookTabStats>;
  page_views_total?: Maybe<Scalars['Int']>;
  page_website_clicks_logged_in_unique?: Maybe<Scalars['Int']>;
  post_activity_unique?: Maybe<Scalars['Int']>;
  post_impressions_unique?: Maybe<Scalars['Int']>;
  page_fans?: Maybe<Scalars['Int']>;
  page_fans_locale?: Maybe<Scalars['JSObject']>;
  page_fans_city?: Maybe<Scalars['JSObject']>;
  page_fans_country?: Maybe<Scalars['JSObject']>;
  page_fans_gender?: Maybe<AudienceGender>;
  page_fans_age?: Maybe<Scalars['JSObject']>;
  page_fan_adds?: Maybe<Scalars['Int']>;
  page_fan_adds_unique?: Maybe<Scalars['Int']>;
  page_fans_by_like_source?: Maybe<Scalars['JSObject']>;
  page_fans_by_like_source_unique?: Maybe<Scalars['JSObject']>;
  page_fan_removes?: Maybe<Scalars['Int']>;
  page_fan_removes_unique?: Maybe<Scalars['Int']>;
  page_fans_by_unlike_source_unique?: Maybe<Scalars['JSObject']>;
  page_content_activity_by_gender_unique?: Maybe<AudienceGender>;
  page_content_activity_by_age_unique?: Maybe<Scalars['JSObject']>;
  page_content_activity_by_city_unique?: Maybe<Scalars['JSObject']>;
  page_content_activity_by_country_unique?: Maybe<Scalars['JSObject']>;
  page_impressions_by_gender_unique?: Maybe<AudienceGender>;
  page_impressions_by_age_unique?: Maybe<Scalars['JSObject']>;
  page_impressions_by_city_unique?: Maybe<Scalars['JSObject']>;
  page_impressions_by_country_unique?: Maybe<Scalars['JSObject']>;
  page_cta_clicks_by_gender_logged_in_unique?: Maybe<AudienceGender>;
  page_cta_clicks_by_age_logged_in_unique?: Maybe<Scalars['JSObject']>;
  page_cta_clicks_logged_in_by_city_unique?: Maybe<Scalars['JSObject']>;
  page_cta_clicks_by_country_logged_in_unique?: Maybe<Scalars['JSObject']>;
  page_call_phone_clicks_by_gender_logged_in_unique?: Maybe<AudienceGender>;
  page_call_phone_clicks_by_age_logged_in_unique?: Maybe<Scalars['JSObject']>;
  page_call_phone_clicks_logged_in_by_city_unique?: Maybe<Scalars['JSObject']>;
  page_call_phone_clicks_by_country_logged_in_unique?: Maybe<Scalars['JSObject']>;
  page_get_directions_clicks_by_gender_logged_in_unique?: Maybe<AudienceGender>;
  page_get_directions_clicks_by_age_logged_in_unique?: Maybe<Scalars['JSObject']>;
  page_get_directions_clicks_logged_in_by_city_unique?: Maybe<Scalars['JSObject']>;
  page_get_directions_clicks_by_country_logged_in_unique?: Maybe<Scalars['JSObject']>;
  page_website_clicks_by_gender_logged_in_unique?: Maybe<AudienceGender>;
  page_website_clicks_by_age_logged_in_unique?: Maybe<Scalars['JSObject']>;
  page_website_clicks_logged_in_by_city_unique?: Maybe<Scalars['JSObject']>;
  page_website_clicks_by_country_logged_in_unique?: Maybe<Scalars['JSObject']>;
  page_places_checkins_by_gender?: Maybe<AudienceGender>;
  page_places_checkins_by_age?: Maybe<Scalars['JSObject']>;
  page_places_checkins_by_locale?: Maybe<Scalars['JSObject']>;
  page_places_checkins_by_country?: Maybe<Scalars['JSObject']>;
  page_fans_netChange?: Maybe<Scalars['Int']>;
  page_fans_bySource?: Maybe<FacebookFanSource>;
  page_tab_clicksTotal?: Maybe<Scalars['Int']>;
  topGender?: Maybe<TopDemographic>;
  topCity?: Maybe<TopDemographic>;
};

export type FacebookPost = {
  __typename?: 'FacebookPost';
  _id: Scalars['ObjectId'];
  /** @deprecated Use profileId instead */
  pageId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  created_time?: Maybe<Scalars['Date']>;
  full_picture?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  instagram_eligibility?: Maybe<Scalars['String']>;
  is_eligible_for_promotion?: Maybe<Scalars['Boolean']>;
  is_expired?: Maybe<Scalars['Boolean']>;
  is_hidden?: Maybe<Scalars['Boolean']>;
  is_instagram_eligible?: Maybe<Scalars['Boolean']>;
  is_popular?: Maybe<Scalars['Boolean']>;
  is_published?: Maybe<Scalars['Boolean']>;
  is_spherical?: Maybe<Scalars['Boolean']>;
  permalink_url?: Maybe<Scalars['String']>;
  privacy?: Maybe<FacebookPrivacy>;
  promotable_id?: Maybe<Scalars['String']>;
  status_type?: Maybe<Scalars['String']>;
  story?: Maybe<Scalars['String']>;
  story_tags?: Maybe<Array<Maybe<FacebookTag>>>;
  message_tags?: Maybe<Array<Maybe<FacebookTag>>>;
  subscribed?: Maybe<Scalars['Boolean']>;
  updated_time?: Maybe<Scalars['Date']>;
  video_buying_eligibility?: Maybe<Array<Maybe<Scalars['String']>>>;
  insights?: Maybe<FacebookPostInsights>;
  marketingInsights?: Maybe<FacebookMarketingInsights>;
  message?: Maybe<Scalars['String']>;
  handles?: Maybe<Array<Maybe<Scalars['String']>>>;
  hashes?: Maybe<Array<Maybe<Scalars['String']>>>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
  timestamp?: Maybe<Scalars['Date']>;
  profile?: Maybe<Profile>;
  history?: Maybe<Array<Maybe<FacebookPostHistorical>>>;
};

export type FacebookPostAverages = {
  __typename?: 'FacebookPostAverages';
  post_reactionsTotal?: Maybe<Scalars['Float']>;
  post_clicks?: Maybe<Scalars['Float']>;
  post_activity?: Maybe<Scalars['Float']>;
  post_activity_percentageOfReach?: Maybe<Scalars['Float']>;
  post_engaged_users?: Maybe<Scalars['Float']>;
  post_engaged_users_percentageOfReach?: Maybe<Scalars['Float']>;
  post_impressions?: Maybe<Scalars['Float']>;
  post_impressions_unique?: Maybe<Scalars['Float']>;
  post_reactions_like_total?: Maybe<Scalars['Float']>;
};

export type FacebookPostHistorical = {
  __typename?: 'FacebookPostHistorical';
  id?: Maybe<Scalars['String']>;
  pageId?: Maybe<Scalars['String']>;
  insights?: Maybe<FacebookPostInsights>;
  foundDate?: Maybe<Scalars['Date']>;
};

export type FacebookPostInsights = {
  __typename?: 'FacebookPostInsights';
  post_reactionsTotal?: Maybe<Scalars['Int']>;
  post_engaged_users?: Maybe<Scalars['Int']>;
  post_engaged_fan?: Maybe<Scalars['Int']>;
  post_clicks?: Maybe<Scalars['Int']>;
  post_clicks_unique?: Maybe<Scalars['Int']>;
  post_impressions?: Maybe<Scalars['Int']>;
  post_impressions_unique?: Maybe<Scalars['Int']>;
  post_impressions_fan?: Maybe<Scalars['Int']>;
  post_impressions_fan_unique?: Maybe<Scalars['Int']>;
  post_impressions_organic?: Maybe<Scalars['Int']>;
  post_impressions_organic_unique?: Maybe<Scalars['Int']>;
  post_impressions_nonviral?: Maybe<Scalars['Int']>;
  post_impressions_nonviral_unique?: Maybe<Scalars['Int']>;
  post_reactions_like_total?: Maybe<Scalars['Int']>;
  post_activity?: Maybe<Scalars['Int']>;
  post_activity_unique?: Maybe<Scalars['Int']>;
  post_clicks_by_type?: Maybe<Scalars['JSObject']>;
  post_clicks_by_type_unique?: Maybe<Scalars['JSObject']>;
  post_reactions_by_type_total?: Maybe<Scalars['JSObject']>;
  post_activity_by_action_type?: Maybe<Scalars['JSObject']>;
  post_activity_by_action_type_unique?: Maybe<Scalars['JSObject']>;
};

export type FacebookPostStats = {
  __typename?: 'FacebookPostStats';
  count?: Maybe<Scalars['Int']>;
  days?: Maybe<Scalars['Int']>;
  postsPerDay?: Maybe<Scalars['Int']>;
  post_activity?: Maybe<MetricSummary>;
  post_activity_percentageOfReach?: Maybe<MetricSummary>;
  post_activity_unique?: Maybe<MetricSummary>;
  post_engaged_users?: Maybe<MetricSummary>;
  post_engaged_users_percentageOfReach?: Maybe<MetricSummary>;
  post_impressions?: Maybe<MetricSummary>;
  post_impressions_unique?: Maybe<MetricSummary>;
  post_reactions_like_total?: Maybe<MetricSummary>;
  topHandles?: Maybe<Array<Maybe<Scalars['String']>>>;
  topHashtags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FacebookPrivacy = {
  __typename?: 'FacebookPrivacy';
  allow?: Maybe<Scalars['String']>;
  deny?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  friends?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type FacebookProfile = {
  __typename?: 'FacebookProfile';
  _id: Scalars['ObjectId'];
  isAddedByOwner?: Maybe<Scalars['Boolean']>;
  agentId?: Maybe<Scalars['String']>;
  biography?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** @deprecated Use cacheError */
  cache_error?: Maybe<Scalars['String']>;
  cache_completed_at?: Maybe<Scalars['Date']>;
  created_at?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  historical_from?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  pageId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  media_cached_at?: Maybe<Scalars['Date']>;
  profileCategory?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use 'profileCategory'. */
  profile_category?: Maybe<Array<Maybe<Scalars['String']>>>;
  profileType?: Maybe<SocialChannel>;
  /** @deprecated Use 'profileType'. */
  profile_type?: Maybe<Scalars['String']>;
  profile_cached_at?: Maybe<Scalars['Date']>;
  profile_media_kit?: Maybe<MediaKit>;
  profile_picture_url?: Maybe<Scalars['String']>;
  redflags_from?: Maybe<Scalars['Date']>;
  redflags_until?: Maybe<Scalars['Date']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['Date']>;
  userId?: Maybe<Scalars['String']>;
  isRestricted?: Maybe<Scalars['Boolean']>;
  isPendingConnection?: Maybe<Scalars['Boolean']>;
  hasAcceptedInvite?: Maybe<Scalars['Boolean']>;
  doesRequireTrackApproval?: Maybe<Scalars['Boolean']>;
  selectedMediaKitId?: Maybe<Scalars['ObjectId']>;
  profileAuditReportLink?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  media_count?: Maybe<Scalars['Float']>;
  followers_count?: Maybe<Scalars['Int']>;
  channelStats?: Maybe<ChannelStats>;
  fan_count?: Maybe<Scalars['Int']>;
  published_post_count?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['String']>;
  union_id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  profile_cache_attempts?: Maybe<Scalars['Int']>;
  media_cache_attempts?: Maybe<Scalars['Int']>;
  last_cache_attempts?: Maybe<Scalars['Int']>;
  cache_error_at?: Maybe<Scalars['Date']>;
  cache_error_resolved_at?: Maybe<Scalars['Date']>;
  last_cache_error?: Maybe<Scalars['String']>;
  user_notified_at?: Maybe<Scalars['Date']>;
  agency_notified_at?: Maybe<Scalars['Date']>;
  historical_until?: Maybe<Scalars['Date']>;
  assignedToAmbassadorId?: Maybe<Scalars['ObjectId']>;
  profile_added_at?: Maybe<Scalars['Date']>;
  ownerNotes?: Maybe<Scalars['String']>;
  ownerTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  isInstagramIncomingMentionsEnabled?: Maybe<Scalars['Boolean']>;
  isInstagramFeedWebhookEnabled?: Maybe<Scalars['Boolean']>;
  pageStats?: Maybe<FacebookPageStats>;
  postStats?: Maybe<FacebookPostStats>;
  historicalFacebookStats?: Maybe<Array<Maybe<FacebookPageStats>>>;
  weeklyFacebookStats?: Maybe<Array<FacebookWeeklyStats>>;
  rankedFacebookMedia?: Maybe<RankedFacebookMedia>;
  latestFacebookPosts?: Maybe<Array<FacebookPost>>;
  facebookPostTrends?: Maybe<FacebookMediaTrends>;
  recentFacebookMediaStats?: Maybe<RecentMediaStats>;
  lifetimeInsights?: Maybe<LifetimeInsights>;
  historicalStats?: Maybe<InstagramHistoricalStats>;
  onlineFollowers?: Maybe<Scalars['JSObject']>;
  /** @deprecated Use rankedInstagramMedia.topMedia instead */
  rankedInstagramPosts?: Maybe<Array<InstagramMedia>>;
  rankedInstagramMedia?: Maybe<RankedInstagramMedia>;
  rankedInstagramFeed?: Maybe<RankedInstagramMedia>;
  rankedInstagramReels?: Maybe<RankedInstagramMedia>;
  latestInstagramPosts?: Maybe<Array<InstagramMedia>>;
  latestInstagramFeed?: Maybe<Array<InstagramMedia>>;
  latestInstagramReels?: Maybe<Array<InstagramMedia>>;
  latestInstagramStories?: Maybe<Array<InstagramStory>>;
  dailyInstagramStats?: Maybe<Array<InstagramHistorical>>;
  weeklyInstagramStats?: Maybe<Array<InstagramHistorical>>;
  mediaStats?: Maybe<MediaStats>;
  instagramFeedStats?: Maybe<MediaStats>;
  instagramReelStats?: Maybe<MediaStats>;
  storyStats?: Maybe<MediaStats>;
  instagramFeedTrends?: Maybe<InstagramMediaTrends>;
  instagramReelTrends?: Maybe<InstagramMediaTrends>;
  instagramStoryTrends?: Maybe<InstagramStoryTrends>;
  instagramCombinedTrends?: Maybe<InstagramCombinedTrends>;
  followerBracket?: Maybe<Scalars['Int']>;
  latestPerformanceReport?: Maybe<PerformanceReport>;
  instagramMediaMentions?: Maybe<Array<InstagramMedia>>;
  recentInstagramMediaStats?: Maybe<RecentInstagramMediaStats>;
  subscribedWebhookFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  cacheError?: Maybe<Scalars['String']>;
  userFriendlyCacheError?: Maybe<UserFriendlyCacheError>;
  user?: Maybe<User>;
  agent?: Maybe<User>;
  cacheSeconds?: Maybe<Scalars['Int']>;
  mediaKitConfigs?: Maybe<Array<Maybe<MediaKitConfig>>>;
  ownerMediaKitConfigs?: Maybe<Array<MediaKitConfig>>;
  profileNote?: Maybe<ProfileNote>;
  dataAccessToken?: Maybe<Scalars['String']>;
  followerCount?: Maybe<Scalars['Int']>;
  selectedMediaKit?: Maybe<MediaKitConfig>;
  isVisibleOnNetwork?: Maybe<Scalars['Boolean']>;
  mediaKits?: Maybe<Array<MediaKitConfig>>;
  thumbnails?: Maybe<Thumbnails>;
  title?: Maybe<Scalars['String']>;
  videoStats?: Maybe<YoutubeMediaStats>;
  snippet?: Maybe<Scalars['String']>;
  ageGroupBreakdown?: Maybe<Array<AgeGroup>>;
  genderBreakdown?: Maybe<Array<GenderGroup>>;
  locationBreakdown?: Maybe<Array<LocationGroup>>;
  myChannelReport?: Maybe<Array<ChannelReport>>;
  subscriberChange?: Maybe<Array<SubscriberChange>>;
  youtubeMedia?: Maybe<Array<YoutubeMedia>>;
  youtubeHistorical?: Maybe<Array<YoutubeHistorical>>;
  youtubeWeeklyStats?: Maybe<Array<YoutubeHistoricalStats>>;
  rankedYoutubeMedia?: Maybe<RankedYoutubeMedia>;
  youtubeVideoTrends?: Maybe<YoutubeMediaTrends>;
  recentYoutubeMediaStats?: Maybe<RecentMediaStats>;
  public?: Maybe<TiktokPublicUser>;
  tiktokMediaStats?: Maybe<TiktokMediaStats>;
  tiktokMediaTrends?: Maybe<TiktokMediaTrends>;
  rankedTiktokMedia?: Maybe<TikTokRankedMedia>;
  recentTiktokMediaStats?: Maybe<RecentMediaStats>;
  picture?: Maybe<FacebookProfilePicture>;
  about?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  category_list?: Maybe<Array<Maybe<FacebookPageCategory>>>;
  engagement?: Maybe<FacebookPageEngagement>;
  website?: Maybe<Scalars['String']>;
};

export type FacebookProfileWeeklyFacebookStatsArgs = {
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type FacebookProfileWeeklyInstagramStatsArgs = {
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type FacebookProfileYoutubeWeeklyStatsArgs = {
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type FacebookProfileInput = {
  pageId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type FacebookProfilePicture = {
  __typename?: 'FacebookProfilePicture';
  data?: Maybe<FacebookProfilePictureData>;
};

export type FacebookProfilePictureData = {
  __typename?: 'FacebookProfilePictureData';
  url?: Maybe<Scalars['String']>;
};

export type FacebookTabStats = {
  __typename?: 'FacebookTabStats';
  HOME?: Maybe<Scalars['Int']>;
};

export type FacebookTag = {
  __typename?: 'FacebookTag';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  length?: Maybe<Scalars['Int']>;
};

export type FacebookUser = {
  __typename?: 'FacebookUser';
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
};

export type FacebookWeeklyStats = {
  __typename?: 'FacebookWeeklyStats';
  _id?: Maybe<Scalars['ObjectId']>;
  date?: Maybe<Scalars['Date']>;
  /** @deprecated Use profileId instead */
  pageId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  change?: Maybe<FacebookPageStats>;
  postStats?: Maybe<FacebookPostAverages>;
  page_impressions?: Maybe<Scalars['Int']>;
  page_post_engagements?: Maybe<Scalars['Int']>;
  page_total_actions?: Maybe<Scalars['Int']>;
  page_video_views?: Maybe<Scalars['Int']>;
  page_actions_post_reactionsTotal?: Maybe<Scalars['Int']>;
  page_actions_post_reactions_anger_total?: Maybe<Scalars['Int']>;
  page_actions_post_reactions_haha_total?: Maybe<Scalars['Int']>;
  page_actions_post_reactions_like_total?: Maybe<Scalars['Int']>;
  page_actions_post_reactions_love_total?: Maybe<Scalars['Int']>;
  page_actions_post_reactions_sorry_total?: Maybe<Scalars['Int']>;
  page_actions_post_reactions_wow_total?: Maybe<Scalars['Int']>;
  page_positive_feedbackTotal?: Maybe<Scalars['Int']>;
  page_negative_feedback_by_type?: Maybe<Page_Negative_Feedback_By_Type>;
  page_negative_feedbackTotal?: Maybe<Scalars['Int']>;
  page_positive_feedback_by_type?: Maybe<Page_Positive_Feedback_By_Type>;
  page_call_phone_clicks_logged_in_unique?: Maybe<Scalars['Int']>;
  page_content_activity?: Maybe<Scalars['Int']>;
  page_content_activity_by_action_type_unique?: Maybe<FacebookActionStats>;
  page_get_directions_clicks_logged_in_unique?: Maybe<Scalars['Int']>;
  page_places_checkin_mobile?: Maybe<Scalars['Int']>;
  page_posts_impressions?: Maybe<Scalars['Int']>;
  page_views_by_profile_tab_total?: Maybe<FacebookTabStats>;
  page_views_total?: Maybe<Scalars['Int']>;
  page_website_clicks_logged_in_unique?: Maybe<Scalars['Int']>;
  post_activity_unique?: Maybe<Scalars['Int']>;
  post_impressions_unique?: Maybe<Scalars['Int']>;
  page_fans?: Maybe<Scalars['Int']>;
  page_fans_locale?: Maybe<Scalars['JSObject']>;
  page_fans_city?: Maybe<Scalars['JSObject']>;
  page_fans_country?: Maybe<Scalars['JSObject']>;
  page_fans_gender?: Maybe<AudienceGender>;
  page_fans_age?: Maybe<Scalars['JSObject']>;
  page_fan_adds?: Maybe<Scalars['Int']>;
  page_fan_adds_unique?: Maybe<Scalars['Int']>;
  page_fans_by_like_source?: Maybe<Scalars['JSObject']>;
  page_fans_by_like_source_unique?: Maybe<Scalars['JSObject']>;
  page_fan_removes?: Maybe<Scalars['Int']>;
  page_fan_removes_unique?: Maybe<Scalars['Int']>;
  page_fans_by_unlike_source_unique?: Maybe<Scalars['JSObject']>;
  page_content_activity_by_gender_unique?: Maybe<AudienceGender>;
  page_content_activity_by_age_unique?: Maybe<Scalars['JSObject']>;
  page_content_activity_by_city_unique?: Maybe<Scalars['JSObject']>;
  page_content_activity_by_country_unique?: Maybe<Scalars['JSObject']>;
  page_impressions_by_gender_unique?: Maybe<AudienceGender>;
  page_impressions_by_age_unique?: Maybe<Scalars['JSObject']>;
  page_impressions_by_city_unique?: Maybe<Scalars['JSObject']>;
  page_impressions_by_country_unique?: Maybe<Scalars['JSObject']>;
  page_cta_clicks_by_gender_logged_in_unique?: Maybe<AudienceGender>;
  page_cta_clicks_by_age_logged_in_unique?: Maybe<Scalars['JSObject']>;
  page_cta_clicks_logged_in_by_city_unique?: Maybe<Scalars['JSObject']>;
  page_cta_clicks_by_country_logged_in_unique?: Maybe<Scalars['JSObject']>;
  page_call_phone_clicks_by_gender_logged_in_unique?: Maybe<AudienceGender>;
  page_call_phone_clicks_by_age_logged_in_unique?: Maybe<Scalars['JSObject']>;
  page_call_phone_clicks_logged_in_by_city_unique?: Maybe<Scalars['JSObject']>;
  page_call_phone_clicks_by_country_logged_in_unique?: Maybe<Scalars['JSObject']>;
  page_get_directions_clicks_by_gender_logged_in_unique?: Maybe<AudienceGender>;
  page_get_directions_clicks_by_age_logged_in_unique?: Maybe<Scalars['JSObject']>;
  page_get_directions_clicks_logged_in_by_city_unique?: Maybe<Scalars['JSObject']>;
  page_get_directions_clicks_by_country_logged_in_unique?: Maybe<Scalars['JSObject']>;
  page_website_clicks_by_gender_logged_in_unique?: Maybe<AudienceGender>;
  page_website_clicks_by_age_logged_in_unique?: Maybe<Scalars['JSObject']>;
  page_website_clicks_logged_in_by_city_unique?: Maybe<Scalars['JSObject']>;
  page_website_clicks_by_country_logged_in_unique?: Maybe<Scalars['JSObject']>;
  page_places_checkins_by_gender?: Maybe<AudienceGender>;
  page_places_checkins_by_age?: Maybe<Scalars['JSObject']>;
  page_places_checkins_by_locale?: Maybe<Scalars['JSObject']>;
  page_places_checkins_by_country?: Maybe<Scalars['JSObject']>;
  page_fans_netChange?: Maybe<Scalars['Int']>;
  page_fans_bySource?: Maybe<FacebookFanSource>;
  page_tab_clicksTotal?: Maybe<Scalars['Int']>;
  topGender?: Maybe<TopDemographic>;
  topCity?: Maybe<TopDemographic>;
  order?: Maybe<Scalars['Int']>;
  latestRecordedDate?: Maybe<Scalars['Date']>;
  dayDirectory?: Maybe<Scalars['JSObject']>;
  days?: Maybe<Array<Maybe<FacebookPageStats>>>;
};

export type FeatureUsage = {
  __typename?: 'FeatureUsage';
  count?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type FeaturedInsightField = {
  __typename?: 'FeaturedInsightField';
  key?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
};

export type FeaturedInsightInput = {
  key?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
};

export type FeaturedInsightsContent = {
  __typename?: 'FeaturedInsightsContent';
  type?: Maybe<ElementType>;
  featured_insights_content?: Maybe<Array<Maybe<FeaturedInsightField>>>;
};

export type FileInput = {
  title?: Maybe<Scalars['String']>;
  fileSize?: Maybe<Scalars['Int']>;
  file?: Maybe<Scalars['Upload']>;
  link?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['Upload']>;
  iconUrl?: Maybe<Scalars['String']>;
};

export type FilesContent = {
  __typename?: 'FilesContent';
  type?: Maybe<ElementType>;
  file_content?: Maybe<Array<Maybe<LinkPair>>>;
};

export type FloatRange = {
  min?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
};

export type GalleryContent = {
  __typename?: 'GalleryContent';
  type?: Maybe<ElementType>;
  gallery_content?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type GalleryInput = {
  link?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['Upload']>;
};

export enum Gender {
  Male = 'MALE',
  Female = 'FEMALE',
  Unspecified = 'UNSPECIFIED'
}

export type GenderGroup = {
  __typename?: 'GenderGroup';
  gender?: Maybe<Gender>;
  viewerPercentage?: Maybe<Scalars['String']>;
};

export type ImageContent = {
  __typename?: 'ImageContent';
  type?: Maybe<ElementType>;
  image_content?: Maybe<Scalars['String']>;
};

export type ImageListContent = {
  __typename?: 'ImageListContent';
  type?: Maybe<ElementType>;
  image_list_content?: Maybe<Array<Maybe<ImageListField>>>;
};

export type ImageListField = {
  __typename?: 'ImageListField';
  url?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
  compressedImageFile?: Maybe<ImageListField>;
};

export type ImageListInput = {
  url?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  file?: Maybe<Scalars['Upload']>;
  type?: Maybe<Scalars['String']>;
  compressedFile?: Maybe<Scalars['Upload']>;
  compressedImageFile?: Maybe<CompressedImageInput>;
};

export type InfluencerPlan = {
  __typename?: 'InfluencerPlan';
  _id: Scalars['ObjectId'];
  code?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['Date']>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
};

export type InfluencerPlanUpdates = {
  code?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['Date']>;
};

export type InfluencerQuery = {
  searchBar?: Maybe<Array<Maybe<Scalars['String']>>>;
  category?: Maybe<Array<Maybe<Scalars['String']>>>;
  profile_type?: Maybe<Scalars['String']>;
  true_reach?: Maybe<Scalars['Float']>;
  top_country?: Maybe<Scalars['String']>;
  top_age?: Maybe<Scalars['String']>;
  top_gender?: Maybe<Scalars['String']>;
  engagement?: Maybe<Scalars['Float']>;
  agent?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
};

export type InstagramAccount = {
  __typename?: 'InstagramAccount';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  profile_picture_url?: Maybe<Scalars['String']>;
  followers_count?: Maybe<Scalars['Int']>;
  followersCount?: Maybe<Scalars['Int']>;
};

export type InstagramAccountInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  profile_picture_url?: Maybe<Scalars['String']>;
  followers_count?: Maybe<Scalars['Int']>;
};

export type InstagramChildMedia = {
  __typename?: 'InstagramChildMedia';
  id?: Maybe<Scalars['String']>;
  media_url?: Maybe<Scalars['String']>;
  media_type?: Maybe<InstagramMediaType>;
  username?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Date']>;
};

export type InstagramCombinedAverages = {
  __typename?: 'InstagramCombinedAverages';
  date?: Maybe<Scalars['Date']>;
  like_count?: Maybe<Scalars['Float']>;
  comments_count?: Maybe<Scalars['Float']>;
  insights?: Maybe<InstagramCombinedInsightAverages>;
};

export type InstagramCombinedInsightAverages = {
  __typename?: 'InstagramCombinedInsightAverages';
  impressions?: Maybe<Scalars['Float']>;
  reach?: Maybe<Scalars['Float']>;
  engagement?: Maybe<Scalars['Float']>;
  engagement_percentageOfReach?: Maybe<Scalars['Float']>;
  saved?: Maybe<Scalars['Float']>;
  video_views?: Maybe<Scalars['Float']>;
  exits?: Maybe<Scalars['Float']>;
  replies?: Maybe<Scalars['Float']>;
  taps_forward?: Maybe<Scalars['Float']>;
  taps_back?: Maybe<Scalars['Float']>;
  viewRate?: Maybe<Scalars['Float']>;
  comments?: Maybe<Scalars['Float']>;
  likes?: Maybe<Scalars['Float']>;
  plays?: Maybe<Scalars['Float']>;
  shares?: Maybe<Scalars['Float']>;
  total_interactions?: Maybe<Scalars['Float']>;
  total_interactions_percentageOfReach?: Maybe<Scalars['Float']>;
};

export type InstagramCombinedTrends = {
  __typename?: 'InstagramCombinedTrends';
  recentAverages?: Maybe<InstagramCombinedAverages>;
  comparisonAverages?: Maybe<InstagramCombinedAverages>;
};

export type InstagramHistorical = {
  __typename?: 'InstagramHistorical';
  _id?: Maybe<Scalars['ObjectId']>;
  start?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  profileId?: Maybe<Scalars['String']>;
  followers_count?: Maybe<Scalars['Int']>;
  follower_count?: Maybe<Scalars['Int']>;
  followers_lost?: Maybe<Scalars['Int']>;
  followers_net?: Maybe<Scalars['Int']>;
  follows_count?: Maybe<Scalars['Int']>;
  profile_views?: Maybe<Scalars['Int']>;
  email_contacts?: Maybe<Scalars['Int']>;
  profileRequestCount?: Maybe<Scalars['Int']>;
  get_directions_clicks?: Maybe<Scalars['Int']>;
  phone_call_clicks?: Maybe<Scalars['Int']>;
  text_message_clicks?: Maybe<Scalars['Int']>;
  website_clicks?: Maybe<Scalars['Int']>;
  reach?: Maybe<Scalars['Int']>;
  impressions?: Maybe<Scalars['Int']>;
  change?: Maybe<InstagramHistorical>;
  storyStats?: Maybe<InstagramStoryInsightAverages>;
  /** @deprecated Use instagramFeedStats and instagramReelStats */
  mediaStats?: Maybe<InstagramPostInsightAverages>;
  instagramFeedStats?: Maybe<InstagramPostInsightAverages>;
  instagramReelStats?: Maybe<InstagramPostInsightAverages>;
  lifetimeInsights?: Maybe<LifetimeInsights>;
  ig_id?: Maybe<Scalars['String']>;
  media_count?: Maybe<Scalars['Int']>;
};

export type InstagramHistoricalStats = {
  __typename?: 'InstagramHistoricalStats';
  start?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  latestRecordedDate?: Maybe<Scalars['Date']>;
  dayDirectory?: Maybe<Scalars['JSObject']>;
  count?: Maybe<Scalars['Int']>;
  follower_count?: Maybe<MetricSummary>;
  followers_count?: Maybe<MetricSummary>;
  follows_count?: Maybe<MetricSummary>;
  media_count?: Maybe<MetricSummary>;
  profileRequestCount?: Maybe<MetricSummary>;
  email_contacts?: Maybe<MetricSummary>;
  profile_views?: Maybe<MetricSummary>;
  get_directions_clicks?: Maybe<MetricSummary>;
  phone_call_clicks?: Maybe<MetricSummary>;
  text_message_clicks?: Maybe<MetricSummary>;
  website_clicks?: Maybe<MetricSummary>;
  reach?: Maybe<MetricSummary>;
  impressions?: Maybe<MetricSummary>;
  storyStats?: Maybe<InstagramStoryInsightAverages>;
  /** @deprecated Use instagramFeedStats and instagramReelStats */
  mediaStats?: Maybe<InstagramPostInsightAverages>;
  instagramFeedStats?: Maybe<InstagramPostInsightAverages>;
  instagramReelStats?: Maybe<InstagramPostInsightAverages>;
  lifetimeInsights?: Maybe<LifetimeInsights>;
};

export type InstagramMarketingInsights = {
  __typename?: 'InstagramMarketingInsights';
  reach?: Maybe<Scalars['Float']>;
  impressions?: Maybe<Scalars['Float']>;
};

export type InstagramMedia = {
  __typename?: 'InstagramMedia';
  _id: Scalars['ObjectId'];
  id: Scalars['ID'];
  profileId?: Maybe<Scalars['String']>;
  shortcode?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  media_type?: Maybe<InstagramMediaType>;
  media_url?: Maybe<Scalars['String']>;
  thumbnail_url?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Date']>;
  webhook_timestamp?: Maybe<Scalars['Date']>;
  insights?: Maybe<InstagramPostInsights>;
  marketingInsights?: Maybe<InstagramMarketingInsights>;
  like_count?: Maybe<Scalars['Int']>;
  comments_count?: Maybe<Scalars['Int']>;
  video_title?: Maybe<Scalars['String']>;
  media_product_type?: Maybe<Scalars['String']>;
  handles?: Maybe<Array<Maybe<Scalars['String']>>>;
  hashes?: Maybe<Array<Maybe<Scalars['String']>>>;
  mentioned?: Maybe<Array<Maybe<Scalars['String']>>>;
  taggedUsernames?: Maybe<Array<Maybe<Scalars['String']>>>;
  isAd?: Maybe<Scalars['Boolean']>;
  children?: Maybe<Array<InstagramChildMedia>>;
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
  profile?: Maybe<Profile>;
  history?: Maybe<Array<Maybe<InstagramMediaHistorical>>>;
};

export type InstagramMediaAverages = {
  __typename?: 'InstagramMediaAverages';
  date?: Maybe<Scalars['Date']>;
  like_count?: Maybe<Scalars['Float']>;
  comments_count?: Maybe<Scalars['Float']>;
  insights?: Maybe<InstagramPostInsightAverages>;
  topHandles?: Maybe<Array<PerformanceReportMention>>;
  topHashtags?: Maybe<Array<PerformanceReportMention>>;
};

export type InstagramMediaHistorical = {
  __typename?: 'InstagramMediaHistorical';
  id?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  like_count?: Maybe<Scalars['Float']>;
  comments_count?: Maybe<Scalars['Float']>;
  insights?: Maybe<InstagramPostInsights>;
  foundDate?: Maybe<Scalars['Date']>;
};

export type InstagramMediaTrends = {
  __typename?: 'InstagramMediaTrends';
  dailyAverages?: Maybe<Array<Maybe<InstagramMediaAverages>>>;
  recentAverages?: Maybe<InstagramMediaAverages>;
  comparisonAverages?: Maybe<InstagramMediaAverages>;
};

export enum InstagramMediaType {
  Image = 'IMAGE',
  CarouselAlbum = 'CAROUSEL_ALBUM',
  Video = 'VIDEO'
}

export type InstagramPostInsightAverages = {
  __typename?: 'InstagramPostInsightAverages';
  impressions?: Maybe<Scalars['Float']>;
  reach?: Maybe<Scalars['Float']>;
  engagement?: Maybe<Scalars['Float']>;
  engagement_percentageOfReach?: Maybe<Scalars['Float']>;
  saved?: Maybe<Scalars['Float']>;
  viewRate?: Maybe<Scalars['Float']>;
  comments?: Maybe<Scalars['Float']>;
  likes?: Maybe<Scalars['Float']>;
  plays?: Maybe<Scalars['Float']>;
  shares?: Maybe<Scalars['Float']>;
  total_interactions?: Maybe<Scalars['Float']>;
  total_interactions_percentageOfReach?: Maybe<Scalars['Float']>;
};

export type InstagramPostInsights = {
  __typename?: 'InstagramPostInsights';
  impressions?: Maybe<Scalars['Int']>;
  reach?: Maybe<Scalars['Int']>;
  engagement?: Maybe<Scalars['Int']>;
  engagement_percentageOfReach?: Maybe<Scalars['Float']>;
  saved?: Maybe<Scalars['Int']>;
  viewRate?: Maybe<Scalars['Float']>;
  video_views?: Maybe<Scalars['Int']>;
  comments?: Maybe<Scalars['Int']>;
  likes?: Maybe<Scalars['Int']>;
  plays?: Maybe<Scalars['Int']>;
  shares?: Maybe<Scalars['Int']>;
  total_interactions?: Maybe<Scalars['Int']>;
  total_interactions_percentageOfReach?: Maybe<Scalars['Float']>;
};

export type InstagramProfile = {
  __typename?: 'InstagramProfile';
  _id: Scalars['ObjectId'];
  isAddedByOwner?: Maybe<Scalars['Boolean']>;
  agentId?: Maybe<Scalars['String']>;
  biography?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** @deprecated Use cacheError */
  cache_error?: Maybe<Scalars['String']>;
  cache_completed_at?: Maybe<Scalars['Date']>;
  created_at?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  historical_from?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  pageId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  media_cached_at?: Maybe<Scalars['Date']>;
  profileCategory?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use 'profileCategory'. */
  profile_category?: Maybe<Array<Maybe<Scalars['String']>>>;
  profileType?: Maybe<SocialChannel>;
  /** @deprecated Use 'profileType'. */
  profile_type?: Maybe<Scalars['String']>;
  profile_cached_at?: Maybe<Scalars['Date']>;
  profile_media_kit?: Maybe<MediaKit>;
  profile_picture_url?: Maybe<Scalars['String']>;
  redflags_from?: Maybe<Scalars['Date']>;
  redflags_until?: Maybe<Scalars['Date']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['Date']>;
  userId?: Maybe<Scalars['String']>;
  isRestricted?: Maybe<Scalars['Boolean']>;
  isPendingConnection?: Maybe<Scalars['Boolean']>;
  hasAcceptedInvite?: Maybe<Scalars['Boolean']>;
  doesRequireTrackApproval?: Maybe<Scalars['Boolean']>;
  selectedMediaKitId?: Maybe<Scalars['ObjectId']>;
  profileAuditReportLink?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  media_count?: Maybe<Scalars['Int']>;
  followers_count?: Maybe<Scalars['Int']>;
  channelStats?: Maybe<ChannelStats>;
  fan_count?: Maybe<Scalars['Int']>;
  published_post_count?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['String']>;
  union_id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  profile_cache_attempts?: Maybe<Scalars['Int']>;
  media_cache_attempts?: Maybe<Scalars['Int']>;
  last_cache_attempts?: Maybe<Scalars['Int']>;
  cache_error_at?: Maybe<Scalars['Date']>;
  cache_error_resolved_at?: Maybe<Scalars['Date']>;
  last_cache_error?: Maybe<Scalars['String']>;
  user_notified_at?: Maybe<Scalars['Date']>;
  agency_notified_at?: Maybe<Scalars['Date']>;
  historical_until?: Maybe<Scalars['Date']>;
  assignedToAmbassadorId?: Maybe<Scalars['ObjectId']>;
  profile_added_at?: Maybe<Scalars['Date']>;
  ownerNotes?: Maybe<Scalars['String']>;
  ownerTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  isInstagramIncomingMentionsEnabled?: Maybe<Scalars['Boolean']>;
  isInstagramFeedWebhookEnabled?: Maybe<Scalars['Boolean']>;
  pageStats?: Maybe<FacebookPageStats>;
  postStats?: Maybe<FacebookPostStats>;
  historicalFacebookStats?: Maybe<Array<FacebookPageStats>>;
  weeklyFacebookStats?: Maybe<Array<FacebookWeeklyStats>>;
  rankedFacebookMedia?: Maybe<RankedFacebookMedia>;
  latestFacebookPosts?: Maybe<Array<FacebookPost>>;
  facebookPostTrends?: Maybe<FacebookMediaTrends>;
  recentFacebookMediaStats?: Maybe<RecentMediaStats>;
  lifetimeInsights?: Maybe<LifetimeInsights>;
  historicalStats?: Maybe<InstagramHistoricalStats>;
  onlineFollowers?: Maybe<Scalars['JSObject']>;
  /** @deprecated Use rankedInstagramMedia.topMedia instead */
  rankedInstagramPosts?: Maybe<Array<InstagramMedia>>;
  rankedInstagramMedia?: Maybe<RankedInstagramMedia>;
  rankedInstagramFeed?: Maybe<RankedInstagramMedia>;
  rankedInstagramReels?: Maybe<RankedInstagramMedia>;
  latestInstagramPosts?: Maybe<Array<InstagramMedia>>;
  latestInstagramFeed?: Maybe<Array<InstagramMedia>>;
  latestInstagramReels?: Maybe<Array<InstagramMedia>>;
  latestInstagramStories?: Maybe<Array<InstagramStory>>;
  dailyInstagramStats?: Maybe<Array<InstagramHistorical>>;
  weeklyInstagramStats?: Maybe<Array<InstagramHistorical>>;
  mediaStats?: Maybe<MediaStats>;
  instagramFeedStats?: Maybe<MediaStats>;
  instagramReelStats?: Maybe<MediaStats>;
  storyStats?: Maybe<MediaStats>;
  instagramFeedTrends?: Maybe<InstagramMediaTrends>;
  instagramReelTrends?: Maybe<InstagramMediaTrends>;
  instagramStoryTrends?: Maybe<InstagramStoryTrends>;
  instagramCombinedTrends?: Maybe<InstagramCombinedTrends>;
  followerBracket?: Maybe<Scalars['Int']>;
  latestPerformanceReport?: Maybe<PerformanceReport>;
  instagramMediaMentions?: Maybe<Array<Maybe<InstagramMedia>>>;
  recentInstagramMediaStats?: Maybe<RecentInstagramMediaStats>;
  subscribedWebhookFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  cacheError?: Maybe<Scalars['String']>;
  userFriendlyCacheError?: Maybe<UserFriendlyCacheError>;
  user?: Maybe<User>;
  agent?: Maybe<User>;
  cacheSeconds?: Maybe<Scalars['Int']>;
  mediaKitConfigs?: Maybe<Array<Maybe<MediaKitConfig>>>;
  ownerMediaKitConfigs?: Maybe<Array<MediaKitConfig>>;
  profileNote?: Maybe<ProfileNote>;
  dataAccessToken?: Maybe<Scalars['String']>;
  followerCount?: Maybe<Scalars['Int']>;
  selectedMediaKit?: Maybe<MediaKitConfig>;
  isVisibleOnNetwork?: Maybe<Scalars['Boolean']>;
  mediaKits?: Maybe<Array<MediaKitConfig>>;
  thumbnails?: Maybe<Thumbnails>;
  title?: Maybe<Scalars['String']>;
  videoStats?: Maybe<YoutubeMediaStats>;
  snippet?: Maybe<Scalars['String']>;
  ageGroupBreakdown?: Maybe<Array<AgeGroup>>;
  genderBreakdown?: Maybe<Array<GenderGroup>>;
  locationBreakdown?: Maybe<Array<LocationGroup>>;
  myChannelReport?: Maybe<Array<ChannelReport>>;
  subscriberChange?: Maybe<Array<SubscriberChange>>;
  youtubeMedia?: Maybe<Array<YoutubeMedia>>;
  youtubeHistorical?: Maybe<Array<YoutubeHistorical>>;
  youtubeWeeklyStats?: Maybe<Array<YoutubeHistoricalStats>>;
  rankedYoutubeMedia?: Maybe<RankedYoutubeMedia>;
  youtubeVideoTrends?: Maybe<YoutubeMediaTrends>;
  recentYoutubeMediaStats?: Maybe<RecentMediaStats>;
  public?: Maybe<TiktokPublicUser>;
  tiktokMediaStats?: Maybe<TiktokMediaStats>;
  tiktokMediaTrends?: Maybe<TiktokMediaTrends>;
  rankedTiktokMedia?: Maybe<TikTokRankedMedia>;
  recentTiktokMediaStats?: Maybe<RecentMediaStats>;
  hasInstagramInsightsSince?: Maybe<Scalars['Date']>;
};

export type InstagramProfileWeeklyFacebookStatsArgs = {
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type InstagramProfileWeeklyInstagramStatsArgs = {
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type InstagramProfileYoutubeWeeklyStatsArgs = {
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type InstagramStory = {
  __typename?: 'InstagramStory';
  _id: Scalars['ObjectId'];
  id: Scalars['ID'];
  profileId?: Maybe<Scalars['String']>;
  shortcode?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  media_type?: Maybe<InstagramMediaType>;
  media_product_type?: Maybe<Scalars['String']>;
  media_url?: Maybe<Scalars['String']>;
  thumbnail_url?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Date']>;
  webhook_timestamp?: Maybe<Scalars['Date']>;
  insights?: Maybe<InstagramStoryInsights>;
  marketingInsights?: Maybe<InstagramMarketingInsights>;
  handles?: Maybe<Array<Maybe<Scalars['String']>>>;
  hashes?: Maybe<Array<Maybe<Scalars['String']>>>;
  isAd?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
  profile?: Maybe<Profile>;
};

export type InstagramStoryAverages = {
  __typename?: 'InstagramStoryAverages';
  date?: Maybe<Scalars['Date']>;
  insights?: Maybe<InstagramStoryInsightAverages>;
  topHandles?: Maybe<Array<PerformanceReportMention>>;
  topHashtags?: Maybe<Array<PerformanceReportMention>>;
};

export type InstagramStoryInsightAverages = {
  __typename?: 'InstagramStoryInsightAverages';
  exits?: Maybe<Scalars['Float']>;
  impressions?: Maybe<Scalars['Float']>;
  reach?: Maybe<Scalars['Float']>;
  replies?: Maybe<Scalars['Float']>;
  taps_forward?: Maybe<Scalars['Float']>;
  taps_back?: Maybe<Scalars['Float']>;
  viewRate?: Maybe<Scalars['Float']>;
  retention?: Maybe<Scalars['Float']>;
  storyFrames?: Maybe<Scalars['Float']>;
};

export type InstagramStoryInsights = {
  __typename?: 'InstagramStoryInsights';
  exits?: Maybe<Scalars['Int']>;
  impressions?: Maybe<Scalars['Int']>;
  reach?: Maybe<Scalars['Int']>;
  replies?: Maybe<Scalars['Int']>;
  taps_forward?: Maybe<Scalars['Int']>;
  taps_back?: Maybe<Scalars['Int']>;
};

export type InstagramStoryTrends = {
  __typename?: 'InstagramStoryTrends';
  dailyAverages?: Maybe<Array<Maybe<InstagramStoryAverages>>>;
  recentAverages?: Maybe<InstagramStoryAverages>;
  comparisonAverages?: Maybe<InstagramStoryAverages>;
};

export type InvoiceShareContact = {
  __typename?: 'InvoiceShareContact';
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type InvoiceShareContactInput = {
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
};

export enum InvoiceStatus {
  Draft = 'DRAFT',
  Sent = 'SENT',
  Overdue = 'OVERDUE',
  Paid = 'PAID',
  Void = 'VOID'
}

export type LandingPageRequestEmailPayload = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  primaryBrandColor?: Maybe<Scalars['String']>;
  secondaryBrandColor?: Maybe<Scalars['String']>;
  imageFile?: Maybe<Scalars['Upload']>;
  changeRequest?: Maybe<Scalars['Boolean']>;
};

export type LifetimeInsights = {
  __typename?: 'LifetimeInsights';
  topCountry?: Maybe<TopDemographic>;
  topGender?: Maybe<TopDemographic>;
  topCity?: Maybe<TopDemographic>;
  audience_gender?: Maybe<AudienceGender>;
  audience_age?: Maybe<Scalars['JSObject']>;
  audience_country?: Maybe<Scalars['JSObject']>;
  audience_city?: Maybe<Scalars['JSObject']>;
};

export type LimitUsage = {
  __typename?: 'LimitUsage';
  reports?: Maybe<Scalars['String']>;
  talent?: Maybe<Scalars['String']>;
  handles?: Maybe<Scalars['String']>;
  ambassadors?: Maybe<Scalars['String']>;
  brandProfiles?: Maybe<Scalars['String']>;
  mediaKitShares?: Maybe<Scalars['String']>;
};

export type Link = {
  __typename?: 'Link';
  url?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type LinkInput = {
  title?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['Upload']>;
  iconUrl?: Maybe<Scalars['String']>;
};

export type LinkPair = {
  __typename?: 'LinkPair';
  title?: Maybe<Scalars['String']>;
  fileSize?: Maybe<Scalars['Int']>;
  link?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
};

export type LinkedAccountsContent = {
  __typename?: 'LinkedAccountsContent';
  type?: Maybe<ElementType>;
  linked_accounts_content?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
};

export type LinksContent = {
  __typename?: 'LinksContent';
  type?: Maybe<ElementType>;
  links_content?: Maybe<Array<Maybe<LinkPair>>>;
};

export type Localized = {
  __typename?: 'Localized';
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type LocationGroup = {
  __typename?: 'LocationGroup';
  country?: Maybe<Scalars['String']>;
  views?: Maybe<Scalars['Int']>;
};

export type LoginResults = {
  __typename?: 'LoginResults';
  intercomUserHash?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  error?: Maybe<Scalars['String']>;
  delegate?: Maybe<Delegate>;
  success?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  data?: Maybe<UserLoginToken>;
  phoneVerificationToken?: Maybe<Scalars['String']>;
  signedBrowserSessionToken?: Maybe<Scalars['String']>;
};

export type MediaByDayStat = {
  __typename?: 'MediaByDayStat';
  count?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['String']>;
};

export type MediaCounts = {
  __typename?: 'MediaCounts';
  /** @deprecated Use instagramFeed or instagramReels */
  media?: Maybe<MediaCountsByCategory>;
  instagramFeed?: Maybe<MediaCountsByCategory>;
  instagramReels?: Maybe<MediaCountsByCategory>;
  stories?: Maybe<MediaCountsByCategory>;
};

export type MediaCountsByCategory = {
  __typename?: 'MediaCountsByCategory';
  ads?: Maybe<Scalars['Int']>;
  all?: Maybe<Scalars['Int']>;
};

export type MediaForConfig = {
  __typename?: 'MediaForConfig';
  /** @deprecated Use instagramFeed or instagramReels */
  media?: Maybe<Array<InstagramMedia>>;
  instagramFeed?: Maybe<Array<InstagramMedia>>;
  instagramReels?: Maybe<Array<InstagramMedia>>;
  stories?: Maybe<Array<InstagramStory>>;
  videos?: Maybe<Array<YoutubeMedia>>;
  posts?: Maybe<Array<FacebookPost>>;
  tiktokMedia?: Maybe<Array<TiktokMedia>>;
  supplementMedia?: Maybe<Array<SupplementMedia>>;
  profiles?: Maybe<Array<Profile>>;
  profilesAll?: Maybe<Array<Profile>>;
};

export type MediaKit = {
  __typename?: 'MediaKit';
  bio?: Maybe<Scalars['String']>;
  links?: Maybe<Array<Maybe<Link>>>;
  rates?: Maybe<Scalars['String']>;
  hashtags?: Maybe<Array<Maybe<Scalars['String']>>>;
  handles?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MediaKitConfig = {
  __typename?: 'MediaKitConfig';
  _id: Scalars['ObjectId'];
  ownerId?: Maybe<Scalars['ObjectId']>;
  ambassadorId?: Maybe<Scalars['ObjectId']>;
  profileId?: Maybe<Scalars['ObjectId']>;
  title?: Maybe<Scalars['String']>;
  headerImage?: Maybe<Scalars['String']>;
  status?: Maybe<MediaKitStatus>;
  colorTheme?: Maybe<MediaKitTheme>;
  colorHex?: Maybe<Scalars['String']>;
  backgroundColorTheme?: Maybe<MediaKitTheme>;
  backgroundColorHex?: Maybe<Scalars['String']>;
  elementIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  draft?: Maybe<MediaKitConfigDraft>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
  owner?: Maybe<User>;
  ambassador?: Maybe<Ambassador>;
  profile?: Maybe<Profile>;
  profiles?: Maybe<Array<Profile>>;
  elements?: Maybe<Array<Maybe<MediaKitElement>>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
  pdfLink?: Maybe<Scalars['String']>;
  socialChannels?: Maybe<Array<Maybe<SocialChannel>>>;
  dataAccessToken?: Maybe<Scalars['String']>;
};

export type MediaKitConfigDraft = {
  __typename?: 'MediaKitConfigDraft';
  title?: Maybe<Scalars['String']>;
  colorTheme?: Maybe<MediaKitTheme>;
  colorHex?: Maybe<Scalars['String']>;
  backgroundColorTheme?: Maybe<MediaKitTheme>;
  backgroundColorHex?: Maybe<Scalars['String']>;
  headerImage?: Maybe<Scalars['String']>;
  elementIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  profiles?: Maybe<Array<Profile>>;
  elements?: Maybe<Array<Maybe<MediaKitElement>>>;
};

export type MediaKitConfigUpdates = {
  title?: Maybe<Scalars['String']>;
  headerImage?: Maybe<Scalars['String']>;
  colorTheme?: Maybe<MediaKitTheme>;
  colorHex?: Maybe<Scalars['String']>;
  backgroundColorTheme?: Maybe<MediaKitTheme>;
  backgroundColorHex?: Maybe<Scalars['String']>;
  ambassadorId?: Maybe<Scalars['ObjectId']>;
  profileId?: Maybe<Scalars['ObjectId']>;
  elementIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
};

export type MediaKitElement = {
  __typename?: 'MediaKitElement';
  _id: Scalars['ObjectId'];
  title?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['ObjectId']>;
  target?: Maybe<ElementTarget>;
  content?: Maybe<ElementContentType>;
  draft?: Maybe<MediaKitElementDraft>;
  mediaKitId?: Maybe<Scalars['ObjectId']>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
  stringifiedContent?: Maybe<Scalars['String']>;
  owner?: Maybe<User>;
};

export type MediaKitElementContentInput = {
  type?: Maybe<ElementType>;
  text_content?: Maybe<Scalars['String']>;
  rich_text_content?: Maybe<Scalars['String']>;
  image_content?: Maybe<Scalars['Upload']>;
  image_list_content?: Maybe<Array<Maybe<ImageListInput>>>;
  gallery_content?: Maybe<Array<Maybe<GalleryInput>>>;
  links_content?: Maybe<Array<Maybe<LinkInput>>>;
  featured_insights_content?: Maybe<Array<Maybe<FeaturedInsightInput>>>;
  video_content?: Maybe<Scalars['Upload']>;
  rate_content?: Maybe<Array<Maybe<RateInput>>>;
  deliverable_rates_content?: Maybe<Array<Maybe<DeliverableRateInput>>>;
  linked_accounts_content?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  file_content?: Maybe<Array<Maybe<FileInput>>>;
  audience_demographics_content?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  selected_media_content?: Maybe<SelectedMediaInput>;
};

export type MediaKitElementDraft = {
  __typename?: 'MediaKitElementDraft';
  title?: Maybe<Scalars['String']>;
  content?: Maybe<ElementContentType>;
  stringifiedContent?: Maybe<Scalars['String']>;
};

export type MediaKitLink = {
  __typename?: 'MediaKitLink';
  _id: Scalars['ObjectId'];
  userId?: Maybe<Scalars['ObjectId']>;
  contactId?: Maybe<Scalars['ObjectId']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['ObjectId']>;
  mediaKitId?: Maybe<Scalars['ObjectId']>;
  isOpened?: Maybe<Scalars['Boolean']>;
  openedAt?: Maybe<Scalars['Date']>;
  expiresAt?: Maybe<Scalars['Date']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  colorTheme?: Maybe<MediaKitTheme>;
  colorHex?: Maybe<Scalars['String']>;
  backgroundColorTheme?: Maybe<MediaKitTheme>;
  backgroundColorHex?: Maybe<Scalars['String']>;
  elementIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  shortenedLink?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
  contact?: Maybe<Contact>;
  profile?: Maybe<Profile>;
  elements?: Maybe<Array<Maybe<MediaKitElement>>>;
  profiles?: Maybe<Array<Maybe<Profile>>>;
  dataAccessToken?: Maybe<Scalars['String']>;
};

export type MediaKitLinkForCode = {
  __typename?: 'MediaKitLinkForCode';
  mediaKitLinkId?: Maybe<Scalars['ObjectId']>;
  dataAccessToken?: Maybe<Scalars['String']>;
};

export type MediaKitShareContact = {
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ObjectId']>;
};

export enum MediaKitStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED'
}

export enum MediaKitTheme {
  Default = 'DEFAULT',
  KbPurple = 'KB_PURPLE',
  Pink = 'PINK',
  Teal = 'TEAL',
  Aqua = 'AQUA',
  Salmon = 'SALMON',
  Gray = 'GRAY',
  Lightsalmon = 'LIGHTSALMON',
  Lightgray = 'LIGHTGRAY',
  Lightorange = 'LIGHTORANGE',
  Mediumpurple = 'MEDIUMPURPLE',
  Linen = 'LINEN',
  Snow = 'SNOW',
  Darkblue = 'DARKBLUE',
  Paleblue = 'PALEBLUE',
  Turquoise = 'TURQUOISE'
}

export type MediaMetrics = {
  __typename?: 'MediaMetrics';
  average?: Maybe<Scalars['Float']>;
  median?: Maybe<Scalars['Float']>;
  actual?: Maybe<Scalars['Float']>;
};

export type MediaPerformance = {
  __typename?: 'MediaPerformance';
  /** @deprecated Use instagramFeed or instagramReels */
  media?: Maybe<PerformanceByCategory>;
  instagramFeed?: Maybe<PerformanceByCategory>;
  instagramReels?: Maybe<PerformanceByCategory>;
  stories?: Maybe<PerformanceByCategory>;
};

export type MediaStats = {
  __typename?: 'MediaStats';
  count?: Maybe<Scalars['Float']>;
  days?: Maybe<Scalars['Float']>;
  postsPerDay?: Maybe<Scalars['Float']>;
  topHashtags?: Maybe<Array<Maybe<Scalars['String']>>>;
  topHandles?: Maybe<Array<Maybe<Scalars['String']>>>;
  like_count?: Maybe<MetricSummary>;
  comments_count?: Maybe<MetricSummary>;
  reach?: Maybe<MetricSummary>;
  impressions?: Maybe<MetricSummary>;
  engagement?: Maybe<MetricSummary>;
  engagement_percentageOfReach?: Maybe<MetricSummary>;
  saved?: Maybe<MetricSummary>;
  video_views?: Maybe<MetricSummary>;
  exits?: Maybe<MetricSummary>;
  replies?: Maybe<MetricSummary>;
  taps_forward?: Maybe<MetricSummary>;
  taps_back?: Maybe<MetricSummary>;
  retention?: Maybe<MetricSummary>;
  storyFrames?: Maybe<MetricSummary>;
  comments?: Maybe<MetricSummary>;
  likes?: Maybe<MetricSummary>;
  plays?: Maybe<MetricSummary>;
  shares?: Maybe<MetricSummary>;
  total_interactions?: Maybe<MetricSummary>;
  total_interactions_percentageOfReach?: Maybe<MetricSummary>;
};

export type MetricSummary = {
  __typename?: 'MetricSummary';
  actual?: Maybe<Scalars['Float']>;
  median?: Maybe<Scalars['Float']>;
  average?: Maybe<Scalars['Float']>;
  percentage?: Maybe<Scalars['Float']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createCollaboration?: Maybe<Scalars['ObjectId']>;
  updateCollaboration: CollaborationUpdateResponse;
  deleteCollaboration: ServerResponse;
  createDeliverable: Scalars['String'];
  updateDeliverable: ServerResponse;
  createMultipleDeliverables: ServerResponse;
  updateAndCreateDeliverables: ServerResponse;
  viewDeliverable: ServerResponse;
  deleteDeliverable: ServerResponse;
  deleteDeliverablesByIds: ServerResponse;
  updateDeliverableValueAddedMediaIds?: Maybe<ServerResponse>;
  generateInstagramRankings: ServerResponse;
  inviteAmbassador: ServerResponse;
  createAmbassadorManually: ServerResponse;
  inviteManualAmbassador: ServerResponse;
  createAmbassador: ServerResponse;
  deleteAmbassador: ServerResponse;
  deleteAmbassadorByUserId: ServerResponse;
  updateAmbassadorInvitation: ServerResponse;
  updateAmbassadorInfo: ServerResponse;
  sendReauthenticationEmail?: Maybe<ServerResponse>;
  registerAmbassador?: Maybe<AmbassadorRegistrationResult>;
  resendAmbassadorInvitation: ServerResponse;
  updateUserSubscription: ServerResponse;
  processAppPurchaseResponse: ServerResponse;
  updateTransactionInfo: ServerResponse;
  testAppSubscription: ServerResponse;
  deletePaymentMethod: Scalars['Boolean'];
  setPaymentMethodToDefault: Scalars['Boolean'];
  connectContact: ConnectReponse;
  createContact?: Maybe<ServerResponse>;
  updateContact: ServerResponse;
  deleteContact: ServerResponse;
  adminCreateCoupon: CouponCode;
  adminUpdateCoupon?: Maybe<CouponCode>;
  adminDeleteCoupon: ServerResponse;
  addDelegate: ServerResponse;
  updateDelegate: ServerResponse;
  deleteDelegate: ServerResponse;
  adminDeleteDelegate: ServerResponse;
  registerDelegate: DelegateRegistrationResult;
  setFacebookProfile: ServerResponse;
  updateUserToken: ServerResponse;
  adminCreatePlan?: Maybe<InfluencerPlan>;
  adminUpdatePlan?: Maybe<InfluencerPlan>;
  adminDeletePlan?: Maybe<ServerResponse>;
  updateWebhookSubscriptions: ServerResponse;
  openCollaborationInvoiceLink: Scalars['String'];
  createCollaborationInvoiceLink: CollaborationInvoiceLinkCreationResult;
  shareCollaborationInvoice: ServerResponse;
  createCollaborationInvoice: Scalars['ObjectId'];
  updateCollaborationInvoice: ServerResponse;
  deleteCollaborationInvoice: ServerResponse;
  createMediaKitLink: Scalars['String'];
  shareMediaKitToContact: ServerResponse;
  shareAparToContact: ServerResponse;
  createMediaKitConfig: Scalars['String'];
  updateMediaKitConfig: ServerResponse;
  updateMediaKitDraftConfig: ServerResponse;
  publishMediaKitDraftConfig: ServerResponse;
  deleteMediaKitConfig: ServerResponse;
  adminClearOrphanedElements?: Maybe<ServerResponse>;
  createElement?: Maybe<Scalars['ObjectId']>;
  updateElement: ServerResponse;
  updateElementDraft: ServerResponse;
  deleteElement: ServerResponse;
  updateNotificationReadStatus: ServerResponse;
  adminUpdatePersistedUser?: Maybe<PersistedUser>;
  updateProfileNote: ServerResponse;
  createProfile: Scalars['ObjectId'];
  updateProfile: ServerResponse;
  unassignAmbassadorFromProfile: ServerResponse;
  deleteProfile: ServerResponse;
  adminUpdateProfile: ServerResponse;
  adminDeleteProfile: ServerResponse;
  adminCreateEternalLink?: Maybe<Scalars['String']>;
  logReceivedPushNotification: ServerResponse;
  updatePushNotificationToken: ServerResponse;
  addRecent?: Maybe<ServerResponse>;
  createSupplementMedia: ServerResponse;
  insertSupplementMediaList: ServerResponse;
  updateSupplementMedia: ServerResponse;
  deleteSupplementMedia: ServerResponse;
  createReportConfig: ReportConfig;
  updateReportConfig: ServerResponse;
  deleteReportConfig: ServerResponse;
  deleteMultipleReportConfigs: ServerResponse;
  approveTrackingRequest: ServerResponse;
  createReportLink: Scalars['String'];
  shareReportToContact: ServerResponse;
  hideSentEmailRecord: ServerResponse;
  resendEmailInvite: ServerResponse;
  sendEmailInvite: ServerResponse;
  sendTalentNetworkContactEmail: ServerResponse;
  sendLandingPageRequestEmail: ServerResponse;
  deleteSubscriptionScheduleForCustomer: ServerResponse;
  deleteStripePaymentMethod: Scalars['Boolean'];
  setStripePaymentMethodToDefault: Scalars['Boolean'];
  createTiktokProfile?: Maybe<ServerResponse>;
  connectTiktokProfile: ServerResponse;
  disconnectTiktokProfile: ServerResponse;
  createTrackingLink: TrackingLink;
  deleteTrackingLink: ServerResponse;
  clearOrphanedLinks: ServerResponse;
  login: ServerResponse;
  updateUser: ServerResponse;
  updateWalkthroughs: ServerResponse;
  veryfyUserEmail: ServerResponse;
  sendVerificationCodeToPhone: ServerResponse;
  resendEmailVerificationCode: ServerResponse;
  deleteMyUser: ServerResponse;
  adminDeleteUser: ServerResponse;
  adminUpdateUser?: Maybe<User>;
  adminRefreshSubscriptions: ServerResponse;
  updateAccountProgress: ServerResponse;
  updateBrandAmbassadorCategory: ServerResponse;
  inviteBrand: ServerResponse;
  logout: ServerResponse;
};

export type MutationCreateCollaborationArgs = {
  updates?: Maybe<CollaborationUpdates>;
};

export type MutationUpdateCollaborationArgs = {
  updates?: Maybe<CollaborationUpdates>;
  id: Scalars['ObjectId'];
};

export type MutationDeleteCollaborationArgs = {
  id: Scalars['ObjectId'];
};

export type MutationCreateDeliverableArgs = {
  updates?: Maybe<DeliverableUpdates>;
};

export type MutationUpdateDeliverableArgs = {
  updates?: Maybe<DeliverableUpdates>;
  id: Scalars['ObjectId'];
};

export type MutationCreateMultipleDeliverablesArgs = {
  updates?: Maybe<DeliverableUpdates>;
  newTrackingLink?: Maybe<TrackingLinkInput>;
};

export type MutationUpdateAndCreateDeliverablesArgs = {
  updates?: Maybe<DeliverableUpdates>;
  newTrackingLink?: Maybe<TrackingLinkInput>;
  id: Scalars['ObjectId'];
};

export type MutationViewDeliverableArgs = {
  id: Scalars['ObjectId'];
};

export type MutationDeleteDeliverableArgs = {
  id: Scalars['ObjectId'];
};

export type MutationDeleteDeliverablesByIdsArgs = {
  ids: Array<Scalars['ObjectId']>;
};

export type MutationUpdateDeliverableValueAddedMediaIdsArgs = {
  remove?: Maybe<Scalars['String']>;
  add?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ObjectId']>;
};

export type MutationGenerateInstagramRankingsArgs = {
  shouldSave?: Maybe<Scalars['Boolean']>;
  shouldNotify?: Maybe<Scalars['Boolean']>;
};

export type MutationInviteAmbassadorArgs = {
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name?: Maybe<Scalars['String']>;
  requestingAccountName?: Maybe<Scalars['String']>;
};

export type MutationCreateAmbassadorManuallyArgs = {
  inviteByEmail?: Maybe<Scalars['Boolean']>;
  updates: AmbassadorInput;
};

export type MutationInviteManualAmbassadorArgs = {
  id?: Maybe<Scalars['ObjectId']>;
};

export type MutationCreateAmbassadorArgs = {
  profileUsername?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['ObjectId']>;
};

export type MutationDeleteAmbassadorArgs = {
  ambassadorId?: Maybe<Scalars['ObjectId']>;
};

export type MutationDeleteAmbassadorByUserIdArgs = {
  userId?: Maybe<Scalars['ObjectId']>;
};

export type MutationUpdateAmbassadorInvitationArgs = {
  id?: Maybe<Scalars['ObjectId']>;
  ambassadorInput?: Maybe<AmbassadorInput>;
};

export type MutationUpdateAmbassadorInfoArgs = {
  updates: AmbassadorInput;
  id: Scalars['ObjectId'];
};

export type MutationSendReauthenticationEmailArgs = {
  userId: Scalars['ObjectId'];
  profileId?: Maybe<Scalars['ObjectId']>;
};

export type MutationRegisterAmbassadorArgs = {
  payload: RegisterAmbassadorInput;
  userId: Scalars['ObjectId'];
};

export type MutationResendAmbassadorInvitationArgs = {
  ambassadorId?: Maybe<Scalars['ObjectId']>;
};

export type MutationUpdateUserSubscriptionArgs = {
  subscription?: Maybe<AppSubscriptionInput>;
  installerName?: Maybe<Scalars['String']>;
};

export type MutationProcessAppPurchaseResponseArgs = {
  purchases?: Maybe<Array<Maybe<AppSubscriptionInput>>>;
  installerName?: Maybe<Scalars['String']>;
};

export type MutationUpdateTransactionInfoArgs = {
  id: Scalars['ObjectId'];
};

export type MutationDeletePaymentMethodArgs = {
  id: Scalars['String'];
};

export type MutationSetPaymentMethodToDefaultArgs = {
  id: Scalars['String'];
};

export type MutationConnectContactArgs = {
  id: Scalars['ObjectId'];
};

export type MutationCreateContactArgs = {
  updates?: Maybe<ContactUpdates>;
};

export type MutationUpdateContactArgs = {
  updates?: Maybe<ContactUpdates>;
  id: Scalars['ObjectId'];
};

export type MutationDeleteContactArgs = {
  id: Scalars['ObjectId'];
};

export type MutationAdminCreateCouponArgs = {
  updates?: Maybe<CouponCodeUpdates>;
};

export type MutationAdminUpdateCouponArgs = {
  couponCodeId?: Maybe<Scalars['String']>;
  updates?: Maybe<CouponCodeUpdates>;
};

export type MutationAdminDeleteCouponArgs = {
  couponCodeId?: Maybe<Scalars['String']>;
};

export type MutationAddDelegateArgs = {
  delegate?: Maybe<DelegateCreationInput>;
  delegates?: Maybe<Array<Maybe<DelegateCreationInput>>>;
};

export type MutationUpdateDelegateArgs = {
  delegateUpdates?: Maybe<DelegateUserUpdates>;
};

export type MutationDeleteDelegateArgs = {
  id: Scalars['ObjectId'];
};

export type MutationAdminDeleteDelegateArgs = {
  id: Scalars['ObjectId'];
};

export type MutationRegisterDelegateArgs = {
  payload: DelegateRegistrationInput;
  delegate_id: Scalars['ObjectId'];
};

export type MutationSetFacebookProfileArgs = {
  updates?: Maybe<FacebookProfileInput>;
};

export type MutationUpdateUserTokenArgs = {
  token?: Maybe<Scalars['String']>;
};

export type MutationAdminCreatePlanArgs = {
  updates?: Maybe<InfluencerPlanUpdates>;
};

export type MutationAdminUpdatePlanArgs = {
  influencerPlanId?: Maybe<Scalars['ObjectId']>;
  updates?: Maybe<InfluencerPlanUpdates>;
};

export type MutationAdminDeletePlanArgs = {
  influencerPlanId?: Maybe<Scalars['ObjectId']>;
};

export type MutationUpdateWebhookSubscriptionsArgs = {
  shouldIncludeMentions?: Maybe<Scalars['Boolean']>;
  profileId: Scalars['String'];
};

export type MutationOpenCollaborationInvoiceLinkArgs = {
  id: Scalars['ObjectId'];
};

export type MutationCreateCollaborationInvoiceLinkArgs = {
  collaborationInvoiceId?: Maybe<Scalars['ObjectId']>;
};

export type MutationShareCollaborationInvoiceArgs = {
  id: Scalars['ObjectId'];
  shareDetails?: Maybe<ShareCollaborationInvoiceDetails>;
};

export type MutationCreateCollaborationInvoiceArgs = {
  updates?: Maybe<CollaborationInvoiceUpdates>;
};

export type MutationUpdateCollaborationInvoiceArgs = {
  updates?: Maybe<CollaborationInvoiceUpdates>;
  id: Scalars['ObjectId'];
};

export type MutationDeleteCollaborationInvoiceArgs = {
  id: Scalars['ObjectId'];
};

export type MutationCreateMediaKitLinkArgs = {
  mediaKitId?: Maybe<Scalars['ObjectId']>;
};

export type MutationShareMediaKitToContactArgs = {
  mediaKitId?: Maybe<Scalars['ObjectId']>;
  contactId?: Maybe<Scalars['ObjectId']>;
  contactIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  contactDetails?: Maybe<MediaKitShareContact>;
  coverLetter?: Maybe<Scalars['String']>;
};

export type MutationShareAparToContactArgs = {
  aparLink?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['ObjectId']>;
  contactIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  contactDetails?: Maybe<MediaKitShareContact>;
  ambassadorId?: Maybe<Scalars['ObjectId']>;
  profileId?: Maybe<Scalars['ObjectId']>;
};

export type MutationCreateMediaKitConfigArgs = {
  updates?: Maybe<MediaKitConfigUpdates>;
};

export type MutationUpdateMediaKitConfigArgs = {
  id: Scalars['ObjectId'];
  updates?: Maybe<MediaKitConfigUpdates>;
};

export type MutationUpdateMediaKitDraftConfigArgs = {
  id: Scalars['ObjectId'];
  updates?: Maybe<MediaKitConfigUpdates>;
};

export type MutationPublishMediaKitDraftConfigArgs = {
  id: Scalars['ObjectId'];
};

export type MutationDeleteMediaKitConfigArgs = {
  id: Scalars['ObjectId'];
};

export type MutationAdminClearOrphanedElementsArgs = {
  shouldDelete?: Maybe<Scalars['Boolean']>;
};

export type MutationCreateElementArgs = {
  type?: Maybe<ElementType>;
  title?: Maybe<Scalars['String']>;
  mediaKitId?: Maybe<Scalars['ObjectId']>;
  index?: Maybe<Scalars['Int']>;
};

export type MutationUpdateElementArgs = {
  id: Scalars['ObjectId'];
  content?: Maybe<MediaKitElementContentInput>;
};

export type MutationUpdateElementDraftArgs = {
  id: Scalars['ObjectId'];
  content?: Maybe<MediaKitElementContentInput>;
};

export type MutationDeleteElementArgs = {
  id: Scalars['ObjectId'];
};

export type MutationUpdateNotificationReadStatusArgs = {
  notificationId?: Maybe<Scalars['ObjectId']>;
};

export type MutationAdminUpdatePersistedUserArgs = {
  userId?: Maybe<Scalars['ObjectId']>;
  userUpdates?: Maybe<PersistedUserUpdates>;
};

export type MutationUpdateProfileNoteArgs = {
  accountId?: Maybe<Scalars['String']>;
  updates?: Maybe<ProfileNoteInput>;
};

export type MutationCreateProfileArgs = {
  info: NewProfileInfo;
};

export type MutationUpdateProfileArgs = {
  updates: ProfileUpdates;
  profileId: Scalars['ObjectId'];
};

export type MutationUnassignAmbassadorFromProfileArgs = {
  profileId: Scalars['ObjectId'];
};

export type MutationDeleteProfileArgs = {
  pageId?: Maybe<Scalars['String']>;
  profileId: Scalars['ObjectId'];
};

export type MutationAdminUpdateProfileArgs = {
  updates?: Maybe<ProfileUpdates>;
  profileId?: Maybe<Scalars['ObjectId']>;
};

export type MutationAdminDeleteProfileArgs = {
  pageId?: Maybe<Scalars['String']>;
  profileId: Scalars['ObjectId'];
};

export type MutationAdminCreateEternalLinkArgs = {
  profileId?: Maybe<Scalars['String']>;
};

export type MutationLogReceivedPushNotificationArgs = {
  content?: Maybe<Scalars['String']>;
};

export type MutationUpdatePushNotificationTokenArgs = {
  pushNotificationTokenInput?: Maybe<PushNotificationTokenInput>;
};

export type MutationAddRecentArgs = {
  accountId?: Maybe<Scalars['String']>;
};

export type MutationCreateSupplementMediaArgs = {
  input?: Maybe<SupplementMediaInput>;
};

export type MutationInsertSupplementMediaListArgs = {
  input?: Maybe<Array<Maybe<SupplementMediaInput>>>;
};

export type MutationUpdateSupplementMediaArgs = {
  id: Scalars['ObjectId'];
  updates?: Maybe<SupplementMediaInput>;
};

export type MutationDeleteSupplementMediaArgs = {
  id: Scalars['ObjectId'];
};

export type MutationCreateReportConfigArgs = {
  config?: Maybe<ReportConfigInput>;
  trackingLinks?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  supplementMedia?: Maybe<Array<Maybe<SupplementMediaInput>>>;
};

export type MutationUpdateReportConfigArgs = {
  reportId?: Maybe<Scalars['ObjectId']>;
  config?: Maybe<ReportConfigInput>;
};

export type MutationDeleteReportConfigArgs = {
  reportId?: Maybe<Scalars['ObjectId']>;
};

export type MutationDeleteMultipleReportConfigsArgs = {
  reportIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
};

export type MutationApproveTrackingRequestArgs = {
  token?: Maybe<Scalars['String']>;
};

export type MutationCreateReportLinkArgs = {
  reportConfigId?: Maybe<Scalars['ObjectId']>;
};

export type MutationShareReportToContactArgs = {
  reportConfigId?: Maybe<Scalars['ObjectId']>;
  contactId?: Maybe<Scalars['ObjectId']>;
  contactIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  contactDetails?: Maybe<ReportShareContact>;
};

export type MutationHideSentEmailRecordArgs = {
  id: Scalars['ObjectId'];
};

export type MutationResendEmailInviteArgs = {
  id: Scalars['ObjectId'];
};

export type MutationSendEmailInviteArgs = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type MutationSendTalentNetworkContactEmailArgs = {
  emailPayload?: Maybe<TalentNetworkContactEmailPayload>;
};

export type MutationSendLandingPageRequestEmailArgs = {
  payload?: Maybe<LandingPageRequestEmailPayload>;
};

export type MutationDeleteStripePaymentMethodArgs = {
  id?: Maybe<Scalars['String']>;
};

export type MutationSetStripePaymentMethodToDefaultArgs = {
  id?: Maybe<Scalars['String']>;
};

export type MutationCreateTiktokProfileArgs = {
  username?: Maybe<Scalars['String']>;
};

export type MutationConnectTiktokProfileArgs = {
  profileId?: Maybe<Scalars['String']>;
};

export type MutationDisconnectTiktokProfileArgs = {
  profileId?: Maybe<Scalars['String']>;
};

export type MutationCreateTrackingLinkArgs = {
  updates?: Maybe<TrackingLinkInput>;
};

export type MutationDeleteTrackingLinkArgs = {
  trackingLinkId?: Maybe<Scalars['ObjectId']>;
};

export type MutationLoginArgs = {
  input?: Maybe<UserLoginInput>;
};

export type MutationUpdateUserArgs = {
  updates?: Maybe<UserUpdates>;
};

export type MutationUpdateWalkthroughsArgs = {
  update?: Maybe<Scalars['String']>;
};

export type MutationVeryfyUserEmailArgs = {
  token?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type MutationSendVerificationCodeToPhoneArgs = {
  phoneNumber?: Maybe<Scalars['String']>;
};

export type MutationResendEmailVerificationCodeArgs = {
  shouldUpdateUserEmail?: Maybe<Scalars['Boolean']>;
  alternateEmail?: Maybe<Scalars['String']>;
};

export type MutationAdminDeleteUserArgs = {
  userId?: Maybe<Scalars['ObjectId']>;
};

export type MutationAdminUpdateUserArgs = {
  updates?: Maybe<UserUpdates>;
  userId?: Maybe<Scalars['ObjectId']>;
};

export type MutationAdminRefreshSubscriptionsArgs = {
  userId?: Maybe<Scalars['ObjectId']>;
  logging?: Maybe<Scalars['Boolean']>;
  shouldCheckAll?: Maybe<Scalars['Boolean']>;
};

export type MutationUpdateAccountProgressArgs = {
  steps?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationUpdateBrandAmbassadorCategoryArgs = {
  ambassadorCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationInviteBrandArgs = {
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name?: Maybe<Scalars['String']>;
};

export type NewProfileInfo = {
  id?: Maybe<Scalars['String']>;
  profileType?: Maybe<SocialChannel>;
  name?: Maybe<Scalars['String']>;
  channel?: Maybe<YoutubeChannelInput>;
  thumbnails?: Maybe<Scalars['JSObject']>;
  picture?: Maybe<Scalars['JSObject']>;
  instagram_business_account?: Maybe<InstagramAccountInput>;
  profileCategory?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Page_Negative_Feedback_By_Type = {
  __typename?: 'PAGE_NEGATIVE_FEEDBACK_BY_TYPE';
  hide_clicks?: Maybe<Scalars['Int']>;
  hide_all_clicks?: Maybe<Scalars['Int']>;
  report_spam_clicks?: Maybe<Scalars['Int']>;
  unlike_page_clicks?: Maybe<Scalars['Int']>;
};

export type Page_Positive_Feedback_By_Type = {
  __typename?: 'PAGE_POSITIVE_FEEDBACK_BY_TYPE';
  answer?: Maybe<Scalars['Int']>;
  claim?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['Int']>;
  like?: Maybe<Scalars['Int']>;
  link?: Maybe<Scalars['Int']>;
  other?: Maybe<Scalars['Int']>;
  rsvp?: Maybe<Scalars['Int']>;
};

export type PendingNotification = {
  __typename?: 'PendingNotification';
  _id?: Maybe<Scalars['ObjectId']>;
  userId?: Maybe<Scalars['ObjectId']>;
  userIdList?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  type?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  expireAt?: Maybe<Scalars['Date']>;
  created_at?: Maybe<Scalars['Date']>;
  reportConfigId?: Maybe<Scalars['ObjectId']>;
  mediaKitConfigId?: Maybe<Scalars['ObjectId']>;
  collaborationId?: Maybe<Scalars['ObjectId']>;
  delayHours?: Maybe<Scalars['Int']>;
};

export type PerformanceByCategory = {
  __typename?: 'PerformanceByCategory';
  ads?: Maybe<PerformanceRanking>;
  all?: Maybe<PerformanceRanking>;
};

export type PerformanceRanking = {
  __typename?: 'PerformanceRanking';
  value?: Maybe<Scalars['Float']>;
  average?: Maybe<Scalars['Float']>;
  comparisonBracket?: Maybe<Scalars['Int']>;
  rank?: Maybe<Scalars['Int']>;
  percentile?: Maybe<Scalars['Int']>;
};

export type PerformanceReport = {
  __typename?: 'PerformanceReport';
  _id: Scalars['ObjectId'];
  date?: Maybe<Scalars['Date']>;
  profileId?: Maybe<Scalars['String']>;
  followerBracket?: Maybe<Scalars['Int']>;
  comparisonBracket?: Maybe<Scalars['Int']>;
  performance?: Maybe<PerformanceRanking>;
  mediaPerformance?: Maybe<MediaPerformance>;
  mediaCounts?: Maybe<MediaCounts>;
  trends?: Maybe<InstagramCombinedAverages>;
  /** @deprecated Use instagramFeedTrends or instagramReelTrends */
  mediaTrends?: Maybe<InstagramMediaAverages>;
  instagramFeedTrends?: Maybe<InstagramMediaAverages>;
  instagramReelTrends?: Maybe<InstagramMediaAverages>;
  storyTrends?: Maybe<InstagramStoryAverages>;
  isLimited?: Maybe<Scalars['Boolean']>;
  isViewed?: Maybe<Scalars['Boolean']>;
  imageUrl?: Maybe<Scalars['String']>;
  incomingMentions?: Maybe<Array<PerformanceReportMention>>;
  startingStats?: Maybe<InstagramHistorical>;
  endingStats?: Maybe<InstagramHistorical>;
  profile?: Maybe<Profile>;
  lastReport?: Maybe<PerformanceReport>;
  instagramMedia?: Maybe<Array<InstagramMedia>>;
  instagramFeed?: Maybe<Array<InstagramMedia>>;
  instagramReels?: Maybe<Array<InstagramMedia>>;
  instagramStories?: Maybe<Array<InstagramStory>>;
};

export type PerformanceReportMention = {
  __typename?: 'PerformanceReportMention';
  displayName?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Float']>;
};

export type PersistedUser = {
  __typename?: 'PersistedUser';
  _id?: Maybe<Scalars['ObjectId']>;
  email?: Maybe<Scalars['String']>;
  signupDate?: Maybe<Scalars['Date']>;
  deletedDate?: Maybe<Scalars['Date']>;
  deletedBy?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  deleteReason?: Maybe<Scalars['String']>;
};

export type PersistedUserUpdates = {
  notes?: Maybe<Scalars['String']>;
};

export type PlanStat = {
  __typename?: 'PlanStat';
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
};

export type PostsForHashtagResponse = {
  __typename?: 'PostsForHashtagResponse';
  posts?: Maybe<Array<Maybe<TiktokMedia>>>;
  userList?: Maybe<Array<Maybe<TiktokUser>>>;
};

export type Profile = {
  __typename?: 'Profile';
  _id: Scalars['ObjectId'];
  isAddedByOwner?: Maybe<Scalars['Boolean']>;
  agentId?: Maybe<Scalars['String']>;
  biography?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** @deprecated Use cacheError */
  cache_error?: Maybe<Scalars['String']>;
  cache_completed_at?: Maybe<Scalars['Date']>;
  created_at?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  historical_from?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  pageId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  media_cached_at?: Maybe<Scalars['Date']>;
  profileCategory?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use 'profileCategory'. */
  profile_category?: Maybe<Array<Maybe<Scalars['String']>>>;
  profileType?: Maybe<SocialChannel>;
  /** @deprecated Use 'profileType'. */
  profile_type?: Maybe<Scalars['String']>;
  profile_cached_at?: Maybe<Scalars['Date']>;
  profile_media_kit?: Maybe<MediaKit>;
  profile_picture_url?: Maybe<Scalars['String']>;
  redflags_from?: Maybe<Scalars['Date']>;
  redflags_until?: Maybe<Scalars['Date']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['Date']>;
  userId?: Maybe<Scalars['String']>;
  isRestricted?: Maybe<Scalars['Boolean']>;
  isPendingConnection?: Maybe<Scalars['Boolean']>;
  hasAcceptedInvite?: Maybe<Scalars['Boolean']>;
  doesRequireTrackApproval?: Maybe<Scalars['Boolean']>;
  selectedMediaKitId?: Maybe<Scalars['ObjectId']>;
  profileAuditReportLink?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  media_count?: Maybe<Scalars['Float']>;
  followers_count?: Maybe<Scalars['Int']>;
  channelStats?: Maybe<ChannelStats>;
  fan_count?: Maybe<Scalars['Int']>;
  published_post_count?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['String']>;
  union_id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  profile_cache_attempts?: Maybe<Scalars['Int']>;
  media_cache_attempts?: Maybe<Scalars['Int']>;
  last_cache_attempts?: Maybe<Scalars['Int']>;
  cache_error_at?: Maybe<Scalars['Date']>;
  cache_error_resolved_at?: Maybe<Scalars['Date']>;
  last_cache_error?: Maybe<Scalars['String']>;
  user_notified_at?: Maybe<Scalars['Date']>;
  agency_notified_at?: Maybe<Scalars['Date']>;
  historical_until?: Maybe<Scalars['Date']>;
  assignedToAmbassadorId?: Maybe<Scalars['ObjectId']>;
  profile_added_at?: Maybe<Scalars['Date']>;
  ownerNotes?: Maybe<Scalars['String']>;
  ownerTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  isInstagramIncomingMentionsEnabled?: Maybe<Scalars['Boolean']>;
  isInstagramFeedWebhookEnabled?: Maybe<Scalars['Boolean']>;
  pageStats?: Maybe<FacebookPageStats>;
  postStats?: Maybe<FacebookPostStats>;
  historicalFacebookStats?: Maybe<Array<FacebookPageStats>>;
  weeklyFacebookStats?: Maybe<Array<FacebookWeeklyStats>>;
  rankedFacebookMedia?: Maybe<RankedFacebookMedia>;
  latestFacebookPosts?: Maybe<Array<FacebookPost>>;
  facebookPostTrends?: Maybe<FacebookMediaTrends>;
  recentFacebookMediaStats?: Maybe<RecentMediaStats>;
  lifetimeInsights?: Maybe<LifetimeInsights>;
  historicalStats?: Maybe<InstagramHistoricalStats>;
  onlineFollowers?: Maybe<Scalars['JSObject']>;
  /** @deprecated Use rankedInstagramMedia.topMedia instead */
  rankedInstagramPosts?: Maybe<Array<InstagramMedia>>;
  rankedInstagramMedia?: Maybe<RankedInstagramMedia>;
  rankedInstagramFeed?: Maybe<RankedInstagramMedia>;
  rankedInstagramReels?: Maybe<RankedInstagramMedia>;
  latestInstagramPosts?: Maybe<Array<InstagramMedia>>;
  latestInstagramFeed?: Maybe<Array<InstagramMedia>>;
  latestInstagramReels?: Maybe<Array<InstagramMedia>>;
  latestInstagramStories?: Maybe<Array<InstagramStory>>;
  dailyInstagramStats?: Maybe<Array<InstagramHistorical>>;
  weeklyInstagramStats?: Maybe<Array<InstagramHistorical>>;
  mediaStats?: Maybe<MediaStats>;
  instagramFeedStats?: Maybe<MediaStats>;
  instagramReelStats?: Maybe<MediaStats>;
  storyStats?: Maybe<MediaStats>;
  instagramFeedTrends?: Maybe<InstagramMediaTrends>;
  instagramReelTrends?: Maybe<InstagramMediaTrends>;
  instagramStoryTrends?: Maybe<InstagramStoryTrends>;
  instagramCombinedTrends?: Maybe<InstagramCombinedTrends>;
  followerBracket?: Maybe<Scalars['Int']>;
  latestPerformanceReport?: Maybe<PerformanceReport>;
  instagramMediaMentions?: Maybe<Array<InstagramMedia>>;
  recentInstagramMediaStats?: Maybe<RecentInstagramMediaStats>;
  subscribedWebhookFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  cacheError?: Maybe<Scalars['String']>;
  userFriendlyCacheError?: Maybe<UserFriendlyCacheError>;
  user?: Maybe<User>;
  agent?: Maybe<User>;
  cacheSeconds?: Maybe<Scalars['Int']>;
  mediaKitConfigs?: Maybe<Array<Maybe<MediaKitConfig>>>;
  ownerMediaKitConfigs?: Maybe<Array<MediaKitConfig>>;
  profileNote?: Maybe<ProfileNote>;
  dataAccessToken?: Maybe<Scalars['String']>;
  followerCount?: Maybe<Scalars['Int']>;
  selectedMediaKit?: Maybe<MediaKitConfig>;
  isVisibleOnNetwork?: Maybe<Scalars['Boolean']>;
  mediaKits?: Maybe<Array<MediaKitConfig>>;
  thumbnails?: Maybe<Thumbnails>;
  title?: Maybe<Scalars['String']>;
  videoStats?: Maybe<YoutubeMediaStats>;
  snippet?: Maybe<Scalars['String']>;
  ageGroupBreakdown?: Maybe<Array<AgeGroup>>;
  genderBreakdown?: Maybe<Array<GenderGroup>>;
  locationBreakdown?: Maybe<Array<LocationGroup>>;
  myChannelReport?: Maybe<Array<ChannelReport>>;
  subscriberChange?: Maybe<Array<SubscriberChange>>;
  youtubeMedia?: Maybe<Array<YoutubeMedia>>;
  youtubeHistorical?: Maybe<Array<YoutubeHistorical>>;
  youtubeWeeklyStats?: Maybe<Array<YoutubeHistoricalStats>>;
  rankedYoutubeMedia?: Maybe<RankedYoutubeMedia>;
  youtubeVideoTrends?: Maybe<YoutubeMediaTrends>;
  recentYoutubeMediaStats?: Maybe<RecentMediaStats>;
  public?: Maybe<TiktokPublicUser>;
  tiktokMediaStats?: Maybe<TiktokMediaStats>;
  tiktokMediaTrends?: Maybe<TiktokMediaTrends>;
  rankedTiktokMedia?: Maybe<TikTokRankedMedia>;
  recentTiktokMediaStats?: Maybe<RecentMediaStats>;
};

export type ProfileWeeklyFacebookStatsArgs = {
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type ProfileWeeklyInstagramStatsArgs = {
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type ProfileYoutubeWeeklyStatsArgs = {
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type ProfileBrowseFilters = {
  channels?: Maybe<Array<Maybe<SocialChannel>>>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  followers?: Maybe<FloatRange>;
  trueReach?: Maybe<FloatRange>;
  engagementPercentage?: Maybe<FloatRange>;
  talentType?: Maybe<Scalars['String']>;
  agencyUserId?: Maybe<Scalars['ObjectId']>;
  timezone?: Maybe<Scalars['String']>;
  timezoneList?: Maybe<Array<Maybe<Scalars['String']>>>;
  profileCountries?: Maybe<Array<Maybe<Scalars['String']>>>;
  usernames?: Maybe<Array<Maybe<Scalars['String']>>>;
  handles?: Maybe<Array<Maybe<Scalars['String']>>>;
  hashtags?: Maybe<Array<Maybe<Scalars['String']>>>;
  country?: Maybe<DemographicFilter>;
  age?: Maybe<DemographicFilter>;
  gender?: Maybe<DemographicFilter>;
};

export type ProfileBrowseResult = {
  __typename?: 'ProfileBrowseResult';
  profile?: Maybe<Profile>;
  profileNoteList?: Maybe<Array<Maybe<ProfileNote>>>;
  selectedMediaKitId?: Maybe<Scalars['ObjectId']>;
  selectedMediaKit?: Maybe<MediaKitConfig>;
};

export type ProfileCacheError = {
  __typename?: 'ProfileCacheError';
  _id?: Maybe<Scalars['ObjectId']>;
  profileId?: Maybe<Scalars['String']>;
  cache_error_at?: Maybe<Scalars['Date']>;
  cache_error_resolved_at?: Maybe<Scalars['Date']>;
  cache_error?: Maybe<Scalars['String']>;
  user_notified_at?: Maybe<Scalars['Date']>;
  agency_notified_at?: Maybe<Scalars['Date']>;
  profile?: Maybe<Profile>;
};

export type ProfileForCode = {
  __typename?: 'ProfileForCode';
  profileId?: Maybe<Scalars['ObjectId']>;
  dataAccessToken?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ObjectId']>;
  profile?: Maybe<Profile>;
  user?: Maybe<User>;
};

export type ProfileListResponse = {
  __typename?: 'ProfileListResponse';
  list?: Maybe<Array<Maybe<Profile>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ProfileNote = {
  __typename?: 'ProfileNote';
  _id: Scalars['ObjectId'];
  accountId?: Maybe<Scalars['String']>;
  profile_category?: Maybe<Array<Maybe<Scalars['String']>>>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  notes?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ObjectId']>;
  selectedMediaKitId?: Maybe<Scalars['ObjectId']>;
  noteAuditReportLink?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  profile?: Maybe<Profile>;
  selectedMediaKit?: Maybe<MediaKitConfig>;
};

export type ProfileNoteInput = {
  notes?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  profile_category?: Maybe<Array<Maybe<Scalars['String']>>>;
  selectedMediaKitId?: Maybe<Scalars['ObjectId']>;
};

export type ProfileNotification = {
  __typename?: 'ProfileNotification';
  _id: Scalars['ObjectId'];
  text?: Maybe<Scalars['String']>;
  isRead?: Maybe<Scalars['Boolean']>;
  recipient?: Maybe<Scalars['ObjectId']>;
};

export type ProfileUpdates = {
  agentId?: Maybe<Scalars['String']>;
  profileCategory?: Maybe<Array<Maybe<Scalars['String']>>>;
  selectedMediaKitId?: Maybe<Scalars['ObjectId']>;
  assignedToAmbassadorId?: Maybe<Scalars['ObjectId']>;
  ownerNotes?: Maybe<Scalars['String']>;
  ownerTags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PushNotificationToken = {
  __typename?: 'PushNotificationToken';
  _id: Scalars['ObjectId'];
  type?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ObjectId']>;
};

export type PushNotificationTokenInput = {
  type?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ObjectId']>;
};

export type Query = {
  __typename?: 'Query';
  getDeliverableList?: Maybe<Array<Maybe<Deliverable>>>;
  getAmbassadorForId?: Maybe<Ambassador>;
  getAmbassadorList: Array<Ambassador>;
  getAmbassadorProfiles: Array<Profile>;
  getAmbassadorInvitations: Array<User>;
  adminGetAmbassadorList: Array<Ambassador>;
  getAmbassadorListByUserId: Array<Ambassador>;
  getAmbassadorActivity: Array<AmbassadorActivity>;
  getDataAccessForAmbassador?: Maybe<UserForCode>;
  adminGetSubscriptionList: Array<BraintreeSubscription>;
  adminGetCustomerList: Array<BraintreeCustomer>;
  adminGetTransactionList: Array<BraintreeTransaction>;
  adminGetSubscriptionGrowth: Array<SubscriptionsAtDate>;
  adminGetAllCollaborations: Array<Collaboration>;
  getCollaborationList: Array<Collaboration>;
  getCollaborationTotals: CollaborationTotalsResponse;
  getContactList: Array<Contact>;
  getPlatformAd?: Maybe<ContentfulPlatformAd>;
  getCoupons: Array<CouponCode>;
  adminGetCouponList: Array<CouponCode>;
  getDelegateList: Array<Delegate>;
  getDelegate?: Maybe<Delegate>;
  getFacebookPages: Array<FacebookPageInfo>;
  adminGetPlansList: Array<InfluencerPlan>;
  searchUsername?: Maybe<InstagramProfile>;
  getMediaForId: Array<InstagramMedia>;
  getStoriesForDate: Array<InstagramStory>;
  getStoriesForMonth: StoriesForMonthResponse;
  adminGetAllInvoices: Array<CollaborationInvoice>;
  viewCollaborationInvoice?: Maybe<CollaborationInvoice>;
  getCollaborationInvoices: Array<CollaborationInvoice>;
  getCollaborationInvoiceTotals?: Maybe<CollaborationInvoiceTotalsResponse>;
  getMediaKitConfig?: Maybe<MediaKitConfig>;
  getMediaKitConfigList: Array<MediaKitConfig>;
  getElement?: Maybe<MediaKitElement>;
  getElementList: Array<MediaKitElement>;
  getMediaKitFromToken?: Maybe<MediaKitLink>;
  getMediaKitLink?: Maybe<MediaKitLink>;
  getMediaKitLinkList: Array<MediaKitLink>;
  getMediaKitAccessForCode: MediaKitLinkForCode;
  getNotification?: Maybe<ProfileNotification>;
  getPerformanceReportForId?: Maybe<PerformanceReport>;
  viewPerformanceReport?: Maybe<PerformanceReport>;
  adminGetDeletedUserList: Array<PersistedUser>;
  getDataAccessForProfileNote?: Maybe<ProfileForCode>;
  getSingleProfile: Profile;
  getProfileForId: Profile;
  getMultipleProfiles: Array<Profile>;
  getProfiles: Array<Profile>;
  getProfilesByUserIds: Array<Profile>;
  searchProfiles: Array<Scalars['String']>;
  searchAllProfiles: Array<Profile>;
  getProfileList: ProfileListResponse;
  adminGetProfileList?: Maybe<Array<Profile>>;
  browseProfiles: Array<ProfileBrowseResult>;
  getDataAccessForProfile?: Maybe<ProfileForCode>;
  adminProfilesInfo: AdminProfileInfo;
  getRecents?: Maybe<Array<Maybe<RecentProfile>>>;
  getReportForId?: Maybe<ReportConfig>;
  getReportConfigList: Array<ReportConfig>;
  getMediaForReportId?: Maybe<MediaForConfig>;
  adminQueryReports: Array<ReportConfig>;
  getReportFromToken?: Maybe<ReportLink>;
  getReportLink?: Maybe<ReportLink>;
  getReportLinkList: Array<ReportLink>;
  getSentInvites: Array<SentEmail>;
  getConnectedAccountLink?: Maybe<StripeAccountLink>;
  getConnectedAccount?: Maybe<StripeConnectedAccount>;
  getSupplementMediaForReport: Array<SupplementMedia>;
  getTiktokProfiles: Array<TiktokProfile>;
  getTiktokMediaForId: Array<TiktokMedia>;
  getTiktokHistoricalForId: Array<TiktokHistorical>;
  getWeeklyTiktokStats: Array<TiktokHistoricalAverages>;
  getTrackingLinkList: Array<TrackingLink>;
  getTrackingLinksForIds: Array<TrackingLink>;
  getRewards: Array<UserReward>;
  getLatestAppUpdate?: Maybe<AppUpdate>;
  getUserById: User;
  getUserList: Array<User>;
  myUser?: Maybe<User>;
  getListOfAgencies: Array<User>;
  adminGetPayingUsers: UserListResponse;
  adminGetUserList: UserListResponse;
  adminGenerateDailyUpdate: ServerResponse;
  getDataAccessForUser?: Maybe<UserForCode>;
  recentYoutubeMedia: RecentYoutubeMediaResult;
  youtubeMediaByViews?: Maybe<YoutubeMediaListResult>;
  youtubeMediaHistorical: YoutubeMediaHistoryResult;
  listYoutubeChannels: Array<YoutubeChannel>;
  listYoutubeProfiles: Array<YoutubeProfile>;
};

export type QueryGetDeliverableListArgs = {
  id?: Maybe<Scalars['ObjectId']>;
  socialChannel?: Maybe<SocialChannel>;
  socialDeliverable?: Maybe<SocialDeliverable>;
};

export type QueryGetAmbassadorForIdArgs = {
  id: Scalars['ObjectId'];
};

export type QueryGetAmbassadorActivityArgs = {
  since?: Maybe<Scalars['Date']>;
  activityType?: Maybe<AmbassadorActivityType>;
};

export type QueryGetDataAccessForAmbassadorArgs = {
  code?: Maybe<Scalars['String']>;
};

export type QueryAdminGetSubscriptionListArgs = {
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
};

export type QueryAdminGetSubscriptionGrowthArgs = {
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
};

export type QueryGetCollaborationListArgs = {
  id?: Maybe<Scalars['ObjectId']>;
  status?: Maybe<CollaborationStatus>;
  canEdit?: Maybe<Scalars['Boolean']>;
};

export type QueryGetCollaborationTotalsArgs = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};

export type QueryGetContactListArgs = {
  id?: Maybe<Scalars['ObjectId']>;
  type?: Maybe<ContactType>;
};

export type QueryGetPlatformAdArgs = {
  params?: Maybe<ContentfulPlatformAdParams>;
};

export type QueryGetDelegateArgs = {
  id?: Maybe<Scalars['ObjectId']>;
};

export type QuerySearchUsernameArgs = {
  username?: Maybe<Scalars['String']>;
};

export type QueryGetMediaForIdArgs = {
  profileId?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryGetStoriesForDateArgs = {
  profileId?: Maybe<Scalars['ObjectId']>;
  date?: Maybe<Scalars['Date']>;
};

export type QueryGetStoriesForMonthArgs = {
  profileId?: Maybe<Scalars['ObjectId']>;
  date?: Maybe<Scalars['Date']>;
};

export type QueryViewCollaborationInvoiceArgs = {
  id: Scalars['ObjectId'];
};

export type QueryGetCollaborationInvoicesArgs = {
  id?: Maybe<Scalars['ObjectId']>;
  collaborationId?: Maybe<Scalars['ObjectId']>;
};

export type QueryGetCollaborationInvoiceTotalsArgs = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};

export type QueryGetMediaKitConfigArgs = {
  id: Scalars['ObjectId'];
};

export type QueryGetElementArgs = {
  id: Scalars['ObjectId'];
};

export type QueryGetElementListArgs = {
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
};

export type QueryGetMediaKitFromTokenArgs = {
  token?: Maybe<Scalars['String']>;
};

export type QueryGetMediaKitLinkArgs = {
  id: Scalars['ObjectId'];
};

export type QueryGetMediaKitAccessForCodeArgs = {
  code?: Maybe<Scalars['String']>;
};

export type QueryGetNotificationArgs = {
  recipientId?: Maybe<Scalars['String']>;
  trigger?: Maybe<Scalars['String']>;
};

export type QueryGetPerformanceReportForIdArgs = {
  id: Scalars['ObjectId'];
};

export type QueryViewPerformanceReportArgs = {
  id: Scalars['ObjectId'];
};

export type QueryGetDataAccessForProfileNoteArgs = {
  code?: Maybe<Scalars['String']>;
};

export type QueryGetSingleProfileArgs = {
  username?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['ObjectId']>;
};

export type QueryGetProfileForIdArgs = {
  id: Scalars['String'];
};

export type QueryGetMultipleProfilesArgs = {
  _ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
};

export type QueryGetProfilesArgs = {
  profileTypeList?: Maybe<Array<Maybe<Scalars['String']>>>;
  profileType?: Maybe<Scalars['String']>;
};

export type QueryGetProfilesByUserIdsArgs = {
  _ids?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QuerySearchProfilesArgs = {
  username?: Maybe<Scalars['String']>;
};

export type QuerySearchAllProfilesArgs = {
  query?: Maybe<Scalars['String']>;
};

export type QueryGetProfileListArgs = {
  pageNumber?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  query?: Maybe<InfluencerQuery>;
};

export type QueryAdminGetProfileListArgs = {
  type?: Maybe<SocialChannel>;
  query?: Maybe<Scalars['String']>;
};

export type QueryBrowseProfilesArgs = {
  filters?: Maybe<ProfileBrowseFilters>;
};

export type QueryGetDataAccessForProfileArgs = {
  code?: Maybe<Scalars['String']>;
};

export type QueryAdminProfilesInfoArgs = {
  limit?: Maybe<Scalars['Float']>;
};

export type QueryGetReportForIdArgs = {
  id: Scalars['ObjectId'];
};

export type QueryGetReportConfigListArgs = {
  id?: Maybe<Scalars['ObjectId']>;
};

export type QueryGetMediaForReportIdArgs = {
  id: Scalars['ObjectId'];
};

export type QueryGetReportFromTokenArgs = {
  token?: Maybe<Scalars['String']>;
  handleFilter?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QueryGetReportLinkArgs = {
  id: Scalars['ObjectId'];
};

export type QueryGetConnectedAccountLinkArgs = {
  returnPath?: Maybe<Scalars['String']>;
};

export type QueryGetSupplementMediaForReportArgs = {
  reportConfigId?: Maybe<Scalars['ObjectId']>;
};

export type QueryGetTiktokMediaForIdArgs = {
  id: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
};

export type QueryGetTiktokHistoricalForIdArgs = {
  id: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
};

export type QueryGetWeeklyTiktokStatsArgs = {
  id: Scalars['String'];
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
};

export type QueryGetTrackingLinkListArgs = {
  type?: Maybe<TrackingLinkType>;
  target?: Maybe<Scalars['String']>;
};

export type QueryGetTrackingLinksForIdsArgs = {
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
};

export type QueryGetLatestAppUpdateArgs = {
  version?: Maybe<Scalars['String']>;
};

export type QueryGetUserByIdArgs = {
  id?: Maybe<Scalars['ObjectId']>;
};

export type QueryGetUserListArgs = {
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
};

export type QueryAdminGetPayingUsersArgs = {
  type?: Maybe<UserType>;
};

export type QueryAdminGetUserListArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  pageNo?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
  type?: Maybe<UserType>;
  sortField?: Maybe<SortField>;
  filter?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  profileQuery?: Maybe<Scalars['String']>;
  hasFilterOutInternal?: Maybe<Scalars['Boolean']>;
  onlyFacebookVerified?: Maybe<Scalars['Boolean']>;
};

export type QueryAdminGenerateDailyUpdateArgs = {
  recipientEmail?: Maybe<Scalars['String']>;
};

export type QueryGetDataAccessForUserArgs = {
  code?: Maybe<Scalars['String']>;
};

export type QueryRecentYoutubeMediaArgs = {
  id?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
  pageNumber?: Maybe<Scalars['Int']>;
  asc?: Maybe<Scalars['Boolean']>;
};

export type QueryYoutubeMediaByViewsArgs = {
  id?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
  pageNumber?: Maybe<Scalars['Int']>;
  asc?: Maybe<Scalars['Boolean']>;
};

export type QueryYoutubeMediaHistoricalArgs = {
  mediaId?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
};

export type QueryListYoutubeChannelsArgs = {
  accessToken?: Maybe<Scalars['String']>;
};

export type RankedFacebookMedia = {
  __typename?: 'RankedFacebookMedia';
  topMedia?: Maybe<Array<Maybe<FacebookPost>>>;
  worstMedia?: Maybe<Array<Maybe<FacebookPost>>>;
};

export type RankedInstagramMedia = {
  __typename?: 'RankedInstagramMedia';
  topMedia?: Maybe<Array<Maybe<InstagramMedia>>>;
  worstMedia?: Maybe<Array<Maybe<InstagramMedia>>>;
};

export type RankedYoutubeMedia = {
  __typename?: 'RankedYoutubeMedia';
  topMedia?: Maybe<Array<Maybe<YoutubeMedia>>>;
  worstMedia?: Maybe<Array<Maybe<YoutubeMedia>>>;
};

export type RateField = {
  __typename?: 'RateField';
  heading?: Maybe<Scalars['String']>;
  rates?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RateInput = {
  heading?: Maybe<Scalars['String']>;
  rates?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RatesContent = {
  __typename?: 'RatesContent';
  type?: Maybe<ElementType>;
  rate_content?: Maybe<Array<Maybe<RateField>>>;
};

export type RecentInstagramMediaStats = {
  __typename?: 'RecentInstagramMediaStats';
  grid?: Maybe<RecentMediaStats>;
  reels?: Maybe<RecentMediaStats>;
  stories?: Maybe<RecentMediaStats>;
};

export type RecentMediaStats = {
  __typename?: 'RecentMediaStats';
  last30Days?: Maybe<Array<RecentMediaSummary>>;
  last14Days?: Maybe<Array<RecentMediaSummary>>;
  last7Days?: Maybe<Array<RecentMediaSummary>>;
};

export type RecentMediaSummary = {
  __typename?: 'RecentMediaSummary';
  key?: Maybe<Scalars['String']>;
  median?: Maybe<Scalars['Float']>;
};

export type RecentProfile = {
  __typename?: 'RecentProfile';
  _id: Scalars['ObjectId'];
  userId?: Maybe<Scalars['ObjectId']>;
  accountId?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
  profile?: Maybe<Profile>;
};

export type RecentYoutubeMediaResult = {
  __typename?: 'RecentYoutubeMediaResult';
  mediaCount?: Maybe<Scalars['Int']>;
  youtubeMedia?: Maybe<Array<Maybe<YoutubeMedia>>>;
  youtubeMediaAverages?: Maybe<YoutubeMediaStats>;
};

export type RegisterAmbassadorInput = {
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  detected_timezone?: Maybe<Scalars['String']>;
  marketingOptIn?: Maybe<Scalars['Boolean']>;
};

export type ReportConfig = {
  __typename?: 'ReportConfig';
  _id: Scalars['ObjectId'];
  userId?: Maybe<Scalars['ObjectId']>;
  /** @deprecated Use reportName. */
  report_name?: Maybe<Scalars['String']>;
  reportName?: Maybe<Scalars['String']>;
  /** @deprecated Use reportStart. */
  report_start?: Maybe<Scalars['Date']>;
  reportStart?: Maybe<Scalars['Date']>;
  /** @deprecated Use reportEnd. */
  report_end?: Maybe<Scalars['Date']>;
  reportEnd?: Maybe<Scalars['Date']>;
  channels?: Maybe<Array<Maybe<SocialChannel>>>;
  profileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  profileTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use brandHandle. */
  brand_handle?: Maybe<Array<Maybe<Scalars['String']>>>;
  brandHandle?: Maybe<Array<Maybe<Scalars['String']>>>;
  handles?: Maybe<Array<Maybe<Scalars['String']>>>;
  mentions?: Maybe<Array<Maybe<Scalars['String']>>>;
  hashtags?: Maybe<Array<Maybe<Scalars['String']>>>;
  selected_media?: Maybe<Array<Maybe<Scalars['String']>>>;
  selected_stories?: Maybe<Array<Maybe<Scalars['String']>>>;
  selected_videos?: Maybe<Array<Maybe<Scalars['String']>>>;
  selected_posts?: Maybe<Array<Maybe<Scalars['String']>>>;
  selected_tiktok_media?: Maybe<Array<Maybe<Scalars['String']>>>;
  collaborationId?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  pdfLink?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
  bitlyActionByCountry?: Maybe<Scalars['JSObject']>;
  challengeName?: Maybe<Scalars['String']>;
  /** @deprecated Use instagram_feed_count or instagram_reel_count */
  media_count?: Maybe<Scalars['Int']>;
  instagram_feed_count?: Maybe<Scalars['Int']>;
  instagram_reel_count?: Maybe<Scalars['Int']>;
  story_count?: Maybe<Scalars['Int']>;
  video_count?: Maybe<Scalars['Int']>;
  post_count?: Maybe<Scalars['Int']>;
  tiktok_media_count?: Maybe<Scalars['Int']>;
  trackMediaFrom?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
  reportStartFormatted?: Maybe<Scalars['String']>;
  reportEndFormatted?: Maybe<Scalars['String']>;
  bitly?: Maybe<Scalars['JSObject']>;
  reportResults?: Maybe<ReportResults>;
  predictedInsights?: Maybe<Scalars['JSObject']>;
  /** @deprecated Use reportResults and choose subfields */
  results?: Maybe<Scalars['JSObject']>;
  profiles?: Maybe<Array<Profile>>;
  reportLinks?: Maybe<Array<ReportLink>>;
  client?: Maybe<Contact>;
};

export type ReportConfigBitlyArgs = {
  handleFilter?: Maybe<Array<Maybe<Scalars['String']>>>;
  profileIdFilter?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ReportConfigReportResultsArgs = {
  handleFilter?: Maybe<Array<Maybe<Scalars['String']>>>;
  profileIdFilter?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ReportConfigPredictedInsightsArgs = {
  handleFilter?: Maybe<Array<Maybe<Scalars['String']>>>;
  profileIdFilter?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ReportConfigResultsArgs = {
  handleFilter?: Maybe<Array<Maybe<Scalars['String']>>>;
  profileIdFilter?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ReportConfigInput = {
  report_name?: Maybe<Scalars['String']>;
  report_start?: Maybe<Scalars['Date']>;
  report_end?: Maybe<Scalars['Date']>;
  channels?: Maybe<Array<Maybe<SocialChannel>>>;
  profileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  profileTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  brand_handle?: Maybe<Array<Maybe<Scalars['String']>>>;
  handles?: Maybe<Array<Maybe<Scalars['String']>>>;
  mentions?: Maybe<Array<Maybe<Scalars['String']>>>;
  hashtags?: Maybe<Array<Maybe<Scalars['String']>>>;
  selected_media?: Maybe<Array<Maybe<Scalars['String']>>>;
  selected_stories?: Maybe<Array<Maybe<Scalars['String']>>>;
  selected_videos?: Maybe<Array<Maybe<Scalars['String']>>>;
  selected_posts?: Maybe<Array<Maybe<Scalars['String']>>>;
  selected_tiktok_media?: Maybe<Array<Maybe<Scalars['String']>>>;
  collaborationId?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  challengeName?: Maybe<Scalars['String']>;
  trackMediaFrom?: Maybe<Scalars['Date']>;
};

export type ReportInstagramMediaStats = {
  __typename?: 'ReportInstagramMediaStats';
  count?: Maybe<Scalars['Int']>;
  days?: Maybe<Scalars['Int']>;
  postsPerDay?: Maybe<Scalars['Float']>;
  like_count?: Maybe<ReportMetric>;
  comments_count?: Maybe<ReportMetric>;
  reach?: Maybe<ReportMetric>;
  impressions?: Maybe<ReportMetric>;
  engagement?: Maybe<ReportMetric>;
  saved?: Maybe<ReportMetric>;
  video_views?: Maybe<ReportMetric>;
  comments?: Maybe<ReportMetric>;
  likes?: Maybe<ReportMetric>;
  plays?: Maybe<ReportMetric>;
  shares?: Maybe<ReportMetric>;
  total_interactions?: Maybe<ReportMetric>;
};

export type ReportInstagramStoryStats = {
  __typename?: 'ReportInstagramStoryStats';
  count?: Maybe<Scalars['Int']>;
  days?: Maybe<Scalars['Int']>;
  postsPerDay?: Maybe<Scalars['Float']>;
  reach?: Maybe<ReportMetric>;
  impressions?: Maybe<ReportMetric>;
  exits?: Maybe<ReportMetric>;
  replies?: Maybe<ReportMetric>;
  taps_forward?: Maybe<ReportMetric>;
  taps_back?: Maybe<ReportMetric>;
};

export type ReportLink = {
  __typename?: 'ReportLink';
  _id: Scalars['ObjectId'];
  userId?: Maybe<Scalars['ObjectId']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  reportConfigId?: Maybe<Scalars['ObjectId']>;
  contactId?: Maybe<Scalars['ObjectId']>;
  isOpened?: Maybe<Scalars['Boolean']>;
  openedAt?: Maybe<Scalars['Date']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  shortenedLink?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['Date']>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
  reportConfig?: Maybe<ReportConfig>;
  contact?: Maybe<Contact>;
};

export type ReportMediaCounts = {
  __typename?: 'ReportMediaCounts';
  /** @deprecated Use instagramFeedCount and instagramReelsCount */
  mediaCount?: Maybe<Scalars['Int']>;
  instagramFeedCount?: Maybe<Scalars['Int']>;
  instagramReelsCount?: Maybe<Scalars['Int']>;
  storiesCount?: Maybe<Scalars['Int']>;
  videoCount?: Maybe<Scalars['Int']>;
  postCount?: Maybe<Scalars['Int']>;
  tiktokMediaCount?: Maybe<Scalars['Int']>;
  supplementMediaCounts?: Maybe<Array<Maybe<ReportSupplementMediaCounts>>>;
};

export type ReportMediaStats = {
  __typename?: 'ReportMediaStats';
  count?: Maybe<Scalars['Int']>;
  days?: Maybe<Scalars['Int']>;
  postsPerDay?: Maybe<Scalars['Float']>;
};

export type ReportMetric = {
  __typename?: 'ReportMetric';
  average?: Maybe<Scalars['Float']>;
  actual?: Maybe<Scalars['Int']>;
  latest?: Maybe<Scalars['Int']>;
};

export type ReportProfile = {
  __typename?: 'ReportProfile';
  _id: Scalars['ObjectId'];
  isAddedByOwner?: Maybe<Scalars['Boolean']>;
  agentId?: Maybe<Scalars['String']>;
  biography?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** @deprecated Use cacheError */
  cache_error?: Maybe<Scalars['String']>;
  cache_completed_at?: Maybe<Scalars['Date']>;
  created_at?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  historical_from?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  pageId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  media_cached_at?: Maybe<Scalars['Date']>;
  profileCategory?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use 'profileCategory'. */
  profile_category?: Maybe<Array<Maybe<Scalars['String']>>>;
  profileType?: Maybe<SocialChannel>;
  /** @deprecated Use 'profileType'. */
  profile_type?: Maybe<Scalars['String']>;
  profile_cached_at?: Maybe<Scalars['Date']>;
  profile_media_kit?: Maybe<MediaKit>;
  profile_picture_url?: Maybe<Scalars['String']>;
  redflags_from?: Maybe<Scalars['Date']>;
  redflags_until?: Maybe<Scalars['Date']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['Date']>;
  userId?: Maybe<Scalars['String']>;
  isRestricted?: Maybe<Scalars['Boolean']>;
  isPendingConnection?: Maybe<Scalars['Boolean']>;
  hasAcceptedInvite?: Maybe<Scalars['Boolean']>;
  doesRequireTrackApproval?: Maybe<Scalars['Boolean']>;
  selectedMediaKitId?: Maybe<Scalars['ObjectId']>;
  profileAuditReportLink?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  media_count?: Maybe<Scalars['Float']>;
  followers_count?: Maybe<Scalars['Int']>;
  channelStats?: Maybe<ChannelStats>;
  fan_count?: Maybe<Scalars['Int']>;
  published_post_count?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['String']>;
  union_id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  profile_cache_attempts?: Maybe<Scalars['Int']>;
  media_cache_attempts?: Maybe<Scalars['Int']>;
  last_cache_attempts?: Maybe<Scalars['Int']>;
  cache_error_at?: Maybe<Scalars['Date']>;
  cache_error_resolved_at?: Maybe<Scalars['Date']>;
  last_cache_error?: Maybe<Scalars['String']>;
  user_notified_at?: Maybe<Scalars['Date']>;
  agency_notified_at?: Maybe<Scalars['Date']>;
  historical_until?: Maybe<Scalars['Date']>;
  assignedToAmbassadorId?: Maybe<Scalars['ObjectId']>;
  profile_added_at?: Maybe<Scalars['Date']>;
  ownerNotes?: Maybe<Scalars['String']>;
  ownerTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  isInstagramIncomingMentionsEnabled?: Maybe<Scalars['Boolean']>;
  isInstagramFeedWebhookEnabled?: Maybe<Scalars['Boolean']>;
  pageStats?: Maybe<FacebookPageStats>;
  postStats?: Maybe<Scalars['JSObject']>;
  historicalFacebookStats?: Maybe<Array<FacebookPageStats>>;
  weeklyFacebookStats?: Maybe<Array<FacebookWeeklyStats>>;
  rankedFacebookMedia?: Maybe<RankedFacebookMedia>;
  latestFacebookPosts?: Maybe<Array<FacebookPost>>;
  facebookPostTrends?: Maybe<FacebookMediaTrends>;
  recentFacebookMediaStats?: Maybe<RecentMediaStats>;
  lifetimeInsights?: Maybe<LifetimeInsights>;
  historicalStats?: Maybe<InstagramHistoricalStats>;
  onlineFollowers?: Maybe<Scalars['JSObject']>;
  /** @deprecated Use rankedInstagramMedia.topMedia instead */
  rankedInstagramPosts?: Maybe<Array<InstagramMedia>>;
  rankedInstagramMedia?: Maybe<RankedInstagramMedia>;
  rankedInstagramFeed?: Maybe<Array<Maybe<InstagramMedia>>>;
  rankedInstagramReels?: Maybe<Array<Maybe<InstagramMedia>>>;
  latestInstagramPosts?: Maybe<Array<InstagramMedia>>;
  latestInstagramFeed?: Maybe<Array<InstagramMedia>>;
  latestInstagramReels?: Maybe<Array<InstagramMedia>>;
  latestInstagramStories?: Maybe<Array<InstagramStory>>;
  dailyInstagramStats?: Maybe<Array<InstagramHistorical>>;
  weeklyInstagramStats?: Maybe<Array<InstagramHistorical>>;
  /** @deprecated Use instagramFeedStats and instagramReelStats */
  mediaStats?: Maybe<ReportInstagramMediaStats>;
  instagramFeedStats?: Maybe<ReportInstagramMediaStats>;
  instagramReelStats?: Maybe<ReportInstagramMediaStats>;
  storyStats?: Maybe<ReportInstagramStoryStats>;
  instagramFeedTrends?: Maybe<InstagramMediaTrends>;
  instagramReelTrends?: Maybe<InstagramMediaTrends>;
  instagramStoryTrends?: Maybe<InstagramStoryTrends>;
  instagramCombinedTrends?: Maybe<InstagramCombinedTrends>;
  followerBracket?: Maybe<Scalars['Int']>;
  latestPerformanceReport?: Maybe<PerformanceReport>;
  instagramMediaMentions?: Maybe<Array<InstagramMedia>>;
  recentInstagramMediaStats?: Maybe<RecentInstagramMediaStats>;
  subscribedWebhookFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  cacheError?: Maybe<Scalars['String']>;
  userFriendlyCacheError?: Maybe<UserFriendlyCacheError>;
  user?: Maybe<User>;
  agent?: Maybe<User>;
  cacheSeconds?: Maybe<Scalars['Int']>;
  mediaKitConfigs?: Maybe<Array<Maybe<MediaKitConfig>>>;
  ownerMediaKitConfigs?: Maybe<Array<MediaKitConfig>>;
  profileNote?: Maybe<ProfileNote>;
  dataAccessToken?: Maybe<Scalars['String']>;
  followerCount?: Maybe<Scalars['Int']>;
  selectedMediaKit?: Maybe<MediaKitConfig>;
  isVisibleOnNetwork?: Maybe<Scalars['Boolean']>;
  mediaKits?: Maybe<Array<MediaKitConfig>>;
  thumbnails?: Maybe<Thumbnails>;
  title?: Maybe<Scalars['String']>;
  videoStats?: Maybe<ReportYoutubeMediaStats>;
  snippet?: Maybe<Scalars['String']>;
  ageGroupBreakdown?: Maybe<Array<AgeGroup>>;
  genderBreakdown?: Maybe<Array<GenderGroup>>;
  locationBreakdown?: Maybe<Array<LocationGroup>>;
  myChannelReport?: Maybe<Array<ChannelReport>>;
  subscriberChange?: Maybe<Array<SubscriberChange>>;
  youtubeMedia?: Maybe<Array<YoutubeMedia>>;
  youtubeHistorical?: Maybe<Array<YoutubeHistorical>>;
  youtubeWeeklyStats?: Maybe<Array<YoutubeHistoricalStats>>;
  rankedYoutubeMedia?: Maybe<RankedYoutubeMedia>;
  youtubeVideoTrends?: Maybe<YoutubeMediaTrends>;
  recentYoutubeMediaStats?: Maybe<RecentMediaStats>;
  public?: Maybe<TiktokPublicUser>;
  tiktokMediaStats?: Maybe<Scalars['JSObject']>;
  tiktokMediaTrends?: Maybe<TiktokMediaTrends>;
  rankedTiktokMedia?: Maybe<Array<Maybe<TiktokMedia>>>;
  recentTiktokMediaStats?: Maybe<RecentMediaStats>;
  notApproved?: Maybe<Scalars['Boolean']>;
  totalReach?: Maybe<Scalars['Int']>;
  totalImpressions?: Maybe<Scalars['Int']>;
  mediaByDay?: Maybe<Array<Maybe<MediaByDayStat>>>;
  media?: Maybe<Array<Maybe<InstagramMedia>>>;
  /** @deprecated Use rankedInstagramFeed or rankedInstagramReels */
  rankedMedia?: Maybe<Array<Maybe<InstagramMedia>>>;
  instagramFeed?: Maybe<Array<Maybe<InstagramMedia>>>;
  instagramReels?: Maybe<Array<Maybe<InstagramMedia>>>;
  stories?: Maybe<Array<Maybe<InstagramStory>>>;
  rankedStories?: Maybe<Array<Maybe<InstagramStory>>>;
  videos?: Maybe<Array<Maybe<YoutubeMedia>>>;
  rankedVideos?: Maybe<Array<Maybe<YoutubeMedia>>>;
  posts?: Maybe<Array<Maybe<FacebookPost>>>;
  rankedPosts?: Maybe<Array<Maybe<FacebookPost>>>;
  tiktokMedia?: Maybe<Array<Maybe<TiktokMedia>>>;
  supplementMediaStats?: Maybe<Array<Maybe<Scalars['JSObject']>>>;
  supplementMedia?: Maybe<Array<Maybe<SupplementMedia>>>;
};

export type ReportProfileWeeklyFacebookStatsArgs = {
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type ReportProfileWeeklyInstagramStatsArgs = {
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type ReportProfileYoutubeWeeklyStatsArgs = {
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type ReportProfileMetric = {
  __typename?: 'ReportProfileMetric';
  actual?: Maybe<Scalars['Int']>;
  average?: Maybe<Scalars['Float']>;
};

export type ReportResults = {
  __typename?: 'ReportResults';
  payload?: Maybe<ReportConfig>;
  profiles?: Maybe<Array<ReportProfile>>;
  profilesAll?: Maybe<Array<ReportProfile>>;
  mediaByDay?: Maybe<Array<MediaByDayStat>>;
  /** @deprecated Use rankedInstagramFeed or rankedInstagramReels */
  rankedMedia?: Maybe<Array<InstagramMedia>>;
  rankedInstagramFeed?: Maybe<Array<InstagramMedia>>;
  rankedInstagramReels?: Maybe<Array<InstagramMedia>>;
  rankedStories?: Maybe<Array<InstagramStory>>;
  rankedVideos?: Maybe<Array<YoutubeMedia>>;
  rankedPosts?: Maybe<Array<FacebookPost>>;
  rankedTiktokMedia?: Maybe<Array<TiktokMedia>>;
  supplementMedia?: Maybe<Scalars['JSObject']>;
  /** @deprecated Use allInstagramReels or allInstagramFeed */
  allMedia?: Maybe<Array<InstagramMedia>>;
  allInstagramReels?: Maybe<Array<InstagramMedia>>;
  allInstagramFeed?: Maybe<Array<InstagramMedia>>;
  allStories?: Maybe<Array<InstagramStory>>;
  allVideos?: Maybe<Array<YoutubeMedia>>;
  allPosts?: Maybe<Array<FacebookPost>>;
  allTiktokMedia?: Maybe<Array<TiktokMedia>>;
  bitlyStats?: Maybe<Scalars['JSObject']>;
  totals?: Maybe<ReportTotals>;
  profileMediaCounts?: Maybe<ReportMediaCounts>;
  predictedInsights?: Maybe<Scalars['JSObject']>;
};

export type ReportShareContact = {
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ObjectId']>;
};

export type ReportSupplementMediaCounts = {
  __typename?: 'ReportSupplementMediaCounts';
  type?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
};

export type ReportTotals = {
  __typename?: 'ReportTotals';
  profileStats?: Maybe<Scalars['JSObject']>;
  /** @deprecated Use instagramFeedStats and instagramReelStats */
  mediaStats?: Maybe<ReportInstagramMediaStats>;
  instagramFeedStats?: Maybe<ReportInstagramMediaStats>;
  instagramReelStats?: Maybe<ReportInstagramMediaStats>;
  storyStats?: Maybe<ReportInstagramStoryStats>;
  videoStats?: Maybe<ReportYoutubeMediaStats>;
  postStats?: Maybe<Scalars['JSObject']>;
  tiktokMediaStats?: Maybe<Scalars['JSObject']>;
  supplementMediaStats?: Maybe<Scalars['JSObject']>;
};

export type ReportYoutubeMediaStats = {
  __typename?: 'ReportYoutubeMediaStats';
  count?: Maybe<Scalars['Int']>;
  days?: Maybe<Scalars['Int']>;
  postsPerDay?: Maybe<Scalars['Float']>;
  views?: Maybe<ReportMetric>;
  averageViewDuration?: Maybe<ReportMetric>;
  averageViewPercentage?: Maybe<ReportMetric>;
  estimatedMinutesWatched?: Maybe<ReportMetric>;
  cardClicks?: Maybe<ReportMetric>;
  cardTeaserClicks?: Maybe<ReportMetric>;
  likes?: Maybe<ReportMetric>;
  comments?: Maybe<ReportMetric>;
};

export type ResourceId = {
  __typename?: 'ResourceId';
  kind?: Maybe<Scalars['String']>;
  videoId?: Maybe<Scalars['String']>;
};

export type RichTextContent = {
  __typename?: 'RichTextContent';
  type?: Maybe<ElementType>;
  rich_text_content?: Maybe<Scalars['String']>;
};

export type RybbonReward = {
  __typename?: 'RybbonReward';
  rybbon_reference_code?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type SelectedMediaContent = {
  __typename?: 'SelectedMediaContent';
  type?: Maybe<ElementType>;
  selected_media_content?: Maybe<SelectedMediaField>;
};

export type SelectedMediaField = {
  __typename?: 'SelectedMediaField';
  profileId?: Maybe<Scalars['String']>;
  instagram_media?: Maybe<Array<Maybe<Scalars['String']>>>;
  instagram_media_data?: Maybe<Array<Maybe<InstagramMedia>>>;
  instagram_stories?: Maybe<Array<Maybe<Scalars['String']>>>;
  facebook_posts?: Maybe<Array<Maybe<Scalars['String']>>>;
  youtube_media?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SelectedMediaInput = {
  profileId?: Maybe<Scalars['String']>;
  instagram_media?: Maybe<Array<Maybe<Scalars['String']>>>;
  instagram_stories?: Maybe<Array<Maybe<Scalars['String']>>>;
  facebook_posts?: Maybe<Array<Maybe<Scalars['String']>>>;
  youtube_media?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SentEmail = {
  __typename?: 'SentEmail';
  _id: Scalars['ObjectId'];
  recipientEmail?: Maybe<Scalars['String']>;
  recipientFirstName?: Maybe<Scalars['String']>;
  recipientLastName?: Maybe<Scalars['String']>;
  sender?: Maybe<Scalars['ObjectId']>;
  kind?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  template?: Maybe<Scalars['String']>;
  shouldHideInUI?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
  messageText?: Maybe<Scalars['String']>;
  /** @deprecated Use recipientUser */
  user?: Maybe<User>;
  recipientUser?: Maybe<User>;
  senderUser?: Maybe<User>;
};

export type ServerResponse = {
  __typename?: 'ServerResponse';
  success?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  insertedId?: Maybe<Scalars['ObjectId']>;
  insertedIdList?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
};

export type ShareCollaborationInvoiceDetails = {
  contactId?: Maybe<Scalars['ObjectId']>;
  contactIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  contactDetails?: Maybe<InvoiceShareContactInput>;
  saveContact?: Maybe<Scalars['Boolean']>;
};

export enum SocialChannel {
  Instagram = 'INSTAGRAM',
  Youtube = 'YOUTUBE',
  Facebook = 'FACEBOOK',
  Twitter = 'TWITTER',
  Twitch = 'TWITCH',
  Snapchat = 'SNAPCHAT',
  Tiktok = 'TIKTOK',
  Blog = 'BLOG',
  Linkedin = 'LINKEDIN',
  Videography = 'VIDEOGRAPHY',
  Photography = 'PHOTOGRAPHY',
  Podcast = 'PODCAST',
  Appearance = 'APPEARANCE',
  Custom = 'CUSTOM'
}

export enum SocialDeliverable {
  IgTv = 'IG_TV',
  IgStory = 'IG_STORY',
  IgGridPost = 'IG_GRID_POST',
  IgReel = 'IG_REEL',
  IgLive = 'IG_LIVE',
  IgImageCarousel = 'IG_IMAGE_CAROUSEL',
  Photoshoot = 'PHOTOSHOOT',
  YtVideo = 'YT_VIDEO',
  FbPagePost = 'FB_PAGE_POST',
  ScVideo = 'SC_VIDEO',
  ScImage = 'SC_IMAGE',
  Retweet = 'RETWEET',
  TwVideo = 'TW_VIDEO',
  TwImage = 'TW_IMAGE',
  Tweet = 'TWEET',
  TtVideo = 'TT_VIDEO',
  TtShare = 'TT_SHARE',
  Article = 'ARTICLE',
  BlVideo = 'BL_VIDEO',
  BlShare = 'BL_SHARE',
  Byline = 'BYLINE',
  LiStory = 'LI_STORY',
  LiPost = 'LI_POST',
  LiShare = 'LI_SHARE',
  LiPublishedArticle = 'LI_PUBLISHED_ARTICLE',
  Custom = 'CUSTOM',
  LinkInBio = 'LINK_IN_BIO',
  IgStoryHighlight = 'IG_STORY_HIGHLIGHT',
  TcVideo = 'TC_VIDEO',
  TcLiveStream = 'TC_LIVE_STREAM',
  TcMention = 'TC_MENTION',
  TcTournament = 'TC_TOURNAMENT',
  ViOneHourShoot = 'VI_ONE_HOUR_SHOOT',
  ViHalfDayShoot = 'VI_HALF_DAY_SHOOT',
  ViFullDayShoot = 'VI_FULL_DAY_SHOOT',
  ViEditing = 'VI_EDITING',
  PhOneHourShoot = 'PH_ONE_HOUR_SHOOT',
  PhHalfDayShoot = 'PH_HALF_DAY_SHOOT',
  PhFullDayShoot = 'PH_FULL_DAY_SHOOT',
  PhEditing = 'PH_EDITING',
  PoMention = 'PO_MENTION',
  PoSegmentAd = 'PO_SEGMENT_AD',
  PoInterview = 'PO_INTERVIEW',
  ApMeetAndGreet = 'AP_MEET_AND_GREET',
  ApInterview = 'AP_INTERVIEW',
  ApVideoMessage = 'AP_VIDEO_MESSAGE',
  ApKeyNote = 'AP_KEY_NOTE'
}

export type SocialProfile = {
  __typename?: 'SocialProfile';
  _id: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  cache_error?: Maybe<Scalars['String']>;
};

export type SortField = {
  field?: Maybe<Scalars['String']>;
  sortAscending?: Maybe<Scalars['Boolean']>;
};

export type StoriesForMonthResponse = {
  __typename?: 'StoriesForMonthResponse';
  stories?: Maybe<Array<Maybe<InstagramStory>>>;
  date?: Maybe<Scalars['Date']>;
};

export type StripeAccountLink = {
  __typename?: 'StripeAccountLink';
  object?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['StripeTimestamp']>;
  expires_at?: Maybe<Scalars['StripeTimestamp']>;
  url?: Maybe<Scalars['String']>;
};

export type StripeCard = {
  __typename?: 'StripeCard';
  exp_year?: Maybe<Scalars['Int']>;
  exp_month?: Maybe<Scalars['Int']>;
  last4?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
};

export type StripeCharge = {
  __typename?: 'StripeCharge';
  id: Scalars['ID'];
  amount?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  refunded?: Maybe<Scalars['Boolean']>;
  receipt_url?: Maybe<Scalars['String']>;
  paid?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['StripeTimestamp']>;
};

export type StripeConnectedAccount = {
  __typename?: 'StripeConnectedAccount';
  id: Scalars['ID'];
  business_profile?: Maybe<Scalars['JSObject']>;
  charges_enabled?: Maybe<Scalars['Boolean']>;
  country?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['StripeTimestamp']>;
  default_currency?: Maybe<Scalars['String']>;
  details_submitted?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['JSObject']>;
  payouts_enabled?: Maybe<Scalars['Boolean']>;
  business_type?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['JSObject']>;
  type?: Maybe<Scalars['String']>;
  expressDashboardLink?: Maybe<Scalars['String']>;
};

export type StripeCustomer = {
  __typename?: 'StripeCustomer';
  id: Scalars['ID'];
  address?: Maybe<Scalars['JSObject']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['JSObject']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  shipping?: Maybe<Scalars['JSObject']>;
  balance?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['StripeTimestamp']>;
  currency?: Maybe<Scalars['String']>;
  default_source?: Maybe<Scalars['String']>;
  delinquent?: Maybe<Scalars['Boolean']>;
  discount?: Maybe<Scalars['JSObject']>;
  invoice_prefix?: Maybe<Scalars['String']>;
  invoice_settings?: Maybe<Scalars['JSObject']>;
  livemode?: Maybe<Scalars['Boolean']>;
  next_invoice_sequence?: Maybe<Scalars['Int']>;
  preferred_locales?: Maybe<Array<Maybe<Scalars['String']>>>;
  sources?: Maybe<Scalars['JSObject']>;
  tax_exempt?: Maybe<Scalars['String']>;
  defaultPaymentMethod?: Maybe<Scalars['String']>;
  firstSubscription?: Maybe<StripeSubscription>;
  activeSubscriptionSchedules?: Maybe<Array<StripeSubscriptionSchedule>>;
  cards?: Maybe<Array<StripePaymentMethod>>;
  charges?: Maybe<Array<StripeCharge>>;
};

export type StripeInvoice = {
  __typename?: 'StripeInvoice';
  id: Scalars['ID'];
  account_country?: Maybe<Scalars['String']>;
  account_name?: Maybe<Scalars['String']>;
  account_tax_ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  amount_due?: Maybe<Scalars['Int']>;
  amount_paid?: Maybe<Scalars['Int']>;
  amount_remaining?: Maybe<Scalars['Int']>;
  application_fee_amount?: Maybe<Scalars['Int']>;
  attempt_count?: Maybe<Scalars['Int']>;
  attempted?: Maybe<Scalars['Boolean']>;
  automatic_tax?: Maybe<Scalars['JSObject']>;
  auto_advance?: Maybe<Scalars['Boolean']>;
  billing_reason?: Maybe<Scalars['String']>;
  charge?: Maybe<Scalars['ID']>;
  collection_method?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  customer?: Maybe<Scalars['ID']>;
  created?: Maybe<Scalars['StripeTimestamp']>;
  custom_fields?: Maybe<Array<Scalars['JSObject']>>;
  customer_address?: Maybe<Scalars['JSObject']>;
  customer_email?: Maybe<Scalars['String']>;
  customer_name?: Maybe<Scalars['String']>;
  customer_phone?: Maybe<Scalars['String']>;
  customer_shipping?: Maybe<Scalars['JSObject']>;
  customer_tax_exempt?: Maybe<Scalars['String']>;
  customer_tax_ids?: Maybe<Array<Scalars['ID']>>;
  default_payment_method?: Maybe<Scalars['String']>;
  default_source?: Maybe<Scalars['String']>;
  default_tax_rates?: Maybe<Scalars['JSObject']>;
  discounts?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  due_date?: Maybe<Scalars['StripeTimestamp']>;
  ending_balance?: Maybe<Scalars['Int']>;
  footer?: Maybe<Scalars['String']>;
  invoice_pdf?: Maybe<Scalars['String']>;
  hosted_invoice_url?: Maybe<Scalars['String']>;
  last_finalization_error?: Maybe<Scalars['JSObject']>;
  lines?: Maybe<Scalars['JSObject']>;
  livemode?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<Scalars['JSObject']>;
  next_payment_attempt?: Maybe<Scalars['StripeTimestamp']>;
  number?: Maybe<Scalars['String']>;
  on_behalf_of?: Maybe<Scalars['String']>;
  paid?: Maybe<Scalars['Boolean']>;
  payment_intent?: Maybe<Scalars['String']>;
  period_end?: Maybe<Scalars['StripeTimestamp']>;
  period_start?: Maybe<Scalars['StripeTimestamp']>;
  status?: Maybe<Scalars['String']>;
  status_transitions?: Maybe<StripeStatusTransition>;
  subscription?: Maybe<Scalars['ID']>;
  subscription_proration_date?: Maybe<Scalars['Int']>;
  subtotal?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type StripePaymentMethod = {
  __typename?: 'StripePaymentMethod';
  id: Scalars['ID'];
  card?: Maybe<StripeCard>;
};

export type StripePrice = {
  __typename?: 'StripePrice';
  id: Scalars['ID'];
  unit_amount_decimal?: Maybe<Scalars['String']>;
  unit_amount?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  billing_scheme?: Maybe<Scalars['String']>;
};

export type StripeStatusTransition = {
  __typename?: 'StripeStatusTransition';
  paid_at?: Maybe<Scalars['Float']>;
};

export type StripeSubscription = {
  __typename?: 'StripeSubscription';
  id: Scalars['ID'];
  application_fee_percent?: Maybe<Scalars['Float']>;
  billing_cycle_anchor?: Maybe<Scalars['StripeTimestamp']>;
  cancel_at?: Maybe<Scalars['StripeTimestamp']>;
  cancel_at_period_end?: Maybe<Scalars['Boolean']>;
  canceled_at?: Maybe<Scalars['StripeTimestamp']>;
  collection_method?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['StripeTimestamp']>;
  current_period_end?: Maybe<Scalars['StripeTimestamp']>;
  current_period_start?: Maybe<Scalars['StripeTimestamp']>;
  customer?: Maybe<Scalars['String']>;
  days_until_due?: Maybe<Scalars['Int']>;
  default_payment_method?: Maybe<Scalars['ID']>;
  default_source?: Maybe<Scalars['ID']>;
  default_tax_rates?: Maybe<Array<Maybe<Scalars['JSObject']>>>;
  discount?: Maybe<Scalars['JSObject']>;
  ended_at?: Maybe<Scalars['StripeTimestamp']>;
  items?: Maybe<Scalars['JSObject']>;
  latest_invoice?: Maybe<Scalars['ID']>;
  metadata?: Maybe<Scalars['JSObject']>;
  next_pending_invoice_item_invoice?: Maybe<Scalars['StripeTimestamp']>;
  status?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['StripeTimestamp']>;
  transfer_data?: Maybe<Scalars['JSObject']>;
  trial_end?: Maybe<Scalars['StripeTimestamp']>;
  trial_start?: Maybe<Scalars['StripeTimestamp']>;
  firstItem?: Maybe<StripeSubscriptionItem>;
};

export type StripeSubscriptionItem = {
  __typename?: 'StripeSubscriptionItem';
  id: Scalars['ID'];
  subscription?: Maybe<Scalars['String']>;
  price?: Maybe<StripePrice>;
  plan?: Maybe<Scalars['JSObject']>;
  metadata?: Maybe<Scalars['JSObject']>;
  created?: Maybe<Scalars['StripeTimestamp']>;
  billing_thresholds?: Maybe<Scalars['JSObject']>;
  tax_rates?: Maybe<Array<Scalars['JSObject']>>;
};

export type StripeSubscriptionSchedule = {
  __typename?: 'StripeSubscriptionSchedule';
  id: Scalars['ID'];
  subscription?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  released_at?: Maybe<Scalars['StripeTimestamp']>;
  phases?: Maybe<Array<Maybe<Scalars['JSObject']>>>;
  metadata?: Maybe<Scalars['JSObject']>;
  end_behavior?: Maybe<Scalars['JSObject']>;
  default_settings?: Maybe<Scalars['JSObject']>;
  customer?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['StripeTimestamp']>;
  current_phase?: Maybe<StripeSubscriptionScheduleCurrentPhase>;
  completed_at?: Maybe<Scalars['StripeTimestamp']>;
  canceled_at?: Maybe<Scalars['StripeTimestamp']>;
};

export type StripeSubscriptionScheduleCurrentPhase = {
  __typename?: 'StripeSubscriptionScheduleCurrentPhase';
  start_date?: Maybe<Scalars['StripeTimestamp']>;
  end_date?: Maybe<Scalars['StripeTimestamp']>;
};

export type StripeTaxRate = {
  __typename?: 'StripeTaxRate';
  id: Scalars['ID'];
  active?: Maybe<Scalars['Boolean']>;
  country?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['StripeTimestamp']>;
  description?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  inclusive?: Maybe<Scalars['Boolean']>;
  jurisdiction?: Maybe<Scalars['String']>;
  livemode?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<Scalars['JSObject']>;
  percentage?: Maybe<Scalars['Float']>;
  state?: Maybe<Scalars['String']>;
};

export type SubscriberChange = {
  __typename?: 'SubscriberChange';
  day?: Maybe<Scalars['String']>;
  subscribersGained?: Maybe<Scalars['Int']>;
  subscribersLost?: Maybe<Scalars['Int']>;
};

export type SubscriptionsAtDate = {
  __typename?: 'SubscriptionsAtDate';
  date?: Maybe<Scalars['Date']>;
  plans?: Maybe<Array<Maybe<PlanStat>>>;
};

export type SupplementMedia = {
  __typename?: 'SupplementMedia';
  _id: Scalars['ObjectId'];
  userId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  reportConfigId?: Maybe<Scalars['ObjectId']>;
  mediaType?: Maybe<SupplementMediaType>;
  insights?: Maybe<SupplementMediaInsights>;
  mediaUrl?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Date']>;
  mediaIsVideo?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
  profile?: Maybe<Profile>;
  reportConfig?: Maybe<ReportConfig>;
};

export type SupplementMediaInput = {
  profileId?: Maybe<Scalars['String']>;
  reportConfigId?: Maybe<Scalars['ObjectId']>;
  mediaType?: Maybe<SupplementMediaType>;
  insights?: Maybe<SupplementMediaInsightsInput>;
  mediaUrl?: Maybe<Scalars['String']>;
  mediaUpload?: Maybe<Scalars['Upload']>;
  caption?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Date']>;
  mediaIsVideo?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  mediaInputUrl?: Maybe<Scalars['String']>;
};

export type SupplementMediaInsights = {
  __typename?: 'SupplementMediaInsights';
  reach?: Maybe<Scalars['Int']>;
  plays?: Maybe<Scalars['Int']>;
  contentInteractions?: Maybe<Scalars['Int']>;
  likes?: Maybe<Scalars['Int']>;
  comments?: Maybe<Scalars['Int']>;
  shares?: Maybe<Scalars['Int']>;
  saves?: Maybe<Scalars['Int']>;
};

export type SupplementMediaInsightsInput = {
  reach?: Maybe<Scalars['Int']>;
  plays?: Maybe<Scalars['Int']>;
  contentInteractions?: Maybe<Scalars['Int']>;
  likes?: Maybe<Scalars['Int']>;
  comments?: Maybe<Scalars['Int']>;
  shares?: Maybe<Scalars['Int']>;
  saves?: Maybe<Scalars['Int']>;
};

export enum SupplementMediaType {
  InstagramReel = 'INSTAGRAM_REEL',
  InstagramStory = 'INSTAGRAM_STORY',
  TiktokVideo = 'TIKTOK_VIDEO'
}

export type TalentNetworkContactEmailPayload = {
  senderName?: Maybe<Scalars['String']>;
  senderCompanyName?: Maybe<Scalars['String']>;
  recipientUserId?: Maybe<Scalars['ObjectId']>;
  messageText?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['ObjectId']>;
  collaborationId?: Maybe<Scalars['ObjectId']>;
};

export type TextContent = {
  __typename?: 'TextContent';
  type?: Maybe<ElementType>;
  text_content?: Maybe<Scalars['String']>;
};

export type ThumbnailInfo = {
  __typename?: 'ThumbnailInfo';
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
};

export type Thumbnails = {
  __typename?: 'Thumbnails';
  default?: Maybe<ThumbnailInfo>;
  medium?: Maybe<ThumbnailInfo>;
  high?: Maybe<ThumbnailInfo>;
  standard?: Maybe<ThumbnailInfo>;
  maxres?: Maybe<ThumbnailInfo>;
};

export type TikTokPublicReport = {
  __typename?: 'TikTokPublicReport';
  profile?: Maybe<TiktokProfile>;
  media?: Maybe<Array<Maybe<TiktokPublicVideo>>>;
};

export type TikTokRankedMedia = {
  __typename?: 'TikTokRankedMedia';
  topMedia?: Maybe<Array<Maybe<TiktokMedia>>>;
  worstMedia?: Maybe<Array<Maybe<TiktokMedia>>>;
};

export type TiktokChallenge = {
  __typename?: 'TiktokChallenge';
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  desc?: Maybe<Scalars['String']>;
  profileThumb?: Maybe<Scalars['String']>;
  profileMedium?: Maybe<Scalars['String']>;
  profileLarger?: Maybe<Scalars['String']>;
  coverThumb?: Maybe<Scalars['String']>;
  coverMedium?: Maybe<Scalars['String']>;
  coverLarger?: Maybe<Scalars['String']>;
  isCommerce?: Maybe<Scalars['Boolean']>;
};

export type TiktokHistorical = {
  __typename?: 'TiktokHistorical';
  _id?: Maybe<Scalars['ObjectId']>;
  date?: Maybe<Scalars['Date']>;
  start?: Maybe<Scalars['String']>;
  public?: Maybe<TiktokPublicUser>;
  tiktokMediaStats?: Maybe<TiktokMediaStats>;
  change?: Maybe<TiktokHistorical>;
};

export type TiktokHistoricalAverages = {
  __typename?: 'TiktokHistoricalAverages';
  start?: Maybe<Scalars['String']>;
  days?: Maybe<Array<Maybe<TiktokHistorical>>>;
  public?: Maybe<TiktokPublicUserAverages>;
  tiktokMediaStats?: Maybe<TiktokMediaStatsAverages>;
};

export type TiktokMedia = {
  __typename?: 'TiktokMedia';
  _id: Scalars['ObjectId'];
  id: Scalars['ID'];
  hashes?: Maybe<Array<Maybe<Scalars['String']>>>;
  handles?: Maybe<Array<Maybe<Scalars['String']>>>;
  profileId?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  totalEngagement?: Maybe<Scalars['Int']>;
  isViralVideo?: Maybe<Scalars['Boolean']>;
  official?: Maybe<TiktokOfficialVideo>;
  public?: Maybe<TiktokPublicVideo>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
  profile?: Maybe<Profile>;
  cachedCoverImageUrl?: Maybe<Scalars['String']>;
  cachedVideoUrl?: Maybe<Scalars['String']>;
  history?: Maybe<Array<Maybe<TiktokMediaHistorical>>>;
};

export type TiktokMediaAverages = {
  __typename?: 'TiktokMediaAverages';
  date?: Maybe<Scalars['Date']>;
  totalEngagement?: Maybe<Scalars['Float']>;
  official?: Maybe<TiktokMediaOfficialAverages>;
  public?: Maybe<TiktokMediaPublicAverages>;
};

export type TiktokMediaHistorical = {
  __typename?: 'TiktokMediaHistorical';
  id?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  totalEngagement?: Maybe<Scalars['Float']>;
  official?: Maybe<TiktokOfficialVideo>;
  public?: Maybe<TiktokPublicVideoStats>;
  foundDate?: Maybe<Scalars['Date']>;
};

export type TiktokMediaOfficialAverages = {
  __typename?: 'TiktokMediaOfficialAverages';
  like_count?: Maybe<Scalars['Float']>;
  comment_count?: Maybe<Scalars['Float']>;
  share_count?: Maybe<Scalars['Float']>;
  view_count?: Maybe<Scalars['Float']>;
};

export type TiktokMediaPublicAverages = {
  __typename?: 'TiktokMediaPublicAverages';
  commentCount?: Maybe<Scalars['Float']>;
  diggCount?: Maybe<Scalars['Float']>;
  playCount?: Maybe<Scalars['Float']>;
  shareCount?: Maybe<Scalars['Float']>;
};

export type TiktokMediaStats = {
  __typename?: 'TiktokMediaStats';
  count?: Maybe<Scalars['Int']>;
  days?: Maybe<Scalars['Int']>;
  postsPerDay?: Maybe<Scalars['Float']>;
  topHashtags?: Maybe<Array<Maybe<Scalars['String']>>>;
  topHandles?: Maybe<Array<Maybe<Scalars['String']>>>;
  viralVideoPercentage?: Maybe<Scalars['Float']>;
  totalEngagement?: Maybe<MetricSummary>;
  totalEngagement_percentageOfPlays?: Maybe<MetricSummary>;
  view_count?: Maybe<MetricSummary>;
  like_count?: Maybe<MetricSummary>;
  like_count_percentageOfPlays?: Maybe<MetricSummary>;
  comment_count?: Maybe<MetricSummary>;
  comment_count_percentageOfPlays?: Maybe<MetricSummary>;
  share_count?: Maybe<MetricSummary>;
  share_count_percentageOfPlays?: Maybe<MetricSummary>;
  diggCount?: Maybe<MetricSummary>;
  diggCount_percentageOfPlays?: Maybe<MetricSummary>;
};

export type TiktokMediaStatsAverages = {
  __typename?: 'TiktokMediaStatsAverages';
  viralVideoPercentage?: Maybe<Scalars['Float']>;
  totalEngagement?: Maybe<Scalars['Float']>;
  totalEngagement_percentageOfPlays?: Maybe<Scalars['Float']>;
  view_count?: Maybe<Scalars['Float']>;
  like_count?: Maybe<Scalars['Float']>;
  like_count_percentageOfPlays?: Maybe<Scalars['Float']>;
  comment_count?: Maybe<Scalars['Float']>;
  comment_count_percentageOfPlays?: Maybe<Scalars['Float']>;
  share_count?: Maybe<Scalars['Float']>;
  share_count_percentageOfPlays?: Maybe<Scalars['Float']>;
  diggCount?: Maybe<Scalars['Float']>;
  diggCount_percentageOfPlays?: Maybe<Scalars['Float']>;
};

export type TiktokMediaTrends = {
  __typename?: 'TiktokMediaTrends';
  dailyAverages?: Maybe<Array<Maybe<TiktokMediaAverages>>>;
  recentAverages?: Maybe<TiktokMediaAverages>;
  comparisonAverages?: Maybe<TiktokMediaAverages>;
};

export type TiktokMusic = {
  __typename?: 'TiktokMusic';
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  playUrl?: Maybe<Scalars['String']>;
  coverThumb?: Maybe<Scalars['String']>;
  coverMedium?: Maybe<Scalars['String']>;
  coverLarge?: Maybe<Scalars['String']>;
  authorName?: Maybe<Scalars['String']>;
  original?: Maybe<Scalars['Boolean']>;
  duration?: Maybe<Scalars['Int']>;
  album?: Maybe<Scalars['String']>;
};

export type TiktokOfficialVideo = {
  __typename?: 'TiktokOfficialVideo';
  create_time?: Maybe<Scalars['Float']>;
  cover_image_url?: Maybe<Scalars['String']>;
  share_url?: Maybe<Scalars['String']>;
  video_description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  embed_html?: Maybe<Scalars['String']>;
  embed_link?: Maybe<Scalars['String']>;
  like_count?: Maybe<Scalars['Int']>;
  comment_count?: Maybe<Scalars['Int']>;
  share_count?: Maybe<Scalars['Int']>;
  view_count?: Maybe<Scalars['Int']>;
};

export type TiktokProfile = {
  __typename?: 'TiktokProfile';
  _id: Scalars['ObjectId'];
  isAddedByOwner?: Maybe<Scalars['Boolean']>;
  agentId?: Maybe<Scalars['String']>;
  biography?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** @deprecated Use cacheError */
  cache_error?: Maybe<Scalars['String']>;
  cache_completed_at?: Maybe<Scalars['Date']>;
  created_at?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  historical_from?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  pageId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  media_cached_at?: Maybe<Scalars['Date']>;
  profileCategory?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use 'profileCategory'. */
  profile_category?: Maybe<Array<Maybe<Scalars['String']>>>;
  profileType?: Maybe<SocialChannel>;
  /** @deprecated Use 'profileType'. */
  profile_type?: Maybe<Scalars['String']>;
  profile_cached_at?: Maybe<Scalars['Date']>;
  profile_media_kit?: Maybe<MediaKit>;
  profile_picture_url?: Maybe<Scalars['String']>;
  redflags_from?: Maybe<Scalars['Date']>;
  redflags_until?: Maybe<Scalars['Date']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['Date']>;
  userId?: Maybe<Scalars['String']>;
  isRestricted?: Maybe<Scalars['Boolean']>;
  isPendingConnection?: Maybe<Scalars['Boolean']>;
  hasAcceptedInvite?: Maybe<Scalars['Boolean']>;
  doesRequireTrackApproval?: Maybe<Scalars['Boolean']>;
  selectedMediaKitId?: Maybe<Scalars['ObjectId']>;
  profileAuditReportLink?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  media_count?: Maybe<Scalars['Float']>;
  followers_count?: Maybe<Scalars['Int']>;
  channelStats?: Maybe<ChannelStats>;
  fan_count?: Maybe<Scalars['Int']>;
  published_post_count?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['String']>;
  union_id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  profile_cache_attempts?: Maybe<Scalars['Int']>;
  media_cache_attempts?: Maybe<Scalars['Int']>;
  last_cache_attempts?: Maybe<Scalars['Int']>;
  cache_error_at?: Maybe<Scalars['Date']>;
  cache_error_resolved_at?: Maybe<Scalars['Date']>;
  last_cache_error?: Maybe<Scalars['String']>;
  user_notified_at?: Maybe<Scalars['Date']>;
  agency_notified_at?: Maybe<Scalars['Date']>;
  historical_until?: Maybe<Scalars['Date']>;
  assignedToAmbassadorId?: Maybe<Scalars['ObjectId']>;
  profile_added_at?: Maybe<Scalars['Date']>;
  ownerNotes?: Maybe<Scalars['String']>;
  ownerTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  isInstagramIncomingMentionsEnabled?: Maybe<Scalars['Boolean']>;
  isInstagramFeedWebhookEnabled?: Maybe<Scalars['Boolean']>;
  pageStats?: Maybe<FacebookPageStats>;
  postStats?: Maybe<FacebookPostStats>;
  historicalFacebookStats?: Maybe<Array<FacebookPageStats>>;
  weeklyFacebookStats?: Maybe<Array<FacebookWeeklyStats>>;
  rankedFacebookMedia?: Maybe<RankedFacebookMedia>;
  latestFacebookPosts?: Maybe<Array<FacebookPost>>;
  facebookPostTrends?: Maybe<FacebookMediaTrends>;
  recentFacebookMediaStats?: Maybe<RecentMediaStats>;
  lifetimeInsights?: Maybe<LifetimeInsights>;
  historicalStats?: Maybe<InstagramHistoricalStats>;
  onlineFollowers?: Maybe<Scalars['JSObject']>;
  /** @deprecated Use rankedInstagramMedia.topMedia instead */
  rankedInstagramPosts?: Maybe<Array<InstagramMedia>>;
  rankedInstagramMedia?: Maybe<RankedInstagramMedia>;
  rankedInstagramFeed?: Maybe<RankedInstagramMedia>;
  rankedInstagramReels?: Maybe<RankedInstagramMedia>;
  latestInstagramPosts?: Maybe<Array<InstagramMedia>>;
  latestInstagramFeed?: Maybe<Array<InstagramMedia>>;
  latestInstagramReels?: Maybe<Array<InstagramMedia>>;
  latestInstagramStories?: Maybe<Array<InstagramStory>>;
  dailyInstagramStats?: Maybe<Array<InstagramHistorical>>;
  weeklyInstagramStats?: Maybe<Array<InstagramHistorical>>;
  mediaStats?: Maybe<MediaStats>;
  instagramFeedStats?: Maybe<MediaStats>;
  instagramReelStats?: Maybe<MediaStats>;
  storyStats?: Maybe<MediaStats>;
  instagramFeedTrends?: Maybe<InstagramMediaTrends>;
  instagramReelTrends?: Maybe<InstagramMediaTrends>;
  instagramStoryTrends?: Maybe<InstagramStoryTrends>;
  instagramCombinedTrends?: Maybe<InstagramCombinedTrends>;
  followerBracket?: Maybe<Scalars['Int']>;
  latestPerformanceReport?: Maybe<PerformanceReport>;
  instagramMediaMentions?: Maybe<Array<InstagramMedia>>;
  recentInstagramMediaStats?: Maybe<RecentInstagramMediaStats>;
  subscribedWebhookFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  cacheError?: Maybe<Scalars['String']>;
  userFriendlyCacheError?: Maybe<UserFriendlyCacheError>;
  user?: Maybe<User>;
  agent?: Maybe<User>;
  cacheSeconds?: Maybe<Scalars['Int']>;
  mediaKitConfigs?: Maybe<Array<Maybe<MediaKitConfig>>>;
  ownerMediaKitConfigs?: Maybe<Array<MediaKitConfig>>;
  profileNote?: Maybe<ProfileNote>;
  dataAccessToken?: Maybe<Scalars['String']>;
  followerCount?: Maybe<Scalars['Int']>;
  selectedMediaKit?: Maybe<MediaKitConfig>;
  isVisibleOnNetwork?: Maybe<Scalars['Boolean']>;
  mediaKits?: Maybe<Array<MediaKitConfig>>;
  thumbnails?: Maybe<Thumbnails>;
  title?: Maybe<Scalars['String']>;
  videoStats?: Maybe<YoutubeMediaStats>;
  snippet?: Maybe<Scalars['String']>;
  ageGroupBreakdown?: Maybe<Array<AgeGroup>>;
  genderBreakdown?: Maybe<Array<GenderGroup>>;
  locationBreakdown?: Maybe<Array<LocationGroup>>;
  myChannelReport?: Maybe<Array<ChannelReport>>;
  subscriberChange?: Maybe<Array<SubscriberChange>>;
  youtubeMedia?: Maybe<Array<YoutubeMedia>>;
  youtubeHistorical?: Maybe<Array<YoutubeHistorical>>;
  youtubeWeeklyStats?: Maybe<Array<YoutubeHistoricalStats>>;
  rankedYoutubeMedia?: Maybe<RankedYoutubeMedia>;
  youtubeVideoTrends?: Maybe<YoutubeMediaTrends>;
  recentYoutubeMediaStats?: Maybe<RecentMediaStats>;
  public?: Maybe<TiktokPublicUser>;
  tiktokMediaStats?: Maybe<TiktokMediaStats>;
  tiktokMediaTrends?: Maybe<TiktokMediaTrends>;
  rankedTiktokMedia?: Maybe<TikTokRankedMedia>;
  recentTiktokMediaStats?: Maybe<RecentMediaStats>;
  refreshToken?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
  open_id?: Maybe<Scalars['String']>;
  avatar_url?: Maybe<Scalars['String']>;
  avatar_large_url?: Maybe<Scalars['String']>;
  avatar_url_100?: Maybe<Scalars['String']>;
  secUid?: Maybe<Scalars['String']>;
};

export type TiktokProfileWeeklyFacebookStatsArgs = {
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type TiktokProfileWeeklyInstagramStatsArgs = {
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type TiktokProfileYoutubeWeeklyStatsArgs = {
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type TiktokPublicUser = {
  __typename?: 'TiktokPublicUser';
  id?: Maybe<Scalars['String']>;
  uniqueId?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  avatarThumb?: Maybe<Scalars['String']>;
  avatarMedium?: Maybe<Scalars['String']>;
  avatarLarger?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
  secUid?: Maybe<Scalars['String']>;
  secret?: Maybe<Scalars['Boolean']>;
  ftc?: Maybe<Scalars['Boolean']>;
  relation?: Maybe<Scalars['Int']>;
  openFavorite?: Maybe<Scalars['Boolean']>;
  commentSetting?: Maybe<Scalars['Int']>;
  duetSetting?: Maybe<Scalars['Int']>;
  stitchSetting?: Maybe<Scalars['Int']>;
  privateAccount?: Maybe<Scalars['Boolean']>;
  stats?: Maybe<TiktokUserStats>;
};

export type TiktokPublicUserAverages = {
  __typename?: 'TiktokPublicUserAverages';
  stats?: Maybe<TiktokPublicUserStatAverages>;
};

export type TiktokPublicUserStatAverages = {
  __typename?: 'TiktokPublicUserStatAverages';
  followingCount?: Maybe<Scalars['Float']>;
  followerCount?: Maybe<Scalars['Float']>;
  heartCount?: Maybe<Scalars['Float']>;
  videoCount?: Maybe<Scalars['Float']>;
  diggCount?: Maybe<Scalars['Float']>;
  heart?: Maybe<Scalars['Float']>;
};

export type TiktokPublicVideo = {
  __typename?: 'TiktokPublicVideo';
  id?: Maybe<Scalars['ID']>;
  desc?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Int']>;
  video?: Maybe<TiktokVideo>;
  author?: Maybe<TiktokPublicUser>;
  music?: Maybe<TiktokMusic>;
  challenges?: Maybe<TiktokChallenge>;
  stats?: Maybe<TiktokPublicVideoStats>;
  originalItem?: Maybe<Scalars['Boolean']>;
  officalItem?: Maybe<Scalars['Boolean']>;
  textExtra?: Maybe<Array<Maybe<TiktokTextExtra>>>;
  secret?: Maybe<Scalars['Boolean']>;
  forFriend?: Maybe<Scalars['Boolean']>;
  digged?: Maybe<Scalars['Boolean']>;
  itemCommentStatus?: Maybe<Scalars['Int']>;
  showNotPass?: Maybe<Scalars['Boolean']>;
  vl1?: Maybe<Scalars['Boolean']>;
  itemMute?: Maybe<Scalars['Boolean']>;
  authorStats?: Maybe<TiktokUserStats>;
  privateItem?: Maybe<Scalars['Boolean']>;
  duetEnabled?: Maybe<Scalars['Boolean']>;
  stitchEnabled?: Maybe<Scalars['Boolean']>;
  shareEnabled?: Maybe<Scalars['Boolean']>;
  isAd?: Maybe<Scalars['Boolean']>;
};

export type TiktokPublicVideoStats = {
  __typename?: 'TiktokPublicVideoStats';
  commentCount?: Maybe<Scalars['Int']>;
  diggCount?: Maybe<Scalars['Int']>;
  playCount?: Maybe<Scalars['Int']>;
  shareCount?: Maybe<Scalars['Int']>;
};

export type TiktokTextExtra = {
  __typename?: 'TiktokTextExtra';
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  playUrl?: Maybe<Scalars['String']>;
  coverThumb?: Maybe<Scalars['String']>;
  coverMedium?: Maybe<Scalars['String']>;
  coverLarge?: Maybe<Scalars['String']>;
  authorName?: Maybe<Scalars['String']>;
  original?: Maybe<Scalars['Boolean']>;
  duration?: Maybe<Scalars['Int']>;
  album?: Maybe<Scalars['String']>;
};

export type TiktokUser = {
  __typename?: 'TiktokUser';
  id?: Maybe<Scalars['String']>;
  uniqueId?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  avatarThumb?: Maybe<Scalars['String']>;
  avatarMedium?: Maybe<Scalars['String']>;
  avatarLarger?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
  secUid?: Maybe<Scalars['String']>;
  secret?: Maybe<Scalars['Boolean']>;
  ftc?: Maybe<Scalars['Boolean']>;
  relation?: Maybe<Scalars['Int']>;
  openFavorite?: Maybe<Scalars['Boolean']>;
  commentSetting?: Maybe<Scalars['Int']>;
  duetSetting?: Maybe<Scalars['Int']>;
  stitchSetting?: Maybe<Scalars['Int']>;
  privateAccount?: Maybe<Scalars['Boolean']>;
  shortId?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Int']>;
  bioLink?: Maybe<Scalars['JSObject']>;
  isADVirtual?: Maybe<Scalars['Boolean']>;
  roomId?: Maybe<Scalars['String']>;
};

export type TiktokUserStats = {
  __typename?: 'TiktokUserStats';
  followingCount?: Maybe<Scalars['Int']>;
  followerCount?: Maybe<Scalars['Int']>;
  heartCount?: Maybe<Scalars['Int']>;
  videoCount?: Maybe<Scalars['Int']>;
  diggCount?: Maybe<Scalars['Int']>;
  heart?: Maybe<Scalars['Int']>;
};

export type TiktokVideo = {
  __typename?: 'TiktokVideo';
  id?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  ratio?: Maybe<Scalars['String']>;
  cover?: Maybe<Scalars['String']>;
  originCover?: Maybe<Scalars['String']>;
  dynamicCover?: Maybe<Scalars['String']>;
  playAddr?: Maybe<Scalars['String']>;
  downloadAddr?: Maybe<Scalars['String']>;
  shareCover?: Maybe<Array<Maybe<Scalars['String']>>>;
  reflowCover?: Maybe<Scalars['String']>;
};

export type TopDemographic = {
  __typename?: 'TopDemographic';
  key?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  percentage?: Maybe<Scalars['Float']>;
  actual?: Maybe<Scalars['Int']>;
};

export type TopMentions = {
  __typename?: 'TopMentions';
  topHandles?: Maybe<Array<Maybe<Scalars['String']>>>;
  topHashes?: Maybe<Array<Maybe<Scalars['String']>>>;
  topTagHandles?: Maybe<Array<Maybe<Scalars['String']>>>;
  topTagHashes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TopStoryMentions = {
  __typename?: 'TopStoryMentions';
  topStoryHandles?: Maybe<Array<Maybe<Scalars['String']>>>;
  topStoryHashes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TrackingLink = {
  __typename?: 'TrackingLink';
  _id: Scalars['ObjectId'];
  link?: Maybe<Scalars['String']>;
  bitlinkId?: Maybe<Scalars['String']>;
  longUrl?: Maybe<Scalars['String']>;
  customLink?: Maybe<Scalars['String']>;
  type?: Maybe<TrackingLinkType>;
  target?: Maybe<Scalars['String']>;
  actionType?: Maybe<Scalars['String']>;
  assignedProfile?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ObjectId']>;
  countries?: Maybe<Scalars['JSObject']>;
  referrers?: Maybe<Scalars['JSObject']>;
  referring_domains?: Maybe<Scalars['JSObject']>;
  created_at?: Maybe<Scalars['Date']>;
  report_id?: Maybe<Scalars['String']>;
  totalClicks?: Maybe<Scalars['Int']>;
  historical_from?: Maybe<Scalars['Date']>;
  summary?: Maybe<BitlinkSummary>;
  clicksByCountry?: Maybe<Array<TrackingLinkMetric>>;
};

export type TrackingLinkHistorical = {
  __typename?: 'TrackingLinkHistorical';
  _id?: Maybe<Scalars['ObjectId']>;
  bitlinkId?: Maybe<Scalars['String']>;
  countries?: Maybe<Scalars['JSObject']>;
  referrers?: Maybe<Scalars['JSObject']>;
  referring_domains?: Maybe<Scalars['JSObject']>;
  date?: Maybe<Scalars['Date']>;
  report_id?: Maybe<Scalars['String']>;
  totalClicks?: Maybe<Scalars['Int']>;
};

export type TrackingLinkInput = {
  _id?: Maybe<Scalars['ObjectId']>;
  longUrl?: Maybe<Scalars['String']>;
  customLink?: Maybe<Scalars['String']>;
  type?: Maybe<TrackingLinkType>;
  target?: Maybe<Scalars['String']>;
  actionType?: Maybe<Scalars['String']>;
  assignedProfile?: Maybe<Scalars['String']>;
};

export type TrackingLinkMetric = {
  __typename?: 'TrackingLinkMetric';
  value?: Maybe<Scalars['String']>;
  clicks?: Maybe<Scalars['Int']>;
};

export enum TrackingLinkType {
  Campaign = 'CAMPAIGN',
  Report = 'REPORT',
  Deliverable = 'DELIVERABLE'
}

export type User = {
  __typename?: 'User';
  _id: Scalars['ObjectId'];
  firstName?: Maybe<Scalars['String']>;
  /** @deprecated Use 'firstName'. */
  first_name?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  /** @deprecated Use 'lastName'. */
  last_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  profileCount?: Maybe<Scalars['Int']>;
  type?: Maybe<UserType>;
  timezone?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  /** @deprecated Use 'companyName'. */
  company_name?: Maybe<Scalars['String']>;
  /** @deprecated Use 'companyName'. */
  agency?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<UserAddress>;
  businessIdentifier?: Maybe<Scalars['String']>;
  paymentDetails?: Maybe<UserPaymentDetails>;
  avatar?: Maybe<Scalars['String']>;
  inviteSent?: Maybe<Scalars['Date']>;
  invite_sent?: Maybe<Scalars['Date']>;
  walkthrough?: Maybe<Array<Maybe<Scalars['String']>>>;
  accountProgress?: Maybe<Array<Maybe<Scalars['String']>>>;
  signupPlanId?: Maybe<Scalars['String']>;
  invitedByBrandId?: Maybe<Scalars['ObjectId']>;
  invitedByTalentId?: Maybe<Scalars['ObjectId']>;
  invitedFromAmbassadorId?: Maybe<Scalars['ObjectId']>;
  country?: Maybe<Scalars['String']>;
  referrer?: Maybe<Scalars['String']>;
  isNotificationOn?: Maybe<Scalars['Boolean']>;
  planId?: Maybe<Scalars['String']>;
  isMfaEnabled?: Maybe<Scalars['Boolean']>;
  doesRequireReauthentication?: Maybe<Scalars['Boolean']>;
  isEmailVerified?: Maybe<Scalars['Boolean']>;
  isAppleVerified?: Maybe<Scalars['Boolean']>;
  isYoutubeVerified?: Maybe<Scalars['Boolean']>;
  isFacebookVerified?: Maybe<Scalars['Boolean']>;
  isGoogleVerified?: Maybe<Scalars['Boolean']>;
  isTiktokVerified?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isAdminVerified?: Maybe<Scalars['Boolean']>;
  isAddedToMonday?: Maybe<Scalars['Boolean']>;
  emailVerificationToken?: Maybe<Scalars['String']>;
  /** @deprecated Use 'emailVerificationToken'. */
  email_verification_token?: Maybe<Scalars['String']>;
  adminVerificationToken?: Maybe<Scalars['String']>;
  /** @deprecated Use 'adminVerificationToken'. */
  admin_verification_token?: Maybe<Scalars['String']>;
  braintreeSubscriptionId?: Maybe<Scalars['String']>;
  braintreeCustomerId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeTaxRate?: Maybe<Scalars['String']>;
  stripeTaxRateDetails?: Maybe<StripeTaxRate>;
  profileProgress?: Maybe<Scalars['Int']>;
  longToken?: Maybe<Scalars['String']>;
  facebookMarketingToken?: Maybe<Scalars['String']>;
  doesRequireTrackApproval?: Maybe<Scalars['Boolean']>;
  lastLoggedIn?: Maybe<Scalars['Date']>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
  marketingOptIn?: Maybe<Scalars['Boolean']>;
  activeNotifications?: Maybe<ActiveNotifications>;
  sqs_url?: Maybe<Scalars['String']>;
  awsTemporaryCredentials?: Maybe<AwsTemporaryCredentials>;
  talentInviteLink?: Maybe<Scalars['String']>;
  googleUserId?: Maybe<Scalars['String']>;
  facebookUserId?: Maybe<Scalars['String']>;
  facebookAccountName?: Maybe<Scalars['String']>;
  appleUserId?: Maybe<Scalars['String']>;
  ambassadors?: Maybe<Array<Maybe<Ambassador>>>;
  ambassadorCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  signupInfo?: Maybe<UserSignupInfo>;
  isAgency?: Maybe<Scalars['Boolean']>;
  websiteUrl?: Maybe<Scalars['String']>;
  tiktokUnionId?: Maybe<Scalars['String']>;
  agentId?: Maybe<Scalars['ObjectId']>;
  facebookUser?: Maybe<FacebookUser>;
  googleRefreshToken?: Maybe<Scalars['String']>;
  signupCouponCode?: Maybe<Scalars['String']>;
  userAuditReportLink?: Maybe<Scalars['String']>;
  dashboardConfiguration?: Maybe<UserDashboardConfiguration>;
  talentConfiguration?: Maybe<UserTalentConfiguration>;
  customLandingPageUrl?: Maybe<Scalars['String']>;
  customLandingPageThumbnail?: Maybe<Scalars['String']>;
  pendingLandingPageRequest?: Maybe<Scalars['Boolean']>;
  whiteLabelConfig?: Maybe<UserWhiteLabelConfig>;
  name?: Maybe<Scalars['String']>;
  longTokenPermissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  facebookMarketingTokenPermissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  influencerPlan?: Maybe<InfluencerPlan>;
  profiles?: Maybe<Array<Profile>>;
  instagram?: Maybe<Array<SocialProfile>>;
  youtube?: Maybe<Array<SocialProfile>>;
  facebook?: Maybe<Array<SocialProfile>>;
  appSubscription?: Maybe<AppSubscription>;
  braintreeSubscription?: Maybe<BraintreeSubscription>;
  braintreeCustomer?: Maybe<BraintreeCustomer>;
  stripeCustomer?: Maybe<StripeCustomer>;
  accountProgressPercentage?: Maybe<Scalars['Float']>;
  ambassadorsCount?: Maybe<Scalars['Int']>;
  delegatesCount?: Maybe<Scalars['Int']>;
  talentsCount?: Maybe<Scalars['Int']>;
  contactsCount?: Maybe<Scalars['Int']>;
  delegate?: Maybe<Delegate>;
  /** @deprecated No longer relevant. */
  limitUsage?: Maybe<LimitUsage>;
  activeSubscription?: Maybe<Scalars['String']>;
  reportsUsage?: Maybe<FeatureUsage>;
  mediaKitsUsage?: Maybe<FeatureUsage>;
  collaborationsUsage?: Maybe<FeatureUsage>;
  invoicesUsage?: Maybe<FeatureUsage>;
  profilesUsage?: Maybe<FeatureUsage>;
  delegatesUsage?: Maybe<FeatureUsage>;
  ambassadorsUsage?: Maybe<FeatureUsage>;
  intercomHash?: Maybe<Scalars['String']>;
  isFirstLogin?: Maybe<Scalars['Boolean']>;
  availableFacebookAdAccounts?: Maybe<Array<Maybe<Scalars['String']>>>;
  bearerToken?: Maybe<Scalars['String']>;
};

export type UserAddress = {
  __typename?: 'UserAddress';
  streetAddress?: Maybe<Scalars['String']>;
  suburb?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type UserAddressUpdate = {
  streetAddress?: Maybe<Scalars['String']>;
  suburb?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export enum UserCurrency {
  Usd = 'USD',
  Gbp = 'GBP',
  Eur = 'EUR',
  Aud = 'AUD',
  Nzd = 'NZD',
  Cad = 'CAD',
  Jpy = 'JPY',
  Inr = 'INR'
}

export type UserDashboardConfiguration = {
  __typename?: 'UserDashboardConfiguration';
  firstColumn?: Maybe<Array<Maybe<Scalars['String']>>>;
  secondColumn?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserDashboardConfigurationUpdates = {
  firstColumn?: Maybe<Array<Maybe<Scalars['String']>>>;
  secondColumn?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserDetails = {
  __typename?: 'UserDetails';
  intercomUserHash?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  error?: Maybe<Scalars['String']>;
  delegate?: Maybe<Delegate>;
};

export type UserForCode = {
  __typename?: 'UserForCode';
  userId?: Maybe<Scalars['ObjectId']>;
  dataAccessToken?: Maybe<Scalars['String']>;
  ambassadorId?: Maybe<Scalars['ObjectId']>;
  user?: Maybe<User>;
  ambassador?: Maybe<Ambassador>;
};

export type UserFriendlyCacheError = {
  __typename?: 'UserFriendlyCacheError';
  error?: Maybe<Scalars['String']>;
  explanation?: Maybe<Scalars['String']>;
  nextSteps?: Maybe<Scalars['String']>;
  linkUrl?: Maybe<Scalars['String']>;
};

export type UserListResponse = {
  __typename?: 'UserListResponse';
  list?: Maybe<Array<User>>;
  total?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
};

export type UserLoginInput = {
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  facebookLoginToken?: Maybe<Scalars['String']>;
  facebookUserId?: Maybe<Scalars['String']>;
  googleIdToken?: Maybe<Scalars['String']>;
  googleUserId?: Maybe<Scalars['String']>;
  appleUserId?: Maybe<Scalars['String']>;
  appleIdentityToken?: Maybe<Scalars['String']>;
  appleLoginCode?: Maybe<Scalars['String']>;
  appleAuthorizationCode?: Maybe<Scalars['String']>;
  tiktokUnionIdToken?: Maybe<Scalars['String']>;
  trustToken?: Maybe<Scalars['String']>;
  authDebugInfo?: Maybe<Scalars['JSObject']>;
  integrationKey?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  shouldStayLoggedIn?: Maybe<Scalars['Boolean']>;
};

export type UserLoginToken = {
  __typename?: 'UserLoginToken';
  type?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type UserPaymentDetails = {
  __typename?: 'UserPaymentDetails';
  currency?: Maybe<Scalars['String']>;
  accountName?: Maybe<Scalars['String']>;
  /** @deprecated Use 'bankCodes'. */
  accountBsb?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  taxAmount?: Maybe<Scalars['Float']>;
  taxType?: Maybe<Scalars['String']>;
  bankCode1?: Maybe<Scalars['String']>;
  bankValue1?: Maybe<Scalars['String']>;
  bankCode2?: Maybe<Scalars['String']>;
  bankValue2?: Maybe<Scalars['String']>;
};

export type UserPaymentDetailsUpdate = {
  currency?: Maybe<UserCurrency>;
  accountName?: Maybe<Scalars['String']>;
  accountBsb?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  taxAmount?: Maybe<Scalars['Float']>;
  taxType?: Maybe<Scalars['String']>;
  bankCode1?: Maybe<BankCode>;
  bankValue1?: Maybe<Scalars['String']>;
  bankCode2?: Maybe<BankCode>;
  bankValue2?: Maybe<Scalars['String']>;
};

export type UserReward = {
  __typename?: 'UserReward';
  _id: Scalars['ObjectId'];
  userId?: Maybe<Scalars['String']>;
  rybbonReferenceCode?: Maybe<Scalars['String']>;
  claim_url?: Maybe<Scalars['String']>;
  gift_image_url?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  rybbonReward?: Maybe<RybbonReward>;
};

export type UserSignupInfo = {
  __typename?: 'UserSignupInfo';
  whatAreYouLookingFor?: Maybe<Scalars['String']>;
};

export type UserSignupInfoInput = {
  whatAreYouLookingFor?: Maybe<Scalars['String']>;
};

export type UserTalentConfiguration = {
  __typename?: 'UserTalentConfiguration';
  columns?: Maybe<Array<Maybe<Scalars['String']>>>;
  brandProfileColumns?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserTalentConfigurationUpdates = {
  columns?: Maybe<Array<Maybe<Scalars['String']>>>;
  brandProfileColumns?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum UserType {
  Brand = 'BRAND',
  Influencer = 'INFLUENCER',
  MediaPr = 'MEDIA_PR',
  TalentAgency = 'TALENT_AGENCY'
}

export type UserUpdates = {
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<UserAddressUpdate>;
  businessIdentifier?: Maybe<Scalars['String']>;
  paymentDetails?: Maybe<UserPaymentDetailsUpdate>;
  country?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  referrer?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isEmailVerified?: Maybe<Scalars['Boolean']>;
  isFacebookVerified?: Maybe<Scalars['Boolean']>;
  isAdminVerified?: Maybe<Scalars['Boolean']>;
  isAddedToMonday?: Maybe<Scalars['Boolean']>;
  influencerPlan?: Maybe<Scalars['String']>;
  influencer_plan?: Maybe<Scalars['String']>;
  isNotificationOn?: Maybe<Scalars['Boolean']>;
  type?: Maybe<UserType>;
  avatar?: Maybe<Scalars['Upload']>;
  isMfaEnabled?: Maybe<Scalars['Boolean']>;
  marketingOptIn?: Maybe<Scalars['Boolean']>;
  activeNotifications?: Maybe<ActiveNotificationsUpdate>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  ambassadorCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  signupInfo?: Maybe<UserSignupInfoInput>;
  isAgency?: Maybe<Scalars['Boolean']>;
  websiteUrl?: Maybe<Scalars['String']>;
  facebookUserId?: Maybe<Scalars['String']>;
  facebookAccessToken?: Maybe<Scalars['String']>;
  facebookAccountName?: Maybe<Scalars['String']>;
  googleIdToken?: Maybe<Scalars['String']>;
  googleAuthCode?: Maybe<Scalars['String']>;
  googleAccountName?: Maybe<Scalars['String']>;
  tiktokUnionIdToken?: Maybe<Scalars['String']>;
  appleUserId?: Maybe<Scalars['String']>;
  appleIdentityToken?: Maybe<Scalars['String']>;
  appleAuthorizationCode?: Maybe<Scalars['String']>;
  authDebugInfo?: Maybe<Scalars['JSObject']>;
  doesRequireTrackApproval?: Maybe<Scalars['Boolean']>;
  detected_timezone?: Maybe<Scalars['String']>;
  dashboardConfiguration?: Maybe<UserDashboardConfigurationUpdates>;
  talentConfiguration?: Maybe<UserTalentConfigurationUpdates>;
};

export type UserWhiteLabelConfig = {
  __typename?: 'UserWhiteLabelConfig';
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  emailLogoUrl?: Maybe<Scalars['String']>;
  emailLogoWidth?: Maybe<Scalars['String']>;
};

export type VideoContent = {
  __typename?: 'VideoContent';
  type?: Maybe<ElementType>;
  video_content?: Maybe<Scalars['String']>;
};

export type YoutubeChannel = {
  __typename?: 'YoutubeChannel';
  id: Scalars['ID'];
  kind?: Maybe<Scalars['String']>;
  etag?: Maybe<Scalars['String']>;
  snippet?: Maybe<YoutubeChannelSnippet>;
};

export type YoutubeChannelContentDetails = {
  __typename?: 'YoutubeChannelContentDetails';
  relatedPlaylists?: Maybe<YoutubePlaylistInfo>;
};

export type YoutubeChannelInput = {
  id?: Maybe<Scalars['String']>;
  snippet?: Maybe<Scalars['JSObject']>;
};

export type YoutubeChannelSnippet = {
  __typename?: 'YoutubeChannelSnippet';
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['String']>;
  thumbnails?: Maybe<YoutubeThumbnailList>;
  localized?: Maybe<YoutubeLocalizedInfo>;
  contentDetails?: Maybe<YoutubeChannelContentDetails>;
  statistics?: Maybe<YoutubeStatistics>;
};

export type YoutubeHistorical = {
  __typename?: 'YoutubeHistorical';
  _id?: Maybe<Scalars['ObjectId']>;
  count?: Maybe<Scalars['Int']>;
  profileId?: Maybe<Scalars['ID']>;
  date?: Maybe<Scalars['Date']>;
  start?: Maybe<Scalars['String']>;
  averageViewDuration?: Maybe<Scalars['Int']>;
  annotationClickThroughRate?: Maybe<Scalars['Float']>;
  averageViewPercentage?: Maybe<Scalars['Float']>;
  estimatedMinutesWatched?: Maybe<Scalars['Int']>;
  subscribersGained?: Maybe<Scalars['Int']>;
  subscribersLost?: Maybe<Scalars['Int']>;
  views?: Maybe<Scalars['Int']>;
  change?: Maybe<YoutubeHistorical>;
};

export type YoutubeHistoricalStats = {
  __typename?: 'YoutubeHistoricalStats';
  _id?: Maybe<Scalars['ObjectId']>;
  count?: Maybe<Scalars['Int']>;
  profileId?: Maybe<Scalars['ID']>;
  date?: Maybe<Scalars['Date']>;
  start?: Maybe<Scalars['String']>;
  averageViewDuration?: Maybe<Scalars['Int']>;
  annotationClickThroughRate?: Maybe<Scalars['Float']>;
  averageViewPercentage?: Maybe<Scalars['Float']>;
  estimatedMinutesWatched?: Maybe<Scalars['Int']>;
  subscribersGained?: Maybe<Scalars['Int']>;
  subscribersLost?: Maybe<Scalars['Int']>;
  views?: Maybe<Scalars['Int']>;
  change?: Maybe<YoutubeHistorical>;
  order?: Maybe<Scalars['Int']>;
  latestRecordedDate?: Maybe<Scalars['Date']>;
  dayDirectory?: Maybe<Scalars['JSObject']>;
  days?: Maybe<Array<Maybe<YoutubeHistorical>>>;
};

export type YoutubeLocalizedInfo = {
  __typename?: 'YoutubeLocalizedInfo';
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type YoutubeMedia = {
  __typename?: 'YoutubeMedia';
  publishedAt?: Maybe<Scalars['Date']>;
  channelId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  thumbnails?: Maybe<Thumbnails>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  categoryId?: Maybe<Scalars['String']>;
  liveBroadcastContent?: Maybe<Scalars['String']>;
  _id: Scalars['ObjectId'];
  id: Scalars['ID'];
  profileId?: Maybe<Scalars['String']>;
  videoId?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  playlistId?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  resourceId?: Maybe<ResourceId>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
  /** @deprecated Use views. */
  totalViews?: Maybe<Scalars['Int']>;
  views?: Maybe<Scalars['Int']>;
  redViews?: Maybe<Scalars['Int']>;
  comments?: Maybe<Scalars['Int']>;
  likes?: Maybe<Scalars['Int']>;
  dislikes?: Maybe<Scalars['Int']>;
  videosAddedToPlaylists?: Maybe<Scalars['Int']>;
  videosRemovedFromPlaylists?: Maybe<Scalars['Int']>;
  shares?: Maybe<Scalars['Int']>;
  /** @deprecated Use estimatedMinutesWatched. */
  estimatedTotalMinutesWatched?: Maybe<Scalars['Int']>;
  estimatedMinutesWatched?: Maybe<Scalars['Int']>;
  estimatedRedMinutesWatched?: Maybe<Scalars['Int']>;
  averageViewDuration?: Maybe<Scalars['Int']>;
  averageViewPercentage?: Maybe<Scalars['Float']>;
  engagement?: Maybe<Scalars['Float']>;
  annotationClickThroughRate?: Maybe<Scalars['Float']>;
  annotationCloseRate?: Maybe<Scalars['Float']>;
  annotationImpressions?: Maybe<Scalars['Int']>;
  annotationClickableImpressions?: Maybe<Scalars['Int']>;
  annotationClosableImpressions?: Maybe<Scalars['Int']>;
  annotationClicks?: Maybe<Scalars['Int']>;
  annotationCloses?: Maybe<Scalars['Int']>;
  cardClickRate?: Maybe<Scalars['Float']>;
  cardTeaserClickRate?: Maybe<Scalars['Float']>;
  cardImpressions?: Maybe<Scalars['Int']>;
  cardTeaserImpressions?: Maybe<Scalars['Int']>;
  cardClicks?: Maybe<Scalars['Int']>;
  cardTeaserClicks?: Maybe<Scalars['Int']>;
  subscribersGained?: Maybe<Scalars['Int']>;
  subscribersLost?: Maybe<Scalars['Int']>;
  contentDetails?: Maybe<YoutubeVideoContentDetails>;
  status?: Maybe<YoutubeVideoStatus>;
  topicDetails?: Maybe<YoutubeVideoTopicDetails>;
  fileDetails?: Maybe<YoutubeVideoFileDetails>;
  processingDetails?: Maybe<YoutubeVideoProcessingDetails>;
  liveStreamingDetails?: Maybe<YoutubeVideoLiveStreamingDetails>;
  statistics?: Maybe<YoutubeVideoStatistics>;
  timestamp?: Maybe<Scalars['Date']>;
  profile?: Maybe<Profile>;
  history?: Maybe<Array<Maybe<YoutubeMediaHistorical>>>;
};

export type YoutubeMediaAverages = {
  __typename?: 'YoutubeMediaAverages';
  date?: Maybe<Scalars['Date']>;
  views?: Maybe<Scalars['Int']>;
  redViews?: Maybe<Scalars['Int']>;
  /** @deprecated Use views. */
  totalViews?: Maybe<Scalars['Int']>;
  subscribersGained?: Maybe<Scalars['Int']>;
  subscribersLost?: Maybe<Scalars['Int']>;
  estimatedMinutesWatched?: Maybe<Scalars['Int']>;
  estimatedRedMinutesWatched?: Maybe<Scalars['Int']>;
  /** @deprecated Use estimatedMinutesWatched. */
  estimatedTotalMinutesWatched?: Maybe<Scalars['Int']>;
  averageViewDuration?: Maybe<Scalars['Float']>;
  averageViewPercentage?: Maybe<Scalars['Float']>;
  annotationClickThroughRate?: Maybe<Scalars['Float']>;
};

export type YoutubeMediaHistorical = {
  __typename?: 'YoutubeMediaHistorical';
  views?: Maybe<Scalars['Int']>;
  videoId?: Maybe<Scalars['String']>;
  estimatedMinutesWatched?: Maybe<Scalars['Int']>;
  likes?: Maybe<Scalars['Int']>;
  dislikes?: Maybe<Scalars['Int']>;
  subscribersGained?: Maybe<Scalars['Int']>;
  comments?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['Date']>;
  foundDate?: Maybe<Scalars['Date']>;
};

export type YoutubeMediaHistoricalAverages = {
  __typename?: 'YoutubeMediaHistoricalAverages';
  views?: Maybe<YoutubeMetricSummary>;
  estimatedMinutesWatched?: Maybe<YoutubeMetricSummary>;
  likes?: Maybe<YoutubeMetricSummary>;
  dislikes?: Maybe<YoutubeMetricSummary>;
  subscribersGained?: Maybe<YoutubeMetricSummary>;
  comments?: Maybe<YoutubeMetricSummary>;
};

export type YoutubeMediaHistoryResult = {
  __typename?: 'YoutubeMediaHistoryResult';
  mediaHistoricalCount?: Maybe<Scalars['Int']>;
  youtubeMediaHistorical?: Maybe<Array<Maybe<YoutubeMediaHistorical>>>;
  youtubeMediaHistoricalAverages?: Maybe<YoutubeMediaHistoricalAverages>;
};

export type YoutubeMediaListResult = {
  __typename?: 'YoutubeMediaListResult';
  mediaCount?: Maybe<Scalars['Int']>;
  youtubeMedia?: Maybe<Array<Maybe<YoutubeMedia>>>;
  youtubeMediaAverages?: Maybe<Scalars['JSObject']>;
};

export type YoutubeMediaStats = {
  __typename?: 'YoutubeMediaStats';
  videoCount?: Maybe<Scalars['Int']>;
  /** @deprecated Use views. */
  totalViews?: Maybe<YoutubeMetricSummary>;
  views?: Maybe<YoutubeMetricSummary>;
  redViews?: Maybe<YoutubeMetricSummary>;
  comments?: Maybe<YoutubeMetricSummary>;
  likes?: Maybe<YoutubeMetricSummary>;
  dislikes?: Maybe<YoutubeMetricSummary>;
  videosAddedToPlaylists?: Maybe<YoutubeMetricSummary>;
  videosRemovedFromPlaylists?: Maybe<YoutubeMetricSummary>;
  shares?: Maybe<YoutubeMetricSummary>;
  /** @deprecated Use estimatedMinutesWatched. */
  estimatedTotalMinutesWatched?: Maybe<YoutubeMetricSummary>;
  estimatedMinutesWatched?: Maybe<YoutubeMetricSummary>;
  estimatedRedMinutesWatched?: Maybe<YoutubeMetricSummary>;
  averageViewDuration?: Maybe<YoutubeMetricSummary>;
  annotationClickThroughRate?: Maybe<YoutubeMetricSummary>;
  annotationCloseRate?: Maybe<YoutubeMetricSummary>;
  annotationImpressions?: Maybe<YoutubeMetricSummary>;
  annotationClickableImpressions?: Maybe<YoutubeMetricSummary>;
  annotationClosableImpressions?: Maybe<YoutubeMetricSummary>;
  annotationClicks?: Maybe<YoutubeMetricSummary>;
  annotationCloses?: Maybe<YoutubeMetricSummary>;
  cardClickRate?: Maybe<YoutubeMetricSummary>;
  cardTeaserClickRate?: Maybe<YoutubeMetricSummary>;
  cardImpressions?: Maybe<YoutubeMetricSummary>;
  cardTeaserImpressions?: Maybe<YoutubeMetricSummary>;
  cardClicks?: Maybe<YoutubeMetricSummary>;
  cardTeaserClicks?: Maybe<YoutubeMetricSummary>;
  netSubscribers?: Maybe<YoutubeMetricSummary>;
  subscribersGained?: Maybe<YoutubeMetricSummary>;
  subscribersLost?: Maybe<YoutubeMetricSummary>;
  engagement?: Maybe<YoutubeMetricSummary>;
  engagement_percentageOfViews?: Maybe<YoutubeMetricSummary>;
};

export type YoutubeMediaTrends = {
  __typename?: 'YoutubeMediaTrends';
  dailyAverages?: Maybe<Array<Maybe<YoutubeMediaAverages>>>;
  recentAverages?: Maybe<YoutubeMediaAverages>;
  comparisonAverages?: Maybe<YoutubeMediaAverages>;
};

export type YoutubeMetricSummary = {
  __typename?: 'YoutubeMetricSummary';
  actual?: Maybe<Scalars['Int']>;
  median?: Maybe<Scalars['Float']>;
  average?: Maybe<Scalars['Float']>;
};

export type YoutubePlaylistInfo = {
  __typename?: 'YoutubePlaylistInfo';
  likes?: Maybe<Scalars['String']>;
  favorites?: Maybe<Scalars['String']>;
  uploads?: Maybe<Scalars['String']>;
  watchHistory?: Maybe<Scalars['String']>;
  watchLater?: Maybe<Scalars['String']>;
};

export type YoutubeProfile = {
  __typename?: 'YoutubeProfile';
  _id: Scalars['ObjectId'];
  isAddedByOwner?: Maybe<Scalars['Boolean']>;
  agentId?: Maybe<Scalars['String']>;
  biography?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** @deprecated Use cacheError */
  cache_error?: Maybe<Scalars['String']>;
  cache_completed_at?: Maybe<Scalars['Date']>;
  created_at?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  historical_from?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  pageId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  media_cached_at?: Maybe<Scalars['Date']>;
  profileCategory?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use 'profileCategory'. */
  profile_category?: Maybe<Array<Maybe<Scalars['String']>>>;
  profileType?: Maybe<SocialChannel>;
  /** @deprecated Use 'profileType'. */
  profile_type?: Maybe<Scalars['String']>;
  profile_cached_at?: Maybe<Scalars['Date']>;
  profile_media_kit?: Maybe<MediaKit>;
  profile_picture_url?: Maybe<Scalars['String']>;
  redflags_from?: Maybe<Scalars['Date']>;
  redflags_until?: Maybe<Scalars['Date']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['Date']>;
  userId?: Maybe<Scalars['String']>;
  isRestricted?: Maybe<Scalars['Boolean']>;
  isPendingConnection?: Maybe<Scalars['Boolean']>;
  hasAcceptedInvite?: Maybe<Scalars['Boolean']>;
  doesRequireTrackApproval?: Maybe<Scalars['Boolean']>;
  selectedMediaKitId?: Maybe<Scalars['ObjectId']>;
  profileAuditReportLink?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  media_count?: Maybe<Scalars['Float']>;
  followers_count?: Maybe<Scalars['Int']>;
  channelStats?: Maybe<ChannelStats>;
  fan_count?: Maybe<Scalars['Int']>;
  published_post_count?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['String']>;
  union_id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  profile_cache_attempts?: Maybe<Scalars['Int']>;
  media_cache_attempts?: Maybe<Scalars['Int']>;
  last_cache_attempts?: Maybe<Scalars['Int']>;
  cache_error_at?: Maybe<Scalars['Date']>;
  cache_error_resolved_at?: Maybe<Scalars['Date']>;
  last_cache_error?: Maybe<Scalars['String']>;
  user_notified_at?: Maybe<Scalars['Date']>;
  agency_notified_at?: Maybe<Scalars['Date']>;
  historical_until?: Maybe<Scalars['Date']>;
  assignedToAmbassadorId?: Maybe<Scalars['ObjectId']>;
  profile_added_at?: Maybe<Scalars['Date']>;
  ownerNotes?: Maybe<Scalars['String']>;
  ownerTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  isInstagramIncomingMentionsEnabled?: Maybe<Scalars['Boolean']>;
  isInstagramFeedWebhookEnabled?: Maybe<Scalars['Boolean']>;
  pageStats?: Maybe<FacebookPageStats>;
  postStats?: Maybe<FacebookPostStats>;
  historicalFacebookStats?: Maybe<Array<FacebookPageStats>>;
  weeklyFacebookStats?: Maybe<Array<FacebookWeeklyStats>>;
  rankedFacebookMedia?: Maybe<RankedFacebookMedia>;
  latestFacebookPosts?: Maybe<Array<FacebookPost>>;
  facebookPostTrends?: Maybe<FacebookMediaTrends>;
  recentFacebookMediaStats?: Maybe<RecentMediaStats>;
  lifetimeInsights?: Maybe<LifetimeInsights>;
  historicalStats?: Maybe<InstagramHistoricalStats>;
  onlineFollowers?: Maybe<Scalars['JSObject']>;
  /** @deprecated Use rankedInstagramMedia.topMedia instead */
  rankedInstagramPosts?: Maybe<Array<InstagramMedia>>;
  rankedInstagramMedia?: Maybe<RankedInstagramMedia>;
  rankedInstagramFeed?: Maybe<RankedInstagramMedia>;
  rankedInstagramReels?: Maybe<RankedInstagramMedia>;
  latestInstagramPosts?: Maybe<Array<InstagramMedia>>;
  latestInstagramFeed?: Maybe<Array<InstagramMedia>>;
  latestInstagramReels?: Maybe<Array<InstagramMedia>>;
  latestInstagramStories?: Maybe<Array<InstagramStory>>;
  dailyInstagramStats?: Maybe<Array<InstagramHistorical>>;
  weeklyInstagramStats?: Maybe<Array<InstagramHistorical>>;
  mediaStats?: Maybe<MediaStats>;
  instagramFeedStats?: Maybe<MediaStats>;
  instagramReelStats?: Maybe<MediaStats>;
  storyStats?: Maybe<MediaStats>;
  instagramFeedTrends?: Maybe<InstagramMediaTrends>;
  instagramReelTrends?: Maybe<InstagramMediaTrends>;
  instagramStoryTrends?: Maybe<InstagramStoryTrends>;
  instagramCombinedTrends?: Maybe<InstagramCombinedTrends>;
  followerBracket?: Maybe<Scalars['Int']>;
  latestPerformanceReport?: Maybe<PerformanceReport>;
  instagramMediaMentions?: Maybe<Array<InstagramMedia>>;
  recentInstagramMediaStats?: Maybe<RecentInstagramMediaStats>;
  subscribedWebhookFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  cacheError?: Maybe<Scalars['String']>;
  userFriendlyCacheError?: Maybe<UserFriendlyCacheError>;
  user?: Maybe<User>;
  agent?: Maybe<User>;
  cacheSeconds?: Maybe<Scalars['Int']>;
  mediaKitConfigs?: Maybe<Array<Maybe<MediaKitConfig>>>;
  ownerMediaKitConfigs?: Maybe<Array<MediaKitConfig>>;
  profileNote?: Maybe<ProfileNote>;
  dataAccessToken?: Maybe<Scalars['String']>;
  followerCount?: Maybe<Scalars['Int']>;
  selectedMediaKit?: Maybe<MediaKitConfig>;
  isVisibleOnNetwork?: Maybe<Scalars['Boolean']>;
  mediaKits?: Maybe<Array<MediaKitConfig>>;
  thumbnails?: Maybe<Thumbnails>;
  title?: Maybe<Scalars['String']>;
  videoStats?: Maybe<YoutubeMediaStats>;
  snippet?: Maybe<Scalars['String']>;
  ageGroupBreakdown?: Maybe<Array<Maybe<AgeGroup>>>;
  genderBreakdown?: Maybe<Array<Maybe<GenderGroup>>>;
  locationBreakdown?: Maybe<Array<Maybe<LocationGroup>>>;
  myChannelReport?: Maybe<Array<Maybe<ChannelReport>>>;
  subscriberChange?: Maybe<Array<Maybe<SubscriberChange>>>;
  youtubeMedia?: Maybe<Array<YoutubeMedia>>;
  youtubeHistorical?: Maybe<Array<YoutubeHistorical>>;
  youtubeWeeklyStats?: Maybe<Array<YoutubeHistoricalStats>>;
  rankedYoutubeMedia?: Maybe<RankedYoutubeMedia>;
  youtubeVideoTrends?: Maybe<YoutubeMediaTrends>;
  recentYoutubeMediaStats?: Maybe<RecentMediaStats>;
  public?: Maybe<TiktokPublicUser>;
  tiktokMediaStats?: Maybe<TiktokMediaStats>;
  tiktokMediaTrends?: Maybe<TiktokMediaTrends>;
  rankedTiktokMedia?: Maybe<TikTokRankedMedia>;
  recentTiktokMediaStats?: Maybe<RecentMediaStats>;
  customUrl?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['Date']>;
  localized?: Maybe<Localized>;
  kind?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
};

export type YoutubeProfileWeeklyFacebookStatsArgs = {
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type YoutubeProfileWeeklyInstagramStatsArgs = {
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type YoutubeProfileYoutubeWeeklyStatsArgs = {
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type YoutubeStatistics = {
  __typename?: 'YoutubeStatistics';
  viewCount?: Maybe<Scalars['Int']>;
  commentCount?: Maybe<Scalars['Int']>;
  subscriberCount?: Maybe<Scalars['Int']>;
  hiddenSubscriberCount?: Maybe<Scalars['Int']>;
  videoCount?: Maybe<Scalars['Int']>;
};

export type YoutubeThumbnail = {
  __typename?: 'YoutubeThumbnail';
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
};

export type YoutubeThumbnailList = {
  __typename?: 'YoutubeThumbnailList';
  default?: Maybe<YoutubeThumbnail>;
  medium?: Maybe<YoutubeThumbnail>;
  high?: Maybe<YoutubeThumbnail>;
};

export type YoutubeVideoContentDetails = {
  __typename?: 'YoutubeVideoContentDetails';
  duration?: Maybe<Scalars['String']>;
  dimension?: Maybe<Scalars['String']>;
  definition?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  licensedContent?: Maybe<Scalars['Boolean']>;
  contentRating?: Maybe<Scalars['JSObject']>;
  projection?: Maybe<Scalars['String']>;
  hasCustomThumbnail?: Maybe<Scalars['Boolean']>;
};

export type YoutubeVideoFileDetails = {
  __typename?: 'YoutubeVideoFileDetails';
  fileName?: Maybe<Scalars['String']>;
};

export type YoutubeVideoLiveStreamingDetails = {
  __typename?: 'YoutubeVideoLiveStreamingDetails';
  actualStartTime?: Maybe<Scalars['Date']>;
  actualEndTime?: Maybe<Scalars['Date']>;
};

export type YoutubeVideoProcessingDetails = {
  __typename?: 'YoutubeVideoProcessingDetails';
  processingStatus?: Maybe<Scalars['String']>;
  fileDetailsAvailability?: Maybe<Scalars['String']>;
  processingIssuesAvailability?: Maybe<Scalars['String']>;
  tagSuggestionsAvailability?: Maybe<Scalars['String']>;
  editorSuggestionsAvailability?: Maybe<Scalars['String']>;
  thumbnailsAvailability?: Maybe<Scalars['String']>;
};

export type YoutubeVideoSnippet = {
  __typename?: 'YoutubeVideoSnippet';
  publishedAt?: Maybe<Scalars['Date']>;
  channelId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  thumbnails?: Maybe<Thumbnails>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  categoryId?: Maybe<Scalars['String']>;
  liveBroadcastContent?: Maybe<Scalars['String']>;
};

export type YoutubeVideoStatistics = {
  __typename?: 'YoutubeVideoStatistics';
  viewCount?: Maybe<Scalars['Int']>;
  likeCount?: Maybe<Scalars['Int']>;
  dislikeCount?: Maybe<Scalars['Int']>;
  favoriteCount?: Maybe<Scalars['Int']>;
  commentCount?: Maybe<Scalars['Int']>;
};

export type YoutubeVideoStatus = {
  __typename?: 'YoutubeVideoStatus';
  uploadStatus?: Maybe<Scalars['String']>;
  privacyStatus?: Maybe<Scalars['String']>;
  license?: Maybe<Scalars['String']>;
  embeddable?: Maybe<Scalars['Boolean']>;
  publicStatsViewable?: Maybe<Scalars['Boolean']>;
  madeForKids?: Maybe<Scalars['Boolean']>;
};

export type YoutubeVideoTopicDetails = {
  __typename?: 'YoutubeVideoTopicDetails';
  topicCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UpdateAmbassadorMutationVariables = Exact<{
  inputValues?: Maybe<AmbassadorInput>;
}>;

export type UpdateAmbassadorMutation = { __typename?: 'Mutation' } & {
  updateAmbassadorInvitation: { __typename?: 'ServerResponse' } & Pick<
    ServerResponse,
    'insertedId' | 'success' | 'message'
  >;
};

export type DeleteCollaborationMutationVariables = Exact<{
  id: Scalars['ObjectId'];
}>;

export type DeleteCollaborationMutation = { __typename?: 'Mutation' } & {
  deleteCollaboration: { __typename?: 'ServerResponse' } & Pick<ServerResponse, 'success' | 'message'>;
};

export type CreateCollaborationMutationVariables = Exact<{
  updates: CollaborationUpdates;
}>;

export type CreateCollaborationMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'createCollaboration'>;

export type UpdateCollaborationMutationVariables = Exact<{
  id: Scalars['ObjectId'];
  updates: CollaborationUpdates;
}>;

export type UpdateCollaborationMutation = { __typename?: 'Mutation' } & {
  updateCollaboration: { __typename?: 'CollaborationUpdateResponse' } & Pick<
    CollaborationUpdateResponse,
    'success' | 'message'
  >;
};

export type CreateContactMutationVariables = Exact<{
  updates: ContactUpdates;
}>;

export type CreateContactMutation = { __typename?: 'Mutation' } & {
  createContact?: Maybe<{ __typename?: 'ServerResponse' } & Pick<ServerResponse, 'success' | 'message' | 'insertedId'>>;
};

export type UpdateContactMutationVariables = Exact<{
  id: Scalars['ObjectId'];
  updates: ContactUpdates;
}>;

export type UpdateContactMutation = { __typename?: 'Mutation' } & {
  updateContact: { __typename?: 'ServerResponse' } & Pick<ServerResponse, 'success' | 'message'>;
};

export type DeleteContactMutationVariables = Exact<{
  id: Scalars['ObjectId'];
}>;

export type DeleteContactMutation = { __typename?: 'Mutation' } & {
  deleteContact: { __typename?: 'ServerResponse' } & Pick<ServerResponse, 'success' | 'message'>;
};

export type ConnectContactMutationVariables = Exact<{
  id: Scalars['ObjectId'];
}>;

export type ConnectContactMutation = { __typename?: 'Mutation' } & {
  connectContact: { __typename?: 'ConnectReponse' } & Pick<ConnectReponse, 'isKBUserExisted'>;
};

export type CreateDeliverableMutationVariables = Exact<{
  updates: DeliverableUpdates;
  newTrackingLink?: Maybe<TrackingLinkInput>;
}>;

export type CreateDeliverableMutation = { __typename?: 'Mutation' } & {
  createMultipleDeliverables: { __typename?: 'ServerResponse' } & Pick<
    ServerResponse,
    'success' | 'message' | 'insertedIdList'
  >;
};

export type UpdateDeliverableMutationVariables = Exact<{
  id: Scalars['ObjectId'];
  updates: DeliverableUpdates;
  newTrackingLink?: Maybe<TrackingLinkInput>;
}>;

export type UpdateDeliverableMutation = { __typename?: 'Mutation' } & {
  updateAndCreateDeliverables: { __typename?: 'ServerResponse' } & Pick<
    ServerResponse,
    'success' | 'message' | 'insertedIdList'
  >;
};

export type DeleteDeliverableMutationVariables = Exact<{
  id: Scalars['ObjectId'];
}>;

export type DeleteDeliverableMutation = { __typename?: 'Mutation' } & {
  deleteDeliverable: { __typename?: 'ServerResponse' } & Pick<ServerResponse, 'success' | 'message'>;
};

export type CreateInvoiceMutationVariables = Exact<{
  updates: CollaborationInvoiceUpdates;
}>;

export type CreateInvoiceMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'createCollaborationInvoice'>;

export type UpdateInvoiceMutationVariables = Exact<{
  id: Scalars['ObjectId'];
  updates: CollaborationInvoiceUpdates;
}>;

export type UpdateInvoiceMutation = { __typename?: 'Mutation' } & {
  updateCollaborationInvoice: { __typename?: 'ServerResponse' } & Pick<
    ServerResponse,
    'success' | 'message' | 'insertedId'
  >;
};

export type DeleteInvoiceMutationVariables = Exact<{
  id: Scalars['ObjectId'];
}>;

export type DeleteInvoiceMutation = { __typename?: 'Mutation' } & {
  deleteCollaborationInvoice: { __typename?: 'ServerResponse' } & Pick<ServerResponse, 'success' | 'message'>;
};

export type ShareInvoiceMutationVariables = Exact<{
  id: Scalars['ObjectId'];
  shareDetails: ShareCollaborationInvoiceDetails;
}>;

export type ShareInvoiceMutation = { __typename?: 'Mutation' } & {
  shareCollaborationInvoice: { __typename?: 'ServerResponse' } & Pick<ServerResponse, 'success'>;
};

export type CreateCollaborationInvoiceLinkMutationVariables = Exact<{
  collaborationInvoiceId: Scalars['ObjectId'];
}>;

export type CreateCollaborationInvoiceLinkMutation = { __typename?: 'Mutation' } & {
  createCollaborationInvoiceLink: { __typename?: 'CollaborationInvoiceLinkCreationResult' } & Pick<
    CollaborationInvoiceLinkCreationResult,
    'link'
  >;
};

export type DeleteMediaKitConfigMutationVariables = Exact<{
  id: Scalars['ObjectId'];
}>;

export type DeleteMediaKitConfigMutation = { __typename?: 'Mutation' } & {
  deleteMediaKitConfig: { __typename?: 'ServerResponse' } & Pick<ServerResponse, 'success'>;
};

export type UpdateMediaKitDraftConfigMutationVariables = Exact<{
  id: Scalars['ObjectId'];
  updates: MediaKitConfigUpdates;
}>;

export type UpdateMediaKitDraftConfigMutation = { __typename?: 'Mutation' } & {
  updateMediaKitDraftConfig: { __typename?: 'ServerResponse' } & Pick<ServerResponse, 'success' | 'message'>;
};

export type CreateElementMutationVariables = Exact<{
  type?: Maybe<ElementType>;
  title?: Maybe<Scalars['String']>;
  mediaKitId?: Maybe<Scalars['ObjectId']>;
  index?: Maybe<Scalars['Int']>;
}>;

export type CreateElementMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'createElement'>;

export type PublishMediaKitDraftConfigMutationVariables = Exact<{
  id: Scalars['ObjectId'];
}>;

export type PublishMediaKitDraftConfigMutation = { __typename?: 'Mutation' } & {
  publishMediaKitDraftConfig: { __typename?: 'ServerResponse' } & Pick<ServerResponse, 'success' | 'message'>;
};

export type UpdateElementDraftMutationVariables = Exact<{
  id: Scalars['ObjectId'];
  content?: Maybe<MediaKitElementContentInput>;
}>;

export type UpdateElementDraftMutation = { __typename?: 'Mutation' } & {
  updateElementDraft: { __typename?: 'ServerResponse' } & Pick<ServerResponse, 'success' | 'message'>;
};

export type DeleteElementMutationVariables = Exact<{
  id: Scalars['ObjectId'];
}>;

export type DeleteElementMutation = { __typename?: 'Mutation' } & {
  deleteElement: { __typename?: 'ServerResponse' } & Pick<ServerResponse, 'success' | 'message'>;
};

export type ShareMediaKitToContactMutationVariables = Exact<{
  mediaKitId: Scalars['ObjectId'];
  contactIds?: Maybe<Array<Maybe<Scalars['ObjectId']>> | Maybe<Scalars['ObjectId']>>;
  coverLetter?: Maybe<Scalars['String']>;
  contactDetails?: Maybe<MediaKitShareContact>;
}>;

export type ShareMediaKitToContactMutation = { __typename?: 'Mutation' } & {
  shareMediaKitToContact: { __typename?: 'ServerResponse' } & Pick<ServerResponse, 'success' | 'message'>;
};

export type UpdateMediaKitConfigMutationVariables = Exact<{
  id: Scalars['ObjectId'];
  updates: MediaKitConfigUpdates;
}>;

export type UpdateMediaKitConfigMutation = { __typename?: 'Mutation' } & {
  updateMediaKitConfig: { __typename?: 'ServerResponse' } & Pick<ServerResponse, 'success' | 'message'>;
};

export type CreateMediaKitLinkMutationVariables = Exact<{
  mediaKitId: Scalars['ObjectId'];
}>;

export type CreateMediaKitLinkMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'createMediaKitLink'>;

export type CreateMediaKitConfigMutationVariables = Exact<{
  updates?: Maybe<MediaKitConfigUpdates>;
}>;

export type CreateMediaKitConfigMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'createMediaKitConfig'>;

export type ShareAparLinkToContactMutationVariables = Exact<{
  aparLink: Scalars['String'];
  profileId?: Maybe<Scalars['ObjectId']>;
  contactId?: Maybe<Scalars['ObjectId']>;
  contactIds?: Maybe<Array<Maybe<Scalars['ObjectId']>> | Maybe<Scalars['ObjectId']>>;
  contactDetails?: Maybe<MediaKitShareContact>;
}>;

export type ShareAparLinkToContactMutation = { __typename?: 'Mutation' } & {
  shareAparToContact: { __typename?: 'ServerResponse' } & Pick<ServerResponse, 'success' | 'message' | 'insertedId'>;
};

export type UpdateNotificationReadStatusMutationVariables = Exact<{
  notificationId: Scalars['ObjectId'];
}>;

export type UpdateNotificationReadStatusMutation = { __typename?: 'Mutation' } & {
  updateNotificationReadStatus: { __typename?: 'ServerResponse' } & Pick<ServerResponse, 'success' | 'message'>;
};

export type CreateProfileMutationVariables = Exact<{
  info: NewProfileInfo;
}>;

export type CreateProfileMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'createProfile'>;

export type DeleteProfileMutationVariables = Exact<{
  profileId: Scalars['ObjectId'];
  pageId?: Maybe<Scalars['String']>;
}>;

export type DeleteProfileMutation = { __typename?: 'Mutation' } & {
  deleteProfile: { __typename?: 'ServerResponse' } & Pick<ServerResponse, 'success' | 'message'>;
};

export type UpdateProfileMutationVariables = Exact<{
  profileId: Scalars['ObjectId'];
  updates: ProfileUpdates;
}>;

export type UpdateProfileMutation = { __typename?: 'Mutation' } & {
  updateProfile: { __typename?: 'ServerResponse' } & Pick<ServerResponse, 'success' | 'message'>;
};

export type UpdateProfileNoteMutationVariables = Exact<{
  accountId: Scalars['String'];
  updates: ProfileNoteInput;
}>;

export type UpdateProfileNoteMutation = { __typename?: 'Mutation' } & {
  updateProfileNote: { __typename?: 'ServerResponse' } & Pick<ServerResponse, 'success'>;
};

export type CreateTiktokProfileMutationVariables = Exact<{
  username: Scalars['String'];
}>;

export type CreateTiktokProfileMutation = { __typename?: 'Mutation' } & {
  createTiktokProfile?: Maybe<
    { __typename?: 'ServerResponse' } & Pick<ServerResponse, 'success' | 'message' | 'insertedId'>
  >;
};

export type ConnectTiktokProfileMutationVariables = Exact<{
  profileId: Scalars['String'];
}>;

export type ConnectTiktokProfileMutation = { __typename?: 'Mutation' } & {
  connectTiktokProfile: { __typename?: 'ServerResponse' } & Pick<ServerResponse, 'success'>;
};

export type DisconnectTiktokProfileMutationVariables = Exact<{
  profileId: Scalars['String'];
}>;

export type DisconnectTiktokProfileMutation = { __typename?: 'Mutation' } & {
  disconnectTiktokProfile: { __typename?: 'ServerResponse' } & Pick<ServerResponse, 'success'>;
};

export type DeleteReportConfigMutationVariables = Exact<{
  reportId: Scalars['ObjectId'];
}>;

export type DeleteReportConfigMutation = { __typename?: 'Mutation' } & {
  deleteReportConfig: { __typename?: 'ServerResponse' } & Pick<ServerResponse, 'success'>;
};

export type CreateReportLinkMutationVariables = Exact<{
  reportConfigId: Scalars['ObjectId'];
}>;

export type CreateReportLinkMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'createReportLink'>;

export type CreateSupplementMediaMutationVariables = Exact<{
  input: SupplementMediaInput;
}>;

export type CreateSupplementMediaMutation = { __typename?: 'Mutation' } & {
  createSupplementMedia: { __typename?: 'ServerResponse' } & Pick<ServerResponse, 'success' | 'insertedId' | 'message'>;
};

export type UpdateSupplementMediaMutationVariables = Exact<{
  id: Scalars['ObjectId'];
  updates: SupplementMediaInput;
}>;

export type UpdateSupplementMediaMutation = { __typename?: 'Mutation' } & {
  updateSupplementMedia: { __typename?: 'ServerResponse' } & Pick<ServerResponse, 'success' | 'message'>;
};

export type DeleteSupplementMediaMutationVariables = Exact<{
  id: Scalars['ObjectId'];
}>;

export type DeleteSupplementMediaMutation = { __typename?: 'Mutation' } & {
  deleteSupplementMedia: { __typename?: 'ServerResponse' } & Pick<ServerResponse, 'success' | 'message'>;
};

export type ClearOrphanedLinksMutationVariables = Exact<{ [key: string]: never }>;

export type ClearOrphanedLinksMutation = { __typename?: 'Mutation' } & {
  clearOrphanedLinks: { __typename?: 'ServerResponse' } & Pick<ServerResponse, 'success' | 'message'>;
};

export type CreateTrackingLinkMutationVariables = Exact<{
  updates?: Maybe<TrackingLinkInput>;
}>;

export type CreateTrackingLinkMutation = { __typename?: 'Mutation' } & {
  createTrackingLink: { __typename?: 'TrackingLink' } & Pick<TrackingLink, '_id'>;
};

export type DeleteTrackingLinkMutationVariables = Exact<{
  trackingLinkId?: Maybe<Scalars['ObjectId']>;
}>;

export type DeleteTrackingLinkMutation = { __typename?: 'Mutation' } & {
  deleteTrackingLink: { __typename?: 'ServerResponse' } & Pick<ServerResponse, 'success'>;
};

export type UpdateWalkthroughsMutationVariables = Exact<{
  update?: Maybe<Scalars['String']>;
}>;

export type UpdateWalkthroughsMutation = { __typename?: 'Mutation' } & {
  updateWalkthroughs: { __typename?: 'ServerResponse' } & Pick<ServerResponse, 'success' | 'message'>;
};

export type UpdateUserProgressMutationVariables = Exact<{
  steps?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;

export type UpdateUserProgressMutation = { __typename?: 'Mutation' } & {
  updateAccountProgress: { __typename?: 'ServerResponse' } & Pick<ServerResponse, 'success' | 'message'>;
};

export type UpdateUserMutationVariables = Exact<{
  updates: UserUpdates;
}>;

export type UpdateUserMutation = { __typename?: 'Mutation' } & {
  updateUser: { __typename?: 'ServerResponse' } & Pick<ServerResponse, 'success' | 'message'>;
};

export type DeleteMyUserMutationVariables = Exact<{ [key: string]: never }>;

export type DeleteMyUserMutation = { __typename?: 'Mutation' } & {
  deleteMyUser: { __typename?: 'ServerResponse' } & Pick<ServerResponse, 'success' | 'message'>;
};

export type DeleteSubscriptionScheduleForCustomerMutationVariables = Exact<{ [key: string]: never }>;

export type DeleteSubscriptionScheduleForCustomerMutation = { __typename?: 'Mutation' } & {
  deleteSubscriptionScheduleForCustomer: { __typename?: 'ServerResponse' } & Pick<
    ServerResponse,
    'success' | 'message'
  >;
};

export type SetPaymentMethodToDefaultMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type SetPaymentMethodToDefaultMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'setPaymentMethodToDefault'
>;

export type DeletePaymentMethodMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeletePaymentMethodMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'deletePaymentMethod'>;

export type UserLogoutMutationVariables = Exact<{ [key: string]: never }>;

export type UserLogoutMutation = { __typename?: 'Mutation' } & {
  logout: { __typename?: 'ServerResponse' } & Pick<ServerResponse, 'success' | 'message'>;
};

export type GetMyUserStripeChargesQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyUserStripeChargesQuery = { __typename?: 'Query' } & {
  myUser?: Maybe<
    { __typename?: 'User' } & Pick<User, '_id' | 'email'> & {
        stripeCustomer?: Maybe<
          { __typename?: 'StripeCustomer' } & {
            charges?: Maybe<
              Array<
                { __typename?: 'StripeCharge' } & Pick<
                  StripeCharge,
                  'id' | 'amount' | 'created' | 'receipt_url' | 'paid'
                >
              >
            >;
          }
        >;
      }
  >;
};

export type GetMyUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyUserQuery = { __typename?: 'Query' } & {
  myUser?: Maybe<
    { __typename?: 'User' } & Pick<
      User,
      | '_id'
      | 'type'
      | 'businessIdentifier'
      | 'companyName'
      | 'country'
      | 'email'
      | 'firstName'
      | 'lastName'
      | 'avatar'
      | 'isNotificationOn'
      | 'isFacebookVerified'
      | 'isYoutubeVerified'
      | 'doesRequireReauthentication'
      | 'activeSubscription'
      | 'marketingOptIn'
      | 'timezone'
      | 'walkthrough'
      | 'stripeAccountId'
      | 'stripeTaxRate'
    > & {
        stripeTaxRateDetails?: Maybe<
          { __typename?: 'StripeTaxRate' } & Pick<
            StripeTaxRate,
            | 'id'
            | 'active'
            | 'country'
            | 'created'
            | 'description'
            | 'display_name'
            | 'inclusive'
            | 'jurisdiction'
            | 'livemode'
            | 'metadata'
            | 'percentage'
            | 'state'
          >
        >;
        activeNotifications?: Maybe<
          { __typename?: 'ActiveNotifications' } & Pick<
            ActiveNotifications,
            | 'isAssignNewDeliverables'
            | 'isMediaKitActivity'
            | 'isReportActivity'
            | 'isRememberDeliverables'
            | 'isSyncDeliverableToCalendar'
          >
        >;
        billingAddress?: Maybe<
          { __typename?: 'UserAddress' } & Pick<UserAddress, 'streetAddress' | 'suburb' | 'state' | 'country'>
        >;
        paymentDetails?: Maybe<
          { __typename?: 'UserPaymentDetails' } & Pick<
            UserPaymentDetails,
            | 'currency'
            | 'accountNumber'
            | 'accountName'
            | 'taxAmount'
            | 'taxType'
            | 'bankCode1'
            | 'bankValue1'
            | 'bankCode2'
            | 'bankValue2'
          >
        >;
      }
  >;
};

export type GetMyUserAwsCredentialsQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyUserAwsCredentialsQuery = { __typename?: 'Query' } & {
  myUser?: Maybe<
    { __typename?: 'User' } & Pick<User, 'sqs_url'> & {
        awsTemporaryCredentials?: Maybe<
          { __typename?: 'AWSTemporaryCredentials' } & Pick<
            AwsTemporaryCredentials,
            'AccessKeyId' | 'SecretAccessKey' | 'SessionToken' | 'Expiration' | 'region' | 'apiVersion'
          >
        >;
      }
  >;
};

export type GetMyUserUsageQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyUserUsageQuery = { __typename?: 'Query' } & {
  myUser?: Maybe<
    { __typename?: 'User' } & Pick<
      User,
      | '_id'
      | 'type'
      | 'businessIdentifier'
      | 'companyName'
      | 'country'
      | 'email'
      | 'firstName'
      | 'lastName'
      | 'avatar'
      | 'isNotificationOn'
      | 'isFacebookVerified'
      | 'doesRequireReauthentication'
      | 'activeSubscription'
      | 'marketingOptIn'
      | 'timezone'
      | 'walkthrough'
      | 'signupPlanId'
    > & {
        paymentDetails?: Maybe<{ __typename?: 'UserPaymentDetails' } & Pick<UserPaymentDetails, 'currency'>>;
        reportsUsage?: Maybe<{ __typename?: 'FeatureUsage' } & Pick<FeatureUsage, 'limit' | 'count'>>;
        mediaKitsUsage?: Maybe<{ __typename?: 'FeatureUsage' } & Pick<FeatureUsage, 'limit' | 'count'>>;
        collaborationsUsage?: Maybe<{ __typename?: 'FeatureUsage' } & Pick<FeatureUsage, 'limit' | 'count'>>;
        invoicesUsage?: Maybe<{ __typename?: 'FeatureUsage' } & Pick<FeatureUsage, 'limit' | 'count'>>;
        profilesUsage?: Maybe<{ __typename?: 'FeatureUsage' } & Pick<FeatureUsage, 'limit' | 'count'>>;
      }
  >;
};

export type GetUserPaymentHistoryQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserPaymentHistoryQuery = { __typename?: 'Query' } & {
  myUser?: Maybe<
    { __typename?: 'User' } & Pick<
      User,
      | '_id'
      | 'type'
      | 'businessIdentifier'
      | 'companyName'
      | 'country'
      | 'email'
      | 'firstName'
      | 'lastName'
      | 'avatar'
      | 'isNotificationOn'
      | 'isFacebookVerified'
      | 'doesRequireReauthentication'
      | 'activeSubscription'
      | 'marketingOptIn'
      | 'timezone'
      | 'walkthrough'
      | 'signupPlanId'
    > & {
        appSubscription?: Maybe<
          { __typename?: 'AppSubscription' } & Pick<
            AppSubscription,
            | '_id'
            | 'productId'
            | 'userId'
            | 'purchaseTime'
            | 'orderId'
            | 'invalidatedById'
            | 'created_at'
            | 'updated_at'
          >
        >;
        influencerPlan?: Maybe<{ __typename?: 'InfluencerPlan' } & Pick<InfluencerPlan, 'planId' | 'code'>>;
        stripeCustomer?: Maybe<
          { __typename?: 'StripeCustomer' } & Pick<StripeCustomer, 'defaultPaymentMethod' | 'id' | 'default_source'> & {
              firstSubscription?: Maybe<
                { __typename?: 'StripeSubscription' } & Pick<
                  StripeSubscription,
                  'id' | 'status' | 'current_period_end'
                > & {
                    firstItem?: Maybe<
                      { __typename?: 'StripeSubscriptionItem' } & {
                        price?: Maybe<{ __typename?: 'StripePrice' } & Pick<StripePrice, 'id'>>;
                      }
                    >;
                  }
              >;
              activeSubscriptionSchedules?: Maybe<
                Array<
                  { __typename?: 'StripeSubscriptionSchedule' } & Pick<StripeSubscriptionSchedule, 'id'> & {
                      current_phase?: Maybe<
                        { __typename?: 'StripeSubscriptionScheduleCurrentPhase' } & Pick<
                          StripeSubscriptionScheduleCurrentPhase,
                          'end_date'
                        >
                      >;
                    }
                >
              >;
              cards?: Maybe<
                Array<
                  { __typename?: 'StripePaymentMethod' } & Pick<StripePaymentMethod, 'id'> & {
                      card?: Maybe<
                        { __typename?: 'StripeCard' } & Pick<
                          StripeCard,
                          'brand' | 'country' | 'last4' | 'exp_month' | 'exp_year'
                        >
                      >;
                    }
                >
              >;
            }
        >;
        braintreeSubscription?: Maybe<
          { __typename?: 'BraintreeSubscription' } & Pick<
            BraintreeSubscription,
            | 'id'
            | 'status'
            | 'balance'
            | 'daysPastDue'
            | 'paidThroughDate'
            | 'planId'
            | 'price'
            | 'nextBillAmount'
            | 'nextBillingDate'
            | 'nextBillingPeriodAmount'
            | 'firstBillingDate'
            | 'paymentMethodToken'
          > & {
              transactions?: Maybe<
                Array<
                  { __typename?: 'BraintreeTransaction' } & Pick<
                    BraintreeTransaction,
                    | 'id'
                    | 'amount'
                    | 'type'
                    | 'refundedTransactionId'
                    | 'taxAmount'
                    | 'taxExempt'
                    | 'createdAt'
                    | 'paymentMethodType'
                    | 'paymentMethodDescription'
                    | 'paymentMethodImageUrl'
                  >
                >
              >;
            }
        >;
      }
  >;
};

export type GetMyUserPaymentMethodsQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyUserPaymentMethodsQuery = { __typename?: 'Query' } & {
  myUser?: Maybe<
    { __typename?: 'User' } & Pick<User, '_id' | 'email'> & {
        braintreeCustomer?: Maybe<
          { __typename?: 'BraintreeCustomer' } & {
            transactions?: Maybe<
              Array<
                { __typename?: 'BraintreeTransaction' } & Pick<
                  BraintreeTransaction,
                  'id' | 'paymentMethodType' | 'paymentMethodDescription' | 'amount' | 'createdAt' | 'status'
                >
              >
            >;
          }
        >;
      }
  >;
};

export type GetAmbassadorProfilesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAmbassadorProfilesQuery = { __typename?: 'Query' } & {
  getAmbassadorProfiles: Array<
    { __typename?: 'Profile' } & Pick<
      Profile,
      | '_id'
      | 'id'
      | 'userId'
      | 'username'
      | 'profile_picture_url'
      | 'profileType'
      | 'displayName'
      | 'name'
      | 'timezone'
      | 'doesRequireTrackApproval'
      | 'cacheError'
      | 'followers_count'
      | 'isPendingConnection'
    > & {
        profileNote?: Maybe<{ __typename?: 'ProfileNote' } & Pick<ProfileNote, '_id' | 'notes' | 'categories'>>;
        mediaStats?: Maybe<
          { __typename?: 'MediaStats' } & {
            reach?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'median'>>;
            impressions?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'average'>>;
            engagement_percentageOfReach?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'median'>>;
          }
        >;
        storyStats?: Maybe<
          { __typename?: 'MediaStats' } & {
            reach?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'median'>>;
          }
        >;
        channelStats?: Maybe<{ __typename?: 'ChannelStats' } & Pick<ChannelStats, 'subscriberCount'>>;
        videoStats?: Maybe<
          { __typename?: 'YoutubeMediaStats' } & {
            views?: Maybe<{ __typename?: 'YoutubeMetricSummary' } & Pick<YoutubeMetricSummary, 'average'>>;
            likes?: Maybe<{ __typename?: 'YoutubeMetricSummary' } & Pick<YoutubeMetricSummary, 'average'>>;
            dislikes?: Maybe<{ __typename?: 'YoutubeMetricSummary' } & Pick<YoutubeMetricSummary, 'average'>>;
            comments?: Maybe<{ __typename?: 'YoutubeMetricSummary' } & Pick<YoutubeMetricSummary, 'average'>>;
            shares?: Maybe<{ __typename?: 'YoutubeMetricSummary' } & Pick<YoutubeMetricSummary, 'average'>>;
            engagement_percentageOfViews?: Maybe<
              { __typename?: 'YoutubeMetricSummary' } & Pick<YoutubeMetricSummary, 'average'>
            >;
          }
        >;
        pageStats?: Maybe<
          { __typename?: 'FacebookPageStats' } & {
            page_positive_feedback_by_type?: Maybe<
              { __typename?: 'PAGE_POSITIVE_FEEDBACK_BY_TYPE' } & Pick<
                Page_Positive_Feedback_By_Type,
                'answer' | 'claim' | 'comment' | 'like' | 'link' | 'other' | 'rsvp'
              >
            >;
            page_negative_feedback_by_type?: Maybe<
              { __typename?: 'PAGE_NEGATIVE_FEEDBACK_BY_TYPE' } & Pick<
                Page_Negative_Feedback_By_Type,
                'hide_clicks' | 'hide_all_clicks' | 'report_spam_clicks' | 'unlike_page_clicks'
              >
            >;
          }
        >;
        postStats?: Maybe<
          { __typename?: 'FacebookPostStats' } & {
            post_reactions_like_total?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'actual'>>;
            post_impressions?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'average'>>;
          }
        >;
      }
  >;
};

export type GetAmbassadorMediaKitsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAmbassadorMediaKitsQuery = { __typename?: 'Query' } & {
  getAmbassadorList: Array<
    { __typename?: 'Ambassador' } & Pick<Ambassador, '_id'> & {
        profileUser?: Maybe<
          { __typename?: 'User' } & Pick<
            User,
            'firstName' | 'lastName' | 'country' | 'avatar' | 'doesRequireTrackApproval'
          >
        >;
        profiles?: Maybe<
          Array<
            { __typename?: 'Profile' } & Pick<
              Profile,
              '_id' | 'id' | 'displayName' | 'profile_picture_url' | 'profileType'
            >
          >
        >;
        mediaKits?: Maybe<Array<{ __typename?: 'MediaKitConfig' } & Pick<MediaKitConfig, '_id' | 'status' | 'title'>>>;
      }
  >;
};

export type GetAmbassadorListQueryVariables = Exact<{ [key: string]: never }>;

export type GetAmbassadorListQuery = { __typename?: 'Query' } & {
  getAmbassadorListByUserId: Array<
    { __typename?: 'Ambassador' } & Pick<
      Ambassador,
      '_id' | 'acceptedInvite' | 'ambassadorId' | 'created_at' | 'isArchive' | 'userId'
    > & {
        profiles?: Maybe<Array<{ __typename?: 'Profile' } & Pick<Profile, '_id' | 'id' | 'displayName'>>>;
        brand?: Maybe<
          { __typename?: 'User' } & Pick<User, '_id' | 'companyName' | 'firstName' | 'lastName' | 'avatar' | 'email'>
        >;
        contact?: Maybe<
          { __typename?: 'Contact' } & Pick<
            Contact,
            | '_id'
            | 'name'
            | 'type'
            | 'companyName'
            | 'firstName'
            | 'lastName'
            | 'avatar'
            | 'email'
            | 'phone'
            | 'role'
            | 'created_at'
          > & {
              children?: Maybe<
                Array<
                  { __typename?: 'Contact' } & Pick<
                    Contact,
                    '_id' | 'firstName' | 'lastName' | 'avatar' | 'email' | 'phone' | 'role'
                  >
                >
              >;
            }
        >;
      }
  >;
};

export type GetCollaborationListQueryVariables = Exact<{
  id?: Maybe<Scalars['ObjectId']>;
  status?: Maybe<CollaborationStatus>;
}>;

export type GetCollaborationListQuery = { __typename?: 'Query' } & {
  getCollaborationList: Array<
    { __typename?: 'Collaboration' } & Pick<
      Collaboration,
      '_id' | 'name' | 'avatar' | 'status' | 'canEdit' | 'created_at' | 'profileTags' | 'notes' | 'profileIds'
    > & {
        brand?: Maybe<{ __typename?: 'Contact' } & Pick<Contact, '_id' | 'avatar' | 'companyName'>>;
        fileUploads?: Maybe<Array<Maybe<{ __typename?: 'LinkPair' } & Pick<LinkPair, 'link' | 'title'>>>>;
        profiles?: Maybe<Array<{ __typename?: 'Profile' } & Pick<Profile, '_id' | 'id' | 'displayName'>>>;
        deliverables?: Maybe<
          Array<
            { __typename?: 'Deliverable' } & Pick<
              Deliverable,
              | '_id'
              | 'canEdit'
              | 'startDate'
              | 'startTime'
              | 'socialChannel'
              | 'socialDeliverable'
              | 'deliverableName'
              | 'discount'
              | 'price'
              | 'currency'
              | 'endDate'
              | 'endTime'
              | 'notes'
              | 'userId'
              | 'storyFrameCount'
              | 'isViewed'
            > & {
                trackingLinks?: Maybe<
                  Array<
                    { __typename?: 'TrackingLink' } & Pick<
                      TrackingLink,
                      '_id' | 'link' | 'actionType' | 'longUrl' | 'totalClicks'
                    >
                  >
                >;
                collaboration?: Maybe<
                  { __typename?: 'Collaboration' } & Pick<Collaboration, '_id' | 'name' | 'status' | 'canEdit'> & {
                      brand?: Maybe<{ __typename?: 'Contact' } & Pick<Contact, '_id' | 'avatar' | 'companyName'>>;
                    }
                >;
                profiles?: Maybe<
                  Array<
                    { __typename?: 'Profile' } & Pick<
                      Profile,
                      '_id' | 'id' | 'profile_picture_url' | 'displayName' | 'name'
                    > & { user?: Maybe<{ __typename?: 'User' } & Pick<User, '_id' | 'avatar'>> }
                  >
                >;
                fileUploads?: Maybe<Array<Maybe<{ __typename?: 'LinkPair' } & Pick<LinkPair, 'link' | 'title'>>>>;
              }
          >
        >;
      }
  >;
};

export type GetCollaborationListIdsQueryVariables = Exact<{ [key: string]: never }>;

export type GetCollaborationListIdsQuery = { __typename?: 'Query' } & {
  getCollaborationList: Array<{ __typename?: 'Collaboration' } & Pick<Collaboration, '_id'>>;
};

export type GetCollaborationTotalsQueryVariables = Exact<{
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
}>;

export type GetCollaborationTotalsQuery = { __typename?: 'Query' } & {
  getCollaborationTotals: { __typename?: 'CollaborationTotalsResponse' } & {
    totals?: Maybe<
      Array<
        Maybe<
          { __typename?: 'CollaborationTotals' } & Pick<CollaborationTotals, 'currency' | 'contactId' | 'totalPrice'>
        >
      >
    >;
  };
};

export type GetBasicContactListQueryVariables = Exact<{ [key: string]: never }>;

export type GetBasicContactListQuery = { __typename?: 'Query' } & {
  getContactList: Array<
    { __typename?: 'Contact' } & Pick<Contact, '_id' | 'type' | 'avatar' | 'companyName' | 'email'> & {
        activity?: Maybe<
          Array<{ __typename?: 'ContactActivity' } & Pick<ContactActivity, 'itemId' | 'timestamp' | 'action' | 'type'>>
        >;
        children?: Maybe<
          Array<{ __typename?: 'Contact' } & Pick<Contact, '_id' | 'avatar' | 'firstName' | 'lastName' | 'type'>>
        >;
      }
  >;
};

export type GetCompanyContactListQueryVariables = Exact<{
  id?: Maybe<Scalars['ObjectId']>;
}>;

export type GetCompanyContactListQuery = { __typename?: 'Query' } & {
  getContactList: Array<
    { __typename?: 'Contact' } & Pick<
      Contact,
      '_id' | 'parentId' | 'type' | 'avatar' | 'firstName' | 'lastName' | 'companyName' | 'email'
    > & {
        activity?: Maybe<
          Array<
            { __typename?: 'ContactActivity' } & Pick<ContactActivity, 'itemId' | 'timestamp' | 'action' | 'type'> & {
                contact?: Maybe<{ __typename?: 'Contact' } & Pick<Contact, 'avatar' | 'email'>>;
              }
          >
        >;
        collaborations?: Maybe<
          Array<
            { __typename?: 'Collaboration' } & Pick<Collaboration, '_id' | 'name' | 'avatar' | 'status'> & {
                brand?: Maybe<{ __typename?: 'Contact' } & Pick<Contact, '_id' | 'companyName'>>;
                deliverables?: Maybe<
                  Array<
                    { __typename?: 'Deliverable' } & Pick<
                      Deliverable,
                      | '_id'
                      | 'socialChannel'
                      | 'notes'
                      | 'socialDeliverable'
                      | 'startDate'
                      | 'startTime'
                      | 'endDate'
                      | 'endTime'
                    > & {
                        collaboration?: Maybe<{ __typename?: 'Collaboration' } & Pick<Collaboration, '_id'>>;
                        profiles?: Maybe<
                          Array<
                            { __typename?: 'Profile' } & Pick<
                              Profile,
                              '_id' | 'id' | 'profile_picture_url' | 'username' | 'name'
                            >
                          >
                        >;
                      }
                  >
                >;
              }
          >
        >;
        children?: Maybe<
          Array<
            { __typename?: 'Contact' } & Pick<
              Contact,
              '_id' | 'avatar' | 'firstName' | 'lastName' | 'email' | 'phone' | 'role'
            >
          >
        >;
      }
  >;
};

export type GetContactsQueryVariables = Exact<{
  id?: Maybe<Scalars['ObjectId']>;
  type?: Maybe<ContactType>;
}>;

export type GetContactsQuery = { __typename?: 'Query' } & {
  getContactList: Array<
    { __typename?: 'Contact' } & Pick<
      Contact,
      | '_id'
      | 'parentId'
      | 'name'
      | 'type'
      | 'companyName'
      | 'created_at'
      | 'firstName'
      | 'lastName'
      | 'avatar'
      | 'email'
      | 'phone'
      | 'role'
    > & {
        parent?: Maybe<{ __typename?: 'Contact' } & Pick<Contact, '_id' | 'avatar' | 'companyName'>>;
        children?: Maybe<
          Array<
            { __typename?: 'Contact' } & Pick<
              Contact,
              '_id' | 'firstName' | 'lastName' | 'avatar' | 'email' | 'phone' | 'role' | 'type'
            >
          >
        >;
      }
  >;
  getAmbassadorListByUserId: Array<
    { __typename?: 'Ambassador' } & Pick<
      Ambassador,
      '_id' | 'acceptedInvite' | 'ambassadorId' | 'created_at' | 'isArchive' | 'userId' | 'status'
    > & {
        brand?: Maybe<
          { __typename?: 'User' } & Pick<User, '_id' | 'companyName' | 'firstName' | 'lastName' | 'avatar' | 'email'>
        >;
        contact?: Maybe<
          { __typename?: 'Contact' } & Pick<
            Contact,
            | '_id'
            | 'name'
            | 'type'
            | 'companyName'
            | 'firstName'
            | 'lastName'
            | 'avatar'
            | 'email'
            | 'phone'
            | 'role'
            | 'created_at'
          > & {
              children?: Maybe<
                Array<
                  { __typename?: 'Contact' } & Pick<
                    Contact,
                    '_id' | 'firstName' | 'lastName' | 'avatar' | 'email' | 'phone' | 'role' | 'type'
                  >
                >
              >;
            }
        >;
      }
  >;
};

export type GetCouponsQueryVariables = Exact<{ [key: string]: never }>;

export type GetCouponsQuery = { __typename?: 'Query' } & {
  getCoupons: Array<
    { __typename?: 'CouponCode' } & Pick<CouponCode, '_id' | 'code' | 'notes' | 'planId' | 'expiresAt'>
  >;
};

export type GetInvoicesQueryVariables = Exact<{
  id?: Maybe<Scalars['ObjectId']>;
  collaborationId?: Maybe<Scalars['ObjectId']>;
}>;

export type GetInvoicesQuery = { __typename?: 'Query' } & {
  getCollaborationInvoices: Array<
    { __typename?: 'CollaborationInvoice' } & Pick<
      CollaborationInvoice,
      | '_id'
      | 'name'
      | 'dueDate'
      | 'status'
      | 'invoiceId'
      | 'invoiceNumber'
      | 'invoiceToContactId'
      | 'collaborationId'
      | 'taxStatus'
      | 'taxType'
      | 'taxPercentage'
      | 'stripeInvoiceUrl'
      | 'deliverableIds'
      | 'totalPrice'
      | 'created_at'
    > & {
        stripeInvoice?: Maybe<
          { __typename?: 'StripeInvoice' } & Pick<StripeInvoice, 'id'> & {
              status_transitions?: Maybe<
                { __typename?: 'StripeStatusTransition' } & Pick<StripeStatusTransition, 'paid_at'>
              >;
            }
        >;
        collaboration?: Maybe<
          { __typename?: 'Collaboration' } & Pick<Collaboration, '_id' | 'name' | 'avatar'> & {
              brand?: Maybe<
                { __typename?: 'Contact' } & Pick<Contact, 'name' | 'email' | 'avatar' | 'firstName' | 'lastName'>
              >;
            }
        >;
        deliverables?: Maybe<
          Array<
            Maybe<
              { __typename?: 'Deliverable' } & Pick<
                Deliverable,
                '_id' | 'price' | 'socialChannel' | 'socialDeliverable'
              >
            >
          >
        >;
        profiles?: Maybe<
          Array<{ __typename?: 'Profile' } & Pick<Profile, '_id' | 'id' | 'displayName' | 'profileType'>>
        >;
        links?: Maybe<
          Array<
            { __typename?: 'CollaborationInvoiceLink' } & Pick<
              CollaborationInvoiceLink,
              '_id' | 'collaborationInvoiceId' | 'shortenedLink' | 'created_at'
            > & {
                contact?: Maybe<
                  { __typename?: 'Contact' } & Pick<
                    Contact,
                    '_id' | 'email' | 'avatar' | 'name' | 'firstName' | 'lastName' | 'companyName'
                  > & { parent?: Maybe<{ __typename?: 'Contact' } & Pick<Contact, 'name' | 'email'>> }
                >;
                contactDetails?: Maybe<
                  { __typename?: 'InvoiceShareContact' } & Pick<
                    InvoiceShareContact,
                    'email' | 'firstName' | 'lastName' | 'name'
                  >
                >;
              }
          >
        >;
      }
  >;
};

export type GetCollaborationInvoiceTotalsQueryVariables = Exact<{
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
}>;

export type GetCollaborationInvoiceTotalsQuery = { __typename?: 'Query' } & {
  getCollaborationInvoiceTotals?: Maybe<
    { __typename?: 'CollaborationInvoiceTotalsResponse' } & {
      totals?: Maybe<
        Array<
          Maybe<
            { __typename?: 'CollaborationInvoiceTotals' } & Pick<
              CollaborationInvoiceTotals,
              | 'currency'
              | 'contactId'
              | 'all'
              | 'max'
              | 'paid'
              | 'due'
              | 'overdue'
              | 'allPercentage'
              | 'paidPercentage'
              | 'duePercentage'
            > & { contact?: Maybe<{ __typename?: 'Contact' } & Pick<Contact, '_id' | 'name' | 'avatar'>> }
          >
        >
      >;
      invoices?: Maybe<
        Array<
          Maybe<
            { __typename?: 'CollaborationInvoice' } & Pick<
              CollaborationInvoice,
              | '_id'
              | 'name'
              | 'dueDate'
              | 'status'
              | 'invoiceId'
              | 'invoiceNumber'
              | 'invoiceToContactId'
              | 'collaborationId'
              | 'taxStatus'
              | 'taxType'
              | 'taxPercentage'
              | 'stripeInvoiceUrl'
              | 'deliverableIds'
              | 'totalPrice'
              | 'created_at'
            > & {
                stripeInvoice?: Maybe<
                  { __typename?: 'StripeInvoice' } & Pick<StripeInvoice, 'id'> & {
                      status_transitions?: Maybe<
                        { __typename?: 'StripeStatusTransition' } & Pick<StripeStatusTransition, 'paid_at'>
                      >;
                    }
                >;
                collaboration?: Maybe<
                  { __typename?: 'Collaboration' } & Pick<Collaboration, '_id' | 'name' | 'avatar'> & {
                      brand?: Maybe<
                        { __typename?: 'Contact' } & Pick<
                          Contact,
                          '_id' | 'name' | 'email' | 'avatar' | 'firstName' | 'lastName'
                        >
                      >;
                    }
                >;
                deliverables?: Maybe<
                  Array<
                    Maybe<
                      { __typename?: 'Deliverable' } & Pick<
                        Deliverable,
                        '_id' | 'price' | 'socialChannel' | 'socialDeliverable'
                      >
                    >
                  >
                >;
                profiles?: Maybe<
                  Array<{ __typename?: 'Profile' } & Pick<Profile, '_id' | 'id' | 'displayName' | 'profileType'>>
                >;
                links?: Maybe<
                  Array<
                    { __typename?: 'CollaborationInvoiceLink' } & Pick<
                      CollaborationInvoiceLink,
                      '_id' | 'collaborationInvoiceId' | 'shortenedLink' | 'created_at'
                    > & {
                        contact?: Maybe<
                          { __typename?: 'Contact' } & Pick<
                            Contact,
                            '_id' | 'email' | 'avatar' | 'name' | 'firstName' | 'lastName' | 'companyName'
                          > & { parent?: Maybe<{ __typename?: 'Contact' } & Pick<Contact, 'name' | 'email'>> }
                        >;
                        contactDetails?: Maybe<
                          { __typename?: 'InvoiceShareContact' } & Pick<
                            InvoiceShareContact,
                            'email' | 'firstName' | 'lastName' | 'name'
                          >
                        >;
                      }
                  >
                >;
              }
          >
        >
      >;
    }
  >;
};

export type GetMediaKitConfigListQueryVariables = Exact<{ [key: string]: never }>;

export type GetMediaKitConfigListQuery = { __typename?: 'Query' } & {
  getMediaKitConfigList: Array<
    { __typename?: 'MediaKitConfig' } & Pick<
      MediaKitConfig,
      | '_id'
      | 'title'
      | 'status'
      | 'isEmpty'
      | 'headerImage'
      | 'created_at'
      | 'updated_at'
      | 'socialChannels'
      | 'colorTheme'
      | 'colorHex'
      | 'backgroundColorTheme'
      | 'backgroundColorHex'
    > & {
        owner?: Maybe<{ __typename?: 'User' } & Pick<User, '_id' | 'avatar' | 'firstName' | 'lastName'>>;
        draft?: Maybe<
          { __typename?: 'MediaKitConfigDraft' } & Pick<
            MediaKitConfigDraft,
            'title' | 'colorTheme' | 'colorHex' | 'backgroundColorTheme' | 'backgroundColorHex'
          >
        >;
      }
  >;
};

export type GetMediaKitLinkListQueryVariables = Exact<{ [key: string]: never }>;

export type GetMediaKitLinkListQuery = { __typename?: 'Query' } & {
  getMediaKitLinkList: Array<
    { __typename?: 'MediaKitLink' } & Pick<
      MediaKitLink,
      '_id' | 'mediaKitId' | 'isOpened' | 'isExpired' | 'openedAt' | 'title' | 'shortenedLink' | 'created_at' | 'name'
    > & {
        contact?: Maybe<
          { __typename?: 'Contact' } & Pick<Contact, 'avatar' | 'name' | 'companyName' | 'firstName' | 'lastName'>
        >;
      }
  >;
};

export type GetMediaKitConfigQueryVariables = Exact<{
  id: Scalars['ObjectId'];
}>;

export type GetMediaKitConfigQuery = { __typename?: 'Query' } & {
  getMediaKitConfig?: Maybe<
    { __typename?: 'MediaKitConfig' } & Pick<
      MediaKitConfig,
      | '_id'
      | 'title'
      | 'headerImage'
      | 'status'
      | 'isEmpty'
      | 'colorTheme'
      | 'colorHex'
      | 'backgroundColorTheme'
      | 'backgroundColorHex'
      | 'created_at'
      | 'updated_at'
    > & {
        elements?: Maybe<
          Array<
            Maybe<
              { __typename?: 'MediaKitElement' } & Pick<MediaKitElement, '_id' | 'title' | 'stringifiedContent'> & {
                  draft?: Maybe<
                    { __typename?: 'MediaKitElementDraft' } & Pick<MediaKitElementDraft, 'title' | 'stringifiedContent'>
                  >;
                }
            >
          >
        >;
        draft?: Maybe<
          { __typename?: 'MediaKitConfigDraft' } & Pick<
            MediaKitConfigDraft,
            'title' | 'headerImage' | 'colorTheme' | 'colorHex' | 'backgroundColorTheme' | 'backgroundColorHex'
          > & {
              elements?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'MediaKitElement' } & Pick<
                      MediaKitElement,
                      '_id' | 'title' | 'stringifiedContent'
                    > & {
                        draft?: Maybe<
                          { __typename?: 'MediaKitElementDraft' } & Pick<
                            MediaKitElementDraft,
                            'title' | 'stringifiedContent'
                          >
                        >;
                      }
                  >
                >
              >;
            }
        >;
      }
  >;
};

export type GetNotificationQueryVariables = Exact<{
  recipientId: Scalars['String'];
  trigger?: Maybe<Scalars['String']>;
}>;

export type GetNotificationQuery = { __typename?: 'Query' } & {
  getNotification?: Maybe<
    { __typename?: 'ProfileNotification' } & Pick<ProfileNotification, '_id' | 'text' | 'isRead' | 'recipient'>
  >;
};

export type GetPlatformAdQueryVariables = Exact<{
  params?: Maybe<ContentfulPlatformAdParams>;
}>;

export type GetPlatformAdQuery = { __typename?: 'Query' } & {
  getPlatformAd?: Maybe<
    { __typename?: 'ContentfulPlatformAd' } & Pick<
      ContentfulPlatformAd,
      'mediaUrl' | 'linkUrl' | 'altText' | 'id' | 'title' | 'width' | 'height' | 'freeUserAd'
    >
  >;
};

export type GetProfilesQueryVariables = Exact<{
  profileType?: Maybe<Scalars['String']>;
  profileTypeList?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;

export type GetProfilesQuery = { __typename?: 'Query' } & {
  getProfiles: Array<
    { __typename?: 'Profile' } & Pick<
      Profile,
      | '_id'
      | 'id'
      | 'displayName'
      | 'username'
      | 'profileType'
      | 'followers_count'
      | 'fan_count'
      | 'profile_picture_url'
      | 'profile_category'
      | 'cache_completed_at'
      | 'profile_cached_at'
      | 'profileCategory'
      | 'snippet'
      | 'isRestricted'
      | 'cacheError'
    > & {
        pageStats?: Maybe<{ __typename?: 'FacebookPageStats' } & Pick<FacebookPageStats, 'page_fans'>>;
        channelStats?: Maybe<{ __typename?: 'ChannelStats' } & Pick<ChannelStats, 'subscriberCount'>>;
        thumbnails?: Maybe<
          { __typename?: 'Thumbnails' } & {
            default?: Maybe<{ __typename?: 'ThumbnailInfo' } & Pick<ThumbnailInfo, 'url'>>;
          }
        >;
        public?: Maybe<
          { __typename?: 'TiktokPublicUser' } & Pick<TiktokPublicUser, 'signature'> & {
              stats?: Maybe<{ __typename?: 'TiktokUserStats' } & Pick<TiktokUserStats, 'followerCount'>>;
            }
        >;
        tiktokMediaStats?: Maybe<
          { __typename?: 'TiktokMediaStats' } & Pick<TiktokMediaStats, 'viralVideoPercentage'> & {
              view_count?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'median'>>;
              totalEngagement_percentageOfPlays?: Maybe<
                { __typename?: 'MetricSummary' } & Pick<MetricSummary, 'median'>
              >;
            }
        >;
      }
  >;
};

export type GetSingleProfileQueryVariables = Exact<{
  _id?: Maybe<Scalars['ObjectId']>;
  id?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
}>;

export type GetSingleProfileQuery = { __typename?: 'Query' } & {
  getSingleProfile: { __typename?: 'Profile' } & Pick<
    Profile,
    | '_id'
    | 'id'
    | 'displayName'
    | 'profileType'
    | 'profile_picture_url'
    | 'followers_count'
    | 'fan_count'
    | 'name'
    | 'title'
    | 'username'
    | 'biography'
  > & {
      user?: Maybe<{ __typename?: 'User' } & Pick<User, '_id' | 'firstName' | 'lastName'>>;
      instagramFeedStats?: Maybe<
        { __typename?: 'MediaStats' } & {
          reach?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'median' | 'average'>>;
          engagement_percentageOfReach?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'median'>>;
        }
      >;
      instagramReelStats?: Maybe<
        { __typename?: 'MediaStats' } & {
          reach?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'median' | 'average'>>;
          total_interactions_percentageOfReach?: Maybe<
            { __typename?: 'MetricSummary' } & Pick<MetricSummary, 'median'>
          >;
        }
      >;
      storyStats?: Maybe<
        { __typename?: 'MediaStats' } & {
          reach?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'median'>>;
        }
      >;
      pageStats?: Maybe<
        { __typename?: 'FacebookPageStats' } & Pick<
          FacebookPageStats,
          'page_fans' | 'page_impressions' | 'page_positive_feedbackTotal' | 'page_negative_feedbackTotal'
        >
      >;
      postStats?: Maybe<
        { __typename?: 'FacebookPostStats' } & {
          post_engaged_users_percentageOfReach?: Maybe<
            { __typename?: 'MetricSummary' } & Pick<MetricSummary, 'median'>
          >;
          post_reactions_like_total?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'actual'>>;
        }
      >;
      channelStats?: Maybe<{ __typename?: 'ChannelStats' } & Pick<ChannelStats, 'subscriberCount'>>;
      videoStats?: Maybe<
        { __typename?: 'YoutubeMediaStats' } & {
          views?: Maybe<{ __typename?: 'YoutubeMetricSummary' } & Pick<YoutubeMetricSummary, 'average'>>;
          engagement_percentageOfViews?: Maybe<
            { __typename?: 'YoutubeMetricSummary' } & Pick<YoutubeMetricSummary, 'average'>
          >;
        }
      >;
      public?: Maybe<
        { __typename?: 'TiktokPublicUser' } & Pick<TiktokPublicUser, 'signature'> & {
            stats?: Maybe<{ __typename?: 'TiktokUserStats' } & Pick<TiktokUserStats, 'followerCount'>>;
          }
      >;
      tiktokMediaStats?: Maybe<
        { __typename?: 'TiktokMediaStats' } & Pick<TiktokMediaStats, 'viralVideoPercentage'> & {
            view_count?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'median'>>;
            totalEngagement?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'median'>>;
            totalEngagement_percentageOfPlays?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'median'>>;
          }
      >;
      mediaKitConfigs?: Maybe<
        Array<
          Maybe<
            { __typename?: 'MediaKitConfig' } & Pick<
              MediaKitConfig,
              '_id' | 'headerImage' | 'colorTheme' | 'title' | 'created_at'
            >
          >
        >
      >;
      rankedInstagramMedia?: Maybe<
        { __typename?: 'RankedInstagramMedia' } & {
          topMedia?: Maybe<
            Array<
              Maybe<
                { __typename?: 'InstagramMedia' } & Pick<
                  InstagramMedia,
                  'id' | '_id' | 'media_url' | 'thumbnail_url' | 'profileId'
                >
              >
            >
          >;
        }
      >;
      rankedTiktokMedia?: Maybe<
        { __typename?: 'TikTokRankedMedia' } & {
          topMedia?: Maybe<
            Array<
              Maybe<
                { __typename?: 'TiktokMedia' } & Pick<TiktokMedia, '_id' | 'id' | 'profileId'> & {
                    official?: Maybe<
                      { __typename?: 'TiktokOfficialVideo' } & Pick<
                        TiktokOfficialVideo,
                        'cover_image_url' | 'video_description'
                      >
                    >;
                  }
              >
            >
          >;
        }
      >;
    };
};

export type MyUserProfilesQueryVariables = Exact<{ [key: string]: never }>;

export type MyUserProfilesQuery = { __typename?: 'Query' } & {
  myUser?: Maybe<
    { __typename?: 'User' } & Pick<
      User,
      '_id' | 'firstName' | 'lastName' | 'avatar' | 'timezone' | 'country' | 'userAuditReportLink'
    > & {
        profiles?: Maybe<
          Array<
            { __typename?: 'Profile' } & Pick<
              Profile,
              | '_id'
              | 'id'
              | 'name'
              | 'displayName'
              | 'biography'
              | 'profileType'
              | 'profile_picture_url'
              | 'followers_count'
              | 'fan_count'
              | 'isAddedByOwner'
              | 'profileAuditReportLink'
              | 'isRestricted'
              | 'userId'
              | 'cache_completed_at'
              | 'created_at'
              | 'cacheError'
            > & {
                channelStats?: Maybe<{ __typename?: 'ChannelStats' } & Pick<ChannelStats, 'subscriberCount'>>;
                pageStats?: Maybe<{ __typename?: 'FacebookPageStats' } & Pick<FacebookPageStats, 'page_fans'>>;
                public?: Maybe<
                  { __typename?: 'TiktokPublicUser' } & Pick<TiktokPublicUser, 'signature'> & {
                      stats?: Maybe<{ __typename?: 'TiktokUserStats' } & Pick<TiktokUserStats, 'followerCount'>>;
                    }
                >;
                tiktokMediaStats?: Maybe<
                  { __typename?: 'TiktokMediaStats' } & Pick<TiktokMediaStats, 'viralVideoPercentage'> & {
                      view_count?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'median'>>;
                      totalEngagement_percentageOfPlays?: Maybe<
                        { __typename?: 'MetricSummary' } & Pick<MetricSummary, 'median'>
                      >;
                    }
                >;
                latestPerformanceReport?: Maybe<
                  { __typename?: 'PerformanceReport' } & Pick<PerformanceReport, '_id' | 'isLimited'> & {
                      performance?: Maybe<
                        { __typename?: 'PerformanceRanking' } & Pick<PerformanceRanking, 'percentile'>
                      >;
                    }
                >;
              }
          >
        >;
      }
  >;
};

export type GetProfilesListQueryVariables = Exact<{ [key: string]: never }>;

export type GetProfilesListQuery = { __typename?: 'Query' } & {
  getProfiles: Array<
    { __typename?: 'Profile' } & Pick<
      Profile,
      | '_id'
      | 'id'
      | 'displayName'
      | 'profile_picture_url'
      | 'name'
      | 'username'
      | 'profileType'
      | 'cache_completed_at'
      | 'followerCount'
      | 'followers_count'
      | 'fan_count'
      | 'profileCategory'
      | 'selectedMediaKitId'
    > & {
        mediaStats?: Maybe<
          { __typename?: 'MediaStats' } & {
            reach?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'median'>>;
            engagement_percentageOfReach?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'median'>>;
          }
        >;
        storyStats?: Maybe<
          { __typename?: 'MediaStats' } & {
            reach?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'median'>>;
          }
        >;
        videoStats?: Maybe<
          { __typename?: 'YoutubeMediaStats' } & {
            views?: Maybe<{ __typename?: 'YoutubeMetricSummary' } & Pick<YoutubeMetricSummary, 'median'>>;
            estimatedMinutesWatched?: Maybe<
              { __typename?: 'YoutubeMetricSummary' } & Pick<YoutubeMetricSummary, 'median'>
            >;
            averageViewDuration?: Maybe<{ __typename?: 'YoutubeMetricSummary' } & Pick<YoutubeMetricSummary, 'median'>>;
            engagement_percentageOfViews?: Maybe<
              { __typename?: 'YoutubeMetricSummary' } & Pick<YoutubeMetricSummary, 'median'>
            >;
          }
        >;
        pageStats?: Maybe<
          { __typename?: 'FacebookPageStats' } & Pick<
            FacebookPageStats,
            | 'page_fans'
            | 'page_impressions'
            | 'page_actions_post_reactionsTotal'
            | 'page_positive_feedbackTotal'
            | 'post_activity_unique'
          >
        >;
        postStats?: Maybe<
          { __typename?: 'FacebookPostStats' } & {
            post_impressions_unique?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'median'>>;
          }
        >;
        public?: Maybe<
          { __typename?: 'TiktokPublicUser' } & Pick<TiktokPublicUser, 'signature'> & {
              stats?: Maybe<{ __typename?: 'TiktokUserStats' } & Pick<TiktokUserStats, 'followerCount'>>;
            }
        >;
        tiktokMediaStats?: Maybe<
          { __typename?: 'TiktokMediaStats' } & Pick<TiktokMediaStats, 'viralVideoPercentage'> & {
              view_count?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'median'>>;
              totalEngagement_percentageOfPlays?: Maybe<
                { __typename?: 'MetricSummary' } & Pick<MetricSummary, 'median'>
              >;
            }
        >;
        profileNote?: Maybe<
          { __typename?: 'ProfileNote' } & Pick<
            ProfileNote,
            '_id' | 'notes' | 'categories' | 'profile_category' | 'selectedMediaKitId'
          >
        >;
        mediaKitConfigs?: Maybe<
          Array<Maybe<{ __typename?: 'MediaKitConfig' } & Pick<MediaKitConfig, '_id' | 'title'>>>
        >;
      }
  >;
};

export type GetFacebookPagesQueryVariables = Exact<{ [key: string]: never }>;

export type GetFacebookPagesQuery = { __typename?: 'Query' } & {
  getFacebookPages: Array<
    { __typename?: 'FacebookPageInfo' } & Pick<FacebookPageInfo, 'id' | 'name'> & {
        picture?: Maybe<
          { __typename?: 'FacebookProfilePicture' } & {
            data?: Maybe<{ __typename?: 'FacebookProfilePictureData' } & Pick<FacebookProfilePictureData, 'url'>>;
          }
        >;
        instagram_business_account?: Maybe<
          { __typename?: 'InstagramAccount' } & Pick<
            InstagramAccount,
            'id' | 'name' | 'username' | 'profile_picture_url' | 'followers_count'
          >
        >;
      }
  >;
};

export type ListYoutubeProfilesQueryVariables = Exact<{ [key: string]: never }>;

export type ListYoutubeProfilesQuery = { __typename?: 'Query' } & {
  listYoutubeProfiles: Array<
    { __typename?: 'YoutubeProfile' } & Pick<
      YoutubeProfile,
      | '_id'
      | 'id'
      | 'isAddedByOwner'
      | 'displayName'
      | 'profileType'
      | 'profile_picture_url'
      | 'profile_category'
      | 'cache_completed_at'
      | 'profile_cached_at'
      | 'profileCategory'
      | 'snippet'
      | 'isRestricted'
      | 'cacheError'
    > & {
        channelStats?: Maybe<{ __typename?: 'ChannelStats' } & Pick<ChannelStats, 'subscriberCount'>>;
        thumbnails?: Maybe<
          { __typename?: 'Thumbnails' } & {
            default?: Maybe<{ __typename?: 'ThumbnailInfo' } & Pick<ThumbnailInfo, 'url'>>;
          }
        >;
      }
  >;
};

export type GetTiktokProfilesQueryVariables = Exact<{ [key: string]: never }>;

export type GetTiktokProfilesQuery = { __typename?: 'Query' } & {
  getTiktokProfiles: Array<
    { __typename?: 'TiktokProfile' } & Pick<
      TiktokProfile,
      | '_id'
      | 'id'
      | 'isAddedByOwner'
      | 'displayName'
      | 'profileType'
      | 'profile_picture_url'
      | 'profile_category'
      | 'cache_completed_at'
      | 'profile_cached_at'
      | 'profileCategory'
      | 'isRestricted'
      | 'cacheError'
    >
  >;
};

export type GetSingleProfileForInstaQueryVariables = Exact<{
  id?: Maybe<Scalars['ObjectId']>;
}>;

export type GetSingleProfileForInstaQuery = { __typename?: 'Query' } & {
  getSingleProfile: { __typename?: 'Profile' } & Pick<
    Profile,
    | '_id'
    | 'id'
    | 'name'
    | 'displayName'
    | 'username'
    | 'profileType'
    | 'profile_picture_url'
    | 'biography'
    | 'followers_count'
    | 'timezone'
    | 'onlineFollowers'
    | 'userId'
  > & {
      lifetimeInsights?: Maybe<
        { __typename?: 'LifetimeInsights' } & Pick<
          LifetimeInsights,
          'audience_city' | 'audience_country' | 'audience_age'
        > & {
            audience_gender?: Maybe<
              { __typename?: 'AudienceGender' } & {
                male?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'actual'>>;
                female?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'actual'>>;
                unspecified?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'actual'>>;
              }
            >;
          }
      >;
      mediaStats?: Maybe<
        { __typename?: 'MediaStats' } & {
          impressions?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'median'>>;
          reach?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'median'>>;
          engagement?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'median'>>;
          engagement_percentageOfReach?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'median'>>;
        }
      >;
      storyStats?: Maybe<
        { __typename?: 'MediaStats' } & {
          impressions?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'median'>>;
          reach?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'median'>>;
          engagement?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'median'>>;
          engagement_percentageOfReach?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'median'>>;
        }
      >;
      historicalStats?: Maybe<
        { __typename?: 'InstagramHistoricalStats' } & Pick<InstagramHistoricalStats, 'count'> & {
            follower_count?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'actual' | 'average'>>;
            follows_count?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'actual' | 'average'>>;
            media_count?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'actual' | 'average'>>;
            profileRequestCount?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'actual' | 'average'>>;
            email_contacts?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'actual' | 'average'>>;
            profile_views?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'actual' | 'average'>>;
            get_directions_clicks?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'actual' | 'average'>>;
            phone_call_clicks?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'actual' | 'average'>>;
            text_message_clicks?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'actual' | 'average'>>;
            website_clicks?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'actual' | 'average'>>;
            reach?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'actual' | 'average'>>;
            impressions?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'actual' | 'average'>>;
          }
      >;
      latestInstagramPosts?: Maybe<
        Array<
          { __typename?: 'InstagramMedia' } & Pick<
            InstagramMedia,
            | '_id'
            | 'id'
            | 'profileId'
            | 'media_url'
            | 'thumbnail_url'
            | 'media_type'
            | 'caption'
            | 'timestamp'
            | 'like_count'
            | 'isAd'
            | 'comments_count'
          > & {
              insights?: Maybe<
                { __typename?: 'InstagramPostInsights' } & Pick<
                  InstagramPostInsights,
                  'reach' | 'impressions' | 'engagement' | 'saved'
                >
              >;
              children?: Maybe<
                Array<
                  { __typename?: 'InstagramChildMedia' } & Pick<InstagramChildMedia, 'id' | 'media_url' | 'media_type'>
                >
              >;
            }
        >
      >;
      latestInstagramStories?: Maybe<
        Array<
          { __typename?: 'InstagramStory' } & Pick<
            InstagramStory,
            '_id' | 'id' | 'profileId' | 'media_url' | 'media_type' | 'thumbnail_url' | 'caption' | 'timestamp' | 'isAd'
          > & {
              insights?: Maybe<
                { __typename?: 'InstagramStoryInsights' } & Pick<
                  InstagramStoryInsights,
                  'reach' | 'impressions' | 'exits' | 'replies' | 'taps_forward' | 'taps_back'
                >
              >;
            }
        >
      >;
      weeklyInstagramStats?: Maybe<
        Array<
          { __typename?: 'InstagramHistorical' } & Pick<
            InstagramHistorical,
            'start' | 'follower_count' | 'followers_lost' | 'followers_net'
          > & {
              mediaStats?: Maybe<
                { __typename?: 'InstagramPostInsightAverages' } & Pick<
                  InstagramPostInsightAverages,
                  'reach' | 'impressions' | 'engagement' | 'engagement_percentageOfReach' | 'viewRate'
                >
              >;
              storyStats?: Maybe<
                { __typename?: 'InstagramStoryInsightAverages' } & Pick<
                  InstagramStoryInsightAverages,
                  'reach' | 'impressions' | 'viewRate'
                >
              >;
              change?: Maybe<
                { __typename?: 'InstagramHistorical' } & Pick<InstagramHistorical, 'followers_net'> & {
                    mediaStats?: Maybe<
                      { __typename?: 'InstagramPostInsightAverages' } & Pick<
                        InstagramPostInsightAverages,
                        'reach' | 'engagement_percentageOfReach'
                      >
                    >;
                    storyStats?: Maybe<
                      { __typename?: 'InstagramStoryInsightAverages' } & Pick<
                        InstagramStoryInsightAverages,
                        'reach' | 'impressions' | 'viewRate'
                      >
                    >;
                  }
              >;
            }
        >
      >;
      dailyInstagramStats?: Maybe<
        Array<{ __typename?: 'InstagramHistorical' } & Pick<InstagramHistorical, 'date' | 'followers_count'>>
      >;
      instagramFeedTrends?: Maybe<
        { __typename?: 'InstagramMediaTrends' } & {
          dailyAverages?: Maybe<
            Array<
              Maybe<
                { __typename?: 'InstagramMediaAverages' } & Pick<InstagramMediaAverages, 'date' | 'like_count'> & {
                    insights?: Maybe<
                      { __typename?: 'InstagramPostInsightAverages' } & Pick<
                        InstagramPostInsightAverages,
                        'reach' | 'engagement' | 'engagement_percentageOfReach'
                      >
                    >;
                  }
              >
            >
          >;
          recentAverages?: Maybe<
            { __typename?: 'InstagramMediaAverages' } & Pick<InstagramMediaAverages, 'like_count'> & {
                insights?: Maybe<
                  { __typename?: 'InstagramPostInsightAverages' } & Pick<
                    InstagramPostInsightAverages,
                    'reach' | 'engagement' | 'engagement_percentageOfReach'
                  >
                >;
              }
          >;
          comparisonAverages?: Maybe<
            { __typename?: 'InstagramMediaAverages' } & Pick<InstagramMediaAverages, 'like_count'> & {
                insights?: Maybe<
                  { __typename?: 'InstagramPostInsightAverages' } & Pick<
                    InstagramPostInsightAverages,
                    'reach' | 'engagement' | 'engagement_percentageOfReach'
                  >
                >;
              }
          >;
        }
      >;
      instagramStoryTrends?: Maybe<
        { __typename?: 'InstagramStoryTrends' } & {
          dailyAverages?: Maybe<
            Array<
              Maybe<
                { __typename?: 'InstagramStoryAverages' } & Pick<InstagramStoryAverages, 'date'> & {
                    insights?: Maybe<
                      { __typename?: 'InstagramStoryInsightAverages' } & Pick<
                        InstagramStoryInsightAverages,
                        'reach' | 'impressions' | 'taps_forward' | 'taps_back' | 'exits' | 'replies'
                      >
                    >;
                  }
              >
            >
          >;
          recentAverages?: Maybe<
            { __typename?: 'InstagramStoryAverages' } & {
              insights?: Maybe<
                { __typename?: 'InstagramStoryInsightAverages' } & Pick<
                  InstagramStoryInsightAverages,
                  'reach' | 'impressions' | 'taps_forward' | 'taps_back' | 'exits' | 'replies'
                >
              >;
            }
          >;
          comparisonAverages?: Maybe<
            { __typename?: 'InstagramStoryAverages' } & {
              insights?: Maybe<
                { __typename?: 'InstagramStoryInsightAverages' } & Pick<
                  InstagramStoryInsightAverages,
                  'reach' | 'impressions' | 'taps_forward' | 'taps_back' | 'exits' | 'replies'
                >
              >;
            }
          >;
        }
      >;
      rankedInstagramMedia?: Maybe<
        { __typename?: 'RankedInstagramMedia' } & {
          topMedia?: Maybe<
            Array<
              Maybe<
                { __typename?: 'InstagramMedia' } & Pick<
                  InstagramMedia,
                  'id' | '_id' | 'media_url' | 'thumbnail_url' | 'profileId'
                >
              >
            >
          >;
        }
      >;
      rankedTiktokMedia?: Maybe<
        { __typename?: 'TikTokRankedMedia' } & {
          topMedia?: Maybe<
            Array<
              Maybe<
                { __typename?: 'TiktokMedia' } & Pick<TiktokMedia, '_id' | 'id' | 'profileId'> & {
                    official?: Maybe<
                      { __typename?: 'TiktokOfficialVideo' } & Pick<
                        TiktokOfficialVideo,
                        'cover_image_url' | 'video_description'
                      >
                    >;
                  }
              >
            >
          >;
        }
      >;
    };
};

export type GetStoriesForDateQueryVariables = Exact<{
  profileId: Scalars['ObjectId'];
  date: Scalars['Date'];
}>;

export type GetStoriesForDateQuery = { __typename?: 'Query' } & {
  getStoriesForDate: Array<
    { __typename?: 'InstagramStory' } & Pick<
      InstagramStory,
      '_id' | 'id' | 'profileId' | 'media_url' | 'media_type' | 'thumbnail_url' | 'caption' | 'timestamp' | 'isAd'
    > & {
        insights?: Maybe<
          { __typename?: 'InstagramStoryInsights' } & Pick<
            InstagramStoryInsights,
            'reach' | 'impressions' | 'exits' | 'replies' | 'taps_forward' | 'taps_back'
          >
        >;
      }
  >;
};

export type GetSingleProfileForFbQueryVariables = Exact<{
  id?: Maybe<Scalars['ObjectId']>;
}>;

export type GetSingleProfileForFbQuery = { __typename?: 'Query' } & {
  getSingleProfile: { __typename?: 'Profile' } & Pick<
    Profile,
    '_id' | 'id' | 'name' | 'displayName' | 'biography' | 'profileType' | 'profile_picture_url' | 'fan_count' | 'userId'
  > & {
      facebookPostTrends?: Maybe<
        { __typename?: 'FacebookMediaTrends' } & {
          dailyAverages?: Maybe<
            Array<
              Maybe<
                { __typename?: 'FacebookMediaAverages' } & Pick<FacebookMediaAverages, 'date'> & {
                    insights?: Maybe<
                      { __typename?: 'FacebookPostAverages' } & Pick<
                        FacebookPostAverages,
                        'post_clicks' | 'post_reactionsTotal' | 'post_impressions_unique' | 'post_engaged_users'
                      >
                    >;
                  }
              >
            >
          >;
          recentAverages?: Maybe<
            { __typename?: 'FacebookMediaAverages' } & {
              insights?: Maybe<
                { __typename?: 'FacebookPostAverages' } & Pick<
                  FacebookPostAverages,
                  'post_clicks' | 'post_reactionsTotal' | 'post_impressions_unique' | 'post_engaged_users'
                >
              >;
            }
          >;
          comparisonAverages?: Maybe<
            { __typename?: 'FacebookMediaAverages' } & {
              insights?: Maybe<
                { __typename?: 'FacebookPostAverages' } & Pick<
                  FacebookPostAverages,
                  'post_clicks' | 'post_reactionsTotal' | 'post_impressions_unique' | 'post_engaged_users'
                >
              >;
            }
          >;
        }
      >;
      pageStats?: Maybe<
        { __typename?: 'FacebookPageStats' } & Pick<
          FacebookPageStats,
          | 'page_impressions'
          | 'page_post_engagements'
          | 'page_total_actions'
          | 'page_video_views'
          | 'page_actions_post_reactions_anger_total'
          | 'page_actions_post_reactions_haha_total'
          | 'page_actions_post_reactions_like_total'
          | 'page_actions_post_reactions_love_total'
          | 'page_actions_post_reactions_sorry_total'
          | 'page_actions_post_reactions_wow_total'
          | 'page_actions_post_reactionsTotal'
          | 'page_call_phone_clicks_logged_in_unique'
          | 'page_content_activity'
          | 'page_get_directions_clicks_logged_in_unique'
          | 'page_places_checkin_mobile'
          | 'page_posts_impressions'
          | 'page_tab_clicksTotal'
          | 'page_views_total'
          | 'page_website_clicks_logged_in_unique'
          | 'page_positive_feedbackTotal'
          | 'page_negative_feedbackTotal'
          | 'page_fans'
          | 'page_fans_city'
          | 'page_fans_country'
          | 'page_fans_age'
          | 'page_content_activity_by_age_unique'
          | 'page_content_activity_by_country_unique'
          | 'page_impressions_by_age_unique'
          | 'page_impressions_by_country_unique'
          | 'page_cta_clicks_by_age_logged_in_unique'
          | 'page_cta_clicks_by_country_logged_in_unique'
          | 'page_call_phone_clicks_by_age_logged_in_unique'
          | 'page_call_phone_clicks_by_country_logged_in_unique'
          | 'page_get_directions_clicks_by_age_logged_in_unique'
          | 'page_get_directions_clicks_by_country_logged_in_unique'
          | 'page_website_clicks_by_age_logged_in_unique'
          | 'page_website_clicks_by_country_logged_in_unique'
          | 'page_places_checkins_by_age'
          | 'page_places_checkins_by_country'
        > & {
            page_content_activity_by_action_type_unique?: Maybe<
              { __typename?: 'FacebookActionStats' } & Pick<FacebookActionStats, 'other'>
            >;
            page_positive_feedback_by_type?: Maybe<
              { __typename?: 'PAGE_POSITIVE_FEEDBACK_BY_TYPE' } & Pick<
                Page_Positive_Feedback_By_Type,
                'answer' | 'claim' | 'comment' | 'like' | 'link' | 'other' | 'rsvp'
              >
            >;
            page_negative_feedback_by_type?: Maybe<
              { __typename?: 'PAGE_NEGATIVE_FEEDBACK_BY_TYPE' } & Pick<
                Page_Negative_Feedback_By_Type,
                'hide_clicks' | 'hide_all_clicks' | 'report_spam_clicks' | 'unlike_page_clicks'
              >
            >;
            page_fans_gender?: Maybe<
              { __typename?: 'AudienceGender' } & {
                male?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'actual'>>;
                female?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'actual'>>;
                unspecified?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'actual'>>;
              }
            >;
            page_content_activity_by_gender_unique?: Maybe<
              { __typename?: 'AudienceGender' } & {
                male?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'actual'>>;
                female?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'actual'>>;
                unspecified?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'actual'>>;
              }
            >;
            page_impressions_by_gender_unique?: Maybe<
              { __typename?: 'AudienceGender' } & {
                male?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'actual'>>;
                female?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'actual'>>;
                unspecified?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'actual'>>;
              }
            >;
            page_cta_clicks_by_gender_logged_in_unique?: Maybe<
              { __typename?: 'AudienceGender' } & {
                male?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'actual'>>;
                female?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'actual'>>;
                unspecified?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'actual'>>;
              }
            >;
            page_call_phone_clicks_by_gender_logged_in_unique?: Maybe<
              { __typename?: 'AudienceGender' } & {
                male?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'actual'>>;
                female?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'actual'>>;
                unspecified?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'actual'>>;
              }
            >;
            page_get_directions_clicks_by_gender_logged_in_unique?: Maybe<
              { __typename?: 'AudienceGender' } & {
                male?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'actual'>>;
                female?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'actual'>>;
                unspecified?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'actual'>>;
              }
            >;
            page_website_clicks_by_gender_logged_in_unique?: Maybe<
              { __typename?: 'AudienceGender' } & {
                male?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'actual'>>;
                female?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'actual'>>;
                unspecified?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'actual'>>;
              }
            >;
            page_places_checkins_by_gender?: Maybe<
              { __typename?: 'AudienceGender' } & {
                male?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'actual'>>;
                female?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'actual'>>;
                unspecified?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'actual'>>;
              }
            >;
          }
      >;
      postStats?: Maybe<
        { __typename?: 'FacebookPostStats' } & Pick<FacebookPostStats, 'count'> & {
            post_activity?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'median'>>;
            post_activity_percentageOfReach?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'median'>>;
            post_engaged_users?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'median'>>;
            post_engaged_users_percentageOfReach?: Maybe<
              { __typename?: 'MetricSummary' } & Pick<MetricSummary, 'median'>
            >;
            post_impressions_unique?: Maybe<{ __typename?: 'MetricSummary' } & Pick<MetricSummary, 'median'>>;
          }
      >;
      historicalFacebookStats?: Maybe<
        Array<
          { __typename?: 'FacebookPageStats' } & Pick<
            FacebookPageStats,
            | 'date'
            | 'page_fans'
            | 'page_video_views'
            | 'page_impressions'
            | 'page_post_engagements'
            | 'page_total_actions'
          >
        >
      >;
      weeklyFacebookStats?: Maybe<
        Array<
          { __typename?: 'FacebookWeeklyStats' } & Pick<
            FacebookWeeklyStats,
            | 'date'
            | 'page_fan_adds_unique'
            | 'page_fan_removes_unique'
            | 'page_fans_netChange'
            | 'page_impressions'
            | 'page_post_engagements'
            | 'page_total_actions'
            | 'page_video_views'
            | 'page_actions_post_reactionsTotal'
          > & {
              page_fans_bySource?: Maybe<
                { __typename?: 'FacebookFanSource' } & {
                  ads?: Maybe<
                    { __typename?: 'FacebookFanSourceStats' } & Pick<
                      FacebookFanSourceStats,
                      'adds' | 'removes' | 'netChange'
                    >
                  >;
                  feed?: Maybe<
                    { __typename?: 'FacebookFanSourceStats' } & Pick<
                      FacebookFanSourceStats,
                      'adds' | 'removes' | 'netChange'
                    >
                  >;
                  page_suggestions?: Maybe<
                    { __typename?: 'FacebookFanSourceStats' } & Pick<
                      FacebookFanSourceStats,
                      'adds' | 'removes' | 'netChange'
                    >
                  >;
                  deactivated_accounts?: Maybe<
                    { __typename?: 'FacebookFanSourceStats' } & Pick<
                      FacebookFanSourceStats,
                      'adds' | 'removes' | 'netChange'
                    >
                  >;
                  search?: Maybe<
                    { __typename?: 'FacebookFanSourceStats' } & Pick<
                      FacebookFanSourceStats,
                      'adds' | 'removes' | 'netChange'
                    >
                  >;
                  other?: Maybe<
                    { __typename?: 'FacebookFanSourceStats' } & Pick<
                      FacebookFanSourceStats,
                      'adds' | 'removes' | 'netChange'
                    >
                  >;
                }
              >;
            }
        >
      >;
      latestFacebookPosts?: Maybe<
        Array<
          { __typename?: 'FacebookPost' } & Pick<
            FacebookPost,
            | '_id'
            | 'id'
            | 'pageId'
            | 'created_time'
            | 'timestamp'
            | 'full_picture'
            | 'story'
            | 'message'
            | 'permalink_url'
          > & {
              insights?: Maybe<
                { __typename?: 'FacebookPostInsights' } & Pick<
                  FacebookPostInsights,
                  'post_clicks' | 'post_engaged_fan' | 'post_engaged_users' | 'post_impressions' | 'post_activity'
                >
              >;
            }
        >
      >;
    };
};

export type GetSingleProfileForYtQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetSingleProfileForYtQuery = { __typename?: 'Query' } & {
  recentYoutubeMedia: { __typename?: 'RecentYoutubeMediaResult' } & Pick<RecentYoutubeMediaResult, 'mediaCount'> & {
      youtubeMedia?: Maybe<
        Array<
          Maybe<
            { __typename?: 'YoutubeMedia' } & Pick<
              YoutubeMedia,
              | 'title'
              | 'date'
              | 'timestamp'
              | 'publishedAt'
              | 'description'
              | 'video'
              | 'views'
              | 'comments'
              | 'likes'
              | 'dislikes'
              | 'estimatedMinutesWatched'
              | 'averageViewDuration'
              | 'annotationImpressions'
              | 'annotationClickableImpressions'
              | 'annotationCloses'
              | 'annotationCloseRate'
              | 'annotationClicks'
              | 'annotationClickThroughRate'
              | 'cardImpressions'
              | 'cardClicks'
              | 'cardClickRate'
            > & {
                thumbnails?: Maybe<
                  { __typename?: 'Thumbnails' } & {
                    default?: Maybe<{ __typename?: 'ThumbnailInfo' } & Pick<ThumbnailInfo, 'url'>>;
                    standard?: Maybe<{ __typename?: 'ThumbnailInfo' } & Pick<ThumbnailInfo, 'url'>>;
                    medium?: Maybe<{ __typename?: 'ThumbnailInfo' } & Pick<ThumbnailInfo, 'url'>>;
                    high?: Maybe<{ __typename?: 'ThumbnailInfo' } & Pick<ThumbnailInfo, 'url'>>;
                    maxres?: Maybe<{ __typename?: 'ThumbnailInfo' } & Pick<ThumbnailInfo, 'url'>>;
                  }
                >;
              }
          >
        >
      >;
      youtubeMediaAverages?: Maybe<
        { __typename?: 'YoutubeMediaStats' } & {
          views?: Maybe<{ __typename?: 'YoutubeMetricSummary' } & Pick<YoutubeMetricSummary, 'median'>>;
          comments?: Maybe<{ __typename?: 'YoutubeMetricSummary' } & Pick<YoutubeMetricSummary, 'median'>>;
          likes?: Maybe<{ __typename?: 'YoutubeMetricSummary' } & Pick<YoutubeMetricSummary, 'median'>>;
          dislikes?: Maybe<{ __typename?: 'YoutubeMetricSummary' } & Pick<YoutubeMetricSummary, 'median'>>;
          videosAddedToPlaylists?: Maybe<
            { __typename?: 'YoutubeMetricSummary' } & Pick<YoutubeMetricSummary, 'median'>
          >;
          videosRemovedFromPlaylists?: Maybe<
            { __typename?: 'YoutubeMetricSummary' } & Pick<YoutubeMetricSummary, 'median'>
          >;
          shares?: Maybe<{ __typename?: 'YoutubeMetricSummary' } & Pick<YoutubeMetricSummary, 'median'>>;
          estimatedMinutesWatched?: Maybe<
            { __typename?: 'YoutubeMetricSummary' } & Pick<YoutubeMetricSummary, 'median'>
          >;
          estimatedRedMinutesWatched?: Maybe<
            { __typename?: 'YoutubeMetricSummary' } & Pick<YoutubeMetricSummary, 'median'>
          >;
          averageViewDuration?: Maybe<{ __typename?: 'YoutubeMetricSummary' } & Pick<YoutubeMetricSummary, 'median'>>;
          annotationClickThroughRate?: Maybe<
            { __typename?: 'YoutubeMetricSummary' } & Pick<YoutubeMetricSummary, 'median'>
          >;
          annotationCloseRate?: Maybe<{ __typename?: 'YoutubeMetricSummary' } & Pick<YoutubeMetricSummary, 'median'>>;
          annotationImpressions?: Maybe<{ __typename?: 'YoutubeMetricSummary' } & Pick<YoutubeMetricSummary, 'median'>>;
          annotationClickableImpressions?: Maybe<
            { __typename?: 'YoutubeMetricSummary' } & Pick<YoutubeMetricSummary, 'median'>
          >;
          annotationClosableImpressions?: Maybe<
            { __typename?: 'YoutubeMetricSummary' } & Pick<YoutubeMetricSummary, 'median'>
          >;
          annotationClicks?: Maybe<{ __typename?: 'YoutubeMetricSummary' } & Pick<YoutubeMetricSummary, 'median'>>;
          annotationCloses?: Maybe<{ __typename?: 'YoutubeMetricSummary' } & Pick<YoutubeMetricSummary, 'median'>>;
          cardClickRate?: Maybe<{ __typename?: 'YoutubeMetricSummary' } & Pick<YoutubeMetricSummary, 'median'>>;
          cardTeaserClickRate?: Maybe<{ __typename?: 'YoutubeMetricSummary' } & Pick<YoutubeMetricSummary, 'median'>>;
          cardImpressions?: Maybe<{ __typename?: 'YoutubeMetricSummary' } & Pick<YoutubeMetricSummary, 'median'>>;
          cardTeaserImpressions?: Maybe<{ __typename?: 'YoutubeMetricSummary' } & Pick<YoutubeMetricSummary, 'median'>>;
          cardClicks?: Maybe<{ __typename?: 'YoutubeMetricSummary' } & Pick<YoutubeMetricSummary, 'median'>>;
          cardTeaserClicks?: Maybe<{ __typename?: 'YoutubeMetricSummary' } & Pick<YoutubeMetricSummary, 'median'>>;
          netSubscribers?: Maybe<{ __typename?: 'YoutubeMetricSummary' } & Pick<YoutubeMetricSummary, 'actual'>>;
          subscribersGained?: Maybe<{ __typename?: 'YoutubeMetricSummary' } & Pick<YoutubeMetricSummary, 'median'>>;
          subscribersLost?: Maybe<{ __typename?: 'YoutubeMetricSummary' } & Pick<YoutubeMetricSummary, 'median'>>;
        }
      >;
    };
  getSingleProfile: { __typename?: 'Profile' } & Pick<
    Profile,
    | '_id'
    | 'id'
    | 'title'
    | 'displayName'
    | 'description'
    | 'userId'
    | 'profileType'
    | 'profile_picture_url'
    | 'country'
  > & {
      ageGroupBreakdown?: Maybe<Array<{ __typename?: 'AgeGroup' } & Pick<AgeGroup, 'ageGroup' | 'viewerPercentage'>>>;
      genderBreakdown?: Maybe<Array<{ __typename?: 'GenderGroup' } & Pick<GenderGroup, 'gender' | 'viewerPercentage'>>>;
      locationBreakdown?: Maybe<Array<{ __typename?: 'LocationGroup' } & Pick<LocationGroup, 'country' | 'views'>>>;
      myChannelReport?: Maybe<
        Array<
          { __typename?: 'ChannelReport' } & Pick<
            ChannelReport,
            | 'day'
            | 'views'
            | 'averageViewDuration'
            | 'averageViewPercentage'
            | 'estimatedMinutesWatched'
            | 'subscribersGained'
            | 'annotationClickThroughRate'
          >
        >
      >;
      subscriberChange?: Maybe<
        Array<
          { __typename?: 'SubscriberChange' } & Pick<SubscriberChange, 'day' | 'subscribersGained' | 'subscribersLost'>
        >
      >;
      youtubeVideoTrends?: Maybe<
        { __typename?: 'YoutubeMediaTrends' } & {
          dailyAverages?: Maybe<
            Array<
              Maybe<
                { __typename?: 'YoutubeMediaAverages' } & Pick<
                  YoutubeMediaAverages,
                  | 'date'
                  | 'views'
                  | 'redViews'
                  | 'averageViewDuration'
                  | 'averageViewPercentage'
                  | 'estimatedMinutesWatched'
                  | 'estimatedRedMinutesWatched'
                  | 'estimatedTotalMinutesWatched'
                  | 'subscribersGained'
                  | 'subscribersLost'
                >
              >
            >
          >;
          recentAverages?: Maybe<
            { __typename?: 'YoutubeMediaAverages' } & Pick<
              YoutubeMediaAverages,
              | 'views'
              | 'redViews'
              | 'averageViewDuration'
              | 'averageViewPercentage'
              | 'estimatedMinutesWatched'
              | 'estimatedRedMinutesWatched'
              | 'estimatedTotalMinutesWatched'
            >
          >;
          comparisonAverages?: Maybe<
            { __typename?: 'YoutubeMediaAverages' } & Pick<
              YoutubeMediaAverages,
              | 'views'
              | 'redViews'
              | 'averageViewDuration'
              | 'averageViewPercentage'
              | 'estimatedMinutesWatched'
              | 'estimatedRedMinutesWatched'
              | 'estimatedTotalMinutesWatched'
            >
          >;
        }
      >;
      channelStats?: Maybe<
        { __typename?: 'ChannelStats' } & Pick<
          ChannelStats,
          'viewCount' | 'commentCount' | 'subscriberCount' | 'hiddenSubscriberCount' | 'videoCount'
        >
      >;
    };
};

export type GetInstagramMediaQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['ObjectId']>;
}>;

export type GetInstagramMediaQuery = { __typename?: 'Query' } & {
  getSingleProfile: { __typename?: 'Profile' } & Pick<
    Profile,
    | '_id'
    | 'id'
    | 'name'
    | 'displayName'
    | 'username'
    | 'profileType'
    | 'profile_picture_url'
    | 'biography'
    | 'followers_count'
    | 'timezone'
    | 'onlineFollowers'
    | 'userId'
  > & {
      latestInstagramPosts?: Maybe<
        Array<
          { __typename?: 'InstagramMedia' } & Pick<
            InstagramMedia,
            | '_id'
            | 'id'
            | 'profileId'
            | 'media_url'
            | 'thumbnail_url'
            | 'media_type'
            | 'caption'
            | 'timestamp'
            | 'like_count'
            | 'isAd'
            | 'comments_count'
            | 'media_product_type'
          > & {
              insights?: Maybe<
                { __typename?: 'InstagramPostInsights' } & Pick<
                  InstagramPostInsights,
                  'reach' | 'impressions' | 'engagement' | 'saved'
                >
              >;
              children?: Maybe<
                Array<
                  { __typename?: 'InstagramChildMedia' } & Pick<InstagramChildMedia, 'id' | 'media_url' | 'media_type'>
                >
              >;
            }
        >
      >;
    };
};

export type GetReportConfigListQueryVariables = Exact<{
  id?: Maybe<Scalars['ObjectId']>;
}>;

export type GetReportConfigListQuery = { __typename?: 'Query' } & {
  getReportConfigList: Array<
    { __typename?: 'ReportConfig' } & Pick<
      ReportConfig,
      | '_id'
      | 'reportName'
      | 'profileIds'
      | 'brandHandle'
      | 'handles'
      | 'reportStart'
      | 'reportEnd'
      | 'mentions'
      | 'hashtags'
      | 'selected_media'
      | 'selected_stories'
      | 'created_at'
      | 'updated_at'
    > & {
        client?: Maybe<
          { __typename?: 'Contact' } & Pick<Contact, 'avatar' | 'name'> & {
              parent?: Maybe<{ __typename?: 'Contact' } & Pick<Contact, 'avatar' | 'name'>>;
            }
        >;
        reportLinks?: Maybe<
          Array<
            { __typename?: 'ReportLink' } & Pick<
              ReportLink,
              'reportConfigId' | 'isOpened' | 'isExpired' | 'name' | 'email'
            > & {
                contact?: Maybe<
                  { __typename?: 'Contact' } & Pick<Contact, 'avatar' | 'name' | 'email'> & {
                      parent?: Maybe<{ __typename?: 'Contact' } & Pick<Contact, 'avatar' | 'name'>>;
                    }
                >;
              }
          >
        >;
      }
  >;
};

export type GetReportConfigListActivityQueryVariables = Exact<{
  id?: Maybe<Scalars['ObjectId']>;
}>;

export type GetReportConfigListActivityQuery = { __typename?: 'Query' } & {
  getReportConfigList: Array<
    { __typename?: 'ReportConfig' } & Pick<
      ReportConfig,
      | '_id'
      | 'reportName'
      | 'reportStart'
      | 'reportEnd'
      | 'channels'
      | 'profileIds'
      | 'brand_handle'
      | 'handles'
      | 'mentions'
      | 'hashtags'
      | 'collaborationId'
      | 'clientId'
      | 'selected_media'
      | 'selected_stories'
      | 'selected_videos'
      | 'selected_posts'
      | 'created_at'
    > & {
        reportLinks?: Maybe<
          Array<
            { __typename?: 'ReportLink' } & Pick<
              ReportLink,
              'reportConfigId' | 'isOpened' | 'isExpired' | 'name' | 'email'
            > & {
                contact?: Maybe<
                  { __typename?: 'Contact' } & Pick<Contact, 'avatar' | 'name' | 'email'> & {
                      parent?: Maybe<{ __typename?: 'Contact' } & Pick<Contact, 'avatar' | 'name'>>;
                    }
                >;
              }
          >
        >;
      }
  >;
};

export type CreateReportConfigMutationVariables = Exact<{
  config: ReportConfigInput;
  supplementMedia?: Maybe<Array<Maybe<SupplementMediaInput>> | Maybe<SupplementMediaInput>>;
}>;

export type CreateReportConfigMutation = { __typename?: 'Mutation' } & {
  createReportConfig: { __typename?: 'ReportConfig' } & Pick<ReportConfig, '_id'>;
};

export type UpdateReportConfigMutationVariables = Exact<{
  reportId: Scalars['ObjectId'];
  config: ReportConfigInput;
}>;

export type UpdateReportConfigMutation = { __typename?: 'Mutation' } & {
  updateReportConfig: { __typename?: 'ServerResponse' } & Pick<ServerResponse, 'success' | 'message'>;
};

export type ShareReportToContactMutationVariables = Exact<{
  reportConfigId: Scalars['ObjectId'];
  contactIds?: Maybe<Array<Maybe<Scalars['ObjectId']>> | Maybe<Scalars['ObjectId']>>;
  contactDetails?: Maybe<ReportShareContact>;
}>;

export type ShareReportToContactMutation = { __typename?: 'Mutation' } & {
  shareReportToContact: { __typename?: 'ServerResponse' } & Pick<ServerResponse, 'success' | 'message'>;
};

export type GetReportListQueryVariables = Exact<{
  id?: Maybe<Scalars['ObjectId']>;
}>;

export type GetReportListQuery = { __typename?: 'Query' } & {
  getReportConfigList: Array<
    { __typename?: 'ReportConfig' } & Pick<
      ReportConfig,
      | '_id'
      | 'reportName'
      | 'reportStart'
      | 'reportEnd'
      | 'channels'
      | 'profileIds'
      | 'brand_handle'
      | 'handles'
      | 'mentions'
      | 'hashtags'
      | 'collaborationId'
      | 'clientId'
      | 'created_at'
      | 'selected_media'
      | 'selected_stories'
      | 'selected_videos'
      | 'selected_posts'
    >
  >;
};

export type GetContactAndInvitationListQueryVariables = Exact<{
  id?: Maybe<Scalars['ObjectId']>;
  type?: Maybe<ContactType>;
}>;

export type GetContactAndInvitationListQuery = { __typename?: 'Query' } & {
  getContactList: Array<
    { __typename?: 'Contact' } & Pick<
      Contact,
      | '_id'
      | 'parentId'
      | 'name'
      | 'type'
      | 'companyName'
      | 'created_at'
      | 'firstName'
      | 'lastName'
      | 'avatar'
      | 'email'
      | 'phone'
      | 'role'
    > & {
        children?: Maybe<
          Array<
            { __typename?: 'Contact' } & Pick<
              Contact,
              '_id' | 'firstName' | 'lastName' | 'avatar' | 'email' | 'phone' | 'role' | 'type'
            >
          >
        >;
      }
  >;
  getAmbassadorListByUserId: Array<
    { __typename?: 'Ambassador' } & Pick<
      Ambassador,
      '_id' | 'acceptedInvite' | 'ambassadorId' | 'created_at' | 'isArchive' | 'userId'
    > & {
        brand?: Maybe<
          { __typename?: 'User' } & Pick<User, '_id' | 'companyName' | 'firstName' | 'lastName' | 'avatar' | 'email'>
        >;
        contact?: Maybe<
          { __typename?: 'Contact' } & Pick<
            Contact,
            | '_id'
            | 'name'
            | 'type'
            | 'companyName'
            | 'firstName'
            | 'lastName'
            | 'avatar'
            | 'email'
            | 'phone'
            | 'role'
            | 'created_at'
          > & {
              children?: Maybe<
                Array<
                  { __typename?: 'Contact' } & Pick<
                    Contact,
                    '_id' | 'firstName' | 'lastName' | 'avatar' | 'email' | 'phone' | 'role' | 'type'
                  >
                >
              >;
            }
        >;
      }
  >;
};

export type GetConnectedAccountLinkQueryVariables = Exact<{
  returnPath?: Maybe<Scalars['String']>;
}>;

export type GetConnectedAccountLinkQuery = { __typename?: 'Query' } & {
  getConnectedAccountLink?: Maybe<
    { __typename?: 'StripeAccountLink' } & Pick<StripeAccountLink, 'object' | 'created' | 'expires_at' | 'url'>
  >;
};

export type GetConnectedAccountQueryVariables = Exact<{ [key: string]: never }>;

export type GetConnectedAccountQuery = { __typename?: 'Query' } & {
  getConnectedAccount?: Maybe<
    { __typename?: 'StripeConnectedAccount' } & Pick<
      StripeConnectedAccount,
      'id' | 'details_submitted' | 'email' | 'expressDashboardLink' | 'type'
    >
  >;
};

export type GetSupplementMediaForReportQueryVariables = Exact<{
  reportConfigId: Scalars['ObjectId'];
}>;

export type GetSupplementMediaForReportQuery = { __typename?: 'Query' } & {
  getSupplementMediaForReport: Array<
    { __typename?: 'SupplementMedia' } & Pick<
      SupplementMedia,
      '_id' | 'mediaType' | 'mediaUrl' | 'profileId' | 'caption' | 'timestamp' | 'mediaIsVideo' | 'mediaId'
    > & {
        insights?: Maybe<
          { __typename?: 'SupplementMediaInsights' } & Pick<
            SupplementMediaInsights,
            'reach' | 'plays' | 'likes' | 'comments' | 'shares' | 'saves'
          >
        >;
      }
  >;
};

export type GetTrackingLinkListQueryVariables = Exact<{
  type?: Maybe<TrackingLinkType>;
  target?: Maybe<Scalars['String']>;
}>;

export type GetTrackingLinkListQuery = { __typename?: 'Query' } & {
  getTrackingLinkList: Array<
    { __typename?: 'TrackingLink' } & Pick<
      TrackingLink,
      '_id' | 'customLink' | 'link' | 'longUrl' | 'type' | 'target' | 'actionType' | 'assignedProfile' | 'totalClicks'
    >
  >;
};

export const UpdateAmbassadorDocument = gql`
  mutation UpdateAmbassador($inputValues: AmbassadorInput) {
    updateAmbassadorInvitation(ambassadorInput: $inputValues) {
      insertedId
      success
      message
    }
  }
`;
export type UpdateAmbassadorMutationFn = Apollo.MutationFunction<
  UpdateAmbassadorMutation,
  UpdateAmbassadorMutationVariables
>;

/**
 * __useUpdateAmbassadorMutation__
 *
 * To run a mutation, you first call `useUpdateAmbassadorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAmbassadorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAmbassadorMutation, { data, loading, error }] = useUpdateAmbassadorMutation({
 *   variables: {
 *      inputValues: // value for 'inputValues'
 *   },
 * });
 */
export function useUpdateAmbassadorMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAmbassadorMutation, UpdateAmbassadorMutationVariables>
) {
  return Apollo.useMutation<UpdateAmbassadorMutation, UpdateAmbassadorMutationVariables>(
    UpdateAmbassadorDocument,
    baseOptions
  );
}
export type UpdateAmbassadorMutationHookResult = ReturnType<typeof useUpdateAmbassadorMutation>;
export type UpdateAmbassadorMutationResult = Apollo.MutationResult<UpdateAmbassadorMutation>;
export type UpdateAmbassadorMutationOptions = Apollo.BaseMutationOptions<
  UpdateAmbassadorMutation,
  UpdateAmbassadorMutationVariables
>;
export const DeleteCollaborationDocument = gql`
  mutation DeleteCollaboration($id: ObjectId!) {
    deleteCollaboration(id: $id) {
      success
      message
    }
  }
`;
export type DeleteCollaborationMutationFn = Apollo.MutationFunction<
  DeleteCollaborationMutation,
  DeleteCollaborationMutationVariables
>;

/**
 * __useDeleteCollaborationMutation__
 *
 * To run a mutation, you first call `useDeleteCollaborationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCollaborationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCollaborationMutation, { data, loading, error }] = useDeleteCollaborationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCollaborationMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteCollaborationMutation, DeleteCollaborationMutationVariables>
) {
  return Apollo.useMutation<DeleteCollaborationMutation, DeleteCollaborationMutationVariables>(
    DeleteCollaborationDocument,
    baseOptions
  );
}
export type DeleteCollaborationMutationHookResult = ReturnType<typeof useDeleteCollaborationMutation>;
export type DeleteCollaborationMutationResult = Apollo.MutationResult<DeleteCollaborationMutation>;
export type DeleteCollaborationMutationOptions = Apollo.BaseMutationOptions<
  DeleteCollaborationMutation,
  DeleteCollaborationMutationVariables
>;
export const CreateCollaborationDocument = gql`
  mutation CreateCollaboration($updates: CollaborationUpdates!) {
    createCollaboration(updates: $updates)
  }
`;
export type CreateCollaborationMutationFn = Apollo.MutationFunction<
  CreateCollaborationMutation,
  CreateCollaborationMutationVariables
>;

/**
 * __useCreateCollaborationMutation__
 *
 * To run a mutation, you first call `useCreateCollaborationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCollaborationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCollaborationMutation, { data, loading, error }] = useCreateCollaborationMutation({
 *   variables: {
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useCreateCollaborationMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCollaborationMutation, CreateCollaborationMutationVariables>
) {
  return Apollo.useMutation<CreateCollaborationMutation, CreateCollaborationMutationVariables>(
    CreateCollaborationDocument,
    baseOptions
  );
}
export type CreateCollaborationMutationHookResult = ReturnType<typeof useCreateCollaborationMutation>;
export type CreateCollaborationMutationResult = Apollo.MutationResult<CreateCollaborationMutation>;
export type CreateCollaborationMutationOptions = Apollo.BaseMutationOptions<
  CreateCollaborationMutation,
  CreateCollaborationMutationVariables
>;
export const UpdateCollaborationDocument = gql`
  mutation UpdateCollaboration($id: ObjectId!, $updates: CollaborationUpdates!) {
    updateCollaboration(id: $id, updates: $updates) {
      success
      message
    }
  }
`;
export type UpdateCollaborationMutationFn = Apollo.MutationFunction<
  UpdateCollaborationMutation,
  UpdateCollaborationMutationVariables
>;

/**
 * __useUpdateCollaborationMutation__
 *
 * To run a mutation, you first call `useUpdateCollaborationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCollaborationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCollaborationMutation, { data, loading, error }] = useUpdateCollaborationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useUpdateCollaborationMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCollaborationMutation, UpdateCollaborationMutationVariables>
) {
  return Apollo.useMutation<UpdateCollaborationMutation, UpdateCollaborationMutationVariables>(
    UpdateCollaborationDocument,
    baseOptions
  );
}
export type UpdateCollaborationMutationHookResult = ReturnType<typeof useUpdateCollaborationMutation>;
export type UpdateCollaborationMutationResult = Apollo.MutationResult<UpdateCollaborationMutation>;
export type UpdateCollaborationMutationOptions = Apollo.BaseMutationOptions<
  UpdateCollaborationMutation,
  UpdateCollaborationMutationVariables
>;
export const CreateContactDocument = gql`
  mutation CreateContact($updates: ContactUpdates!) {
    createContact(updates: $updates) {
      success
      message
      insertedId
    }
  }
`;
export type CreateContactMutationFn = Apollo.MutationFunction<CreateContactMutation, CreateContactMutationVariables>;

/**
 * __useCreateContactMutation__
 *
 * To run a mutation, you first call `useCreateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactMutation, { data, loading, error }] = useCreateContactMutation({
 *   variables: {
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useCreateContactMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateContactMutation, CreateContactMutationVariables>
) {
  return Apollo.useMutation<CreateContactMutation, CreateContactMutationVariables>(CreateContactDocument, baseOptions);
}
export type CreateContactMutationHookResult = ReturnType<typeof useCreateContactMutation>;
export type CreateContactMutationResult = Apollo.MutationResult<CreateContactMutation>;
export type CreateContactMutationOptions = Apollo.BaseMutationOptions<
  CreateContactMutation,
  CreateContactMutationVariables
>;
export const UpdateContactDocument = gql`
  mutation UpdateContact($id: ObjectId!, $updates: ContactUpdates!) {
    updateContact(id: $id, updates: $updates) {
      success
      message
    }
  }
`;
export type UpdateContactMutationFn = Apollo.MutationFunction<UpdateContactMutation, UpdateContactMutationVariables>;

/**
 * __useUpdateContactMutation__
 *
 * To run a mutation, you first call `useUpdateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactMutation, { data, loading, error }] = useUpdateContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useUpdateContactMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateContactMutation, UpdateContactMutationVariables>
) {
  return Apollo.useMutation<UpdateContactMutation, UpdateContactMutationVariables>(UpdateContactDocument, baseOptions);
}
export type UpdateContactMutationHookResult = ReturnType<typeof useUpdateContactMutation>;
export type UpdateContactMutationResult = Apollo.MutationResult<UpdateContactMutation>;
export type UpdateContactMutationOptions = Apollo.BaseMutationOptions<
  UpdateContactMutation,
  UpdateContactMutationVariables
>;
export const DeleteContactDocument = gql`
  mutation DeleteContact($id: ObjectId!) {
    deleteContact(id: $id) {
      success
      message
    }
  }
`;
export type DeleteContactMutationFn = Apollo.MutationFunction<DeleteContactMutation, DeleteContactMutationVariables>;

/**
 * __useDeleteContactMutation__
 *
 * To run a mutation, you first call `useDeleteContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContactMutation, { data, loading, error }] = useDeleteContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteContactMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteContactMutation, DeleteContactMutationVariables>
) {
  return Apollo.useMutation<DeleteContactMutation, DeleteContactMutationVariables>(DeleteContactDocument, baseOptions);
}
export type DeleteContactMutationHookResult = ReturnType<typeof useDeleteContactMutation>;
export type DeleteContactMutationResult = Apollo.MutationResult<DeleteContactMutation>;
export type DeleteContactMutationOptions = Apollo.BaseMutationOptions<
  DeleteContactMutation,
  DeleteContactMutationVariables
>;
export const ConnectContactDocument = gql`
  mutation ConnectContact($id: ObjectId!) {
    connectContact(id: $id) {
      isKBUserExisted
    }
  }
`;
export type ConnectContactMutationFn = Apollo.MutationFunction<ConnectContactMutation, ConnectContactMutationVariables>;

/**
 * __useConnectContactMutation__
 *
 * To run a mutation, you first call `useConnectContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectContactMutation, { data, loading, error }] = useConnectContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConnectContactMutation(
  baseOptions?: Apollo.MutationHookOptions<ConnectContactMutation, ConnectContactMutationVariables>
) {
  return Apollo.useMutation<ConnectContactMutation, ConnectContactMutationVariables>(
    ConnectContactDocument,
    baseOptions
  );
}
export type ConnectContactMutationHookResult = ReturnType<typeof useConnectContactMutation>;
export type ConnectContactMutationResult = Apollo.MutationResult<ConnectContactMutation>;
export type ConnectContactMutationOptions = Apollo.BaseMutationOptions<
  ConnectContactMutation,
  ConnectContactMutationVariables
>;
export const CreateDeliverableDocument = gql`
  mutation CreateDeliverable($updates: DeliverableUpdates!, $newTrackingLink: TrackingLinkInput) {
    createMultipleDeliverables(updates: $updates, newTrackingLink: $newTrackingLink) {
      success
      message
      insertedIdList
    }
  }
`;
export type CreateDeliverableMutationFn = Apollo.MutationFunction<
  CreateDeliverableMutation,
  CreateDeliverableMutationVariables
>;

/**
 * __useCreateDeliverableMutation__
 *
 * To run a mutation, you first call `useCreateDeliverableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeliverableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeliverableMutation, { data, loading, error }] = useCreateDeliverableMutation({
 *   variables: {
 *      updates: // value for 'updates'
 *      newTrackingLink: // value for 'newTrackingLink'
 *   },
 * });
 */
export function useCreateDeliverableMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateDeliverableMutation, CreateDeliverableMutationVariables>
) {
  return Apollo.useMutation<CreateDeliverableMutation, CreateDeliverableMutationVariables>(
    CreateDeliverableDocument,
    baseOptions
  );
}
export type CreateDeliverableMutationHookResult = ReturnType<typeof useCreateDeliverableMutation>;
export type CreateDeliverableMutationResult = Apollo.MutationResult<CreateDeliverableMutation>;
export type CreateDeliverableMutationOptions = Apollo.BaseMutationOptions<
  CreateDeliverableMutation,
  CreateDeliverableMutationVariables
>;
export const UpdateDeliverableDocument = gql`
  mutation UpdateDeliverable($id: ObjectId!, $updates: DeliverableUpdates!, $newTrackingLink: TrackingLinkInput) {
    updateAndCreateDeliverables(id: $id, updates: $updates, newTrackingLink: $newTrackingLink) {
      success
      message
      insertedIdList
    }
  }
`;
export type UpdateDeliverableMutationFn = Apollo.MutationFunction<
  UpdateDeliverableMutation,
  UpdateDeliverableMutationVariables
>;

/**
 * __useUpdateDeliverableMutation__
 *
 * To run a mutation, you first call `useUpdateDeliverableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeliverableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeliverableMutation, { data, loading, error }] = useUpdateDeliverableMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updates: // value for 'updates'
 *      newTrackingLink: // value for 'newTrackingLink'
 *   },
 * });
 */
export function useUpdateDeliverableMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateDeliverableMutation, UpdateDeliverableMutationVariables>
) {
  return Apollo.useMutation<UpdateDeliverableMutation, UpdateDeliverableMutationVariables>(
    UpdateDeliverableDocument,
    baseOptions
  );
}
export type UpdateDeliverableMutationHookResult = ReturnType<typeof useUpdateDeliverableMutation>;
export type UpdateDeliverableMutationResult = Apollo.MutationResult<UpdateDeliverableMutation>;
export type UpdateDeliverableMutationOptions = Apollo.BaseMutationOptions<
  UpdateDeliverableMutation,
  UpdateDeliverableMutationVariables
>;
export const DeleteDeliverableDocument = gql`
  mutation DeleteDeliverable($id: ObjectId!) {
    deleteDeliverable(id: $id) {
      success
      message
    }
  }
`;
export type DeleteDeliverableMutationFn = Apollo.MutationFunction<
  DeleteDeliverableMutation,
  DeleteDeliverableMutationVariables
>;

/**
 * __useDeleteDeliverableMutation__
 *
 * To run a mutation, you first call `useDeleteDeliverableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDeliverableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDeliverableMutation, { data, loading, error }] = useDeleteDeliverableMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDeliverableMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteDeliverableMutation, DeleteDeliverableMutationVariables>
) {
  return Apollo.useMutation<DeleteDeliverableMutation, DeleteDeliverableMutationVariables>(
    DeleteDeliverableDocument,
    baseOptions
  );
}
export type DeleteDeliverableMutationHookResult = ReturnType<typeof useDeleteDeliverableMutation>;
export type DeleteDeliverableMutationResult = Apollo.MutationResult<DeleteDeliverableMutation>;
export type DeleteDeliverableMutationOptions = Apollo.BaseMutationOptions<
  DeleteDeliverableMutation,
  DeleteDeliverableMutationVariables
>;
export const CreateInvoiceDocument = gql`
  mutation CreateInvoice($updates: CollaborationInvoiceUpdates!) {
    createCollaborationInvoice(updates: $updates)
  }
`;
export type CreateInvoiceMutationFn = Apollo.MutationFunction<CreateInvoiceMutation, CreateInvoiceMutationVariables>;

/**
 * __useCreateInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoiceMutation, { data, loading, error }] = useCreateInvoiceMutation({
 *   variables: {
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useCreateInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateInvoiceMutation, CreateInvoiceMutationVariables>
) {
  return Apollo.useMutation<CreateInvoiceMutation, CreateInvoiceMutationVariables>(CreateInvoiceDocument, baseOptions);
}
export type CreateInvoiceMutationHookResult = ReturnType<typeof useCreateInvoiceMutation>;
export type CreateInvoiceMutationResult = Apollo.MutationResult<CreateInvoiceMutation>;
export type CreateInvoiceMutationOptions = Apollo.BaseMutationOptions<
  CreateInvoiceMutation,
  CreateInvoiceMutationVariables
>;
export const UpdateInvoiceDocument = gql`
  mutation UpdateInvoice($id: ObjectId!, $updates: CollaborationInvoiceUpdates!) {
    updateCollaborationInvoice(id: $id, updates: $updates) {
      success
      message
      insertedId
    }
  }
`;
export type UpdateInvoiceMutationFn = Apollo.MutationFunction<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>;

/**
 * __useUpdateInvoiceMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceMutation, { data, loading, error }] = useUpdateInvoiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useUpdateInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>
) {
  return Apollo.useMutation<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>(UpdateInvoiceDocument, baseOptions);
}
export type UpdateInvoiceMutationHookResult = ReturnType<typeof useUpdateInvoiceMutation>;
export type UpdateInvoiceMutationResult = Apollo.MutationResult<UpdateInvoiceMutation>;
export type UpdateInvoiceMutationOptions = Apollo.BaseMutationOptions<
  UpdateInvoiceMutation,
  UpdateInvoiceMutationVariables
>;
export const DeleteInvoiceDocument = gql`
  mutation DeleteInvoice($id: ObjectId!) {
    deleteCollaborationInvoice(id: $id) {
      success
      message
    }
  }
`;
export type DeleteInvoiceMutationFn = Apollo.MutationFunction<DeleteInvoiceMutation, DeleteInvoiceMutationVariables>;

/**
 * __useDeleteInvoiceMutation__
 *
 * To run a mutation, you first call `useDeleteInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvoiceMutation, { data, loading, error }] = useDeleteInvoiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteInvoiceMutation, DeleteInvoiceMutationVariables>
) {
  return Apollo.useMutation<DeleteInvoiceMutation, DeleteInvoiceMutationVariables>(DeleteInvoiceDocument, baseOptions);
}
export type DeleteInvoiceMutationHookResult = ReturnType<typeof useDeleteInvoiceMutation>;
export type DeleteInvoiceMutationResult = Apollo.MutationResult<DeleteInvoiceMutation>;
export type DeleteInvoiceMutationOptions = Apollo.BaseMutationOptions<
  DeleteInvoiceMutation,
  DeleteInvoiceMutationVariables
>;
export const ShareInvoiceDocument = gql`
  mutation ShareInvoice($id: ObjectId!, $shareDetails: ShareCollaborationInvoiceDetails!) {
    shareCollaborationInvoice(id: $id, shareDetails: $shareDetails) {
      success
    }
  }
`;
export type ShareInvoiceMutationFn = Apollo.MutationFunction<ShareInvoiceMutation, ShareInvoiceMutationVariables>;

/**
 * __useShareInvoiceMutation__
 *
 * To run a mutation, you first call `useShareInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareInvoiceMutation, { data, loading, error }] = useShareInvoiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      shareDetails: // value for 'shareDetails'
 *   },
 * });
 */
export function useShareInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<ShareInvoiceMutation, ShareInvoiceMutationVariables>
) {
  return Apollo.useMutation<ShareInvoiceMutation, ShareInvoiceMutationVariables>(ShareInvoiceDocument, baseOptions);
}
export type ShareInvoiceMutationHookResult = ReturnType<typeof useShareInvoiceMutation>;
export type ShareInvoiceMutationResult = Apollo.MutationResult<ShareInvoiceMutation>;
export type ShareInvoiceMutationOptions = Apollo.BaseMutationOptions<
  ShareInvoiceMutation,
  ShareInvoiceMutationVariables
>;
export const CreateCollaborationInvoiceLinkDocument = gql`
  mutation CreateCollaborationInvoiceLink($collaborationInvoiceId: ObjectId!) {
    createCollaborationInvoiceLink(collaborationInvoiceId: $collaborationInvoiceId) {
      link
    }
  }
`;
export type CreateCollaborationInvoiceLinkMutationFn = Apollo.MutationFunction<
  CreateCollaborationInvoiceLinkMutation,
  CreateCollaborationInvoiceLinkMutationVariables
>;

/**
 * __useCreateCollaborationInvoiceLinkMutation__
 *
 * To run a mutation, you first call `useCreateCollaborationInvoiceLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCollaborationInvoiceLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCollaborationInvoiceLinkMutation, { data, loading, error }] = useCreateCollaborationInvoiceLinkMutation({
 *   variables: {
 *      collaborationInvoiceId: // value for 'collaborationInvoiceId'
 *   },
 * });
 */
export function useCreateCollaborationInvoiceLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCollaborationInvoiceLinkMutation,
    CreateCollaborationInvoiceLinkMutationVariables
  >
) {
  return Apollo.useMutation<CreateCollaborationInvoiceLinkMutation, CreateCollaborationInvoiceLinkMutationVariables>(
    CreateCollaborationInvoiceLinkDocument,
    baseOptions
  );
}
export type CreateCollaborationInvoiceLinkMutationHookResult = ReturnType<
  typeof useCreateCollaborationInvoiceLinkMutation
>;
export type CreateCollaborationInvoiceLinkMutationResult =
  Apollo.MutationResult<CreateCollaborationInvoiceLinkMutation>;
export type CreateCollaborationInvoiceLinkMutationOptions = Apollo.BaseMutationOptions<
  CreateCollaborationInvoiceLinkMutation,
  CreateCollaborationInvoiceLinkMutationVariables
>;
export const DeleteMediaKitConfigDocument = gql`
  mutation DeleteMediaKitConfig($id: ObjectId!) {
    deleteMediaKitConfig(id: $id) {
      success
    }
  }
`;
export type DeleteMediaKitConfigMutationFn = Apollo.MutationFunction<
  DeleteMediaKitConfigMutation,
  DeleteMediaKitConfigMutationVariables
>;

/**
 * __useDeleteMediaKitConfigMutation__
 *
 * To run a mutation, you first call `useDeleteMediaKitConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMediaKitConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMediaKitConfigMutation, { data, loading, error }] = useDeleteMediaKitConfigMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMediaKitConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteMediaKitConfigMutation, DeleteMediaKitConfigMutationVariables>
) {
  return Apollo.useMutation<DeleteMediaKitConfigMutation, DeleteMediaKitConfigMutationVariables>(
    DeleteMediaKitConfigDocument,
    baseOptions
  );
}
export type DeleteMediaKitConfigMutationHookResult = ReturnType<typeof useDeleteMediaKitConfigMutation>;
export type DeleteMediaKitConfigMutationResult = Apollo.MutationResult<DeleteMediaKitConfigMutation>;
export type DeleteMediaKitConfigMutationOptions = Apollo.BaseMutationOptions<
  DeleteMediaKitConfigMutation,
  DeleteMediaKitConfigMutationVariables
>;
export const UpdateMediaKitDraftConfigDocument = gql`
  mutation UpdateMediaKitDraftConfig($id: ObjectId!, $updates: MediaKitConfigUpdates!) {
    updateMediaKitDraftConfig(id: $id, updates: $updates) {
      success
      message
    }
  }
`;
export type UpdateMediaKitDraftConfigMutationFn = Apollo.MutationFunction<
  UpdateMediaKitDraftConfigMutation,
  UpdateMediaKitDraftConfigMutationVariables
>;

/**
 * __useUpdateMediaKitDraftConfigMutation__
 *
 * To run a mutation, you first call `useUpdateMediaKitDraftConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMediaKitDraftConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMediaKitDraftConfigMutation, { data, loading, error }] = useUpdateMediaKitDraftConfigMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useUpdateMediaKitDraftConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMediaKitDraftConfigMutation,
    UpdateMediaKitDraftConfigMutationVariables
  >
) {
  return Apollo.useMutation<UpdateMediaKitDraftConfigMutation, UpdateMediaKitDraftConfigMutationVariables>(
    UpdateMediaKitDraftConfigDocument,
    baseOptions
  );
}
export type UpdateMediaKitDraftConfigMutationHookResult = ReturnType<typeof useUpdateMediaKitDraftConfigMutation>;
export type UpdateMediaKitDraftConfigMutationResult = Apollo.MutationResult<UpdateMediaKitDraftConfigMutation>;
export type UpdateMediaKitDraftConfigMutationOptions = Apollo.BaseMutationOptions<
  UpdateMediaKitDraftConfigMutation,
  UpdateMediaKitDraftConfigMutationVariables
>;
export const CreateElementDocument = gql`
  mutation CreateElement($type: ElementType, $title: String, $mediaKitId: ObjectId, $index: Int) {
    createElement(type: $type, title: $title, mediaKitId: $mediaKitId, index: $index)
  }
`;
export type CreateElementMutationFn = Apollo.MutationFunction<CreateElementMutation, CreateElementMutationVariables>;

/**
 * __useCreateElementMutation__
 *
 * To run a mutation, you first call `useCreateElementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateElementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createElementMutation, { data, loading, error }] = useCreateElementMutation({
 *   variables: {
 *      type: // value for 'type'
 *      title: // value for 'title'
 *      mediaKitId: // value for 'mediaKitId'
 *      index: // value for 'index'
 *   },
 * });
 */
export function useCreateElementMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateElementMutation, CreateElementMutationVariables>
) {
  return Apollo.useMutation<CreateElementMutation, CreateElementMutationVariables>(CreateElementDocument, baseOptions);
}
export type CreateElementMutationHookResult = ReturnType<typeof useCreateElementMutation>;
export type CreateElementMutationResult = Apollo.MutationResult<CreateElementMutation>;
export type CreateElementMutationOptions = Apollo.BaseMutationOptions<
  CreateElementMutation,
  CreateElementMutationVariables
>;
export const PublishMediaKitDraftConfigDocument = gql`
  mutation PublishMediaKitDraftConfig($id: ObjectId!) {
    publishMediaKitDraftConfig(id: $id) {
      success
      message
    }
  }
`;
export type PublishMediaKitDraftConfigMutationFn = Apollo.MutationFunction<
  PublishMediaKitDraftConfigMutation,
  PublishMediaKitDraftConfigMutationVariables
>;

/**
 * __usePublishMediaKitDraftConfigMutation__
 *
 * To run a mutation, you first call `usePublishMediaKitDraftConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishMediaKitDraftConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishMediaKitDraftConfigMutation, { data, loading, error }] = usePublishMediaKitDraftConfigMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishMediaKitDraftConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PublishMediaKitDraftConfigMutation,
    PublishMediaKitDraftConfigMutationVariables
  >
) {
  return Apollo.useMutation<PublishMediaKitDraftConfigMutation, PublishMediaKitDraftConfigMutationVariables>(
    PublishMediaKitDraftConfigDocument,
    baseOptions
  );
}
export type PublishMediaKitDraftConfigMutationHookResult = ReturnType<typeof usePublishMediaKitDraftConfigMutation>;
export type PublishMediaKitDraftConfigMutationResult = Apollo.MutationResult<PublishMediaKitDraftConfigMutation>;
export type PublishMediaKitDraftConfigMutationOptions = Apollo.BaseMutationOptions<
  PublishMediaKitDraftConfigMutation,
  PublishMediaKitDraftConfigMutationVariables
>;
export const UpdateElementDraftDocument = gql`
  mutation UpdateElementDraft($id: ObjectId!, $content: MediaKitElementContentInput) {
    updateElementDraft(id: $id, content: $content) {
      success
      message
    }
  }
`;
export type UpdateElementDraftMutationFn = Apollo.MutationFunction<
  UpdateElementDraftMutation,
  UpdateElementDraftMutationVariables
>;

/**
 * __useUpdateElementDraftMutation__
 *
 * To run a mutation, you first call `useUpdateElementDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateElementDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateElementDraftMutation, { data, loading, error }] = useUpdateElementDraftMutation({
 *   variables: {
 *      id: // value for 'id'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useUpdateElementDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateElementDraftMutation, UpdateElementDraftMutationVariables>
) {
  return Apollo.useMutation<UpdateElementDraftMutation, UpdateElementDraftMutationVariables>(
    UpdateElementDraftDocument,
    baseOptions
  );
}
export type UpdateElementDraftMutationHookResult = ReturnType<typeof useUpdateElementDraftMutation>;
export type UpdateElementDraftMutationResult = Apollo.MutationResult<UpdateElementDraftMutation>;
export type UpdateElementDraftMutationOptions = Apollo.BaseMutationOptions<
  UpdateElementDraftMutation,
  UpdateElementDraftMutationVariables
>;
export const DeleteElementDocument = gql`
  mutation DeleteElement($id: ObjectId!) {
    deleteElement(id: $id) {
      success
      message
    }
  }
`;
export type DeleteElementMutationFn = Apollo.MutationFunction<DeleteElementMutation, DeleteElementMutationVariables>;

/**
 * __useDeleteElementMutation__
 *
 * To run a mutation, you first call `useDeleteElementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteElementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteElementMutation, { data, loading, error }] = useDeleteElementMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteElementMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteElementMutation, DeleteElementMutationVariables>
) {
  return Apollo.useMutation<DeleteElementMutation, DeleteElementMutationVariables>(DeleteElementDocument, baseOptions);
}
export type DeleteElementMutationHookResult = ReturnType<typeof useDeleteElementMutation>;
export type DeleteElementMutationResult = Apollo.MutationResult<DeleteElementMutation>;
export type DeleteElementMutationOptions = Apollo.BaseMutationOptions<
  DeleteElementMutation,
  DeleteElementMutationVariables
>;
export const ShareMediaKitToContactDocument = gql`
  mutation ShareMediaKitToContact(
    $mediaKitId: ObjectId!
    $contactIds: [ObjectId]
    $coverLetter: String
    $contactDetails: MediaKitShareContact
  ) {
    shareMediaKitToContact(
      mediaKitId: $mediaKitId
      contactIds: $contactIds
      coverLetter: $coverLetter
      contactDetails: $contactDetails
    ) {
      success
      message
    }
  }
`;
export type ShareMediaKitToContactMutationFn = Apollo.MutationFunction<
  ShareMediaKitToContactMutation,
  ShareMediaKitToContactMutationVariables
>;

/**
 * __useShareMediaKitToContactMutation__
 *
 * To run a mutation, you first call `useShareMediaKitToContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareMediaKitToContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareMediaKitToContactMutation, { data, loading, error }] = useShareMediaKitToContactMutation({
 *   variables: {
 *      mediaKitId: // value for 'mediaKitId'
 *      contactIds: // value for 'contactIds'
 *      coverLetter: // value for 'coverLetter'
 *      contactDetails: // value for 'contactDetails'
 *   },
 * });
 */
export function useShareMediaKitToContactMutation(
  baseOptions?: Apollo.MutationHookOptions<ShareMediaKitToContactMutation, ShareMediaKitToContactMutationVariables>
) {
  return Apollo.useMutation<ShareMediaKitToContactMutation, ShareMediaKitToContactMutationVariables>(
    ShareMediaKitToContactDocument,
    baseOptions
  );
}
export type ShareMediaKitToContactMutationHookResult = ReturnType<typeof useShareMediaKitToContactMutation>;
export type ShareMediaKitToContactMutationResult = Apollo.MutationResult<ShareMediaKitToContactMutation>;
export type ShareMediaKitToContactMutationOptions = Apollo.BaseMutationOptions<
  ShareMediaKitToContactMutation,
  ShareMediaKitToContactMutationVariables
>;
export const UpdateMediaKitConfigDocument = gql`
  mutation UpdateMediaKitConfig($id: ObjectId!, $updates: MediaKitConfigUpdates!) {
    updateMediaKitConfig(id: $id, updates: $updates) {
      success
      message
    }
  }
`;
export type UpdateMediaKitConfigMutationFn = Apollo.MutationFunction<
  UpdateMediaKitConfigMutation,
  UpdateMediaKitConfigMutationVariables
>;

/**
 * __useUpdateMediaKitConfigMutation__
 *
 * To run a mutation, you first call `useUpdateMediaKitConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMediaKitConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMediaKitConfigMutation, { data, loading, error }] = useUpdateMediaKitConfigMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useUpdateMediaKitConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateMediaKitConfigMutation, UpdateMediaKitConfigMutationVariables>
) {
  return Apollo.useMutation<UpdateMediaKitConfigMutation, UpdateMediaKitConfigMutationVariables>(
    UpdateMediaKitConfigDocument,
    baseOptions
  );
}
export type UpdateMediaKitConfigMutationHookResult = ReturnType<typeof useUpdateMediaKitConfigMutation>;
export type UpdateMediaKitConfigMutationResult = Apollo.MutationResult<UpdateMediaKitConfigMutation>;
export type UpdateMediaKitConfigMutationOptions = Apollo.BaseMutationOptions<
  UpdateMediaKitConfigMutation,
  UpdateMediaKitConfigMutationVariables
>;
export const CreateMediaKitLinkDocument = gql`
  mutation CreateMediaKitLink($mediaKitId: ObjectId!) {
    createMediaKitLink(mediaKitId: $mediaKitId)
  }
`;
export type CreateMediaKitLinkMutationFn = Apollo.MutationFunction<
  CreateMediaKitLinkMutation,
  CreateMediaKitLinkMutationVariables
>;

/**
 * __useCreateMediaKitLinkMutation__
 *
 * To run a mutation, you first call `useCreateMediaKitLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMediaKitLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMediaKitLinkMutation, { data, loading, error }] = useCreateMediaKitLinkMutation({
 *   variables: {
 *      mediaKitId: // value for 'mediaKitId'
 *   },
 * });
 */
export function useCreateMediaKitLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateMediaKitLinkMutation, CreateMediaKitLinkMutationVariables>
) {
  return Apollo.useMutation<CreateMediaKitLinkMutation, CreateMediaKitLinkMutationVariables>(
    CreateMediaKitLinkDocument,
    baseOptions
  );
}
export type CreateMediaKitLinkMutationHookResult = ReturnType<typeof useCreateMediaKitLinkMutation>;
export type CreateMediaKitLinkMutationResult = Apollo.MutationResult<CreateMediaKitLinkMutation>;
export type CreateMediaKitLinkMutationOptions = Apollo.BaseMutationOptions<
  CreateMediaKitLinkMutation,
  CreateMediaKitLinkMutationVariables
>;
export const CreateMediaKitConfigDocument = gql`
  mutation CreateMediaKitConfig($updates: MediaKitConfigUpdates) {
    createMediaKitConfig(updates: $updates)
  }
`;
export type CreateMediaKitConfigMutationFn = Apollo.MutationFunction<
  CreateMediaKitConfigMutation,
  CreateMediaKitConfigMutationVariables
>;

/**
 * __useCreateMediaKitConfigMutation__
 *
 * To run a mutation, you first call `useCreateMediaKitConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMediaKitConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMediaKitConfigMutation, { data, loading, error }] = useCreateMediaKitConfigMutation({
 *   variables: {
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useCreateMediaKitConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateMediaKitConfigMutation, CreateMediaKitConfigMutationVariables>
) {
  return Apollo.useMutation<CreateMediaKitConfigMutation, CreateMediaKitConfigMutationVariables>(
    CreateMediaKitConfigDocument,
    baseOptions
  );
}
export type CreateMediaKitConfigMutationHookResult = ReturnType<typeof useCreateMediaKitConfigMutation>;
export type CreateMediaKitConfigMutationResult = Apollo.MutationResult<CreateMediaKitConfigMutation>;
export type CreateMediaKitConfigMutationOptions = Apollo.BaseMutationOptions<
  CreateMediaKitConfigMutation,
  CreateMediaKitConfigMutationVariables
>;
export const ShareAparLinkToContactDocument = gql`
  mutation ShareAparLinkToContact(
    $aparLink: String!
    $profileId: ObjectId
    $contactId: ObjectId
    $contactIds: [ObjectId]
    $contactDetails: MediaKitShareContact
  ) {
    shareAparToContact(
      aparLink: $aparLink
      profileId: $profileId
      contactId: $contactId
      contactIds: $contactIds
      contactDetails: $contactDetails
    ) {
      success
      message
      insertedId
    }
  }
`;
export type ShareAparLinkToContactMutationFn = Apollo.MutationFunction<
  ShareAparLinkToContactMutation,
  ShareAparLinkToContactMutationVariables
>;

/**
 * __useShareAparLinkToContactMutation__
 *
 * To run a mutation, you first call `useShareAparLinkToContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareAparLinkToContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareAparLinkToContactMutation, { data, loading, error }] = useShareAparLinkToContactMutation({
 *   variables: {
 *      aparLink: // value for 'aparLink'
 *      profileId: // value for 'profileId'
 *      contactId: // value for 'contactId'
 *      contactIds: // value for 'contactIds'
 *      contactDetails: // value for 'contactDetails'
 *   },
 * });
 */
export function useShareAparLinkToContactMutation(
  baseOptions?: Apollo.MutationHookOptions<ShareAparLinkToContactMutation, ShareAparLinkToContactMutationVariables>
) {
  return Apollo.useMutation<ShareAparLinkToContactMutation, ShareAparLinkToContactMutationVariables>(
    ShareAparLinkToContactDocument,
    baseOptions
  );
}
export type ShareAparLinkToContactMutationHookResult = ReturnType<typeof useShareAparLinkToContactMutation>;
export type ShareAparLinkToContactMutationResult = Apollo.MutationResult<ShareAparLinkToContactMutation>;
export type ShareAparLinkToContactMutationOptions = Apollo.BaseMutationOptions<
  ShareAparLinkToContactMutation,
  ShareAparLinkToContactMutationVariables
>;
export const UpdateNotificationReadStatusDocument = gql`
  mutation updateNotificationReadStatus($notificationId: ObjectId!) {
    updateNotificationReadStatus(notificationId: $notificationId) {
      success
      message
    }
  }
`;
export type UpdateNotificationReadStatusMutationFn = Apollo.MutationFunction<
  UpdateNotificationReadStatusMutation,
  UpdateNotificationReadStatusMutationVariables
>;

/**
 * __useUpdateNotificationReadStatusMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationReadStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationReadStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationReadStatusMutation, { data, loading, error }] = useUpdateNotificationReadStatusMutation({
 *   variables: {
 *      notificationId: // value for 'notificationId'
 *   },
 * });
 */
export function useUpdateNotificationReadStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateNotificationReadStatusMutation,
    UpdateNotificationReadStatusMutationVariables
  >
) {
  return Apollo.useMutation<UpdateNotificationReadStatusMutation, UpdateNotificationReadStatusMutationVariables>(
    UpdateNotificationReadStatusDocument,
    baseOptions
  );
}
export type UpdateNotificationReadStatusMutationHookResult = ReturnType<typeof useUpdateNotificationReadStatusMutation>;
export type UpdateNotificationReadStatusMutationResult = Apollo.MutationResult<UpdateNotificationReadStatusMutation>;
export type UpdateNotificationReadStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateNotificationReadStatusMutation,
  UpdateNotificationReadStatusMutationVariables
>;
export const CreateProfileDocument = gql`
  mutation CreateProfile($info: NewProfileInfo!) {
    createProfile(info: $info)
  }
`;
export type CreateProfileMutationFn = Apollo.MutationFunction<CreateProfileMutation, CreateProfileMutationVariables>;

/**
 * __useCreateProfileMutation__
 *
 * To run a mutation, you first call `useCreateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProfileMutation, { data, loading, error }] = useCreateProfileMutation({
 *   variables: {
 *      info: // value for 'info'
 *   },
 * });
 */
export function useCreateProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateProfileMutation, CreateProfileMutationVariables>
) {
  return Apollo.useMutation<CreateProfileMutation, CreateProfileMutationVariables>(CreateProfileDocument, baseOptions);
}
export type CreateProfileMutationHookResult = ReturnType<typeof useCreateProfileMutation>;
export type CreateProfileMutationResult = Apollo.MutationResult<CreateProfileMutation>;
export type CreateProfileMutationOptions = Apollo.BaseMutationOptions<
  CreateProfileMutation,
  CreateProfileMutationVariables
>;
export const DeleteProfileDocument = gql`
  mutation DeleteProfile($profileId: ObjectId!, $pageId: String) {
    deleteProfile(profileId: $profileId, pageId: $pageId) {
      success
      message
    }
  }
`;
export type DeleteProfileMutationFn = Apollo.MutationFunction<DeleteProfileMutation, DeleteProfileMutationVariables>;

/**
 * __useDeleteProfileMutation__
 *
 * To run a mutation, you first call `useDeleteProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProfileMutation, { data, loading, error }] = useDeleteProfileMutation({
 *   variables: {
 *      profileId: // value for 'profileId'
 *      pageId: // value for 'pageId'
 *   },
 * });
 */
export function useDeleteProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteProfileMutation, DeleteProfileMutationVariables>
) {
  return Apollo.useMutation<DeleteProfileMutation, DeleteProfileMutationVariables>(DeleteProfileDocument, baseOptions);
}
export type DeleteProfileMutationHookResult = ReturnType<typeof useDeleteProfileMutation>;
export type DeleteProfileMutationResult = Apollo.MutationResult<DeleteProfileMutation>;
export type DeleteProfileMutationOptions = Apollo.BaseMutationOptions<
  DeleteProfileMutation,
  DeleteProfileMutationVariables
>;
export const UpdateProfileDocument = gql`
  mutation UpdateProfile($profileId: ObjectId!, $updates: ProfileUpdates!) {
    updateProfile(profileId: $profileId, updates: $updates) {
      success
      message
    }
  }
`;
export type UpdateProfileMutationFn = Apollo.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      profileId: // value for 'profileId'
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useUpdateProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>
) {
  return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, baseOptions);
}
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateProfileMutation,
  UpdateProfileMutationVariables
>;
export const UpdateProfileNoteDocument = gql`
  mutation UpdateProfileNote($accountId: String!, $updates: ProfileNoteInput!) {
    updateProfileNote(accountId: $accountId, updates: $updates) {
      success
    }
  }
`;
export type UpdateProfileNoteMutationFn = Apollo.MutationFunction<
  UpdateProfileNoteMutation,
  UpdateProfileNoteMutationVariables
>;

/**
 * __useUpdateProfileNoteMutation__
 *
 * To run a mutation, you first call `useUpdateProfileNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileNoteMutation, { data, loading, error }] = useUpdateProfileNoteMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useUpdateProfileNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateProfileNoteMutation, UpdateProfileNoteMutationVariables>
) {
  return Apollo.useMutation<UpdateProfileNoteMutation, UpdateProfileNoteMutationVariables>(
    UpdateProfileNoteDocument,
    baseOptions
  );
}
export type UpdateProfileNoteMutationHookResult = ReturnType<typeof useUpdateProfileNoteMutation>;
export type UpdateProfileNoteMutationResult = Apollo.MutationResult<UpdateProfileNoteMutation>;
export type UpdateProfileNoteMutationOptions = Apollo.BaseMutationOptions<
  UpdateProfileNoteMutation,
  UpdateProfileNoteMutationVariables
>;
export const CreateTiktokProfileDocument = gql`
  mutation CreateTiktokProfile($username: String!) {
    createTiktokProfile(username: $username) {
      success
      message
      insertedId
    }
  }
`;
export type CreateTiktokProfileMutationFn = Apollo.MutationFunction<
  CreateTiktokProfileMutation,
  CreateTiktokProfileMutationVariables
>;

/**
 * __useCreateTiktokProfileMutation__
 *
 * To run a mutation, you first call `useCreateTiktokProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTiktokProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTiktokProfileMutation, { data, loading, error }] = useCreateTiktokProfileMutation({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useCreateTiktokProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTiktokProfileMutation, CreateTiktokProfileMutationVariables>
) {
  return Apollo.useMutation<CreateTiktokProfileMutation, CreateTiktokProfileMutationVariables>(
    CreateTiktokProfileDocument,
    baseOptions
  );
}
export type CreateTiktokProfileMutationHookResult = ReturnType<typeof useCreateTiktokProfileMutation>;
export type CreateTiktokProfileMutationResult = Apollo.MutationResult<CreateTiktokProfileMutation>;
export type CreateTiktokProfileMutationOptions = Apollo.BaseMutationOptions<
  CreateTiktokProfileMutation,
  CreateTiktokProfileMutationVariables
>;
export const ConnectTiktokProfileDocument = gql`
  mutation ConnectTiktokProfile($profileId: String!) {
    connectTiktokProfile(profileId: $profileId) {
      success
    }
  }
`;
export type ConnectTiktokProfileMutationFn = Apollo.MutationFunction<
  ConnectTiktokProfileMutation,
  ConnectTiktokProfileMutationVariables
>;

/**
 * __useConnectTiktokProfileMutation__
 *
 * To run a mutation, you first call `useConnectTiktokProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectTiktokProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectTiktokProfileMutation, { data, loading, error }] = useConnectTiktokProfileMutation({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useConnectTiktokProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<ConnectTiktokProfileMutation, ConnectTiktokProfileMutationVariables>
) {
  return Apollo.useMutation<ConnectTiktokProfileMutation, ConnectTiktokProfileMutationVariables>(
    ConnectTiktokProfileDocument,
    baseOptions
  );
}
export type ConnectTiktokProfileMutationHookResult = ReturnType<typeof useConnectTiktokProfileMutation>;
export type ConnectTiktokProfileMutationResult = Apollo.MutationResult<ConnectTiktokProfileMutation>;
export type ConnectTiktokProfileMutationOptions = Apollo.BaseMutationOptions<
  ConnectTiktokProfileMutation,
  ConnectTiktokProfileMutationVariables
>;
export const DisconnectTiktokProfileDocument = gql`
  mutation DisconnectTiktokProfile($profileId: String!) {
    disconnectTiktokProfile(profileId: $profileId) {
      success
    }
  }
`;
export type DisconnectTiktokProfileMutationFn = Apollo.MutationFunction<
  DisconnectTiktokProfileMutation,
  DisconnectTiktokProfileMutationVariables
>;

/**
 * __useDisconnectTiktokProfileMutation__
 *
 * To run a mutation, you first call `useDisconnectTiktokProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectTiktokProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectTiktokProfileMutation, { data, loading, error }] = useDisconnectTiktokProfileMutation({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useDisconnectTiktokProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<DisconnectTiktokProfileMutation, DisconnectTiktokProfileMutationVariables>
) {
  return Apollo.useMutation<DisconnectTiktokProfileMutation, DisconnectTiktokProfileMutationVariables>(
    DisconnectTiktokProfileDocument,
    baseOptions
  );
}
export type DisconnectTiktokProfileMutationHookResult = ReturnType<typeof useDisconnectTiktokProfileMutation>;
export type DisconnectTiktokProfileMutationResult = Apollo.MutationResult<DisconnectTiktokProfileMutation>;
export type DisconnectTiktokProfileMutationOptions = Apollo.BaseMutationOptions<
  DisconnectTiktokProfileMutation,
  DisconnectTiktokProfileMutationVariables
>;
export const DeleteReportConfigDocument = gql`
  mutation DeleteReportConfig($reportId: ObjectId!) {
    deleteReportConfig(reportId: $reportId) {
      success
    }
  }
`;
export type DeleteReportConfigMutationFn = Apollo.MutationFunction<
  DeleteReportConfigMutation,
  DeleteReportConfigMutationVariables
>;

/**
 * __useDeleteReportConfigMutation__
 *
 * To run a mutation, you first call `useDeleteReportConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReportConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReportConfigMutation, { data, loading, error }] = useDeleteReportConfigMutation({
 *   variables: {
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useDeleteReportConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteReportConfigMutation, DeleteReportConfigMutationVariables>
) {
  return Apollo.useMutation<DeleteReportConfigMutation, DeleteReportConfigMutationVariables>(
    DeleteReportConfigDocument,
    baseOptions
  );
}
export type DeleteReportConfigMutationHookResult = ReturnType<typeof useDeleteReportConfigMutation>;
export type DeleteReportConfigMutationResult = Apollo.MutationResult<DeleteReportConfigMutation>;
export type DeleteReportConfigMutationOptions = Apollo.BaseMutationOptions<
  DeleteReportConfigMutation,
  DeleteReportConfigMutationVariables
>;
export const CreateReportLinkDocument = gql`
  mutation CreateReportLink($reportConfigId: ObjectId!) {
    createReportLink(reportConfigId: $reportConfigId)
  }
`;
export type CreateReportLinkMutationFn = Apollo.MutationFunction<
  CreateReportLinkMutation,
  CreateReportLinkMutationVariables
>;

/**
 * __useCreateReportLinkMutation__
 *
 * To run a mutation, you first call `useCreateReportLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReportLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReportLinkMutation, { data, loading, error }] = useCreateReportLinkMutation({
 *   variables: {
 *      reportConfigId: // value for 'reportConfigId'
 *   },
 * });
 */
export function useCreateReportLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateReportLinkMutation, CreateReportLinkMutationVariables>
) {
  return Apollo.useMutation<CreateReportLinkMutation, CreateReportLinkMutationVariables>(
    CreateReportLinkDocument,
    baseOptions
  );
}
export type CreateReportLinkMutationHookResult = ReturnType<typeof useCreateReportLinkMutation>;
export type CreateReportLinkMutationResult = Apollo.MutationResult<CreateReportLinkMutation>;
export type CreateReportLinkMutationOptions = Apollo.BaseMutationOptions<
  CreateReportLinkMutation,
  CreateReportLinkMutationVariables
>;
export const CreateSupplementMediaDocument = gql`
  mutation CreateSupplementMedia($input: SupplementMediaInput!) {
    createSupplementMedia(input: $input) {
      success
      insertedId
      message
    }
  }
`;
export type CreateSupplementMediaMutationFn = Apollo.MutationFunction<
  CreateSupplementMediaMutation,
  CreateSupplementMediaMutationVariables
>;

/**
 * __useCreateSupplementMediaMutation__
 *
 * To run a mutation, you first call `useCreateSupplementMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSupplementMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSupplementMediaMutation, { data, loading, error }] = useCreateSupplementMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSupplementMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateSupplementMediaMutation, CreateSupplementMediaMutationVariables>
) {
  return Apollo.useMutation<CreateSupplementMediaMutation, CreateSupplementMediaMutationVariables>(
    CreateSupplementMediaDocument,
    baseOptions
  );
}
export type CreateSupplementMediaMutationHookResult = ReturnType<typeof useCreateSupplementMediaMutation>;
export type CreateSupplementMediaMutationResult = Apollo.MutationResult<CreateSupplementMediaMutation>;
export type CreateSupplementMediaMutationOptions = Apollo.BaseMutationOptions<
  CreateSupplementMediaMutation,
  CreateSupplementMediaMutationVariables
>;
export const UpdateSupplementMediaDocument = gql`
  mutation UpdateSupplementMedia($id: ObjectId!, $updates: SupplementMediaInput!) {
    updateSupplementMedia(id: $id, updates: $updates) {
      success
      message
    }
  }
`;
export type UpdateSupplementMediaMutationFn = Apollo.MutationFunction<
  UpdateSupplementMediaMutation,
  UpdateSupplementMediaMutationVariables
>;

/**
 * __useUpdateSupplementMediaMutation__
 *
 * To run a mutation, you first call `useUpdateSupplementMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSupplementMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSupplementMediaMutation, { data, loading, error }] = useUpdateSupplementMediaMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useUpdateSupplementMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSupplementMediaMutation, UpdateSupplementMediaMutationVariables>
) {
  return Apollo.useMutation<UpdateSupplementMediaMutation, UpdateSupplementMediaMutationVariables>(
    UpdateSupplementMediaDocument,
    baseOptions
  );
}
export type UpdateSupplementMediaMutationHookResult = ReturnType<typeof useUpdateSupplementMediaMutation>;
export type UpdateSupplementMediaMutationResult = Apollo.MutationResult<UpdateSupplementMediaMutation>;
export type UpdateSupplementMediaMutationOptions = Apollo.BaseMutationOptions<
  UpdateSupplementMediaMutation,
  UpdateSupplementMediaMutationVariables
>;
export const DeleteSupplementMediaDocument = gql`
  mutation DeleteSupplementMedia($id: ObjectId!) {
    deleteSupplementMedia(id: $id) {
      success
      message
    }
  }
`;
export type DeleteSupplementMediaMutationFn = Apollo.MutationFunction<
  DeleteSupplementMediaMutation,
  DeleteSupplementMediaMutationVariables
>;

/**
 * __useDeleteSupplementMediaMutation__
 *
 * To run a mutation, you first call `useDeleteSupplementMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSupplementMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSupplementMediaMutation, { data, loading, error }] = useDeleteSupplementMediaMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSupplementMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteSupplementMediaMutation, DeleteSupplementMediaMutationVariables>
) {
  return Apollo.useMutation<DeleteSupplementMediaMutation, DeleteSupplementMediaMutationVariables>(
    DeleteSupplementMediaDocument,
    baseOptions
  );
}
export type DeleteSupplementMediaMutationHookResult = ReturnType<typeof useDeleteSupplementMediaMutation>;
export type DeleteSupplementMediaMutationResult = Apollo.MutationResult<DeleteSupplementMediaMutation>;
export type DeleteSupplementMediaMutationOptions = Apollo.BaseMutationOptions<
  DeleteSupplementMediaMutation,
  DeleteSupplementMediaMutationVariables
>;
export const ClearOrphanedLinksDocument = gql`
  mutation ClearOrphanedLinks {
    clearOrphanedLinks {
      success
      message
    }
  }
`;
export type ClearOrphanedLinksMutationFn = Apollo.MutationFunction<
  ClearOrphanedLinksMutation,
  ClearOrphanedLinksMutationVariables
>;

/**
 * __useClearOrphanedLinksMutation__
 *
 * To run a mutation, you first call `useClearOrphanedLinksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearOrphanedLinksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearOrphanedLinksMutation, { data, loading, error }] = useClearOrphanedLinksMutation({
 *   variables: {
 *   },
 * });
 */
export function useClearOrphanedLinksMutation(
  baseOptions?: Apollo.MutationHookOptions<ClearOrphanedLinksMutation, ClearOrphanedLinksMutationVariables>
) {
  return Apollo.useMutation<ClearOrphanedLinksMutation, ClearOrphanedLinksMutationVariables>(
    ClearOrphanedLinksDocument,
    baseOptions
  );
}
export type ClearOrphanedLinksMutationHookResult = ReturnType<typeof useClearOrphanedLinksMutation>;
export type ClearOrphanedLinksMutationResult = Apollo.MutationResult<ClearOrphanedLinksMutation>;
export type ClearOrphanedLinksMutationOptions = Apollo.BaseMutationOptions<
  ClearOrphanedLinksMutation,
  ClearOrphanedLinksMutationVariables
>;
export const CreateTrackingLinkDocument = gql`
  mutation CreateTrackingLink($updates: TrackingLinkInput) {
    createTrackingLink(updates: $updates) {
      _id
    }
  }
`;
export type CreateTrackingLinkMutationFn = Apollo.MutationFunction<
  CreateTrackingLinkMutation,
  CreateTrackingLinkMutationVariables
>;

/**
 * __useCreateTrackingLinkMutation__
 *
 * To run a mutation, you first call `useCreateTrackingLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTrackingLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTrackingLinkMutation, { data, loading, error }] = useCreateTrackingLinkMutation({
 *   variables: {
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useCreateTrackingLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTrackingLinkMutation, CreateTrackingLinkMutationVariables>
) {
  return Apollo.useMutation<CreateTrackingLinkMutation, CreateTrackingLinkMutationVariables>(
    CreateTrackingLinkDocument,
    baseOptions
  );
}
export type CreateTrackingLinkMutationHookResult = ReturnType<typeof useCreateTrackingLinkMutation>;
export type CreateTrackingLinkMutationResult = Apollo.MutationResult<CreateTrackingLinkMutation>;
export type CreateTrackingLinkMutationOptions = Apollo.BaseMutationOptions<
  CreateTrackingLinkMutation,
  CreateTrackingLinkMutationVariables
>;
export const DeleteTrackingLinkDocument = gql`
  mutation DeleteTrackingLink($trackingLinkId: ObjectId) {
    deleteTrackingLink(trackingLinkId: $trackingLinkId) {
      success
    }
  }
`;
export type DeleteTrackingLinkMutationFn = Apollo.MutationFunction<
  DeleteTrackingLinkMutation,
  DeleteTrackingLinkMutationVariables
>;

/**
 * __useDeleteTrackingLinkMutation__
 *
 * To run a mutation, you first call `useDeleteTrackingLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTrackingLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTrackingLinkMutation, { data, loading, error }] = useDeleteTrackingLinkMutation({
 *   variables: {
 *      trackingLinkId: // value for 'trackingLinkId'
 *   },
 * });
 */
export function useDeleteTrackingLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteTrackingLinkMutation, DeleteTrackingLinkMutationVariables>
) {
  return Apollo.useMutation<DeleteTrackingLinkMutation, DeleteTrackingLinkMutationVariables>(
    DeleteTrackingLinkDocument,
    baseOptions
  );
}
export type DeleteTrackingLinkMutationHookResult = ReturnType<typeof useDeleteTrackingLinkMutation>;
export type DeleteTrackingLinkMutationResult = Apollo.MutationResult<DeleteTrackingLinkMutation>;
export type DeleteTrackingLinkMutationOptions = Apollo.BaseMutationOptions<
  DeleteTrackingLinkMutation,
  DeleteTrackingLinkMutationVariables
>;
export const UpdateWalkthroughsDocument = gql`
  mutation UpdateWalkthroughs($update: String) {
    updateWalkthroughs(update: $update) {
      success
      message
    }
  }
`;
export type UpdateWalkthroughsMutationFn = Apollo.MutationFunction<
  UpdateWalkthroughsMutation,
  UpdateWalkthroughsMutationVariables
>;

/**
 * __useUpdateWalkthroughsMutation__
 *
 * To run a mutation, you first call `useUpdateWalkthroughsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWalkthroughsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWalkthroughsMutation, { data, loading, error }] = useUpdateWalkthroughsMutation({
 *   variables: {
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpdateWalkthroughsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateWalkthroughsMutation, UpdateWalkthroughsMutationVariables>
) {
  return Apollo.useMutation<UpdateWalkthroughsMutation, UpdateWalkthroughsMutationVariables>(
    UpdateWalkthroughsDocument,
    baseOptions
  );
}
export type UpdateWalkthroughsMutationHookResult = ReturnType<typeof useUpdateWalkthroughsMutation>;
export type UpdateWalkthroughsMutationResult = Apollo.MutationResult<UpdateWalkthroughsMutation>;
export type UpdateWalkthroughsMutationOptions = Apollo.BaseMutationOptions<
  UpdateWalkthroughsMutation,
  UpdateWalkthroughsMutationVariables
>;
export const UpdateUserProgressDocument = gql`
  mutation UpdateUserProgress($steps: [String]) {
    updateAccountProgress(steps: $steps) {
      success
      message
    }
  }
`;
export type UpdateUserProgressMutationFn = Apollo.MutationFunction<
  UpdateUserProgressMutation,
  UpdateUserProgressMutationVariables
>;

/**
 * __useUpdateUserProgressMutation__
 *
 * To run a mutation, you first call `useUpdateUserProgressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProgressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProgressMutation, { data, loading, error }] = useUpdateUserProgressMutation({
 *   variables: {
 *      steps: // value for 'steps'
 *   },
 * });
 */
export function useUpdateUserProgressMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserProgressMutation, UpdateUserProgressMutationVariables>
) {
  return Apollo.useMutation<UpdateUserProgressMutation, UpdateUserProgressMutationVariables>(
    UpdateUserProgressDocument,
    baseOptions
  );
}
export type UpdateUserProgressMutationHookResult = ReturnType<typeof useUpdateUserProgressMutation>;
export type UpdateUserProgressMutationResult = Apollo.MutationResult<UpdateUserProgressMutation>;
export type UpdateUserProgressMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserProgressMutation,
  UpdateUserProgressMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation UpdateUser($updates: UserUpdates!) {
    updateUser(updates: $updates) {
      success
      message
    }
  }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>
) {
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const DeleteMyUserDocument = gql`
  mutation DeleteMyUser {
    deleteMyUser {
      success
      message
    }
  }
`;
export type DeleteMyUserMutationFn = Apollo.MutationFunction<DeleteMyUserMutation, DeleteMyUserMutationVariables>;

/**
 * __useDeleteMyUserMutation__
 *
 * To run a mutation, you first call `useDeleteMyUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMyUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMyUserMutation, { data, loading, error }] = useDeleteMyUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteMyUserMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteMyUserMutation, DeleteMyUserMutationVariables>
) {
  return Apollo.useMutation<DeleteMyUserMutation, DeleteMyUserMutationVariables>(DeleteMyUserDocument, baseOptions);
}
export type DeleteMyUserMutationHookResult = ReturnType<typeof useDeleteMyUserMutation>;
export type DeleteMyUserMutationResult = Apollo.MutationResult<DeleteMyUserMutation>;
export type DeleteMyUserMutationOptions = Apollo.BaseMutationOptions<
  DeleteMyUserMutation,
  DeleteMyUserMutationVariables
>;
export const DeleteSubscriptionScheduleForCustomerDocument = gql`
  mutation DeleteSubscriptionScheduleForCustomer {
    deleteSubscriptionScheduleForCustomer {
      success
      message
    }
  }
`;
export type DeleteSubscriptionScheduleForCustomerMutationFn = Apollo.MutationFunction<
  DeleteSubscriptionScheduleForCustomerMutation,
  DeleteSubscriptionScheduleForCustomerMutationVariables
>;

/**
 * __useDeleteSubscriptionScheduleForCustomerMutation__
 *
 * To run a mutation, you first call `useDeleteSubscriptionScheduleForCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubscriptionScheduleForCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubscriptionScheduleForCustomerMutation, { data, loading, error }] = useDeleteSubscriptionScheduleForCustomerMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteSubscriptionScheduleForCustomerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSubscriptionScheduleForCustomerMutation,
    DeleteSubscriptionScheduleForCustomerMutationVariables
  >
) {
  return Apollo.useMutation<
    DeleteSubscriptionScheduleForCustomerMutation,
    DeleteSubscriptionScheduleForCustomerMutationVariables
  >(DeleteSubscriptionScheduleForCustomerDocument, baseOptions);
}
export type DeleteSubscriptionScheduleForCustomerMutationHookResult = ReturnType<
  typeof useDeleteSubscriptionScheduleForCustomerMutation
>;
export type DeleteSubscriptionScheduleForCustomerMutationResult =
  Apollo.MutationResult<DeleteSubscriptionScheduleForCustomerMutation>;
export type DeleteSubscriptionScheduleForCustomerMutationOptions = Apollo.BaseMutationOptions<
  DeleteSubscriptionScheduleForCustomerMutation,
  DeleteSubscriptionScheduleForCustomerMutationVariables
>;
export const SetPaymentMethodToDefaultDocument = gql`
  mutation SetPaymentMethodToDefault($id: String!) {
    setPaymentMethodToDefault(id: $id)
  }
`;
export type SetPaymentMethodToDefaultMutationFn = Apollo.MutationFunction<
  SetPaymentMethodToDefaultMutation,
  SetPaymentMethodToDefaultMutationVariables
>;

/**
 * __useSetPaymentMethodToDefaultMutation__
 *
 * To run a mutation, you first call `useSetPaymentMethodToDefaultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPaymentMethodToDefaultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPaymentMethodToDefaultMutation, { data, loading, error }] = useSetPaymentMethodToDefaultMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSetPaymentMethodToDefaultMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetPaymentMethodToDefaultMutation,
    SetPaymentMethodToDefaultMutationVariables
  >
) {
  return Apollo.useMutation<SetPaymentMethodToDefaultMutation, SetPaymentMethodToDefaultMutationVariables>(
    SetPaymentMethodToDefaultDocument,
    baseOptions
  );
}
export type SetPaymentMethodToDefaultMutationHookResult = ReturnType<typeof useSetPaymentMethodToDefaultMutation>;
export type SetPaymentMethodToDefaultMutationResult = Apollo.MutationResult<SetPaymentMethodToDefaultMutation>;
export type SetPaymentMethodToDefaultMutationOptions = Apollo.BaseMutationOptions<
  SetPaymentMethodToDefaultMutation,
  SetPaymentMethodToDefaultMutationVariables
>;
export const DeletePaymentMethodDocument = gql`
  mutation DeletePaymentMethod($id: String!) {
    deletePaymentMethod(id: $id)
  }
`;
export type DeletePaymentMethodMutationFn = Apollo.MutationFunction<
  DeletePaymentMethodMutation,
  DeletePaymentMethodMutationVariables
>;

/**
 * __useDeletePaymentMethodMutation__
 *
 * To run a mutation, you first call `useDeletePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePaymentMethodMutation, { data, loading, error }] = useDeletePaymentMethodMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<DeletePaymentMethodMutation, DeletePaymentMethodMutationVariables>
) {
  return Apollo.useMutation<DeletePaymentMethodMutation, DeletePaymentMethodMutationVariables>(
    DeletePaymentMethodDocument,
    baseOptions
  );
}
export type DeletePaymentMethodMutationHookResult = ReturnType<typeof useDeletePaymentMethodMutation>;
export type DeletePaymentMethodMutationResult = Apollo.MutationResult<DeletePaymentMethodMutation>;
export type DeletePaymentMethodMutationOptions = Apollo.BaseMutationOptions<
  DeletePaymentMethodMutation,
  DeletePaymentMethodMutationVariables
>;
export const UserLogoutDocument = gql`
  mutation UserLogout {
    logout {
      success
      message
    }
  }
`;
export type UserLogoutMutationFn = Apollo.MutationFunction<UserLogoutMutation, UserLogoutMutationVariables>;

/**
 * __useUserLogoutMutation__
 *
 * To run a mutation, you first call `useUserLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userLogoutMutation, { data, loading, error }] = useUserLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useUserLogoutMutation(
  baseOptions?: Apollo.MutationHookOptions<UserLogoutMutation, UserLogoutMutationVariables>
) {
  return Apollo.useMutation<UserLogoutMutation, UserLogoutMutationVariables>(UserLogoutDocument, baseOptions);
}
export type UserLogoutMutationHookResult = ReturnType<typeof useUserLogoutMutation>;
export type UserLogoutMutationResult = Apollo.MutationResult<UserLogoutMutation>;
export type UserLogoutMutationOptions = Apollo.BaseMutationOptions<UserLogoutMutation, UserLogoutMutationVariables>;
export const GetMyUserStripeChargesDocument = gql`
  query GetMyUserStripeCharges {
    myUser {
      _id
      email
      stripeCustomer {
        charges {
          id
          amount
          created
          receipt_url
          paid
        }
      }
    }
  }
`;

/**
 * __useGetMyUserStripeChargesQuery__
 *
 * To run a query within a React component, call `useGetMyUserStripeChargesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyUserStripeChargesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyUserStripeChargesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyUserStripeChargesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMyUserStripeChargesQuery, GetMyUserStripeChargesQueryVariables>
) {
  return Apollo.useQuery<GetMyUserStripeChargesQuery, GetMyUserStripeChargesQueryVariables>(
    GetMyUserStripeChargesDocument,
    baseOptions
  );
}
export function useGetMyUserStripeChargesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMyUserStripeChargesQuery, GetMyUserStripeChargesQueryVariables>
) {
  return Apollo.useLazyQuery<GetMyUserStripeChargesQuery, GetMyUserStripeChargesQueryVariables>(
    GetMyUserStripeChargesDocument,
    baseOptions
  );
}
export type GetMyUserStripeChargesQueryHookResult = ReturnType<typeof useGetMyUserStripeChargesQuery>;
export type GetMyUserStripeChargesLazyQueryHookResult = ReturnType<typeof useGetMyUserStripeChargesLazyQuery>;
export type GetMyUserStripeChargesQueryResult = Apollo.QueryResult<
  GetMyUserStripeChargesQuery,
  GetMyUserStripeChargesQueryVariables
>;
export const GetMyUserDocument = gql`
  query GetMyUser {
    myUser {
      _id
      type
      businessIdentifier
      companyName
      country
      email
      firstName
      lastName
      avatar
      isNotificationOn
      isFacebookVerified
      isYoutubeVerified
      doesRequireReauthentication
      activeSubscription
      marketingOptIn
      timezone
      walkthrough
      stripeAccountId
      stripeTaxRate
      stripeTaxRateDetails {
        id
        active
        country
        created
        description
        display_name
        inclusive
        jurisdiction
        livemode
        metadata
        percentage
        state
      }
      activeNotifications {
        isAssignNewDeliverables
        isMediaKitActivity
        isReportActivity
        isRememberDeliverables
        isSyncDeliverableToCalendar
      }
      billingAddress {
        streetAddress
        suburb
        state
        country
      }
      paymentDetails {
        currency
        accountNumber
        accountName
        taxAmount
        taxType
        bankCode1
        bankValue1
        bankCode2
        bankValue2
      }
    }
  }
`;

/**
 * __useGetMyUserQuery__
 *
 * To run a query within a React component, call `useGetMyUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyUserQuery(baseOptions?: Apollo.QueryHookOptions<GetMyUserQuery, GetMyUserQueryVariables>) {
  return Apollo.useQuery<GetMyUserQuery, GetMyUserQueryVariables>(GetMyUserDocument, baseOptions);
}
export function useGetMyUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMyUserQuery, GetMyUserQueryVariables>
) {
  return Apollo.useLazyQuery<GetMyUserQuery, GetMyUserQueryVariables>(GetMyUserDocument, baseOptions);
}
export type GetMyUserQueryHookResult = ReturnType<typeof useGetMyUserQuery>;
export type GetMyUserLazyQueryHookResult = ReturnType<typeof useGetMyUserLazyQuery>;
export type GetMyUserQueryResult = Apollo.QueryResult<GetMyUserQuery, GetMyUserQueryVariables>;
export const GetMyUserAwsCredentialsDocument = gql`
  query GetMyUserAWSCredentials {
    myUser {
      sqs_url
      awsTemporaryCredentials {
        AccessKeyId
        SecretAccessKey
        SessionToken
        Expiration
        region
        apiVersion
      }
    }
  }
`;

/**
 * __useGetMyUserAwsCredentialsQuery__
 *
 * To run a query within a React component, call `useGetMyUserAwsCredentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyUserAwsCredentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyUserAwsCredentialsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyUserAwsCredentialsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMyUserAwsCredentialsQuery, GetMyUserAwsCredentialsQueryVariables>
) {
  return Apollo.useQuery<GetMyUserAwsCredentialsQuery, GetMyUserAwsCredentialsQueryVariables>(
    GetMyUserAwsCredentialsDocument,
    baseOptions
  );
}
export function useGetMyUserAwsCredentialsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMyUserAwsCredentialsQuery, GetMyUserAwsCredentialsQueryVariables>
) {
  return Apollo.useLazyQuery<GetMyUserAwsCredentialsQuery, GetMyUserAwsCredentialsQueryVariables>(
    GetMyUserAwsCredentialsDocument,
    baseOptions
  );
}
export type GetMyUserAwsCredentialsQueryHookResult = ReturnType<typeof useGetMyUserAwsCredentialsQuery>;
export type GetMyUserAwsCredentialsLazyQueryHookResult = ReturnType<typeof useGetMyUserAwsCredentialsLazyQuery>;
export type GetMyUserAwsCredentialsQueryResult = Apollo.QueryResult<
  GetMyUserAwsCredentialsQuery,
  GetMyUserAwsCredentialsQueryVariables
>;
export const GetMyUserUsageDocument = gql`
  query GetMyUserUsage {
    myUser {
      _id
      type
      businessIdentifier
      companyName
      country
      email
      firstName
      lastName
      avatar
      isNotificationOn
      isFacebookVerified
      doesRequireReauthentication
      activeSubscription
      marketingOptIn
      timezone
      walkthrough
      signupPlanId
      paymentDetails {
        currency
      }
      reportsUsage {
        limit
        count
      }
      mediaKitsUsage {
        limit
        count
      }
      collaborationsUsage {
        limit
        count
      }
      invoicesUsage {
        limit
        count
      }
      profilesUsage {
        limit
        count
      }
    }
  }
`;

/**
 * __useGetMyUserUsageQuery__
 *
 * To run a query within a React component, call `useGetMyUserUsageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyUserUsageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyUserUsageQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyUserUsageQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMyUserUsageQuery, GetMyUserUsageQueryVariables>
) {
  return Apollo.useQuery<GetMyUserUsageQuery, GetMyUserUsageQueryVariables>(GetMyUserUsageDocument, baseOptions);
}
export function useGetMyUserUsageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMyUserUsageQuery, GetMyUserUsageQueryVariables>
) {
  return Apollo.useLazyQuery<GetMyUserUsageQuery, GetMyUserUsageQueryVariables>(GetMyUserUsageDocument, baseOptions);
}
export type GetMyUserUsageQueryHookResult = ReturnType<typeof useGetMyUserUsageQuery>;
export type GetMyUserUsageLazyQueryHookResult = ReturnType<typeof useGetMyUserUsageLazyQuery>;
export type GetMyUserUsageQueryResult = Apollo.QueryResult<GetMyUserUsageQuery, GetMyUserUsageQueryVariables>;
export const GetUserPaymentHistoryDocument = gql`
  query GetUserPaymentHistory {
    myUser {
      _id
      type
      businessIdentifier
      companyName
      country
      email
      firstName
      lastName
      avatar
      isNotificationOn
      isFacebookVerified
      doesRequireReauthentication
      activeSubscription
      marketingOptIn
      timezone
      walkthrough
      signupPlanId
      appSubscription {
        _id
        productId
        userId
        purchaseTime
        orderId
        invalidatedById
        created_at
        updated_at
      }
      influencerPlan {
        planId
        code
      }
      stripeCustomer {
        defaultPaymentMethod
        id
        default_source
        defaultPaymentMethod
        firstSubscription {
          id
          status
          current_period_end
          firstItem {
            price {
              id
            }
          }
        }
        activeSubscriptionSchedules {
          id
          current_phase {
            end_date
          }
        }
        cards {
          id
          card {
            brand
            country
            last4
            exp_month
            exp_year
          }
        }
      }
      braintreeSubscription {
        id
        status
        balance
        daysPastDue
        paidThroughDate
        planId
        price
        nextBillAmount
        nextBillingDate
        nextBillingPeriodAmount
        firstBillingDate
        paymentMethodToken
        transactions {
          id
          amount
          type
          refundedTransactionId
          taxAmount
          taxExempt
          createdAt
          paymentMethodType
          paymentMethodDescription
          paymentMethodImageUrl
        }
      }
    }
  }
`;

/**
 * __useGetUserPaymentHistoryQuery__
 *
 * To run a query within a React component, call `useGetUserPaymentHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPaymentHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPaymentHistoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserPaymentHistoryQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUserPaymentHistoryQuery, GetUserPaymentHistoryQueryVariables>
) {
  return Apollo.useQuery<GetUserPaymentHistoryQuery, GetUserPaymentHistoryQueryVariables>(
    GetUserPaymentHistoryDocument,
    baseOptions
  );
}
export function useGetUserPaymentHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserPaymentHistoryQuery, GetUserPaymentHistoryQueryVariables>
) {
  return Apollo.useLazyQuery<GetUserPaymentHistoryQuery, GetUserPaymentHistoryQueryVariables>(
    GetUserPaymentHistoryDocument,
    baseOptions
  );
}
export type GetUserPaymentHistoryQueryHookResult = ReturnType<typeof useGetUserPaymentHistoryQuery>;
export type GetUserPaymentHistoryLazyQueryHookResult = ReturnType<typeof useGetUserPaymentHistoryLazyQuery>;
export type GetUserPaymentHistoryQueryResult = Apollo.QueryResult<
  GetUserPaymentHistoryQuery,
  GetUserPaymentHistoryQueryVariables
>;
export const GetMyUserPaymentMethodsDocument = gql`
  query GetMyUserPaymentMethods {
    myUser {
      _id
      email
      braintreeCustomer {
        transactions {
          id
          paymentMethodType
          paymentMethodDescription
          amount
          createdAt
          status
        }
      }
    }
  }
`;

/**
 * __useGetMyUserPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useGetMyUserPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyUserPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyUserPaymentMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyUserPaymentMethodsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMyUserPaymentMethodsQuery, GetMyUserPaymentMethodsQueryVariables>
) {
  return Apollo.useQuery<GetMyUserPaymentMethodsQuery, GetMyUserPaymentMethodsQueryVariables>(
    GetMyUserPaymentMethodsDocument,
    baseOptions
  );
}
export function useGetMyUserPaymentMethodsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMyUserPaymentMethodsQuery, GetMyUserPaymentMethodsQueryVariables>
) {
  return Apollo.useLazyQuery<GetMyUserPaymentMethodsQuery, GetMyUserPaymentMethodsQueryVariables>(
    GetMyUserPaymentMethodsDocument,
    baseOptions
  );
}
export type GetMyUserPaymentMethodsQueryHookResult = ReturnType<typeof useGetMyUserPaymentMethodsQuery>;
export type GetMyUserPaymentMethodsLazyQueryHookResult = ReturnType<typeof useGetMyUserPaymentMethodsLazyQuery>;
export type GetMyUserPaymentMethodsQueryResult = Apollo.QueryResult<
  GetMyUserPaymentMethodsQuery,
  GetMyUserPaymentMethodsQueryVariables
>;
export const GetAmbassadorProfilesDocument = gql`
  query GetAmbassadorProfiles {
    getAmbassadorProfiles {
      _id
      id
      userId
      username
      profile_picture_url
      profileType
      displayName
      name
      timezone
      doesRequireTrackApproval
      cacheError
      followers_count
      isPendingConnection
      profileNote {
        _id
        notes
        categories
      }
      mediaStats {
        reach {
          median
        }
        impressions {
          average
        }
        engagement_percentageOfReach {
          median
        }
      }
      storyStats {
        reach {
          median
        }
      }
      channelStats {
        subscriberCount
      }
      videoStats {
        views {
          average
        }
        likes {
          average
        }
        dislikes {
          average
        }
        comments {
          average
        }
        shares {
          average
        }
        engagement_percentageOfViews {
          average
        }
      }
      pageStats {
        page_positive_feedback_by_type {
          answer
          claim
          comment
          like
          link
          other
          rsvp
        }
        page_negative_feedback_by_type {
          hide_clicks
          hide_all_clicks
          report_spam_clicks
          unlike_page_clicks
        }
      }
      postStats {
        post_reactions_like_total {
          actual
        }
        post_impressions {
          average
        }
      }
    }
  }
`;

/**
 * __useGetAmbassadorProfilesQuery__
 *
 * To run a query within a React component, call `useGetAmbassadorProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAmbassadorProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAmbassadorProfilesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAmbassadorProfilesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAmbassadorProfilesQuery, GetAmbassadorProfilesQueryVariables>
) {
  return Apollo.useQuery<GetAmbassadorProfilesQuery, GetAmbassadorProfilesQueryVariables>(
    GetAmbassadorProfilesDocument,
    baseOptions
  );
}
export function useGetAmbassadorProfilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAmbassadorProfilesQuery, GetAmbassadorProfilesQueryVariables>
) {
  return Apollo.useLazyQuery<GetAmbassadorProfilesQuery, GetAmbassadorProfilesQueryVariables>(
    GetAmbassadorProfilesDocument,
    baseOptions
  );
}
export type GetAmbassadorProfilesQueryHookResult = ReturnType<typeof useGetAmbassadorProfilesQuery>;
export type GetAmbassadorProfilesLazyQueryHookResult = ReturnType<typeof useGetAmbassadorProfilesLazyQuery>;
export type GetAmbassadorProfilesQueryResult = Apollo.QueryResult<
  GetAmbassadorProfilesQuery,
  GetAmbassadorProfilesQueryVariables
>;
export const GetAmbassadorMediaKitsDocument = gql`
  query GetAmbassadorMediaKits {
    getAmbassadorList {
      _id
      profileUser {
        firstName
        lastName
        country
        avatar
        doesRequireTrackApproval
      }
      profiles {
        _id
        id
        displayName
        profile_picture_url
        profileType
      }
      mediaKits {
        _id
        status
        title
      }
    }
  }
`;

/**
 * __useGetAmbassadorMediaKitsQuery__
 *
 * To run a query within a React component, call `useGetAmbassadorMediaKitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAmbassadorMediaKitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAmbassadorMediaKitsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAmbassadorMediaKitsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAmbassadorMediaKitsQuery, GetAmbassadorMediaKitsQueryVariables>
) {
  return Apollo.useQuery<GetAmbassadorMediaKitsQuery, GetAmbassadorMediaKitsQueryVariables>(
    GetAmbassadorMediaKitsDocument,
    baseOptions
  );
}
export function useGetAmbassadorMediaKitsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAmbassadorMediaKitsQuery, GetAmbassadorMediaKitsQueryVariables>
) {
  return Apollo.useLazyQuery<GetAmbassadorMediaKitsQuery, GetAmbassadorMediaKitsQueryVariables>(
    GetAmbassadorMediaKitsDocument,
    baseOptions
  );
}
export type GetAmbassadorMediaKitsQueryHookResult = ReturnType<typeof useGetAmbassadorMediaKitsQuery>;
export type GetAmbassadorMediaKitsLazyQueryHookResult = ReturnType<typeof useGetAmbassadorMediaKitsLazyQuery>;
export type GetAmbassadorMediaKitsQueryResult = Apollo.QueryResult<
  GetAmbassadorMediaKitsQuery,
  GetAmbassadorMediaKitsQueryVariables
>;
export const GetAmbassadorListDocument = gql`
  query GetAmbassadorList {
    getAmbassadorListByUserId {
      _id
      acceptedInvite
      ambassadorId
      created_at
      isArchive
      userId
      profiles {
        _id
        id
        displayName
      }
      brand {
        _id
        companyName
        firstName
        lastName
        avatar
        email
      }
      contact {
        _id
        name
        type
        companyName
        firstName
        lastName
        avatar
        email
        phone
        role
        created_at
        children {
          _id
          firstName
          lastName
          avatar
          email
          phone
          role
        }
      }
    }
  }
`;

/**
 * __useGetAmbassadorListQuery__
 *
 * To run a query within a React component, call `useGetAmbassadorListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAmbassadorListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAmbassadorListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAmbassadorListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAmbassadorListQuery, GetAmbassadorListQueryVariables>
) {
  return Apollo.useQuery<GetAmbassadorListQuery, GetAmbassadorListQueryVariables>(
    GetAmbassadorListDocument,
    baseOptions
  );
}
export function useGetAmbassadorListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAmbassadorListQuery, GetAmbassadorListQueryVariables>
) {
  return Apollo.useLazyQuery<GetAmbassadorListQuery, GetAmbassadorListQueryVariables>(
    GetAmbassadorListDocument,
    baseOptions
  );
}
export type GetAmbassadorListQueryHookResult = ReturnType<typeof useGetAmbassadorListQuery>;
export type GetAmbassadorListLazyQueryHookResult = ReturnType<typeof useGetAmbassadorListLazyQuery>;
export type GetAmbassadorListQueryResult = Apollo.QueryResult<GetAmbassadorListQuery, GetAmbassadorListQueryVariables>;
export const GetCollaborationListDocument = gql`
  query GetCollaborationList($id: ObjectId, $status: CollaborationStatus) {
    getCollaborationList(id: $id, status: $status) {
      _id
      name
      avatar
      status
      canEdit
      created_at
      brand {
        _id
        avatar
        companyName
      }
      fileUploads {
        link
        title
      }
      profileTags
      notes
      profileIds
      profiles {
        _id
        id
        displayName
      }
      deliverables {
        _id
        canEdit
        startDate
        startTime
        socialChannel
        socialDeliverable
        deliverableName
        discount
        price
        currency
        endDate
        endTime
        notes
        userId
        storyFrameCount
        trackingLinks {
          _id
          link
          actionType
          longUrl
          totalClicks
        }
        collaboration {
          _id
          name
          status
          canEdit
          brand {
            _id
            avatar
            companyName
          }
        }
        price
        discount
        profiles {
          _id
          id
          profile_picture_url
          displayName
          name
          user {
            _id
            avatar
          }
        }
        isViewed
        fileUploads {
          link
          title
        }
      }
    }
  }
`;

/**
 * __useGetCollaborationListQuery__
 *
 * To run a query within a React component, call `useGetCollaborationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollaborationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollaborationListQuery({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetCollaborationListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCollaborationListQuery, GetCollaborationListQueryVariables>
) {
  return Apollo.useQuery<GetCollaborationListQuery, GetCollaborationListQueryVariables>(
    GetCollaborationListDocument,
    baseOptions
  );
}
export function useGetCollaborationListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCollaborationListQuery, GetCollaborationListQueryVariables>
) {
  return Apollo.useLazyQuery<GetCollaborationListQuery, GetCollaborationListQueryVariables>(
    GetCollaborationListDocument,
    baseOptions
  );
}
export type GetCollaborationListQueryHookResult = ReturnType<typeof useGetCollaborationListQuery>;
export type GetCollaborationListLazyQueryHookResult = ReturnType<typeof useGetCollaborationListLazyQuery>;
export type GetCollaborationListQueryResult = Apollo.QueryResult<
  GetCollaborationListQuery,
  GetCollaborationListQueryVariables
>;
export const GetCollaborationListIdsDocument = gql`
  query GetCollaborationListIds {
    getCollaborationList {
      _id
    }
  }
`;

/**
 * __useGetCollaborationListIdsQuery__
 *
 * To run a query within a React component, call `useGetCollaborationListIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollaborationListIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollaborationListIdsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCollaborationListIdsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCollaborationListIdsQuery, GetCollaborationListIdsQueryVariables>
) {
  return Apollo.useQuery<GetCollaborationListIdsQuery, GetCollaborationListIdsQueryVariables>(
    GetCollaborationListIdsDocument,
    baseOptions
  );
}
export function useGetCollaborationListIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCollaborationListIdsQuery, GetCollaborationListIdsQueryVariables>
) {
  return Apollo.useLazyQuery<GetCollaborationListIdsQuery, GetCollaborationListIdsQueryVariables>(
    GetCollaborationListIdsDocument,
    baseOptions
  );
}
export type GetCollaborationListIdsQueryHookResult = ReturnType<typeof useGetCollaborationListIdsQuery>;
export type GetCollaborationListIdsLazyQueryHookResult = ReturnType<typeof useGetCollaborationListIdsLazyQuery>;
export type GetCollaborationListIdsQueryResult = Apollo.QueryResult<
  GetCollaborationListIdsQuery,
  GetCollaborationListIdsQueryVariables
>;
export const GetCollaborationTotalsDocument = gql`
  query GetCollaborationTotals($startDate: Date, $endDate: Date) {
    getCollaborationTotals(startDate: $startDate, endDate: $endDate) {
      totals {
        currency
        contactId
        totalPrice
      }
    }
  }
`;

/**
 * __useGetCollaborationTotalsQuery__
 *
 * To run a query within a React component, call `useGetCollaborationTotalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollaborationTotalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollaborationTotalsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetCollaborationTotalsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCollaborationTotalsQuery, GetCollaborationTotalsQueryVariables>
) {
  return Apollo.useQuery<GetCollaborationTotalsQuery, GetCollaborationTotalsQueryVariables>(
    GetCollaborationTotalsDocument,
    baseOptions
  );
}
export function useGetCollaborationTotalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCollaborationTotalsQuery, GetCollaborationTotalsQueryVariables>
) {
  return Apollo.useLazyQuery<GetCollaborationTotalsQuery, GetCollaborationTotalsQueryVariables>(
    GetCollaborationTotalsDocument,
    baseOptions
  );
}
export type GetCollaborationTotalsQueryHookResult = ReturnType<typeof useGetCollaborationTotalsQuery>;
export type GetCollaborationTotalsLazyQueryHookResult = ReturnType<typeof useGetCollaborationTotalsLazyQuery>;
export type GetCollaborationTotalsQueryResult = Apollo.QueryResult<
  GetCollaborationTotalsQuery,
  GetCollaborationTotalsQueryVariables
>;
export const GetBasicContactListDocument = gql`
  query GetBasicContactList {
    getContactList(type: COMPANY) {
      _id
      type
      avatar
      companyName
      email
      activity {
        itemId
        timestamp
        action
        type
      }
      children {
        _id
        avatar
        firstName
        lastName
        type
      }
    }
  }
`;

/**
 * __useGetBasicContactListQuery__
 *
 * To run a query within a React component, call `useGetBasicContactListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBasicContactListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBasicContactListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBasicContactListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetBasicContactListQuery, GetBasicContactListQueryVariables>
) {
  return Apollo.useQuery<GetBasicContactListQuery, GetBasicContactListQueryVariables>(
    GetBasicContactListDocument,
    baseOptions
  );
}
export function useGetBasicContactListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBasicContactListQuery, GetBasicContactListQueryVariables>
) {
  return Apollo.useLazyQuery<GetBasicContactListQuery, GetBasicContactListQueryVariables>(
    GetBasicContactListDocument,
    baseOptions
  );
}
export type GetBasicContactListQueryHookResult = ReturnType<typeof useGetBasicContactListQuery>;
export type GetBasicContactListLazyQueryHookResult = ReturnType<typeof useGetBasicContactListLazyQuery>;
export type GetBasicContactListQueryResult = Apollo.QueryResult<
  GetBasicContactListQuery,
  GetBasicContactListQueryVariables
>;
export const GetCompanyContactListDocument = gql`
  query GetCompanyContactList($id: ObjectId) {
    getContactList(type: COMPANY, id: $id) {
      _id
      parentId
      type
      avatar
      firstName
      lastName
      companyName
      email
      activity {
        itemId
        timestamp
        contact {
          avatar
          email
        }
        action
        type
      }
      collaborations {
        _id
        name
        avatar
        status
        brand {
          _id
          companyName
        }
        deliverables {
          _id
          collaboration {
            _id
          }
          profiles {
            _id
            id
            profile_picture_url
            username
            name
          }
          socialChannel
          notes
          socialDeliverable
          startDate
          startTime
          endDate
          endTime
        }
      }
      children {
        _id
        avatar
        firstName
        lastName
        email
        phone
        role
      }
    }
  }
`;

/**
 * __useGetCompanyContactListQuery__
 *
 * To run a query within a React component, call `useGetCompanyContactListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyContactListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyContactListQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyContactListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCompanyContactListQuery, GetCompanyContactListQueryVariables>
) {
  return Apollo.useQuery<GetCompanyContactListQuery, GetCompanyContactListQueryVariables>(
    GetCompanyContactListDocument,
    baseOptions
  );
}
export function useGetCompanyContactListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyContactListQuery, GetCompanyContactListQueryVariables>
) {
  return Apollo.useLazyQuery<GetCompanyContactListQuery, GetCompanyContactListQueryVariables>(
    GetCompanyContactListDocument,
    baseOptions
  );
}
export type GetCompanyContactListQueryHookResult = ReturnType<typeof useGetCompanyContactListQuery>;
export type GetCompanyContactListLazyQueryHookResult = ReturnType<typeof useGetCompanyContactListLazyQuery>;
export type GetCompanyContactListQueryResult = Apollo.QueryResult<
  GetCompanyContactListQuery,
  GetCompanyContactListQueryVariables
>;
export const GetContactsDocument = gql`
  query getContacts($id: ObjectId, $type: ContactType) {
    getContactList(id: $id, type: $type) {
      _id
      parentId
      parent {
        _id
        avatar
        companyName
      }
      name
      type
      companyName
      created_at
      firstName
      lastName
      avatar
      email
      phone
      role
      children {
        _id
        firstName
        lastName
        avatar
        email
        phone
        role
        type
      }
    }
    getAmbassadorListByUserId {
      _id
      acceptedInvite
      ambassadorId
      created_at
      isArchive
      userId
      status
      brand {
        _id
        companyName
        firstName
        lastName
        avatar
        email
      }
      contact {
        _id
        name
        type
        companyName
        firstName
        lastName
        avatar
        email
        phone
        role
        created_at
        children {
          _id
          firstName
          lastName
          avatar
          email
          phone
          role
          type
        }
      }
    }
  }
`;

/**
 * __useGetContactsQuery__
 *
 * To run a query within a React component, call `useGetContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetContactsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetContactsQuery, GetContactsQueryVariables>
) {
  return Apollo.useQuery<GetContactsQuery, GetContactsQueryVariables>(GetContactsDocument, baseOptions);
}
export function useGetContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetContactsQuery, GetContactsQueryVariables>
) {
  return Apollo.useLazyQuery<GetContactsQuery, GetContactsQueryVariables>(GetContactsDocument, baseOptions);
}
export type GetContactsQueryHookResult = ReturnType<typeof useGetContactsQuery>;
export type GetContactsLazyQueryHookResult = ReturnType<typeof useGetContactsLazyQuery>;
export type GetContactsQueryResult = Apollo.QueryResult<GetContactsQuery, GetContactsQueryVariables>;
export const GetCouponsDocument = gql`
  query GetCoupons {
    getCoupons {
      _id
      code
      notes
      planId
      expiresAt
    }
  }
`;

/**
 * __useGetCouponsQuery__
 *
 * To run a query within a React component, call `useGetCouponsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCouponsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCouponsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCouponsQuery(baseOptions?: Apollo.QueryHookOptions<GetCouponsQuery, GetCouponsQueryVariables>) {
  return Apollo.useQuery<GetCouponsQuery, GetCouponsQueryVariables>(GetCouponsDocument, baseOptions);
}
export function useGetCouponsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCouponsQuery, GetCouponsQueryVariables>
) {
  return Apollo.useLazyQuery<GetCouponsQuery, GetCouponsQueryVariables>(GetCouponsDocument, baseOptions);
}
export type GetCouponsQueryHookResult = ReturnType<typeof useGetCouponsQuery>;
export type GetCouponsLazyQueryHookResult = ReturnType<typeof useGetCouponsLazyQuery>;
export type GetCouponsQueryResult = Apollo.QueryResult<GetCouponsQuery, GetCouponsQueryVariables>;
export const GetInvoicesDocument = gql`
  query GetInvoices($id: ObjectId, $collaborationId: ObjectId) {
    getCollaborationInvoices(id: $id, collaborationId: $collaborationId) {
      _id
      name
      dueDate
      status
      invoiceId
      invoiceNumber
      invoiceToContactId
      collaborationId
      taxStatus
      taxType
      taxPercentage
      stripeInvoiceUrl
      stripeInvoice {
        id
        status_transitions {
          paid_at
        }
      }
      collaboration {
        _id
        name
        avatar
        brand {
          name
          email
          avatar
          firstName
          lastName
        }
      }
      deliverableIds
      deliverables {
        _id
        price
        socialChannel
        socialDeliverable
      }
      profiles {
        _id
        id
        displayName
        profileType
      }
      totalPrice
      created_at
      links {
        _id
        collaborationInvoiceId
        shortenedLink
        created_at
        contact {
          _id
          email
          avatar
          name
          firstName
          lastName
          companyName
          parent {
            name
            email
          }
        }
        contactDetails {
          email
          firstName
          lastName
          name
        }
      }
    }
  }
`;

/**
 * __useGetInvoicesQuery__
 *
 * To run a query within a React component, call `useGetInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      collaborationId: // value for 'collaborationId'
 *   },
 * });
 */
export function useGetInvoicesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetInvoicesQuery, GetInvoicesQueryVariables>
) {
  return Apollo.useQuery<GetInvoicesQuery, GetInvoicesQueryVariables>(GetInvoicesDocument, baseOptions);
}
export function useGetInvoicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetInvoicesQuery, GetInvoicesQueryVariables>
) {
  return Apollo.useLazyQuery<GetInvoicesQuery, GetInvoicesQueryVariables>(GetInvoicesDocument, baseOptions);
}
export type GetInvoicesQueryHookResult = ReturnType<typeof useGetInvoicesQuery>;
export type GetInvoicesLazyQueryHookResult = ReturnType<typeof useGetInvoicesLazyQuery>;
export type GetInvoicesQueryResult = Apollo.QueryResult<GetInvoicesQuery, GetInvoicesQueryVariables>;
export const GetCollaborationInvoiceTotalsDocument = gql`
  query GetCollaborationInvoiceTotals($startDate: Date, $endDate: Date) {
    getCollaborationInvoiceTotals(startDate: $startDate, endDate: $endDate) {
      totals {
        currency
        contactId
        contact {
          _id
          name
          avatar
        }
        all
        max
        paid
        due
        overdue
        allPercentage
        paidPercentage
        duePercentage
      }
      invoices {
        _id
        name
        dueDate
        status
        invoiceId
        invoiceNumber
        invoiceToContactId
        collaborationId
        taxStatus
        taxType
        taxPercentage
        stripeInvoiceUrl
        stripeInvoice {
          id
          status_transitions {
            paid_at
          }
        }
        collaboration {
          _id
          name
          avatar
          brand {
            _id
            name
            email
            avatar
            firstName
            lastName
          }
        }
        deliverableIds
        deliverables {
          _id
          price
          socialChannel
          socialDeliverable
        }
        profiles {
          _id
          id
          displayName
          profileType
        }
        totalPrice
        created_at
        links {
          _id
          collaborationInvoiceId
          shortenedLink
          created_at
          contact {
            _id
            email
            avatar
            name
            firstName
            lastName
            companyName
            parent {
              name
              email
            }
          }
          contactDetails {
            email
            firstName
            lastName
            name
          }
        }
      }
    }
  }
`;

/**
 * __useGetCollaborationInvoiceTotalsQuery__
 *
 * To run a query within a React component, call `useGetCollaborationInvoiceTotalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollaborationInvoiceTotalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollaborationInvoiceTotalsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetCollaborationInvoiceTotalsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCollaborationInvoiceTotalsQuery, GetCollaborationInvoiceTotalsQueryVariables>
) {
  return Apollo.useQuery<GetCollaborationInvoiceTotalsQuery, GetCollaborationInvoiceTotalsQueryVariables>(
    GetCollaborationInvoiceTotalsDocument,
    baseOptions
  );
}
export function useGetCollaborationInvoiceTotalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCollaborationInvoiceTotalsQuery,
    GetCollaborationInvoiceTotalsQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetCollaborationInvoiceTotalsQuery, GetCollaborationInvoiceTotalsQueryVariables>(
    GetCollaborationInvoiceTotalsDocument,
    baseOptions
  );
}
export type GetCollaborationInvoiceTotalsQueryHookResult = ReturnType<typeof useGetCollaborationInvoiceTotalsQuery>;
export type GetCollaborationInvoiceTotalsLazyQueryHookResult = ReturnType<
  typeof useGetCollaborationInvoiceTotalsLazyQuery
>;
export type GetCollaborationInvoiceTotalsQueryResult = Apollo.QueryResult<
  GetCollaborationInvoiceTotalsQuery,
  GetCollaborationInvoiceTotalsQueryVariables
>;
export const GetMediaKitConfigListDocument = gql`
  query GetMediaKitConfigList {
    getMediaKitConfigList {
      _id
      title
      status
      isEmpty
      headerImage
      owner {
        _id
        avatar
        firstName
        lastName
      }
      created_at
      updated_at
      socialChannels
      colorTheme
      colorHex
      backgroundColorTheme
      backgroundColorHex
      draft {
        title
        colorTheme
        colorHex
        backgroundColorTheme
        backgroundColorHex
      }
    }
  }
`;

/**
 * __useGetMediaKitConfigListQuery__
 *
 * To run a query within a React component, call `useGetMediaKitConfigListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaKitConfigListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaKitConfigListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMediaKitConfigListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMediaKitConfigListQuery, GetMediaKitConfigListQueryVariables>
) {
  return Apollo.useQuery<GetMediaKitConfigListQuery, GetMediaKitConfigListQueryVariables>(
    GetMediaKitConfigListDocument,
    baseOptions
  );
}
export function useGetMediaKitConfigListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMediaKitConfigListQuery, GetMediaKitConfigListQueryVariables>
) {
  return Apollo.useLazyQuery<GetMediaKitConfigListQuery, GetMediaKitConfigListQueryVariables>(
    GetMediaKitConfigListDocument,
    baseOptions
  );
}
export type GetMediaKitConfigListQueryHookResult = ReturnType<typeof useGetMediaKitConfigListQuery>;
export type GetMediaKitConfigListLazyQueryHookResult = ReturnType<typeof useGetMediaKitConfigListLazyQuery>;
export type GetMediaKitConfigListQueryResult = Apollo.QueryResult<
  GetMediaKitConfigListQuery,
  GetMediaKitConfigListQueryVariables
>;
export const GetMediaKitLinkListDocument = gql`
  query GetMediaKitLinkList {
    getMediaKitLinkList {
      _id
      mediaKitId
      isOpened
      isExpired
      openedAt
      title
      shortenedLink
      created_at
      name
      contact {
        avatar
        name
        companyName
        firstName
        lastName
      }
    }
  }
`;

/**
 * __useGetMediaKitLinkListQuery__
 *
 * To run a query within a React component, call `useGetMediaKitLinkListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaKitLinkListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaKitLinkListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMediaKitLinkListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMediaKitLinkListQuery, GetMediaKitLinkListQueryVariables>
) {
  return Apollo.useQuery<GetMediaKitLinkListQuery, GetMediaKitLinkListQueryVariables>(
    GetMediaKitLinkListDocument,
    baseOptions
  );
}
export function useGetMediaKitLinkListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMediaKitLinkListQuery, GetMediaKitLinkListQueryVariables>
) {
  return Apollo.useLazyQuery<GetMediaKitLinkListQuery, GetMediaKitLinkListQueryVariables>(
    GetMediaKitLinkListDocument,
    baseOptions
  );
}
export type GetMediaKitLinkListQueryHookResult = ReturnType<typeof useGetMediaKitLinkListQuery>;
export type GetMediaKitLinkListLazyQueryHookResult = ReturnType<typeof useGetMediaKitLinkListLazyQuery>;
export type GetMediaKitLinkListQueryResult = Apollo.QueryResult<
  GetMediaKitLinkListQuery,
  GetMediaKitLinkListQueryVariables
>;
export const GetMediaKitConfigDocument = gql`
  query GetMediaKitConfig($id: ObjectId!) {
    getMediaKitConfig(id: $id) {
      _id
      title
      headerImage
      status
      isEmpty
      colorTheme
      colorHex
      backgroundColorTheme
      backgroundColorHex
      created_at
      updated_at
      elements {
        _id
        title
        stringifiedContent
        draft {
          title
          stringifiedContent
        }
      }
      draft {
        title
        headerImage
        colorTheme
        colorHex
        backgroundColorTheme
        backgroundColorHex
        elements {
          _id
          title
          stringifiedContent
          draft {
            title
            stringifiedContent
          }
        }
      }
    }
  }
`;

/**
 * __useGetMediaKitConfigQuery__
 *
 * To run a query within a React component, call `useGetMediaKitConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaKitConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaKitConfigQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMediaKitConfigQuery(
  baseOptions: Apollo.QueryHookOptions<GetMediaKitConfigQuery, GetMediaKitConfigQueryVariables>
) {
  return Apollo.useQuery<GetMediaKitConfigQuery, GetMediaKitConfigQueryVariables>(
    GetMediaKitConfigDocument,
    baseOptions
  );
}
export function useGetMediaKitConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMediaKitConfigQuery, GetMediaKitConfigQueryVariables>
) {
  return Apollo.useLazyQuery<GetMediaKitConfigQuery, GetMediaKitConfigQueryVariables>(
    GetMediaKitConfigDocument,
    baseOptions
  );
}
export type GetMediaKitConfigQueryHookResult = ReturnType<typeof useGetMediaKitConfigQuery>;
export type GetMediaKitConfigLazyQueryHookResult = ReturnType<typeof useGetMediaKitConfigLazyQuery>;
export type GetMediaKitConfigQueryResult = Apollo.QueryResult<GetMediaKitConfigQuery, GetMediaKitConfigQueryVariables>;
export const GetNotificationDocument = gql`
  query GetNotification($recipientId: String!, $trigger: String) {
    getNotification(recipientId: $recipientId, trigger: $trigger) {
      _id
      text
      isRead
      recipient
    }
  }
`;

/**
 * __useGetNotificationQuery__
 *
 * To run a query within a React component, call `useGetNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationQuery({
 *   variables: {
 *      recipientId: // value for 'recipientId'
 *      trigger: // value for 'trigger'
 *   },
 * });
 */
export function useGetNotificationQuery(
  baseOptions: Apollo.QueryHookOptions<GetNotificationQuery, GetNotificationQueryVariables>
) {
  return Apollo.useQuery<GetNotificationQuery, GetNotificationQueryVariables>(GetNotificationDocument, baseOptions);
}
export function useGetNotificationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationQuery, GetNotificationQueryVariables>
) {
  return Apollo.useLazyQuery<GetNotificationQuery, GetNotificationQueryVariables>(GetNotificationDocument, baseOptions);
}
export type GetNotificationQueryHookResult = ReturnType<typeof useGetNotificationQuery>;
export type GetNotificationLazyQueryHookResult = ReturnType<typeof useGetNotificationLazyQuery>;
export type GetNotificationQueryResult = Apollo.QueryResult<GetNotificationQuery, GetNotificationQueryVariables>;
export const GetPlatformAdDocument = gql`
  query GetPlatformAd($params: ContentfulPlatformAdParams) {
    getPlatformAd(params: $params) {
      mediaUrl
      linkUrl
      altText
      id
      title
      width
      height
      freeUserAd
    }
  }
`;

/**
 * __useGetPlatformAdQuery__
 *
 * To run a query within a React component, call `useGetPlatformAdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlatformAdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlatformAdQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetPlatformAdQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPlatformAdQuery, GetPlatformAdQueryVariables>
) {
  return Apollo.useQuery<GetPlatformAdQuery, GetPlatformAdQueryVariables>(GetPlatformAdDocument, baseOptions);
}
export function useGetPlatformAdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPlatformAdQuery, GetPlatformAdQueryVariables>
) {
  return Apollo.useLazyQuery<GetPlatformAdQuery, GetPlatformAdQueryVariables>(GetPlatformAdDocument, baseOptions);
}
export type GetPlatformAdQueryHookResult = ReturnType<typeof useGetPlatformAdQuery>;
export type GetPlatformAdLazyQueryHookResult = ReturnType<typeof useGetPlatformAdLazyQuery>;
export type GetPlatformAdQueryResult = Apollo.QueryResult<GetPlatformAdQuery, GetPlatformAdQueryVariables>;
export const GetProfilesDocument = gql`
  query GetProfiles($profileType: String, $profileTypeList: [String]) {
    getProfiles(profileType: $profileType, profileTypeList: $profileTypeList) {
      _id
      id
      displayName
      username
      profileType
      followers_count
      fan_count
      profile_picture_url
      profile_category
      cache_completed_at
      profile_cached_at
      profileCategory
      snippet
      isRestricted
      cacheError
      pageStats {
        page_fans
      }
      channelStats {
        subscriberCount
      }
      thumbnails {
        default {
          url
        }
      }
      public {
        signature
        stats {
          followerCount
        }
      }
      tiktokMediaStats {
        viralVideoPercentage
        view_count {
          median
        }
        totalEngagement_percentageOfPlays {
          median
        }
      }
    }
  }
`;

/**
 * __useGetProfilesQuery__
 *
 * To run a query within a React component, call `useGetProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfilesQuery({
 *   variables: {
 *      profileType: // value for 'profileType'
 *      profileTypeList: // value for 'profileTypeList'
 *   },
 * });
 */
export function useGetProfilesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetProfilesQuery, GetProfilesQueryVariables>
) {
  return Apollo.useQuery<GetProfilesQuery, GetProfilesQueryVariables>(GetProfilesDocument, baseOptions);
}
export function useGetProfilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetProfilesQuery, GetProfilesQueryVariables>
) {
  return Apollo.useLazyQuery<GetProfilesQuery, GetProfilesQueryVariables>(GetProfilesDocument, baseOptions);
}
export type GetProfilesQueryHookResult = ReturnType<typeof useGetProfilesQuery>;
export type GetProfilesLazyQueryHookResult = ReturnType<typeof useGetProfilesLazyQuery>;
export type GetProfilesQueryResult = Apollo.QueryResult<GetProfilesQuery, GetProfilesQueryVariables>;
export const GetSingleProfileDocument = gql`
  query GetSingleProfile($_id: ObjectId, $id: String, $username: String) {
    getSingleProfile(_id: $_id, id: $id, username: $username) {
      _id
      id
      displayName
      user {
        _id
        firstName
        lastName
      }
      profileType
      profile_picture_url
      followers_count
      fan_count
      name
      title
      username
      biography
      instagramFeedStats {
        reach {
          median
          average
        }
        engagement_percentageOfReach {
          median
        }
      }
      instagramReelStats {
        reach {
          median
          average
        }
        total_interactions_percentageOfReach {
          median
        }
      }
      storyStats {
        reach {
          median
        }
      }
      pageStats {
        page_fans
        page_impressions
        page_positive_feedbackTotal
        page_negative_feedbackTotal
      }
      postStats {
        post_engaged_users_percentageOfReach {
          median
        }
        post_reactions_like_total {
          actual
        }
      }
      channelStats {
        subscriberCount
      }
      videoStats {
        views {
          average
        }
        engagement_percentageOfViews {
          average
        }
      }
      public {
        signature
        stats {
          followerCount
        }
      }
      tiktokMediaStats {
        viralVideoPercentage
        view_count {
          median
        }
        totalEngagement {
          median
        }
        totalEngagement_percentageOfPlays {
          median
        }
      }
      mediaKitConfigs {
        _id
        headerImage
        colorTheme
        title
        created_at
      }
      rankedInstagramMedia {
        topMedia {
          id
          _id
          media_url
          thumbnail_url
          profileId
        }
      }
      rankedTiktokMedia {
        topMedia {
          _id
          id
          profileId
          official {
            cover_image_url
            video_description
          }
        }
      }
    }
  }
`;

/**
 * __useGetSingleProfileQuery__
 *
 * To run a query within a React component, call `useGetSingleProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleProfileQuery({
 *   variables: {
 *      _id: // value for '_id'
 *      id: // value for 'id'
 *      username: // value for 'username'
 *   },
 * });
 */
export function useGetSingleProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSingleProfileQuery, GetSingleProfileQueryVariables>
) {
  return Apollo.useQuery<GetSingleProfileQuery, GetSingleProfileQueryVariables>(GetSingleProfileDocument, baseOptions);
}
export function useGetSingleProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSingleProfileQuery, GetSingleProfileQueryVariables>
) {
  return Apollo.useLazyQuery<GetSingleProfileQuery, GetSingleProfileQueryVariables>(
    GetSingleProfileDocument,
    baseOptions
  );
}
export type GetSingleProfileQueryHookResult = ReturnType<typeof useGetSingleProfileQuery>;
export type GetSingleProfileLazyQueryHookResult = ReturnType<typeof useGetSingleProfileLazyQuery>;
export type GetSingleProfileQueryResult = Apollo.QueryResult<GetSingleProfileQuery, GetSingleProfileQueryVariables>;
export const MyUserProfilesDocument = gql`
  query MyUserProfiles {
    myUser {
      _id
      firstName
      lastName
      avatar
      timezone
      country
      userAuditReportLink
      profiles {
        _id
        id
        name
        displayName
        biography
        profileType
        profile_picture_url
        followers_count
        fan_count
        isAddedByOwner
        profileAuditReportLink
        channelStats {
          subscriberCount
        }
        pageStats {
          page_fans
        }
        public {
          signature
          stats {
            followerCount
          }
        }
        tiktokMediaStats {
          viralVideoPercentage
          view_count {
            median
          }
          totalEngagement_percentageOfPlays {
            median
          }
        }
        latestPerformanceReport {
          _id
          isLimited
          performance {
            percentile
          }
        }
        isRestricted
        userId
        cache_completed_at
        created_at
        cacheError
      }
    }
  }
`;

/**
 * __useMyUserProfilesQuery__
 *
 * To run a query within a React component, call `useMyUserProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyUserProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyUserProfilesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyUserProfilesQuery(
  baseOptions?: Apollo.QueryHookOptions<MyUserProfilesQuery, MyUserProfilesQueryVariables>
) {
  return Apollo.useQuery<MyUserProfilesQuery, MyUserProfilesQueryVariables>(MyUserProfilesDocument, baseOptions);
}
export function useMyUserProfilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MyUserProfilesQuery, MyUserProfilesQueryVariables>
) {
  return Apollo.useLazyQuery<MyUserProfilesQuery, MyUserProfilesQueryVariables>(MyUserProfilesDocument, baseOptions);
}
export type MyUserProfilesQueryHookResult = ReturnType<typeof useMyUserProfilesQuery>;
export type MyUserProfilesLazyQueryHookResult = ReturnType<typeof useMyUserProfilesLazyQuery>;
export type MyUserProfilesQueryResult = Apollo.QueryResult<MyUserProfilesQuery, MyUserProfilesQueryVariables>;
export const GetProfilesListDocument = gql`
  query GetProfilesList {
    getProfiles {
      _id
      id
      displayName
      profile_picture_url
      name
      username
      profileType
      cache_completed_at
      followerCount
      followers_count
      fan_count
      profileCategory
      selectedMediaKitId
      mediaStats {
        reach {
          median
        }
        engagement_percentageOfReach {
          median
        }
      }
      storyStats {
        reach {
          median
        }
      }
      videoStats {
        views {
          median
        }
        estimatedMinutesWatched {
          median
        }
        averageViewDuration {
          median
        }
        engagement_percentageOfViews {
          median
        }
      }
      pageStats {
        page_fans
        page_impressions
        page_actions_post_reactionsTotal
        page_positive_feedbackTotal
        post_activity_unique
      }
      postStats {
        post_impressions_unique {
          median
        }
      }
      public {
        signature
        stats {
          followerCount
        }
      }
      tiktokMediaStats {
        viralVideoPercentage
        view_count {
          median
        }
        totalEngagement_percentageOfPlays {
          median
        }
      }
      profileNote {
        _id
        notes
        categories
        profile_category
        selectedMediaKitId
      }
      mediaKitConfigs {
        _id
        title
      }
    }
  }
`;

/**
 * __useGetProfilesListQuery__
 *
 * To run a query within a React component, call `useGetProfilesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfilesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfilesListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProfilesListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetProfilesListQuery, GetProfilesListQueryVariables>
) {
  return Apollo.useQuery<GetProfilesListQuery, GetProfilesListQueryVariables>(GetProfilesListDocument, baseOptions);
}
export function useGetProfilesListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetProfilesListQuery, GetProfilesListQueryVariables>
) {
  return Apollo.useLazyQuery<GetProfilesListQuery, GetProfilesListQueryVariables>(GetProfilesListDocument, baseOptions);
}
export type GetProfilesListQueryHookResult = ReturnType<typeof useGetProfilesListQuery>;
export type GetProfilesListLazyQueryHookResult = ReturnType<typeof useGetProfilesListLazyQuery>;
export type GetProfilesListQueryResult = Apollo.QueryResult<GetProfilesListQuery, GetProfilesListQueryVariables>;
export const GetFacebookPagesDocument = gql`
  query GetFacebookPages {
    getFacebookPages {
      id
      name
      picture {
        data {
          url
        }
      }
      instagram_business_account {
        id
        name
        username
        profile_picture_url
        followers_count
      }
    }
  }
`;

/**
 * __useGetFacebookPagesQuery__
 *
 * To run a query within a React component, call `useGetFacebookPagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFacebookPagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFacebookPagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFacebookPagesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetFacebookPagesQuery, GetFacebookPagesQueryVariables>
) {
  return Apollo.useQuery<GetFacebookPagesQuery, GetFacebookPagesQueryVariables>(GetFacebookPagesDocument, baseOptions);
}
export function useGetFacebookPagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFacebookPagesQuery, GetFacebookPagesQueryVariables>
) {
  return Apollo.useLazyQuery<GetFacebookPagesQuery, GetFacebookPagesQueryVariables>(
    GetFacebookPagesDocument,
    baseOptions
  );
}
export type GetFacebookPagesQueryHookResult = ReturnType<typeof useGetFacebookPagesQuery>;
export type GetFacebookPagesLazyQueryHookResult = ReturnType<typeof useGetFacebookPagesLazyQuery>;
export type GetFacebookPagesQueryResult = Apollo.QueryResult<GetFacebookPagesQuery, GetFacebookPagesQueryVariables>;
export const ListYoutubeProfilesDocument = gql`
  query ListYoutubeProfiles {
    listYoutubeProfiles {
      _id
      id
      isAddedByOwner
      displayName
      profileType
      profile_picture_url
      profile_category
      cache_completed_at
      profile_cached_at
      profileCategory
      snippet
      isRestricted
      cacheError
      channelStats {
        subscriberCount
      }
      thumbnails {
        default {
          url
        }
      }
    }
  }
`;

/**
 * __useListYoutubeProfilesQuery__
 *
 * To run a query within a React component, call `useListYoutubeProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListYoutubeProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListYoutubeProfilesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListYoutubeProfilesQuery(
  baseOptions?: Apollo.QueryHookOptions<ListYoutubeProfilesQuery, ListYoutubeProfilesQueryVariables>
) {
  return Apollo.useQuery<ListYoutubeProfilesQuery, ListYoutubeProfilesQueryVariables>(
    ListYoutubeProfilesDocument,
    baseOptions
  );
}
export function useListYoutubeProfilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListYoutubeProfilesQuery, ListYoutubeProfilesQueryVariables>
) {
  return Apollo.useLazyQuery<ListYoutubeProfilesQuery, ListYoutubeProfilesQueryVariables>(
    ListYoutubeProfilesDocument,
    baseOptions
  );
}
export type ListYoutubeProfilesQueryHookResult = ReturnType<typeof useListYoutubeProfilesQuery>;
export type ListYoutubeProfilesLazyQueryHookResult = ReturnType<typeof useListYoutubeProfilesLazyQuery>;
export type ListYoutubeProfilesQueryResult = Apollo.QueryResult<
  ListYoutubeProfilesQuery,
  ListYoutubeProfilesQueryVariables
>;
export const GetTiktokProfilesDocument = gql`
  query GetTiktokProfiles {
    getTiktokProfiles {
      _id
      id
      isAddedByOwner
      displayName
      profileType
      profile_picture_url
      profile_category
      cache_completed_at
      profile_cached_at
      profileCategory
      isRestricted
      cacheError
    }
  }
`;

/**
 * __useGetTiktokProfilesQuery__
 *
 * To run a query within a React component, call `useGetTiktokProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTiktokProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTiktokProfilesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTiktokProfilesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTiktokProfilesQuery, GetTiktokProfilesQueryVariables>
) {
  return Apollo.useQuery<GetTiktokProfilesQuery, GetTiktokProfilesQueryVariables>(
    GetTiktokProfilesDocument,
    baseOptions
  );
}
export function useGetTiktokProfilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTiktokProfilesQuery, GetTiktokProfilesQueryVariables>
) {
  return Apollo.useLazyQuery<GetTiktokProfilesQuery, GetTiktokProfilesQueryVariables>(
    GetTiktokProfilesDocument,
    baseOptions
  );
}
export type GetTiktokProfilesQueryHookResult = ReturnType<typeof useGetTiktokProfilesQuery>;
export type GetTiktokProfilesLazyQueryHookResult = ReturnType<typeof useGetTiktokProfilesLazyQuery>;
export type GetTiktokProfilesQueryResult = Apollo.QueryResult<GetTiktokProfilesQuery, GetTiktokProfilesQueryVariables>;
export const GetSingleProfileForInstaDocument = gql`
  query GetSingleProfileForInsta($id: ObjectId) {
    getSingleProfile(_id: $id) {
      _id
      id
      name
      displayName
      username
      profileType
      profile_picture_url
      biography
      followers_count
      timezone
      onlineFollowers
      userId
      lifetimeInsights {
        audience_city
        audience_country
        audience_age
        audience_gender {
          male {
            actual
          }
          female {
            actual
          }
          unspecified {
            actual
          }
        }
      }
      mediaStats {
        impressions {
          median
        }
        reach {
          median
        }
        engagement {
          median
        }
        engagement_percentageOfReach {
          median
        }
      }
      storyStats {
        impressions {
          median
        }
        reach {
          median
        }
        engagement {
          median
        }
        engagement_percentageOfReach {
          median
        }
      }
      historicalStats {
        count
        follower_count {
          actual
          average
        }
        follows_count {
          actual
          average
        }
        media_count {
          actual
          average
        }
        profileRequestCount {
          actual
          average
        }
        email_contacts {
          actual
          average
        }
        profileRequestCount {
          actual
          average
        }
        profile_views {
          actual
          average
        }
        get_directions_clicks {
          actual
          average
        }
        phone_call_clicks {
          actual
          average
        }
        text_message_clicks {
          actual
          average
        }
        website_clicks {
          actual
          average
        }
        reach {
          actual
          average
        }
        impressions {
          actual
          average
        }
      }
      latestInstagramPosts {
        _id
        id
        profileId
        media_url
        thumbnail_url
        media_type
        caption
        timestamp
        like_count
        isAd
        comments_count
        insights {
          reach
          impressions
          engagement
          saved
        }
        children {
          id
          media_url
          media_type
        }
      }
      latestInstagramStories {
        _id
        id
        profileId
        media_url
        media_type
        thumbnail_url
        caption
        timestamp
        isAd
        insights {
          reach
          impressions
          exits
          replies
          taps_forward
          taps_back
        }
      }
      weeklyInstagramStats {
        start
        follower_count
        followers_lost
        followers_net
        mediaStats {
          reach
          impressions
          engagement
          engagement_percentageOfReach
          viewRate
        }
        storyStats {
          reach
          impressions
          viewRate
        }
        change {
          followers_net
          mediaStats {
            reach
            engagement_percentageOfReach
          }
          storyStats {
            reach
            impressions
            viewRate
          }
        }
      }
      dailyInstagramStats {
        date
        followers_count
      }
      instagramFeedTrends {
        dailyAverages {
          date
          like_count
          insights {
            reach
            engagement
            engagement_percentageOfReach
          }
        }
        recentAverages {
          like_count
          insights {
            reach
            engagement
            engagement_percentageOfReach
          }
        }
        comparisonAverages {
          like_count
          insights {
            reach
            engagement
            engagement_percentageOfReach
          }
        }
      }
      instagramStoryTrends {
        dailyAverages {
          date
          insights {
            reach
            impressions
            taps_forward
            taps_back
            exits
            replies
          }
        }
        recentAverages {
          insights {
            reach
            impressions
            taps_forward
            taps_back
            exits
            replies
          }
        }
        comparisonAverages {
          insights {
            reach
            impressions
            taps_forward
            taps_back
            exits
            replies
          }
        }
      }
      rankedInstagramMedia {
        topMedia {
          id
          _id
          media_url
          thumbnail_url
          profileId
        }
      }
      rankedTiktokMedia {
        topMedia {
          _id
          id
          profileId
          official {
            cover_image_url
            video_description
          }
        }
      }
    }
  }
`;

/**
 * __useGetSingleProfileForInstaQuery__
 *
 * To run a query within a React component, call `useGetSingleProfileForInstaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleProfileForInstaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleProfileForInstaQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSingleProfileForInstaQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSingleProfileForInstaQuery, GetSingleProfileForInstaQueryVariables>
) {
  return Apollo.useQuery<GetSingleProfileForInstaQuery, GetSingleProfileForInstaQueryVariables>(
    GetSingleProfileForInstaDocument,
    baseOptions
  );
}
export function useGetSingleProfileForInstaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSingleProfileForInstaQuery, GetSingleProfileForInstaQueryVariables>
) {
  return Apollo.useLazyQuery<GetSingleProfileForInstaQuery, GetSingleProfileForInstaQueryVariables>(
    GetSingleProfileForInstaDocument,
    baseOptions
  );
}
export type GetSingleProfileForInstaQueryHookResult = ReturnType<typeof useGetSingleProfileForInstaQuery>;
export type GetSingleProfileForInstaLazyQueryHookResult = ReturnType<typeof useGetSingleProfileForInstaLazyQuery>;
export type GetSingleProfileForInstaQueryResult = Apollo.QueryResult<
  GetSingleProfileForInstaQuery,
  GetSingleProfileForInstaQueryVariables
>;
export const GetStoriesForDateDocument = gql`
  query GetStoriesForDate($profileId: ObjectId!, $date: Date!) {
    getStoriesForDate(profileId: $profileId, date: $date) {
      _id
      id
      profileId
      media_url
      media_type
      thumbnail_url
      caption
      timestamp
      isAd
      insights {
        reach
        impressions
        exits
        replies
        taps_forward
        taps_back
      }
    }
  }
`;

/**
 * __useGetStoriesForDateQuery__
 *
 * To run a query within a React component, call `useGetStoriesForDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoriesForDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoriesForDateQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetStoriesForDateQuery(
  baseOptions: Apollo.QueryHookOptions<GetStoriesForDateQuery, GetStoriesForDateQueryVariables>
) {
  return Apollo.useQuery<GetStoriesForDateQuery, GetStoriesForDateQueryVariables>(
    GetStoriesForDateDocument,
    baseOptions
  );
}
export function useGetStoriesForDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetStoriesForDateQuery, GetStoriesForDateQueryVariables>
) {
  return Apollo.useLazyQuery<GetStoriesForDateQuery, GetStoriesForDateQueryVariables>(
    GetStoriesForDateDocument,
    baseOptions
  );
}
export type GetStoriesForDateQueryHookResult = ReturnType<typeof useGetStoriesForDateQuery>;
export type GetStoriesForDateLazyQueryHookResult = ReturnType<typeof useGetStoriesForDateLazyQuery>;
export type GetStoriesForDateQueryResult = Apollo.QueryResult<GetStoriesForDateQuery, GetStoriesForDateQueryVariables>;
export const GetSingleProfileForFbDocument = gql`
  query GetSingleProfileForFB($id: ObjectId) {
    getSingleProfile(_id: $id) {
      _id
      id
      name
      displayName
      biography
      profileType
      profile_picture_url
      fan_count
      userId
      facebookPostTrends {
        dailyAverages {
          date
          insights {
            post_clicks
            post_reactionsTotal
            post_impressions_unique
            post_engaged_users
          }
        }
        recentAverages {
          insights {
            post_clicks
            post_reactionsTotal
            post_impressions_unique
            post_engaged_users
          }
        }
        comparisonAverages {
          insights {
            post_clicks
            post_reactionsTotal
            post_impressions_unique
            post_engaged_users
          }
        }
      }
      pageStats {
        page_impressions
        page_post_engagements
        page_total_actions
        page_video_views
        page_actions_post_reactions_anger_total
        page_actions_post_reactions_haha_total
        page_actions_post_reactions_like_total
        page_actions_post_reactions_love_total
        page_actions_post_reactions_sorry_total
        page_actions_post_reactions_wow_total
        page_actions_post_reactionsTotal
        page_call_phone_clicks_logged_in_unique
        page_content_activity
        page_content_activity_by_action_type_unique {
          other
        }
        page_get_directions_clicks_logged_in_unique
        page_places_checkin_mobile
        page_posts_impressions
        page_tab_clicksTotal
        page_views_total
        page_website_clicks_logged_in_unique
        page_positive_feedback_by_type {
          answer
          claim
          comment
          like
          link
          other
          rsvp
        }
        page_positive_feedbackTotal
        page_negative_feedback_by_type {
          hide_clicks
          hide_all_clicks
          report_spam_clicks
          unlike_page_clicks
        }
        page_negative_feedbackTotal
        page_fans
        page_fans_city
        page_fans_country
        page_fans_age
        page_fans_gender {
          male {
            actual
          }
          female {
            actual
          }
          unspecified {
            actual
          }
        }
        page_content_activity_by_gender_unique {
          male {
            actual
          }
          female {
            actual
          }
          unspecified {
            actual
          }
        }
        page_content_activity_by_age_unique
        page_content_activity_by_country_unique
        page_impressions_by_gender_unique {
          male {
            actual
          }
          female {
            actual
          }
          unspecified {
            actual
          }
        }
        page_impressions_by_age_unique
        page_impressions_by_country_unique
        page_cta_clicks_by_gender_logged_in_unique {
          male {
            actual
          }
          female {
            actual
          }
          unspecified {
            actual
          }
        }
        page_cta_clicks_by_age_logged_in_unique
        page_cta_clicks_by_country_logged_in_unique
        page_call_phone_clicks_by_gender_logged_in_unique {
          male {
            actual
          }
          female {
            actual
          }
          unspecified {
            actual
          }
        }
        page_call_phone_clicks_by_age_logged_in_unique
        page_call_phone_clicks_by_country_logged_in_unique
        page_get_directions_clicks_by_gender_logged_in_unique {
          male {
            actual
          }
          female {
            actual
          }
          unspecified {
            actual
          }
        }
        page_get_directions_clicks_by_age_logged_in_unique
        page_get_directions_clicks_by_country_logged_in_unique
        page_website_clicks_by_gender_logged_in_unique {
          male {
            actual
          }
          female {
            actual
          }
          unspecified {
            actual
          }
        }
        page_website_clicks_by_age_logged_in_unique
        page_website_clicks_by_country_logged_in_unique
        page_places_checkins_by_gender {
          male {
            actual
          }
          female {
            actual
          }
          unspecified {
            actual
          }
        }
        page_places_checkins_by_age
        page_places_checkins_by_country
      }
      postStats {
        count
        post_activity {
          median
        }
        post_activity_percentageOfReach {
          median
        }
        post_engaged_users {
          median
        }
        post_engaged_users_percentageOfReach {
          median
        }
        post_impressions_unique {
          median
        }
      }
      historicalFacebookStats {
        date
        page_fans
        page_video_views
        page_impressions
        page_post_engagements
        page_total_actions
      }
      weeklyFacebookStats {
        date
        page_fan_adds_unique
        page_fan_removes_unique
        page_fans_netChange
        page_impressions
        page_post_engagements
        page_total_actions
        page_video_views
        page_actions_post_reactionsTotal
        page_fans_bySource {
          ads {
            adds
            removes
            netChange
          }
          feed {
            adds
            removes
            netChange
          }
          page_suggestions {
            adds
            removes
            netChange
          }
          deactivated_accounts {
            adds
            removes
            netChange
          }
          search {
            adds
            removes
            netChange
          }
          other {
            adds
            removes
            netChange
          }
        }
      }
      latestFacebookPosts {
        _id
        id
        pageId
        created_time
        timestamp
        full_picture
        story
        message
        permalink_url
        insights {
          post_clicks
          post_engaged_fan
          post_engaged_users
          post_impressions
          post_activity
        }
      }
    }
  }
`;

/**
 * __useGetSingleProfileForFbQuery__
 *
 * To run a query within a React component, call `useGetSingleProfileForFbQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleProfileForFbQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleProfileForFbQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSingleProfileForFbQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSingleProfileForFbQuery, GetSingleProfileForFbQueryVariables>
) {
  return Apollo.useQuery<GetSingleProfileForFbQuery, GetSingleProfileForFbQueryVariables>(
    GetSingleProfileForFbDocument,
    baseOptions
  );
}
export function useGetSingleProfileForFbLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSingleProfileForFbQuery, GetSingleProfileForFbQueryVariables>
) {
  return Apollo.useLazyQuery<GetSingleProfileForFbQuery, GetSingleProfileForFbQueryVariables>(
    GetSingleProfileForFbDocument,
    baseOptions
  );
}
export type GetSingleProfileForFbQueryHookResult = ReturnType<typeof useGetSingleProfileForFbQuery>;
export type GetSingleProfileForFbLazyQueryHookResult = ReturnType<typeof useGetSingleProfileForFbLazyQuery>;
export type GetSingleProfileForFbQueryResult = Apollo.QueryResult<
  GetSingleProfileForFbQuery,
  GetSingleProfileForFbQueryVariables
>;
export const GetSingleProfileForYtDocument = gql`
  query GetSingleProfileForYT($id: String!) {
    recentYoutubeMedia(id: $id) {
      mediaCount
      youtubeMedia {
        title
        date
        timestamp
        publishedAt
        description
        video
        thumbnails {
          default {
            url
          }
          standard {
            url
          }
          medium {
            url
          }
          high {
            url
          }
          maxres {
            url
          }
        }
        views
        comments
        likes
        dislikes
        estimatedMinutesWatched
        averageViewDuration
        annotationImpressions
        annotationClickableImpressions
        annotationCloses
        annotationCloseRate
        annotationClicks
        annotationClickThroughRate
        cardImpressions
        cardClicks
        cardClickRate
      }
      youtubeMediaAverages {
        views {
          median
        }
        comments {
          median
        }
        likes {
          median
        }
        dislikes {
          median
        }
        videosAddedToPlaylists {
          median
        }
        videosRemovedFromPlaylists {
          median
        }
        shares {
          median
        }
        estimatedMinutesWatched {
          median
        }
        estimatedRedMinutesWatched {
          median
        }
        averageViewDuration {
          median
        }
        annotationClickThroughRate {
          median
        }
        annotationCloseRate {
          median
        }
        annotationImpressions {
          median
        }
        annotationClickableImpressions {
          median
        }
        annotationClosableImpressions {
          median
        }
        annotationClicks {
          median
        }
        annotationCloses {
          median
        }
        cardClickRate {
          median
        }
        cardTeaserClickRate {
          median
        }
        cardImpressions {
          median
        }
        cardTeaserImpressions {
          median
        }
        cardClicks {
          median
        }
        cardTeaserClicks {
          median
        }
        netSubscribers {
          actual
        }
        subscribersGained {
          median
        }
        subscribersLost {
          median
        }
      }
    }
    getSingleProfile(id: $id) {
      _id
      id
      title
      displayName
      description
      userId
      profileType
      profile_picture_url
      country
      ageGroupBreakdown {
        ageGroup
        viewerPercentage
      }
      genderBreakdown {
        gender
        viewerPercentage
      }
      locationBreakdown {
        country
        views
      }
      myChannelReport {
        day
        views
        averageViewDuration
        averageViewPercentage
        estimatedMinutesWatched
        subscribersGained
        annotationClickThroughRate
      }
      subscriberChange {
        day
        subscribersGained
        subscribersLost
      }
      youtubeVideoTrends {
        dailyAverages {
          date
          views
          redViews
          averageViewDuration
          averageViewPercentage
          estimatedMinutesWatched
          estimatedRedMinutesWatched
          estimatedTotalMinutesWatched
          subscribersGained
          subscribersLost
        }
        recentAverages {
          views
          redViews
          averageViewDuration
          averageViewPercentage
          estimatedMinutesWatched
          estimatedRedMinutesWatched
          estimatedTotalMinutesWatched
        }
        comparisonAverages {
          views
          redViews
          averageViewDuration
          averageViewPercentage
          estimatedMinutesWatched
          estimatedRedMinutesWatched
          estimatedTotalMinutesWatched
        }
      }
      channelStats {
        viewCount
        commentCount
        subscriberCount
        hiddenSubscriberCount
        videoCount
      }
    }
  }
`;

/**
 * __useGetSingleProfileForYtQuery__
 *
 * To run a query within a React component, call `useGetSingleProfileForYtQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleProfileForYtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleProfileForYtQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSingleProfileForYtQuery(
  baseOptions: Apollo.QueryHookOptions<GetSingleProfileForYtQuery, GetSingleProfileForYtQueryVariables>
) {
  return Apollo.useQuery<GetSingleProfileForYtQuery, GetSingleProfileForYtQueryVariables>(
    GetSingleProfileForYtDocument,
    baseOptions
  );
}
export function useGetSingleProfileForYtLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSingleProfileForYtQuery, GetSingleProfileForYtQueryVariables>
) {
  return Apollo.useLazyQuery<GetSingleProfileForYtQuery, GetSingleProfileForYtQueryVariables>(
    GetSingleProfileForYtDocument,
    baseOptions
  );
}
export type GetSingleProfileForYtQueryHookResult = ReturnType<typeof useGetSingleProfileForYtQuery>;
export type GetSingleProfileForYtLazyQueryHookResult = ReturnType<typeof useGetSingleProfileForYtLazyQuery>;
export type GetSingleProfileForYtQueryResult = Apollo.QueryResult<
  GetSingleProfileForYtQuery,
  GetSingleProfileForYtQueryVariables
>;
export const GetInstagramMediaDocument = gql`
  query GetInstagramMedia($id: String, $_id: ObjectId) {
    getSingleProfile(id: $id, _id: $_id) {
      _id
      id
      name
      displayName
      username
      profileType
      profile_picture_url
      biography
      followers_count
      timezone
      onlineFollowers
      userId
      latestInstagramPosts {
        _id
        id
        profileId
        media_url
        thumbnail_url
        media_type
        caption
        timestamp
        like_count
        isAd
        comments_count
        media_product_type
        insights {
          reach
          impressions
          engagement
          saved
        }
        children {
          id
          media_url
          media_type
        }
      }
    }
  }
`;

/**
 * __useGetInstagramMediaQuery__
 *
 * To run a query within a React component, call `useGetInstagramMediaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstagramMediaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstagramMediaQuery({
 *   variables: {
 *      id: // value for 'id'
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useGetInstagramMediaQuery(
  baseOptions?: Apollo.QueryHookOptions<GetInstagramMediaQuery, GetInstagramMediaQueryVariables>
) {
  return Apollo.useQuery<GetInstagramMediaQuery, GetInstagramMediaQueryVariables>(
    GetInstagramMediaDocument,
    baseOptions
  );
}
export function useGetInstagramMediaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetInstagramMediaQuery, GetInstagramMediaQueryVariables>
) {
  return Apollo.useLazyQuery<GetInstagramMediaQuery, GetInstagramMediaQueryVariables>(
    GetInstagramMediaDocument,
    baseOptions
  );
}
export type GetInstagramMediaQueryHookResult = ReturnType<typeof useGetInstagramMediaQuery>;
export type GetInstagramMediaLazyQueryHookResult = ReturnType<typeof useGetInstagramMediaLazyQuery>;
export type GetInstagramMediaQueryResult = Apollo.QueryResult<GetInstagramMediaQuery, GetInstagramMediaQueryVariables>;
export const GetReportConfigListDocument = gql`
  query GetReportConfigList($id: ObjectId) {
    getReportConfigList(id: $id) {
      _id
      reportName
      profileIds
      brandHandle
      handles
      reportStart
      reportEnd
      mentions
      hashtags
      selected_media
      selected_stories
      created_at
      updated_at
      client {
        avatar
        name
        parent {
          avatar
          name
        }
      }
      reportLinks {
        reportConfigId
        isOpened
        isExpired
        name
        email
        contact {
          avatar
          name
          email
          parent {
            avatar
            name
          }
        }
      }
    }
  }
`;

/**
 * __useGetReportConfigListQuery__
 *
 * To run a query within a React component, call `useGetReportConfigListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportConfigListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportConfigListQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetReportConfigListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetReportConfigListQuery, GetReportConfigListQueryVariables>
) {
  return Apollo.useQuery<GetReportConfigListQuery, GetReportConfigListQueryVariables>(
    GetReportConfigListDocument,
    baseOptions
  );
}
export function useGetReportConfigListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetReportConfigListQuery, GetReportConfigListQueryVariables>
) {
  return Apollo.useLazyQuery<GetReportConfigListQuery, GetReportConfigListQueryVariables>(
    GetReportConfigListDocument,
    baseOptions
  );
}
export type GetReportConfigListQueryHookResult = ReturnType<typeof useGetReportConfigListQuery>;
export type GetReportConfigListLazyQueryHookResult = ReturnType<typeof useGetReportConfigListLazyQuery>;
export type GetReportConfigListQueryResult = Apollo.QueryResult<
  GetReportConfigListQuery,
  GetReportConfigListQueryVariables
>;
export const GetReportConfigListActivityDocument = gql`
  query GetReportConfigListActivity($id: ObjectId) {
    getReportConfigList(id: $id) {
      _id
      reportName
      reportStart
      reportEnd
      channels
      profileIds
      brand_handle
      handles
      mentions
      hashtags
      collaborationId
      clientId
      selected_media
      selected_stories
      selected_videos
      selected_posts
      created_at
      reportLinks {
        reportConfigId
        isOpened
        isExpired
        name
        email
        contact {
          avatar
          name
          email
          parent {
            avatar
            name
          }
        }
      }
    }
  }
`;

/**
 * __useGetReportConfigListActivityQuery__
 *
 * To run a query within a React component, call `useGetReportConfigListActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportConfigListActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportConfigListActivityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetReportConfigListActivityQuery(
  baseOptions?: Apollo.QueryHookOptions<GetReportConfigListActivityQuery, GetReportConfigListActivityQueryVariables>
) {
  return Apollo.useQuery<GetReportConfigListActivityQuery, GetReportConfigListActivityQueryVariables>(
    GetReportConfigListActivityDocument,
    baseOptions
  );
}
export function useGetReportConfigListActivityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetReportConfigListActivityQuery, GetReportConfigListActivityQueryVariables>
) {
  return Apollo.useLazyQuery<GetReportConfigListActivityQuery, GetReportConfigListActivityQueryVariables>(
    GetReportConfigListActivityDocument,
    baseOptions
  );
}
export type GetReportConfigListActivityQueryHookResult = ReturnType<typeof useGetReportConfigListActivityQuery>;
export type GetReportConfigListActivityLazyQueryHookResult = ReturnType<typeof useGetReportConfigListActivityLazyQuery>;
export type GetReportConfigListActivityQueryResult = Apollo.QueryResult<
  GetReportConfigListActivityQuery,
  GetReportConfigListActivityQueryVariables
>;
export const CreateReportConfigDocument = gql`
  mutation CreateReportConfig($config: ReportConfigInput!, $supplementMedia: [SupplementMediaInput]) {
    createReportConfig(config: $config, supplementMedia: $supplementMedia) {
      _id
    }
  }
`;
export type CreateReportConfigMutationFn = Apollo.MutationFunction<
  CreateReportConfigMutation,
  CreateReportConfigMutationVariables
>;

/**
 * __useCreateReportConfigMutation__
 *
 * To run a mutation, you first call `useCreateReportConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReportConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReportConfigMutation, { data, loading, error }] = useCreateReportConfigMutation({
 *   variables: {
 *      config: // value for 'config'
 *      supplementMedia: // value for 'supplementMedia'
 *   },
 * });
 */
export function useCreateReportConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateReportConfigMutation, CreateReportConfigMutationVariables>
) {
  return Apollo.useMutation<CreateReportConfigMutation, CreateReportConfigMutationVariables>(
    CreateReportConfigDocument,
    baseOptions
  );
}
export type CreateReportConfigMutationHookResult = ReturnType<typeof useCreateReportConfigMutation>;
export type CreateReportConfigMutationResult = Apollo.MutationResult<CreateReportConfigMutation>;
export type CreateReportConfigMutationOptions = Apollo.BaseMutationOptions<
  CreateReportConfigMutation,
  CreateReportConfigMutationVariables
>;
export const UpdateReportConfigDocument = gql`
  mutation UpdateReportConfig($reportId: ObjectId!, $config: ReportConfigInput!) {
    updateReportConfig(reportId: $reportId, config: $config) {
      success
      message
    }
  }
`;
export type UpdateReportConfigMutationFn = Apollo.MutationFunction<
  UpdateReportConfigMutation,
  UpdateReportConfigMutationVariables
>;

/**
 * __useUpdateReportConfigMutation__
 *
 * To run a mutation, you first call `useUpdateReportConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReportConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReportConfigMutation, { data, loading, error }] = useUpdateReportConfigMutation({
 *   variables: {
 *      reportId: // value for 'reportId'
 *      config: // value for 'config'
 *   },
 * });
 */
export function useUpdateReportConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateReportConfigMutation, UpdateReportConfigMutationVariables>
) {
  return Apollo.useMutation<UpdateReportConfigMutation, UpdateReportConfigMutationVariables>(
    UpdateReportConfigDocument,
    baseOptions
  );
}
export type UpdateReportConfigMutationHookResult = ReturnType<typeof useUpdateReportConfigMutation>;
export type UpdateReportConfigMutationResult = Apollo.MutationResult<UpdateReportConfigMutation>;
export type UpdateReportConfigMutationOptions = Apollo.BaseMutationOptions<
  UpdateReportConfigMutation,
  UpdateReportConfigMutationVariables
>;
export const ShareReportToContactDocument = gql`
  mutation ShareReportToContact(
    $reportConfigId: ObjectId!
    $contactIds: [ObjectId]
    $contactDetails: ReportShareContact
  ) {
    shareReportToContact(reportConfigId: $reportConfigId, contactIds: $contactIds, contactDetails: $contactDetails) {
      success
      message
    }
  }
`;
export type ShareReportToContactMutationFn = Apollo.MutationFunction<
  ShareReportToContactMutation,
  ShareReportToContactMutationVariables
>;

/**
 * __useShareReportToContactMutation__
 *
 * To run a mutation, you first call `useShareReportToContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareReportToContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareReportToContactMutation, { data, loading, error }] = useShareReportToContactMutation({
 *   variables: {
 *      reportConfigId: // value for 'reportConfigId'
 *      contactIds: // value for 'contactIds'
 *      contactDetails: // value for 'contactDetails'
 *   },
 * });
 */
export function useShareReportToContactMutation(
  baseOptions?: Apollo.MutationHookOptions<ShareReportToContactMutation, ShareReportToContactMutationVariables>
) {
  return Apollo.useMutation<ShareReportToContactMutation, ShareReportToContactMutationVariables>(
    ShareReportToContactDocument,
    baseOptions
  );
}
export type ShareReportToContactMutationHookResult = ReturnType<typeof useShareReportToContactMutation>;
export type ShareReportToContactMutationResult = Apollo.MutationResult<ShareReportToContactMutation>;
export type ShareReportToContactMutationOptions = Apollo.BaseMutationOptions<
  ShareReportToContactMutation,
  ShareReportToContactMutationVariables
>;
export const GetReportListDocument = gql`
  query GetReportList($id: ObjectId) {
    getReportConfigList(id: $id) {
      _id
      reportName
      reportStart
      reportEnd
      channels
      profileIds
      brand_handle
      handles
      mentions
      hashtags
      collaborationId
      clientId
      created_at
      selected_media
      selected_stories
      selected_videos
      selected_posts
      created_at
    }
  }
`;

/**
 * __useGetReportListQuery__
 *
 * To run a query within a React component, call `useGetReportListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportListQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetReportListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetReportListQuery, GetReportListQueryVariables>
) {
  return Apollo.useQuery<GetReportListQuery, GetReportListQueryVariables>(GetReportListDocument, baseOptions);
}
export function useGetReportListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetReportListQuery, GetReportListQueryVariables>
) {
  return Apollo.useLazyQuery<GetReportListQuery, GetReportListQueryVariables>(GetReportListDocument, baseOptions);
}
export type GetReportListQueryHookResult = ReturnType<typeof useGetReportListQuery>;
export type GetReportListLazyQueryHookResult = ReturnType<typeof useGetReportListLazyQuery>;
export type GetReportListQueryResult = Apollo.QueryResult<GetReportListQuery, GetReportListQueryVariables>;
export const GetContactAndInvitationListDocument = gql`
  query GetContactAndInvitationList($id: ObjectId, $type: ContactType) {
    getContactList(id: $id, type: $type) {
      _id
      parentId
      name
      type
      companyName
      created_at
      firstName
      lastName
      avatar
      email
      phone
      role
      children {
        _id
        firstName
        lastName
        avatar
        email
        phone
        role
        type
      }
    }
    getAmbassadorListByUserId {
      _id
      acceptedInvite
      ambassadorId
      created_at
      isArchive
      userId
      brand {
        _id
        companyName
        firstName
        lastName
        avatar
        email
      }
      contact {
        _id
        name
        type
        companyName
        firstName
        lastName
        avatar
        email
        phone
        role
        created_at
        children {
          _id
          firstName
          lastName
          avatar
          email
          phone
          role
          type
        }
      }
    }
  }
`;

/**
 * __useGetContactAndInvitationListQuery__
 *
 * To run a query within a React component, call `useGetContactAndInvitationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactAndInvitationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactAndInvitationListQuery({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetContactAndInvitationListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetContactAndInvitationListQuery, GetContactAndInvitationListQueryVariables>
) {
  return Apollo.useQuery<GetContactAndInvitationListQuery, GetContactAndInvitationListQueryVariables>(
    GetContactAndInvitationListDocument,
    baseOptions
  );
}
export function useGetContactAndInvitationListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetContactAndInvitationListQuery, GetContactAndInvitationListQueryVariables>
) {
  return Apollo.useLazyQuery<GetContactAndInvitationListQuery, GetContactAndInvitationListQueryVariables>(
    GetContactAndInvitationListDocument,
    baseOptions
  );
}
export type GetContactAndInvitationListQueryHookResult = ReturnType<typeof useGetContactAndInvitationListQuery>;
export type GetContactAndInvitationListLazyQueryHookResult = ReturnType<typeof useGetContactAndInvitationListLazyQuery>;
export type GetContactAndInvitationListQueryResult = Apollo.QueryResult<
  GetContactAndInvitationListQuery,
  GetContactAndInvitationListQueryVariables
>;
export const GetConnectedAccountLinkDocument = gql`
  query GetConnectedAccountLink($returnPath: String) {
    getConnectedAccountLink(returnPath: $returnPath) {
      object
      created
      expires_at
      url
    }
  }
`;

/**
 * __useGetConnectedAccountLinkQuery__
 *
 * To run a query within a React component, call `useGetConnectedAccountLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConnectedAccountLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConnectedAccountLinkQuery({
 *   variables: {
 *      returnPath: // value for 'returnPath'
 *   },
 * });
 */
export function useGetConnectedAccountLinkQuery(
  baseOptions?: Apollo.QueryHookOptions<GetConnectedAccountLinkQuery, GetConnectedAccountLinkQueryVariables>
) {
  return Apollo.useQuery<GetConnectedAccountLinkQuery, GetConnectedAccountLinkQueryVariables>(
    GetConnectedAccountLinkDocument,
    baseOptions
  );
}
export function useGetConnectedAccountLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetConnectedAccountLinkQuery, GetConnectedAccountLinkQueryVariables>
) {
  return Apollo.useLazyQuery<GetConnectedAccountLinkQuery, GetConnectedAccountLinkQueryVariables>(
    GetConnectedAccountLinkDocument,
    baseOptions
  );
}
export type GetConnectedAccountLinkQueryHookResult = ReturnType<typeof useGetConnectedAccountLinkQuery>;
export type GetConnectedAccountLinkLazyQueryHookResult = ReturnType<typeof useGetConnectedAccountLinkLazyQuery>;
export type GetConnectedAccountLinkQueryResult = Apollo.QueryResult<
  GetConnectedAccountLinkQuery,
  GetConnectedAccountLinkQueryVariables
>;
export const GetConnectedAccountDocument = gql`
  query GetConnectedAccount {
    getConnectedAccount {
      id
      details_submitted
      email
      expressDashboardLink
      type
    }
  }
`;

/**
 * __useGetConnectedAccountQuery__
 *
 * To run a query within a React component, call `useGetConnectedAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConnectedAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConnectedAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetConnectedAccountQuery(
  baseOptions?: Apollo.QueryHookOptions<GetConnectedAccountQuery, GetConnectedAccountQueryVariables>
) {
  return Apollo.useQuery<GetConnectedAccountQuery, GetConnectedAccountQueryVariables>(
    GetConnectedAccountDocument,
    baseOptions
  );
}
export function useGetConnectedAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetConnectedAccountQuery, GetConnectedAccountQueryVariables>
) {
  return Apollo.useLazyQuery<GetConnectedAccountQuery, GetConnectedAccountQueryVariables>(
    GetConnectedAccountDocument,
    baseOptions
  );
}
export type GetConnectedAccountQueryHookResult = ReturnType<typeof useGetConnectedAccountQuery>;
export type GetConnectedAccountLazyQueryHookResult = ReturnType<typeof useGetConnectedAccountLazyQuery>;
export type GetConnectedAccountQueryResult = Apollo.QueryResult<
  GetConnectedAccountQuery,
  GetConnectedAccountQueryVariables
>;
export const GetSupplementMediaForReportDocument = gql`
  query GetSupplementMediaForReport($reportConfigId: ObjectId!) {
    getSupplementMediaForReport(reportConfigId: $reportConfigId) {
      _id
      mediaType
      mediaUrl
      profileId
      caption
      timestamp
      mediaIsVideo
      mediaId
      insights {
        reach
        plays
        likes
        comments
        shares
        saves
      }
    }
  }
`;

/**
 * __useGetSupplementMediaForReportQuery__
 *
 * To run a query within a React component, call `useGetSupplementMediaForReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplementMediaForReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplementMediaForReportQuery({
 *   variables: {
 *      reportConfigId: // value for 'reportConfigId'
 *   },
 * });
 */
export function useGetSupplementMediaForReportQuery(
  baseOptions: Apollo.QueryHookOptions<GetSupplementMediaForReportQuery, GetSupplementMediaForReportQueryVariables>
) {
  return Apollo.useQuery<GetSupplementMediaForReportQuery, GetSupplementMediaForReportQueryVariables>(
    GetSupplementMediaForReportDocument,
    baseOptions
  );
}
export function useGetSupplementMediaForReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSupplementMediaForReportQuery, GetSupplementMediaForReportQueryVariables>
) {
  return Apollo.useLazyQuery<GetSupplementMediaForReportQuery, GetSupplementMediaForReportQueryVariables>(
    GetSupplementMediaForReportDocument,
    baseOptions
  );
}
export type GetSupplementMediaForReportQueryHookResult = ReturnType<typeof useGetSupplementMediaForReportQuery>;
export type GetSupplementMediaForReportLazyQueryHookResult = ReturnType<typeof useGetSupplementMediaForReportLazyQuery>;
export type GetSupplementMediaForReportQueryResult = Apollo.QueryResult<
  GetSupplementMediaForReportQuery,
  GetSupplementMediaForReportQueryVariables
>;
export const GetTrackingLinkListDocument = gql`
  query GetTrackingLinkList($type: TrackingLinkType, $target: String) {
    getTrackingLinkList(type: $type, target: $target) {
      _id
      customLink
      link
      longUrl
      type
      target
      actionType
      assignedProfile
      totalClicks
    }
  }
`;

/**
 * __useGetTrackingLinkListQuery__
 *
 * To run a query within a React component, call `useGetTrackingLinkListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrackingLinkListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrackingLinkListQuery({
 *   variables: {
 *      type: // value for 'type'
 *      target: // value for 'target'
 *   },
 * });
 */
export function useGetTrackingLinkListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTrackingLinkListQuery, GetTrackingLinkListQueryVariables>
) {
  return Apollo.useQuery<GetTrackingLinkListQuery, GetTrackingLinkListQueryVariables>(
    GetTrackingLinkListDocument,
    baseOptions
  );
}
export function useGetTrackingLinkListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTrackingLinkListQuery, GetTrackingLinkListQueryVariables>
) {
  return Apollo.useLazyQuery<GetTrackingLinkListQuery, GetTrackingLinkListQueryVariables>(
    GetTrackingLinkListDocument,
    baseOptions
  );
}
export type GetTrackingLinkListQueryHookResult = ReturnType<typeof useGetTrackingLinkListQuery>;
export type GetTrackingLinkListLazyQueryHookResult = ReturnType<typeof useGetTrackingLinkListLazyQuery>;
export type GetTrackingLinkListQueryResult = Apollo.QueryResult<
  GetTrackingLinkListQuery,
  GetTrackingLinkListQueryVariables
>;
