import { useState, useCallback, useEffect } from 'react';
import heic2any from 'heic2any';
import Resizer from 'react-image-file-resizer';
import { ISizeCalculationResult } from 'image-size/dist/types/interface';

interface ICachedImage {
  [key: string]: string;
}

export function isFile(item?: File | Blob | Blob[] | ProgressEvent<FileReader> | string | null): item is File {
  return item && (item as File).name !== undefined ? true : false;
}

export function isBlobArray(item?: File | Blob | Blob[] | null): item is Blob[] {
  return item && !isFile(item) && (item as Blob[]).constructor === Array ? true : false;
}

export const useFileReader = (): {
  readFile: (
    file?: File | Blob | Blob[] | null,
    callback?: (name?: string, blob?: Blob, encodedImage?: string) => void
  ) => void;
  convertFile: (file?: File | Blob | Blob[] | null, callback?: (name?: string, blob?: Blob) => void) => void;
  getCachedImage: (key: string) => string;
  clearCache: () => void;
  convertFileLoading: boolean;
  readFileLoading: boolean;
  compressFile: (fileData: { file: File; sizeInfo: ISizeCalculationResult | undefined }) => Promise<unknown>;
  compressFileLoading: boolean;
} => {
  const [convertFileLoading, setConvertFileLoading] = useState(false);
  const [compressFileLoading, setCompressFileLoading] = useState(false);
  const [readFileLoading, setReadFileLoading] = useState(false);
  const [imageCache, setImageCache] = useState<ICachedImage>({});
  const [imageToAppend, setImageToAppend] = useState<[string, string] | undefined>();

  useEffect(() => {
    if (imageToAppend) {
      const imageName = imageToAppend[0];
      const imageData = imageToAppend[1];
      const alreadySaved = Boolean(imageCache[imageName] === imageData);
      if (!alreadySaved) {
        setImageCache({ ...imageCache, [imageName]: imageData });
      }
    }
  }, [imageToAppend, imageCache]);

  const convertFile = useCallback(
    (file?: Blob | File | Blob[] | null, callback?: (name?: string, blob?: Blob) => void) => {
      setConvertFileLoading(true);
      const fileToUse = isBlobArray(file) ? (file.length ? file[0] : undefined) : file;
      if (fileToUse) {
        if (fileToUse.type === 'image/heif' || fileToUse.type === 'image/heic') {
          heic2any({ blob: fileToUse, toType: 'image/jpeg' })
            .then((converted) => {
              const convertedFile = isBlobArray(converted) ? (converted.length ? converted[0] : undefined) : converted;

              setConvertFileLoading(false);
              if (callback) {
                callback(`converted_file.${fileToUse.type}`, convertedFile);
              }
            })
            .catch((ex) => console.error('heic2any EXCEPTION', ex));
        } else {
          setConvertFileLoading(false);
          if (callback) {
            callback(isFile(fileToUse) ? fileToUse.name : `raw_file.${fileToUse.type}`, fileToUse);
          }
        }
      } else if (callback) {
        callback();
      }
    },
    [setConvertFileLoading]
  );

  const readFile = useCallback(
    (file?: Blob | File | Blob[] | null, callback?: (name?: string, blob?: Blob, encodedImage?: string) => void) => {
      setReadFileLoading(true);
      const reader = new FileReader();
      const fileToUse = isBlobArray(file) ? (file.length ? file[0] : undefined) : file;
      if (fileToUse) {
        convertFile(fileToUse, (name, blob) => {
          if (blob) {
            reader.onload = (e) => {
              setReadFileLoading(false);
              const fileName = isFile(fileToUse) ? fileToUse.name : name || `converted_file.${fileToUse.type}`;
              if (typeof e?.target?.result === 'string') {
                setImageToAppend([fileName, e.target.result]);

                if (callback) {
                  callback(fileName, blob, e.target.result);
                }
              } else if (callback) {
                callback(fileName, blob);
              }
            };
            reader.readAsDataURL(blob);
          } else if (callback) {
            callback();
          }
        });
      } else if (callback) {
        callback();
      }
    },
    [setImageToAppend, convertFile, setReadFileLoading]
  );

  const compressFile = (fileData: {
    file: File;
    sizeInfo: ISizeCalculationResult | undefined;
  }): Promise<File | undefined> =>
    new Promise((resolve, reject) => {
      setCompressFileLoading(true);
      try {
        const imageHeight = fileData.sizeInfo?.height || 0;
        const imageWidth = fileData.sizeInfo?.width || 0;
        const newHeightRatio = imageWidth && 800 / imageWidth;
        const adjustedImageHeight = imageHeight * newHeightRatio || 1000;

        Resizer.imageFileResizer(
          fileData.file, // Is the file of the image which will resized.
          800, // Is the maxWidth of the resized new image.
          adjustedImageHeight, // Is the maxHeight of the resized new image.
          'jpeg', // Is the compressFormat of the resized new image.
          70, // Is the quality of the resized new image.
          0, // Is the degree of clockwise rotation to apply to uploaded image.
          (responseUri) => {
            setCompressFileLoading(false);
            resolve(isFile(responseUri) ? responseUri : undefined);
          }, // Is the callBack function of the resized new image URI.
          'file' // Is the output type of the resized new image.
        );
      } catch (ex) {
        setCompressFileLoading(false);
        console.error('react-image-file-resizer Resizer.imageFileResizer EXCEPTION', ex);
        reject(ex);
      }
    });

  const getCachedImage = useCallback(
    (key: string) => {
      return imageCache[key];
    },
    [imageCache]
  );

  const clearCache = useCallback(() => {
    setImageCache({});
  }, [setImageCache]);

  return {
    readFile,
    getCachedImage,
    clearCache,
    convertFile,
    compressFile,
    readFileLoading,
    convertFileLoading,
    compressFileLoading
  };
};
