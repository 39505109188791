import { useEffect, useState, CSSProperties } from 'react';
import {
  useTheme,
  Button,
  ButtonBase,
  Grid,
  TextField,
  CircularProgress,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Modal from 'components/Modal';
import useTracking from 'context/useTrackingCtx';
import { formatError } from 'lib/formatStrings';
import {
  CollaborationStatus,
  GetCollaborationListDocument,
  Collaboration,
  GetCompanyContactListDocument,
  UpdateCollaborationMutationVariables,
  useCreateCollaborationMutation,
  useUpdateCollaborationMutation,
  GetMyUserUsageDocument,
  FileInput
} from 'types/generated';
import { useNavigate, useLocation } from 'react-router-dom';
import ProfileSelector from 'components/Fields/ProfileSelector';
import useSubscribePopupCtx, { PlanLimitType } from 'context/useSubscribePopupCtx';
import ContactSelector from '../../Fields/ContactSelector';
import PaginatedForm, { PageContent } from './PaginatedForm';
import { compact, isEmpty, pick } from 'lodash';
import FileUploadsField from './FileUploadsField';

interface CollabCreationProps {
  children: JSX.Element;
  editableCollab?: Collaboration;
  selectedClientId?: string;
  toggleModal?: () => void;
  buttonStyles?: CSSProperties;
  size?: number;
}

interface CreationModalProps extends CollabCreationProps {
  isOpen: boolean;
}

export const CollabCreation = (props: CollabCreationProps) => {
  const { children, editableCollab, size } = props;
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const { executeLimitedAction, loading } = useSubscribePopupCtx(PlanLimitType.COLLABS_USAGE);

  const openModal = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    const hasNewPath = location.search.includes('create');
    if (hasNewPath) {
      executeLimitedAction(openModal);
    }
    // only want to run this once on initial component mount, otherwise any state changes from parent component will re-run this hook
    // eslint-disable-next-line
  }, []);

  const dismissModal = () => {
    setIsOpen(false);
    if (props.toggleModal) props.toggleModal();
  };

  return (
    <span style={{ display: 'block', width: '100%' }}>
      {isOpen && <CreationModal {...props} isOpen={isOpen} toggleModal={dismissModal} />}
      <ButtonBase
        style={{ width: '100%', ...props.buttonStyles }}
        onClick={() => (editableCollab ? openModal() : executeLimitedAction(openModal))}
        disabled={loading}
      >
        {loading ? <CircularProgress size={size} /> : children}
      </ButtonBase>
    </span>
  );
};

const CreationModal = (props: CreationModalProps) => {
  const { isOpen, toggleModal, editableCollab, selectedClientId } = props;
  const { trackEvent } = useTracking();
  const theme = useTheme();
  const { classes } = useStyles();

  const determineDefaultIds = () => {
    let defaultInfluencerIds: Array<string> = [];
    if (editableCollab && !isEmpty(editableCollab?.profiles)) {
      const profilesList = editableCollab?.profiles ? compact(editableCollab?.profiles) : [];

      defaultInfluencerIds =
        profilesList.reduce<string[]>((acc, curr) => {
          if (curr && curr._id) acc.push(curr._id);
          return acc;
        }, []) || [];
    }
    return defaultInfluencerIds;
  };

  const [name, setName] = useState(editableCollab?.name || '');
  const [brandId, setBrandId] = useState<string | undefined>(
    editableCollab?.brand?._id || selectedClientId || undefined
  );
  const [fileUploads, setFileUploads] = useState<Array<FileInput>>(
    editableCollab?.fileUploads ? compact(editableCollab.fileUploads) : []
  );
  const [talentSelectMethod, setTalentSelectMethod] = useState<'individually' | 'allTalent'>('individually');
  const [profileIds, setProfileIds] = useState(determineDefaultIds());
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const clearTalentEntries = () => {
    setProfileIds([]);
  };

  const [createCollaboration, { loading: createLoading }] = useCreateCollaborationMutation({
    refetchQueries: [
      { query: GetCollaborationListDocument },
      { query: GetCompanyContactListDocument },
      { query: GetMyUserUsageDocument }
    ]
  });
  const [updateCollaboration, { loading: updateLoading }] = useUpdateCollaborationMutation({
    refetchQueries: [{ query: GetCollaborationListDocument }, { query: GetCompanyContactListDocument }]
  });

  const createCollab = () => {
    // Set payload
    if (!name) {
      setError('Please add a name for your campaign');
      return null;
    } else if (error) {
      setError('');
    }

    const updates: UpdateCollaborationMutationVariables['updates'] = {
      name,
      profileIds,
      fileUploads: fileUploads.map((o) => pick(o, ['link', 'title', 'file', 'fileSize']))
    };
    if (brandId) updates.brandId = brandId;
    // Create/update
    if (editableCollab?._id) {
      updateCollaboration({ variables: { id: editableCollab?._id, updates } })
        .then(() => {
          trackEvent('campaigns', 'updated campaign');
          toggleModal?.();
        })
        .catch((error) => {
          const errorMessage = formatError(error) || 'Error updating campaign';
          setError(errorMessage);
        });
    } else {
      createCollaboration({ variables: { updates: { ...updates, status: CollaborationStatus.Draft } } })
        .then(() => {
          trackEvent('campaigns', 'created campaign');
          toggleModal?.();
          navigate('/campaigns');
        })
        .catch((error) => {
          const errorMessage = formatError(error) || 'Error creating campaign';
          setError(errorMessage);
        });
    }
  };

  const pages: PageContent[] = [
    {
      title: 'New Campaign',
      content: (
        <Grid container className={classes.modalContainer}>
          <Grid item xs={12} className={classes.input}>
            <TextField
              variant='standard'
              value={name}
              onChange={(e: any) => setName(e.target.value)}
              label={'Name of Campaign *'}
              fullWidth={true}
              inputProps={{ contentEditable: true }}
            />
          </Grid>
          <ContactSelector setSelectedId={(id) => setBrandId(id)} selectedId={brandId} emptyLabel={'Select brand'} />

          <Grid item xs={12} className={classes.input}>
            <FileUploadsField value={fileUploads} setValue={setFileUploads} />
          </Grid>
        </Grid>
      ),
      renderActions: ({ nextPage }) => (
        <Grid container spacing={2}>
          <Grid item>
            <Button variant='contained' color='primary' onClick={nextPage} disabled={isEmpty(name)}>
              Next - Add Talent
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={createCollab} variant='outlined'>
              Save & Exit
            </Button>
          </Grid>
        </Grid>
      )
    },
    {
      title: 'Add Talent',
      content: (
        <Grid container className={classes.modalContainer}>
          <Grid item xs={12} className={classes.input}>
            <RadioGroup
              value={talentSelectMethod}
              onChange={(event: any) => {
                clearTalentEntries();
                setTalentSelectMethod(event?.target?.value);
              }}
              style={{ marginLeft: '-10px' }}
            >
              <FormControlLabel
                value={'individually'}
                control={<Radio checked={Boolean(talentSelectMethod === 'individually')} style={{ fontSize: 12 }} />}
                label='Individually'
                style={{ margin: 0 }}
              />
              <FormControlLabel
                value={'allTalent'}
                control={<Radio checked={Boolean(talentSelectMethod === 'allTalent')} />}
                label={<span style={{ fontSize: 14 }}>Assign to all accounts</span>}
                style={{ margin: 0 }}
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={12} className={classes.input}>
            <ProfileSelector
              emptyLabel='Assign to Talent'
              selectedIds={profileIds}
              setSelectedIds={setProfileIds}
              isAllSelected={Boolean(talentSelectMethod === 'allTalent')}
            />
          </Grid>
        </Grid>
      ),
      renderActions: ({ previousPage }) => (
        <Grid container spacing={2} style={{ width: '100%' }}>
          <Grid item>
            <Button variant='outlined' color='primary' onClick={previousPage}>
              Back
            </Button>
          </Grid>
          <Grid item>
            <Button variant='contained' color='primary' onClick={createCollab}>
              {editableCollab ? 'Update' : 'Create'}
            </Button>
          </Grid>
          {error && (
            <Grid item xs={12}>
              <Typography color='error'>{error}</Typography>
            </Grid>
          )}
        </Grid>
      )
    }
  ];

  return (
    <Modal
      isOpen={isOpen}
      onToggle={toggleModal}
      hideConfirmationButtons
      errorMessage={error}
      showErrorMessage={Boolean(error)}
      maxWidth={'sm'}
      showCloseIcon
      disableBackdropClick={true}
      heading={editableCollab?._id ? `Edit ${editableCollab?.name || ' Campaign'}` : 'New Campaign'}
      paperStyle={{ borderColor: theme.palette.primary.main, borderStyle: 'solid', borderWidth: 0.5, borderRadius: 4 }}
    >
      <PaginatedForm pages={pages} isLoading={createLoading || updateLoading} />
    </Modal>
  );
};

const useStyles = makeStyles()((theme) => {
  return {
    centred: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    modalContainer: {
      overflowX: 'hidden'
    },
    input: {
      margin: '15px 0'
    },
    inputTitle: {
      fontSize: 12,
      fontWeight: 500
    },
    statusButton: {
      minWidth: 110,
      margin: 5,
      fontSize: 12,
      color: '#333333',
      textTransform: 'none'
    },
    inactive: {
      backgroundColor: '#F3F1F1'
    },
    active: {
      border: 'solid 0.5px #979797'
    },
    activeIndicator: {
      height: 6,
      width: 6,
      borderRadius: '100%',
      backgroundColor: '#01D311',
      marginRight: 5
    },
    finishButton: {
      width: '100%'
    },
    error: {
      color: theme.palette.error.main,
      fontSize: 12,
      marginTop: 10,
      textAlign: 'center'
    }
  };
});
