import React, { useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ButtonBase from '@mui/material/ButtonBase';
import { Loader } from 'components/Loader';
import AccountItem from 'components/AccountItem';
import NoConnectedHint from './NoConnectedHint';
import { useMyUserProfilesQuery, Profile } from 'types/generated';
import useSubscribePopupCtx from 'context/useSubscribePopupCtx';
import isEmpty from 'lodash/isEmpty';

/**
 * Please be carefully, the report generator will ask for mongo id: such as
 * "5c8ac97c62ec18045a9398a1"
 *
 * the account performance will ask for the profile id such as
 * "17841400065550179"
 */

interface IProfileSelectSlider {
  value?: string | null | Array<string>;
  onSelect?: (value?: Profile | null) => void;
}

const ProfileSelectSlider = ({ value, onSelect }: IProfileSelectSlider) => {
  const { classes } = useClasses();
  const { data, loading } = useMyUserProfilesQuery({ fetchPolicy: 'network-only' });
  const profiles = data?.myUser?.profiles;
  const noProfiles = Boolean(!profiles?.length);
  const valueType = typeof value;
  const { openSubscribePopup } = useSubscribePopupCtx();

  useEffect(() => {
    if (onSelect && profiles && profiles[0]) {
      if (isEmpty(value) && profiles[0].id) {
        onSelect(profiles[0]);
      }
    }
  }, [profiles, onSelect, value]);

  const profileStyleController = (profileId: string, mongoId: string) => {
    if (valueType === 'string') {
      // string
      if (value === profileId) return classes.activeItem;
      return classes.inactiveItem;
    }

    if (valueType === 'object') {
      // Array
      if (!value) return classes.inactiveItem;
      if (value?.indexOf(mongoId) > -1) {
        return classes.activeItem;
      }
      return classes.inactiveItem;
    }
  };

  return (
    <>
      <Box className={classes.pageWrapper} style={noProfiles ? { overflow: 'hidden' } : undefined}>
        {loading && <Loader />}
        {Boolean(noProfiles && !loading) && <NoConnectedHint />}
        <Box className={classes.accountContainer}>
          {profiles?.map((profile) => {
            return (
              <ButtonBase
                className={`
                    ${classes.profileBtn} 
                    ${profileStyleController(profile?.id || '', profile?._id || '')}`}
                key={profile?.id}
                onClick={() => {
                  if (onSelect) {
                    return profile?.isRestricted ? openSubscribePopup() : onSelect(profile);
                  }
                }}
              >
                <Grid container justifyContent='flex-start'>
                  <AccountItem profile={profile!} />
                </Grid>
              </ButtonBase>
            );
          })}
        </Box>
      </Box>
    </>
  );
};

const useClasses = makeStyles()((theme) => ({
  loadingWrapper: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 9999
  },
  pageWrapper: {
    overflow: 'auto',
    maxWidth: 930,
    width: '100%',
    marginBottom: 10
  },
  headerNavigationWrapper: {
    display: 'flex',
    alignItems: 'center',
    color: '#3A3A3A',
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  pageTitle: {
    fontSize: 20,
    color: '#3A3A3A',
    fontFamily: 'Open Sans',
    fontWeight: 500
  },
  profileBtn: {
    borderRadius: 4,
    minWidth: 240,
    margin: 4,
    paddingRight: 10,
    paddingLeft: 10,
    paddingTop: 3,
    paddingBottom: 3,
    backgroundColor: '#fff'
  },
  activeItem: {
    border: `solid 0.5px ${theme.palette.primary.main}`
  },
  inactiveItem: {
    border: 'solid 0.5px #B0A1AA'
  },
  accountContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 0,
    overflow: 'auto'
  },
  mediaKitColWrapper: {
    marginTop: 40,
    paddingLeft: 80
  }
}));

export default ProfileSelectSlider;
