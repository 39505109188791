import { Grid, Typography, Avatar } from '@mui/material';
import { get } from 'lodash';

import { formatNumber } from 'lib/formatNumber';

import SummaryStats from './SummaryStats';
import { channelIcons } from 'lib/constants';
import { Profile } from 'types/generated';

import 'utils/LineClamp.css';
import { makeStyles } from 'tss-react/mui';

const styles = makeStyles()((theme) => ({
  accountTitle: {
    fontSize: 16
  },
  accountInfo: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    flexGrow: 1
  },
  avatarContainer: {
    position: 'relative',
    marginRight: 25,
    height: 'fit-content'
  },
  avatar: {
    width: 49,
    height: 49
  },
  bioText: {
    fontSize: 12
  },
  channelContainer: {
    height: 28,
    width: 28,
    position: 'absolute',
    bottom: 0,
    right: -15,
    backgroundColor: '#FFF',
    borderRadius: '100%',
    border: `solid ${theme.palette.secondary.main} 0.5px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  channelIcon: {
    height: 16,
    width: 16
  },
  sliderContainer: {
    height: 150
  },
  gallerySlide: {
    paddingRight: 6
  },
  galleryImage: {
    height: 100,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  sliderDots: {
    display: 'flex !important',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '100%',
    '& li': {
      width: 6,
      height: 6,
      padding: 0,
      borderRadius: 5,
      border: `1px solid ${theme.palette.primary.main}`,
      color: 'transparent',
      margin: '0 2px',
      listStyle: 'none',
      '&.slick-active': {
        backgroundColor: theme.palette.primary.main
      },
      '& button': {
        border: 'none',
        margin: 0,
        background: 0,
        color: 'transparent',
        outline: 0
      }
    }
  }
}));

const Summary = ({ profile }: { profile: Profile }) => {
  const { classes } = styles();

  let channel = profile?.profileType || get(profile, '[0].profileType') || '';
  if (channel) channel = channel.toLowerCase();
  const logos: { [key: string]: string } = channelIcons;
  const channelLogo = logos[channel];

  return (
    <Grid item xs={12}>
      <Grid container spacing={3}>
        <Grid item xs={5} container spacing={2}>
          <Grid item xs={12}>
            <Typography className={classes.accountTitle}>Account Information</Typography>
          </Grid>
          <Grid item container xs={12} className={classes.accountInfo}>
            <div className={classes.avatarContainer}>
              <Avatar src={profile.profile_picture_url || undefined} className={classes.avatar} />
              {channelLogo && (
                <div className={classes.channelContainer}>
                  <img src={channelLogo} alt={channel} className={classes.channelIcon} />
                </div>
              )}
            </div>
            <div>
              <Typography>
                <b>{profile.displayName}</b>
              </Typography>
              <Typography className={classes.bioText}>
                {`${formatNumber(profile.fan_count || profile.pageStats?.page_fans)} fans`}
              </Typography>
              <Typography className={`${classes.bioText} line-clamp clamp-2`}>{profile.biography}</Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <SummaryStats profile={profile} />
    </Grid>
  );
};

export default Summary;
