import React, { useState, useEffect, useCallback } from 'react';

import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Delete from '@mui/icons-material/Delete';

import InputAdornment from '@mui/material/InputAdornment';

import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import compact from 'lodash/compact';

import { EditBoxProps } from './MediaKitEditBox';
import { availableElements } from '../../../lib/availableElements';
import { isRatesContent } from '../../../types/custom';

const styles = makeStyles()((theme) => ({
  error: { color: 'red' },
  textEditor: { minHeight: 300, width: '100%' },
  textPreview: { marginTop: 12, fontStyle: 'italic', fontSize: 14 },
  previewTitle: { fontSize: 16, fontWeight: 500 },
  headingSeparator: { margin: '8px 0' },
  previewContainer: { padding: 10, fontSize: 14, marginTop: 10, paddingBottom: 20 },
  rateTitle: { fontStyle: 'italic' }
}));

type RateItem = {
  heading: string;
  rates: Array<string>;
};

const LinksBox = ({ element: { content, title }, handleUpdate, isEditing }: EditBoxProps) => {
  const { classes } = styles();
  const descriptionText = find(availableElements, (el) => el.title === title)?.description || '';

  const [rateContent, setRateContent] = useState<Array<RateItem>>([]);

  const handleUpdateItem = (link: RateItem, index: number) => {
    setRateContent(rateContent.map((o, i) => (i === index ? link : o)));
  };

  const handleAddItem = () => {
    setRateContent([...rateContent, { heading: '', rates: [''] }]);
  };

  const handleRemoveItem = (index: number) => {
    setRateContent(rateContent.filter((o, i) => (i === index ? false : true)));
  };

  useEffect(() => {
    if (!isEditing) {
      if (isRatesContent(content) && content?.rate_content) {
        const ratesContent = content && compact(content?.rate_content);
        setRateContent(
          ratesContent.map((o) => ({
            heading: o.heading || '',
            rates: compact(o.rates)
          }))
        );
      } else {
        setRateContent([]);
      }
    }
  }, [content, setRateContent, isEditing]);

  useEffect(() => {
    if (isEditing && isEmpty(rateContent)) {
      setRateContent([{ heading: '', rates: [''] }]);
    }
  }, [isEditing, rateContent, setRateContent]);

  const handleSave = () => {
    const cleanedContent = rateContent.filter((o) => Boolean(o.heading && !isEmpty(o.rates)));
    handleUpdate({ ...content, rate_content: cleanedContent });
    setRateContent(cleanedContent);
  };

  if (isEditing) {
    return (
      <>
        {rateContent.map((o, i) => (
          <RateSection
            key={`rate_section_${i}`}
            item={o}
            onChange={(link) => (link ? handleUpdateItem(link, i) : handleRemoveItem(i))}
            classes={classes}
          />
        ))}
        <Grid container justifyContent={'flex-end'}>
          <Grid item>
            <Button size={'small'} onClick={() => handleAddItem()}>
              Add Heading
            </Button>
          </Grid>
        </Grid>
        <Button variant='outlined' color='primary' onClick={handleSave}>
          {`Save & Close`}
        </Button>
      </>
    );
  }

  return (
    <Box className={classes.previewContainer}>
      {isEmpty(rateContent) ? (
        <Typography align='center' className={classes.textPreview}>
          {descriptionText || 'Enter rates here...'}
        </Typography>
      ) : (
        rateContent.map((o, i) => <RateSection key={`rate_${i}`} item={o} classes={classes} />)
      )}
    </Box>
  );
};

const RateSection = ({
  item,
  onChange,
  classes
}: {
  item: RateItem;
  onChange?: (item?: RateItem) => void;
  classes: any;
}) => {
  const updateRate = useCallback(
    (index: number, value?: string) => {
      if (onChange) {
        if (value) {
          onChange({ ...item, rates: item.rates.map((o, i) => (i === index ? value : o)) });
        } else {
          onChange({
            ...item,
            rates: item.rates.length > 1 ? item.rates.filter((o, i) => (i === index ? false : true)) : ['']
          });
        }
      }
    },
    [onChange, item]
  );

  if (onChange) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <TextField
            variant='standard'
            fullWidth
            label={'Heading'}
            value={item.heading}
            onChange={(e) => {
              onChange({ ...item, heading: e.target.value });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton onClick={() => onChange()}>
                    <Delete />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Grid>
        {item.rates.map((o, i) => {
          return (
            <Grid key={`rate_${i}`} item xs={12}>
              <TextField
                variant='standard'
                fullWidth
                label={`- Rate ${i + 1}`}
                value={o}
                onChange={(e) => updateRate(i, e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton onClick={() => updateRate(i)}>
                        <Delete />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
          );
        })}
        <Grid container justifyContent={'flex-end'}>
          <Grid item>
            <Button size={'small'} onClick={() => onChange({ ...item, rates: [...item.rates, ''] })}>
              Add Rate
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography className={classes.rateTitle}>{item.heading}</Typography>
        <hr className={classes.headingSeparator} />
      </Grid>
      {item.rates.map((o, i) => {
        return (
          <Grid key={`rate_${i}`} item xs={12}>
            <Typography>{`- ${o}`}</Typography>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default LinksBox;
