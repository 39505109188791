export type CurrencySign = '€' | '¥' | '£' | '$' | '₹';
export type CurrencyOption = { title: string; value: string; symbol: CurrencySign };

export const currencyOptions: Array<CurrencyOption> = [
  { title: 'Pound Sterling (£GBP)', value: 'GBP', symbol: '£' },
  { title: 'Australian Dollar ($AUD)', value: 'AUD', symbol: '$' },
  { title: 'US Dollar ($USD)', value: 'USD', symbol: '$' },
  { title: 'Euro (€EUR)', value: 'EUR', symbol: '€' },
  { title: 'New Zealand Dollar ($NZD)', value: 'NZD', symbol: '$' },
  { title: 'Canadian Dollar ($CAD)', value: 'CAD', symbol: '$' },
  { title: 'Japanese Yen (¥JPY)', value: 'JPY', symbol: '¥' },
  { title: 'Indian Rupees (₹INR )', value: 'INR', symbol: '₹' }
];

export let currencyDirectory: { [key: string]: CurrencyOption } = {};

currencyOptions.forEach((o) => (currencyDirectory[o.value] = o));

export const bankInfoOptions = [
  { title: 'BSB', value: 'BSB', description: 'Bank-State-Branch' },
  { title: 'BIC', value: 'BIC', description: 'Business Identification Code' },
  {
    title: 'SWIFT Code',
    value: 'SWIFT',
    description: 'Standard format of Business Identification Codes'
  },
  {
    title: 'CHIPS',
    value: 'CHIPS',
    description: 'Clearing House Inter-Bank Payment System',
    countries: ['US', 'CA']
  },
  { title: 'BNCC', value: 'BNCC', description: 'National Clearing Code' },
  { title: 'BSC', value: 'BSC', description: 'Bank Sort Code' },
  { title: 'IFSC', value: 'IFSC', description: 'Indian Financial System Code' }
];
