import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import PerformanceBarChart from '../PerformanceBarChart';
// icons
import FollowersIcon from 'assets/images/followers.svg';
import ImpressionsIcon from 'assets/images/impressions.svg';

interface IYoutubeVideoSummary {
  stats: any;
}

const YoutubeVideoSummary = ({ stats }: IYoutubeVideoSummary) => {
  const { classes } = styles();

  let performanceRows: any[] = [];

  if (stats) {
    if (stats.viewCount)
      performanceRows.push({
        name: 'Views',
        value: stats.viewCount,
        icon: ImpressionsIcon
      });
    if (stats.subscriberCount)
      performanceRows.push({
        name: 'Subscribers',
        value: stats.subscriberCount,
        icon: FollowersIcon
      });
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box className={'insights-container'}>
          <Typography className={classes.cardTitle}>Lifetime stats</Typography>
          <PerformanceBarChart data={performanceRows} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default YoutubeVideoSummary;

const styles = makeStyles()({
  cardTitle: {
    marginBottom: 10,
    fontSize: 16,
    fontWeight: 500
  },
  cardSubtitle: {
    marginBottom: 10,
    fontSize: 14
  },
  insightsContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 10,
    margin: 4
  },
  insightsAmount: {
    fontSize: 18,
    textAlign: 'center'
  },
  insightsChange: {
    fontSize: 12,
    verticalAlign: 'top'
  },
  insightsTitle: {
    fontSize: 14,
    textAlign: 'center'
  },
  insightsSubtitle: {
    color: '#7A7A7A',
    fontSize: 10,
    textAlign: 'center'
  }
});
