import { useState, useEffect } from 'react';

import {
  Grid,
  InputProps,
  FormHelperTextProps,
  Chip,
  Input,
  InputLabel,
  FormControl,
  IconButton,
  InputAdornment,
  FormHelperText
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import uniq from 'lodash/uniq';

import { CustomChip } from 'components/Fields/CustomChip';
import { makeStyles } from 'tss-react/mui';
import { isEmpty } from 'lodash';

interface ChipFieldProps {
  title?: string;
  placeholder?: string;
  chips: Array<string>;
  symbol: string;
  type?: string;
  limit: number;
  onChange: (chips: Array<string>) => void;
}

interface HandleDeleteChipProps {
  chip: string;
  index: number;
}

interface ChipRendererParams {
  text: string;
  handleDelete: (props: HandleDeleteChipProps) => void;
}

const ChipField = (props: ChipFieldProps) => {
  const { classes } = useStyles();

  const [chips, setChips] = useState<Array<string>>(props.chips || []);
  const [errorText, setErrorText] = useState<string | null>(null);

  const title = props.title || null;

  useEffect(() => {
    if (props.chips) {
      setChips(props.chips);
    }
  }, [props.chips]);

  const handleBeforeAddChip = (newChipToAdd: string): boolean => {
    let shouldAdd: boolean = false;
    if (newChipToAdd) {
      if (newChipToAdd.startsWith('@')) {
        shouldAdd = true;
        setErrorText(null);
      } else if (newChipToAdd.startsWith('#')) {
        shouldAdd = true;
        setErrorText(null);
      } else {
        setErrorText(`Must start with '@' or '#'`);
      }
    }

    return shouldAdd;
  };

  const handleAddChip = (newChip: string) => {
    let newChips = uniq(chips.concat(newChip.split(' ')));

    if (typeof props.limit === 'number') {
      newChips = newChips.slice(0, props.limit);
    }

    let filteredChips: string[] = [];
    newChips.forEach((o) => {
      let cleanChip = o.replace(/\s/g, '');

      if (cleanChip.length > 0) {
        filteredChips.push(cleanChip);
      }
    });

    setChips(filteredChips);

    if (props.onChange) props.onChange(filteredChips);
  };

  const handleRemoveChip = (index: number) => {
    if (chips.length > index) {
      let newChips = chips.concat();
      newChips.splice(index, 1);
      setChips(newChips);
      if (props.onChange) props.onChange(newChips);
    }
  };

  return (
    <Grid container style={{ marginTop: '15px' }}>
      {title && (
        <Grid item xs={12} style={{ minHeight: '22px' }}>
          <span className={classes.inputLabelText}>{title}</span>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        style={{ minHeight: '33px', display: 'flex', alignItems: 'flex-end', flexDirection: 'row', flexWrap: 'nowrap' }}
      >
        <ChipInput
          data-cy={props.type ? `chip-input-${props.type}` : 'chip-input'}
          placeholder={props.placeholder}
          value={chips}
          onAdd={(chip: string) => handleAddChip(chip)}
          onBeforeAdd={(chip: string) => handleBeforeAddChip(chip)}
          helperText={errorText || ''}
          onDelete={(chip: string, index: number) => handleRemoveChip(index)}
          fullWidth
          chipRenderer={(params: ChipRendererParams, key: string) => {
            const { text, handleDelete } = params;
            return <CustomChip key={key} label={`${text}`} onDelete={handleDelete} />;
          }}
          InputProps={{
            inputProps: {
              contentEditable: true,
              'data-cy': props.symbol === '#' ? 'mentioned-hashtag-entry' : 'mentioned-handle-entry'
            }
          }}
          FormHelperTextProps={{
            style: {
              color: '#F00',
              fontSize: '10px'
            }
          }}
        />
      </Grid>
    </Grid>
  );
};

export default ChipField;

const useStyles = makeStyles()({
  inputLabelText: {
    color: '#757575',
    fontFamily: 'Open Sans',
    fontSize: '0.6em',
    fontWeight: 600,
    textTransform: 'uppercase',
    paddingBottom: '5px'
  }
});

interface IChipInput {
  value: string[];
  onAdd: (chip: string) => void;
  onBeforeAdd?: (chip: string) => void;
  helperText?: string;
  placeholder?: string;
  onDelete: (chip: string, index: number) => void;
  chipRenderer: (params: ChipRendererParams, key: string) => React.ReactNode;
  InputProps?: InputProps;
  FormHelperTextProps?: FormHelperTextProps;
  className?: string;
  fullWidth?: boolean;
}

const ChipInput = ({
  value,
  onAdd,
  onBeforeAdd,
  helperText,
  placeholder,
  onDelete,
  chipRenderer,
  InputProps,
  FormHelperTextProps,
  className,
  fullWidth
}: IChipInput) => {
  const [newChipText, setNewChipText] = useState('');

  const handleAddChip = () => {
    if (newChipText) {
      if (onBeforeAdd) {
        onBeforeAdd(newChipText);
      }
      if (onAdd) {
        onAdd(newChipText);
      }
    }
    setNewChipText('');
  };

  return (
    <Grid container>
      {!isEmpty(value) && (
        <Grid item xs={12} style={{ marginBottom: 10 }}>
          {value.map((o, i) => {
            if (chipRenderer) {
              return chipRenderer({ text: o, handleDelete: () => onDelete(o, i) }, `chip-input-${i}`);
            }
            return (
              <Chip
                onDelete={() => {
                  if (onDelete) {
                    onDelete(o, i);
                  }
                }}
              />
            );
          })}
        </Grid>
      )}
      <Grid item xs={12} className={className}>
        <FormControl variant='standard' fullWidth>
          {placeholder && <InputLabel htmlFor='chip-input-form'>{placeholder}</InputLabel>}
          {helperText && <FormHelperText {...FormHelperTextProps}>{helperText}</FormHelperText>}
          <Input
            id='chip-input-form'
            fullWidth
            value={newChipText}
            onChange={(e) => {
              setNewChipText(e.target.value);
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleAddChip();
              }
            }}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton onClick={handleAddChip} onMouseDown={handleAddChip}>
                  <AddIcon />
                </IconButton>
              </InputAdornment>
            }
            {...InputProps}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};
