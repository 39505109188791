import ButtonBase from '@mui/material/ButtonBase';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from 'tss-react/mui';

const styles = makeStyles()({
  expandNotes: {
    color: '#808080',
    marginLeft: 3,
    height: 'fit-content'
  }
});

interface ComponentProps {
  isOpen: boolean;
  onClick?: () => void;
  color?: 'primary' | 'secondary' | 'error' | 'inherit';
}

const ExpandIcon = ({ isOpen, onClick, color }: ComponentProps) => {
  const { classes } = styles();

  const icon = isOpen ? (
    <ExpandLessIcon color={color} style={{ display: 'block', width: 14, height: 14 }} fontSize={'small'} />
  ) : (
    <ExpandMoreIcon color={color} style={{ display: 'block', width: 14, height: 14 }} fontSize={'small'} />
  );
  return onClick ? (
    <ButtonBase onClick={onClick} className={classes.expandNotes}>
      {icon}
    </ButtonBase>
  ) : (
    icon
  );
};

export default ExpandIcon;
