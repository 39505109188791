import { useApolloClient } from '@apollo/client';
import { AppBar, ButtonBase, CircularProgress, Grid, Toolbar } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import logo from 'assets/images/logo.svg';
import { CenteredContainer } from 'components/Shared/CenteredContainer';
import { useEffect, useState } from 'react';
import AppConfig from '../../config/appConfig';
import { useLocation } from 'react-router-dom';
import { useUserLogoutMutation } from '../../types/generated';

const Logout = () => {
  const [loading, setLoading] = useState(true);
  const { classes } = useStyles();
  const client = useApolloClient();
  const location = useLocation();

  const [userLogout] = useUserLogoutMutation({
    onCompleted: () => {
      sessionStorage.clear();
      localStorage.clear();
      client.clearStore();
      setLoading(false);
    }
  });

  useEffect(() => {
    if (loading) {
      if (location.state?.deletedAccount) {
        setLoading(false);
      } else {
        userLogout();
      }
    }
  }, [loading, location.state, userLogout]);

  const renderMessage = () => {
    if (loading) return <span>Logging out...</span>;
    return (
      <>
        <Typography variant='h2' style={{ margin: 20 }}>
          {location.state?.deletedAccount ? 'Account successfully deleted' : 'Successfully logged out'}
        </Typography>
        <Button
          href={AppConfig.HOME_URL}
          variant='contained'
          color='primary'
          // matched to signup styling (landing page)
          style={{
            margin: '10px',
            minWidth: 330,
            minHeight: 56,
            boxShadow: 'none',
            fontWeight: 500,
            fontSize: 16
          }}
        >
          Go to homepage
        </Button>
      </>
    );
  };

  return (
    <>
      <AppBar position='fixed' className={classes.appBar} elevation={1}>
        <Toolbar>
          <ButtonBase href={AppConfig.APP_URL}>
            <img src={logo} alt='logo' className={classes.logo} />
          </ButtonBase>
        </Toolbar>
      </AppBar>
      <CenteredContainer>
        <Grid container direction='column' alignItems='center' style={{ rowGap: 20 }}>
          {renderMessage()}
          {loading && <CircularProgress color='secondary' />}
        </Grid>
      </CenteredContainer>
    </>
  );
};

export default Logout;

const useStyles = makeStyles()((theme) => ({
  appBar: {
    backgroundColor: theme.palette.secondary.main,
    zIndex: theme.zIndex.drawer + 1
  },
  logo: {
    height: 50,
    width: 86
  },
  centeredContainer: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute'
  }
}));
