import { useCallback } from 'react';

const useCopyText = () => {
  const copyWithDeprecatedFunctionality = useCallback((textToCopy?: string | null) => {
    if (textToCopy) {
      let inputElement = document.createElement('input');
      inputElement.setAttribute('value', textToCopy);
      inputElement.type = 'text';
      inputElement.setAttribute('style', 'position: absolute, left: 99999, z-index: -100');
      document.body.appendChild(inputElement);

      inputElement.setAttribute('readonly', '');
      inputElement.select();
      inputElement.setSelectionRange(0, 99999);
      const success = document.execCommand('copy');

      inputElement.remove();
      return success;
    }
    return false;
  }, []);

  const copyText = useCallback(
    async (textToCopy?: string | null, callback?: (error?: string) => void) => {
      try {
        if (textToCopy) {
          await navigator.clipboard.writeText(textToCopy);
          const clipboardItem = new ClipboardItem({ 'text/plain': new Blob([textToCopy]) });
          await navigator.clipboard.write([clipboardItem]);
          if (callback) {
            callback();
          }
        } else {
          throw new Error('No text supplied');
        }
      } catch (ex) {
        const success = copyWithDeprecatedFunctionality(textToCopy);
        console.warn('copyText EXCEPTION', ex);
        if (callback) {
          const error = ex as Error;
          callback(error && !success ? error.message : undefined);
        }
      }
    },
    [copyWithDeprecatedFunctionality]
  );

  return copyText;
};

export default useCopyText;
