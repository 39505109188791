import { makeStyles } from 'tss-react/mui';

export const useCalendarStyles = makeStyles()((theme) => {
  return {
    container: {
      padding: '10px 0',
      fontSize: 12
    },
    allDates: {
      color: theme.palette.common.black,
      backgroundColor: '#FFF',
      '&:hover': {
        backgroundColor: '#FFF'
      },
      '&:disabled': {
        backgroundColor: '#FFF'
      },
      '&:enabled&:focus': {
        backgroundColor: theme.palette.primary.main,
        color: '#FFF'
      }
    },
    pastDate: {
      backgroundColor: '#ECECEC',
      '&:disabled': {
        backgroundColor: '#ECECEC'
      }
    },
    deliverableDate: {
      backgroundColor: theme.palette.secondary.main
    },
    activeDate: {
      backgroundColor: theme.palette.primary.main,
      color: '#FFF'
    }
  };
});
