import { Grid, Box, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { logoUrl } from 'config/muiTheme';

const KitHeader = () => {
  const theme = useTheme();
  const { classes } = styles();

  return (
    <Box className={classes.previewHeader} style={{ borderBottom: '2px solid ' + theme.palette.primary.main }}>
      <Grid container justifyContent='space-between' alignItems='center'>
        <Grid item className={classes.previewLogo}>
          <img src={logoUrl} alt='kitly' />
        </Grid>
        <Grid item className={classes.previewText}>
          MEDIA KIT
        </Grid>
        <Grid item className={classes.previewLogo}></Grid>
      </Grid>
    </Box>
  );
};

const styles = makeStyles()(() => ({
  previewHeader: {
    backgroundColor: '#fff',
    border: '0.5px solid #bbb',
    padding: '12px 18px'
  },
  previewText: {
    fontSize: '15px',
    letterSpacing: '1px',
    color: '#999',
    fontWeight: 500
  },
  previewLogo: {
    width: 100,
    '& img': {
      display: 'inline-flex',
      height: '35px',
      marginLeft: '4px',
      marginRight: '16px'
    }
  }
}));

export default KitHeader;
