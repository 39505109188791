import { makeStyles } from 'tss-react/mui';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Modal from 'components/Modal';
import TextPassword from 'components/TextPassword';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import React, { useEffect, useState } from 'react';
import q83API from '../../api/q83API';
import { Error_Type_Enum, useErrorHandlerHook } from '../../hooks/useErrorCatchHook';
import { useForm } from '../../hooks/useForm';
import { getInitials } from '../../lib/formatStrings';
import { useGetMyUserQuery, useUpdateUserMutation } from '../../types/generated';
import './EditProfile.css';

const defaultUserUpdates = {
  email: '',
  firstName: '',
  lastName: ''
};

const defaultPasswordUpdates = {
  password: '',
  current_password: '',
  confirm_password: ''
};

type UpdatesFormat = {
  [key: string]: string;
};

const EditProfile = () => {
  const theme = useTheme();
  const { classes } = useStyles();
  const [confirmationAlert, setConfirmationAlert] = useState<string | undefined>();
  const [editProfileSettings, setEditProfileSettings] = useState(false);
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [showPasswordErrors, setShowPasswordErrors] = useState(false);

  const { data: userData, loading: userLoading, error: userError } = useGetMyUserQuery();
  const [updateUser, { loading: updateLoading, error: updateError }] = useUpdateUserMutation({
    onCompleted: () => {
      setConfirmationAlert('Profile updated successfully!');
      setEditProfileSettings(false);
    }
  });

  const user = userData?.myUser;

  const { apolloHandler, errorHandler } = useErrorHandlerHook();

  const [values, setValue, setAllValues] = useForm(defaultUserUpdates);

  const [passwordValues, setPasswordValue, setAllPasswordValues] = useForm(defaultPasswordUpdates);

  const isLoading = updateLoading || userLoading;

  useEffect(() => {
    if (updateError) {
      apolloHandler(updateError);
    }
  }, [updateError, apolloHandler]);

  useEffect(() => {
    if (userError) {
      apolloHandler(userError);
    }
  }, [userError, apolloHandler]);

  useEffect(() => {
    if (user) {
      setAllValues({
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        email: user.email || ''
      });
    }
  }, [user, setAllValues]);

  const handleChange = (name) => (event) => {
    let updateString = event.target.value;

    if (name === 'email') {
      updateString = updateString.replace(/\s/g, '').toLowerCase();
    }

    setValue(name, updateString);
  };

  const areUpdatesValid = (updates: UpdatesFormat) => {
    const updateKeys = Object.keys(updates);
    for (let i = 0; i < updateKeys.length; i++) {
      if (!updates[updateKeys[i]]) {
        return false;
      }
    }
    return true;
  };

  const handleChangePassword = (name: string) => (event: any) => {
    setPasswordValue(name, event.target.value);
  };

  const handleProfileUpdate = () => {
    if (areUpdatesValid(values)) {
      updateUser({
        variables: {
          updates: values
        }
      });
    } else {
      setShowErrors(true);
    }
  };

  const handlePasswordUpdate = () => {
    if (!areUpdatesValid(passwordValues)) {
      setShowPasswordErrors(true);
    } else if (passwordValues.password !== passwordValues.confirm_password) {
      errorHandler(Error_Type_Enum.passwords_do_not_match);
    } else {
      const payload = pick(passwordValues, ['password', 'current_password']);
      q83API
        .post('user/password/change', payload)
        .then(() => {
          setAllPasswordValues(defaultPasswordUpdates);
          setConfirmationAlert('Password has been successfully updated!');
        })
        .catch((ex) => {
          errorHandler(ex.message);
        });
    }
  };

  const renderName = () => {
    return (
      <Grid container direction='column'>
        <Grid item xs={12} style={{ marginLeft: editProfileSettings ? 0 : 30, marginBottom: 20 }}>
          {editProfileSettings ? (
            <TextField
              variant='standard'
              fullWidth
              required
              error={Boolean(showErrors && isEmpty(values.firstName))}
              helperText={showErrors && isEmpty(values.firstName) && 'Please enter your first name'}
              id='firstname'
              label='FIRST NAME'
              value={values.firstName}
              onChange={handleChange('firstName')}
            />
          ) : (
            <div>
              <span style={{ fontWeight: 500 }}>First Name:</span> {values.firstName || ''}
            </div>
          )}
        </Grid>
        <Grid item xs={12} style={{ marginLeft: editProfileSettings ? 0 : 30, marginBottom: 20 }}>
          {editProfileSettings ? (
            <TextField
              variant='standard'
              fullWidth
              required
              error={Boolean(showErrors && isEmpty(values.lastName))}
              helperText={showErrors && isEmpty(values.lastName) && 'Please enter your last name'}
              id='lastname'
              label='LAST NAME'
              value={values.lastName}
              onChange={handleChange('lastName')}
            />
          ) : (
            <div>
              <span style={{ fontWeight: 500 }}>Last Name:</span> {values.lastName || ''}
            </div>
          )}
        </Grid>
      </Grid>
    );
  };

  const renderEmail = () => {
    return (
      <Grid item xs={12} style={{ marginLeft: editProfileSettings ? 0 : 30, marginBottom: 20 }}>
        {editProfileSettings ? (
          <TextField
            variant='standard'
            fullWidth
            required
            error={Boolean(showErrors && isEmpty(values.email))}
            helperText={showErrors && isEmpty(values.email) && 'Please enter your email address'}
            id='email'
            label='EMAIL'
            value={values.email}
            onChange={handleChange('email')}
          />
        ) : (
          <div>
            <span style={{ fontWeight: 500 }}>Email:</span> {values.email || ''}
          </div>
        )}
      </Grid>
    );
  };

  const renderPassword = () => {
    return (
      <Grid container spacing={2} style={{ marginLeft: 10 }}>
        <Grid item xs={12}>
          <TextPassword
            dataCy={'current-password-input'}
            error={Boolean(!passwordValues.current_password && showPasswordErrors)}
            errorMessage={'Please enter your current password'}
            id='currentpassword'
            label='CURRENT PASSWORD'
            show={showCurrentPassword}
            value={passwordValues.current_password}
            onShow={() => {
              setShowCurrentPassword(!showCurrentPassword);
            }}
            onChange={handleChangePassword('current_password')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextPassword
            dataCy={'change-password-input'}
            error={Boolean(!passwordValues.password && showPasswordErrors)}
            errorMessage={'Please enter your new password'}
            id='password'
            label='PASSWORD'
            show={showPassword}
            value={passwordValues.password}
            onShow={() => {
              setShowPassword(!showPassword);
            }}
            onChange={handleChangePassword('password')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextPassword
            dataCy={'confirm-password-input'}
            error={Boolean(!passwordValues.confirm_password && showPasswordErrors)}
            errorMessage={'Please enter your new password again'}
            id='confirmpassword'
            label='CONFIRM PASSWORD'
            show={showConfirmPassword}
            value={passwordValues.confirm_password}
            onShow={() => {
              setShowConfirmPassword(!showConfirmPassword);
            }}
            onChange={handleChangePassword('confirm_password')}
          />
        </Grid>
      </Grid>
    );
  };

  const renderButtonProfile = () => {
    return (
      <Grid item container justifyContent='flex-end'>
        {isLoading ? (
          <CircularProgress color='secondary' size={15} />
        ) : (
          <ButtonBase onClick={() => setEditProfileSettings(true)}>
            <Typography style={{ color: theme.palette.primary.main, padding: '0 19px', fontWeight: 500, fontSize: 14 }}>
              Edit
            </Typography>
          </ButtonBase>
        )}
      </Grid>
    );
  };

  const renderButtonReset = () => {
    return (
      <Grid item xs={12} style={{ display: 'flex', marginLeft: 12, marginBottom: 16 }}>
        {changePasswordModalOpen ? (
          <Grid item>
            <Button data-cy='change-password-button' onClick={() => handlePasswordUpdate()} variant='contained'>
              {isLoading ? <CircularProgress color='secondary' /> : 'Change Password'}
            </Button>
            <Button
              data-cy='cancel-password-button'
              onClick={() => {
                setAllPasswordValues(defaultPasswordUpdates);
                setChangePasswordModalOpen(false);
              }}
              style={{ margin: '10px 5px' }}
              variant='outlined'
            >
              Cancel
            </Button>
          </Grid>
        ) : (
          <Button data-cy='change-password-button' onClick={() => setChangePasswordModalOpen(true)} variant='outlined'>
            Change Password
          </Button>
        )}
      </Grid>
    );
  };

  const renderConfirmationAlert = () => {
    return (
      <Modal
        isOpen={Boolean(confirmationAlert)}
        onToggle={() => {
          setConfirmationAlert(undefined);
        }}
        onConfirm={() => {
          setConfirmationAlert(undefined);
        }}
        confirmLabel={'Okay'}
        hideCancelButton={true}
        heading={'Profile change'}
      >
        <div style={{ margin: '10px 0 15px' }}>{confirmationAlert}</div>
      </Modal>
    );
  };

  const renderChangePassword = () => {
    return (
      <Grid container>
        {changePasswordModalOpen && renderPassword()}
        {renderButtonReset()}
        {renderConfirmationAlert()}
      </Grid>
    );
  };

  const renderEditProfileModal = () => {
    return (
      <Modal
        isOpen={editProfileSettings}
        onToggle={() => {
          setEditProfileSettings(!setEditProfileSettings);
        }}
        onConfirm={() => handleProfileUpdate()}
        confirmLabel={'Save'}
        heading={'Edit Profile Settings'}
      >
        {renderName()}
        {renderEmail()}
      </Modal>
    );
  };

  const renderChangePasswordModal = () => {
    return (
      <Modal
        isOpen={changePasswordModalOpen}
        onToggle={() => {
          setChangePasswordModalOpen(!changePasswordModalOpen);

          setAllPasswordValues(defaultPasswordUpdates);
        }}
        heading={'Change Password'}
        hideConfirmationButtons
        maxWidth={'sm'}
      >
        {renderChangePassword()}
      </Modal>
    );
  };

  const brandInitials = getInitials({ firstInput: user?.firstName || '', secondInput: user?.lastName || '' });
  const avatarUrl = user?.avatar || '';

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} lg={12} style={{ position: 'relative', marginBottom: 20 }}>
        <Paper style={{ display: 'flex', flexDirection: 'column' }}>
          <Grid item container style={{ justifyContent: 'space-between', marginTop: 25 }}>
            <Grid
              item
              container
              alignItems='center'
              sm={4}
              style={{
                marginLeft: 30
              }}
            >
              <Grid item container alignItems='center'>
                <Avatar
                  src={avatarUrl}
                  alt={`${user?.firstName || ''} ${user?.lastName || ''}`}
                  variant='circular'
                  className={classes.avatar}
                >
                  <Typography
                    style={{
                      fontWeight: 500,
                      fontSize: 16
                    }}
                  >
                    {brandInitials}
                  </Typography>
                </Avatar>
                <Box ml='10px'>
                  <Typography variant='h5'>My Details</Typography>
                </Box>
              </Grid>
              <Grid item>
                <span style={{ flex: 1, fontSize: 16, fontWeight: 600, marginLeft: 10 }}></span>
              </Grid>
            </Grid>
            <Grid item>{renderButtonProfile()}</Grid>
          </Grid>
          {renderName()}
          <Grid container direction='column' spacing={2}>
            {renderEmail()}
          </Grid>
          <Grid container spacing={1} style={{ paddingLeft: 16, paddingBottom: 16 }}>
            {renderChangePassword()}
          </Grid>
        </Paper>
      </Grid>
      {renderEditProfileModal()}
      {renderChangePasswordModal()}
    </Grid>
  );
};

export default EditProfile;

const useStyles = makeStyles()((theme) => ({
  avatar: {
    width: 32,
    height: 32
  }
}));
