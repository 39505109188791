import React, { useState, useEffect, useCallback } from 'react';
import { EditBoxProps } from './MediaKitEditBox';
import Gallery from 'react-photo-gallery';
import sizeOf from 'image-size';
import { makeStyles } from 'tss-react/mui';
import { Grid, Typography, Button, Avatar, Box, ButtonBase, CircularProgress } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import { useFileReader } from 'hooks/useFileReader';
import Slider from 'react-slick';

import { filter, compact, pick, isEmpty } from 'lodash';

import { useDropzone } from 'react-dropzone';

import CloseButton from './CloseButton';

import AddIcon from 'assets/components/AddIcon';

import { ElementContentType, ImageListInput, ImageListContent, ImageListField } from 'types/generated';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const styles = makeStyles()((theme) => ({
  error: { color: 'red' },
  imageEditContainer: { display: 'flex', justifyContent: 'center' },
  imageEditor: { width: '100%' },
  imageSection: {
    backgroundColor: theme.palette.info.light,
    paddingBottom: 10,
    paddingTop: 10,
    marginBottom: 10,
    width: '100%'
  },
  imagePreview: { width: '100%', height: 120 },
  imagePlaceholder: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: 16,
    color: theme.palette.primary.main,
    textAlign: 'center',
    padding: 12
  },
  imagePlaceholderTitle: {
    color: theme.palette.primary.main,
    margin: 4
  },
  galleryImage: {
    position: 'relative',
    '& img': {
      height: 120,
      marginRight: 6,
      marginLeft: 6,
      objectFit: 'contain'
    }
  },
  galleryPreviewContainer: {
    paddingBottom: 10,
    paddingTop: 20,
    borderBottomColor: 'white',
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    width: '100%'
  },
  galleryImagePlaceholder: {
    margin: '0 auto',
    borderStyle: 'dashed',
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
    height: 120,
    width: 120
  },
  galleryImageActions: {
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 1
  },
  placeholderContainer: {
    width: '100%',
    paddingBottom: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 200
  },
  imageUploadSubtitle: {
    fontStyle: 'italic',
    marginTop: 4
  }
}));

function isImageListContent(content?: ElementContentType | null): content is ImageListContent {
  return content && (content as ImageListContent).image_list_content !== undefined ? true : false;
}

function isImageListInput(image?: ImageListInput | ImageListField): image is ImageListInput {
  return image && (image as ImageListInput).compressedFile !== undefined ? true : false;
}

const maximumGallerySize = 12;

const ImageListBox = ({ element: { content }, handleUpdate, isEditing, setIsEditing }: EditBoxProps) => {
  const { classes } = styles();
  const [imageListContent, setImageListContent] = useState<Array<ImageListInput | ImageListField>>([]);
  const { readFile, compressFile, readFileLoading, compressFileLoading } = useFileReader();

  const storedImageListContent = isImageListContent(content) ? content.image_list_content : undefined;

  useEffect(() => {
    if (!isEditing) {
      if (storedImageListContent) {
        setImageListContent(compact(storedImageListContent));
      } else {
        setImageListContent([]);
      }
    }
  }, [storedImageListContent, setImageListContent, isEditing]);

  const dropImage = useCallback(
    (acceptedFiles) => {
      const filePromises = acceptedFiles.slice(0, maximumGallerySize).map(
        (file: File) =>
          new Promise((resolve, reject) => {
            try {
              readFile(file, async (name, blob, encodedString) => {
                const img = encodedString ? Buffer.from(encodedString.split(';base64,')[1], 'base64') : undefined;
                const sizeInfo = img ? sizeOf(img) : undefined;
                const height = sizeInfo?.height;
                const width = sizeInfo?.width;

                let fileDataToReturn: ImageListInput = {
                  file: blob,
                  url: encodedString,
                  width,
                  height
                };
                if (width && width > 800) {
                  const compressedFile = (await compressFile({ file, sizeInfo })) as File;
                  if (compressedFile) {
                    fileDataToReturn = { ...fileDataToReturn, compressedFile };
                  }
                }

                resolve(fileDataToReturn);
              });
            } catch (ex) {
              reject(ex);
            }
          })
      );

      Promise.all(filePromises).then((results) => {
        setImageListContent([...imageListContent, ...results] as ImageListInput[]);
      });
    },
    [readFile, setImageListContent, compressFile, imageListContent]
  );

  const handleRemoveImage = useCallback(
    (index: number, shouldSave?: boolean) => {
      const updatedContent = imageListContent.filter((o, i) => i !== index);
      setImageListContent(updatedContent);
      if (shouldSave) {
        handleUpdate({ ...content, image_list_content: updatedContent });
      }
    },
    [imageListContent, setImageListContent, handleUpdate, content]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: dropImage,
    accept: 'image/jpg, image/jpeg, image/png, image/heic, image/heif',
    maxSize: 100000000
  });

  if (isEditing) {
    return (
      <>
        <Box className={classes.imageSection}>
          <GallerySlider
            classes={classes}
            content={imageListContent}
            compressFileLoading={compressFileLoading}
            onPress={(index) => handleRemoveImage(index)}
          />
          <Box className={classes.imageEditContainer}>
            <div {...getRootProps()} className={classes.imageEditor}>
              <input {...getInputProps()} />

              <Box className={classes.imagePlaceholder}>
                {readFileLoading ? (
                  <CircularProgress />
                ) : (
                  <>
                    <ImageIcon fontSize={'large'} />
                    <Typography
                      className={classes.imagePlaceholderTitle}
                    >{`Drop up to ${maximumGallerySize} images or`}</Typography>
                    <Button variant='outlined' color='primary'>
                      Browse
                    </Button>
                    <Typography variant='caption' className={classes.imageUploadSubtitle}>
                      Accepts JPEG or PNG images up to 100MB
                    </Typography>
                  </>
                )}
              </Box>
            </div>
          </Box>
        </Box>
        <Grid container justifyContent='flex-start'>
          <Button
            variant='outlined'
            color='primary'
            onClick={() => {
              const newContent = {
                ...content,
                image_list_content: imageListContent.slice(0, maximumGallerySize).map((item) => {
                  const keysToUse = item.url?.includes('data:image')
                    ? ['height', 'width', 'file', 'type', 'compressedFile', 'compressedImageFile']
                    : ['url', 'height', 'width', 'file', 'type', 'compressedFile', 'compressedImageFile'];
                  return pick(item, keysToUse);
                })
              };
              const previewContent = {
                ...content,
                image_list_content: imageListContent.slice(0, maximumGallerySize)
              };
              handleUpdate(newContent, previewContent);
            }}
          >
            {`Save & Close`}
          </Button>
        </Grid>
      </>
    );
  }

  return (
    <Box className={classes.galleryPreviewContainer}>
      {imageListContent.length ? (
        <Gallery
          photos={filter(imageListContent, (o) => Boolean(o.url && o.width && o.height)).map((o) => {
            let url = o.url!;
            let height = o.height || 1;
            let width = o.width || 1;
            if (isImageListInput(o)) {
              if (!isEmpty(o.compressedFile)) {
                url = o.compressedFile?.url || '';
                height = o.compressedFile?.height || 1;
                width = o.compressedFile?.width || 1;
              }
            } else {
              if (!isEmpty(o.compressedImageFile)) {
                url = o.compressedImageFile?.url || '';
                height = o.compressedImageFile?.height || 1;
                width = o.compressedImageFile?.width || 1;
              }
            }

            return { src: url, height, width };
          })}
        />
      ) : (
        <ButtonBase className={classes.placeholderContainer} onClick={() => setIsEditing(true)}>
          <AddIcon size={35} strokeWidth={3} />
        </ButtonBase>
      )}
    </Box>
  );
};

const SliderConfig = {
  dots: true,
  dotsClass: 'slider-dots',
  arrows: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  infinite: false
};

const GallerySlider = ({
  content,
  onPress,
  compressFileLoading,
  classes
}: {
  content: Array<ImageListInput>;
  onPress?: (index: number) => void;
  compressFileLoading: boolean;
  classes: any;
}) => {
  return (
    <Slider {...SliderConfig} className='links-slider slider-theme'>
      {padArray(content, 'blank', SliderConfig.slidesToShow).map((o, i) => {
        if (o === 'blank') {
          return (
            <div key={i} className={classes.galleryImage}>
              {i === content.length && compressFileLoading ? (
                <Grid container justifyContent='center' alignItems='center' style={{ height: 120 }}>
                  <CircularProgress />
                </Grid>
              ) : (
                <div className={classes.galleryImagePlaceholder} />
              )}
            </div>
          );
        }
        return (
          <div key={i} className={classes.galleryImage}>
            {onPress && <CloseButton size={20} filledCircle onPress={() => onPress(i)} />}
            <Avatar src={o?.compressedImageFile?.url || o.url} variant='square' className={classes.imagePreview} />
          </div>
        );
      })}
    </Slider>
  );
};

const padArray = (array: Array<any>, content: any, length: number) => {
  let returnArray: any[] = [];
  const size = Math.max(array.length, length);

  for (let i = 0; i < size; i++) {
    if (array[i]) {
      returnArray.push(array[i]);
    } else {
      returnArray.push(content);
    }
  }

  return returnArray;
};

export default ImageListBox;
