import Box from '@mui/material/Box';
import { History } from './History';
import { Header } from './Header';
import { SavedTemplates } from './SavedTemplates';
import { Walkthrough } from './Walkthrough';
import { useUserConfig } from 'hooks/useUserConfig';
import { WALKTHROUGHS } from 'types/custom';
import SimpleTabs from 'components/settings/SimpleTabs';
import AuditReports from './AuditReports';
import { useParams } from 'react-router-dom';

export const MediaKitsPage = () => {
  const params = useParams<{ activeTab?: string }>();
  const { config } = useUserConfig();

  const hasWalkedMediaKits = config?.walkthrough?.includes(WALKTHROUGHS.MEDIA_KITS);

  if (!hasWalkedMediaKits) {
    return <Walkthrough />;
  }

  const tabContent = [
    {
      label: 'Audit Reports',
      param: 'audit-reports',
      content: <AuditReports />
    },
    {
      label: 'Media Kits',
      param: 'templates',
      content: <SavedTemplates />
    },
    {
      label: 'History',
      param: 'history',
      content: <History />
    }
  ];

  return (
    <Box px={5} width={'100%'} maxWidth={930}>
      <Header />
      <SimpleTabs tabContent={tabContent} page='media-kits' availableParam={params.activeTab || 'audit-reports'} />
    </Box>
  );
};
