import React, { useState, useEffect, useMemo } from 'react';
import { Box, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Modal from 'components/Modal';
import { User, Profile, GetMyUserUsageDocument } from 'types/generated';
import ReportDatePicker from './SelectorFields/ReportDatePicker';
import ReportTrackers from './SelectorFields/ReportTracker';
import { cloneDeep, isEmpty, compact } from 'lodash';
import {
  useCreateReportConfigMutation,
  useUpdateReportConfigMutation,
  useGetReportConfigListLazyQuery,
  GetReportConfigListDocument,
  SupplementMediaInput,
  SupplementMedia,
  ReportConfigInput
} from 'types/generated';
import { formatError } from 'lib/formatStrings';
import { Error_Type_Enum, useErrorHandlerHook } from 'hooks/useErrorCatchHook';
import FlashPreventLoading from './FlashPreventLoading';
import ProfileSelectSlider from 'components/Dashboard/AccountPerformance/ProfileSelectSlider';
import ContactSelector from 'components/Fields/ContactSelector';
import SupplementMediaField from 'components/Reports/SupplementMediaField';
export interface FormProps {
  theme?: any;
  payload: ReportConfigInput;
  setPayload: (payload: ReportConfigInput) => void;
}

const errorStyle = { color: 'red', fontSize: '10px' };

interface IReportEditorModel {
  isOpen: boolean;
  toggleCreationModal: (reportId: string) => void;
  isEditingExisting: boolean;
  selectedClient?: User;
  reportId?: string;
  updateNewestReportId: (reportId: string) => void;
}

interface FormError {
  [key: string]: string;
}

const ReportEditorModel: React.FC<IReportEditorModel> = (props) => {
  const { classes } = useClasses();
  const { reportId } = props;
  const newReport_flag = useMemo(() => Boolean(!reportId), [reportId]);
  const { successHandler } = useErrorHandlerHook();

  // form data
  const [payload, setPayload] = useState<ReportConfigInput>({
    report_name: '',
    profileIds: [],
    mentions: [],
    hashtags: [],
    collaborationId: null,
    clientId: props.selectedClient?._id || '',
    channels: []
  });

  const [supplementMedia, setSupplementMedia] = useState<Array<SupplementMedia | SupplementMediaInput>>([]);

  const [error, setError] = useState<FormError | undefined>();

  const [saveReportConfig, { loading: saveLoading, error: saveError }] = useCreateReportConfigMutation({
    refetchQueries: [{ query: GetMyUserUsageDocument }],
    awaitRefetchQueries: true
  });
  const [updateReportConfig, { loading: updateLoading, error: updateError }] = useUpdateReportConfigMutation();
  const [getExistingReport, { data: reportData, loading: reportLoading, error: reportError }] =
    useGetReportConfigListLazyQuery();

  // initialization: If report id exists, get the report data from the backend
  useEffect(() => {
    (async () => {
      if (!newReport_flag && props.isOpen) {
        await getExistingReport({
          variables: {
            id: reportId
          }
        });
      }
      if (newReport_flag) {
        formDataAndOperations._resetForm();
      }
    })();
    // Disabling warning to prevent render loop
    // eslint-disable-next-line
  }, [reportId, getExistingReport, newReport_flag]);

  // initialization: if the report is not new, mapping data to the state
  useEffect(() => {
    if (reportData?.getReportConfigList?.length && reportId) {
      formDataAndOperations._mappingDataFromExistingReportData(reportData.getReportConfigList[0]);
    }
    // Disabling warning to prevent render loop
    // eslint-disable-next-line
  }, [reportData, reportId]);

  const errorMessage = reportError
    ? `Existing report error: ${formatError(reportError)}`
    : updateError
    ? `Update error: ${formatError(updateError)}`
    : saveError
    ? `Save error: ${formatError(saveError)}`
    : error
    ? 'Please fix above errors'
    : null;

  const selectedClientName = props.selectedClient?.companyName;

  const formDataAndOperations = {
    _resetForm: () => {
      setPayload({
        report_name: '',
        report_start: undefined,
        report_end: undefined,
        profileIds: [],
        mentions: [],
        hashtags: [],
        collaborationId: '',
        clientId: '',
        channels: []
      });
      setSupplementMedia([]);
    },
    _mappingDataFromExistingReportData: (report: any) => {
      setPayload({
        report_name: report.reportName,
        report_start: report.reportStart,
        report_end: report.reportEnd,
        profileIds: report.profileIds || [],
        mentions: report.mentions || [],
        hashtags: report.hashtags || [],
        channels: report.channels || [],
        collaborationId: report.collaborationId,
        clientId: report.clientId
      });
    },
    _toggleModal: () => {
      props.toggleCreationModal('');
    },
    _formValidationAndSubmission: async () => {
      try {
        let payloadToSend: any = cloneDeep(payload);
        const noDates = !payload.report_start || !payload.report_end;
        const noProfiles = isEmpty(payload.profileIds) && !payload.collaborationId;

        if (!payload.report_name) {
          setError({ report_name: 'Please add a name for the report' });
          throw new Error('Validation failed');
        } else if (noDates) {
          setError({ dates: 'Please add a valid date range' });
          throw new Error('Validation failed');
        } else if (noProfiles) {
          setError({ profiles: 'Please add at least one' });
          throw new Error('Validation failed');
        } else {
          setError(undefined);
          if (!newReport_flag && reportId) {
            const updateResponse = await updateReportConfig({
              variables: {
                config: payloadToSend,
                reportId
              },
              refetchQueries: [
                {
                  query: GetReportConfigListDocument,
                  variables: {
                    id: reportId
                  }
                }
              ]
            });
            const updateResponseConfig = updateResponse?.data?.updateReportConfig;
            if (updateResponseConfig && updateResponseConfig.success) {
              successHandler(Error_Type_Enum.report_update_success);
            }
            formDataAndOperations._toggleModal();
          } else {
            const createResponse = await saveReportConfig({ variables: { config: payloadToSend } });
            if (createResponse?.data?.createReportConfig?._id) {
              props.updateNewestReportId(createResponse.data.createReportConfig._id);
              successHandler(Error_Type_Enum.report_create_success);
              formDataAndOperations._toggleModal();
            }
          }
        }
      } catch (ex) {
        const error = ex as any;
        console.warn(error.message);
      }
    },
    _onProfileSelect: (profile?: Profile | null) => {
      if (profile?._id) {
        const profileList = cloneDeep(payload.profileIds);
        if (profileList?.includes(profile._id)) {
          const updatedProfileList = profileList.filter((item) => item !== profile._id);
          setPayload({
            ...payload,
            profileIds: updatedProfileList
          });
        } else if (payload.profileIds) {
          setPayload({
            ...payload,
            profileIds: [...payload.profileIds, profile._id]
          });
        } else {
          setPayload({
            ...payload,
            profileIds: [profile._id]
          });
        }
      }
    },
    _onClientSelect: (clientId: string | null | undefined) => {
      setPayload({
        ...payload,
        clientId: clientId
      });
    },
    heading: `${!newReport_flag ? 'Edit Report' : 'New Report'} ${
      selectedClientName ? ` for ${selectedClientName}` : ''
    }`,
    confirmLabel: newReport_flag ? 'Generate' : 'Update'
  };

  //  report name component
  const report_name_field = (
    <>
      <Grid item xs={12} style={{ margin: '15px 0 15px' }}>
        <TextField
          variant='standard'
          data-cy={`report_name-entry-field`}
          value={payload.report_name}
          onChange={(event) => {
            if (typeof event.target.value === 'string') {
              setPayload({ ...payload, report_name: event.target.value });
            }
          }}
          label={`Report Name *`}
          fullWidth={true}
          error={false}
        />
        {error && error.report_name && <Typography style={errorStyle}>{error.report_name}</Typography>}
      </Grid>
    </>
  );

  // report Date picker
  const report_date_field = (
    <Box className={classes.datePickerWrapper}>
      <Grid item xs={12} data-cy='date-picker-container'>
        <div style={{ fontSize: '1em', opacity: 0.5, marginTop: 5 }}>Report Dates * </div>
        <Box style={{ width: '100%', backgroundColor: 'pink' }}>
          <ReportDatePicker payload={payload} setPayload={setPayload} />
        </Box>

        {error && error.dates && <Typography style={errorStyle}>{error.dates}</Typography>}
      </Grid>
    </Box>
  );

  // report tracker
  const report_tracker_field = (
    <Grid item xs={12}>
      <ReportTrackers payload={payload} setPayload={setPayload} />
    </Grid>
  );

  // error message component
  const error_message_component = (
    <>
      {errorMessage && (
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginBottom: 10 }}>
          <Typography style={errorStyle}>{errorMessage}</Typography>
        </Grid>
      )}
    </>
  );

  // profile selector horizontal bar
  const profile_selector_slider = (
    <Box className={classes.profileSelectWrapper}>
      <ProfileSelectSlider
        onSelect={(profile) => formDataAndOperations._onProfileSelect(profile)}
        value={payload.profileIds ? compact(payload.profileIds) : undefined}
      />
    </Box>
  );

  // contact selector
  const contact_selector = (
    <>
      <ContactSelector
        setSelectedId={(id) => (id ? formDataAndOperations._onClientSelect(id) : {})}
        selectedId={payload.clientId || undefined}
      />
    </>
  );

  return (
    <Modal
      isOpen={props.isOpen}
      onToggle={() => {
        formDataAndOperations._toggleModal();
      }}
      heading={formDataAndOperations.heading}
      maxWidth={'md'}
      confirmLabel={formDataAndOperations.confirmLabel}
      onConfirm={formDataAndOperations._formValidationAndSubmission}
    >
      {!newReport_flag && (
        <FlashPreventLoading loadingStatus={Boolean(saveLoading || updateLoading || reportLoading)} />
      )}
      {saveLoading ? (
        <Box textAlign='center'>
          <CircularProgress color='primary' />
        </Box>
      ) : null}

      <Grid container xs={12} className={classes.modal}>
        {report_name_field}
        {contact_selector}
        {report_date_field}
        {profile_selector_slider}
        {report_tracker_field}
        <Grid item xs={12} style={{ paddingBottom: 15, paddingTop: 15 }}>
          <Typography style={{ fontWeight: 200, fontSize: 16 }}>Manually Add Reels</Typography>
        </Grid>
        <Grid item xs={12}>
          <SupplementMediaField reportConfigId={reportId} value={supplementMedia} onChange={setSupplementMedia} />
        </Grid>
        {error_message_component}
      </Grid>
    </Modal>
  );
};

const useClasses = makeStyles()((theme) => ({
  modal: {
    width: 585,
    minHeight: 490,
    position: 'relative',
    zIndex: 10,
    overflowX: 'hidden',
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  modalLoading: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  profileSelectWrapper: {
    overflowX: 'scroll',
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  datePickerWrapper: {
    position: 'relative',
    zIndex: 99999,
    width: '100%'
  }
}));

export default ReportEditorModel;
