import React from 'react';

interface IEmbeddedInstagramMedia {
  embeddedMediaId: string;
  width?: number;
  disableClick?: boolean;
}

const DEFAULT_WIDTH = 210;
const MEDIA_ASPECT_RATIO = 16 / 9;
const MEDIA_MARGIN_WIDTH_RATIO = 9 / 42;

const EmbeddedInstagramMedia = ({ embeddedMediaId, width, disableClick }: IEmbeddedInstagramMedia) => {
  const widthToUse = width || DEFAULT_WIDTH;
  const heightToUse = widthToUse * MEDIA_ASPECT_RATIO;
  const fullMediaHeight = heightToUse + 54;
  const leftMargin = widthToUse * MEDIA_MARGIN_WIDTH_RATIO;
  const fullMediaWidth = leftMargin * 2 + widthToUse;

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div
        style={{
          position: 'relative',
          overflow: 'hidden',
          height: heightToUse,
          width: widthToUse,
          pointerEvents: disableClick ? 'none' : undefined
        }}
      >
        <iframe
          title='Embedded Instagram Media'
          style={{
            position: 'absolute',
            width: fullMediaWidth,
            top: heightToUse - fullMediaHeight,
            left: 0 - leftMargin,
            border: 'none',
            height: fullMediaHeight
          }}
          src={`https://www.instagram.com/reel/${embeddedMediaId}/embed`}
        />
      </div>
    </div>
  );
};

export default EmbeddedInstagramMedia;
