import { makeStyles } from 'tss-react/mui';
import { Grid, Button, lighten } from '@mui/material';
import PinkMedal from 'assets/icons/pink-medal.svg';
import GreyMedal from 'assets/icons/grey-medal.svg';
import BlueSmallMedal from 'assets/icons/blue-small-medal.svg';
import { Profile } from 'types/generated';
import AppConfig from 'config/appConfig';
import useSubscribePopupCtx from 'context/useSubscribePopupCtx';

const PerformanceBadge = ({ profile }: { profile: Profile }) => {
  const { classes } = styles();
  const report = profile?.latestPerformanceReport;
  const { openSubscribePopup } = useSubscribePopupCtx();

  if (!report) {
    return null;
  }

  const selectedProfilePercentile = report.performance?.percentile || 0;
  const isLimited = report.isLimited;

  return (
    <Grid container alignItems='center' wrap='nowrap' className={classes.containerStyle}>
      <Grid
        item
        container
        justifyContent='center'
        alignItems='center'
        style={{
          position: 'relative',
          height: 48,
          width: 48,
          marginRight: 10
        }}
      >
        <img
          src={
            selectedProfilePercentile <= 25 ? PinkMedal : selectedProfilePercentile <= 50 ? BlueSmallMedal : GreyMedal
          }
          alt='Performance badge'
          style={{ height: 48, width: 48 }}
        />
        <Grid
          item
          style={{
            color: '#743ED8',
            fontWeight: 'bold',
            textAlign: 'center',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        >
          {selectedProfilePercentile <= 50 ? 'TOP' : 'LOW'}
          <br />
          <span style={{ filter: isLimited ? 'blur(6px)' : '' }}>
            {isLimited ? ` Kitly` : ` ${selectedProfilePercentile <= 50 ? selectedProfilePercentile : 50}%`}
          </span>
        </Grid>
      </Grid>
      <Grid item container xs={12} justifyContent='space-between'>
        <div style={{ display: 'flex', justifyContent: 'space-between', flexGrow: 1 }}>
          <div>
            {`Compared to other Kitly users, this account performed in the `}
            <span style={{ fontWeight: 'bold' }}>{`${selectedProfilePercentile <= 50 ? 'top' : 'lower'}`}</span>
            <span style={{ filter: isLimited ? 'blur(6px)' : '' }}>
              {isLimited ? ` Kitly` : ` ${selectedProfilePercentile <= 50 ? selectedProfilePercentile : 50}%.`}
            </span>
            <span
              onClick={() => {
                const msg = {
                  clickFrom: 'VIEW',
                  payload: report._id
                };
                window.parent.postMessage(JSON.stringify(msg), '*');
                window.open(`${AppConfig.MEDIAKIT_URL}/#/performance-report/${report._id}`, '_blank');
              }}
              className={classes.linkStyle}
            >
              View
            </span>
          </div>
          {isLimited && (
            <div style={{ marginLeft: 10 }}>
              <Button
                onClick={() => openSubscribePopup()}
                variant='contained'
                classes={{ root: classes.buttonHover }}
                size='small'
              >
                Upgrade
              </Button>
            </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

const styles = makeStyles()((theme) => ({
  containerStyle: {
    backgroundColor: '#F59F9F20',
    borderRadius: 4,
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.12)',
    marginBottom: 20,
    padding: 13
  },
  linkStyle: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    textDecoration: 'none',
    marginLeft: 4,
    fontWeight: 700
  },
  buttonHover: {
    '&:hover': {
      background: lighten(theme.palette.primary.main, 0.33)
    }
  }
}));

export default PerformanceBadge;
