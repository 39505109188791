import React, { useState, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';
import ButtonBase from '@mui/material/ButtonBase';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { lighten } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Brand, isContact, useBrandRequest } from 'hooks/useBrandRequest';
import createDataContext from 'lib/createDataContext';
import { formatError } from 'lib/formatStrings';
import isEmpty from 'lodash/isEmpty';
import { useMatch, useParams, useNavigate } from 'react-router-dom';
import { BrandDetails } from './BrandDetails';
import { BrandList } from './BrandList';
import SimpleTabs from '../../settings/SimpleTabs';
import { Header } from './Header';
import { NoData } from './NoData';
import { InviteDialog } from 'components/Shared/InviteDialog';
import ContentfulAdBanner from 'components/Dashboard/ContentfulAdBanner';

const defaultState = {
  avatar: null,
  companyName: '',
  email: '',
  editingId: undefined,
  contactUpdates: {}
};

export const { Context, Provider } = createDataContext(
  (state, action) => {
    switch (action.type) {
      case 'SET_AVATAR':
        return { ...state, avatar: action.payload };
      case 'SET_COMPANY_NAME':
        return { ...state, companyName: action.payload };
      case 'SET_EMAIL':
        return { ...state, email: action.payload };
      case 'SET_EDITING_ID':
        return { ...state, editingId: action.payload };
      case 'SET_CONTACT_UPDATES':
        return { ...state, contactUpdates: action.payload };
      case 'CLEAR_ALL':
        return defaultState;
      default:
        return state;
    }
  },
  {
    setAvatar: (dispatch: any) => (payload: any) => dispatch({ type: 'SET_AVATAR', payload }),
    setCompanyName: (dispatch: any) => (payload: any) => dispatch({ type: 'SET_COMPANY_NAME', payload }),
    setEmail: (dispatch: any) => (payload: any) => dispatch({ type: 'SET_EMAIL', payload }),
    setEditingId: (dispatch: any) => (payload: any) => dispatch({ type: 'SET_EDITING_ID', payload }),
    setContactUpdates: (dispatch: any) => (payload: any) => dispatch({ type: 'SET_CONTACT_UPDATES', payload }),
    clearAll: (dispatch: any) => () => dispatch({ type: 'CLEAR_ALL' })
  },
  defaultState
);

interface BrandDirectory {
  [key: string]: Brand;
}

export const BrandsPage = () => {
  const { contactId } = useParams<{ contactId?: string }>();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const params = useParams<{ activeTab?: string }>();
  const activeTab = params?.activeTab || 'connections';
  const { invites, loading, error } = useBrandRequest();

  const brandDirectory = useMemo(() => {
    let directory: BrandDirectory = {};

    invites.forEach((o) => {
      if (isContact(o)) {
        if (o._id) {
          directory[o._id] = o;
        }
      } else if (o.contact?._id) {
        directory[o.contact._id] = o.contact;
      }
    });

    return directory;
  }, [invites]);

  const selectedBrand = useMemo(() => {
    if (contactId) {
      return brandDirectory[contactId];
    }
  }, [brandDirectory, contactId]);

  const startWithInviteModal = useMatch('/my-brands/invite');

  const [isInviteModalOpen, setIsInviteModalOpen] = useState(Boolean(startWithInviteModal));

  const NavList = () => (
    <Grid item xs={12} className={classes.navlist}>
      <ButtonBase onClick={() => navigate('/my-brands')}>
        <Typography variant='subtitle1' className={classes.subtitle}>
          Brands
        </Typography>
      </ButtonBase>
      {selectedBrand && (
        <Typography variant='caption' className={classes.navText}>
          &nbsp;/{' '}
          {isContact(selectedBrand)
            ? selectedBrand.companyName
            : selectedBrand.contact?.companyName || selectedBrand.brand?.companyName}
        </Typography>
      )}
    </Grid>
  );

  const Loader = () => (
    <Grid item xs={12} className={`${classes.centred} ${classes.loaderContainer}`}>
      <CircularProgress />
    </Grid>
  );

  const ErrorMessage = () => (
    <Grid item xs={12} className={`${classes.centred} ${classes.loaderContainer}`}>
      {formatError(error)}
    </Grid>
  );

  return (
    <div>
      <div
        style={{
          paddingLeft: window.matchMedia('(max-width: 425px)').matches ? 0 : 20,
          paddingRight: window.matchMedia('(max-width: 425px)').matches ? 0 : 20
        }}
      >
        <div>
          <Grid container style={{ width: 1000, margin: '0 auto' }}>
            <NavList />
            {loading ? (
              <Loader />
            ) : error ? (
              <ErrorMessage />
            ) : isEmpty(invites) ? (
              <NoData />
            ) : selectedBrand?._id ? (
              <BrandDetails brandId={selectedBrand._id} />
            ) : (
              <Grid item xs={12}>
                <Header />
                <ContentfulAdBanner
                  adLocation='my-brands'
                  style={{ marginTop: 20 }}
                  skeletonStyle={{ marginTop: 20 }}
                />
                <SimpleTabs
                  tabContent={[
                    {
                      label: 'Connections List',
                      param: 'connections',
                      content: (
                        <BrandList
                          setSelectedBrand={(o) => {
                            navigate(`/my-brands/view/${o._id}`);
                          }}
                        />
                      )
                    },
                    {
                      label: 'Archived',
                      param: 'archived',
                      content: (
                        <BrandList
                          displayArchived
                          setSelectedBrand={(o) => {
                            navigate(`/my-brands/view/${o._id}`);
                          }}
                        />
                      )
                    }
                  ]}
                  scrollButtons='auto'
                  variant='scrollable'
                  availableParam={activeTab}
                  page={'collabs'}
                />
              </Grid>
            )}
          </Grid>
        </div>
      </div>
      <InviteDialog isOpen={isInviteModalOpen} onClose={() => setIsInviteModalOpen(false)} />
    </div>
  );
};

const useStyles = makeStyles()((theme) => {
  return {
    navlist: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      flexWrap: 'nowrap'
    },
    navText: {
      fontWeight: 200,
      color: lighten(theme.palette.common.black, 0.33),
      letterSpacing: '0.5px'
    },
    centred: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    loaderContainer: {
      height: '85vh'
    },
    button: {
      margin: 5
    },
    subtitle: {
      fontWeight: 500
    },
    tab: {
      padding: 0,
      '& span': {
        fontSize: 10,
        alignItems: 'flex-start'
      }
    }
  };
});
