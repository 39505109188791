import React from 'react';
import Modal from 'components/Modal';
import FlashPreventLoading from './FlashPreventLoading';
import ShareFields, { SharePayload } from '../../../Fields/ShareFields';
import { useShareReportToContactMutation } from 'types/generated';
import { Error_Type_Enum, useErrorHandlerHook } from 'hooks/useErrorCatchHook';
import PdfButtons from '../../../Fields/PdfButtons';

interface IShareReportModal {
  reportId: string;
  isOpen: boolean;
  toggleShareModal: (reportId: string) => void;
}
const ShareReportModal: React.FC<IShareReportModal> = (props) => {
  const [shareReport, { loading: shareLoading }] = useShareReportToContactMutation();
  const { successHandler, apolloHandler } = useErrorHandlerHook();

  const handleShareReport = async (payload: SharePayload) => {
    try {
      const shareResponse = await shareReport({
        variables: {
          reportConfigId: props.reportId,
          ...payload
        }
      });

      if (shareResponse?.data?.shareReportToContact?.success) {
        successHandler(Error_Type_Enum.report_share_success);
        props.toggleShareModal('');
      } else {
        throw new Error('Error with sharing report');
      }
    } catch (ex) {
      const error = ex as any;
      apolloHandler(error);
    }
  };

  const loading_component = <>{shareLoading && <FlashPreventLoading loadingStatus={true} />}</>;
  return (
    <Modal
      isOpen={props.isOpen}
      onToggle={() => {
        props.toggleShareModal('');
      }}
      hideConfirmButton={true}
      hideCancelButton={true}
      subConfirmationContent={<PdfButtons reportConfigId={props.reportId || ''} />}
      heading='Share Report'
      confirmLabel='Share'
      maxWidth={'sm'}
    >
      {loading_component && shareLoading}
      <FlashPreventLoading loadingStatus={shareLoading} />
      <ShareFields
        onShare={handleShareReport}
        onDismiss={() => {
          props.toggleShareModal('');
        }}
        loading={shareLoading}
        sendNewContactRequest={true}
      />
    </Modal>
  );
};

export default ShareReportModal;
