import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Profile, useGetSingleProfileForYtQuery } from 'types/generated';
import { Loader } from 'components/Loader';
import YoutubeVideoSummary from './YoutubeVideoSummary';
import MediaKitCard from 'components/Dashboard/Cards/MediaKitCard';

interface IYouTubeInsights {
  channelId: string;
}
interface IiInsightState {
  selectedIndex: number;
  profile?: Profile | null;
  YTMedia?: any;
}

const YouTubeInsights: React.FC<IYouTubeInsights> = (props) => {
  const { data, error, loading } = useGetSingleProfileForYtQuery({ variables: { id: props.channelId } });
  const [YTInsightState, setYTInsightState] = useState<IiInsightState>({
    selectedIndex: 0,
    profile: undefined,
    YTMedia: undefined
  });
  // initialization
  useEffect(() => {
    setYTInsightState({
      ...YTInsightState,
      profile: data?.getSingleProfile,
      YTMedia: data?.recentYoutubeMedia
    });
    // eslint-disable-next-line
  }, [data]);

  // LOADING COMPONENT
  const loading_component = (
    <>
      {loading && (
        <Box display='flex' justifyContent='center' alignItems='center' mt={4}>
          <Loader />
        </Box>
      )}
    </>
  );

  // VIDEO CONTENT
  const yt_video_content = Boolean(YTInsightState?.YTMedia) && (
    <>{YTInsightState.selectedIndex === 0 && <YoutubeVideoSummary stats={YTInsightState.profile?.channelStats} />}</>
  );

  return (
    <div>
      {loading_component}
      {(!loading || Boolean(error)) && (
        <>
          <Grid container>
            <Grid item sm={7} xs={12}>
              {
                // account performance
              }
              <>{yt_video_content}</>
            </Grid>
            <Grid item sm={4} xs={12}>
              {
                // media kits
              }
              <MediaKitCard />
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

export default YouTubeInsights;
