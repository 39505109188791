import React, { useState, useEffect } from 'react';

import { makeStyles } from 'tss-react/mui';
import { Typography, Button, Box, Grid, ButtonBase } from '@mui/material';
import { get } from 'lodash';

import { EditBoxProps } from './MediaKitEditBox';

import AddIcon from 'assets/components/AddIcon';

import { ElementContentType, TextContent } from 'types/generated';

const styles = makeStyles()((theme) => ({
  error: { color: 'red' },
  textEditor: { padding: 15, minHeight: 300, width: '100%', boxSizing: 'border-box' },
  textPreview: { marginTop: 12, fontSize: 14 },
  placeholderPreview: { marginTop: 12, fontSize: 14, fontStyle: 'italic' },
  previewContainer: { marginTop: 10, fontSize: 14, padding: 10, paddingBottom: 20 },
  placeholderContainer: {
    width: '100%',
    paddingBottom: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 200
  }
}));

function isTextContent(content?: ElementContentType | null): content is TextContent {
  return content && (content as TextContent).text_content !== undefined ? true : false;
}

const PlainTextBox = ({ element: { content, title }, handleUpdate, isEditing, setIsEditing }: EditBoxProps) => {
  const { classes } = styles();
  const [textContent, setTextContent] = useState('');

  const storedTextContent = isTextContent(content) ? content.text_content : undefined;

  useEffect(() => {
    if (!isEditing) {
      setTextContent(storedTextContent || '');
    }
  }, [storedTextContent, setTextContent, isEditing]);

  if (isEditing) {
    return (
      <Box>
        <textarea
          className={classes.textEditor}
          value={textContent}
          onChange={(event) => {
            setTextContent(get(event, 'target.value') || '');
          }}
        />
        <Grid container justifyContent='flex-start'>
          <Button
            variant='outlined'
            color='primary'
            onClick={() => {
              handleUpdate({ ...content, text_content: textContent });
            }}
            style={{ marginTop: 20 }}
          >
            {`Save & Close`}
          </Button>
        </Grid>
      </Box>
    );
  }

  return (
    <Box className={classes.previewContainer}>
      {textContent ? (
        <Typography align='center' className={classes.textPreview}>
          {textContent}
        </Typography>
      ) : (
        <ButtonBase className={classes.placeholderContainer} onClick={() => setIsEditing(true)}>
          <AddIcon size={35} strokeWidth={3} />
        </ButtonBase>
      )}
    </Box>
  );
};

export default PlainTextBox;
