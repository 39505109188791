import { useEffect, useMemo } from 'react';
import theme from 'config/muiTheme';
import moment from 'moment';
import {
  ButtonBase,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import {
  BraintreeTransaction,
  StripeCharge,
  useGetMyUserPaymentMethodsQuery,
  useGetMyUserStripeChargesQuery
} from 'types/generated';
import { useErrorHandlerHook } from 'hooks/useErrorCatchHook';
import { compact, isEmpty } from 'lodash';
import AppConfig from '../../config/appConfig';
import { isStripeCharge } from 'lib/type_guards';

export const ChargeHistory = () => {
  const { classes } = useStyles();
  const { apolloHandler } = useErrorHandlerHook();

  const { data, loading: stripeChargesLoading, error: stripeChargesError } = useGetMyUserStripeChargesQuery();

  const {
    data: userTransactionHistoryData,
    loading: braintreeChargesLoading,
    error: braintreeChargesError
  } = useGetMyUserPaymentMethodsQuery();
  const user = userTransactionHistoryData?.myUser;
  const braintreeCharges = useMemo(
    () => (user?.braintreeCustomer?.transactions ? compact(user.braintreeCustomer.transactions) : []),
    [user?.braintreeCustomer?.transactions]
  );

  useEffect(() => {
    if (stripeChargesError) {
      apolloHandler(stripeChargesError);
    }
  }, [stripeChargesError, apolloHandler]);

  useEffect(() => {
    if (braintreeChargesError) {
      apolloHandler(braintreeChargesError);
    }
  }, [braintreeChargesError, apolloHandler]);

  const stripeCharges = useMemo(
    () => (data?.myUser?.stripeCustomer?.charges ? compact(data.myUser.stripeCustomer.charges) : []),
    [data]
  );

  const chargesCombinedAndSortedByTimestamp = useMemo(() => {
    const combinedCharges: (BraintreeTransaction | StripeCharge)[] = [...braintreeCharges, ...stripeCharges];
    if (isEmpty(combinedCharges)) return [];
    const unifyChargesForSorting = combinedCharges.map((o) => {
      if (isStripeCharge(o)) {
        return {
          provider: 'stripe',
          timestamp: o.created,
          amount: o.amount ? (o.amount / 100).toFixed(2) : 0,
          paid: o.paid,
          buttonHref: o.receipt_url,
          buttonText: `Stripe_receipt_${moment(o.created, 'ms').format('MMMM')}`
        };
      } else {
        const invoiceDownloadLink = `${AppConfig.API_URL}invoice/download/${btoa(
          JSON.stringify({ transactionId: o.id, userId: user?._id })
        )}`;
        return {
          provider: 'braintree',
          timestamp: o.createdAt,
          amount: o.amount ? o.amount : 0,
          paid: o.status === 'settled',
          buttonHref: invoiceDownloadLink,
          buttonText: `Kitly_Invoice_${moment(o.createdAt).format('MMMM')}`
        };
      }
    });

    const sortedCharges = unifyChargesForSorting.sort((a, b) => {
      const aTime = moment(a.timestamp).toDate().valueOf();
      const bTime = moment(b.timestamp).toDate().valueOf();

      return bTime - aTime;
    });

    return sortedCharges;
  }, [braintreeCharges, stripeCharges, user?._id]);

  if (stripeChargesLoading || braintreeChargesLoading) {
    return (
      <Grid container justifyContent='center' alignItems='center' style={{ height: 60 }}>
        <Grid>
          <CircularProgress style={{ color: theme.palette.primary.main }} />
        </Grid>
      </Grid>
    );
  }

  if (!stripeCharges.length && !braintreeCharges.length) {
    return (
      <Grid container direction='column'>
        <Grid item>No past charges found</Grid>
      </Grid>
    );
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={`${classes.tableHeader}`}>Date</TableCell>
            <TableCell className={`${classes.tableHeader}`}>Receipt</TableCell>
            <TableCell className={`${classes.tableHeader}`}>Amount</TableCell>
            <TableCell className={`${classes.tableHeader}`}>Paid</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {chargesCombinedAndSortedByTimestamp.map((o, i) => {
            return (
              <TableRow key={`payment-method-${i}`} style={{ marginBottom: 10 }}>
                <TableCell>{moment(o.timestamp).format('DD/MM/YYYY')}</TableCell>
                <TableCell>
                  {o.buttonHref && (
                    <ButtonBase target='_blank' href={o.buttonHref} style={{ color: theme.palette.primary.main }}>
                      {o.buttonText}
                    </ButtonBase>
                  )}
                </TableCell>
                <TableCell>{o.amount}</TableCell>
                <TableCell>{o.paid ? 'Yes' : 'No'}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const useStyles = makeStyles()(() => ({
  tableHeader: {
    fontSize: 12,
    fontWeight: 'lighter'
  },
  tableBody: {
    '& > tr:last-child td': {
      borderBottom: 'none'
    }
  }
}));
