import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import {
  useCreateMediaKitConfigMutation,
  GetMediaKitConfigListDocument,
  GetMyUserUsageDocument
} from 'types/generated';
import useSubscribePopupCtx, { PlanLimitType } from 'context/useSubscribePopupCtx';
import { useWalkthrough } from 'hooks/useWalkthrough';
import { WALKTHROUGHS } from 'types/custom';

interface Props {
  buttonText: string;
  buttonVariant: ButtonProps['variant'];
  buttonSize: ButtonProps['size'];
  alignItems: 'center' | 'flex-end' | 'flex-start';
  justifyContent?: 'center' | 'flex-end' | 'flex-start';
}

export const NewMediaKitButton = ({ buttonText, buttonVariant, buttonSize, alignItems, justifyContent }: Props) => {
  const navigate = useNavigate();
  const { executeLimitedAction } = useSubscribePopupCtx(PlanLimitType.MEDIA_KIT_USAGE);
  const { handleWalkthrough: updateMediaKitWalkthrough } = useWalkthrough(WALKTHROUGHS.MEDIA_KITS);

  const [createKit, { loading: createLoading }] = useCreateMediaKitConfigMutation({
    onCompleted: (createData) => {
      if (createData.createMediaKitConfig) {
        return navigate(`/mediakit-edit/${createData.createMediaKitConfig}?action=create`);
      }
    },
    refetchQueries: [{ query: GetMediaKitConfigListDocument }, { query: GetMyUserUsageDocument }],
    awaitRefetchQueries: true
  });

  return (
    <Grid container justifyContent={justifyContent || 'center'} alignItems={alignItems}>
      <Grid item>
        <Button
          size={buttonSize}
          variant={buttonVariant}
          onClick={() => {
            updateMediaKitWalkthrough();
            executeLimitedAction(createKit);
          }}
        >
          {Boolean(createLoading) && <CircularProgress size={20} style={{ marginRight: 7 }} />} {buttonText}
        </Button>
      </Grid>
    </Grid>
  );
};

NewMediaKitButton.defaultProps = {
  buttonText: 'New Media Kit',
  buttonVariant: 'outlined',
  buttonSize: 'small'
};
