import { useMemo } from 'react';
import { useGetContactsQuery, Contact, Ambassador } from 'types/generated';

export type Brand = Ambassador | Contact;

export function isContact(arg?: Brand | null): arg is Contact {
  return arg?.__typename === 'Contact';
}

export const useBrandRequest = () => {
  const { data: queryData, loading, error, refetch } = useGetContactsQuery();

  const contactList = useMemo(() => {
    let allContacts: Contact[] = [];
    let companies: Contact[] = [];
    let invites: Brand[] = [];

    let existingContactIds: string[] = [];

    queryData?.getAmbassadorListByUserId?.forEach((o) => {
      if (o) {
        if (isContact(o.contact)) {
          if (o?.contact?._id) {
            existingContactIds.push(o.contact._id);
          }
          companies.push(o.contact);
          allContacts.push(o.contact);
        }
        invites.push(o);
      }
    });

    queryData?.getContactList?.forEach((o) => {
      if (isContact(o) && o._id && !existingContactIds.includes(o._id) && o) {
        allContacts.push(o);
        if (o.type === 'COMPANY') {
          companies.push(o);
          invites.push(o);
        }
      }
    });

    return { allContacts, companies, invites };
  }, [queryData?.getAmbassadorListByUserId, queryData?.getContactList]);

  return {
    ...contactList,
    refetch,
    loading,
    error
  };
};
