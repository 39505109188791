import React, { useEffect } from 'react';
import StripeLogo from '../../../assets/icons/stripe_logo.svg';
import { Box, Grid, Typography, Button, CircularProgress, Link } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import {
  useGetMyUserQuery,
  useGetConnectedAccountLinkLazyQuery,
  useGetConnectedAccountLazyQuery
} from 'types/generated';
import { useErrorHandlerHook } from 'hooks/useErrorCatchHook';
import useSubscribePopupCtx from 'context/useSubscribePopupCtx';

interface IStripeSetupBox {
  returnPath?: string;
}

const StripeSetupBox = ({ returnPath }: IStripeSetupBox) => {
  const { classes } = styles();

  const { setSelectedPlanId } = useSubscribePopupCtx();

  const { data: userData, error: userError, loading: userLoading } = useGetMyUserQuery();

  const [getConnectedAccountLink, { loading: connectAccountLinkLoading, error: connectAccountLinkError }] =
    useGetConnectedAccountLinkLazyQuery({
      variables: { returnPath: returnPath || '/finance/overview' },
      onCompleted: (data) => {
        const accountLinkUrl = data?.getConnectedAccountLink?.url;
        if (accountLinkUrl) {
          window.location.assign(accountLinkUrl);
        }
      }
    });

  const [
    getConnectedAccount,
    { data: connectAccountData, loading: connectAccountLoading, error: connectAccountError }
  ] = useGetConnectedAccountLazyQuery();

  const connectedType = connectAccountData?.getConnectedAccount?.type;

  const hasSubmittedDetails = connectAccountData?.getConnectedAccount?.details_submitted;

  const dashboardLink =
    connectAccountData?.getConnectedAccount?.expressDashboardLink || 'https://dashboard.stripe.com/login';

  const { apolloHandler } = useErrorHandlerHook();

  useEffect(() => {
    if (userError) {
      apolloHandler(userError, `Unable to get current invoice settings`);
    }
  }, [userError, apolloHandler]);

  useEffect(() => {
    if (connectAccountLinkError) {
      apolloHandler(connectAccountLinkError, `Unable to create a Stripe Account Link`);
    }
  }, [connectAccountLinkError, apolloHandler]);

  useEffect(() => {
    if (connectAccountError) {
      apolloHandler(connectAccountError, `Unable to get Stripe Account information`);
    }
  }, [connectAccountError, apolloHandler]);

  const user = userData?.myUser;
  const stripeAccountId = user?.stripeAccountId;

  useEffect(() => {
    if (stripeAccountId) {
      getConnectedAccount();
    }
  }, [stripeAccountId, getConnectedAccount]);

  if (userLoading || connectAccountLoading || hasSubmittedDetails) {
    return null;
  }

  const isLoading = connectAccountLoading || connectAccountLinkLoading;

  return (
    <Box className={classes.container}>
      <Grid container justifyContent={'space-between'} wrap='nowrap'>
        <Grid item container direction={'column'} alignItems='flex-start' className={classes.textContainer}>
          <Typography className={classes.title}>Get started with Stripe</Typography>
          <Typography className={classes.bodyText}>
            Create and send invoices on the go and get paid faster! Connect with Stripe to access real time payments.
            Have your invoices paid globally through a trusted, secure portal.
            <div style={{ marginTop: 10 }}>
              Invoice payments via Kitly will be visible within your accounting software.
            </div>
          </Typography>
          <Typography className={classes.bodyText}>
            Stripe invoice fees are dependant on your Kitly subscription, between 1% & 3% , see{' '}
            <Link
              component='a'
              onClick={() => setSelectedPlanId('kitly-usd-2')}
              style={{ cursor: 'pointer' }}
              underline='hover'
            >
              subscriptions
            </Link>{' '}
            to save on your invoice fees.
          </Typography>
          <Button
            variant='outlined'
            disabled={isLoading}
            onClick={() =>
              connectedType === 'express' ? window.open(dashboardLink, '_blank') : getConnectedAccountLink()
            }
          >
            {isLoading ? <CircularProgress /> : 'Connect To Stripe'}
          </Button>
        </Grid>
        <Grid>
          <img src={StripeLogo} alt='' className={classes.logo} />
        </Grid>
      </Grid>
    </Box>
  );
};

const styles = makeStyles()(() => ({
  container: {
    backgroundColor: '#33333325',
    paddingTop: 14,
    paddingBottom: 20,
    paddingLeft: 23,
    paddingRight: 35
  },
  textContainer: {
    maxWidth: 600
  },
  title: {
    color: '#424242',
    fontWeight: 500,
    fontSize: 14,
    marginBottom: 11
  },
  bodyText: {
    color: '#4B4B4B',
    fontWeight: 200,
    fontSize: 12,
    marginBottom: 11
  },
  logo: {
    width: 82,
    height: 34,
    marginTop: 10
  }
}));

export default StripeSetupBox;
