import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import InfluencerProfile from './InfluencerProfile';
// UI
import { makeStyles } from 'tss-react/mui';
import { Typography, Box, Divider } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ProfileSelectSlider from './ProfileSelectSlider';
// Utils
import { Link } from 'react-router-dom';
import ContentfulAdBanner from '../ContentfulAdBanner';
// types

const AccountPerformance = () => {
  const { classes } = useClasses();
  const navigate = useNavigate();
  const { id: profileId } = useParams<{ id?: string }>();

  // navigation button component
  const navigation_btn = (
    <>
      <Link className={classes.headerNavigationWrapper} to='/'>
        <ArrowBackIcon fontSize={'inherit'} />
        <Typography display={'inline'}>Back to dashboard</Typography>
      </Link>
    </>
  );
  // profile scroll picker component
  const profile_selection_component = (
    <>
      <ProfileSelectSlider
        value={profileId}
        onSelect={(profile) => {
          navigate(`/account-performance/${profile?.id}`);
        }}
      />
      <Divider style={{ marginTop: 18 }} />
    </>
  );

  return (
    <Box className={classes.pageWrapper}>
      {navigation_btn}
      <ContentfulAdBanner
        style={{ marginTop: 20 }}
        skeletonStyle={{ marginTop: 20 }}
        adLocation='account-performance'
      />
      <Spacer padding={20} />
      {profile_selection_component}
      <InfluencerProfile />
    </Box>
  );
};

const useClasses = makeStyles()((theme) => ({
  loadingWrapper: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 9999
  },
  pageWrapper: {
    minWidth: 920,
    padding: '0 137px'
  },
  headerNavigationWrapper: {
    display: 'flex',
    alignItems: 'center',
    color: '#3A3A3A',
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  pageTitle: {
    fontSize: 20,
    color: '#3A3A3A',
    fontFamily: 'Open Sans',
    fontWeight: 500,
    marginBottom: 5
  },
  pageSubtitle: {
    fontSize: 14,
    marginBottom: 10
  },
  profileBtn: {
    borderRadius: 4,
    minWidth: 240,
    margin: 4,
    paddingRight: 10,
    paddingLeft: 10,
    paddingTop: 3,
    paddingBottom: 3,
    backgroundColor: '#fff'
  },
  activeItem: {
    border: `solid 0.5px ${theme.palette.primary.main}`
  },
  inactiveItem: {
    border: 'solid 0.5px #B0A1AA'
  },
  accountContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 0,
    overflow: 'auto',
    width: 920, //TODO: ADDING MORE STYLE TO CONTROL THE CONTAINER
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  mediaKitColWrapper: {
    marginTop: 40,
    paddingLeft: 80
  }
}));

interface ISpacer {
  padding: number;
}
export const Spacer: React.FC<ISpacer> = (props) => {
  return <Box style={{ padding: props.padding }} />;
};
Spacer.defaultProps = {
  padding: 0
};

export default AccountPerformance;
