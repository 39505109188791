import React from 'react';
import Avatar from './Avatar';
// UI
import { makeStyles } from 'tss-react/mui';
import { Grid, Box, Badge, Typography } from '@mui/material';
// Icons
import FacebookIcon from '../assets/images/facebook.svg';
import GreyMedal from '../assets/images/grey-medal.svg';
import InstagramIcon from '../assets/images/instagram.svg';
import PinkMedal from '../assets/images/pink-medal.svg';
import ytIcon from '../assets/images/yt_icon.svg';
import TiktokIcon from 'assets/icons/tikChannel.svg';
import BlueSmallMedal from '../assets/images/blue-small-medal.svg';
// UTILS
import { formatNumber } from '../lib/formatNumber';
// TYPES
import { Profile, SocialChannel } from '../types/generated';
import theme from 'config/muiTheme';

interface IAccountItem {
  profile: Profile;
}

const AccountItem = ({ profile }: IAccountItem) => {
  const { classes } = styles();

  const profileType = profile.profileType;
  const performancePercentile = profile?.latestPerformanceReport?.performance?.percentile;
  const isLimited = profile?.latestPerformanceReport?.isLimited;
  const isRestricted = profile?.isRestricted;

  const renderProfileSubtitle = () => {
    let imgSrc;
    if (performancePercentile) {
      imgSrc = performancePercentile <= 25 ? PinkMedal : performancePercentile <= 50 ? BlueSmallMedal : GreyMedal;
    } else {
      imgSrc = GreyMedal;
    }

    let subtitle: string = '';

    switch (profileType) {
      case SocialChannel.Instagram:
        subtitle = profile.followers_count ? `${formatNumber(profile.followers_count)} followers` : 'Instagram account';
        break;
      case SocialChannel.Youtube:
        subtitle = profile.channelStats
          ? `${formatNumber(profile.channelStats.subscriberCount ?? 0)} subscribers`
          : 'YouTube channel';
        break;
      case SocialChannel.Facebook:
        const fansCount = profile.fan_count || profile.pageStats?.page_fans;
        subtitle = fansCount ? `${formatNumber(fansCount)} fans` : 'Facebook page';
        break;
      case SocialChannel.Tiktok:
        subtitle = profile.public?.stats?.followerCount
          ? `${formatNumber(profile.public.stats.followerCount)} followers`
          : 'TikTok account';
        break;
      default:
        break;
    }

    let message: string | JSX.Element = '';
    if (isRestricted) {
      message = 'Upgrade to view';
    } else if (performancePercentile && profileType === SocialChannel.Instagram) {
      if (performancePercentile <= 50) {
        message = isLimited ? (
          <span>
            Top
            <span style={{ filter: 'blur(6px)' }}>{`Kitly`}</span>
          </span>
        ) : (
          `Top ${performancePercentile}%`
        );
      } else {
        message = isLimited ? <span style={{ filter: 'blur(6px)' }}>{`Kitly`}</span> : `Lower 50%`;
      }
    } else {
      message = subtitle;
    }

    return (
      <Grid container alignItems='center' style={{ flexWrap: 'nowrap' }}>
        {performancePercentile && (
          <Grid item style={{ marginRight: 5 }}>
            <img src={imgSrc} alt='Performance Medal' style={{ height: 15, width: 15 }} />
          </Grid>
        )}
        <Grid
          item
          style={{
            color: Boolean(isRestricted || isLimited) ? theme.palette.primary.main : '',
            fontSize: 10,
            flexWrap: 'nowrap'
          }}
        >
          {message}
        </Grid>
      </Grid>
    );
  };

  return (
    <Box display='flex' alignItems='center' justifyItems='flex-start' className={classes.wrapper}>
      <ProfileBadge profile={profile} />
      <Box className={classes.profileName}>
        <Typography>{profile.displayName}</Typography>
        {renderProfileSubtitle()}
      </Box>
    </Box>
  );
};

export default AccountItem;

interface IProfileBadge {
  profile: Profile | null | undefined;
  size?: number;
}
export const ProfileBadge: React.FC<IProfileBadge> = ({ profile, size }) => {
  const badgeClasses = badgeStyles();
  const { classes } = styles();
  if (!profile) return null;
  return (
    <Badge
      classes={badgeClasses}
      overlap='circular'
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      badgeContent={
        <Box className={classes.badgeIcon}>
          {profile.profileType === SocialChannel.Instagram && (
            <img src={InstagramIcon} alt='instagram' className={classes.badgeImage} />
          )}
          {profile.profileType === SocialChannel.Youtube && (
            <img src={ytIcon} alt='youtube' className={classes.badgeImage} />
          )}
          {profile.profileType === SocialChannel.Facebook && (
            <img src={FacebookIcon} alt='facebook' className={classes.badgeImage} />
          )}
          {profile.profileType === SocialChannel.Tiktok && (
            <img src={TiktokIcon} alt='tiktok' className={classes.badgeImage} />
          )}
        </Box>
      }
    >
      <Box display='flex' alignItems='center'>
        <Avatar classes={classes.avatar} src={profile.profile_picture_url ?? ''} />
      </Box>
    </Badge>
  );
};

const styles = makeStyles()((theme) => ({
  wrapper: {
    height: 44,
    boxSizing: 'border-box'
  },
  badgeIcon: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    padding: 3,
    border: `1px solid ${theme.palette.background.default}`,
    backgroundColor: '#fff'
  },
  badgeImage: {
    width: 12,
    height: 12
  },
  avatar: {
    width: '32px',
    height: '32px',
    backgroundColor: 'white'
  },
  text: {
    textAlign: 'left'
  },
  linkStyle: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    marginLeft: 4,
    fontWeight: 'bold',
    fontSize: 10
  },
  profileName: {
    marginLeft: 20
  }
}));

const badgeStyles = makeStyles()({
  root: {
    width: 32,
    height: 32
  },

  badge: {
    bottom: 0,
    right: 'auto',
    left: '100%',
    transform: 'translateX(-50%) translateY(0)',
    padding: 0
  }
});
