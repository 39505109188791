import React from 'react';
const ArrowRight = ({ fill, size }: { fill?: string; size?: number }) => {
  const iconSize = size || 24;
  return (
    <svg
      width={`${iconSize}px`}
      height={`${iconSize}px`}
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='3.-Influencer' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='3.1-Influencer-view_Instagram'
          transform='translate(-1082.000000, -1485.000000)'
          fill={fill || '#EEEEEE'}
        >
          <g id='Performance' transform='translate(391.000000, 1231.000000)'>
            <path
              d='M703,254 C709.627417,254 715,259.372583 715,266 C715,272.627417 709.627417,278 703,278 C696.372583,278 691,272.627417 691,266 C691,259.372583 696.372583,254 703,254 Z M701.090909,259.355472 L699.355472,261.090909 L704.264563,266 L699.355472,270.909091 L701.090909,272.644528 L706.867719,266.867719 C707.347122,266.388315 707.347122,265.611679 706.867719,265.132281 L701.090909,259.355472 Z'
              id='Combined-Shape'
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ArrowRight;
