import React, { createContext, useState, useContext, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import SubscribePopup from 'components/subscribe/SubscribePopup';
import { useUserConfig } from 'hooks/useUserConfig';

interface ISubscribePopupContext {
  selectedPlanId?: string;
  setSelectedPlanId: (id?: string) => void;
}

const subscribePopupCtx = {
  selectedPlanId: undefined,
  setSelectedPlanId: () => {}
};

export const SubsribePopupContext = createContext<ISubscribePopupContext>(subscribePopupCtx);

export const SubscribePopupProvider = ({ children }: { children: React.ReactNode }) => {
  const [selectedPlanId, setSelectedPlanId] = useState<string | undefined>();
  const params = useParams<{ stripeSessionId?: string }>();
  const navigate = useNavigate();

  const stripeSessionId = params.stripeSessionId;

  return (
    <>
      {Boolean(selectedPlanId || stripeSessionId) && (
        <SubscribePopup
          selectedPlanId={selectedPlanId}
          closeSubscribePopup={() => {
            setSelectedPlanId(undefined);
            if (stripeSessionId) {
              navigate('/');
            }
          }}
          stripeSessionId={stripeSessionId}
        />
      )}
      <SubsribePopupContext.Provider
        value={{
          selectedPlanId,
          setSelectedPlanId
        }}
      >
        {children}
      </SubsribePopupContext.Provider>
    </>
  );
};

export enum PlanLimitType {
  REPORTS_USAGE,
  COLLABS_USAGE,
  MEDIA_KIT_USAGE,
  INVOICE_USAGE
}

const useSubscribePopupCtx = (limitType?: PlanLimitType) => {
  const { setSelectedPlanId } = useContext(SubsribePopupContext);
  const { userData, loading } = useUserConfig();
  const user = userData?.myUser;

  const handleSetSelectedPlanId = useCallback(() => {
    if (user?.activeSubscription) {
      if (user.activeSubscription.includes('-yearly')) {
        setSelectedPlanId('kitly-usd-2-yearly');
      } else {
        setSelectedPlanId('kitly-usd-2');
      }
    } else {
      setSelectedPlanId('kitly-usd-1');
    }
  }, [setSelectedPlanId, user]);

  const executeLimitedAction = useCallback(
    (callback: () => void) => {
      let usage;
      switch (limitType) {
        case PlanLimitType.COLLABS_USAGE:
          usage = user?.collaborationsUsage;
          break;
        case PlanLimitType.MEDIA_KIT_USAGE:
          usage = user?.mediaKitsUsage;
          break;
        case PlanLimitType.REPORTS_USAGE:
          usage = user?.reportsUsage;
          break;
        case PlanLimitType.INVOICE_USAGE:
          usage = user?.invoicesUsage;
          break;
        default:
          break;
      }

      if (usage?.count && usage?.limit) {
        if (usage.count >= usage.limit) {
          return handleSetSelectedPlanId();
        }
      }
      if (!usage) return;

      callback();
    },
    [limitType, handleSetSelectedPlanId, user]
  );

  const openSubscribePopup = handleSetSelectedPlanId;

  return { setSelectedPlanId, executeLimitedAction, loading, openSubscribePopup } as const;
};

export default useSubscribePopupCtx;
