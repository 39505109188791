import { makeStyles } from 'tss-react/mui';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { formatDate } from 'lib/formatDates';
import { formatName, formatInitials } from 'lib/formatStrings';
import { useGetMediaKitLinkListQuery } from 'types/generated';

export const History = () => {
  const { classes } = useStyles();
  const { data } = useGetMediaKitLinkListQuery({ fetchPolicy: 'network-only' });

  const tableHeadersData = ['Date', 'Shared To', null, 'Status'];
  const tableRowsData = data?.getMediaKitLinkList || [];

  const renderTable = () => {
    return (
      <TableContainer>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              {tableHeadersData.map((o, i) => (
                <TableCell key={i}>
                  <Typography>{o}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableRowsData.map((o, i) => (
              <TableRow key={i}>
                <TableCell component='th' scope='row'>
                  {o?.created_at ? formatDate(o.created_at) : ''}
                </TableCell>
                <TableCell>
                  <Grid container alignItems='center'>
                    <Grid item>
                      <Avatar alt='user' src={o?.contact?.avatar || ''} className={classes.avatar}>
                        {o?.contact ? formatInitials(o.contact) : o ? formatInitials(o) : ''}
                      </Avatar>
                    </Grid>
                    <Grid item>{o?.contact ? formatName(o.contact) : o?.name || 'Link'}</Grid>
                  </Grid>
                </TableCell>
                <TableCell>{o?.title}</TableCell>
                <TableCell>
                  <Typography
                    className={classes.mediaKitLinkStatusContainer}
                    style={{
                      backgroundColor: o?.isOpened ? '#FCE78A' : '#C2EDE8'
                    }}
                  >
                    {o?.isOpened ? 'OPENED' : 'SENT'}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Box mt='18px'>
      <Box mb={'10px'}>
        <Typography variant='h4'>Media Kit History</Typography>
      </Box>
      {renderTable()}
    </Box>
  );
};

const useStyles = makeStyles()({
  avatar: {
    display: 'inline-flex',
    height: 26,
    width: 26,
    backgroundColor: '#60D8CA',
    color: '#333333',
    fontSize: 10,
    marginRight: 8
  },
  mediaKitLinkStatusContainer: {
    fontSize: 10,
    color: '#000000',
    fontWeight: 600,
    padding: '1px 0px',
    display: 'inline-block',
    minWidth: 55,
    textAlign: 'center',
    borderRadius: 4
  }
});
