import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import get from 'lodash/get';

import { formatNumber } from 'lib/formatNumber';
import { Profile } from 'types/generated';

interface ComponentProps {
  profile: Profile;
}

interface StatBoxProps {
  stat: string;
  title: string;
  subtitle: string;
}

const styles = makeStyles()((theme) => ({
  statContainer: {
    border: 'solid #6C6C72 0.5px',
    borderRadius: 4,
    marginBottom: 10,
    padding: '15px 0'
  },
  statTitle: {
    fontSize: 24,
    textAlign: 'center'
  },
  statSubtitle: {
    fontSize: 10,
    fontWeight: 600,
    textAlign: 'center',
    maxWidth: 160,
    margin: '0 auto'
  },
  statTip: {
    fontSize: 10,
    textAlign: 'center',
    maxWidth: 160,
    margin: '0 auto'
  },
  statCell: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 10px'
  },
  statDivider: {
    borderRight: 'solid #D8D8D8 2px',
    width: 2
  }
}));

const SummaryStats = ({ profile }: ComponentProps) => {
  const { classes } = styles();

  const StatBox = ({ stat, title, subtitle }: StatBoxProps) => (
    <>
      <Typography className={classes.statTitle}>{stat}</Typography>
      <Typography className={classes.statSubtitle}>{title}</Typography>
      <Typography className={classes.statTip}>{subtitle}</Typography>
    </>
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Grid container className={classes.statContainer}>
          <Grid item xs={6} className={`${classes.statCell} ${classes.statDivider}`}>
            <StatBox
              stat={formatNumber(get(profile, 'channelStats.subscriberCount'))}
              title={'Subscribers'}
              subtitle={'Total users subscribed to this account'}
            />
          </Grid>
          <Grid item xs={6} className={classes.statCell}>
            <StatBox
              stat={formatNumber(get(profile, 'videoStats.views.average'))}
              title={'Average views'}
              subtitle={'Average views per video'}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SummaryStats;
