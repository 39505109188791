let { hostname, protocol } = window.location;

let settings = {
  // Can't use this client ID until our access is approved by Google for our test environment
  // GOOGLE_OAUTH_CLIENT_ID: '185910475637-7fvvop0gl49ect3r97h6aqpmdsvdrsuo.apps.googleusercontent.com',
  GOOGLE_OAUTH_CLIENT_ID: '1076090351285-ludue4ustuh7pm2ob9vi5piol74j1hq7.apps.googleusercontent.com',
  GLOBAL_DOMAINS: ['staging-app.q-83.com', 'app.q-83.com', 'localhost'],
  CMT_DOMAINS: ['staging-app.q-83.com', 'localhost'],
  MICROSERVICES_KEY: 'SKkE6MyIxDa6ZRkTxXxRR86n5EhYH7Wbav3v4YON',
  CACHED_DATA_URL: 'https://cache-global.q-83.com/instagram_summary/',
  Q83_SERVICES_URL: 'https://ft6h871s9c.execute-api.ap-southeast-2.amazonaws.com/',
  INSTAGRAM_DATA_URL: 'https://8z2k7ah3s8.execute-api.ap-southeast-2.amazonaws.com/default/',
  NEWS_SERVICE_URL: 'https://44clkmqemi.execute-api.ap-southeast-2.amazonaws.com/default/',
  PROFILE_PIC_PATH: 'https://cache-global.q-83.com/profile_pics/',
  IP_API_KEY: 'a519f063-3b01-4341-a203-2d113336da6a',
  TRUE_REACH_THRESHOLD: 75,
  FACEBOOK_APP_ID: '198456758090892',
  FACEBOOK_APP_PERMISSIONS:
    'instagram_basic,instagram_manage_insights,instagram_manage_comments,pages_manage_metadata,pages_read_engagement,business_management,pages_show_list,read_insights,pages_read_user_content',
  INTERCOM_ID: 'qo9cqxhh',
  API_URL: `${protocol}//${hostname}:3333/`,
  APP_URL: `${protocol}//${hostname}:3011`,
  HOME_URL: `${protocol}//${hostname}:3009`,
  REPORT_URL: `${protocol}//${hostname}:3003`,
  MEDIAKIT_URL: `${protocol}//${hostname}:3004`,
  INVOICE_URL: `${protocol}//${hostname}:3005`,
  STRIPE_PK: 'pk_test_3AzSKyFbHqEBO2mQr4k9aCHR',
  FACEBOOK_REDIRECT_URI: `${protocol}//${hostname}:3333/kitly-web/facebook/verify`,
  TERMS_URL: 'https://kitly.co/#/terms'
};

if (process.env.REACT_APP_DEPLOYMENT === 'staging' || process.env.REACT_APP_DATABASE === 'staging') {
  settings.API_URL = 'https://api-v2-staging.q-83.com/';
} else if (process.env.REACT_APP_DEPLOYMENT === 'production' || process.env.REACT_APP_DATABASE === 'production') {
  settings.API_URL = 'https://api-v2.q-83.com/';
}

if (process.env.REACT_APP_DEPLOYMENT === 'staging') {
  settings.APP_URL = 'https://app-staging.kitly.co';
  settings.HOME_URL = `https://staging.kitly.co/`;
  settings.REPORT_URL = `https://reports-staging.kitly.co`;
  settings.MEDIAKIT_URL = `https://kit-staging.kitly.co`;
  settings.INVOICE_URL = `https://invoice-staging.kitly.co`;
  settings.FACEBOOK_REDIRECT_URI = `${settings.API_URL}kitly-web/facebook/verify`;
} else if (process.env.REACT_APP_DEPLOYMENT === 'production') {
  settings.GOOGLE_OAUTH_CLIENT_ID = '1076090351285-ludue4ustuh7pm2ob9vi5piol74j1hq7.apps.googleusercontent.com';
  settings.APP_URL = 'https://app.kitly.co';
  settings.HOME_URL = `https://kitly.co/`;
  settings.REPORT_URL = `https://reports.kitly.co`;
  settings.MEDIAKIT_URL = `https://kit.kitly.co`;
  settings.STRIPE_PK = 'pk_live_rzYUpMA3MO9oXPX2UR8EVfWr';
  settings.INTERCOM_ID = 'kha2p6h3';
  settings.FACEBOOK_APP_ID = '451462545297421';
  settings.FACEBOOK_REDIRECT_URI = `${settings.API_URL}kitly-web/facebook/verify`;
  settings.INVOICE_URL = 'https://invoice.kitly.co';
}

export default settings;
