import { useContext, useCallback } from 'react';
import { ErrorHandlerCtx, Error_Type_Enum } from '../context/useErrorHandlerCtx';
import { AxiosError } from 'axios';
import { ApolloError } from '@apollo/client';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import last from 'lodash/last';

export type errorTypes = 'info' | 'success' | 'warning' | 'error' | 'default' | 'dark' | undefined | 'graphqlError';
const WARNING = 'warning';
const SUCCESS = 'success';
const ERROR = 'error';
const GRAPHQL_ERROR = 'error';

const useErrorHandlerHook = () => {
  const errorHelperCtx = useContext(ErrorHandlerCtx);
  const { catchError } = errorHelperCtx;

  const errorHandler = useCallback(
    (input: Error_Type_Enum) => {
      const label = ERROR;
      if (!typeChecker(label, input)) return;
      catchError(input);
    },
    [catchError]
  );

  const warningHandler = useCallback(
    (input: Error_Type_Enum) => {
      const label = WARNING;
      if (!typeChecker(label, input)) return;
      catchError(input);
    },
    [catchError]
  );

  const successHandler = useCallback(
    (input: Error_Type_Enum, customizedMsg?: string) => {
      const label = SUCCESS;
      if (!typeChecker(label, input)) return;
      catchError(input, customizedMsg);
    },
    [catchError]
  );

  const axiosHandler = useCallback(
    (input: AxiosError, fallback?: string) => {
      catchError(
        Error_Type_Enum.server_error,
        input.message || input.response?.data.message || input.response?.data.error || fallback,
        input
      );
    },
    [catchError]
  );

  const apolloHandler = useCallback(
    (input: ApolloError | string, fallback?: string) => {
      let errorMessage;
      const noFormat = Boolean(
        Boolean(typeof input === 'string') || Boolean(typeof input === 'undefined') || Boolean(input === null)
      );
      if (noFormat) {
        // @ts-ignore: type is checked on return
        errorMessage = fallback;
        // pull out error messaging
      } else if (isArray(input)) {
        errorMessage = input[0].message ? input[0].message : null;
      } else {
        const fallbackError = 'Unknown error';
        errorMessage =
          get(input, ['message']) || get(input, ['error', 'message']) || get(input, ['error']) || fallbackError;
        // if error message isn't a string, go to fallback
        if (typeof errorMessage !== 'string') {
          errorMessage = fallbackError;
        }
      }

      catchError(Error_Type_Enum.server_error, errorMessage || fallback, typeof input !== 'string' ? input : undefined);
    },
    [catchError]
  );

  const graphqlErrorHandler = useCallback(
    (customizedErrorMsg: any) => {
      const label = GRAPHQL_ERROR;
      const input = Error_Type_Enum.graphql_error;
      if (!typeChecker(label, Error_Type_Enum.graphql_error)) return;

      catchError(input, customizedErrorMsg);
    },
    [catchError]
  );

  return {
    errorHandler,
    warningHandler,
    graphqlErrorHandler,
    successHandler,
    axiosHandler,
    apolloHandler
  };
};

export { Error_Type_Enum, useErrorHandlerHook };

// Utilities
const typeChecker = (target: errorTypes, input: Error_Type_Enum) => {
  const type = last(Error_Type_Enum[input].split('_'));
  if (target !== type) {
    console.warn(`You are not handle an ${target} !`, type);
    return false;
  }
  return true;
};
