import React from 'react';
import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { makeStyles } from 'tss-react/mui';

import { InvoiceHistory } from './InvoiceHistory';
import FinanceOverview from './FinanceOverview';
import SimpleTabs from '../../settings/SimpleTabs';
import { InvoiceShareProvider } from 'context/InvoiceShareContext';

import FinanceWalkthrough from './FinanceWalkthrough';
import ContentfulAdBanner from 'components/Dashboard/ContentfulAdBanner';

const FinancePage = () => {
  const { classes } = useStyles();
  const params = useParams<{ activeTab?: string }>();
  const activeTab = params?.activeTab || 'overview';

  return (
    <InvoiceShareProvider>
      <FinanceWalkthrough />
      <Box px={5} maxWidth={971} style={{ width: '100%' }}>
        <Grid container spacing={6}>
          <Grid item xs={12} className={classes.header} direction='column' container>
            <Typography variant='subtitle1' className={classes.subtitle}>
              Manage
            </Typography>
            <Grid container justifyContent='space-between' style={{ height: 50 }}>
              <Typography variant='h2'>Finance</Typography>
            </Grid>
          </Grid>
          <Grid item container>
            <ContentfulAdBanner adLocation='finance' />
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.header} direction='column' container>
          <SimpleTabs
            tabContent={[
              {
                label: 'Overview',
                param: 'overview',
                content: (
                  <div style={{ marginTop: 30 }}>
                    <FinanceOverview />
                  </div>
                )
              },
              {
                label: 'Invoicing',
                param: 'invoicehistory',
                content: (
                  <div style={{ marginTop: 30 }}>
                    <InvoiceHistory />
                  </div>
                )
              }
            ]}
            scrollButtons='auto'
            variant='scrollable'
            availableParam={activeTab}
            page={'finance'}
          />
        </Grid>
      </Box>
    </InvoiceShareProvider>
  );
};

export default FinancePage;

const useStyles = makeStyles()((theme) => {
  return {
    subtitle: {
      fontWeight: 500,
      marginBottom: 12
    },
    centred: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    loaderContainer: {
      height: '85vh'
    },
    header: {
      marginBottom: 1
    },
    marketingBox: {
      padding: 15,
      marginBottom: 15,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      boxShadow: '0 1px 2px 0 rgba(211,211,211,0.5)'
    },
    marketingHeader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      marginBottom: 10
    },
    marketingTitle: {
      fontWeight: 500,
      marginLeft: 15,
      fontSize: 14
    },
    description: {
      lineHeight: '18px'
    },
    button: {
      margin: 5
    }
  };
});
