import React from 'react';

import Grid from '@mui/material/Grid';

interface ActivityTagProps {
  action: string;
}

const ActivityTag = (props: ActivityTagProps): JSX.Element => {
  const { action } = props;

  let actionBackgroundColor: string;
  switch (action) {
    case 'SENT':
      actionBackgroundColor = 'rgba(255,208,21,0.5)';
      break;
    case 'OPENED':
      actionBackgroundColor = '#C2EDE8';
      break;
    case 'ADDED':
      actionBackgroundColor = '#c0dbff';
      break;
    case 'SET_UP':
      actionBackgroundColor = '#c0dbff';
      break;
    case 'SET UP':
      actionBackgroundColor = '#c0dbff';
      break;
    case 'OVERDUE':
      actionBackgroundColor = '#F68D8D';
      break;
    case 'PAID':
      actionBackgroundColor = '#C2EDE8';
      break;
    case 'DRAFT':
      actionBackgroundColor = '#FFB96F';
      break;
    case 'EXPIRED':
      actionBackgroundColor = '#F68D8D';
      break;
    case 'VOID':
      actionBackgroundColor = '#BBBBBB';
      break;
    default:
      actionBackgroundColor = '#F68D8D';
      break;
  }

  return (
    <Grid
      item
      style={{
        marginRight: 8,
        borderRadius: 5,
        padding: '4px 10px',
        minWidth: 75,
        width: 'min-content',
        fontSize: 10,
        fontWeight: 'bold',
        textAlign: 'center',
        backgroundColor: actionBackgroundColor
      }}
    >
      {action.replace('_', ' ')}
    </Grid>
  );
};

export default ActivityTag;
