import React, { CSSProperties, ReactNode } from 'react';

import { makeStyles } from 'tss-react/mui';

import { Button, ButtonBase, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';

interface ModalProps {
  children: ReactNode;
  isOpen: boolean;
  onToggle?: (props: any) => void;
  onConfirm?: (props: any) => void;
  onCancel?: () => void;
  isLoadingConfirm?: boolean;
  confirmLabel?: string;
  cancelLabel?: string;
  confirmVariant?: 'outlined' | 'contained';
  hideConfirmationButtons?: boolean;
  hideConfirmButton?: boolean;
  disableConfirmButton?: boolean;
  hideCancelButton?: boolean;
  subConfirmationContent?: ReactNode;
  errorMessage?: string | null | undefined;
  showErrorMessage?: boolean;
  heading?: ReactNode | string;
  showCloseIcon?: boolean;
  fullScreen?: boolean;
  fullWidth?: boolean;
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false;
  disableBackdropClick?: boolean;
  disableEscapeKeyDown?: boolean;
  'data-cy'?: string;
  additionalButton?: JSX.Element;
  padding?: any;
  paperStyle?: CSSProperties;
}

const useStyles = makeStyles()((theme) => ({
  buttonRoot: {
    minWidth: '134px',
    [theme.breakpoints.down('xs')]: {
      minWidth: '100px'
    }
  },
  dialogPaper: {
    border: 'none',
    padding: '43px 74px',
    [theme.breakpoints.down('xs')]: {
      padding: '25px 20px'
    }
  },
  closeIcon: {
    fontSize: 22,
    color: theme.palette.common.black
  }
}));

const Modal: React.FunctionComponent<ModalProps> = (props) => {
  const {
    isOpen,
    onToggle,
    onConfirm,
    onCancel,
    children,
    subConfirmationContent,
    isLoadingConfirm,
    confirmLabel,
    confirmVariant,
    hideConfirmationButtons,
    hideConfirmButton,
    disableConfirmButton,
    hideCancelButton,
    errorMessage,
    showErrorMessage,
    heading,
    showCloseIcon,
    fullScreen,
    fullWidth,
    maxWidth,
    disableBackdropClick,
    disableEscapeKeyDown,
    additionalButton,
    paperStyle
  } = props;

  const { classes } = useStyles();

  const noop = () => {};

  const confirmLabelDisplay = confirmLabel || 'Confirm';

  return (
    <Dialog
      data-cy={props['data-cy']}
      open={isOpen}
      onClose={(event, reason) => {
        if (
          (reason === 'backdropClick' && !disableBackdropClick) ||
          (reason === 'escapeKeyDown' && !disableEscapeKeyDown)
        ) {
          if (onCancel) {
            onCancel();
          } else if (onToggle) {
            onToggle(event);
          }
        }
      }}
      fullScreen={fullScreen || false}
      fullWidth={fullWidth || false}
      maxWidth={maxWidth || false}
      classes={{ paper: classes.dialogPaper }}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: fullScreen ? '100%' : 'initial'
      }}
      PaperProps={{
        style: paperStyle
      }}
    >
      {showCloseIcon && (
        <div style={{ position: 'absolute', right: 40, top: 40 }}>
          <ButtonBase onClick={onCancel ? onCancel : onToggle}>
            <ClearRoundedIcon className={classes.closeIcon} />
          </ButtonBase>
        </div>
      )}
      <DialogTitle style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {heading ? heading : <div />}
      </DialogTitle>
      <DialogContent style={{ display: 'flex', flexDirection: 'column', flexWrap: 'nowrap' }}>{children}</DialogContent>
      {!hideConfirmationButtons && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            flexWrap: 'nowrap'
          }}
        >
          <DialogActions style={{ display: 'flex', alignItems: 'center' }}>
            {additionalButton ? additionalButton : null}
            {!hideConfirmButton && (
              <Button
                data-cy={`modal-confirm-${confirmLabel}`}
                disabled={Boolean(disableConfirmButton)}
                onClick={isLoadingConfirm ? noop : onConfirm ? onConfirm : onToggle}
                color='primary'
                variant={confirmVariant ? confirmVariant : 'contained'}
                classes={{ root: classes.buttonRoot }}
              >
                {isLoadingConfirm ? <CircularProgress style={{ color: '#fff' }} size={15} /> : confirmLabelDisplay}
              </Button>
            )}
            {!hideCancelButton && (
              <Button
                data-cy={`modal-cancel-${confirmLabel}`}
                onClick={isLoadingConfirm ? noop : onCancel ? onCancel : onToggle}
                variant='outlined'
                classes={{ root: classes.buttonRoot }}
              >
                {props.cancelLabel || 'Cancel'}
              </Button>
            )}
          </DialogActions>
          {showErrorMessage && (
            <div
              style={{
                display: 'flex',
                width: '100%',
                textAlign: 'center',
                paddingLeft: '15px',
                color: 'red',
                fontSize: '10px'
              }}
            >
              {typeof errorMessage === 'string' ? errorMessage : 'Unknown error'}
            </div>
          )}
        </div>
      )}
      {subConfirmationContent && <DialogContent>{subConfirmationContent}</DialogContent>}
    </Dialog>
  );
};

export default Modal;
