// Adaption of this code sample: https://github.com/jaydenseric/apollo-upload-client/issues/88

export const customFetch = (input: RequestInfo, init?: RequestInit) => {
  return new Promise<Response>((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.open(init?.method || 'get', typeof input === 'string' ? input : input.url);

    if (typeof init?.headers === 'object') {
      const headersObject = init.headers as any;
      Object.keys(headersObject).forEach((k) => {
        const value = headersObject[k];
        xhr.setRequestHeader(k, value);
      });
    }

    xhr.onload = (e: any) => {
      resolve(new Response(e?.target?.responseText, init));
    };

    xhr.onerror = (ex) => {
      console.error('Fetch exception', ex);
      reject(ex);
    };

    if (xhr.upload) {
      xhr.upload.onprogress = (event) => {
        const customInit = init as any;
        if (customInit?.onProgress) {
          customInit?.onProgress(event.loaded / event.total);
        }
      };
    }

    if (typeof input !== 'string' && input.body) {
      xhr.send(input.body as any);
    } else if (init?.body) {
      xhr.send(init.body as any);
    } else {
      xhr.send();
    }
  });
};
