import { useEffect, useMemo, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Box, Button, Typography, CircularProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { TileContainer } from 'components/Containers/TileContainer';
import { ScrollableBoxNoScroll } from 'components/Containers/ScrollableBoxNoScroll';
import { GradientBar } from 'components/pages/Finance/FinanceOverview';
import { formatLongNumber } from 'lib/formatNumber';
import { COLORS } from 'lib/constants';
import CollabsIcon from 'assets/icons/collabs_dark.svg';
import CardIcon from 'assets/icons/card.svg';
import HandoutIcon from 'assets/icons/handout.svg';
import { currencyOptions as currencies } from 'lib/invoiceValues';
import {
  useGetCollaborationInvoiceTotalsLazyQuery,
  useGetCollaborationTotalsLazyQuery,
  useGetInvoicesQuery
} from 'types/generated';
import { compact, filter, find, uniq } from 'lodash';
import moment from 'moment';
import { useErrorHandlerHook } from 'hooks/useErrorCatchHook';

const FinanceCard = () => {
  const navigate = useNavigate();
  const { classes } = useStyles();

  const [currency, setCurrency] = useState(currencies.length ? currencies[0].value : undefined);

  const { data: invoicesData, loading: invoicesDataLoading, error: invoicesError } = useGetInvoicesQuery();

  const [getCollaborationInvoiceTotals, { data, loading, error }] = useGetCollaborationInvoiceTotalsLazyQuery({
    fetchPolicy: 'cache-and-network'
  });

  const [getCollaborationTotals, { data: collabData, loading: collabLoading, error: collabError }] =
    useGetCollaborationTotalsLazyQuery({
      fetchPolicy: 'cache-and-network'
    });

  const handleGetTotals = useCallback(() => {
    const todaysDate = moment();
    const firstOfTheMonth = moment({ date: 1, month: todaysDate.month() });
    const lastOfTheMonth = moment({ date: todaysDate.daysInMonth(), month: todaysDate.month() });
    getCollaborationInvoiceTotals({
      variables: { startDate: firstOfTheMonth, endDate: lastOfTheMonth }
    });
    getCollaborationTotals({
      variables: { startDate: firstOfTheMonth, endDate: lastOfTheMonth }
    });
  }, [getCollaborationInvoiceTotals, getCollaborationTotals]);

  useEffect(() => {
    handleGetTotals();
  }, [handleGetTotals]);

  const availableCurrencies = useMemo(() => {
    return uniq([
      ...compact(
        data?.getCollaborationInvoiceTotals?.totals?.map((o) => {
          return o?.currency;
        })
      ),
      ...compact(
        collabData?.getCollaborationTotals?.totals?.map((o) => {
          return o?.currency;
        })
      )
    ]);
  }, [data, collabData]);

  useEffect(() => {
    if (availableCurrencies?.length) {
      setCurrency(availableCurrencies[0]);
    }
  }, [availableCurrencies]);

  const selectedCurrency = useMemo(() => {
    if (currency) {
      return find(currencies, { value: currency });
    }
    return null;
  }, [currency]);

  const filteredTotals = useMemo(() => {
    if (data?.getCollaborationInvoiceTotals?.totals) {
      return filter(data.getCollaborationInvoiceTotals.totals, (o) => {
        return o?.currency === currency;
      });
    }
    return null;
  }, [data, currency]);

  const incomeTotals = useMemo(() => {
    return find(filteredTotals, (o) => {
      if (!o?.contactId) {
        return true;
      }
      return false;
    });
  }, [filteredTotals]);

  const collabTotals = useMemo(() => {
    return collabData?.getCollaborationTotals?.totals
      ? find(collabData.getCollaborationTotals.totals, {
          contactId: 'total',
          currency
        })
      : null;
  }, [collabData, currency]);

  const maxTotal = useMemo(() => {
    return Math.max(collabTotals?.totalPrice || 0, incomeTotals?.max || 0);
  }, [collabTotals, incomeTotals]);

  const financeCardLoading = loading || collabLoading || invoicesDataLoading;
  const hasFinanceContent = Boolean(invoicesData && invoicesData?.getCollaborationInvoices?.length);

  const { apolloHandler } = useErrorHandlerHook();

  useEffect(() => {
    if (error) {
      apolloHandler(error);
    }
  }, [error, apolloHandler]);

  useEffect(() => {
    if (collabError) {
      apolloHandler(collabError);
    }
  }, [collabError, apolloHandler]);

  useEffect(() => {
    if (invoicesError) {
      apolloHandler(invoicesError);
    }
  }, [invoicesError, apolloHandler]);

  const renderLoader = () => {
    return (
      <Grid container alignItems='center' justifyContent='center' style={{ height: '100%' }}>
        <CircularProgress />
      </Grid>
    );
  };

  const renderViewMoreButton = () => {
    return (
      <Button variant='outlined' size='small' color='primary' onClick={() => navigate(`/finance`)}>
        View More
      </Button>
    );
  };

  const renderContent = () => {
    if (hasFinanceContent) {
      return (
        <Grid container>
          <Grid item xs={12}>
            <Typography className={classes.incomeTitle}>{`${selectedCurrency?.value}${
              selectedCurrency?.symbol
            }${formatLongNumber(incomeTotals?.all)}`}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.incomeSubtitle}>{`Income this month.`}</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent='flex-start'
            alignItems='center'
            spacing={2}
            className={classes.incomeRow}
          >
            <Grid item>
              <img src={CollabsIcon} alt='due' />
            </Grid>
            <Grid>
              <Typography className={classes.incomeRowText}>
                {`${selectedCurrency?.value}${selectedCurrency?.symbol}${formatLongNumber(collabTotals?.totalPrice)} -
                      Campaigns Value`}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.incomeBar}>
            <GradientBar
              widthPercentage={
                Boolean(collabTotals?.totalPrice && maxTotal && collabTotals?.totalPrice > 0)
                  ? (collabTotals?.totalPrice! / maxTotal!) * 100
                  : 0
              }
            />
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent='flex-start'
            alignItems='center'
            spacing={2}
            className={classes.incomeRow}
          >
            <Grid item>
              <img src={HandoutIcon} alt='due' />
            </Grid>
            <Grid>
              <Typography className={classes.incomeRowText}>
                {`${selectedCurrency?.value}${selectedCurrency?.symbol}${formatLongNumber(incomeTotals?.due)} -
                      Invoiced & Due`}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.incomeBar}>
            <GradientBar
              widthPercentage={
                Boolean(incomeTotals?.due && maxTotal && incomeTotals?.due > 0)
                  ? (incomeTotals!.due! / maxTotal!) * 100
                  : 0
              }
            />
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent='flex-start'
            alignItems='center'
            spacing={2}
            className={classes.incomeRow}
          >
            <Grid item>
              <img src={CardIcon} alt='paid' />
            </Grid>
            <Grid item>
              <Typography className={classes.incomeRowText}>
                {`${selectedCurrency?.value}${selectedCurrency?.symbol}${formatLongNumber(incomeTotals?.paid)} -
                      Received`}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <GradientBar
              widthPercentage={
                Boolean(incomeTotals?.paid && maxTotal && incomeTotals?.paid > 0)
                  ? (incomeTotals!.paid! / maxTotal!) * 100
                  : 0
              }
            />
          </Grid>
        </Grid>
      );
    }

    return (
      <>
        <Box mt={'11px'}>
          <Grid container justifyContent='space-between'>
            <Grid item container xs={6}>
              <Grid container direction='column' justifyContent='space-between'>
                <Typography className={classes.textContainer}>
                  Create invoices on the run and stay on top of your income.
                </Typography>
                <Grid item>{renderViewMoreButton()}</Grid>
              </Grid>
            </Grid>
            <Grid container item xs={6} justifyContent='flex-end'>
              <img src={'https://media.q-83.com/site/kitly/Finance-card-empty.png'} alt='' className={classes.image} />
            </Grid>
          </Grid>
        </Box>
        <Box mt={'auto'}></Box>
      </>
    );
  };

  return (
    <TileContainer height={207} title='Finance' topRightContent={Boolean(hasFinanceContent) && renderViewMoreButton()}>
      <ScrollableBoxNoScroll parentHeight={257}>
        {financeCardLoading ? renderLoader() : renderContent()}
      </ScrollableBoxNoScroll>
    </TileContainer>
  );
};

export default FinanceCard;

const useStyles = makeStyles()((theme) => ({
  boldText: {
    fontWeight: 600
  },
  image: {
    height: '100%'
  },
  textContainer: {
    lineHeight: '20px'
  },
  buttonText: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontSize: 14,
    position: 'absolute',
    left: 20,
    bottom: 20
  },
  incomeTitle: {
    color: COLORS.MAROON,
    fontSize: 20,
    fontWeight: 500
  },
  incomeSubtitle: {
    color: '#6C6C72',
    fontSize: 10,
    marginBottom: 10
  },
  incomeBar: {
    marginBottom: 10
  },
  incomeRow: {
    marginBottom: 10
  },
  incomeRowText: {
    fontSize: 12,
    fontWeight: 500,
    color: '#333333'
  }
}));
