import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { formatNumber, formatPercentage } from 'lib/formatNumber';
import { Profile } from 'types/generated';

interface ComponentProps {
  profile: Profile;
}

interface StatBoxProps {
  stat: string;
  title: string;
  subtitle: string;
}

const styles = makeStyles()((theme) => ({
  statContainer: {
    border: 'solid #6C6C72 0.5px',
    borderRadius: 4,
    padding: '15px 0'
  },
  statTitle: {
    fontSize: 24,
    textAlign: 'center'
  },
  statSubtitle: {
    fontSize: 10,
    fontWeight: 600,
    textAlign: 'center',
    maxWidth: 160,
    margin: '0 auto'
  },
  statTip: {
    fontSize: 10,
    textAlign: 'center',
    maxWidth: 160,
    margin: '0 auto'
  },
  statCell: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 10px'
  },
  statDivider: {
    borderRight: 'solid #D8D8D8 2px',
    width: 2
  }
}));

const SummaryStats = ({ profile }: ComponentProps) => {
  const { classes } = styles();

  const StatBox = ({ stat, title, subtitle }: StatBoxProps) => (
    <>
      <Typography className={classes.statTitle}>{stat}</Typography>
      <Typography className={classes.statSubtitle}>{title}</Typography>
      <Typography className={classes.statTip}>{subtitle}</Typography>
    </>
  );

  return (
    <Grid container className={classes.statContainer}>
      <Grid item xs={4} className={`${classes.statCell} ${classes.statDivider}`}>
        <StatBox
          stat={formatNumber(profile.public?.stats?.followerCount)}
          title={'Followers'}
          subtitle={'Total users following this account'}
        />
      </Grid>
      <Grid item xs={4} className={`${classes.statCell} ${classes.statDivider}`}>
        <StatBox
          stat={formatNumber(profile.tiktokMediaStats?.view_count?.median)}
          title={'Median video plays'}
          subtitle={'Median plays on a piece of feed content'}
        />
      </Grid>
      <Grid item xs={4} className={`${classes.statCell}`}>
        <StatBox
          stat={`${formatPercentage(profile.tiktokMediaStats?.totalEngagement_percentageOfPlays?.median)}%`}
          title={'Engagement'}
          subtitle={'Total engagements Vs plays (Likes, Comments & Shares)'}
        />
      </Grid>
    </Grid>
  );
};

export default SummaryStats;
