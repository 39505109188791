import React, { ReactNode } from 'react';

import { Grid, Tooltip, Theme, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { adjustColor } from 'lib/themeUtils';
import { formatNumber, getPercentage } from 'lib/formatNumber';

const useStyles = makeStyles()((theme: Theme) => ({
  chart: {
    display: 'flex',
    overflow: 'hidden',
    padding: `${theme.spacing(2)} 0`
  },
  chartElement: {
    padding: `${theme.spacing(2)} 0`,
    textAlign: 'center',
    transition: 'width 2s'
  },
  leftLabel: {},
  rightLabel: {
    textAlign: 'right'
  }
}));

export type RatioChartElement = {
  name: string;
  value: number;
  color?: string;
  showValue?: boolean;
  showTooltip?: boolean;
  style?: React.CSSProperties;
};

export type HorizontalRatioChartProps = {
  dataList: RatioChartElement[];
  baseColor?: string;
  leftLabel?: string | ReactNode;
  rightLabel?: string | ReactNode;
};

const HorizontalRatioChart: React.FC<HorizontalRatioChartProps> = (props) => {
  const { classes } = useStyles();
  const theme = useTheme();

  const { dataList, leftLabel, rightLabel } = props;

  const dataSum = dataList.reduce((acc, curr) => (acc += curr.value), 0);
  const { main } = theme.palette.primary;

  const displayBarElement = (element: RatioChartElement, index: number) => {
    const { name, showValue = false, value, color, style, showTooltip = true } = element;

    const width = `${getPercentage(value, dataSum)}%`;
    const backgroundColor = color ? color : adjustColor(main, 10 * index);
    const title = `${name}: ${formatNumber(value)}`;

    const barElement = (
      <div
        className={classes.chartElement}
        style={{
          width,
          backgroundColor,
          background: 'linear-gradient(45deg, #FBCDDF, #C5D5E2)',
          ...style
        }}
      >
        {showValue && <span>{title}</span>}
      </div>
    );

    return (
      <div key={title} style={{ width: '100%' }}>
        {showTooltip ? (
          <Tooltip title={title} key={title}>
            {barElement}
          </Tooltip>
        ) : (
          barElement
        )}
      </div>
    );
  };

  return (
    <Grid container>
      <Grid item xs={rightLabel ? 6 : 12}>
        {leftLabel && leftLabel}
      </Grid>
      {rightLabel && (
        <Grid item xs={6} className={classes.rightLabel}>
          {rightLabel}
        </Grid>
      )}
      <Grid className={classes.chart} xs={12} item>
        {dataList.map(displayBarElement)}
      </Grid>
    </Grid>
  );
};

export default HorizontalRatioChart;
