import { useEffect, useMemo, CSSProperties, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Grid, Box, Link, CircularProgress, Skeleton } from '@mui/material';
import { useUserConfig } from 'hooks/useUserConfig';
import { useGetPlatformAdQuery, AdType } from '../../types/generated';
import useSubscribePopupCtx from 'context/useSubscribePopupCtx';
import { useErrorHandlerHook } from '../../hooks/useErrorCatchHook';
import useTracking from '../../context/useTrackingCtx';
import { makeStyles } from 'tss-react/mui';

/* 
  <ContentfulAdBanner /> has been added to these files: 
    src/components/Dashboard/index.tsx
    src/components/pages/Settings.tsx
    src/components/pages/MediaKitsPage/index.tsx
    src/components/pages/ReportPage/index.tsx
    src/components/pages/Finance/FinancePage.tsx
    src/components/pages/BrandsPage/index.tsx
*/
interface IContentfulAdBannerProps {
  adLocation: string;
  orientation?: 'horizontal' | 'vertical';
  height?: number | string;
  width?: number | string;
  style?: CSSProperties;
  skeletonStyle?: CSSProperties;
}
const ContentfulAdBanner = ({
  adLocation,
  orientation = 'horizontal',
  height,
  width,
  style,
  skeletonStyle
}: IContentfulAdBannerProps): JSX.Element | null => {
  const { openSubscribePopup } = useSubscribePopupCtx();
  const { apolloHandler } = useErrorHandlerHook();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { userData } = useUserConfig();
  const { trackEvent } = useTracking();
  const isHorizontal = orientation === 'horizontal';
  const { pathname } = useLocation();
  const [imageLoaded, setImageLoaded] = useState(false);

  const boxHeight = height ? height : isHorizontal ? 160 : 830;
  const boxWidth = width ? width : isHorizontal ? 930 : 200;

  const { data, loading, error } = useGetPlatformAdQuery({
    variables: {
      params: {
        containerWidth: parseInt(`${boxWidth}`),
        containerHeight: parseInt(`${boxHeight}`),
        path: pathname,
        type: AdType.Kitlyweb
      }
    },
    fetchPolicy: 'network-only'
  });

  const adContent = useMemo(() => {
    if (data?.getPlatformAd) {
      return data.getPlatformAd;
    }
    return undefined;
  }, [data]);

  useEffect(() => {
    if (error) {
      apolloHandler(error);
    }
  }, [error, apolloHandler]);

  const user = userData?.myUser;

  const handleClick = () => {
    trackEvent('platform_ad', 'clicked_ad', {
      adLocation,
      userId: user?._id,
      userSub: user?.activeSubscription,
      destination: adContent?.linkUrl,
      adTitle: adContent?.title,
      freeUserAd: adContent?.freeUserAd
    });
    if (adContent?.linkUrl?.includes('https') || adContent?.linkUrl?.startsWith('mailto:')) {
      window.open(adContent?.linkUrl, '_blank');
      return;
    }

    if (adContent?.linkUrl === 'subscribe-popup') {
      openSubscribePopup();
    } else {
      const destination = `/${adContent?.linkUrl}`;
      navigate(destination);
    }
  };

  return (
    <Grid container alignItems='center' justifyContent='center' style={{ position: 'relative' }}>
      {loading ? (
        <Skeleton
          variant='rectangular'
          className={classes.skeleton}
          style={
            skeletonStyle
              ? { backgroundColor: `rgba(55,55,55,0.6)`, height: boxHeight, width: boxWidth, ...skeletonStyle }
              : { backgroundColor: `rgba(55,55,55,0.4)`, height: boxHeight, width: boxWidth }
          }
        />
      ) : (
        <Box
          className={classes.adContainer}
          style={
            style
              ? {
                  height: boxHeight,
                  width: boxWidth,
                  ...style
                }
              : {
                  height: boxHeight,
                  width: boxWidth
                }
          }
        >
          {adContent && !loading && (
            <>
              <Link
                component={adContent?.linkUrl?.includes('https') ? 'a' : 'button'}
                onClick={handleClick}
                underline='hover'
              >
                <img
                  src={adContent?.mediaUrl || ''}
                  alt={adContent?.altText || ''}
                  style={{ height: boxHeight, width: boxWidth, opacity: imageLoaded ? 1 : 0.1 }}
                  onLoadStart={() => setImageLoaded(false)}
                  onLoad={() => {
                    setImageLoaded(true);
                    trackEvent('platform_ad', 'loaded_ad', {
                      adLocation,
                      userId: user?._id,
                      userSub: user?.activeSubscription,
                      destination: adContent?.linkUrl,
                      adTitle: adContent?.title,
                      freeUserAd: adContent?.freeUserAd
                    });
                  }}
                />
              </Link>
              {!imageLoaded && (
                <Grid container style={{ position: 'absolute' }} justifyContent='center' alignItems='center'>
                  <CircularProgress />
                </Grid>
              )}
            </>
          )}
        </Box>
      )}
    </Grid>
  );
};

export default ContentfulAdBanner;

const useStyles = makeStyles()({
  adContainer: {
    alignItems: 'center',
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 8
  },
  skeleton: {
    border: '0.5px solid #333',
    borderRadius: 4,
    marginBottom: 8
  }
});
