import { useState, useMemo } from 'react';
import ButtonBase from '@mui/material/ButtonBase';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import ArrowLeft from 'assets/icons/ArrowLeft';
import ArrowRight from 'assets/icons/ArrowRight';

import { isEmpty, filter } from 'lodash';

import allChannels from 'lib/channels';
import { SocialChannel } from 'types/generated';
import { useTheme } from '@mui/material';

interface SelectChannelProps {
  title?: string;
  availableChannels?: string[];
  channels: Array<SocialChannel | string>;
  setChannels: (arg: SocialChannel) => void;
  isMultiple?: boolean;
  disabled?: boolean;
  pageSize?: number;
  buttonPadding?: number;
}

const SelectChannel = (props: SelectChannelProps) => {
  const { title, channels, availableChannels, setChannels, isMultiple, disabled, pageSize, buttonPadding } = props;

  const theme = useTheme();
  const { palette } = theme;

  const [pageNumber, setPageNumber] = useState(0);

  const pageSizeToUse = pageSize || 8;

  const channelsToUse = useMemo(
    () => (isEmpty(availableChannels) ? allChannels : filter(allChannels, (o) => availableChannels!.includes(o.value))),
    [availableChannels]
  );

  const numberOfPages = Math.floor(channelsToUse.length / pageSizeToUse);

  const previousPageSize = pageNumber * pageSizeToUse;

  const paginatedChannels = channelsToUse.slice(previousPageSize, previousPageSize + pageSizeToUse);

  return (
    <>
      <Grid item xs={12}>
        {typeof title === 'string' ? (
          <Typography variant='body1' style={{ fontWeight: 500, fontSize: 14 }}>
            {title}
          </Typography>
        ) : (
          <Typography variant='body1' style={{ fontWeight: 500, fontSize: 14 }}>
            Select Social Channel{isMultiple && 's'}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} style={{ minHeight: 50, display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
        {pageNumber > 0 && (
          <Grid item style={{ marginRight: 10 }}>
            <ButtonBase onClick={() => setPageNumber(pageNumber - 1)}>
              <ArrowLeft fill={palette.primary.main} size={20} />
            </ButtonBase>
          </Grid>
        )}
        {paginatedChannels.map((channel) => {
          const isActive = channels?.includes(channel.value);
          const borderStyling = isActive ? `solid 2px ${palette.primary.main}` : `solid 0.5px #D8D8D8`;

          if (channel.logo) {
            return (
              <Tooltip
                key={`channel-${channel.value}`}
                title={<Typography style={{ textTransform: 'capitalize', color: '#FFF' }}>{channel.label}</Typography>}
              >
                <ButtonBase
                  disabled={disabled}
                  onClick={() => setChannels(channel.value)}
                  style={{
                    margin: 5,
                    padding: buttonPadding || 5,
                    borderRadius: '100%',
                    border: borderStyling,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <img src={channel.logo} alt={channel.label} style={{ height: 24, width: 24, borderRadius: 5 }} />
                </ButtonBase>
              </Tooltip>
            );
          }
          return null;
        })}
        {pageNumber < numberOfPages && (
          <Grid item style={{ height: 20, width: 20, marginLeft: 10 }}>
            <ButtonBase onClick={() => setPageNumber(pageNumber + 1)}>
              <ArrowRight fill={palette.primary.main} size={20} />
            </ButtonBase>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default SelectChannel;
