// Looks like a button for button-in-button circumstances (e.g. modals)
// Styles copied from MUIButton overrides in theme.js - MUST MATCH

import React from 'react';
import { makeStyles } from 'tss-react/mui';

const styles = makeStyles()((theme) => ({
  buttonBasics: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    lineHeight: '24.5px',
    padding: '6px 16px'
  },
  root: {
    minHeight: '40px',
    minWidth: '162px',
    color: theme.palette.primary.main,
    borderWidth: '4px',
    fontSize: '14px',
    textTransform: 'capitalize',
    fontWeight: 500,
    '&:hover': {
      color: '#FFF'
    }
  },
  contained: {
    color: '#FFF',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    },
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)'
  },
  outlined: {
    color: theme.palette.primary.main,
    backgroundColor: '#FFF',
    border: `0.5px solid ${theme.palette.primary.main}`,
    '&:hover': {
      background: '#FFF',
      color: theme.palette.secondary.main,
      border: `0.5px solid ${theme.palette.secondary.main}`
    }
  },
  large: {
    fontSize: '16px',
    minHeight: '60px',
    minWidth: '305px'
  },
  small: {
    minWidth: 'initial',
    minHeight: 'initial',
    padding: '3px 15px'
  }
}));

interface FakeButtonProps {
  children: string | JSX.Element;
  variant?: 'contained' | 'outlined';
  size?: 'large' | 'small';
  className?: any;
  style?: Object;
  onClick?: () => void;
}

const FakeButton = (props: FakeButtonProps) => {
  const { children, className, style, onClick } = props;
  const { classes } = styles();
  const variant = props.variant || 'contained';
  const size = props.size || '';

  return (
    <div
      onClick={onClick}
      className={`
        ${classes.buttonBasics} 
        ${classes.root} 
        ${classes[variant]} 
        ${classes[size]} 
        ${className ? className : ''}
      `}
      style={style ? style : {}}
    >
      <div>{children}</div>
    </div>
  );
};

export default FakeButton;
