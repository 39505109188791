import React from 'react';
import Avatar from '@mui/material/Avatar';
import { CircularProgress, Typography, Grid, MenuItem, Select, Link } from '@mui/material';
import { find, isEmpty } from 'lodash';
import { gql, useQuery } from '@apollo/client';
import { CollabCreation } from 'components/pages/CollabsPage/CollabCreation';

const backupUserImg = 'https://media.q-83.com/site/user.png';

interface CollabSelectorProps {
  selectedId?: string;
  selectCollab: (arg?: string) => void;
}

interface CollabShape {
  _id: string;
  name: string;
  avatar: string;
}

const COLLABORATION_LIST = gql`
  query {
    getCollaborationList {
      _id
      name
      avatar
    }
  }
`;

const CollabSelector = (props: CollabSelectorProps) => {
  const { selectedId, selectCollab } = props;
  const { loading, error, data } = useQuery(COLLABORATION_LIST);

  const collaborationList = data?.getCollaborationList || [];

  const formatValues = (selectedId: string) => {
    if (!selectedId) return 'Select campaign';
    const selectedCollab = find(collaborationList, { _id: selectedId });
    const name = selectedCollab?.name || `#${selectedId}`;
    return name;
  };

  if (loading) return <CircularProgress style={{ height: 15, width: 15 }} />;
  if (error) return <Typography>Error returning campaigns list</Typography>;
  if (isEmpty(collaborationList))
    return (
      <Grid container justifyContent='center'>
        <Typography>No campaigns found.</Typography>

        <CollabCreation>
          <Typography>
            <Link underline='hover'>Create one here</Link>
          </Typography>
        </CollabCreation>
      </Grid>
    );

  return (
    <Select
      variant='standard'
      data-cy='campaign-select'
      value={selectedId}
      onChange={(e: any) => selectCollab(e.target.value)}
      fullWidth={true}
      displayEmpty={true}
      renderValue={(selectedId: any) => formatValues(selectedId)}
    >
      <MenuItem value=''>
        Select collaboration
        {loading && <CircularProgress size={15} style={{ marginLeft: '10px' }} />}
      </MenuItem>
      {collaborationList &&
        collaborationList.map((o: CollabShape) => (
          <MenuItem key={`campaign-${o._id}`} value={o._id}>
            <DropdownOption collab={o} />
          </MenuItem>
        ))}
    </Select>
  );
};

const DropdownOption = (props: { collab: CollabShape }) => {
  const { name, avatar } = props.collab;
  return (
    <Grid container style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
      <Avatar
        src={avatar || backupUserImg}
        onError={(e: any) => {
          e.persist();
          e.target.src = backupUserImg;
        }}
        style={{ width: '20px', height: '20px', margin: '0 5px' }}
      />
      {name}
    </Grid>
  );
};

export default CollabSelector;
