import React, { useState, useRef } from 'react';
import ButtonBase from '@mui/material/ButtonBase';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const businessScreenshots = [
  'https://media.q-83.com/troubleshooting/Business1.png',
  'https://media.q-83.com/troubleshooting/Business2.png',
  'https://media.q-83.com/troubleshooting/Business3.png'
];

const notBusinessScreenshots = [
  'https://media.q-83.com/troubleshooting/NotBusiness1.png',
  'https://media.q-83.com/troubleshooting/NotBusiness2.png',
  'https://media.q-83.com/troubleshooting/NotBusiness3.png',
  'https://media.q-83.com/troubleshooting/NotBusiness4.png',
  'https://media.q-83.com/troubleshooting/NotBusiness5.png'
];

interface ITroublshootingSection {
  hasBusinessAccount: boolean;
}

const Troubleshooting = () => {
  const carouselRef = useRef<AliceCarousel>(null);
  const [view, setView] = useState('buttons');

  const renderGalleryNav = ({ nav }) => {
    let icon, placement, handleDirection;
    if (nav === 'prev') {
      icon = <ChevronLeftIcon style={{ color: '#A9A9A9', fontSize: '18px' }} />;
      placement = { left: 10 };
      handleDirection = () => carouselRef.current?.slidePrev();
    } else {
      icon = <ChevronRightIcon style={{ color: '#A9A9A9', fontSize: '18px' }} />;
      placement = { right: 10 };
      handleDirection = () => carouselRef.current?.slideNext();
    }
    return (
      <ButtonBase
        onClick={handleDirection}
        style={{ ...placement, position: 'absolute', bottom: '50%', zIndex: 100, borderRadius: '100%' }}
      >
        <div
          style={{
            backgroundColor: 'rgb(255, 255, 255, 0.5)',
            borderRadius: '100%',
            height: '35px',
            width: '35px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {icon}
        </div>
      </ButtonBase>
    );
  };

  const renderGallery = ({ hasBusinessAccount }: ITroublshootingSection) => {
    const handleOnDragStart = (e) => e.preventDefault();
    const images = hasBusinessAccount ? businessScreenshots : notBusinessScreenshots;
    return (
      <Grid item xs={12} md={6} style={{ padding: '10px', position: 'relative' }}>
        {renderGalleryNav({ nav: 'prev' })}
        <AliceCarousel infinite={false} ref={carouselRef}>
          {images.map((url, i) => {
            const key = hasBusinessAccount ? 'businessScreenshot' + i : 'notBusinessScreenshot' + i;
            return (
              <Grid
                key={key}
                style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                <img
                  src={url}
                  alt=''
                  style={{ maxHeight: '350px', objectFit: 'contain' }}
                  onDragStart={handleOnDragStart}
                />
              </Grid>
            );
          })}
        </AliceCarousel>
        {renderGalleryNav({ nav: 'next' })}
      </Grid>
    );
  };

  const renderChoiceBox = ({ hasBusinessAccount }: ITroublshootingSection) => {
    let icon, text, handleChoice;
    if (hasBusinessAccount) {
      icon = <CheckCircleRoundedIcon style={{ color: '#A9A9A9', marginRight: '15px', fontSize: '48px' }} />;
      text = 'My instagram account is already a business or creator account';
      handleChoice = () => setView('troubleshoot1');
    } else {
      icon = <CancelIcon style={{ color: '#A9A9A9', marginRight: '15px', fontSize: '48px' }} />;
      text = (
        <span>
          My instagram account is <b>not</b> a business or creator account
        </span>
      );
      handleChoice = () => setView('troubleshoot2');
    }
    return (
      <ButtonBase onClick={handleChoice}>
        <Paper
          style={{
            padding: '15px',
            margin: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '250px'
          }}
        >
          {icon}
          <span style={{ fontSize: '16px', textAlign: 'left' }}>{text}</span>
        </Paper>
      </ButtonBase>
    );
  };

  const renderTroubleshoot1 = () => {
    return (
      <>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            paddingLeft: '15px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            marginBottom: '30px'
          }}
        >
          <span style={{ marginBottom: '20px' }}>
            If your <b>Instagram</b> account is <b>already a business or creator</b> account:
          </span>
          <ol style={{ paddingLeft: '20px', paddingBottom: 0, marginBottom: 0 }}>
            <li style={{ marginBottom: '10px' }}>
              Go to your <b>Instagram profile</b> via your profile icon in the bottom-right of the Instagram app
            </li>
            <li style={{ marginBottom: '10px' }}>
              Click <b>'Edit Profile'</b>
            </li>
            <li style={{ marginBottom: '10px' }}>
              Click <b>'Page'</b> located under 'Business Information'
            </li>
            <li style={{ marginBottom: '10px' }}>
              Ensure you have a connected <b>Facebook Page</b> selected
            </li>
            <li style={{ marginBottom: '10px' }}>
              If you do not - click <b>'Create a new Facebook Page'</b> located at the bottom
            </li>
            <li style={{ marginBottom: '10px' }}>Close this troubleshooting window and refresh the page</li>
          </ol>
          <span style={{ marginTop: '10px' }}>
            This will automatically connect your Instagram profile to your Kitly Dashboard
          </span>
        </Grid>
        {renderGallery({ hasBusinessAccount: true })}
      </>
    );
  };

  const renderTroubleshoot2 = () => {
    return (
      <>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            paddingLeft: '15px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            marginBottom: '10px'
          }}
        >
          <span style={{ marginBottom: '20px' }}>
            {' '}
            If your <b>Instagram</b> account is <b>not a business or creator</b> account:
          </span>
          <ol style={{ paddingLeft: '20px', paddingBottom: 0, marginBottom: 0 }}>
            <li style={{ marginBottom: '10px' }}>
              Go to your <b>Instagram profile</b> via your profile icon in the bottom-right of the Instagram app
            </li>
            <li style={{ marginBottom: '10px' }}>
              Click the <b>three lines</b> in the top right corner
            </li>
            <li style={{ marginBottom: '10px' }}>
              Click <b>'Settings'</b>
            </li>
            <li style={{ marginBottom: '10px' }}>
              Click <b>'Account'</b>
            </li>
            <li style={{ marginBottom: '10px' }}>
              Click <b>'Switch to Business'</b> or <b>'Get More Tools'</b>
            </li>
            <li style={{ marginBottom: '10px' }}>Follow the prompted steps</li>
            <li style={{ marginBottom: '10px' }}>Close this troubleshooting window and refresh the page</li>
          </ol>
        </Grid>
        {renderGallery({ hasBusinessAccount: false })}
      </>
    );
  };

  return (
    <Grid container style={{ position: 'relative' }}>
      {view !== 'buttons' && (
        <ButtonBase
          onClick={() => setView('buttons')}
          style={{ color: '#A9A9A9', position: 'absolute', top: 0, left: 0 }}
        >
          <ArrowBackRoundedIcon
            style={{ fontSize: '24px', color: '#fff', backgroundColor: '#A9A9A9', borderRadius: '50%' }}
          />
        </ButtonBase>
      )}

      <Grid item xs={12} style={{ fontWeight: 500, fontSize: '20px', textAlign: 'center', marginBottom: '30px' }}>
        Troubleshooting
      </Grid>

      <Grid container item xs={12} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
        {view === 'buttons' && (
          <>
            {renderChoiceBox({ hasBusinessAccount: true })}
            {renderChoiceBox({ hasBusinessAccount: false })}
          </>
        )}
        {view === 'troubleshoot1' && renderTroubleshoot1()}
        {view === 'troubleshoot2' && renderTroubleshoot2()}
      </Grid>
    </Grid>
  );
};

export default Troubleshooting;
