import { ApolloProvider } from '@apollo/client';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import { App } from 'components/App';
import { client } from 'config/apolloClient';
import theme from 'config/muiTheme';
import { createRoot } from 'react-dom/client';
import { ErrorHandlerProvider } from 'context/useErrorHandlerCtx';
import { createHashRouter, Route, RouterProvider } from 'react-router-dom';
import { AuthProvider } from 'context/useAuthCtx';
import { TrackingProvider } from './context/useTrackingCtx';

const rootElement = document.getElementById('root');

if (rootElement) {
  const router = createHashRouter([
    {
      path: '/*',
      element: (
        <AuthProvider>
          <Route
            path='*'
            element={
              <TrackingProvider>
                <App />
              </TrackingProvider>
            }
          />
        </AuthProvider>
      )
    }
  ]);
  const root = createRoot(rootElement!);

  root.render(
    <ApolloProvider client={client}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <ErrorHandlerProvider>
            <RouterProvider router={router} />
          </ErrorHandlerProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </ApolloProvider>
  );
}
