import find from 'lodash/find';

export type PlanEntry = {
  planId: string;
  id: string;
  price: number;
  name: string;
  title: string;
  billingFrequency: number;
  actionText: string;
  stripeIds?: Array<string>;
};

const allPlans: Array<PlanEntry> = [
  {
    planId: 'kitly-usd-0',
    id: 'kitly-usd-0',
    price: 0,
    name: 'FREE',
    title: 'FREE',
    billingFrequency: 0,
    actionText: 'Sign Up'
  },
  {
    planId: 'kitly-usd-1',
    id: 'kitly-usd-1',
    price: 9,
    name: 'Professional',
    title: 'Professional',
    actionText: 'Upgrade Now',
    billingFrequency: 1,
    stripeIds:
      process.env.REACT_APP_DEPLOYMENT === 'production'
        ? ['price_1JbfLqJTilPjzlKdmAuLqZFT', 'price_1IvCpOJTilPjzlKdS2rq1W6d']
        : ['price_1Jbex0JTilPjzlKd18Q8bhGB', 'price_1IggVHJTilPjzlKd17pM5d7G']
  },
  {
    planId: 'kitly-usd-1-yearly',
    id: 'kitly-usd-1-yearly',
    price: 89,
    name: 'Professional',
    title: 'Professional',
    actionText: 'Upgrade Now',
    billingFrequency: 12,
    stripeIds:
      process.env.REACT_APP_DEPLOYMENT === 'production'
        ? ['price_1JbfLwJTilPjzlKdd9w8affw', 'price_1IvCpOJTilPjzlKdtF3tRwgo']
        : ['price_1Jbf2DJTilPjzlKdsleJUr8J', 'price_1IggVHJTilPjzlKdGaMJGpbQ']
  },
  {
    planId: 'kitly-usd-2',
    id: 'kitly-usd-2',
    price: 19,
    name: 'Publisher',
    title: 'Publisher',
    actionText: 'Go Unlimited',
    billingFrequency: 1,
    stripeIds:
      process.env.REACT_APP_DEPLOYMENT === 'production'
        ? ['price_1JbfM1JTilPjzlKdhKYPGyVf', 'price_1IvCpOJTilPjzlKdOV2ja2HD']
        : ['price_1Jbf3CJTilPjzlKdmVm1FoLV', 'price_1IggYPJTilPjzlKdLeQ9ZGgV']
  },
  {
    planId: 'kitly-usd-2-yearly',
    id: 'kitly-usd-2-yearly',
    price: 190,
    name: 'Publisher',
    title: 'Publisher',
    actionText: 'Go Unlimited',
    billingFrequency: 12,
    stripeIds:
      process.env.REACT_APP_DEPLOYMENT === 'production'
        ? ['price_1JbfM6JTilPjzlKdUxoJFy2Y', 'price_1IvCpOJTilPjzlKdTq8lfNU2']
        : ['price_1Jbf4FJTilPjzlKdbgcQFuuU', 'price_1IggYPJTilPjzlKdFRa1HSPm']
  }
];

const getPlan = (planId?: string | null) => {
  return planId ? find(allPlans, { planId }) : null;
};

export const getPlanForStripeId = (id?: string | null) => {
  return id ? find(allPlans, (o) => Boolean(o?.stripeIds?.includes(id))) : null;
};

export default getPlan;
