import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { useMobileView } from '../../hooks/useMobileView';
import { formatError } from '../../lib/formatStrings';
import dropin from 'braintree-web-drop-in';
import { useErrorHandlerHook } from '../../hooks/useErrorCatchHook';

import q83API from '../../api/q83API';

interface IPayment {
  chosenPlan?: string;
  couponCode?: string;
  handleComplete?: () => void;
  handleCancel?: () => void;
}

const Payment = ({ chosenPlan, couponCode, handleComplete, handleCancel }: IPayment) => {
  const mobileView = useMobileView();
  const [instance, setInstance] = useState<any>();
  const [error, setError] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(true);

  const { axiosHandler } = useErrorHandlerHook();

  useEffect(() => {
    q83API.get('payment/token').then((response) => {
      const token = response?.data?.token;
      console.log('response', response);
      console.log('token', token);
      dropin.create(
        {
          authorization: token,
          container: '#dropin-container',
          paypal: {
            flow: 'vault',
            buttonStyle: {
              color: 'blue',
              shape: 'rect',
              size: 'medium'
            }
          },
          paypalCredit: {
            flow: 'vault'
          }
        },
        (createErr: string, instance: any) => {
          if (createErr) {
            setError(createErr);
          } else {
            setInstance(instance);
          }
          setIsLoading(false);
        }
      );
    });
  }, [setIsLoading]);

  const handleClick = () => {
    if (instance) {
      setIsLoading(true);
      setError(undefined);

      instance.requestPaymentMethod((requestPaymentMethodErr: string, payload: any) => {
        if (requestPaymentMethodErr) {
          console.log('requestPaymentMethodErr', requestPaymentMethodErr);
          setIsLoading(false);
          setError('Please select a valid payment method.');
        } else {
          if (chosenPlan) {
            q83API
              .post('payment/subscription', { planId: chosenPlan, couponCode, ...payload })
              .then(() => {
                if (handleComplete) handleComplete();
              })
              .catch((ex) => {
                axiosHandler(ex, `Error subscribing to plan`);
              })
              .finally(() => {
                setIsLoading(false);
              });
          } else {
            q83API
              .post('payment/method', payload)
              .then(() => {
                if (handleComplete) handleComplete();
              })
              .catch((ex) => {
                axiosHandler(ex, `Error updating payment method`);
              })
              .finally(() => {
                setIsLoading(false);
              });
          }
        }
      });
    } else {
      setError('Unable to get payment authorization.');
      setIsLoading(false);
    }
  };

  return (
    <div
      style={{
        minWidth: mobileView ? 300 : 495,
        minHeight: 350,
        display: 'flex',
        flexDirection: 'column',
        padding: '10px'
      }}
    >
      <div>
        <Typography variant='h4' style={{ fontWeight: 500, marginBottom: 10 }}>
          Select a Payment Method
        </Typography>
        {instance === null && (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <CircularProgress style={{ marginTop: 50 }} />
          </div>
        )}
        {isLoading && (
          <div
            style={{ margin: '10px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <CircularProgress color='primary' />
          </div>
        )}
        <div style={{ display: instance ? 'inherit' : 'none' }} id='dropin-container'></div>
        {error && <div style={{ color: 'red', textAlign: 'center' }}>{formatError(error)}</div>}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {handleCancel && (
            <Button
              variant='outlined'
              disabled={isLoading}
              style={{ marginRight: 10, minWidth: 98 }}
              onClick={handleCancel}
            >
              Back
            </Button>
          )}
          {instance && (
            <Button
              color='primary'
              variant='contained'
              disabled={isLoading}
              onClick={() => {
                if (handleClick) handleClick();
              }}
              style={{ minWidth: 134 }}
            >
              Confirm
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Payment;
