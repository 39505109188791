import { Switch } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const styles = makeStyles()((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1)
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: '1px solid grey'
      }
    },
    '&$focusVisible $thumb': {
      color: '#fff',
      border: '6px solid #fff'
    }
  },
  thumb: {
    color: '#fff',
    width: 24,
    height: 24
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[100],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border'])
  },
  checked: {},
  focusVisible: {}
}));

export const IOSSwitch = ({ ...props }) => {
  const { classes } = styles();
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked
      }}
      {...props}
    />
  );
};
