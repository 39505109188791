import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import {
  useTheme,
  Typography,
  MenuItem,
  CircularProgress,
  Button,
  ButtonBase,
  Card,
  CardContent,
  Box,
  Select,
  Grid
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Modal from 'components/Modal';
import ProfileListItem from './ProfileListItem';
import { GET_PROFILE_LIST } from 'graphql/queries/profiles';
import PieChartIcon from '../../../assets/icons/chart-pie-36.svg';
import RoundTick from 'assets/icons/RoundTick';
import { findIndex, isEmpty, find, get, filter, unionBy, compact, map } from 'lodash';
import Slider from 'react-slick';
import { useErrorHandlerHook } from 'hooks/useErrorCatchHook';

import { insightList, MetricDetails } from 'lib/featuredInsights';
import { ElementContentType, FeaturedInsightsContent, FeaturedInsightField, Profile } from 'types/generated';
import { gcd_more_than_two_numbers } from 'utils/utils';

import { EditBoxProps } from './MediaKitEditBox';
import AddIcon from 'assets/components/AddIcon';

const styles = makeStyles()((theme) => ({
  error: { color: 'red', marginTop: 12 },
  textPreview: { marginTop: 12, fontStyle: 'italic', fontSize: 14 },
  previewContainer: { fontSize: 14, paddingTop: 10, paddingBottom: 20, width: '100%' },
  previewEditContainer: {
    backgroundColor: theme.palette.info.light,
    paddingBottom: 10,
    marginBottom: 10,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  },
  previewTitle: { fontSize: 16, fontWeight: 500 },
  placeholderContainer: {
    width: '100%',
    paddingBottom: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 200
  },
  placeholderTitle: {
    color: theme.palette.primary.main,
    margin: 4
  },
  placeholderImage: {
    width: 28,
    height: 28
  },
  metricsContainer: {
    backgroundColor: theme.palette.info.light,
    padding: 10,
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    marginBottom: 20
  },
  profileItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch'
  },
  item: {
    textAlign: 'center',
    marginRight: 10,
    width: '100%',
    paddingRight: 2,
    paddingLeft: 2
  },
  itemCard: {
    width: '100%',
    marginBottom: 10,
    height: 100
  },
  cardTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  cardTitleSpacer: {
    width: 24
  },
  cardDivider: {
    display: 'block',
    border: 'none',
    width: 31,
    height: 2,
    margin: '4px auto',
    backgroundImage: `linear-gradient(
          to left,
          #cccccc,
          ${theme.palette.background.default} 
        )`
  },
  icon: {
    width: 12,
    height: 12,
    marginRight: 8
  },
  text1: {
    fontSize: 22,
    color: theme.palette.primary.main
  },
  text2: {
    fontSize: 14,
    fontWeight: 600
  },
  text3: {
    fontSize: 10
  },
  text4: {
    fontSize: 10
  },
  slider: {
    width: '100%'
  },
  slideContainer: { position: 'relative' }
}));

function isFeaturedInsightsContent(content?: ElementContentType | null): content is FeaturedInsightsContent {
  return content && (content as FeaturedInsightsContent).featured_insights_content !== undefined ? true : false;
}

const FeaturedInsightsBox = ({ element: { content }, handleUpdate, isEditing, setIsEditing }: EditBoxProps) => {
  const { classes } = styles();
  const { apolloHandler } = useErrorHandlerHook();

  const [featuredInsights, setFeaturedInsights] = useState<Array<FeaturedInsightField>>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const featuredInsightsContent = useMemo(
    () => (isFeaturedInsightsContent(content) ? content?.featured_insights_content || [] : []),
    [content]
  );

  useEffect(() => {
    if (!isEditing) {
      setFeaturedInsights(featuredInsightsContent ? compact(featuredInsightsContent) : []);
    }
  }, [featuredInsightsContent, setFeaturedInsights, isEditing]);

  const { data, loading, error } = useQuery(GET_PROFILE_LIST);

  useEffect(() => {
    if (error) {
      apolloHandler(error);
    }
  }, [error, apolloHandler]);

  const profiles: Array<Profile> = useMemo(
    () =>
      data
        ? compact(unionBy(data.getProfiles, data.getAmbassadorProfiles, map(data.getTalentList, 'profile'), 'id'))
        : [],
    [data]
  );

  const toggleMetric = useCallback(
    (metric: FeaturedInsightField) => {
      const existingIndex = findIndex(featuredInsights, metric);

      const newContent = isEmpty(featuredInsights)
        ? [metric]
        : existingIndex > -1
        ? featuredInsights.filter((o: any, i: number) => {
            return Boolean(i !== existingIndex);
          })
        : featuredInsights.concat([metric]);

      setFeaturedInsights(newContent);
    },
    [featuredInsights]
  );

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return (
      <Typography align='center' variant='h5' className={classes.error}>
        {String(error.message)}
      </Typography>
    );
  }

  if (isEditing) {
    return (
      <>
        {isModalOpen && (
          <InsightSelectModal
            featuredInsights={featuredInsights}
            classes={classes}
            toggleMetric={toggleMetric}
            onComplete={() => setIsModalOpen(false)}
            profiles={profiles}
          />
        )}
        <Box className={classes.previewEditContainer}>
          {isEmpty(featuredInsights) ? (
            <Box className={classes.placeholderContainer}>
              <img src={PieChartIcon} alt='Pie Chart Icon' className={classes.placeholderImage} />
              <Typography className={classes.placeholderTitle}>Add up to 12 featured insights</Typography>
            </Box>
          ) : (
            <InsightSlider
              insightList={insightList}
              classes={classes}
              profiles={profiles}
              featuredInsights={featuredInsights}
            />
          )}
          <Button variant='outlined' color='primary' onClick={() => setIsModalOpen(true)}>
            Add
          </Button>
        </Box>
        <Grid container justifyContent='flex-start'>
          <Button
            variant='outlined'
            color='primary'
            onClick={() => {
              handleUpdate({ ...content, featured_insights_content: featuredInsights });
            }}
          >
            {`Save & Close`}
          </Button>
        </Grid>
      </>
    );
  }

  return (
    <Box className={classes.previewContainer}>
      {isEmpty(featuredInsights) ? (
        <ButtonBase className={classes.placeholderContainer} onClick={() => setIsEditing(true)}>
          <AddIcon size={35} strokeWidth={3} />
        </ButtonBase>
      ) : (
        <InsightSlider
          insightList={insightList}
          classes={classes}
          profiles={profiles}
          featuredInsights={featuredInsights}
        />
      )}
    </Box>
  );
};

const profileSelectStyles = makeStyles()((theme) => ({
  select: {
    height: 60,
    width: 300,
    padding: '0 !important',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch'
  },
  icon: {
    marginRight: 20
  }
}));

const InsightSelectModal = ({
  featuredInsights,
  profiles,
  toggleMetric,
  onComplete,
  classes
}: {
  featuredInsights: Array<FeaturedInsightField>;
  profiles: Array<Profile>;
  toggleMetric: (metric: FeaturedInsightField) => void;
  onComplete: () => void;
  classes: any;
}) => {
  const { classes: profileSelectClasses } = profileSelectStyles();
  const [selectedProfileId, setSelectedProfileId] = useState<string | undefined>(
    profiles.length ? profiles[0]._id || undefined : undefined
  );
  const selectedProfile = selectedProfileId ? find(profiles, { _id: selectedProfileId }) : undefined;

  const requiredDataCollection = selectedProfile
    ? filter(insightList, (o) => Boolean(o.channel === selectedProfile.profileType))
    : undefined;

  return (
    <Modal
      isOpen
      confirmLabel={'Done'}
      onConfirm={onComplete}
      heading='Featured Insights'
      hideCancelButton={true}
      onToggle={onComplete}
    >
      <Typography>{`${featuredInsights.length} Insights Selected`}</Typography>

      {!isEmpty(profiles) ? (
        <Select
          variant='standard'
          disableUnderline
          classes={profileSelectClasses}
          value={selectedProfileId}
          onChange={(e: any) => setSelectedProfileId(e.target?.value || undefined)}
        >
          {profiles.map((o, i) =>
            o._id ? (
              <MenuItem value={o._id} key={o._id} className={classes.profileItem}>
                <ProfileListItem key={`account_${i}`} profile={o} />
              </MenuItem>
            ) : null
          )}
        </Select>
      ) : (
        <Typography>No linked profiles</Typography>
      )}

      {requiredDataCollection && (
        <Box className={classes.metricsContainer}>
          {requiredDataCollection.map((metric) => (
            <MetricCard
              key={metric.key}
              profile={selectedProfile}
              metric={metric}
              onClick={toggleMetric}
              classes={classes}
              checked={
                selectedProfile
                  ? Boolean(find(featuredInsights, { key: metric.key, profileId: selectedProfile._id }))
                  : false
              }
            />
          ))}
        </Box>
      )}
    </Modal>
  );
};

const SliderConfig = {
  dots: true,
  dotsClass: 'slider-dots',
  arrows: true,
  slidesToScroll: 1,
  infinite: false
};

const InsightSlider = ({
  profiles,
  featuredInsights,
  insightList,
  classes
}: {
  profiles: Array<Profile>;
  featuredInsights: Array<FeaturedInsightField>;
  insightList: Array<MetricDetails>;
  classes: any;
}) => {
  const slidesToShow = Math.min(2, featuredInsights.length) || 1;

  return (
    <Box className={classes.previewContainer}>
      <Slider {...SliderConfig} slidesToShow={slidesToShow} className='link-slider slider-theme'>
        {featuredInsights.map((o, i) => {
          return (
            <Box key={`insight_${i}`} className={classes.slideContainer}>
              <MetricCard
                metric={(o.key ? find(insightList, { key: o.key }) : null) || { title: '', key: o.key || 'empty' }}
                profile={o.profileId ? find(profiles, { _id: o.profileId }) : null}
                classes={classes}
              />
            </Box>
          );
        })}
      </Slider>
    </Box>
  );
};

const MetricCard = ({
  metric,
  profile,
  classes,
  onClick,
  checked
}: {
  metric: MetricDetails | null;
  profile?: Profile | null;
  classes: any;
  onClick?: (metric: FeaturedInsightField) => void;
  checked?: boolean;
}) => {
  const insightInfo = metric?.key ? find(insightList, { key: metric.key }) : null;
  const theme = useTheme();

  if (!profile) return null;

  const value = metric?.key ? get(profile, metric.key) : null;

  let displayValue = 'No data';

  if (value) {
    const prefix = value > 0 ? '+' : '';
    if (insightInfo && insightInfo.displayType === 'PERCENTAGE') {
      displayValue = `${prefix}${(value * 100).toFixed(2)}%`;
    } else if (insightInfo && insightInfo.displayType === 'RATIO') {
      const wholeValue = Math.round(value * 10000);
      const gcd = gcd_more_than_two_numbers([wholeValue, 10000]);
      if (gcd) {
        displayValue = `${wholeValue / gcd}:${1000 / gcd}`;
      } else {
        displayValue = '0:0';
      }
    } else {
      displayValue = `${prefix}${value.toFixed(0)}`;
    }
  }

  return (
    <ButtonBase
      className={classes.item}
      disabled={Boolean(!onClick)}
      onClick={onClick ? () => onClick({ key: metric?.key, profileId: profile._id }) : undefined}
    >
      <Card className={classes.itemCard}>
        <CardContent>
          <Box className={classes.cardTitle}>
            <div className={classes.cardTitleSpacer}></div>
            <Box className={classes.text1}>{displayValue}</Box>
            <div className={classes.cardTitleSpacer}>
              {onClick && <RoundTick fill={checked ? theme.palette.primary.main : undefined} />}
            </div>
          </Box>

          {insightInfo && (
            <>
              <Box className={classes.text2}>{insightInfo.title}</Box>
              <Box className={classes.text3}>{insightInfo.description}</Box>
            </>
          )}
          <hr className={classes.cardDivider} />
        </CardContent>
      </Card>
    </ButtonBase>
  );
};

export default FeaturedInsightsBox;
