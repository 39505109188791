import { useCallback } from 'react';
import { useApolloClient } from '@apollo/client';

const useCacheOperations = () => {
  const client = useApolloClient();

  const resetStoreSafely = useCallback(() => {
    client.resetStore();
  }, [client]);

  return { resetStoreSafely };
};

export default useCacheOperations;
