import { useEffect, useState } from 'react';
import { get } from 'lodash';
import { useGetMyUserQuery, useGetConnectedAccountLazyQuery } from '../types/generated';

export const requiredInvoiceTemplateFields = ['stripeAccountId'];

const checkInvoiceTemplate = (myUser: any) => {
  if (!myUser) return false;
  for (let i = 0; i < requiredInvoiceTemplateFields.length; i++) {
    if (!get(myUser, requiredInvoiceTemplateFields[i])) {
      return false;
    }
  }
  return true;
};

export const useCheckInvoiceHooks = () => {
  const [hasSubmittedStripeDetails, setHasSubmittedStripeDetails] = useState<boolean | undefined>();
  const { data: myUserData } = useGetMyUserQuery();
  const [getConnectedAccount, { data: connectAccountData }] = useGetConnectedAccountLazyQuery();

  const invoiceTemplateCheckResult = myUserData ? checkInvoiceTemplate(myUserData?.myUser) : undefined;

  useEffect(() => {
    if (myUserData) {
      if (myUserData?.myUser?.stripeAccountId) {
        getConnectedAccount();
      } else {
        setHasSubmittedStripeDetails(false);
      }
    }
  }, [myUserData, getConnectedAccount]);

  useEffect(() => {
    if (connectAccountData) {
      setHasSubmittedStripeDetails(Boolean(connectAccountData.getConnectedAccount?.details_submitted));
    }
  }, [connectAccountData]);

  return [invoiceTemplateCheckResult, hasSubmittedStripeDetails];
};
