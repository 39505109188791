import { useState, useCallback } from 'react';

export const useForm = <T>(
  initialValues: T
): [T, (name: string, value: string | object | number | boolean) => void, (newValues: T) => void] => {
  const [values, setValues] = useState(initialValues);

  const setValue = useCallback(
    (name: string, value: string | object | number | boolean): void => {
      setValues({
        ...values,
        [name]: value
      });
    },
    // Prevent infinite
    [setValues, values]
  );

  const replaceValues = useCallback(
    (newValues: T): void => {
      setValues(newValues);
    },
    [setValues]
  );

  return [values, setValue, replaceValues];
};
