/**
 * Action list for the report,
 * it will take care of three actions,
 * Update -> Todo
 * Select and track the content -> Todo
 * Delete -> Todo
 *
 */

import React from 'react';
import { Menu, MenuItem, IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteEnsureDialog from './DeleteEnsureDialog';

interface IReportOperations {
  toggleReport: () => void;
  deleteReportWidthId: () => void;
  deleteEnsureDialog: boolean;
  toggleDeleteEnsureDialog: () => void;
}
const ReportOperations: React.FC<IReportOperations> = (props) => {
  const { classes } = useClasses();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const menuOperations = {
    toggleModalEditor: () => {
      props.toggleReport();
      setAnchorEl(null);
    },
    onToggleDeleteDialog: () => {
      props.toggleDeleteEnsureDialog();
    },
    deleteReportWithId: () => {
      props.toggleDeleteEnsureDialog();
      props.deleteReportWidthId();
      setAnchorEl(null);
    },
    onClose: () => {
      setAnchorEl(null);
    }
  };

  const report_delete_dialog = (
    <>
      <DeleteEnsureDialog
        isOpen={props.deleteEnsureDialog}
        _onConfirm={() => {
          menuOperations.deleteReportWithId();
        }}
        _onClose={() => {
          props.toggleDeleteEnsureDialog();
        }}
      />
    </>
  );

  return (
    <div>
      {report_delete_dialog}
      <IconButton onClick={handleClick} className={classes.pointer_wrapper}>
        <MoreHorizIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => menuOperations.onClose()}>
        <MenuItem onClick={() => menuOperations.toggleModalEditor()} style={{ width: '120px' }}>
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            menuOperations.onToggleDeleteDialog();
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
};

const useClasses = makeStyles()((theme) => ({
  pointer_wrapper: {
    cursor: 'pointer'
  }
}));

export default ReportOperations;
