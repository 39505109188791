import { useState, useEffect, useMemo } from 'react';
import { Grid, Typography, CircularProgress, Button, Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ProfileListItem from './ProfileListItem';
import { useGetProfilesListQuery, Profile } from 'types/generated';

import { isEmpty, compact } from 'lodash';

import { EditBoxProps } from './MediaKitEditBox';
import { useErrorHandlerHook } from 'hooks/useErrorCatchHook';

import { ElementContentType, AudienceDemographicsContent } from 'types/generated';

const styles = makeStyles()((theme) => ({
  error: { color: 'red', marginTop: 12 },
  textEditor: { padding: 15, minHeight: 300, width: '100%' },
  textPreview: { marginTop: 12, fontStyle: 'italic', fontSize: 14 },
  editorTitle: { fontSize: 14, fontWeight: 500 },
  previewContainer: { padding: 10, fontSize: 14, marginTop: 10, paddingBottom: 20 },
  previewEditContainer: {
    backgroundColor: theme.palette.info.light,
    padding: 20,
    marginBottom: 10,
    width: '100%'
  },
  profileListContainer: {
    padding: 10
  }
}));

function isAudienceDemographicsContent(content?: ElementContentType | null): content is AudienceDemographicsContent {
  return content && (content as AudienceDemographicsContent).audience_demographics_content !== undefined ? true : false;
}

const AudienceDemographicsBox = ({ element: { content, title }, handleUpdate, isEditing }: EditBoxProps) => {
  const { classes } = styles();
  const { apolloHandler } = useErrorHandlerHook();

  const [selectedProfileIds, setSelectedProfileIds] = useState<Array<string>>([]);

  const audienceDemographicsContent = isAudienceDemographicsContent(content)
    ? content.audience_demographics_content
    : undefined;

  useEffect(() => {
    if (!isEditing) {
      setSelectedProfileIds(audienceDemographicsContent ? compact(audienceDemographicsContent) : []);
    }
  }, [audienceDemographicsContent, setSelectedProfileIds, isEditing]);

  const { data, loading, error } = useGetProfilesListQuery();

  useEffect(() => {
    if (error) {
      apolloHandler(error);
    }
  }, [error, apolloHandler]);

  const profiles: Array<Profile> = useMemo(() => (data ? compact(data.getProfiles) : []), [data]);

  const linkedProfiles = useMemo(() => {
    return profiles.filter((o) => Boolean(o._id && selectedProfileIds.includes(o._id)));
  }, [selectedProfileIds, profiles]);

  const handleToggleProfile = (profile: Profile) => {
    if (profile._id) {
      if (selectedProfileIds.includes(profile._id)) {
        setSelectedProfileIds(selectedProfileIds.filter((o) => o !== profile._id));
      } else {
        setSelectedProfileIds(selectedProfileIds.concat([profile._id]));
      }
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return (
      <Typography align='center' variant='h5' className={classes.error}>
        {String(error.message)}
      </Typography>
    );
  }

  if (isEditing) {
    return (
      <Grid container>
        <Box className={classes.previewEditContainer}>
          <Typography align='left' className={classes.editorTitle}>
            Choose Account/s
          </Typography>
          <Grid container className={classes.profileListContainer} direction='column'>
            {profiles.map((o, i) => {
              return o._id ? (
                <ProfileListItem
                  key={`account_${i}`}
                  profile={o}
                  onPress={() => handleToggleProfile(o)}
                  isActive={selectedProfileIds.includes(o._id)}
                />
              ) : null;
            })}
          </Grid>
        </Box>
        <Grid container justifyContent='flex-start'>
          <Button
            variant='outlined'
            color='primary'
            onClick={() => {
              handleUpdate({ ...content, audience_demographics_content: selectedProfileIds });
            }}
          >
            {`Save & Close`}
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <Box className={classes.previewContainer}>
      {isEmpty(linkedProfiles) ? (
        <Typography align='center' className={classes.textPreview}>
          Select profiles to link...
        </Typography>
      ) : (
        linkedProfiles.map((o, i) => {
          return <ProfileListItem key={`account_${i}`} profile={o} />;
        })
      )}
    </Box>
  );
};

export default AudienceDemographicsBox;
