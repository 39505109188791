import React, { useState, useEffect, useMemo } from 'react';

import { Typography, TextField, Grid, Box, Button, IconButton, FormLabel, MenuItem, ButtonBase } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import IconClose from '@mui/icons-material/Close';
import IconRemoveCircleOutline from '@mui/icons-material/RemoveCircleOutline';

import { isEmpty, find, groupBy, filter, compact } from 'lodash';

import { EditBoxProps } from './MediaKitEditBox';
import { currencyOptions } from 'lib/invoiceValues';
import deliverables from 'lib/deliverables';
import channels from 'lib/channels';
import SelectChannel from 'components/Fields/SelectChannel';
import EditIcon from '../../../assets/icons/edit.svg';
import {
  ElementContentType,
  DeliverableRatesContent,
  DeliverableRateField,
  SocialChannel,
  SocialDeliverable,
  DeliverableRateInput
} from 'types/generated';

import AddIcon from 'assets/components/AddIcon';

type DeliverableRateRow = DeliverableRateField & { index: number };

const styles = makeStyles()((theme) => ({
  error: { color: 'red' },
  textEditor: { padding: 15, minHeight: 300, width: '100%' },
  textPreview: { marginTop: 12, fontStyle: 'italic', fontSize: 14 },
  previewContainer: { padding: 10, fontSize: 14, marginTop: 10, paddingBottom: 20 },
  rateTitle: { fontSize: '12px', fontWeight: 600 },
  rateInfo: {
    backgroundImage:
      'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMSIgdmlld0JveD0iMCAwIDEwIDEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxyZWN0IHdpZHRoPSIxMCIgaGVpZ2h0PSIxIiBmaWxsPSJ3aGl0ZSIvPgo8cmVjdCB4PSI5IiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSIjMzMzMzMzIi8+CjxyZWN0IHdpZHRoPSI5IiBoZWlnaHQ9IjEiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=)',
    backgroundRepeat: 'repeat-x',
    backgroundPosition: '50% 60%'
  },
  rateInfoBGWhite: {
    backgroundColor: '#fff',
    paddingLeft: '5px',
    paddingRight: '5px',
    display: 'flex',
    flexWrap: 'nowrap'
  },
  smallButton: {
    marginLeft: 4,
    padding: 0
  },
  placeholderContainer: {
    width: '100%',
    paddingBottom: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 200
  },
  addAnotherButton: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.main
    }
  }
}));

const defaultDeliverable: DeliverableRateInput = {
  deliverable: undefined,
  currency: undefined,
  value: undefined
};

function isDeliverableRateContent(content?: ElementContentType | null): content is DeliverableRatesContent {
  return content && (content as DeliverableRatesContent).deliverable_rates_content !== undefined ? true : false;
}

const RatesBox = ({ element: { content, title }, handleUpdate, isEditing, setIsEditing }: EditBoxProps) => {
  const { classes } = styles();
  const [rateContent, setRateContent] = useState<Array<DeliverableRateField>>([]);
  const [editingRateIndexList, setEditingRateIndexList] = useState<Array<number>>([]);

  const groupedRates = useMemo(
    () =>
      groupBy(
        rateContent.map((o, i) => ({ ...o, index: i })),
        'channel'
      ),
    [rateContent]
  );

  const handleUpdateItem = (item: DeliverableRateField, index: number) => {
    setRateContent(rateContent.map((o, i) => (i === index ? item : o)));
  };

  const handleRemoveItem = (index: number) => {
    setRateContent(filter(rateContent, (o, i) => (i === index ? false : true)));
    const updatedIndexList = filter(editingRateIndexList, (i) => Boolean(i !== index));
    setEditingRateIndexList([...updatedIndexList]);
    if (isEmpty(updatedIndexList)) {
      setIsEditing(false);
    }
  };

  // Manually set channel to SocialChannel enum because api doesn't automatically convert the raw value
  const deliverableRatesContent = useMemo(
    () =>
      isDeliverableRateContent(content)
        ? compact(
            content.deliverable_rates_content?.map((o) =>
              o ? { ...o, channel: o.channel?.toUpperCase() as SocialChannel } : undefined
            )
          )
        : undefined,
    [content]
  );

  useEffect(() => {
    if (!isEditing) {
      setRateContent(deliverableRatesContent || []);
    }
  }, [deliverableRatesContent, setRateContent, isEditing]);

  useEffect(() => {
    if (isEditing && isEmpty(rateContent)) {
      setRateContent([{ ...defaultDeliverable }]);
      setEditingRateIndexList([0]);
    }
  }, [isEditing, rateContent, setRateContent]);

  const handleSave = () => {
    const cleanedContent = filter(rateContent, (o) => Boolean(o.channel && o.deliverable));
    handleUpdate({ ...content, deliverable_rates_content: cleanedContent });
    setRateContent(cleanedContent);
    setEditingRateIndexList([]);
  };

  const handleDelete = (index: number) => {
    const cleanedContent = filter(
      filter(rateContent, (o, i) => Boolean(i !== index)),
      (o) => Boolean(o.channel && o.deliverable)
    );
    handleUpdate({ ...content, deliverable_rates_content: cleanedContent });
    setRateContent(cleanedContent);
  };

  const handleAddItem = () => {
    setRateContent([...rateContent, { ...defaultDeliverable }]);
    setEditingRateIndexList([...editingRateIndexList, rateContent.length]);
  };

  if (isEditing) {
    return (
      <>
        {editingRateIndexList.map((i, index) => {
          const editingItem = typeof i === 'number' ? rateContent[i] : undefined;
          return (
            <RateEditBox
              item={editingItem}
              onChange={(content) => handleUpdateItem(content, i)}
              onRemove={index !== 0 ? () => handleRemoveItem(i) : undefined}
            />
          );
        })}
        <Grid container justifyContent='space-between'>
          <Grid item>
            <Button variant='outlined' color='primary' onClick={handleSave} style={{ marginTop: 20 }} size='small'>
              {`Save & Close`}
            </Button>
          </Grid>
          <Grid item>
            <Button
              color='primary'
              onClick={handleAddItem}
              style={{ marginTop: 20 }}
              size='small'
              className={classes.addAnotherButton}
            >
              {`Add Another`}
            </Button>
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <Box className={classes.previewContainer}>
      {isEmpty(rateContent) ? (
        <ButtonBase className={classes.placeholderContainer} onClick={() => setIsEditing(true)}>
          <AddIcon size={35} strokeWidth={3} />
        </ButtonBase>
      ) : (
        Object.keys(groupedRates).map((key, i) => {
          return (
            <RateSection
              channel={key as SocialChannel}
              key={`rate_${i}`}
              items={groupedRates[key]}
              classes={classes}
              onEdit={(index) => {
                if (setIsEditing) {
                  setIsEditing(true);
                }
                setEditingRateIndexList([index]);
              }}
              onDelete={handleDelete}
            />
          );
        })
      )}
    </Box>
  );
};

const RateSection = ({
  channel,
  items,
  classes,
  onEdit,
  onDelete
}: {
  channel: SocialChannel;
  items: DeliverableRateRow[];
  classes: any;
  onEdit?: (index: number) => void;
  onDelete?: (index: number) => void;
}) => {
  const channelInfo = find(channels, { value: channel });

  return (
    <>
      <Box display='flex' fontSize='12px' mb='8px'>
        <Box width='20px' className='rates-icon' mr='8px'>
          {channelInfo && <img src={channelInfo.logo} alt={channel} />}
        </Box>
        <Box minWidth={80}>
          <Typography className={classes.rateTitle}>{channelInfo?.label || channel}</Typography>
        </Box>
        <Box pl='8px' flexGrow={1}>
          {items?.map((rate, i) => {
            const currencyInfo = rate.currency ? find(currencyOptions, { value: rate.currency }) : null;
            const deliverableInfo = rate.deliverable
              ? find(deliverables, { value: rate.deliverable as SocialDeliverable })
              : null;
            return (
              <Box
                display='flex'
                justifyContent='space-between'
                className={classes.rateInfo}
                key={`rate_${channel}_${i}`}
                mb='4px'
              >
                <Box className={classes.rateInfoBGWhite}>
                  {deliverableInfo ? deliverableInfo.label : rate.deliverable}
                </Box>
                <Box className={classes.rateInfoBGWhite}>
                  <Box>
                    {`${
                      currencyInfo ? `${currencyInfo.symbol}${currencyInfo.value}` : rate.currency
                    } ${rate.value?.toFixed(2)}`}
                  </Box>
                  {onEdit && (
                    <IconButton onClick={() => onEdit(rate.index)} className={classes.smallButton}>
                      <img src={EditIcon} alt='edit element' className={classes.customIcon} />
                    </IconButton>
                  )}
                  {onDelete && (
                    <IconButton onClick={() => onDelete(rate.index)} className={classes.smallButton}>
                      <IconClose fontSize='small' />
                    </IconButton>
                  )}
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
      <hr className={classes.headingSeparator} />
    </>
  );
};

const RateEditBox = ({
  item,
  onChange,
  onRemove
}: {
  item?: DeliverableRateField;
  onChange: (item: DeliverableRateField) => void;
  onRemove?: () => void;
}) => {
  const defaultItem: DeliverableRateField = useMemo(() => item || { ...defaultDeliverable }, [item]);

  return (
    <Grid container style={{ marginBottom: 24 }}>
      <Grid item xs={12} container justifyContent='space-between'>
        <Grid item>
          <FormLabel style={{ marginBottom: 10, display: 'flex', fontSize: 11, color: '#333333', opacity: 0.7 }}>
            Platform Type
          </FormLabel>
          <SelectChannel
            pageSize={9}
            availableChannels={[
              SocialChannel.Instagram,
              SocialChannel.Youtube,
              SocialChannel.Facebook,
              SocialChannel.Snapchat,
              SocialChannel.Twitter,
              SocialChannel.Tiktok,
              SocialChannel.Blog,
              SocialChannel.Linkedin
            ]}
            channels={defaultItem.channel ? [defaultItem.channel] : []}
            setChannels={(channel) => onChange({ ...defaultItem, channel })}
          />
        </Grid>
        <Grid item>
          {onRemove && (
            <IconButton onClick={() => onRemove()}>
              <IconRemoveCircleOutline />
            </IconButton>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={1}>
        {defaultItem.channel && (
          <Grid item xs={12}>
            <TextField
              variant='standard'
              fullWidth
              label={`Deliverable`}
              value={defaultItem.deliverable}
              select
              onChange={(e: any) => onChange({ ...defaultItem, deliverable: e.target.value })}
            >
              {filter(deliverables, (o) => {
                if (o.hidden) {
                  return false;
                }
                return o.channel.constructor === Array
                  ? o.channel.includes(defaultItem.channel!)
                  : o.channel === defaultItem.channel!;
              }).map((o) => (
                <MenuItem value={o.value}>{o.label}</MenuItem>
              ))}
            </TextField>
          </Grid>
        )}
        <Grid item xs={6}>
          <TextField
            variant='standard'
            fullWidth
            label={`Currency`}
            value={defaultItem.currency}
            select
            onChange={(e: any) => onChange({ ...defaultItem, currency: e.target.value })}
          >
            {currencyOptions.map((o) => (
              <MenuItem value={o.value}>{o.title}</MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant='standard'
            fullWidth
            label={`Value`}
            value={defaultItem.value || ''}
            type='number'
            onChange={(e: any) =>
              onChange({ ...defaultItem, value: e.target.value ? Number(e.target.value) : undefined })
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RatesBox;
