import { createTheme, Theme } from '@mui/material';
import '../index.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import '../ReactDatesOverride.css';
import LogoUrl from '../assets/icons/logo.svg';

export const defaultColors = {
  fontColor: '#333333', // Dark grey
  fontColorLight: '#6C6C72',
  primaryColor: '#CC215D', // Kitly
  logoUrl: 'Kitly.svg',
  secondaryColor: '#FCE3ED', // Lighter kitly pink
  backgroundColor: '#ffffff',
  secondaryBackground: '#F9F9F9',
  secondaryHighlight: '#C2EDE8' // Light blue
};

const fontColor = defaultColors.fontColor;
const primaryColor = defaultColors.primaryColor;
const secondaryColor = defaultColors.secondaryColor;
const backgroundColor = defaultColors.backgroundColor;
const secondaryBackground = defaultColors.secondaryBackground;
const secondaryHighlight = defaultColors.secondaryHighlight;

export const logoUrl = LogoUrl;

export const whiteSelect = createTheme({
  components: {
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: 'white'
        },
        select: {
          color: 'white'
        }
      }
    }
  }
});

const fontFamily = ['Open Sans', 'sans-serif'].join(',');

const theme: Theme = createTheme({
  components: {
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontFamily
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: 54
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        colorDefault: {
          color: '#000',
          backgroundColor: secondaryHighlight,
          textTransform: 'uppercase'
        }
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(235, 235, 235, 0.75)'
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          minHeight: '40px',
          minWidth: '162px',
          color: primaryColor,
          borderWidth: '4px',
          fontSize: 14,
          textTransform: 'capitalize',
          fontWeight: 500,
          '&:hover': {
            color: '#FFF'
          }
        },
        contained: {
          color: '#FFF',
          backgroundColor: primaryColor,
          '&:hover': {
            backgroundColor: secondaryColor
          }
        },
        containedSecondary: {
          color: '#FFF'
        },
        outlined: {
          color: primaryColor,
          backgroundColor: '#FFF',
          border: `0.5px solid ${primaryColor}`,
          '&:hover': {
            background: '#FFF',
            color: secondaryColor,
            border: `0.5px solid ${secondaryColor}`
          }
        },
        sizeLarge: {
          fontSize: 16,
          minHeight: '60px',
          minWidth: '305px'
        },
        sizeSmall: {
          fontSize: 14,
          minWidth: 'initial',
          minHeight: 'initial',
          padding: '3px 15px'
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: '15px',
          border: `solid 0.5px ${primaryColor}`,
          boxShadow: 'none'
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: 'flex-start',
          padding: 0
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '15px 0px 10px'
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: 22,
          padding: 0
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '&$disabled': {
            opacity: 0.5
          }
        },
        input: {
          color: fontColor,
          '&::placeholder': {
            color: fontColor
          }
        },
        underline: {
          '&:hover:not($disabled):not($focused):not($error):before': {
            borderBottomWidth: 0.5
          },
          '&$focused:before': {
            borderBottom: `0.5px solid ${primaryColor}`
          },
          '&$focused:after': {
            borderBottom: `0.5px solid ${primaryColor}`
          },
          '&:hover&$focused:before': {
            borderBottom: `1px solid ${primaryColor}`
          },
          '&$disabled:before': {
            borderBottom: `0.5px solid ${fontColor}`
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: fontColor,
          opacity: 0.7,
          '&$focused': {
            color: fontColor
          }
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: secondaryBackground,
          borderRadius: 0,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          '&:hover': {
            borderRadius: 0,
            backgroundColor: secondaryBackground
          },
          '&:focused': {
            borderRadius: 0,
            backgroundColor: secondaryBackground
          }
        },
        input: {
          backgroundColor: secondaryBackground
        },
        multiline: {
          backgroundColor: secondaryBackground,
          padding: 0
        },
        inputMultiline: {
          backgroundColor: secondaryBackground,
          padding: 10
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#ed9191'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          border: 'solid #e6e6e6 0.5px',
          WebkitBoxShadow: '0px 1px 2px 0px rgba(211,211,211,0.5)',
          MozBoxShadow: '0px 1px 2px 0px rgba(211,211,211,0.5)',
          boxShadow: '0px 1px 2px 0px rgba(211,211,211,0.5)'
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          textAlign: 'left'
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        wrapped: {
          fontSize: '1.1rem'
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 12
        }
      }
    }
  },
  palette: {
    primary: {
      main: primaryColor,
      light: '#FCE3ED',
      dark: primaryColor
    },
    secondary: {
      main: secondaryColor,
      light: '#EEEEEE'
    },
    background: {
      default: backgroundColor
    },
    error: {
      main: 'rgb(255,69,58)', // bright red
      dark: 'rgb(255,59,48)' // darker bright red
    },
    common: {
      black: fontColor
    },
    info: {
      main: '#F7F6F9',
      light: '#F7F6F9',
      dark: '#7A7A7A'
    }
  } as any,
  typography: {
    fontFamily,
    fontSize: 14,
    h1: {
      color: fontColor,
      fontSize: 32,
      lineHeight: '43px'
    },
    h2: {
      color: fontColor,
      fontSize: 28,
      lineHeight: '38px'
    },
    h3: {
      color: fontColor,
      fontSize: 24,
      lineHeight: '33px'
    },
    h4: {
      color: fontColor,
      fontSize: 22,
      lineHeight: '30px'
    },
    h5: {
      color: fontColor,
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '22px'
    },
    h6: {
      fontSize: 14,
      fontWeight: 500,
      color: '#3A3A3A'
    },
    subtitle1: {
      color: fontColor,
      fontSize: 10,
      fontWeight: 600,
      opacity: 0.67
    },
    subtitle2: {
      color: fontColor,
      fontSize: 10,
      textTransform: 'uppercase',
      fontWeight: 500
    },
    body1: {
      color: fontColor,
      fontSize: 12
    },
    body2: {
      color: '#6B6B6B',
      fontSize: 12
    },
    caption: {
      color: fontColor,
      fontSize: 10,
      fontWeight: 500
    }
  },
  spacing: 4
});

export default theme;
