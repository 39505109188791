import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import isEmpty from 'lodash/isEmpty';
import FollowersIcon from '../../../../../assets/images/followers.svg';
import ImpressionsIcon from '../../../../../assets/images/impressions.svg';
import ReachIcon from '../../../../../assets/images/reach.svg';
import PerformanceBarChart from '../PerformanceBarChart';
import { Profile } from 'types/generated';

const styles = makeStyles()({
  cardTitle: {
    marginBottom: 10,
    fontSize: 16,
    fontWeight: 500
  },
  cardSubtitle: {
    marginBottom: 10,
    fontSize: 14
  },
  insightsContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 10,
    margin: 4
  },
  insightsAmount: {
    fontSize: 18,
    textAlign: 'center'
  },
  insightsChange: {
    fontSize: 12,
    verticalAlign: 'top'
  },
  insightsTitle: {
    fontSize: 14,
    textAlign: 'center'
  },
  insightsSubtitle: {
    color: '#7A7A7A',
    fontSize: 10,
    textAlign: 'center'
  }
});

interface IFacebookPostContent {
  profile: Profile;
}

const FacebookPostContent = ({ profile }: IFacebookPostContent) => {
  const { postStats, pageStats } = profile;

  const { classes } = styles();

  const performanceData =
    postStats && pageStats
      ? [
          {
            name: 'Fans',
            value: profile.fan_count || pageStats.page_fans,
            icon: FollowersIcon
          },
          {
            name: 'Reach',
            value: postStats?.post_impressions_unique?.median,
            icon: ImpressionsIcon
          },
          {
            name: `Engagement`,
            value: postStats?.post_activity?.median,
            percentageValue: postStats?.post_activity_percentageOfReach?.median,
            icon: ReachIcon
          }
        ]
      : undefined;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box className={'insights-container'}>
          <Typography className={classes.cardTitle}>Post content over last 100 posts</Typography>
          {isEmpty(performanceData) ? (
            <Typography>No available data</Typography>
          ) : (
            <PerformanceBarChart data={performanceData} />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default FacebookPostContent;
