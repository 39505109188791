import React, { useState, useEffect, useCallback } from 'react';
import { EditBoxProps } from './MediaKitEditBox';

import { makeStyles } from 'tss-react/mui';
import { Typography, Button, Avatar, Box, Grid, ButtonBase, CircularProgress } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import { useFileReader } from 'hooks/useFileReader';
import { useDropzone } from 'react-dropzone';

import AddIcon from 'assets/components/AddIcon';

import { ElementContentType, ImageContent } from 'types/generated';

const styles = makeStyles()((theme) => ({
  error: { color: 'red' },
  imageEditContainer: { display: 'flex', justifyContent: 'center' },
  imageSection: { backgroundColor: theme.palette.info.light, padding: 10, marginBottom: 10 },
  placeholderContainer: {
    width: '100%',
    paddingBottom: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 200
  },
  imageEditor: { width: '100%' },
  imagePreview: { width: '100%', height: 300 },
  imagePlaceholder: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: 16,
    color: theme.palette.primary.main,
    textAlign: 'center',
    padding: 12
  },
  imagePlaceholderTitle: {
    color: theme.palette.primary.main,
    margin: 4
  },
  imageUploadSubtitle: {
    fontStyle: 'italic',
    marginTop: 4
  }
}));

function isImageContent(content?: ElementContentType | null): content is ImageContent {
  return content && (content as ImageContent).image_content !== undefined ? true : false;
}

const SingleImageBox = ({ element: { content }, handleUpdate, isEditing, setIsEditing }: EditBoxProps) => {
  const { classes } = styles();

  const [imageContent, setImageContent] = useState<string | undefined>();
  const { readFile, readFileLoading } = useFileReader();

  useEffect(() => {
    if (!isEditing) {
      if (isImageContent(content)) {
        setImageContent(content.image_content || undefined);
      }
    }
  }, [content, setImageContent, readFile, isEditing]);

  const dropImage = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file: File) => {
        readFile(file, (name, blob, encodedString) => {
          setImageContent(encodedString);
        });
      });
    },
    [readFile]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: dropImage,
    accept: 'image/jpg, image/jpeg, image/png, image/heic, image/heif',
    maxSize: 100000000
  });

  if (isEditing) {
    return (
      <>
        <Box className={classes.imageSection}>
          <Box className={classes.imageEditContainer}>
            <div {...getRootProps()} className={classes.imageEditor}>
              <input {...getInputProps()} />
              <Avatar src={imageContent} variant='square' className={classes.imagePreview} />
              <Box className={classes.imagePlaceholder}>
                {readFileLoading ? (
                  <CircularProgress />
                ) : (
                  <>
                    <ImageIcon fontSize={'large'} />
                    <Typography className={classes.imagePlaceholderTitle}>Drop an image or</Typography>
                    <Button variant='outlined' color='primary'>
                      Browse
                    </Button>
                    <Typography variant='caption' className={classes.imageUploadSubtitle}>
                      Accepts JPEG or PNG images up to 100MB
                    </Typography>
                  </>
                )}
              </Box>
            </div>
          </Box>
        </Box>
        <Grid container justifyContent='flex-start'>
          <Button
            variant='outlined'
            color='primary'
            onClick={() => {
              if (imageContent) {
                fetch(imageContent)
                  .then((res) => res.blob())
                  .then((blob) => {
                    const file = new File([blob], 'scaled_avatar.png', { type: 'image/png' });
                    handleUpdate({ ...content, image_content: file }, { ...content, image_content: imageContent });
                  });
              }
            }}
          >
            {`Save & Close`}
          </Button>
        </Grid>
      </>
    );
  }

  return (
    <>
      {imageContent ? (
        <Avatar src={imageContent} variant='square' className={classes.imagePreview} />
      ) : (
        <ButtonBase className={classes.placeholderContainer} onClick={() => setIsEditing(true)}>
          <AddIcon size={35} strokeWidth={3} />
        </ButtonBase>
      )}
    </>
  );
};

export default SingleImageBox;
