import config from 'config/appConfig';
import moment from 'moment';
import { Deliverable } from 'types/generated';

export const isPrintRoute = (): Boolean => window.location.href.includes('/print/');

export const downloadImageFromNewTab = (imageUrl: string) => {
  const encodedUrl = encodeURIComponent(imageUrl);

  return window.open(`${config.API_URL}/downloadUrl/${encodedUrl}`, '_blank');
};

export function generateBearerLink(reportId: string, token?: string | null) {
  if (!token) return;
  const returnString = JSON.stringify({ token, reportId });

  let domain = `${config.REPORT_URL}/#/view-referral`;

  return `${domain}/${btoa(returnString)}`;
}

export const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const numberToMomentHoursMinutes = (endTime: number) => {
  let numberToUse = endTime;

  if (endTime > 2400) numberToUse = endTime % 2400;

  const endTimeHours = Math.floor(numberToUse / 100);
  const endTimeMinutes = numberToUse % 100;

  return moment({ minutes: endTimeMinutes, hours: endTimeHours });
};

export const getDateDisplay = (deliverable?: Deliverable) => {
  return `${deliverable?.startDate ? `${moment(deliverable.startDate).format('D MMM YY')} - ` : ''}${
    deliverable?.endDate ? moment(deliverable.endDate).format('D MMM YY') : ''
  }`;
};

export const getTimeDisplay = (deliverable?: Deliverable) => {
  return `${deliverable?.startTime ? `${numberToMomentHoursMinutes(deliverable.startTime).format('h:mma')} - ` : ''}${
    deliverable?.endTime ? numberToMomentHoursMinutes(deliverable.endTime).format('h:mma') : ''
  }`;
};

export const gcd_more_than_two_numbers = (input: number[]) => {
  const len = input.length;
  if (!len) {
    return null;
  }
  let a = input[0];
  let b: number;
  for (var i = 1; i < len; i++) {
    b = input[i];
    a = gcd_two_numbers(a, b);
  }
  return a;
};

const gcd_two_numbers = (x: number, y: number) => {
  x = Math.abs(x);
  y = Math.abs(y);
  while (y) {
    var t = y;
    y = x % y;
    x = t;
  }
  return x;
};
