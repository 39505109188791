import { ButtonBase, colors, Typography, ButtonProps } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

type MuiBtnTypes = Pick<ButtonProps, 'disabled' | 'variant'>;

interface IThemeButton extends MuiBtnTypes {
  className?: string;
  height?: number;
  width?: number | string;
  text: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  _onClick?: () => void;
}

const ThemeButton: React.FC<IThemeButton> = (props) => {
  const { classes } = useClasses();

  // switch the theme
  const variantSwitch = (variant?: string) => {
    switch (variant) {
      case 'contained':
        return classes.contained;
      case 'outlined':
        return classes.outlined;
      case 'text':
        return classes.textBtn;
      default:
        return classes.outlined;
    }
  };

  // switch the text color
  const textVariant = (variant?: string) => {
    switch (variant) {
      case 'contained':
        return classes.contained_text;
      case 'text':
        return classes.default_text;
      default:
        return classes.default_text;
    }
  };

  return (
    <ButtonBase
      disableTouchRipple
      type={'button'}
      className={`${variantSwitch(props?.variant)} ${props.className}`}
      onClick={props._onClick}
      style={{ height: props.height, width: props.width }}
    >
      <Typography className={`${textVariant(props?.variant)}`}>{props.text}</Typography>
    </ButtonBase>
  );
};

ThemeButton.defaultProps = {
  variant: 'contained',
  type: 'button',
  height: 32,
  width: 110
};

const useClasses = makeStyles()((theme) => ({
  contained: {
    fontSize: 14,
    borderRadius: 3,
    background: theme.palette.primary.dark,
    borderWidth: 0,
    color: colors.common.white
  },
  outlined: {
    fontSize: 14,
    borderRadius: 3,
    color: theme.palette.primary.dark,
    background: colors.common.white,
    border: `1px solid ${theme.palette.primary.dark}`,
    borderColor: theme.palette.primary.dark
  },
  textBtn: {
    padding: 0,
    color: theme.palette.primary.dark,
    fontFamily: 'Open Sans',
    fontWeight: 500
  },
  contained_text: {
    fontSize: '14px',
    color: colors.common.white,
    fontFamily: 'Open Sans',
    fontWeight: 500
  },
  default_text: {
    fontSize: '14px',
    color: theme.palette.primary.dark,
    fontFamily: 'Open Sans',
    fontWeight: 500
  }
}));

export default ThemeButton;
