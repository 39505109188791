import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import {
  useTheme,
  Avatar,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tab,
  Tabs,
  Typography,
  Box
} from '@mui/material';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from 'assets/icons/EditIcon.svg';
import FakeButton from 'components/FakeButton';
import { CollabCreation } from 'components/pages/CollabsPage/CollabCreation';
import { DeliverableCreation } from 'components/pages/CollabsPage/DeliverableCreation';
import ActivityTag from 'components/Shared/ActivityTag';
import { formatName, getInitials } from 'lib/formatStrings';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { useGetCompanyContactListQuery, Contact } from 'types/generated';
import { AddContactButton } from './AddContactButton';
import { BrandCreation } from './BrandCreation';
import { ContactCreation } from './ContactCreation';

interface Props {
  brandId: string;
}

export const BrandDetails = (props: Props) => {
  const { brandId } = props;
  const { classes } = useStyles();
  const theme = useTheme();

  const { loading, data } = useGetCompanyContactListQuery({ variables: { id: brandId } });
  const brand = data?.getContactList?.length ? data.getContactList[0] : undefined;
  const avatar = brand?.avatar || '';
  const companyName = brand?.companyName || '';
  const companyNameInitials = getInitials({ firstInput: companyName, isSingleInitial: true });
  const email = brand?.email || '';
  const collaborationList = brand ? brand?.collaborations || [] : [];
  const contactList: Contact['children'] = brand ? brand?.children || [] : [];
  const brandActivity = brand ? brand?.activity || [] : [];

  const Loader = () => (
    <Grid container item xs={12} className={classes.container}>
      <Grid item xs={12}>
        <CircularProgress />
      </Grid>
    </Grid>
  );

  const DetailsBox = () => (
    <Grid container item xs={12} className={classes.detailsContainer}>
      <Grid item xs={12} className={classes.detailsRow}>
        <Typography variant='body1' className={`${classes.detailsSubtitle} ${classes.font14}`}>
          Email:&nbsp;
        </Typography>
        <Typography variant='body1' className={`${classes.detailsText} ${classes.font14}`}>
          {email}
        </Typography>
      </Grid>
      <BrandCreation editableBrand={brand}>
        <Typography className={`${classes.detailsText} ${classes.font14} ${classes.clickableText}`}>Edit</Typography>
      </BrandCreation>
    </Grid>
  );

  // Contacts & Activities tabs
  const [value, setValue] = useState<number>(0);
  const [openCollabDetails, setOpenCollabDetails] = useState<boolean>(false);
  const [selectedCollab, setSelectedCollab] = useState<string | null>(null);

  const renderTabHeaders = () => {
    return (
      <Grid
        container
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
          borderBottom: `0.5px solid ${theme.palette.primary.main}`
        }}
      >
        <Tabs
          indicatorColor='primary'
          value={value}
          onChange={(e, newValue) => {
            setValue(newValue);
          }}
        >
          <Tab label='Contacts' value={0} className={classes.tab} />
          <Tab label='Activity' value={1} className={classes.tab} />
        </Tabs>
      </Grid>
    );
  };

  const BrandContacts = () => (
    <Grid container item xs={12} className={classes.brandContactsActivity}>
      <Grid item xs={12}>
        <AddContactButton brandId={brandId} />
      </Grid>
      {isEmpty(contactList) ? (
        <Box mt='16px'>
          <Typography variant='body1'>No contacts added yet</Typography>
        </Box>
      ) : (
        <Grid item xs={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.cell20}>Name</TableCell>
                  <TableCell className={classes.cell20}>Phone</TableCell>
                  <TableCell className={classes.cell30}>Email</TableCell>
                  <TableCell className={classes.cell25}>Role</TableCell>
                  <TableCell className={classes.cell5}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contactList?.map((o, i) => {
                  const firstName = o?.firstName || '';
                  const lastName = o?.lastName || '';
                  const name = formatName({ firstName, lastName });
                  const contactInitials = getInitials({
                    firstInput: firstName,
                    secondInput: lastName,
                    isSingleInitial: true
                  });
                  return (
                    <TableRow key={`contact-${o?._id || i}`} className={classes.tableRow}>
                      <TableCell className={classes.cell20}>
                        <div className={classes.avatarContainer}>
                          <Avatar src={o?.avatar || ''} className={classes.profilePic}>
                            {contactInitials}
                          </Avatar>
                          {name}
                        </div>
                      </TableCell>
                      <TableCell className={classes.cell20}>{o?.phone || ''}</TableCell>
                      <TableCell className={classes.cell30}>{o?.email || ''}</TableCell>
                      <TableCell className={classes.cell25}>{o?.role || ''}</TableCell>
                      <TableCell className={classes.cell5}>
                        <ContactCreation editableContact={o} selectedParentId={brandId}>
                          <img src={EditIcon} alt='Edit' style={{ fontSize: 20 }} />
                        </ContactCreation>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
    </Grid>
  );

  const BrandActivities = () => (
    <Grid container item xs={12} className={classes.brandContactsActivity}>
      {isEmpty(brandActivity) ? (
        <Typography variant='body1'>No activity yet</Typography>
      ) : (
        <Grid item xs={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.cell20}>Date</TableCell>
                  <TableCell className={classes.cell30}>Contact</TableCell>
                  <TableCell className={classes.cell50}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!isEmpty(brandActivity) &&
                  brandActivity.map((a, i: number) => {
                    const collabDetails = collaborationList.find((collab) => collab?._id === a?.itemId);

                    const timestamp = a?.timestamp ? moment(a.timestamp).format('D MMM YY') : '';
                    const avatarSrc = a?.contact?.avatar || '';
                    const contactEmail = a?.contact?.email || '';
                    const action = a?.action ? a.action.replace('_', ' ') : '';
                    const actionType = a?.type ? a.type.replace('_', ' ') : '';

                    return (
                      <TableRow key={`activity-${a?.timestamp || i}`} className={classes.tableRow}>
                        <TableCell className={classes.cell20} style={{ verticalAlign: 'top' }}>
                          {timestamp}
                        </TableCell>
                        <TableCell className={classes.cell30} style={{ verticalAlign: 'top' }}>
                          <div className={classes.avatarContainer}>
                            <Avatar src={avatarSrc} className={classes.profilePic} />
                            {contactEmail}
                          </div>
                        </TableCell>
                        <TableCell className={classes.cell50}>
                          <Grid container>
                            <ActivityTag action={action} />
                            <Grid item>{actionType}</Grid>
                            {a?.type === 'COLLABORATION' && !isEmpty(collabDetails) && (
                              <Grid item>
                                {openCollabDetails && a?.itemId === selectedCollab ? (
                                  <ExpandLessIcon
                                    onClick={() => {
                                      setOpenCollabDetails(false);
                                      setSelectedCollab(null);
                                    }}
                                    style={{ fontSize: 15 }}
                                  />
                                ) : (
                                  <ExpandMoreIcon
                                    onClick={() => {
                                      setOpenCollabDetails(true);
                                      setSelectedCollab(a?.itemId || null);
                                    }}
                                    style={{ fontSize: 15 }}
                                  />
                                )}
                              </Grid>
                            )}
                            {openCollabDetails && !isEmpty(collabDetails) && a?.itemId === selectedCollab && (
                              <Grid
                                item
                                container
                                sm={12}
                                spacing={2}
                                direction='column'
                                style={{ fontWeight: 'normal', paddingTop: 10 }}
                              >
                                {collabDetails?.name && <Grid item>{collabDetails.name}</Grid>}
                                {collabDetails?.brand?.companyName && (
                                  <Grid item>{collabDetails.brand.companyName}</Grid>
                                )}
                                {collabDetails?.status && <Grid item>{collabDetails.status}</Grid>}
                                <Grid item>
                                  <CollabCreation editableCollab={collabDetails || undefined}>
                                    <span style={{ cursor: 'pointer', color: theme.palette.primary.main }}>Edit</span>
                                  </CollabCreation>
                                  <span>{' | '}</span>
                                  <DeliverableCreation selectedCollabId={selectedCollab}>
                                    <span style={{ cursor: 'pointer', color: theme.palette.primary.main }}>
                                      Add deliverable
                                    </span>
                                  </DeliverableCreation>
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
    </Grid>
  );

  if (loading && isEmpty(brand)) {
    return <Loader />;
  }

  return (
    <Grid container item xs={12} className={classes.container} justifyContent='space-between'>
      <Grid container item xs={7}>
        <Grid item xs={12} className={classes.overview} container alignItems='flex-start'>
          <Avatar src={avatar} className={classes.avatar}>
            {companyNameInitials}
          </Avatar>
          <Box mt='7px'>
            <Typography variant='h1'>{companyName}</Typography>
            <Box mt='10px'>
              <DetailsBox />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid item>
        <ContactCreation selectedParentId={brandId}>
          <FakeButton variant='contained'>New Contact</FakeButton>
        </ContactCreation>
      </Grid>
      {renderTabHeaders()}
      {value === 0 && <BrandContacts />}
      {value === 1 && <BrandActivities />}
    </Grid>
  );
};

const useStyles = makeStyles()((theme) => {
  return {
    container: {
      paddingTop: 30
    },
    overview: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      marginBottom: 20
    },
    detailsContainer: {
      height: 'fit-content'
    },
    detailsRow: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      marginBottom: 10
    },
    detailsTitle: {
      letterSpacing: '0.8px'
    },
    detailsSubtitle: {
      letterSpacing: '0.7px',
      fontWeight: 500
    },
    detailsText: {
      letterSpacing: '0.7px'
    },
    overviewButtons: {
      marginBottom: 60
    },
    avatar: {
      marginRight: 17,
      height: 60,
      width: 60
    },
    title: {
      marginBottom: 20
    },
    button: {
      margin: 5
    },
    brandReports: {
      marginBottom: 60
    },
    brandContactsActivity: {
      marginTop: 30,
      marginBottom: 60
    },
    brandCollabs: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 60
    },
    tableRow: {
      position: 'relative'
    },
    cell5: {
      width: '5%'
    },
    cell20: {
      width: '20%',
      paddingLeft: 0
    },
    cell25: {
      width: '25%',
      paddingLeft: 0
    },
    cell30: {
      width: '30%',
      paddingLeft: 0
    },
    cell50: {
      width: '50%',
      paddingLeft: 0
    },
    avatarContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flexDirection: 'row',
      flexWrap: 'nowrap'
    },
    profilePic: {
      height: 24,
      width: 24,
      marginRight: 10,
      fontSize: 9,
      backgroundColor: '#60D8CA'
    },
    editButton: {
      position: 'absolute',
      top: '50%',
      right: 0
    },
    font14: {
      fontSize: 14
    },
    clickableText: {
      color: theme.palette.primary.main,
      fontWeight: 500
    },
    tab: {
      '& span': {
        fontSize: 10
      }
    }
  };
});
