export const availableElements: Array<{
  title: string;
  subtitle?: string;
  type: string;
  description?: string;
  width?: 2 | 4 | 6 | 12;
  restricted?: boolean;
}> = [
  {
    title: 'Heading',
    subtitle: 'Your name or handle',
    type: 'TEXT',
    description: `This is the main call out of your media kit. We find your name, username, or the name of the campaign work best.`
  },
  {
    title: 'Image',
    subtitle: 'Share your highest performing content',
    type: 'IMAGE',
    description: `Upload an image here, something like your profile picture will work.`
  },
  {
    title: 'Short Bio',
    subtitle: 'A little about you',
    type: 'RICH_TEXT',
    description: `Here’s your chance to share what you’re all about. It pays to include the aspects of your influence that reach beyond your audience metrics. Are you a public speaker? A presenter? Or an advocate for a charity that’s really important for you? It’s important to collaborate with brands and causes that align with your values so, tell them what they are.`
  },
  {
    title: 'Account Performance Audit Report (APAR)',
    subtitle: 'Account performance insights and Audience demographics',
    type: 'LINKED_ACCOUNTS'
  },
  {
    title: 'Rates',
    subtitle: 'Add rates for your skills and time',
    type: 'DELIVERABLE_RATES',
    description: `Feel free to add in your rates. We find it pays to be specific. Including things like editing time, photoshoots, as well as your social media real-estate, can be really helpful.`
  },
  {
    title: 'Links',
    subtitle: 'Link to your blog, Website etc',
    type: 'LINKS',
    description: `Include links to the important stuff outside of social media. A website, articles you’ve featured in, your portfolio or that Ted Talk you presented at would all work.`,
    restricted: true
  },
  {
    title: 'PDF Upload',
    subtitle: 'Upload an exisitng Media Kit and link your insights',
    type: 'FILES',
    description: `Upload documentation for recent work you’ve done, your portfolio or anything else you might want to share.`,
    restricted: true
  },
  { title: 'Video', subtitle: 'Upload a Video', type: 'VIDEO', restricted: true },
  { title: 'Image Gallery', subtitle: 'Choose up to 10 Images for a carousel', type: 'IMAGE_LIST', restricted: true },
  {
    title: 'Custom Insights',
    subtitle: 'Choose which insights you wish to add',
    type: 'FEATURED_INSIGHTS',
    restricted: true
  },

  {
    title: 'Audience Demographics Only',
    subtitle: 'Audience demographics will be displayed of the linked accounts',
    type: 'AUDIENCE_DEMOGRAPHICS',
    restricted: true
  },
  {
    title: 'Custom Media',
    subtitle: 'Select media from your Instagram account to highlight',
    type: 'SELECTED_MEDIA',
    restricted: true
  }
];
