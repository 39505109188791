import { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { Grid, CircularProgress, Typography, IconButton, Box, Button, TextField, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import {
  GetSupplementMediaForReportDocument,
  useGetSupplementMediaForReportLazyQuery,
  useCreateSupplementMediaMutation,
  useUpdateSupplementMediaMutation,
  useDeleteSupplementMediaMutation,
  SupplementMedia,
  SupplementMediaInput,
  SupplementMediaType,
  SupplementMediaInsightsInput
} from 'types/generated';
import { useErrorHandlerHook } from '../../hooks/useErrorCatchHook';
import { compact, filter, isEmpty, omit, get, difference } from 'lodash';
import Modal from 'components/Modal';
import AddIcon from '@mui/icons-material/Add';
import { isSupplementMedia } from '../../lib/type_guards';
import ProfileSelector from '../Fields/ProfileSelector';
import { useDropzone } from 'react-dropzone';
import ImageIcon from '@mui/icons-material/Image';
import EditIcon from '../../assets/icons/edit.svg';
import BinIcon from '../../assets/icons/bin.svg';
import ExclamationIcon from '../../assets/icons/exclamation.svg';
import Rectangle from '../../assets/images/Rectangle.svg';
import { SingleDatePicker } from 'react-dates';
import moment, { Moment } from 'moment';
import EmbeddedInstagramMedia from './EmbeddedInstagramMedia';
import CloseButton from './CloseButton';

interface ISupplementMediaField {
  reportConfigId?: string;
  onChange?: (media: Array<SupplementMedia | SupplementMediaInput>) => void;
  value?: Array<SupplementMedia | SupplementMediaInput>;
  isLoading?: boolean;
}

const SupplementMediaField = ({ reportConfigId, onChange, value, isLoading }: ISupplementMediaField) => {
  const { classes } = styles();
  const { apolloHandler } = useErrorHandlerHook();

  const [deletingIndex, setDeletingIndex] = useState<undefined | number>();
  const [updatingIndex, setUpdatingIndex] = useState<undefined | number>();

  const [getMedia, { data, loading, error }] = useGetSupplementMediaForReportLazyQuery();

  useEffect(() => {
    if (reportConfigId) {
      getMedia({ variables: { reportConfigId } });
    }
  }, [reportConfigId, getMedia]);

  useEffect(() => {
    if (error) {
      apolloHandler(error);
    }
  }, [error, apolloHandler]);

  useEffect(() => {
    if (data?.getSupplementMediaForReport && onChange) {
      onChange(compact(data.getSupplementMediaForReport));
    }
  }, [data, onChange]);

  const [createMedia, { loading: createLoading, error: createError }] = useCreateSupplementMediaMutation({
    refetchQueries: [GetSupplementMediaForReportDocument],
    onCompleted: () => {
      setUpdatingIndex(undefined);
    }
  });

  useEffect(() => {
    if (createError) {
      apolloHandler(createError);
    }
  }, [createError, apolloHandler]);

  const [updateMedia, { loading: updateLoading, error: updateError }] = useUpdateSupplementMediaMutation({
    refetchQueries: [GetSupplementMediaForReportDocument],
    onCompleted: () => {
      setUpdatingIndex(undefined);
    }
  });

  useEffect(() => {
    if (updateError) {
      apolloHandler(updateError);
    }
  }, [updateError, apolloHandler]);

  const [deleteMedia, { loading: deleteLoading, error: deleteError }] = useDeleteSupplementMediaMutation({
    refetchQueries: [GetSupplementMediaForReportDocument],
    onCompleted: () => {
      setDeletingIndex(undefined);
    }
  });

  useEffect(() => {
    if (deleteError) {
      apolloHandler(deleteError);
    }
  }, [deleteError, apolloHandler]);

  const deletingMedia = useMemo(
    () => (typeof deletingIndex === 'number' && value?.length ? value[deletingIndex] : null),
    [value, deletingIndex]
  );
  const updatingMedia = useMemo(
    () => (typeof updatingIndex === 'number' && value?.length ? value[updatingIndex] : null),
    [value, updatingIndex]
  );

  const showLoadingState = isLoading || loading || createLoading;
  const loadingMediaWithIndex = updateLoading ? updatingIndex : deleteLoading ? deletingIndex : null;

  const handleDelete = () => {
    if (isSupplementMedia(deletingMedia)) {
      if (deletingMedia?._id) {
        deleteMedia({ variables: { id: deletingMedia._id } });
      }
    } else if (onChange) {
      onChange(filter(value, (o, i) => Boolean(i !== deletingIndex)));
      setDeletingIndex(undefined);
    }
  };

  const handleUpdate = (updates: SupplementMediaInput) => {
    if (isSupplementMedia(updatingMedia)) {
      if (updatingMedia._id) {
        updateMedia({ variables: { id: updatingMedia._id, updates } });
      }
    } else if (reportConfigId) {
      createMedia({ variables: { input: { ...updates, reportConfigId } } });
    } else if (value && onChange) {
      const updatedValue = value.map((o, i) => (i === updatingIndex ? { ...o, ...updates } : o));
      onChange(updatedValue);
      setUpdatingIndex(undefined);
    }
  };

  const handleAdd = () => {
    if (onChange) {
      onChange(value ? [...value, {}] : [{}]);
    }
    setUpdatingIndex(value?.length || 0);
  };

  return (
    <>
      {Boolean(typeof updatingIndex === 'number' && !updateLoading) && (
        <SupplementMediaEditModal
          loading={updateLoading || createLoading}
          existingMedia={updatingMedia || undefined}
          onConfirm={handleUpdate}
          onCancel={() => {
            if (onChange) {
              onChange(filter(value, (o) => !isEmpty(o)));
            }
            setUpdatingIndex(undefined);
          }}
        />
      )}
      {Boolean(typeof deletingIndex === 'number' && !deleteLoading) && (
        <SupplementMediaDeleteModal
          onConfirm={handleDelete}
          onCancel={() => {
            setDeletingIndex(undefined);
          }}
        />
      )}
      <Grid container justifyContent='flex-start' alignItems='flex-start'>
        {value?.map((o, i) => {
          return (
            <Grid key={`supplement_media_${isSupplementMedia(o) ? o._id : i}`} item className={classes.gridCell}>
              {loadingMediaWithIndex === i && (
                <div className={classes.loadingContainer}>
                  <CircularProgress />
                </div>
              )}
              <div className={classes.videoPreview}>
                {o.mediaUrl ? (
                  <>
                    {o.mediaIsVideo ? (
                      <video src={o.mediaUrl} style={{ width: '100%', height: '100%' }} />
                    ) : (
                      <img
                        src={o.mediaUrl}
                        alt='Manually added media'
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                      />
                    )}
                  </>
                ) : o.mediaId ? (
                  <EmbeddedInstagramMedia embeddedMediaId={o.mediaId} width={150} disableClick />
                ) : (
                  <img
                    src={Rectangle}
                    alt='Checkered pattern'
                    style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                  />
                )}
              </div>
              <div className={classes.buttonsContainer}>
                <Grid container>
                  <Grid item>
                    <IconButton onClick={() => setUpdatingIndex(i)} className={classes.mediaButton}>
                      <img src={EditIcon} alt='delete element' />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={() => setDeletingIndex(i)} className={classes.mediaButton}>
                      <img src={BinIcon} alt='delete element' />
                    </IconButton>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          );
        })}
        {showLoadingState ? (
          <Grid item className={classes.gridCell}>
            <Grid container alignItems='center' justifyContent='center'>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12} className={classes.addButtonContainer}>
            <IconButton color='primary' className={classes.addButton} onClick={handleAdd}>
              <AddIcon fontSize='large' className={classes.addButtonIcon} />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default SupplementMediaField;

const styles = makeStyles()((theme) => ({
  gridCell: {
    height: 266,
    width: 150,
    margin: 3,
    position: 'relative'
  },
  addButtonContainer: {
    padding: 10,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  addButton: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    }
  },
  loadingContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 100
  },
  addButtonIcon: {
    color: theme.palette.common.white
  },
  videoPreview: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden'
  },
  buttonsContainer: {
    backgroundColor: theme.palette.primary.light,
    position: 'absolute',
    top: 0,
    right: 0
  },
  mediaButton: {
    height: 24,
    width: 24,
    padding: 3
  }
}));

const SupplementMediaDeleteModal = ({ onConfirm, onCancel }: { onConfirm: () => void; onCancel: () => void }) => {
  return (
    <Modal
      isOpen
      onToggle={onCancel}
      onConfirm={onConfirm}
      maxWidth={'md'}
      confirmLabel={'Yes'}
      cancelLabel={'No'}
      heading={
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <img src={ExclamationIcon} alt='Warning' style={{ height: 45, width: 45 }} />
        </div>
      }
    >
      <div style={{ paddingTop: 30, paddingBottom: 30 }}>
        <Typography>Are you sure you want to remove this media?</Typography>
      </div>
    </Modal>
  );
};

type AvailableInsights = {
  [key in keyof SupplementMediaInsightsInput]: string;
};

const insightsToUse: AvailableInsights = {
  reach: 'Reach',
  plays: 'Plays',
  likes: 'Likes',
  comments: 'Comments',
  shares: 'Shares',
  saves: 'Saves'
};

const videoFileTypes = ['video/mp4', 'video/quicktime'];
const imageFileTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/heic', 'image/heif'];

const SupplementMediaEditModal = ({
  loading,
  existingMedia,
  onConfirm,
  onCancel
}: {
  loading?: boolean;
  existingMedia?: SupplementMedia | SupplementMediaInput;
  onConfirm: (updates: SupplementMediaInput) => void;
  onCancel: () => void;
}) => {
  const { classes } = editStyles();
  const embeddedMediaRef = useRef<HTMLDivElement | null>(null);

  const [profileId, setProfileId] = useState<undefined | string>();
  const [mediaIsVideo, setMediaIsVideo] = useState(false);
  const [mediaUrl, setMediaUrl] = useState<undefined | string>();
  const [mediaType, setMediaType] = useState<SupplementMediaType>(SupplementMediaType.InstagramReel);
  const [caption, setCaption] = useState('');
  const [timestamp, setTimestamp] = useState<Moment | null>(null);
  const [datePickerFocused, setDatePickerFocused] = useState(false);
  const [insights, setInsights] = useState<SupplementMediaInsightsInput>({});
  const [showError, setShowError] = useState(false);
  const [mediaId, setMediaId] = useState<undefined | string>();
  const [showUrlModal, setShowUrlModal] = useState(false);

  const [mediaUpload, setMediaUpload] = useState<File | undefined>();
  const [showRejectedFileWarning, setShowRejectedFileWarning] = useState<boolean>(false);

  const fileDropRejected = () => {
    setShowRejectedFileWarning(true);
  };

  const missingFields = useMemo(() => {
    let missingFields: Array<string> = [];
    if (!profileId) {
      missingFields.push('profileId');
    }
    return missingFields;
  }, [profileId]);

  const missingInsightFields = useMemo<Array<keyof SupplementMediaInsightsInput>>(() => {
    return difference(
      Object.keys(insightsToUse),
      compact(
        Object.keys(insights).map((key) => (insights[key as keyof SupplementMediaInsightsInput] ? key : undefined))
      )
    ) as Array<keyof SupplementMediaInsightsInput>;
  }, [insights]);

  const fileReader = useMemo(() => new FileReader(), []);

  const dropMedia = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles[0]) {
        setMediaUpload(acceptedFiles[0]);

        fileReader.onload = (e) => {
          if (e?.target?.result && typeof e?.target?.result !== 'string') {
            const blob = new Blob([e.target.result]);
            const dataUrl = URL.createObjectURL(blob);

            setMediaUrl(dataUrl);
          }
        };

        if (acceptedFiles[0].type && videoFileTypes.includes(acceptedFiles[0].type)) {
          setMediaIsVideo(true);
        } else {
          setMediaIsVideo(false);
        }

        fileReader.readAsArrayBuffer(acceptedFiles[0]);
      }
    },
    [fileReader]
  );

  const {
    getRootProps,
    getInputProps,
    open: openFileSelector
  } = useDropzone({
    onDrop: dropMedia,
    accept: [...videoFileTypes, ...imageFileTypes],
    maxFiles: 1,
    maxSize: 100000000, // 100MB
    onDropRejected: fileDropRejected,
    noClick: true
  });

  useEffect(() => {
    if (existingMedia?.insights) {
      setInsights(omit(existingMedia.insights, ['__typename']));
    }
    if (existingMedia?.profileId) {
      setProfileId(existingMedia.profileId);
    }
    if (existingMedia?.mediaType) {
      setMediaType(existingMedia.mediaType);
    }
    if (existingMedia?.mediaUrl) {
      setMediaUrl(existingMedia.mediaUrl);
    }
    if (existingMedia?.caption) {
      setCaption(existingMedia.caption);
    }
    if (existingMedia?.timestamp) {
      setTimestamp(moment(existingMedia.timestamp));
    }
    if (existingMedia?.mediaId) {
      setMediaId(existingMedia.mediaId);
    }
    if (typeof existingMedia?.mediaIsVideo === 'boolean') {
      setMediaIsVideo(existingMedia?.mediaIsVideo);
    } else if (existingMedia?.mediaUrl) {
      if (existingMedia.mediaUrl.endsWith('.mp4') || existingMedia.mediaUrl.endsWith('.mov')) {
        setMediaIsVideo(true);
      } else {
        setMediaIsVideo(false);
      }
    }
  }, [
    existingMedia,
    setInsights,
    setProfileId,
    setMediaType,
    setMediaUrl,
    setCaption,
    setTimestamp,
    setMediaId,
    setMediaIsVideo
  ]);

  return (
    <>
      {showUrlModal && (
        <MediaUrlEditModal
          onToggle={() => setShowUrlModal(false)}
          onConfirm={(id) => {
            setMediaId(id);
            setShowUrlModal(false);
          }}
        />
      )}
      <Modal
        isOpen
        onToggle={onCancel}
        onConfirm={() => {
          if (missingFields.length || missingInsightFields.length) {
            setShowError(true);
          } else {
            onConfirm({
              mediaType,
              profileId,
              insights,
              mediaUpload,
              mediaUrl,
              caption,
              mediaIsVideo,
              mediaId,
              timestamp: timestamp ? timestamp.toDate() : undefined
            });
          }
        }}
        isLoadingConfirm={loading}
        maxWidth={'md'}
      >
        <Typography variant='h2'>Manually Add Reels</Typography>
        <Grid container>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={12} style={{ paddingTop: 20, paddingBottom: 20 }}>
                <Typography style={{ fontSize: 14, fontWeight: 500 }}>Platform and Content Upload</Typography>
              </Grid>
              <Grid item xs={12} className={classes.fieldContainer}>
                <ProfileSelector
                  emptyLabel='Assign to Talent'
                  selectedId={profileId}
                  setSelectedId={(id) => {
                    setProfileId(id);
                  }}
                  errorText={
                    Boolean(showError && missingFields?.includes('profileId'))
                      ? 'Please select a talent record'
                      : undefined
                  }
                />
              </Grid>
              <Grid item xs={12} className={classes.fieldContainer}>
                <Grid container justifyContent='space-between' alignItems='center'>
                  <Grid item>
                    <Typography style={{ fontSize: 14, fontWeight: 500 }}>Post date</Typography>
                  </Grid>
                  <Grid item>
                    <SingleDatePicker
                      id={'timestamp-date'}
                      date={timestamp}
                      monthFormat={'MMM YYYY'}
                      onDateChange={(date) => {
                        setTimestamp(date);
                      }}
                      focused={datePickerFocused}
                      onFocusChange={(e) => {
                        setDatePickerFocused(Boolean(e.focused));
                      }}
                      placeholder={'DD/MM/YYYY*'}
                      isOutsideRange={() => false}
                      daySize={25}
                      small
                      block
                      noBorder
                      hideKeyboardShortcutsPanel
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.fieldContainer} style={{ marginBottom: 20 }}>
                <textarea
                  placeholder='Caption...'
                  className={classes.textEditor}
                  value={caption}
                  onChange={(event) => {
                    setCaption(get(event, 'target.value') || '');
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container>
              {Object.keys(insightsToUse).map((key) => {
                const insightKey = key as keyof SupplementMediaInsightsInput;
                return (
                  <Grid item xs={12} key={key}>
                    <InsightEditField
                      insightKey={insightKey}
                      insights={insights}
                      setInsights={setInsights}
                      showError={Boolean(showError && missingInsightFields?.includes(insightKey))}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid item container xs={6} className={`${classes.fieldContainer} ${classes.imageFieldContainer}`}>
            <Box className={classes.imageSection}>
              <Box className={classes.imageEditContainer}>
                {showRejectedFileWarning ? (
                  <Grid container direction='column' alignItems='center' justifyContent='center'>
                    <Grid item>
                      <Typography className={classes.warningText} align='center'>
                        Your file must be of the type ‘mp4’, ‘mov’, 'jpeg' or 'png'
                        <br />
                        and be less than 100MB in size
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Button variant='outlined' onClick={() => setShowRejectedFileWarning(false)}>
                        Okay, got it!
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  <div {...getRootProps()} className={classes.imageEditor}>
                    <input {...getInputProps()} />

                    {mediaUrl ? (
                      <>
                        <div className={classes.removeButtonContainer}>
                          <CloseButton
                            onPress={() => {
                              setMediaUrl(undefined);
                            }}
                          />
                        </div>
                        {mediaIsVideo ? (
                          <video src={mediaUrl} controls style={{ width: '100%' }} />
                        ) : (
                          <img src={mediaUrl} alt='Preview' style={{ width: '100%', objectFit: 'cover' }} />
                        )}
                      </>
                    ) : mediaId ? (
                      <div ref={embeddedMediaRef}>
                        <div className={classes.removeButtonContainer}>
                          <CloseButton
                            onPress={() => {
                              setMediaId(undefined);
                            }}
                          />
                        </div>
                        <EmbeddedInstagramMedia embeddedMediaId={mediaId} />
                      </div>
                    ) : (
                      <Box className={classes.imagePlaceholder}>
                        <ImageIcon fontSize={'large'} />
                        <Typography className={classes.imagePlaceholderTitle}>Drop Image or Video or</Typography>
                        <Button variant='outlined' color='primary' onClick={() => openFileSelector()}>
                          Browse
                        </Button>
                        <Typography className={classes.imagePlaceholderTitle}>or</Typography>
                        <Button variant='outlined' color='primary' onClick={() => setShowUrlModal(true)}>
                          Add Media URL
                        </Button>
                      </Box>
                    )}
                  </div>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

const InsightEditField = ({
  insightKey,
  insights,
  setInsights,
  showError
}: {
  insightKey: keyof SupplementMediaInsightsInput;
  insights: SupplementMediaInsightsInput;
  setInsights: (updates: SupplementMediaInsightsInput) => void;
  showError?: boolean;
}) => {
  const { classes } = editStyles();

  return (
    <Grid container>
      <Grid item xs={12} className={classes.fieldContainer}>
        <TextField
          variant='standard'
          fullWidth
          label={insightsToUse[insightKey]}
          placeholder={'Value'}
          helperText={showError ? `Please provide a value for ${insightsToUse[insightKey]}` : undefined}
          value={insights[insightKey] || ''}
          type='number'
          onChange={(e: any) =>
            setInsights({ ...insights, [insightKey]: e.target.value ? Number(e.target.value) : undefined })
          }
          error={showError}
        />
      </Grid>
    </Grid>
  );
};

const editStyles = makeStyles()((theme) => ({
  warningText: {
    color: theme.palette.error.main,
    marginBottom: 12
  },
  fieldContainer: {
    paddingRight: 20,
    paddingBottom: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  imageFieldContainer: {
    alignItems: 'flex-start'
  },
  imageSection: {
    backgroundColor: theme.palette.info.light,
    padding: 10,
    marginBottom: 10,
    width: '100%',
    height: 'fit-content'
  },
  imageEditContainer: { display: 'flex', justifyContent: 'center' },
  imageEditor: { width: '100%', position: 'relative' },
  imagePlaceholderTitle: {
    color: theme.palette.primary.main,
    margin: 4
  },
  imagePlaceholder: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: 16,
    color: theme.palette.primary.main,
    textAlign: 'center',
    padding: 12,
    minHeight: 160,
    justifyContent: 'center'
  },
  textEditor: { padding: 15, minHeight: 80, width: '100%' },
  removeButtonContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 100
  }
}));

interface IMediaUrlEditModal {
  onToggle: () => void;
  onConfirm: (id?: string) => void;
}

const MediaUrlEditModal = ({ onToggle, onConfirm }: IMediaUrlEditModal) => {
  const theme = useTheme() as any;
  const [urlInput, setUrlInput] = useState('');
  const [shouldHighlightRequired, setShouldHighlightRequired] = useState(false);

  return (
    <Modal
      isOpen
      hideConfirmationButtons
      maxWidth={'md'}
      fullWidth
      onToggle={onToggle}
      paperStyle={{ padding: 0 }}
      heading={
        <Grid
          container
          alignItems='center'
          justifyContent='space-between'
          style={{
            backgroundColor: theme.palette.background.dark,
            height: 122,
            padding: 10,
            borderBottomColor: theme.palette.primary.main,
            borderBottomStyle: 'solid',
            borderBottomWidth: 0.5
          }}
        >
          <Grid item xs={3}>
            {onToggle && <Button onClick={onToggle}>Back</Button>}
          </Grid>
          <Grid item>Unique Media URL</Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      }
    >
      <Grid container style={{ padding: 50, paddingLeft: 90, paddingRight: 90 }}>
        <Grid item xs={12} container>
          <Grid item xs={6}>
            <Typography variant='h6'>Paste Link</Typography>
            <TextField
              variant='standard'
              placeholder='https://www.instagram.com/reel/000000/'
              value={urlInput}
              onChange={(e: any) => {
                setUrlInput(e.target.value);
              }}
              error={Boolean(shouldHighlightRequired && !urlInput)}
              fullWidth={true}
              helperText={shouldHighlightRequired ? 'Invalid URL input' : undefined}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <Typography style={{ fontWeight: 500, fontSize: 14 }}>
              The unique Reel link can be found in the 3 dot menu as ‘copy link’ do not use your browser URL
            </Typography>
            <Typography style={{ fontWeight: 500, fontSize: 14 }}>
              e.g: https://www.instagram.com/reel/000000/
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          container
          spacing={2}
          justifyContent='flex-start'
          alignItems='center'
          style={{ paddingTop: 40 }}
        >
          <Grid item>
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                const extractedMediaId = urlInput ? extractIdFromUrl(urlInput) : undefined;
                if (extractedMediaId) {
                  onConfirm(extractedMediaId);
                } else {
                  setShouldHighlightRequired(true);
                }
              }}
            >
              Save
            </Button>
          </Grid>
          <Grid>
            <Button variant='outlined' color='primary' onClick={onToggle}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

const extractIdFromUrl = (url: string) => {
  const instagramRegex = new RegExp('instagram.com/reel/([a-zA-Z0-9_-]+)[/]?', 'g');

  const instagramMatches = instagramRegex.exec(url);

  if (instagramMatches?.length && instagramMatches.length > 1) {
    return instagramMatches[1];
  }

  const tiktokRegex = new RegExp('tiktok.com/@[a-zA-Z0-9_.-]+/video/([0-9]+)', 'g');

  const tiktokMatches = tiktokRegex.exec(url);

  if (tiktokMatches?.length && tiktokMatches.length > 1) {
    return tiktokMatches[1];
  }

  return undefined;
};
