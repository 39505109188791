import ButtonBase from '@mui/material/ButtonBase';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import EditIcon from 'assets/icons/EditIcon.svg';
import Modal from 'components/Modal';
import useTracking from 'context/useTrackingCtx';
import { formatError } from 'lib/formatStrings';
import React, { useState, useContext } from 'react';
import { useDeleteInvoiceMutation, GetInvoicesDocument } from 'types/generated';
import { InvoiceCreation } from './InvoiceCreation';
import InvoiceShareContext from 'context/InvoiceShareContext';

interface Props {
  invoiceId: string;
}

export const InvoiceEditMenu = (props: Props) => {
  const { invoiceId } = props;
  const { trackEvent } = useTracking();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteInvoice, { loading: deleteLoading, error: deleteError }] = useDeleteInvoiceMutation({
    refetchQueries: [{ query: GetInvoicesDocument }],
    awaitRefetchQueries: true
  });
  const { setShareInvoiceId } = useContext(InvoiceShareContext);

  const deleteErrorMessage = deleteError ? formatError(deleteError) : '';

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  const toggleDeleteModal = () => {
    setDeleteModalOpen(!deleteModalOpen);
    if (anchorEl) closeMenu();
  };

  const handleDeleteInvoice = () => {
    deleteInvoice({ variables: { id: invoiceId } }).then(() => {
      trackEvent('invoice', 'deleted invoice');
      toggleDeleteModal();
    });
  };

  const DeleteModal = () => (
    <Modal
      isOpen={deleteModalOpen}
      onToggle={toggleDeleteModal}
      onConfirm={handleDeleteInvoice}
      isLoadingConfirm={deleteLoading}
      confirmLabel={'Void'}
      errorMessage={deleteErrorMessage}
      showErrorMessage={Boolean(deleteError)}
      heading={'Void invoice'}
      maxWidth={'sm'}
    >
      <Typography style={{ padding: '10px 0 20px' }}>Are you sure you want to void this invoice?</Typography>
    </Modal>
  );

  const MenuOptions = () => (
    <Menu id='long-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={closeMenu}>
      <InvoiceCreation
        invoiceIdForEditing={invoiceId}
        toggleModal={() => {
          closeMenu();
        }}
      >
        <MenuItem style={{ width: '100%' }}>Edit</MenuItem>
      </InvoiceCreation>
      <MenuItem
        onClick={() => {
          setShareInvoiceId(invoiceId);
          closeMenu();
        }}
      >
        Share
      </MenuItem>
      <MenuItem onClick={toggleDeleteModal}>Void</MenuItem>
    </Menu>
  );

  return (
    <>
      {anchorEl && <MenuOptions />}
      {deleteModalOpen && <DeleteModal />}
      <ButtonBase onClick={(event) => openMenu(event)} style={{ borderRadius: '100%' }}>
        <img src={EditIcon} alt='Edit' style={{ fontSize: 22 }} />
      </ButtonBase>
    </>
  );
};
