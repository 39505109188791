import DashboardPage from 'components/pages/DashboardPage';
import Logout from 'components/pages/Logout';
import LoginProgress from 'components/pages/LoginProgress';
import MediaKitBuilder from 'components/pages/MediaKitBuilder/MediaKitBuilder';
import { Route, Routes } from 'react-router-dom';

const Routers = () => {
  return (
    <Routes>
      <Route path='/login-admin/:token' element={<LoginProgress />} />
      <Route path='/logout' element={<Logout />} />
      <Route path='/404' element={<div>not found</div>} />
      <Route path='/mediakit-edit/:id' element={<MediaKitBuilder />} />
      <Route path='/*' element={<DashboardPage />} />
    </Routes>
  );
};

export default Routers;
