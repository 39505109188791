import { useState, useEffect, useRef } from 'react';

import { Grid, Button, IconButton, InputAdornment, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ClearIcon from '@mui/icons-material/Clear';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { FileInput } from 'types/generated';
import DocsIcon from 'assets/icons/google-docs-icon.svg';
import { defaultColors } from 'config/muiTheme';

import { get, compact } from 'lodash';

const styles = makeStyles()((theme) => ({
  row: {
    marginBottom: 5,
    marginTop: 5,
    '& a': {
      color: defaultColors.fontColorLight
    }
  },
  fileRow: {
    marginBottom: 10,
    '& button': {
      textAlign: 'left',
      color: '#6C6C72',
      cursor: 'pointer',
      border: 'none',
      background: 'none',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    '& p': {
      color: '#6C6C72'
    },
    '& a': {
      color: '#6C6C72'
    }
  },
  title: {
    fontSize: 12,
    color: defaultColors.fontColorLight
  }
}));

interface FileUploadsField {
  setValue?: (items: Array<FileInput>) => void;
  value?: Array<FileInput | null> | null;
}

const FilesBox = ({ value, setValue }: FileUploadsField) => {
  const { classes } = styles();

  const [fileContent, setFileContent] = useState<Array<FileInput>>([]);

  const handleUpdateItem = (item: FileInput, index: number) => {
    const updatedContent = fileContent.map((o, i) => (i === index ? item : o));
    setFileContent(updatedContent);
    if (setValue) {
      setValue(updatedContent);
    }
  };

  const handleAddItem = () => {
    setFileContent([...fileContent, { title: '' }]);
  };

  const handleRemoveItem = (index: number) => {
    const updatedContent = fileContent.filter((o, i) => (i === index ? false : true));
    setFileContent(updatedContent);
    if (setValue) {
      setValue(updatedContent);
    }
  };

  useEffect(() => {
    if (value) {
      setFileContent(compact(value));
    }
  }, [setFileContent, value]);

  return (
    <Grid container>
      {setValue && (
        <Grid item xs={12} container justifyContent={'flex-start'} className={classes.row}>
          <Grid item>
            <Button
              size={'small'}
              variant={'outlined'}
              onClick={() => handleAddItem()}
              startIcon={<AttachFileIcon style={{ transform: 'rotate(45deg)' }} />}
            >
              Attach
            </Button>
          </Grid>
        </Grid>
      )}
      {fileContent.map((o, i) => (
        <Grid
          item
          xs={12}
          container
          key={`link_${i}`}
          wrap={'nowrap'}
          direction={'row'}
          justifyContent={'flex-start'}
          alignItems={'center'}
          className={classes.fileRow}
          spacing={2}
        >
          <Grid item>
            <img src={DocsIcon} alt='unchecked box' style={{ height: 25, width: 25, marginLeft: 2, marginRight: 12 }} />
          </Grid>
          <Grid item>
            <FilePair
              item={o}
              onChange={(link) => (link ? handleUpdateItem(link, i) : handleRemoveItem(i))}
              disabled={!Boolean(setValue)}
            />
          </Grid>
          {setValue && (
            <Grid item>
              <InputAdornment position='end'>
                <IconButton onClick={() => handleRemoveItem(i)}>
                  <ClearIcon fontSize={'large'} />
                </IconButton>
              </InputAdornment>
            </Grid>
          )}
        </Grid>
      ))}
    </Grid>
  );
};

const FilePair = ({
  item,
  onChange,
  disabled
}: {
  item: FileInput;
  onChange: (item?: FileInput) => void;
  disabled?: boolean;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!item.file) {
      inputRef.current?.click();
    }
  }, [inputRef, item]);

  if (item.link || disabled) {
    if (item.link) {
      return (
        <a href={item.link} target='_blank' rel='noopener noreferrer'>
          {item.title}
        </a>
      );
    }
    return <Typography>{item.title || item.file?.name || 'No title'}</Typography>;
  }
  return (
    <Grid item>
      <button onClick={() => inputRef.current?.click()}>
        {item.file?.name ? item.file.name : <i>No file selected...</i>}
      </button>
      <input
        ref={inputRef}
        type='file'
        accept='application/pdf'
        style={{ opacity: 0, top: 0, left: 0, position: 'absolute' }}
        onChange={(e) => {
          const file = get(e, ['target', 'files', 0]);
          if (file) {
            onChange({ ...item, file, fileSize: file.size });
          }
        }}
      />
    </Grid>
  );
};

export default FilesBox;
