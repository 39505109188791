import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { EditBoxProps } from './MediaKitEditBox';

import { Grid, ButtonBase, Button, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Box from '@mui/material/Box';
import ImageIcon from '@mui/icons-material/Image';

import { useDropzone } from 'react-dropzone';

import AddIcon from '../../../assets/components/AddIcon';

import { ElementContentType, VideoContent } from 'types/generated';

const styles = makeStyles()((theme) => ({
  error: { color: 'red' },
  editorContainer: { marginTop: 15, padding: 8, alignItems: 'center' },
  imageEditContainer: { display: 'flex', justifyContent: 'center' },
  imageEditor: { width: '100%' },
  imagePreview: { width: '100%', height: 300 },
  imagePlaceholder: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: 16,
    color: theme.palette.primary.main,
    textAlign: 'center',
    padding: 12
  },
  imagePlaceholderTitle: {
    color: theme.palette.primary.main,
    margin: 4
  },
  warningText: {
    color: theme.palette.error.main,
    marginBottom: 12
  },
  placeholderContainer: {
    width: '100%',
    paddingBottom: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 200
  }
}));

function isVideoContent(content?: ElementContentType | null): content is VideoContent {
  return content && (content as VideoContent).video_content !== undefined ? true : false;
}

const VideoBox = ({ element: { content }, handleUpdate, isEditing, setIsEditing }: EditBoxProps) => {
  const { classes } = styles();
  const [previewUrl, setPreviewUrl] = useState<string | undefined>();
  const [videoContent, setVideoContent] = useState<File | undefined>();
  const [showRejectedFileWarning, setShowRejectedFileWarning] = useState<boolean>(false);

  const fileReader = useMemo(() => new FileReader(), []);

  const dropVideo = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles[0]) {
        fileReader.onload = (e) => {
          if (e?.target?.result && typeof e?.target?.result !== 'string') {
            const blob = new Blob([e.target.result]);
            const dataUrl = URL.createObjectURL(blob);

            setPreviewUrl(dataUrl);
          }
        };

        setVideoContent(acceptedFiles[0]);

        fileReader.readAsArrayBuffer(acceptedFiles[0]);
      }
    },
    [fileReader]
  );

  const storedVideoContent = useMemo(
    () => (isVideoContent(content) ? content.video_content || undefined : undefined),
    [content]
  );

  useEffect(() => {
    if (!isEditing) {
      setPreviewUrl(storedVideoContent);
    }
  }, [storedVideoContent, setPreviewUrl, isEditing]);

  const fileDropRejected = () => {
    setShowRejectedFileWarning(true);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: dropVideo,
    accept: ['video/mp4', 'video/quicktime'],
    maxFiles: 1,
    maxSize: 100000000, // 100MB
    onDropRejected: fileDropRejected
  });

  if (isEditing) {
    return (
      <Grid container>
        <Grid item xs={12} className={classes.imageEditContainer}>
          {showRejectedFileWarning ? (
            <Grid container direction='column' alignItems='center' justifyContent='center'>
              <Grid item>
                <Typography className={classes.warningText} align='center'>
                  Your video file must be of the type ‘mp4’ or ‘mov’
                  <br />
                  and be less than 100MB in size
                </Typography>
              </Grid>
              <Grid item>
                <Button variant='outlined' onClick={() => setShowRejectedFileWarning(false)}>
                  Okay, got it!
                </Button>
              </Grid>
            </Grid>
          ) : (
            <div {...getRootProps()} className={classes.imageEditor}>
              <input {...getInputProps()} />

              {previewUrl && <video src={previewUrl} controls style={{ width: '100%' }} />}
              <Box className={classes.imagePlaceholder}>
                <ImageIcon fontSize={'large'} />
                <Typography className={classes.imagePlaceholderTitle}>
                  Drop a video file with the type ‘mp4’ or ‘mov’ and up to 100MB
                  <br />
                  or
                </Typography>
                <Button variant='outlined' color='primary'>
                  Browse
                </Button>
              </Box>
            </div>
          )}
        </Grid>
        <Grid item xs={12} container justifyContent='flex-start'>
          <Button
            variant='outlined'
            color='primary'
            onClick={() => {
              if (videoContent) {
                handleUpdate({ ...content, video_content: videoContent }, { ...content, video_content: previewUrl });
              }
            }}
          >
            {`Save & Close`}
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      {previewUrl ? (
        <video src={previewUrl} controls style={{ width: '100%' }} />
      ) : (
        <ButtonBase className={classes.placeholderContainer} onClick={() => setIsEditing(true)}>
          <AddIcon size={35} strokeWidth={3} />
        </ButtonBase>
      )}
    </>
  );
};

export default VideoBox;
