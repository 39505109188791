import React from 'react';
import { useTheme } from '@mui/material';

const TickOutlined = ({ active, size }: { active?: boolean; size?: number }) => {
  const theme = useTheme();

  return (
    <svg
      width={size ? `${size}px` : '24px'}
      height={size ? `${size}px` : '24px'}
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>F439397A-1C74-440B-9AA3-FB5B5B47ECE0@1x</title>
      <g
        id='Style-Guide'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <g
          id='Kitlfy-for-Business-Style-Guide_2'
          transform='translate(-621.000000, -701.000000)'
          stroke={active ? theme.palette.primary.main : '#CCCCCC'}
        >
          <g id='c-check' transform='translate(621.000000, 701.000000)'>
            <circle id='Oval' cx='12' cy='12' r='11.25'></circle>
            <polyline id='Path' points='6.75 11.25 10.5 15 18 7.5'></polyline>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default TickOutlined;
