import React, { CSSProperties } from 'react';
import { useTheme } from '@mui/material';

const AddIcon = ({
  fill,
  style,
  strokeWidth,
  size
}: {
  fill?: string;
  style?: CSSProperties;
  strokeWidth?: number;
  size?: number;
}) => {
  const theme = useTheme();

  const iconSize = size || 39;

  const fillColour = fill || theme.palette.primary.main;
  const strokeWidthNumber = strokeWidth ? `${strokeWidth}` : '3';
  return (
    <svg
      width={`${iconSize}px`}
      height={`${iconSize}px`}
      viewBox='0 0 39 39'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>Icon/General/Add - purple large </title>
      <g
        id='2.-V3---Media-Kit---Uploaded-'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <g
          id='6.-V2.-Media-Kit-builder---Mode-2-of-4-'
          transform='translate(-460.000000, -3011.000000)'
          stroke={fillColour}
          strokeWidth={strokeWidthNumber}
        >
          <g id='Group-9' transform='translate(264.000000, 2904.000000)'>
            <g id='add-notification' transform='translate(197.926420, 108.843750)'>
              <path
                d='M28.125,21.09375 L28.125,32.8125 C28.125,34.10625 27.075,35.15625 25.78125,35.15625 L2.34375,35.15625 C1.05,35.15625 0,34.10625 0,32.8125 L0,9.375 C0,8.08125 1.05,7.03125 2.34375,7.03125 L14.0625,7.03125'
                id='Path'
              ></path>
              <line x1='28.125' y1='0' x2='28.125' y2='14.0625' id='Path'></line>
              <line x1='21.09375' y1='7.03125' x2='35.15625' y2='7.03125' id='Path'></line>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default AddIcon;
