import React from 'react';
import { IconButton, useTheme } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Close';

const CloseButton = ({
  onPress,
  filledCircle,
  size
}: {
  onPress: () => void;
  filledCircle?: boolean;
  size?: number;
}) => {
  const theme = useTheme();
  return (
    <IconButton
      onClick={onPress}
      style={{
        position: 'absolute',
        top: 0,
        right: 5,
        backgroundColor: filledCircle ? theme.palette.primary.main : undefined,
        width: size,
        height: size,
        zIndex: 100
      }}
    >
      <DeleteIcon style={{ color: filledCircle ? theme.palette.common.white : undefined }} />
    </IconButton>
  );
};

export default CloseButton;
