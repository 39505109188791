import { useEffect } from 'react';
import Routers from 'routers/Routers';
import { Route, Routes } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { SubscribePopupProvider } from 'context/useSubscribePopupCtx';
import ReauthenticationModal from 'components/settings/ReauthenticationModal';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import config from '../config/appConfig';
import { getVersionNumber } from '../components/VersionInfo';
import fontList from '../lib/fonts';

const stripePromise = loadStripe(config.STRIPE_PK);

export const App = () => {
  useEffect(() => {
    console.log('Kitly Web', getVersionNumber());
    console.log('Environment', process.env.NODE_ENV);
    console.log('Deployment', process.env.REACT_APP_DEPLOYMENT);
  }, []);
  return (
    <Routes>
      <Route path={'/subscribe/s/complete/:stripeSessionId'} element={<MainRoutes />} />
      <Route path='/*' element={<MainRoutes />} />
    </Routes>
  );
};

const MainRoutes = () => {
  return (
    <Elements
      stripe={stripePromise}
      options={{
        fonts: fontList
      }}
    >
      <SnackbarProvider maxSnack={10}>
        <SubscribePopupProvider>
          <ReauthenticationModal />
          <Routers />
        </SubscribePopupProvider>
      </SnackbarProvider>
    </Elements>
  );
};
