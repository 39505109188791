import { GetMyUserDocument, useUpdateWalkthroughsMutation } from 'types/generated';
import { useUserConfig } from './useUserConfig';
import { WALKTHROUGHS } from 'types/custom';
import { useNavigate } from 'react-router';

export const useWalkthrough = (
  walkthrough: WALKTHROUGHS,
  callbackRoute?: string
): { shouldShowWalkthrough: boolean; handleWalkthrough: () => void; loading: boolean } => {
  const { config } = useUserConfig();
  const [updateWalkthroughs, { loading }] = useUpdateWalkthroughsMutation({
    refetchQueries: [{ query: GetMyUserDocument }]
  });
  const navigate = useNavigate();

  return {
    shouldShowWalkthrough: !Boolean(config?.walkthrough?.includes(walkthrough)),
    handleWalkthrough: () => {
      if (config?.walkthrough?.includes(walkthrough)) {
        if (callbackRoute) {
          navigate(callbackRoute);
        }
      } else {
        updateWalkthroughs({ variables: { update: walkthrough } }).then(() => {
          if (callbackRoute) {
            navigate(callbackRoute);
          }
        });
      }
    },
    loading
  } as const;
};
