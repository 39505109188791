import { ReactNode } from 'react';

import { BoxProps, Paper, Typography, Grid, Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface Props extends BoxProps {
  children: JSX.Element[] | JSX.Element;
  title?: string;
  disabled?: boolean;
  topRightContent?: ReactNode;
}

export const TileContainer = ({ children, disabled, title, topRightContent, ...otherProps }: Props) => {
  const { classes } = useClasses();
  return (
    <Paper>
      <Box
        {...otherProps}
        position='relative'
        borderRadius={4}
        padding={'20px'}
        {...(disabled && { style: { opacity: 0.5 } })}
      >
        <Grid container justifyContent='space-between'>
          <Grid item>{title && <Typography className={classes.cardTitle}>{title}</Typography>}</Grid>
          {topRightContent && <Grid item>{topRightContent}</Grid>}
        </Grid>

        {children}
      </Box>
    </Paper>
  );
};

const useClasses = makeStyles()((theme) => ({
  root: {},
  cardWrapper: {
    padding: 6
  },
  cardTitle: {
    fontWeight: 500,
    fontFamily: 'Open Sans',
    fontSize: 14,
    color: '#3A3A3A',
    marginBottom: 11
  },
  cardSubtitle: {
    fontSize: 12,
    color: '#19252E',
    fontWeight: 500,
    fontFamily: 'Open Sans'
  }
}));
