import React from 'react';
import { useTheme } from '@mui/material';

const NotesIcon = () => {
  const theme = useTheme();

  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'>
      <title>Notes</title>
      <g
        strokeLinecap='square'
        strokeLinejoin='miter'
        strokeWidth='1'
        fill={theme.palette.primary.dark}
        stroke={theme.palette.primary.dark}
      >
        <rect
          x='1'
          y='3'
          width='22'
          height='18'
          fill='none'
          stroke={theme.palette.primary.dark}
          strokeMiterlimit='10'
        />
        <line x1='6' y1='8' x2='18' y2='8' fill='none' strokeMiterlimit='10' data-color='color-2' />
        <line x1='6' y1='12' x2='18' y2='12' fill='none' strokeMiterlimit='10' data-color='color-2' />
        <line x1='6' y1='16' x2='11' y2='16' fill='none' strokeMiterlimit='10' data-color='color-2' />
      </g>
    </svg>
  );
};

export default NotesIcon;
