import { Grid, Hidden, Button, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import InstagramIcon from '../../../assets/icons/insta.svg';
import FacebookIcon from '../../../assets/icons/facebook.svg';
import YoutubeIcon from '../../../assets/icons/youtube.svg';

import { logoUrl } from 'config/muiTheme';

const KitFooter = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.wrapper}>
      <Grid container justifyContent='space-between' alignItems='center' className={classes.container}>
        <Grid item>
          <img src={logoUrl} alt='logo' style={{ maxHeight: '30px', height: 20, display: 'block' }} />
        </Grid>
        <Hidden smDown>
          <Grid item>
            <Typography className={classes.title}>Campaign Management, simplified</Typography>
          </Grid>
        </Hidden>

        <Grid item>
          <Grid container alignItems='center'>
            <Grid item>
              <Hidden smDown>
                <Grid container justifyContent='flex-end' wrap='nowrap' alignItems='center'>
                  <Grid item>
                    <img alt='Feed' src={InstagramIcon} className={classes.tabIcon} />
                  </Grid>
                  <Grid item>
                    <img alt='Feed' src={FacebookIcon} className={classes.tabIcon} />
                  </Grid>
                  <Grid item>
                    <img alt='Feed' src={YoutubeIcon} className={classes.tabIcon} />
                  </Grid>
                </Grid>
              </Hidden>
            </Grid>
            <Button variant='contained' size={'small'} className={classes.action} color='primary'>
              Learn more
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    width: '100%',
    backgroundColor: '#EDEDEE',
    borderTopColor: theme.palette.secondary.light,
    borderTopWidth: 0.5,
    borderTopStyle: 'solid'
  },
  container: {
    padding: 12,
    paddingLeft: 20,
    paddingRight: 20,
    maxWidth: 1000,
    margin: '0 auto'
  },
  title: {
    fontSize: 7,
    fontWeight: 500,
    color: '#333333'
  },
  action: {
    padding: 0,
    minWidth: 54,
    height: 24,
    fontSize: 7
  },
  tabIcon: {
    marginRight: 5,
    height: 12,
    width: 12,
    marginBottom: '0 !important',
    display: 'block'
  }
}));

export default KitFooter;
