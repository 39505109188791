import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { Button, Dialog } from '@mui/material';

import KitlyIntro from './KitlyIntro';
import { Exact, GetMyUserQuery } from 'types/generated';
import { ApolloQueryResult } from '@apollo/client/core/types';

const styles = makeStyles()({
  modalStyle: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'
  },
  paperStyle: { padding: 0 }
});

interface WelcomeModalProps {
  refetchUser:
    | ((
        variables?:
          | Partial<
              Exact<{
                [key: string]: never;
              }>
            >
          | undefined
      ) => Promise<ApolloQueryResult<GetMyUserQuery>>)
    | undefined;
  showWalkthrough: boolean;
  dismissWalkthrough: () => void;
}

const WelcomeModal = (props: WelcomeModalProps) => {
  const { classes } = styles();
  const { showWalkthrough, dismissWalkthrough } = props;

  return (
    <Dialog
      open={showWalkthrough}
      fullScreen={true}
      className={classes.modalStyle}
      classes={{ paper: classes.paperStyle }}
    >
      <div className={classes.modalStyle} style={{ width: '100vw' }}>
        <WelcomeModalContents {...props} showWalkthrough={showWalkthrough} dismissWalkthrough={dismissWalkthrough} />
      </div>
    </Dialog>
  );
};

const WelcomeModalContents = (props: WelcomeModalProps) => {
  const { refetchUser, showWalkthrough, dismissWalkthrough } = props;
  if (showWalkthrough) {
    return <KitlyIntro refetchUser={refetchUser} dismissWalkthrough={dismissWalkthrough} />;
  }
  // Shouldn't appear, just a fallback
  return (
    <Button onClick={() => dismissWalkthrough()} variant='outlined'>
      Return to dashboard
    </Button>
  );
};

export default WelcomeModal;
