const SettingsIcon = ({ stroke }: { stroke?: string }) => {
  return (
    // <?xml version="1.0" encoding="UTF-8"?>
    <svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <title>6BDEDC8A-FF23-451B-B9FD-6EF0E48CFFD2@1x</title>
      <g id='-' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' strokeLinecap='square'>
        <g
          id='Kitlfy-for-Business-Style-Guide_2'
          transform='translate(-709.000000, -331.000000)'
          stroke={stroke || '#5C5CB9'}
        >
          <g id='Icon/Browse-Copy-7' transform='translate(709.000000, 331.000000)'>
            <g id='gear' transform='translate(1.000000, 1.000000)'>
              <path
                d='M22.5,11.5 C22.5,10.395 21.605,9.5 20.5,9.5 L19.238,9.5 C19.049,8.768 18.761,8.078 18.386,7.442 L19.278,6.55 C20.059,5.769 20.059,4.503 19.278,3.722 C18.497,2.941 17.231,2.941 16.45,3.722 L15.558,4.614 C14.922,4.239 14.232,3.951 13.5,3.762 L13.5,2.5 C13.5,1.396 12.605,0.5 11.5,0.5 C10.395,0.5 9.5,1.395 9.5,2.5 L9.5,3.762 C8.768,3.951 8.078,4.239 7.442,4.614 L6.55,3.722 C5.769,2.941 4.503,2.941 3.722,3.722 C2.941,4.503 2.941,5.769 3.722,6.55 L4.614,7.442 C4.239,8.078 3.951,8.768 3.762,9.5 L2.5,9.5 C1.396,9.5 0.5,10.395 0.5,11.5 C0.5,12.605 1.395,13.5 2.5,13.5 L3.762,13.5 C3.951,14.232 4.239,14.922 4.614,15.558 L3.722,16.45 C2.941,17.231 2.941,18.497 3.722,19.278 C4.503,20.059 5.769,20.059 6.55,19.278 L7.442,18.386 C8.077,18.761 8.768,19.049 9.5,19.238 L9.5,20.5 C9.5,21.604 10.395,22.5 11.5,22.5 C12.605,22.5 13.5,21.605 13.5,20.5 L13.5,19.238 C14.232,19.049 14.922,18.761 15.558,18.386 L16.45,19.278 C17.231,20.059 18.497,20.059 19.278,19.278 C20.059,18.497 20.059,17.231 19.278,16.45 L18.386,15.558 C18.761,14.923 19.049,14.232 19.238,13.5 L20.5,13.5 C21.604,13.5 22.5,12.605 22.5,11.5 Z'
                id='Path'
              ></path>
              <circle id='Oval' cx='11.5' cy='11.5' r='3'></circle>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SettingsIcon;
