import { useEffect } from 'react';
import { ApolloQueryResult } from '@apollo/client';
import { Dialog, DialogActions, Grid, Typography, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { Exact, GetMyUserQuery, useUpdateWalkthroughsMutation } from 'types/generated';

import { useErrorHandlerHook } from 'hooks/useErrorCatchHook';

interface NewReleaseUpdatesModalProps {
  versionString: string;
  refetchUser:
    | ((
        variables?:
          | Partial<
              Exact<{
                [key: string]: never;
              }>
            >
          | undefined
      ) => Promise<ApolloQueryResult<GetMyUserQuery>>)
    | undefined;
}

const useStyles = makeStyles()((theme) => ({
  bodyText: {
    fontSize: 12,
    lineHeight: '24px'
  },
  listStyles: {
    '& li::marker': {
      color: theme.palette.primary.main,
      fontSize: 24
    }
  }
}));

const MediaKitUpdateImage = 'https://media.q-83.com/releases/version_2_0_update.png';

const NewReleaseUpdatesModal = ({ versionString, refetchUser }: NewReleaseUpdatesModalProps) => {
  const { apolloHandler } = useErrorHandlerHook();
  const { classes } = useStyles();

  const [updateWalkthroughs, { error }] = useUpdateWalkthroughsMutation();

  useEffect(() => {
    if (error) {
      apolloHandler(error);
    }
  }, [error, apolloHandler]);

  const handleAcknowledgeMessage = () => {
    updateWalkthroughs({ variables: { update: versionString } })
      .then(() => refetchUser!())
      .catch((ex) => apolloHandler(ex));
  };

  return (
    <Dialog
      open
      onClose={handleAcknowledgeMessage}
      aria-label='New Release Updates'
      BackdropProps={{ style: { backgroundColor: 'rgba(235, 235, 235, 0.75)' } }}
      PaperProps={{ style: { width: 537 } }}
    >
      <Grid container style={{ padding: '40px 20px 20px' }}>
        <Grid item container xs={12} justifyContent='center'>
          <img src={MediaKitUpdateImage} alt='Example media kit' style={{ width: 355 }} />
        </Grid>
        <Grid item xs={12} style={{ margin: '20px 0' }}>
          <Typography variant='h4' align='center'>
            {`New Release ${versionString}`}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 20 }}>
          <Typography variant='body2' align='center' className={classes.bodyText}>
            If you’re not sitting down you should as we have some huge news!
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 20 }}>
          <Typography variant='body2' align='center' className={classes.bodyText}>
            Kitly V2.0 see’s the launch of the '<span style={{ fontWeight: 'bold' }}>Kitly Creator Network</span>'
            allowing you as a creator to assign categories and a media kit to your profile in the ‘
            <span style={{ fontWeight: 'bold' }}>Creator Network</span>’ tab which will be displayed as above to the
            Brands and Marketers looking for talent.
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 20 }}>
          <Typography variant='body2' align='center' className={classes.bodyText} style={{ fontWeight: 'bold' }}>
            Oh, and all of our awesome Starter & Creator users will be highlighted as ‘Pro’ users in the Network.
          </Typography>
        </Grid>
      </Grid>
      <DialogActions>
        <Button
          variant='contained'
          onClick={() => handleAcknowledgeMessage()}
          style={{ height: 60, width: 275, margin: '0 auto 30px' }}
        >
          Got it
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewReleaseUpdatesModal;
