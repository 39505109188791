import {
  Profile,
  Contact,
  ElementContentType,
  TextContent,
  RichTextContent,
  ImageContent,
  GalleryContent,
  GalleryInput,
  LinksContent,
  FilesContent,
  RatesContent,
  VideoContent,
  LinkedAccountsContent,
  FeaturedInsightsContent,
  CollaborationInvoiceLink,
  ReportLink,
  MediaKitLink
} from './generated';

export function isCollaborationInvoicelink(
  item?: CollaborationInvoiceLink | ReportLink | MediaKitLink | undefined
): item is CollaborationInvoiceLink {
  return item && (item as CollaborationInvoiceLink).collaborationInvoiceId !== undefined ? true : false;
}

export function isReportLink(
  item?: CollaborationInvoiceLink | ReportLink | MediaKitLink | undefined
): item is ReportLink {
  return item && (item as ReportLink).reportConfigId !== undefined ? true : false;
}

export function isMediaKitLink(
  item?: CollaborationInvoiceLink | ReportLink | MediaKitLink | undefined
): item is ReportLink {
  return item && (item as MediaKitLink).mediaKitId !== undefined ? true : false;
}

export function isProfile(item?: Profile | string | null): item is Profile {
  return item && (item as Profile)._id !== undefined ? true : false;
}

export function isContact(item?: Contact | string | null): item is Contact {
  return item && (item as Contact)._id !== undefined ? true : false;
}

export type TrackingType = 'CAMPAIGN' | 'REPORT' | 'DELIVERABLE';

export type GridTypes = boolean | 4 | 2 | 1 | 5 | 6 | 3 | 10 | 7 | 8 | 9 | 11 | 12 | 'auto' | undefined;

export enum WALKTHROUGHS {
  COLLABORATIONS = 'collaborations',
  REPORTS = 'reports',
  MEDIA_KITS = 'mediaKits',
  FIRST_LOGIN = 'firstLogin',
  FINANCE = 'finance'
}

export const isTextContent = (content?: ElementContentType | null): content is TextContent => {
  return Boolean(content && (content as TextContent).text_content !== undefined);
};

export const isRichTextContent = (content?: ElementContentType | null): content is RichTextContent => {
  return Boolean(content && (content as RichTextContent).rich_text_content !== undefined);
};

export const isImageContent = (content?: ElementContentType | null): content is ImageContent => {
  return Boolean(content && (content as ImageContent).image_content !== undefined);
};

export const isGalleryContent = (content?: ElementContentType | null): content is GalleryContent => {
  return Boolean(content && (content as GalleryContent).gallery_content !== undefined);
};

export const isGalleryInput = (content?: any | null): content is GalleryInput => {
  return Boolean(content && typeof (content as GalleryInput).link === 'string');
};

export const isLinksContent = (content?: ElementContentType | null): content is LinksContent => {
  return Boolean(content && (content as LinksContent).links_content !== undefined);
};

export const isFilesContent = (content?: ElementContentType | null): content is FilesContent => {
  return Boolean(content && (content as FilesContent).file_content !== undefined);
};

export const isRatesContent = (content?: ElementContentType | null): content is RatesContent => {
  return Boolean(content && (content as RatesContent).rate_content !== undefined);
};

export const isVideoContent = (content?: ElementContentType | null): content is VideoContent => {
  return Boolean(content && (content as VideoContent).video_content !== undefined);
};

export const isLinkedAccountsContent = (content?: ElementContentType | null): content is LinkedAccountsContent => {
  return Boolean(content && (content as LinkedAccountsContent).linked_accounts_content !== undefined);
};

export const isFeaturedInsightsContent = (content?: ElementContentType | null): content is FeaturedInsightsContent => {
  return Boolean(content && (content as FeaturedInsightsContent).featured_insights_content !== undefined);
};
