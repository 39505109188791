import { Box, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { useState } from 'react';

interface IAvatar {
  size?: number; // the size of the circle
  src?: string; // the link fo the image
  classes?: string; // customized classname for the wrapper
  firstName?: string; // user first name
  lastName?: string; // user last name
  nameDisplay?: boolean; // should the user first name and last name display at the right side
  avatarBackgroundColor?: string; // the background color of the avatar
  customizedText?: boolean; // the text to replace the user name
  initailsFontSize?: number;
}
const Avatar: React.FC<IAvatar> = (props) => {
  const { classes } = useClasses();
  // Set fontSize for initials. Order of priority: props.initialFontSize > props.size > fallback of 20
  const initailsFontSize = props?.initailsFontSize ? props?.initailsFontSize : props?.size ? props?.size / 1.6 : 20;
  const [imageError, setImageError] = useState<boolean>(false);

  return (
    <Box className={`${classes.wrapper} ${props.classes}`}>
      <Box
        className={classes.avatarWrapper}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: props.size,
          width: props.size,
          backgroundColor: props.avatarBackgroundColor ?? '#84c6ff'
        }}
      >
        {Boolean(props.src && !imageError) ? (
          <img src={props.src} onError={() => setImageError(true)} className={classes.avatarImage} alt='avatar' />
        ) : (
          <Typography align='center' style={{ fontSize: initailsFontSize }}>
            {props.firstName ? props.firstName[0].toUpperCase() : ''}
            {props.lastName ? props.lastName[0].toUpperCase() : ''}
          </Typography>
        )}
      </Box>

      {props.nameDisplay && (props.firstName || props.lastName) && (
        <Typography variant='body1' className={classes.nameWrapper}>
          {props.firstName} {props.lastName}
        </Typography>
      )}
    </Box>
  );
};

Avatar.defaultProps = {
  nameDisplay: true,
  size: 30
};

const useClasses = makeStyles()((theme) => {
  return {
    wrapper: {
      display: 'flex',
      alignItems: 'center'
    },
    avatarWrapper: {
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 100,
      overflow: 'hidden'
    },
    avatarImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    },
    nameWrapper: {
      marginLeft: 10
    }
  };
});

export default Avatar;
