import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import get from 'lodash/get';

import { formatNumber, formatPercentage } from 'lib/formatNumber';
import { Profile } from 'types/generated';

interface ComponentProps {
  profile: Profile;
}

interface StatBoxProps {
  stat: string;
  title: string;
  subtitle: string;
}

const styles = makeStyles()((theme) => ({
  statContainer: {
    border: 'solid #6C6C72 0.5px',
    borderRadius: 4,
    marginBottom: 10,
    padding: '15px 0'
  },
  statTitle: {
    fontSize: 24,
    textAlign: 'center'
  },
  statSubtitle: {
    fontSize: 10,
    fontWeight: 600,
    textAlign: 'center',
    maxWidth: 160,
    margin: '0 auto'
  },
  statTip: {
    fontSize: 10,
    textAlign: 'center',
    maxWidth: 160,
    margin: '0 auto'
  },
  statCell: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 10px'
  },
  statDivider: {
    borderRight: 'solid #D8D8D8 2px',
    width: 2
  }
}));

const SummaryStats = ({ profile }: ComponentProps) => {
  const { classes } = styles();

  const positiveFeedback: number = get(profile, 'pageStats.page_positive_feedbackTotal') || 0;
  const negativeFeedback: number = get(profile, 'pageStats.page_negative_feedbackTotal') || 0;
  const allFeedback = positiveFeedback + negativeFeedback;

  const StatBox = ({ stat, title, subtitle }: StatBoxProps) => (
    <>
      <Typography className={classes.statTitle}>{stat}</Typography>
      <Typography className={classes.statSubtitle}>{title}</Typography>
      <Typography className={classes.statTip}>{subtitle}</Typography>
    </>
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <Grid container className={classes.statContainer}>
          <Grid item xs={3} className={`${classes.statCell} ${classes.statDivider}`}>
            <StatBox
              stat={formatNumber(get(profile, 'postStats.post_reactions_like_total.actual'))}
              title={'Likes'}
              subtitle={'Total like reactions'}
            />
          </Grid>
          <Grid item xs={3} className={`${classes.statCell} ${classes.statDivider}`}>
            <StatBox
              stat={`${formatPercentage(get(profile, 'postStats.post_engaged_users_percentageOfReach.median'))}%`}
              title={'Engagement'}
              subtitle={'Total engagements vs true reach'}
            />
          </Grid>
          <Grid item xs={3} className={`${classes.statCell} ${classes.statDivider}`}>
            <StatBox
              stat={`${formatPercentage(positiveFeedback, allFeedback, 0)}% / ${positiveFeedback}`}
              title={'Positive Feedback'}
              subtitle={'Total positive actions (e.g. likes)'}
            />
          </Grid>
          <Grid item xs={3} className={classes.statCell}>
            <StatBox
              stat={`${formatPercentage(negativeFeedback, allFeedback, 0)}% / ${negativeFeedback}`}
              title={'Negative Feedback'}
              subtitle={'Total negative actions (e.g. hid a post)'}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SummaryStats;
