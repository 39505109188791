import React, { useState, useEffect, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Typography, CircularProgress, Button, Box, Grid } from '@mui/material';
import ProfileListItem from './ProfileListItem';
import { Profile, useGetProfilesListQuery } from '../../../types/generated';
import { isLinkedAccountsContent } from '../../../types/custom';

import { isEmpty, compact } from 'lodash';

import { EditBoxProps } from './MediaKitEditBox';

const styles = makeStyles()((theme) => ({
  error: { color: 'red', marginTop: 12 },
  textEditor: { padding: 15, minHeight: 300, width: '100%' },
  textPreview: { marginTop: 12, fontStyle: 'italic', fontSize: 14 },
  editorTitle: { fontSize: 14, fontWeight: 500 },
  previewContainer: { padding: 10, fontSize: 14, marginTop: 10, paddingBottom: 20 },
  previewEditContainer: {
    backgroundColor: theme.palette.info.light,
    padding: 20,
    marginBottom: 10,
    width: '100%'
  },
  profileListContainer: {
    padding: 10
  }
}));

const LinkedAccountsBox = ({ element: { content, title }, handleUpdate, isEditing }: EditBoxProps) => {
  const { classes } = styles();

  const [linkedAccounts, setLinkedAccounts] = useState<Array<string | null>>([]);
  useEffect(() => {
    if (!isEditing) {
      if (isLinkedAccountsContent(content) && content?.linked_accounts_content) {
        const linkedAccountsContent = content && compact(content?.linked_accounts_content);
        setLinkedAccounts(linkedAccountsContent);
      } else {
        setLinkedAccounts([]);
      }
    }
  }, [content, setLinkedAccounts, isEditing]);

  const { data, loading, error } = useGetProfilesListQuery();

  const profiles: Array<Profile> = useMemo(() => (data ? compact(data.getProfiles) : []), [data]);

  const linkedProfiles = useMemo(() => {
    return profiles.filter((o) => Boolean(o._id && linkedAccounts.includes(o._id)));
  }, [linkedAccounts, profiles]);

  const handleToggleProfile = (profile: Profile) => {
    if (profile._id) {
      if (linkedAccounts.includes(profile._id)) {
        setLinkedAccounts(linkedAccounts.filter((o) => o !== profile._id));
      } else {
        setLinkedAccounts(linkedAccounts.concat([profile._id]));
      }
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return (
      <Typography align='center' variant='h5' className={classes.error}>
        {String(error.message)}
      </Typography>
    );
  }

  if (isEditing) {
    return (
      <Grid container>
        <Box className={classes.previewEditContainer}>
          <Typography align='left' className={classes.editorTitle}>
            Choose Account/s
          </Typography>
          {profiles.map((o, i) => {
            return (
              <ProfileListItem
                key={`accoun_${i}`}
                profile={o}
                onPress={() => handleToggleProfile(o)}
                isActive={Boolean(o._id && linkedAccounts.includes(o._id))}
              />
            );
          })}
        </Box>
        <Grid container justifyContent='flex-start'>
          <Button
            variant='outlined'
            color='primary'
            onClick={() => {
              handleUpdate({ ...content, linked_accounts_content: linkedAccounts });
            }}
          >
            {`Save & Close`}
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <Box className={classes.previewContainer}>
      {isEmpty(linkedProfiles) ? (
        <Typography align='center' className={classes.textPreview}>
          Select profiles to link...
        </Typography>
      ) : (
        linkedProfiles.map((o, i) => {
          return <ProfileListItem key={`account_${i}`} profile={o} />;
        })
      )}
    </Box>
  );
};

export default LinkedAccountsBox;
