import { useState, useEffect } from 'react';

import { useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import PdfButtons from '../../Fields/PdfButtons';
import Modal from 'components/Modal';
import ShareFields, { SharePayload } from 'components/Fields/ShareFields';
import PartyPopperIcon from '../../../assets/icons/PartyPopper.svg';

import useTracking from 'context/useTrackingCtx';
import { formatError } from '../../../lib/formatStrings';

import { useShareInvoiceMutation, GetInvoicesDocument } from '../../../types/generated';

interface ComponentProps {
  invoiceToShareId: string;
  openSubscribePopup?: (show: Boolean, selectedPlanId: string) => void;
  onComplete?: () => void;
  onExternalToggle?: (arg: boolean) => void;
  externalShareMetricsOpen?: boolean;
  isNewlyCreated?: boolean;
  isModalOnly?: boolean;
  children?: any;
  buttonTitle?: string;
}

const ShareInvoiceModal = (props: ComponentProps) => {
  const {
    invoiceToShareId,
    onComplete,
    onExternalToggle,
    externalShareMetricsOpen,
    isNewlyCreated,
    isModalOnly,
    children,
    buttonTitle
  } = props;

  const { trackEvent } = useTracking();

  const theme = useTheme();

  // Modal states:
  const [shareMetricsOpen, setShareMetricsOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  // Error states:
  const [serverError, setServerError] = useState('');

  // External modal states:
  useEffect(() => {
    const isExternalToggle = typeof onExternalToggle !== 'undefined';
    const isExternalState = typeof externalShareMetricsOpen !== 'undefined';
    const isStateMismatch = externalShareMetricsOpen !== shareMetricsOpen;
    if (isExternalToggle && isExternalState && isStateMismatch) {
      // @ts-ignore - checked using isExternalState
      setShareMetricsOpen(externalShareMetricsOpen);
    }
  }, [onExternalToggle, externalShareMetricsOpen, shareMetricsOpen]);

  // Graphql:
  const [shareInvoiceToContact, { loading: shareLoading }] = useShareInvoiceMutation({
    refetchQueries: [{ query: GetInvoicesDocument }]
  });

  const handleToggleShareMetrics = (open?: boolean) => {
    const isOpen = typeof open === 'boolean' ? open : !shareMetricsOpen;
    onExternalToggle ? onExternalToggle(isOpen) : setShareMetricsOpen(isOpen);
  };

  const handleToggleSuccess = (open?: boolean) => {
    const isOpen = typeof open === 'boolean' ? open : !successOpen;
    setSuccessOpen(isOpen);
  };

  const handleShare = (payload: SharePayload) => {
    // Submit payload:
    shareInvoiceToContact({ variables: { id: invoiceToShareId, shareDetails: payload } })
      .then(() => {
        trackEvent('invoice', 'shared invoice');
        if (onComplete) onComplete();
        handleToggleSuccess(true);
        handleToggleShareMetrics(false);
      })
      .catch((err) => {
        setServerError(formatError(err) || 'Unknown error');
      });
  };

  const renderSuccessModal = () => {
    let color = theme?.palette?.primary?.dark;
    return (
      <Dialog open={true} onClose={() => handleToggleSuccess(false)}>
        <Grid container direction='column' alignItems='center' style={{ padding: 15 }}>
          <Grid item>
            <CheckCircleOutlineIcon style={{ fontSize: '36px', color, margin: '15px' }} />
          </Grid>
          <Grid item>
            <span style={{ marginBottom: '5px' }}>Successfully sent invoice</span>
          </Grid>
          <Grid item>
            <Button onClick={() => handleToggleSuccess(false)} color='primary'>
              Okay
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    );
  };

  const renderHeader = () => {
    return (
      <div style={{ position: 'relative', width: '100%' }}>
        {isNewlyCreated ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              marginTop: 39,
              fontSize: 22,
              marginBottom: 40
            }}
          >
            <img src={PartyPopperIcon} alt='Success' style={{ marginBottom: 20 }} />
            <div>Yay! Your invoice has been generated</div>
          </div>
        ) : (
          <span>Share Invoice</span>
        )}
      </div>
    );
  };

  const renderModalContent = () => {
    return (
      <Modal
        isOpen={true}
        onToggle={handleToggleShareMetrics}
        hideConfirmButton
        hideCancelButton
        subConfirmationContent={<PdfButtons collaborationInvoiceId={invoiceToShareId} />}
        errorMessage={serverError}
        showErrorMessage={Boolean(serverError)}
        showCloseIcon={true}
        heading={renderHeader()}
        hideConfirmationButtons
        maxWidth={'sm'}
        padding={'35px 75px'}
      >
        <ShareFields
          onShare={handleShare}
          onDismiss={handleToggleShareMetrics}
          loading={shareLoading}
          sendNewContactRequest={false}
        />
      </Modal>
    );
  };

  return (
    <>
      {successOpen && renderSuccessModal()}
      {renderModalContent()}
      {isModalOnly ? (
        <div />
      ) : children ? (
        children
      ) : (
        <Button variant='contained' color='primary' onClick={() => handleToggleShareMetrics(true)}>
          {buttonTitle ? buttonTitle : `Share Invoice`}
        </Button>
      )}
    </>
  );
};

export default ShareInvoiceModal;
