import axios from 'axios';
import settings from 'config/appConfig';

const instance = axios.create({
  baseURL: settings.API_URL
});

// -> api interceptor
instance.interceptors.request.use(
  async (requestConfig) => {
    const localSessionToken = sessionStorage.getItem('auth-token') || process.env.REACT_APP_AUTH_TOKEN;

    return localSessionToken
      ? { ...requestConfig, headers: { ...requestConfig.headers, Authorization: `Bearer ${localSessionToken}` } }
      : requestConfig;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  async (response) => {
    return response;
  },
  (error) => {
    const errorObject = Boolean(error.response && error.response.data) ? error.response.data : error;

    return Promise.reject(Boolean(errorObject.error) ? errorObject.error : error);
  }
);

export default instance;
