import { SocialChannel } from 'types/generated';

const channels: Array<{ value: SocialChannel; logo: string; label: string }> = [
  {
    value: SocialChannel.Instagram,
    logo: 'https://media.q-83.com/site/channel_icons/instagram.png',
    label: 'Instagram'
  },
  { value: SocialChannel.Youtube, logo: 'https://media.q-83.com/site/channel_icons/yt_logo.svg', label: 'YouTube' },
  { value: SocialChannel.Facebook, logo: 'https://media.q-83.com/site/channel_icons/facebook.svg', label: 'Facebook' },
  { value: SocialChannel.Tiktok, logo: 'https://media.q-83.com/site/channel_icons/tiktok.svg', label: 'TikTok' },
  { value: SocialChannel.Snapchat, logo: 'https://media.q-83.com/site/channel_icons/snapchat.svg', label: 'SnapChat' },
  { value: SocialChannel.Twitter, logo: 'https://media.q-83.com/site/channel_icons/twitter.svg', label: 'Twitter' },
  { value: SocialChannel.Blog, logo: 'https://media.q-83.com/site/channel_icons/blog.svg', label: 'Blog' },
  { value: SocialChannel.Linkedin, logo: 'https://media.q-83.com/site/channel_icons/linkedin.svg', label: 'LinkedIn' },
  { value: SocialChannel.Twitch, logo: 'https://media.q-83.com/site/channel_icons/twitch.png', label: 'Twitch' },
  {
    value: SocialChannel.Videography,
    logo: 'https://media.q-83.com/site/channel_icons/camera-video.png',
    label: 'Videography'
  },
  {
    value: SocialChannel.Photography,
    logo: 'https://media.q-83.com/site/channel_icons/camera-dslr.png',
    label: 'Photography'
  },
  { value: SocialChannel.Podcast, logo: 'https://media.q-83.com/site/channel_icons/microphone.png', label: 'Podcast' },
  {
    value: SocialChannel.Appearance,
    logo: 'https://media.q-83.com/site/channel_icons/speaker.png',
    label: 'Appearance'
  },
  { value: SocialChannel.Custom, logo: 'https://media.q-83.com/site/channel_icons/favourite.svg', label: 'Custom' }
];

export default channels;
