import { Box, Button, Menu, MenuItem } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ScrollableBoxNoScroll } from 'components/Containers/ScrollableBoxNoScroll';
import { Loader } from 'components/Loader';
import { TileContainer } from 'components/Containers/TileContainer';
import AppConfig from 'config/appConfig';
import React, { useState } from 'react';
import {
  GetReportConfigListQuery,
  useDeleteReportConfigMutation,
  useGetReportConfigListQuery,
  GetMyUserUsageDocument
} from 'types/generated';
import { Data } from './Data';
import { NoData } from './NoData';
import { useNavigate } from 'react-router';
import useSubscribePopupCtx, { PlanLimitType } from 'context/useSubscribePopupCtx';

export const ReportsTile = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedReportKitConfig, setSelectedMediaKitConfigId] = useState<null | string>(null);
  const { data, loading: queryLoading } = useGetReportConfigListQuery();
  const [deleteReportConfig, { loading: mutationLoading }] = useDeleteReportConfigMutation({
    refetchQueries: [{ query: GetMyUserUsageDocument }],
    awaitRefetchQueries: true
  });
  const reportData = data?.getReportConfigList || [];
  const navigate = useNavigate();
  const { classes } = useStyles();

  const { executeLimitedAction } = useSubscribePopupCtx(PlanLimitType.REPORTS_USAGE);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
    setAnchorEl(event.currentTarget);
    setSelectedMediaKitConfigId(id);
  };

  const handleNewReport = () => navigate('/reports?action=create');

  const renderContent = () => {
    if (queryLoading || mutationLoading) {
      return <Loader />;
    }

    const renderData = () => {
      return reportData.length ? (
        <Data reportData={reportData} onMenuClick={handleMenuClick} />
      ) : (
        <>
          <NoData />
        </>
      );
    };

    return (
      <>
        <ScrollableBoxNoScroll parentHeight={280}>{renderData()}</ScrollableBoxNoScroll>
        {renderNewButton()}
        {renderMenu()}
      </>
    );
  };

  const renderNewButton = () => {
    return (
      <Box mt='auto'>
        <Button
          className={classes.button}
          onClick={() => executeLimitedAction(handleNewReport)}
          {...(!reportData.length && {
            style: { width: 'calc(100% - 40px)' }
          })}
          variant='outlined'
          size='small'
        >
          Create Report
        </Button>
      </Box>
    );
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedMediaKitConfigId(null);
  };

  const renderMenu = () => {
    const handleView = () => {
      window.open(`${AppConfig.REPORT_URL}/#/view/${selectedReportKitConfig}`, '_blank');
      handleClose();
    };

    const handleDelete = () => {
      if (selectedReportKitConfig) {
        deleteReportConfig({
          variables: { reportId: selectedReportKitConfig },
          update(cache, { data }) {
            if (data?.deleteReportConfig?.success === true) {
              try {
                cache.modify({
                  fields: {
                    getReportConfigList(
                      existingList: NonNullable<GetReportConfigListQuery['getReportConfigList']>,
                      { readField }
                    ) {
                      return existingList.filter((o) => Boolean(o && selectedReportKitConfig !== readField('_id', o)));
                    }
                  }
                });
              } catch (e) {
                console.warn('e', e);
              }
            }
          }
        });
        handleClose();
      }
    };

    return (
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleView}>View</MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>
    );
  };

  return (
    <TileContainer height={280} title='Reports' width={260}>
      <Box display='flex' flexDirection='column'>
        {renderContent()}
      </Box>
    </TileContainer>
  );
};

const useStyles = makeStyles()((theme) => {
  return {
    button: {
      position: 'absolute',
      bottom: 20,
      left: 20
    }
  };
});
