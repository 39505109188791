import { useCallback, useContext } from 'react';
import { UPDATE_KIT_ELEMENT } from '../../../graphql/mutations/mediaKits';
import { MediaKitElement } from '../../../types/generated';
import { Paper, IconButton, Grid, Box, Typography, CircularProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import AddIcon from 'assets/components/AddIcon';
import { get } from 'lodash';
import { FileUploadContext } from 'context/fileUploadingContext';

import SingleImageBox from './SingleImageBox';
import GalleryBox from './GalleryBox';
import ImageListBox from './ImageListBox';
import VideoBox from './VideoBox';
import PlainTextBox from './PlainTextBox';
import LinkedAccountsBox from './LinkedAccountsBox';
import FeaturedInsightsBox from './FeaturedInsightsBox';
import RichTextBox from './RichTextBox';
import LinksBox from './LinksBox';
import RatesBox from './RatesBox';
import DeliverableRatesBox from './DeliverableRatesBox';
import FilesBox from './FilesBox';
import AudienceDemographicsBox from './AudienceDemographicsBox';
import SelectedMediaBox from './SelectedMediaBox';

import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '../../../assets/icons/edit.svg';
import BinIcon from '../../../assets/icons/bin.svg';
import MoveIcon from '../../../assets/icons/move.svg';

const styles = makeStyles()((theme) => ({
  divider: {
    backgroundColor: '#ACACAC',
    height: 0.5
  },
  error: { color: 'red' },
  editorContainer: {
    alignItems: 'center',
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
    borderStyle: 'solid'
  },
  previewContainer: {
    border: 'none',
    background: 'none',
    boxShadow: 'none'
  },
  titleSeparator: { width: '100%', borderWidth: 1, borderColor: theme.palette.common.black },
  elementTitle: {
    opacity: 0.5,
    color: theme.palette.common.black,
    fontWeight: 500,
    fontSize: 10,
    textTransform: 'uppercase'
  },
  controlsContainer: {
    zIndex: 2
  },
  customIcon: {
    width: 18,
    height: 18
  },
  previewTitle: { fontSize: 16, fontWeight: 500 },
  previewSection: {
    minHeight: 80,
    backgroundColor: '#ffffff',
    borderRadius: '4px',
    marginTop: '15px',
    marginBottom: '15px',
    transition: 'all 0.3s',
    boxShadow: '0 1px 2px 0 rgba(211,211,211,0.5)',
    '&:hover': {
      boxShadow: `0 2px 4px 0 ${theme.palette.primary.main}`
    }
  }
}));

interface MediaKitEditBoxProps {
  isEditing?: boolean;
  setIsEditing: (isEditing: boolean) => void;
  handleDelete?: () => void;
  loading?: boolean;
  element: MediaKitElement;
  elementId: string;
  onUpdate: (content: any) => void;
  deleteLoading?: boolean;
  classes?: any;
}

export interface EditBoxProps {
  element: MediaKitElement;
  handleUpdate: (content: any, previewContent?: any) => void;
  isEditing?: boolean;
  setIsEditing: (isEditing: boolean) => void;
  linkedAccountsContent?: Array<string>;
}

const MediaKitEditBox = (props: MediaKitEditBoxProps) => {
  const { isEditing, setIsEditing, handleDelete, loading, element, deleteLoading } = props;
  const { classes } = styles();

  return (
    <Box className={classes.previewSection}>
      <Paper className={isEditing ? classes.editorContainer : classes.previewContainer}>
        <Grid container alignItems='stretch' direction='column' justifyContent='flex-start' style={{ padding: 18 }}>
          <Grid container justifyContent='space-between' alignItems='center'>
            <Grid item>
              {!isEditing ? (
                <Typography className={classes.previewTitle}>{element.title}</Typography>
              ) : (
                <div style={{ width: 40 }} />
              )}
            </Grid>
            <Grid item>{isEditing && <Typography className={classes.elementTitle}>{element.title}</Typography>}</Grid>
            <Grid item className={classes.controlsContainer}>
              <Grid container justifyContent='flex-end' alignItems='center'>
                <Grid item>
                  {Boolean(loading) ? (
                    <CircularProgress />
                  ) : setIsEditing ? (
                    <IconButton onClick={() => setIsEditing(!Boolean(isEditing))}>
                      {isEditing ? (
                        <ClearIcon />
                      ) : Boolean(
                          element.content?.type &&
                            ['DELIVERABLE_RATES', 'LINKS', 'FILES', 'FEATURED_INSIGHTS', 'IMAGE_LIST'].includes(
                              element.content.type
                            )
                        ) ? (
                        <AddIcon fill='#111111' size={18} strokeWidth={2} />
                      ) : (
                        <img src={EditIcon} alt='edit element' className={classes.customIcon} />
                      )}
                    </IconButton>
                  ) : null}
                </Grid>

                {Boolean(handleDelete && !isEditing) && (
                  <Grid item>
                    <IconButton onClick={handleDelete}>
                      {deleteLoading ? (
                        <CircularProgress size='small' />
                      ) : (
                        <img src={BinIcon} alt='delete element' className={classes.customIcon} />
                      )}
                    </IconButton>
                  </Grid>
                )}
                {Boolean(!isEditing) && (
                  <Grid item>
                    <IconButton disabled={true}>
                      <img src={MoveIcon} alt='move element' className={classes.customIcon} />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          {Boolean(!isEditing) && <hr className={classes.titleSeparator} />}

          <EditBoxSwitch {...props} classes={classes} />
        </Grid>
      </Paper>
    </Box>
  );
};

const EditBoxSwitch = ({ isEditing, setIsEditing, elementId, element, onUpdate }: MediaKitEditBoxProps) => {
  const uploadingContext = useContext(FileUploadContext);

  const handleUpdate = useCallback(
    (content: any, previewContent?: any) => {
      if (elementId) {
        uploadingContext.beginUpload(elementId, UPDATE_KIT_ELEMENT, {
          id: elementId,
          content
        });
        if (previewContent) {
          onUpdate(previewContent);
        } else {
          onUpdate(content);
        }
      }
    },

    // Prevent render loop
    // eslint-disable-next-line
    [elementId, onUpdate]
  );

  if (elementId && uploadingContext.loadingState[elementId]) {
    return (
      <Grid container justifyContent='center' alignItems='center' direction='column'>
        {uploadingContext.loadingState[elementId].percentage && (
          <Typography style={{ marginBottom: 5 }}>
            {uploadingContext.loadingState[elementId].percentage < 1
              ? `${(uploadingContext.loadingState[elementId].percentage * 100).toFixed()}%`
              : 'processing...'}
          </Typography>
        )}
        <CircularProgress />
      </Grid>
    );
  }

  const type = get(element, 'content.type');

  if (type === 'TEXT') {
    return (
      <PlainTextBox element={element} handleUpdate={handleUpdate} isEditing={isEditing} setIsEditing={setIsEditing} />
    );
  }

  if (type === 'RICH_TEXT') {
    return (
      <RichTextBox element={element} handleUpdate={handleUpdate} isEditing={isEditing} setIsEditing={setIsEditing} />
    );
  }

  if (type === 'IMAGE') {
    return (
      <SingleImageBox element={element} handleUpdate={handleUpdate} isEditing={isEditing} setIsEditing={setIsEditing} />
    );
  }

  if (type === 'VIDEO') {
    return <VideoBox element={element} handleUpdate={handleUpdate} isEditing={isEditing} setIsEditing={setIsEditing} />;
  }

  if (type === 'GALLERY') {
    return (
      <GalleryBox element={element} handleUpdate={handleUpdate} isEditing={isEditing} setIsEditing={setIsEditing} />
    );
  }

  if (type === 'IMAGE_LIST') {
    return (
      <ImageListBox element={element} handleUpdate={handleUpdate} isEditing={isEditing} setIsEditing={setIsEditing} />
    );
  }

  if (type === 'LINKS') {
    return <LinksBox element={element} handleUpdate={handleUpdate} isEditing={isEditing} setIsEditing={setIsEditing} />;
  }

  if (type === 'RATES') {
    return <RatesBox element={element} handleUpdate={handleUpdate} isEditing={isEditing} setIsEditing={setIsEditing} />;
  }

  if (type === 'DELIVERABLE_RATES') {
    return (
      <DeliverableRatesBox
        element={element}
        handleUpdate={handleUpdate}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />
    );
  }

  if (type === 'FILES') {
    return <FilesBox element={element} handleUpdate={handleUpdate} isEditing={isEditing} setIsEditing={setIsEditing} />;
  }

  if (type === 'LINKED_ACCOUNTS') {
    return (
      <LinkedAccountsBox
        element={element}
        handleUpdate={handleUpdate}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />
    );
  }

  if (type === 'FEATURED_INSIGHTS') {
    return (
      <FeaturedInsightsBox
        element={element}
        handleUpdate={handleUpdate}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />
    );
  }

  if (type === 'AUDIENCE_DEMOGRAPHICS') {
    return (
      <AudienceDemographicsBox
        element={element}
        handleUpdate={handleUpdate}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />
    );
  }
  if (type === 'SELECTED_MEDIA') {
    return (
      <SelectedMediaBox
        element={element}
        handleUpdate={handleUpdate}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />
    );
  }

  return null;
};

export default MediaKitEditBox;
