import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import useCopyText from 'hooks/useCopyText';

import LinkIcon from 'assets/icons/LinkIcon.svg';
import {
  useCreateMediaKitLinkMutation,
  useCreateReportLinkMutation,
  useCreateCollaborationInvoiceLinkMutation
} from 'types/generated';
import { useSnackbar } from 'notistack';
import { SNACK_BAR_MESSAGES } from 'lib/snackbarMessages';

interface ComponentProps {
  mediaKitId?: string;
  reportConfigId?: string;
  collaborationInvoiceId?: string;
  aparLink?: string;
}

const PdfButtons = (props: ComponentProps) => {
  const theme = useTheme();
  const { mediaKitId, reportConfigId, collaborationInvoiceId, aparLink } = props;
  const primaryColor = theme?.palette?.primary?.main;
  const [isCopyActive, setIsCopyActive] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const copyText = useCopyText();

  const [createMediaKitLink, { data: mediaKitLinkData, loading: mediaKitLinkLoading }] =
    useCreateMediaKitLinkMutation();

  const mediaKitLink = useMemo(() => mediaKitLinkData?.createMediaKitLink, [mediaKitLinkData]);

  const copyMediaKitLink = useCallback(
    (showError?: boolean) => {
      if (mediaKitLink) {
        copyText(mediaKitLink, (error) => {
          if (error) {
            if (showError) {
              enqueueSnackbar(error);
            }
          } else {
            enqueueSnackbar(SNACK_BAR_MESSAGES.MEDIA_KIT_LINK_COPIED);
          }
        });
      }
    },
    [mediaKitLink, enqueueSnackbar, copyText]
  );

  const [createReportLink, { data: reportLinkData, loading: reportLinkLoading }] = useCreateReportLinkMutation();

  const reportLink = useMemo(() => reportLinkData?.createReportLink, [reportLinkData]);

  const copyReportLink = useCallback(
    (showError?: boolean) => {
      if (reportLink) {
        copyText(reportLink, (error) => {
          if (error) {
            if (showError) {
              enqueueSnackbar(error);
            }
          } else {
            enqueueSnackbar(SNACK_BAR_MESSAGES.REPORT_LINK_COPIED);
          }
        });
      }
    },
    [reportLink, enqueueSnackbar, copyText]
  );

  const [getInvoices, { data: invoicesData, loading: invoicesLoading }] = useCreateCollaborationInvoiceLinkMutation();

  const invoiceLink = useMemo(() => invoicesData?.createCollaborationInvoiceLink?.link, [invoicesData]);

  const copyInvoiceLink = useCallback(
    (showError?: boolean) => {
      if (invoiceLink) {
        copyText(invoiceLink, (error) => {
          if (error) {
            if (showError) {
              enqueueSnackbar(error);
            }
          } else {
            enqueueSnackbar(SNACK_BAR_MESSAGES.INVOICE_LINK_COPIED);
          }
        });
      }
    },
    [invoiceLink, enqueueSnackbar, copyText]
  );

  useEffect(() => {
    if (isCopyActive) {
      copyMediaKitLink();
    }
  }, [copyMediaKitLink, isCopyActive]);

  useEffect(() => {
    if (isCopyActive) {
      copyReportLink();
    }
  }, [copyReportLink, isCopyActive]);

  useEffect(() => {
    if (isCopyActive) {
      copyInvoiceLink();
    }
  }, [copyInvoiceLink, isCopyActive]);

  const copyAparLink = useCallback(
    (showError?: boolean) => {
      if (aparLink) {
        copyText(aparLink, (error) => {
          if (error) {
            if (showError) {
              enqueueSnackbar(error);
            }
          } else {
            enqueueSnackbar(SNACK_BAR_MESSAGES.APAR_LINK_COPIED);
          }
        });
      }
    },
    [aparLink, enqueueSnackbar, copyText]
  );

  useEffect(() => {
    if (aparLink) {
      setIsCopyActive(true);
    }
  }, [aparLink, setIsCopyActive]);

  const copyLink = () => {
    if (isCopyActive) {
      copyMediaKitLink(true);
      copyReportLink(true);
      copyInvoiceLink(true);
      copyAparLink(true);
    } else {
      setIsCopyActive(true);

      if (mediaKitId) {
        createMediaKitLink({ variables: { mediaKitId } });
      }
      if (reportConfigId) {
        createReportLink({ variables: { reportConfigId } });
      }
      if (collaborationInvoiceId) {
        getInvoices({ variables: { collaborationInvoiceId } });
      }
    }
  };

  const isLoading = mediaKitLinkLoading || reportLinkLoading || invoicesLoading;

  return (
    <div>
      <Typography style={{ fontWeight: 500, marginTop: 7 }}>Other sharing options</Typography>
      <Button
        onClick={copyLink}
        variant='outlined'
        color='primary'
        style={{ width: '100%', marginTop: 15, color: primaryColor }}
      >
        {Boolean(isLoading) ? (
          <>
            <CircularProgress size={18} style={{ marginRight: 8 }} />
            Retrieving Link...
          </>
        ) : (
          <>
            <img src={LinkIcon} alt='' style={{ marginRight: 8 }} />
            {isCopyActive ? 'Copy Link' : 'Generate Link'}
          </>
        )}
      </Button>
    </div>
  );
};

export default PdfButtons;
