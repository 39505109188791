import { Box, Button, CircularProgress, Menu, MenuItem } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ScrollableBoxNoScroll } from 'components/Containers/ScrollableBoxNoScroll';
import { TileContainer } from 'components/Containers/TileContainer';
import { Loader } from 'components/Loader';
import AppConfig from 'config/appConfig';
import { useErrorHandlerHook } from 'hooks/useErrorCatchHook';
import { COLORS } from 'lib/constants';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSubscribePopupCtx, { PlanLimitType } from 'context/useSubscribePopupCtx';

import {
  GetMediaKitConfigListDocument,
  GetMyUserUsageDocument,
  MediaKitConfig,
  useCreateMediaKitConfigMutation,
  useDeleteMediaKitConfigMutation,
  useGetMediaKitConfigListQuery
} from 'types/generated';
import { Data } from './Data';
import { NoData } from './NoData';
import { useWalkthrough } from 'hooks/useWalkthrough';
import { WALKTHROUGHS } from 'types/custom';

export const MediaKitsTile = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { data, loading: queryLoading } = useGetMediaKitConfigListQuery({
    onError: (error) => {
      apolloHandler(error);
    }
  });
  const [deleteMediaKit, { loading: mutationLoading }] = useDeleteMediaKitConfigMutation({
    onError: (deleteError) => {
      apolloHandler(deleteError);
    },
    refetchQueries: [{ query: GetMyUserUsageDocument }],
    awaitRefetchQueries: true
  });
  const { classes } = useStyles();
  const mediaKitData = data?.getMediaKitConfigList || [];
  const [selectedMediaKitConfigId, setSelectedMediaKitConfigId] = useState<null | string>(null);
  const { apolloHandler } = useErrorHandlerHook();
  const navigate = useNavigate();
  const { executeLimitedAction } = useSubscribePopupCtx(PlanLimitType.MEDIA_KIT_USAGE);

  const { handleWalkthrough: updateMediaKitWalkthrough } = useWalkthrough(WALKTHROUGHS.MEDIA_KITS);

  const [createKit, { loading: createLoading }] = useCreateMediaKitConfigMutation({
    onCompleted: (createData) => {
      if (createData.createMediaKitConfig) {
        return navigate(`/mediakit-edit/${createData.createMediaKitConfig}?action=create`);
      }
    },
    refetchQueries: [{ query: GetMediaKitConfigListDocument }]
  });

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedMediaKitConfigId(null);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
    setAnchorEl(event.currentTarget);
    setSelectedMediaKitConfigId(id);
  };

  const renderMenu = () => {
    const handleView = () => {
      window.open(`${AppConfig.MEDIAKIT_URL}/#/view/${selectedMediaKitConfigId}`, '_blank');
      handleClose();
    };

    const handleDelete = () => {
      if (selectedMediaKitConfigId) {
        deleteMediaKit({
          variables: { id: selectedMediaKitConfigId },
          update(cache, { data }) {
            if (data?.deleteMediaKitConfig?.success === true) {
              try {
                cache.modify({
                  fields: {
                    getMediaKitConfigList(existingList: MediaKitConfig[], { readField }) {
                      return existingList.filter((o) => selectedMediaKitConfigId !== readField('_id', o));
                    }
                  }
                });
              } catch (e) {
                console.warn('e', e);
              }
            }
          }
        });

        handleClose();
      }
    };

    return (
      <Menu id='long-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleView}>View</MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>
    );
  };

  const renderContent = () => {
    if (queryLoading || mutationLoading) {
      return <Loader />;
    }

    const renderData = () => {
      return mediaKitData.length ? <Data mediaKitData={mediaKitData} onMenuClick={handleMenuClick} /> : <NoData />;
    };

    return (
      <>
        <ScrollableBoxNoScroll parentHeight={280}>{renderData()}</ScrollableBoxNoScroll>
        {renderNewButton()}
        {renderMenu()}
      </>
    );
  };

  const handleNewMediaKit = () => {
    updateMediaKitWalkthrough();
    executeLimitedAction(createKit);
  };

  const renderNewButton = () => {
    return (
      <Box mt='auto'>
        <Button
          onClick={handleNewMediaKit}
          {...(!mediaKitData.length && {
            style: { width: 'calc(100% - 40px)' }
          })}
          className={classes.button}
          variant='outlined'
          size='small'
        >
          {createLoading && (
            <CircularProgress
              size={16}
              style={{ marginRight: 8, ...(!mediaKitData.length && { color: COLORS.GRAY_1 }) }}
            />
          )}
          Create Media Kit
        </Button>
      </Box>
    );
  };

  return (
    <TileContainer title={'Media Kits'} height={280} width={260}>
      <Box display='flex' flexDirection='column'>
        {renderContent()}
      </Box>
    </TileContainer>
  );
};

const useStyles = makeStyles()((theme) => {
  return {
    button: {
      position: 'absolute',
      bottom: 20,
      left: 20
    }
  };
});
