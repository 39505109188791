import React from 'react';
import TextField from '@mui/material/TextField';
import { FormHelperTextProps, InputLabelProps, TextFieldProps, InputProps } from '@mui/material';

import InputMask from 'react-input-mask';

interface SignUpTextInputProps {
  errorMessage?: any;
  error?: string;
  value?: string;
  required?: boolean | string;
  margin?: TextFieldProps['margin'] | undefined;
  type?: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined;
  onBlur?: () => void;
  mismatch?: any;
  helperText?: string;
  id?: string;
  label?: string;
  placeholder?: string;
  InputLabelProps?: Partial<InputLabelProps>;
  FormHelperTextProps?: Partial<FormHelperTextProps<'p', {}>> | undefined;
  dataCy?: string;
  inputProps?: InputProps;
  variant?: 'filled' | 'standard' | 'outlined' | undefined;
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement> | undefined;
  disabled?: boolean;
}

const SignUpTextInput = (props: SignUpTextInputProps) => {
  const noop = () => {};
  let error = '';
  if (props.errorMessage && props.errorMessage.constructor === Array) {
    error = props.errorMessage.join(' - ');
  } else if (typeof props.errorMessage === 'string') {
    error = props.errorMessage;
  }

  let value = props.value;
  let required = props.required ? false : true; // Note: Enter any string to make false
  let margin = props.margin || 'normal';

  if (props.type === 'date') {
    return (
      <InputMask
        mask={'99/99/9999'}
        value={value}
        onChange={props.onChange}
        onBlur={props.onBlur || noop}
        disabled={props.disabled}
      >
        {() => (
          <TextField
            required={required}
            error={props.error || props.mismatch}
            helperText={error ? error : props.helperText}
            id={props.id}
            label={props.label}
            placeholder={props.placeholder}
            fullWidth={true}
            InputLabelProps={props.InputLabelProps ? props.InputLabelProps : undefined}
            FormHelperTextProps={props.FormHelperTextProps ? props.FormHelperTextProps : undefined}
            InputProps={Object.assign(
              { id: props.dataCy || null, inputProps: { contentEditable: true, 'data-cy': props.dataCy } },
              props.inputProps ? props.inputProps : {}
            )}
            value={value}
            variant={props.variant}
            onChange={props.onChange}
            margin={margin}
            onKeyDown={props.onKeyDown}
          />
        )}
      </InputMask>
    );
  }

  return (
    <TextField
      value={value}
      onChange={props.onChange}
      onBlur={props.onBlur || noop}
      disabled={props.disabled}
      required={required}
      error={props.error || props.mismatch}
      helperText={error ? error : props.helperText}
      id={props.id}
      label={props.label}
      placeholder={props.placeholder}
      fullWidth={true}
      InputLabelProps={props.InputLabelProps ? props.InputLabelProps : undefined}
      FormHelperTextProps={props.FormHelperTextProps ? props.FormHelperTextProps : undefined}
      InputProps={Object.assign(
        { id: props.dataCy || null, inputProps: { contentEditable: true, 'data-cy': props.dataCy } },
        props.inputProps ? props.inputProps : {}
      )}
      variant={props.variant}
      margin={margin}
      onKeyDown={props.onKeyDown}
    />
  );
};

export default SignUpTextInput;
