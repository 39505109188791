import { Box, Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { NewMediaKitButton } from './NewMediaKitButton';
import ContentfulAdBanner from 'components/Dashboard/ContentfulAdBanner';

export const Header = () => {
  const { classes } = useClasses();

  return (
    <>
      <Box mb={'14px'}>
        <Typography className={classes.subtitleText}>Manage</Typography>
      </Box>
      <Grid style={{ marginBottom: 23 }} container justifyContent='space-between'>
        <Grid item>
          <Typography style={{ color: '#333333', fontWeight: 400, fontSize: 28 }}>Media Kits</Typography>
        </Grid>
        <Grid item>
          <NewMediaKitButton
            buttonText='New Media Kit'
            buttonVariant='contained'
            buttonSize='medium'
            alignItems='center'
          />
        </Grid>
      </Grid>
      <ContentfulAdBanner adLocation='media-kits' />
    </>
  );
};

const useClasses = makeStyles()((theme) => {
  return {
    subtitleText: {
      fontWeight: 500,
      opacity: 0.67,
      fontSize: 10
    },
    headerCardText: {
      color: '#4C4C4C',
      lineHeight: '20px'
    },
    headerCardTextBold: {
      fontWeight: 500,
      color: '#3A3A3A'
    },
    headerPinkText: {
      color: '#C12265',
      fontSize: 14,
      fontWeight: 500
    },
    card: {
      padding: '15px 20px',
      border: '0.5px solid #BBBBBB',
      borderRadius: '4px',
      backgroundColor: '#FFFFFF',
      boxShadow: '0 1px 2px 0 rgba(211,211,211,0.5)'
    }
  };
});
