import React, { useState, useEffect } from 'react';

import { useTheme } from '@mui/material';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import Dialog from '@mui/material/Dialog';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import LinearProgress from '@mui/material/LinearProgress';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ShareFields, { SharePayload } from '../../Fields/ShareFields';

import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';

import PdfButtons from '../../Fields/PdfButtons';
import Modal from 'components/Modal';
import TextInput from '../../TextInput';
import PartyPopperIcon from '../../../assets/icons/PartyPopper.svg';

import useTracking from 'context/useTrackingCtx';
import { formatError } from '../../../lib/formatStrings';

import {
  useShareMediaKitToContactMutation,
  useGetMediaKitConfigListQuery,
  useUpdateMediaKitConfigMutation,
  GetMediaKitLinkListDocument
} from '../../../types/generated';

interface ComponentProps {
  selectedMediaKitId?: string;
  openSubscribePopup?: (show: Boolean, selectedPlanId: string) => void;
  onComplete?: () => void;
  onExternalToggle?: (arg: boolean) => void;
  externalShareMetricsOpen?: boolean;
  isModalOnly?: boolean;
  isNewlyPublished?: boolean;
  children?: any;
  mediaKitLinkCount?: number;
  buttonTitle?: string;
}

const defaultTitle = 'My Media Kit';

const ShareMediaKitModal = (props: ComponentProps) => {
  const {
    selectedMediaKitId,
    onComplete,
    onExternalToggle,
    externalShareMetricsOpen,
    isNewlyPublished,
    isModalOnly,
    children,
    buttonTitle
  } = props;

  const theme = useTheme();

  const { trackEvent } = useTracking();

  // Base payload states:
  const [mediaKitId, setMediaKitId] = useState(selectedMediaKitId || '');
  // Extra payload states:
  const [mediaKitName, setMediaKitName] = useState(defaultTitle);
  // Modal states:
  const [shareMetricsOpen, setShareMetricsOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  // Error states:
  const [serverError, setServerError] = useState('');
  const [fieldErrors, setFieldErrors] = useState<any>({});

  // External modal states:
  useEffect(() => {
    const isExternalToggle = typeof onExternalToggle !== 'undefined';
    const isExternalState = typeof externalShareMetricsOpen !== 'undefined';
    const isStateMismatch = externalShareMetricsOpen !== shareMetricsOpen;
    if (isExternalToggle && isExternalState && isStateMismatch) {
      // @ts-ignore - checked using isExternalState
      setShareMetricsOpen(externalShareMetricsOpen);
    }
  }, [onExternalToggle, externalShareMetricsOpen, shareMetricsOpen]);

  // Graphql:
  const {
    loading: mediaKitLoading,
    data: mediaKitData,
    error: mediaKitError,
    refetch: mediaKitRefetch
  } = useGetMediaKitConfigListQuery();
  const mediaKits = mediaKitData?.getMediaKitConfigList || [];
  const [updateMediaKitConfig] = useUpdateMediaKitConfigMutation();
  const [shareMediaKitToContact, { loading: shareLoading }] = useShareMediaKitToContactMutation({
    refetchQueries: [{ query: GetMediaKitLinkListDocument }]
  });

  const mediaKit = find(mediaKits, { _id: mediaKitId });

  useEffect(() => {
    if (shareMetricsOpen) {
      mediaKitRefetch();
    }
  }, [mediaKitRefetch, shareMetricsOpen]);

  useEffect(() => {
    const savedTitle = mediaKit?.title;
    if (savedTitle && Boolean(savedTitle !== defaultTitle) && Boolean(mediaKitName === defaultTitle)) {
      setMediaKitName(savedTitle);
    }
  }, [mediaKit, mediaKitName]);

  const checkInput = () => {
    if (isNewlyPublished && !mediaKitName) {
      setFieldErrors({ mediaKitName: 'Please name your media kit' });
      return false;
    }
    if (isEmpty(mediaKitId)) {
      setFieldErrors({ mediaKitId: 'Please select a media kit' });
      return false;
    }
    return true;
  };

  const handleToggleShareMetrics = (open?: boolean) => {
    const isOpen = typeof open === 'boolean' ? open : !shareMetricsOpen;
    onExternalToggle ? onExternalToggle(isOpen) : setShareMetricsOpen(isOpen);
  };

  const handleToggleSuccess = (open?: boolean) => {
    const isOpen = typeof open === 'boolean' ? open : !successOpen;
    setSuccessOpen(isOpen);
  };

  const handleUpdateMediaKit = () => {
    if (mediaKit?._id && mediaKitName) {
      updateMediaKitConfig({
        variables: {
          id: mediaKit?._id,
          updates: { title: mediaKitName }
        }
      }).catch((err) => {
        console.log('media kit name error:', err);
      });
    }
  };

  const handleShareProfile = (payload: SharePayload) => {
    // Update media kit title, if newly published:
    if (isNewlyPublished && mediaKitName) {
      handleUpdateMediaKit();
    }
    // Submit payload:
    shareMediaKitToContact({
      variables: {
        mediaKitId,
        ...payload
      }
    })
      .then(() => {
        trackEvent('mediaKit', 'shared media kit');
        if (onComplete) onComplete();
        handleToggleSuccess(true);
        handleToggleShareMetrics(false);
        mediaKitRefetch();
      })
      .catch((err) => {
        setServerError(formatError(err) || 'Unknown error');
      });
  };

  const handleError = (name: string) => fieldErrors.hasOwnProperty(name);

  const renderSuccessModal = () => {
    let color = theme?.palette?.primary?.dark;
    return (
      <Dialog open={true} onClose={() => handleToggleSuccess(false)}>
        <Grid container direction='column' alignItems='center' style={{ padding: 15 }}>
          <Grid item>
            <CheckCircleOutlineIcon style={{ fontSize: '36px', color, margin: '15px' }} />
          </Grid>
          <Grid item>
            <span style={{ marginBottom: '5px' }}>Successfully sent media kit</span>
          </Grid>
          <Grid item>
            <Button onClick={() => handleToggleSuccess(false)} color='primary'>
              Okay
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    );
  };

  const renderMediaKits = () => {
    return (
      <Grid container style={{ marginTop: 16, marginBottom: 8 }}>
        <Grid item xs={12}>
          {mediaKitLoading ? (
            <LinearProgress style={{ width: '100%' }} />
          ) : mediaKitError ? (
            <Typography variant='caption' style={{ fontWeight: 200 }}>
              {formatError(mediaKitError)}
            </Typography>
          ) : (
            <FormControl variant='standard' fullWidth={true}>
              <InputLabel id='mediakit-label'>Media Kit</InputLabel>
              <Select
                variant='standard'
                disabled={Boolean(selectedMediaKitId)}
                value={mediaKitId}
                onChange={(e: any) => setMediaKitId(e?.target?.value)}
                fullWidth={true}
                labelId='mediakit-label'
              >
                <MenuItem disabled>Select media kit</MenuItem>
                {mediaKits?.map((o) =>
                  o?._id ? (
                    <MenuItem key={`mediakit-${o._id}`} value={o._id}>
                      {o.title}
                    </MenuItem>
                  ) : null
                )}
              </Select>
              {handleError('mediaKitId') && (
                <FormHelperText style={{ color: 'red' }}>{fieldErrors['mediaKitId']}</FormHelperText>
              )}
            </FormControl>
          )}
        </Grid>
      </Grid>
    );
  };

  const renderMediaKitNameField = () => {
    return (
      <Grid container style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '0 auto 10' }}>
        <Grid item xs={12}>
          <TextInput
            dataCy={'mediakit_name-input'}
            error={handleError('mediaKitName')}
            errorMessage={fieldErrors['mediaKitName']}
            id='mediaKitName'
            label='Media Kit Name'
            value={mediaKitName}
            onChange={(e: any) => setMediaKitName(e?.target?.value)}
            margin='dense'
          />
        </Grid>
      </Grid>
    );
  };

  const renderHeader = () => {
    return (
      <div style={{ position: 'relative', width: '100%' }}>
        {isNewlyPublished ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column'
            }}
          >
            <img src={PartyPopperIcon} alt='Success' style={{ marginBottom: 20 }} />
            <div>Yay! Your media kit has been created</div>
          </div>
        ) : (
          <span>
            Share media kit <span style={{ color: theme.palette.primary.main }}>{mediaKit?.title || ''}</span>
          </span>
        )}
        <ButtonBase onClick={() => handleToggleShareMetrics()} style={{ position: 'absolute', top: 0, right: 0 }}>
          <CloseRoundedIcon style={{ fontSize: 22 }} />
        </ButtonBase>
      </div>
    );
  };

  const renderShareMetricsModal = () => {
    return (
      <Modal
        isOpen
        hideConfirmationButtons
        hideConfirmButton={true}
        subConfirmationContent={<PdfButtons mediaKitId={mediaKit?._id || selectedMediaKitId || ''} />}
        errorMessage={serverError}
        showErrorMessage={Boolean(serverError)}
        heading={renderHeader()}
        maxWidth={'sm'}
      >
        {isNewlyPublished && renderMediaKitNameField()}
        <ShareFields
          onShare={(payload) => handleShareProfile(payload)}
          checkIfEnabled={checkInput}
          onDismiss={handleToggleShareMetrics}
          loading={shareLoading}
          sendNewContactRequest={true}
          radioButtonColor='primary'
        >
          {!selectedMediaKitId && renderMediaKits()}
        </ShareFields>
      </Modal>
    );
  };

  return (
    <>
      {shareMetricsOpen && renderShareMetricsModal()}
      {successOpen && renderSuccessModal()}
      <div onClick={() => handleToggleShareMetrics(true)}>
        {isModalOnly ? (
          <div />
        ) : children ? (
          children
        ) : (
          <Button variant='contained' color='primary'>
            {buttonTitle ? buttonTitle : `Share Media Kit`}
          </Button>
        )}
      </div>
    </>
  );
};

export default ShareMediaKitModal;
