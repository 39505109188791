import React from 'react';
import { useTheme } from '@mui/material';

const MediaKitIcon = () => {
  const theme = useTheme();

  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'>
      <title>saved items</title>
      <g
        strokeLinecap='square'
        strokeLinejoin='miter'
        strokeWidth='1'
        fill={theme.palette.primary.dark}
        stroke={theme.palette.primary.dark}
      >
        <path
          d='M20,2a2,2,0,0,1,2,2V20a2,2,0,0,1-2,2H4a2,2,0,0,1-2-2V4A2,2,0,0,1,4,2'
          fill='none'
          stroke={theme.palette.primary.dark}
          strokeMiterlimit='10'
        />{' '}
        <polygon points='16 12 12 9 8 12 8 2 16 2 16 12' fill='none' strokeMiterlimit='10' data-color='color-2' />
      </g>
    </svg>
  );
};

export default MediaKitIcon;
