import { useState, useEffect } from 'react';

export const useMobileView = (maxWidth?: string) => {
  const [mobileView, setMobileView] = useState(false);
  const [tabletView, setTabletView] = useState(false);

  const mobileWidth = maxWidth || '500px';
  const tabletWidth = '800px';
  const windowMobile = window.matchMedia(`(max-width: ${mobileWidth})`).matches;
  const windowTablet = window.matchMedia(`(max-width: ${tabletWidth})`).matches;

  useEffect(() => {
    if (windowMobile) setMobileView(true);
  }, [maxWidth, windowMobile]);

  useEffect(() => {
    if (windowTablet) setTabletView(true);
  }, [windowTablet]);

  return [mobileView, tabletView];
};
